<template>
    <div>
        <div v-if="component_done_loading" class="test">
            <template v-if="test._isPreview">
                <test-topbar ref="topbarRef" :test-obj="test" :options="{ forceHideTimer: true }" @timer-shown="processTimerShown" />
            </template>
            <template v-else>
                <test-topbar ref="topbarRef" :test-obj="test" @timer-shown="processTimerShown" />
            </template>

            <div
                :style="{
                    paddingTop: computedPaddingTop,
                    paddingBottom: '36px',
                }"
                class="test_content"
            >
                <h1 class="print">
                    {{ test.name }}
                </h1>
                <stopping-div :test-obj="test" class="padding20" @display="processDisplayTestProper" />
                <div v-if="display_test_proper" class="new-side-bar">
                    <template v-if="!test._isPreview || test._isTeacherPreview">
                        <test-sidebar ref="sideBarRef" :test-obj="test" :side-bar-closed-value="sideBarClosed" @button-clicked="handleButtonClicked" />
                    </template>
                    <main
                        class="main padding20"
                        :style="{
                            marginLeft: computedMarginLeft,
                            width: 'calc(100% - ' + computedMarginLeft + ')',
                        }"
                    >
                        <template v-if="!test._isPreview || test._isTeacherPreview">
                            <template
                                v-if="
                                    isTimerExtended &&
                                    (test.type == 'irat' || (test.type == 'application' && test.others.applicationType != 'team')) &&
                                    extendedTime != ''
                                "
                            >
                                <div id="insert-alert">
                                    <div class="alert alert-warning marginBottom30 flexSpaceBetween" role="alert" aria-live="assertive" aria-atomic="true">
                                        Instructor has added extra
                                        {{ extendedTime }} to the activity
                                        <button type="button" class="close padding0" data-dismiss="alert" aria-label="Close">
                                            <i class="fa-solid fa-xmark fontSize18 fontBlack" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </template>

                            <team-leadership
                                v-if="(test.type == 'trat' || (test.type == 'application' && test.others.applicationType == 'team')) && !test._isPreview"
                                class="marginBottom20"
                                :test-obj="test"
                            />

                            <div
                                :class="{
                                    'flexSpaceBetween align-items': notMobile,
                                }"
                            >
                                <test-progression class="width100" :test-obj="test" />

                                <question-pagination-container
                                    v-if="notMobile"
                                    class="marginLeft20 flexRight"
                                    :test-obj="test"
                                    :options="{
                                        showPagination: false,
                                    }"
                                />
                            </div>
                        </template>
                        <div>
                            <question-pagination-container
                                v-if="!notMobile"
                                class="flexRight marginTop20"
                                :test-obj="test"
                                :options="{
                                    showPagination: false,
                                }"
                            />
                            <question-groups class="marginTop20" :test-obj="test" :fetch-test="fetchTest" />
                            <question-pagination-container class="marginTop20" :test-obj="test" />
                        </div>
                    </main>
                </div>

                <!-- <div class="col-md-10 col-md-offset-1 flex" v-if="display_test_proper">
          <div
            class="col-xs-12 marginBottom10"
            v-if="!test._isPreview || test._isTeacherPreview"
          >
            <team-leadership
              class="marginBottom20"
              v-if="
                (test.type == 'trat' ||
                  (test.type == 'application' &&
                    test.others.applicationType == 'team')) &&
                  !test._isPreview
              "
              :testObj="test"
            ></team-leadership>
            <test-progression :testObj="test"></test-progression>
          </div>
          <div
            class="col-xs-12 col-md-8 col-md-offset-4 marginBottom20"
            :class="{
              '':
                (test.type == 'trat' ||
                  (test.type == 'application' &&
                    test.others.applicationType == 'team')) &&
                !test._isPreview,
            }"
          >
            <question-pagination-container
              class="marginTop20"
              :testObj="test"
            ></question-pagination-container>
          </div>
          <div
            class="col-md-4"
            v-if="!test._isPreview || test._isTeacherPreview"
          >
            <test-sidebar :testObj="test"></test-sidebar>
          </div>
          <div
            class="col-xs-12 col-md-8 testPanel"
            :class="{
              '':
                (test.type == 'trat' ||
                  (test.type == 'application' &&
                    test.others.applicationType == 'team')) &&
                !test._isPreview,
            }"
          >
            <question-groups
              :testObj="test"
              :fetchTest="fetchTest"
            ></question-groups>
            <question-pagination-container
              class="marginTop20"
              :testObj="test"
              :options="{ reverse: true }"
            ></question-pagination-container>
          </div>
        </div> -->
            </div>
            <!-- <div
        id="test_confirm_modal"
        class="modal"
        style="z-index: 50001 !important"
        v-shortkey.propagte="{
          left: ['arrowleft'],
          right: ['arrowright'],
          enter: ['enter'],
        }"
        @shortkey="submitConfirmAccessibility"
        tabindex="-1"
        role="dialog"
        aria-labelledby="testConfirmModal-title"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                &times;
              </button>
              <h2 id="testConfirmModal-title" class="modal-title">
                Test Submission
              </h2>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="message">
                  <div
                    class="col-xs-12"
                    v-if="test.type == 'irat' || test.type == 'trat'"
                  >
                    <div class="col-xs-offset-1 col-xs-10">
                      <div class="col-xs-12 marginBottom15">
                        <i class="fa fa-exclamation-triangle"></i>
                      </div>
                      <div class="col-xs-12 marginBottom30">
                        Please note that once you have submitted, you cannot
                        make changes to your answer choices. Are you sure about
                        this?
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12" v-if="test.type == 'application'">
                    <div class="col-xs-offset-1 col-xs-10">
                      <div class="col-xs-12 marginBottom15">
                        <i class="fa fa-exclamation-triangle"></i>
                      </div>
                      <div class="col-xs-12 marginBottom30">
                        Great! You have completed your Application Test and
                        ready for submission. You are about to submit your test.
                        No changes can be made after submission. Are you sure to
                        go ahead?
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 buttonGroup">
                  <button
                    class="btn btn-success mainBtn"
                    @click.prevent="submitTest()"
                    aria-label="Submit Test"
                  >
                    <i class="fas fa-check"></i>&nbsp;Submit Test
                  </button>
                  <button
                    class="btn btn-default mainBtn"
                    data-dismiss="modal"
                    aria-label="Back"
                  >
                    <i class="fas fa-caret-left"></i>&nbsp;Back
                  </button>
                </div>
              </div>
            </div>
            <div class="modal-footer"></div>
          </div>
        </div>
      </div> -->
        </div>
        <div
            id="previewChooseTestTypeModal"
            class="modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="previewChooseTestTypeModal-title"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="previewChooseTestTypeModal-title" class="modal-title">Test Preview</h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-xs-12" style="text-align: center">
                                <div>
                                    You are previewing an
                                    <b>IRAT AND TRAT</b> Activity.
                                </div>
                                <div>Please choose what test type you want to preview.</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="flex">
                                <div class="col-xs-12 col-md-6">
                                    <button
                                        id="modal-irat-btn"
                                        class="btn btn-default mainBtn"
                                        style="width: 100%"
                                        data-dismiss="modal"
                                        aria-label="Preview IRAT"
                                        @click.prevent="processPreviewTestType('irat')"
                                    >
                                        IRAT
                                    </button>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <button
                                        id="modal-trat-btn"
                                        class="btn btn-default mainBtn"
                                        style="width: 100%"
                                        data-dismiss="modal"
                                        aria-label="Preview TRAT"
                                        @click.prevent="processPreviewTestType('trat')"
                                    >
                                        TRAT
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="clearAnswersModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="clearAnswersModal-title">
            <div class="modal-dialog">
                <div class="preview modal-content">
                    <div class="modal-body">
                        <div class="textAlignCenter">
                            <p>Your instructor has reset your answers</p>
                        </div>
                        <div class="flex marginTop20">
                            <div class="center-block">
                                <button class="btn btn-primary subBtn" data-dismiss="modal">OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="teacherEndedModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-describedby="teacherEndedModal-describedby">
            <div class="modal-dialog">
                <div class="modal-content blue-modal-new">
                    <div class="modal-body textAlignCenter">
                        <img src="/img/teacher_ended.png" alt="" class="marginBottom20" width="50%" />

                        <div id="teacherEndedModal-describedby" class="visible-hidden">
                            Your instructor has ended the activity. Please wait. Redirecting in 3s.
                        </div>

                        <p class="marginBottom5">
                            <b>Your instructor has ended the activity.</b>
                        </p>

                        <p class="marginBottom30">Please wait.</p>

                        <div class="flexOnly justifyCenter marginBottom10">
                            <div class="modal-countdown-number marginLeft10" />
                            <p class="loadingAnimation" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="timeUpModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-describedby="timeUpModal-describedby">
            <div class="modal-dialog">
                <div class="modal-content blue-modal-new">
                    <div class="modal-body textAlignCenter">
                        <img src="/img/time_up.png" alt="" class="marginBottom20" width="50%" />

                        <div id="timeUpModal-describedby" class="visible-hidden">Time’s Up! Please wait. Redirecting in 3s.</div>

                        <p class="marginBottom5">
                            <b>Time’s Up!</b>
                        </p>

                        <p class="marginBottom30">Please wait.</p>

                        <div class="flexOnly justifyCenter marginBottom10">
                            <div class="modal-countdown-number marginLeft10" />
                            <p class="loadingAnimation" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="test_confirm_modal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-describedby="test_confirm_modal-describedby">
            <div class="modal-dialog">
                <div class="modal-content blue-modal-new">
                    <div class="modal-body textAlignCenter">
                        <img src="/img/submit_alert.png" alt="" class="marginBottom20" width="30%" />

                        <div id="test_confirm_modal-describedby" class="visible-hidden">
                            You will not be able to change your answers after submitting. This is final. Do you want to submit?
                        </div>

                        <p class="marginBottom5">You will not be able to change your answers after submitting. This is <b>final.</b></p>

                        <p class="marginBottom30">Do you want to submit?</p>

                        <div class="flex marginTop30">
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                            <button class="btn btn-success mainBtn" aria-label="Submit Test" data-dismiss="modal" @click.prevent="submitTest()">
                                Submit Test
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="redirectingModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-describedby="redirectingModal-describedby">
            <div class="modal-dialog">
                <div class="modal-content blue-modal-new">
                    <div class="modal-body textAlignCenter">
                        <img src="/img/redirecting.png" alt="" class="marginBottom20" width="30%" />

                        <div id="redirectingModal-describedby" class="visible-hidden">Submission Successful Redirecting in 3 seconds</div>

                        <h3 class="text-success">Submission Successful</h3>
                        <div class="flexOnly justifyCenter marginBottom10">
                            <div class="modal-countdown-number marginLeft10" />
                            <p class="loadingAnimation" />
                        </div>
                        <p>
                            <router-link :to="{ name: 'tests.index' }" tag="button" class="btn btn-primary"> Return to Dashboard </router-link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import KrAuth from '../../../../components/auth/auth';
import { testObj as tObj } from './testObj';
export default {
    data() {
        let auth = new KrAuth();
        return {
            auth: auth,
            component_done_loading: false,
            test: {},
            display_test_proper: false,
            is_timer_shown: false,
            accessibility: {
                testSubmissionBtnOption: false,
            },
            componentElement: '',
            sideBarClosed: false,
            sideBarElement: '',
        };
    },
    mounted() {
        var that = this;
        if (['tests.proper', 'tests.preview.rat'].includes(this.$route.name)) {
            Events.listen('trigger-test-restart', function (o) {
                that.fetchTest().then(function (response) {
                    that.test = response.data.data;
                    //Vue.set(that, 'test', response.data.data);
                    Events.fire('test-restarted');
                });
            });
            this.fetchTest().then(function (response) {
                console.log(response);
                that.test = response.data.data;
                if (!that.test.questions) {
                    that.test.questions = [];
                }
                //tag test as preview
                if (that.$route.name == 'tests.preview.rat') {
                    that.test._isPreview = true;
                }
                if (
                    ['Lapsed', 'Expired'].includes(that.auth.user().status) &&
                    that.auth.user().account.paymentMethod == 'Student-Paid' &&
                    !that.isTestCompleted(that.test)
                ) {
                    that.$router.push({ name: 'errors.403' });
                }
                that.processEchoListener();
                that.component_done_loading = true;
                /*if(that.test.presentationMode){
						that.$notify({
		                    group: 'form',
		                    type:'success',
		                    title: 'Success',
		                    text: 'You will be redirected to discussion screen'
		                });
						that.$router.push({name:'tests.discussion',params:{id:that.$route.params.id}});
					}*/

                var toUpperCase = that.test.type.toUpperCase();
                document.title = `${that.test.name} | ${toUpperCase} | InteDashboard | TBL Makes Teams Work`;
            });
        } else if (this.$route.name == 'teachers.tests.preview.rat') {
            this.fetchActivity().then(function (response) {
                that.test = response.data.data;
                that.test._isPreview = true;
                that.test._isTeacherPreview = true;
                that.test.status = 'ongoing';
                that.test.myProgression = { userTestStatus: 'started' };
                if (that.test.type == 'iratntrat') {
                    if (that.$route.query.type) {
                        that.processPreviewTestType(that.$route.query.type);
                    } else {
                        that.openPreviewChooseTestTypeModal();
                    }
                    return;
                } else if (that.test.type == 'irat') {
                    that.test.settings = that.test.settings.irat;
                    that.component_done_loading = true;
                } else if (that.test.type == 'trat') {
                    that.test.settings = that.test.settings.trat;
                    that.component_done_loading = true;
                } else if (that.test.type == 'application') {
                    that.test.settings = that.test.settings.application;
                    that.component_done_loading = true;
                }
                if (['irat', 'trat', 'application']) {
                    if (!_.isArray(_.get(that.test, 'settings.attachments'))) {
                        if (!that.test.settings) {
                            that.test.settings = {};
                        }
                        that.test.settings.hasTimeLimit = false;
                        that.test.settings.attachments = [];
                    }
                }

                var toUpperCase = that.test.type.toUpperCase();
                document.title = `${that.test.name} | ${toUpperCase} | InteDashboard | TBL Makes Teams Work`;
            });
        }

        $('.test > .left_col').addClass('d-none');
    },
    updated() {
        var that = this;
        this.$nextTick(() => {
            if (that.$refs.topbarRef) {
                that.componentElement = that.$refs.topbarRef.$el;
            }
            if (that.$refs.sideBarRef) {
                that.sideBarElement = this.$refs.sideBarRef.$el;
            }
        });
    },
    created() {
        var that = this;
        $('body').addClass('test').removeClass('nav-sm').addClass('nav-none');

        Events.listen('submit-test', function (data) {
            $('#test_confirm_modal').modal('show');
        });

        window.addEventListener('resize', this.handleResize);
    },
    methods: {
        processEchoListener() {
            var that = this;

            let h1 = (e) => {
                if (e.test.uuid != that.test.testUuid) {
                    return false;
                }
                if (that.test.settings.type == 'synchronous') {
                    that.test.myProgression.startTime = e.test.settings.startTime;
                    that.test.myProgression.endTime = e.test.settings.endTime;
                } else {
                    that.test.myProgression.startTime = e.test.settings.startTime;
                    that.test.myProgression.endTime =
                        moment
                            .utc(that.test.myProgression.endTime, 'YYYY-MM-DDThh:mm:ssZ')
                            .add(e.test.settings.diffInSeconds, 'seconds')
                            .format('YYYY-MM-DDTHH:mm:ss') + 'Z';
                    //asynchronous case here
                }
                let old_status = that.test.status;
                if (!(['not started'].includes(old_status) && e.test.status == 'ongoing')) {
                    that.test.status = e.test.status;
                }
                if (!['not started', 'scheduled'].includes(that.test.status)) {
                    if (that.test._isPreview) {
                        that.$notify({
                            group: 'form',
                            type: 'success',
                            title: 'Success',
                            text: 'This test has started. Redirecting you back to test listings',
                        });
                        that.$router.push({ name: 'tests.index' });
                    }
                }
                if (that.test.status == 'completed' && !this.isTimerExtended) {
                    /*if(window.bugsnagClient!=null){
        					window.bugsnagClient.leaveBreadcrumb('TEST:timeout',{test:this.test,winterbreak:message.data});
                         //submited the test
        				}*/
                    //submited the test

                    // that.$notify({
                    //   group: "form",
                    //   type: "success",
                    //   title: "Success",
                    //   text: "Time's up! Pass your papers!",
                    // });
                    // if (
                    //   that.test.type == "trat" &&
                    //   that.test.settings.allowTeamClarifications
                    // ) {
                    //   that.$router.push({
                    //     name: "tests.clarification",
                    //     params: { id: that.test.uuid },
                    //   });
                    // } else {
                    //   that.$router.push({ name: "tests.index" });
                    // }

                    $('#timeUpModal').modal('show');

                    window.setTimeout(function () {
                        $('#timeUpModal').modal('hide');

                        that.$notify({
                            group: 'form',
                            type: 'success',
                            title: 'Success',
                            text: "Time's up! Pass your papers!",
                        });
                        if (that.test.type == 'trat' && that.test.settings.allowTeamClarifications) {
                            that.$router.push({
                                name: 'tests.clarification',
                                params: { id: that.test.uuid },
                            });
                        } else {
                            that.$router.push({ name: 'tests.index' });
                        }
                    }, 3000);
                }
                if (that.test.status == 'ended') {
                    if (that.test.type == 'irat' || (that.test.type == 'application' && _.get(that.test, 'others.applicationType') != 'team')) {
                        that.test.myProgression.userTestStatus = 'incomplete';
                        /*Vue.set(
                            that.test.myProgression,
                            'userTestStatus',
                            'incomplete'
                        );*/
                    }
                    $('#teacherEndedModal').modal('show');

                    window.setTimeout(function () {
                        $('#teacherEndedModal').modal('hide');

                        that.$notify({
                            group: 'form',
                            type: 'success',
                            title: 'Success',
                            text: 'Teacher has ended the test',
                        });

                        if (that.test.type == 'trat' && that.test.settings.allowTeamClarifications) {
                            that.$router.push({
                                name: 'tests.clarification',
                                params: { id: that.test.uuid },
                            });
                        } else {
                            that.$router.push({ name: 'tests.index' });
                        }
                    }, 3000);
                }
                if (['ended', 'completed'].includes(old_status) && that.test.status == 'ongoing') {
                    Events.fire('trigger-test-restart');
                }
                if (['not started'].includes(old_status) && e.test.status == 'ongoing') {
                    that.fetchTest().then(function (response) {
                        that.test.questions = response.data.data.questions;
                        /*Vue.set(
                            that.test,
                            'questions',
                            response.data.data.questions
                        );*/
                        Vue.nextTick(function () {
                            that.test.status = 'ongoing';
                        });
                    });
                }
            };
            let c1 = window.Echo.private(`activity.${that.test.activityUuid}.student`).listen('TestStatusUpdated', h1);
            this.echoChannels.push({
                channel: c1,
                event: 'TestStatusUpdated',
                handler: h1,
            });

            let h2 = (e) => {
                /*if(that.test.type=='irat'&&that.test.settings.type=='synchronous'){
	            		return;
	            	}*/
                let old_status = that.test.myProgression.userTestStatus;
                let justEnteredPassword = that.test.myProgression._justEnteredPassword;
                let students = _.get(that.test, 'myProgression.team.students', []);
                that.test.myProgression = e.userPlacementTests.myProgression;
                /*Vue.set(
                    that.test,
                    'myProgression',
                    e.userPlacementTests.myProgression
                );*/
                if (that.test.myProgression.team) {
                    that.test.myProgression.team.students = students;
                }
                that.test.myProgression._justEnteredPassword = justEnteredPassword;
                if (old_status != that.test.myProgression.userTestStatus && !that.test._isSubmitting) {
                    if (that.test.myProgression.userTestStatus == 'finished') {
                        // that.$notify({
                        //   group: "form",
                        //   type: "success",
                        //   title: "Success",
                        //   text: "Time's up! Pass your papers!",
                        // });
                        // if (
                        //   that.test.type == "trat" &&
                        //   that.test.settings.allowTeamClarifications
                        // ) {
                        //   that.$router.push({
                        //     name: "tests.clarification",
                        //     params: { id: that.test.uuid },
                        //   });
                        // } else {
                        //   that.$router.push({ name: "tests.index" });
                        // }

                        $('#timeUpModal').modal('show');

                        window.setTimeout(function () {
                            $('#timeUpModal').modal('hide');

                            that.$notify({
                                group: 'form',
                                type: 'success',
                                title: 'Success',
                                text: "Time's up! Pass your papers!",
                            });
                            if (that.test.type == 'trat' && that.test.settings.allowTeamClarifications) {
                                that.$router.push({
                                    name: 'tests.clarification',
                                    params: { id: that.test.uuid },
                                });
                            } else {
                                that.$router.push({ name: 'tests.index' });
                            }
                        }, 3000);
                    }
                    if (that.test.myProgression.userTestStatus == 'incomplete') {
                        $('#teacherEndedModal').modal('show');

                        window.setTimeout(function () {
                            $('#teacherEndedModal').modal('hide');

                            that.$notify({
                                group: 'form',
                                type: 'success',
                                title: 'Success',
                                text: 'Teacher has ended the test',
                            });

                            if (that.test.type == 'trat' && that.test.settings.allowTeamClarifications) {
                                that.$router.push({
                                    name: 'tests.clarification',
                                    params: { id: that.test.uuid },
                                });
                            } else {
                                that.$router.push({ name: 'tests.index' });
                            }
                        }, 10000);
                    }
                }
            };
            if (this.test.type == 'trat' || (this.test.type == 'application' && this.test.others.applicationType == 'team')) {
                let c2 = window.Echo.join(`test.${this.test.testUuid}.team.${this.test.teamUuid}`).listen('StudentTestStatusUpdated', h2);
                this.echoChannels.push({
                    channel: c2,
                    event: 'StudentTestStatusUpdated',
                    handler: h2,
                });
                let h6 = (e) => {
                    this.test.myProgression.enteredPassword = 'entered';
                    /*Vue.set(
                        this.test.myProgression,
                        'enteredPassword',
                        'entered'
                    );*/
                };
                let c6 = window.Echo.join(`test.${this.test.testUuid}.team.${this.test.teamUuid}`).listen('ActivityPasswordEntered', h6);
                this.echoChannels.push({
                    channel: c6,
                    event: 'ActivityPasswordEntered',
                    handler: h6,
                });
                let h7 = (e) => {
                    $('#clearAnswersModal').modal('show');
                    Events.fire('trigger-test-restart');
                };
                let c7 = window.Echo.join(`test.${this.test.testUuid}.team.${this.test.teamUuid}`).listen('TeamResetAnswers', h7);
                this.echoChannels.push({
                    channel: c7,
                    event: 'TeamResetAnswers',
                    handler: h7,
                });
            } else {
                let c2 = window.Echo.private(`placementtest.${this.test.uuid}`).listen('StudentTestStatusUpdated', h2);
                this.echoChannels.push({
                    channel: c2,
                    event: 'StudentTestStatusUpdated',
                    handler: h2,
                });
                let h6 = (e) => {
                    this.test.myProgression.enteredPassword = 'entered';
                    /*Vue.set(
                        this.test.myProgression,
                        'enteredPassword',
                        'entered'
                    );*/
                };
                let c6 = window.Echo.private(`placementtest.${this.test.uuid}`).listen('ActivityPasswordEntered', h6);
                this.echoChannels.push({
                    channel: c6,
                    event: 'ActivityPasswordEntered',
                    handler: h6,
                });

                let h7 = (e) => {
                    $('#clearAnswersModal').modal('show');
                    Events.fire('trigger-test-restart');
                };
                let c7 = window.Echo.private(`placementtest.${this.test.uuid}`).listen('StudentResetAnswers', h7);
                this.echoChannels.push({
                    channel: c7,
                    event: 'StudentResetAnswers',
                    handler: h7,
                });
                let h8 = (e) => {
                    this.test.extraTimeValues = e.extraTimeValues;
                    this.test.expectedEndingTime = e.expectedEndingTime;
                    this.test.myProgression.userTestStatus = e.userPlacementTestStatus;
                    /*Vue.set(this.test, 'extraTimeValues', e.extraTimeValues);
                    Vue.set(
                        this.test.extraTimeValues,
                        'extraTimeValues',
                        e.extraTimeValues
                    );
                    Vue.set(
                        this.test,
                        'expectedEndingTime',
                        e.expectedEndingTime
                    );
                    Vue.set(
                        this.test.myProgression,
                        'userTestStatus',
                        e.userPlacementTestStatus
                    );*/

                    if (this.test.extraTimeValues != null && $('.alert').length == 0) {
                        $('#insert-alert').append(
                            '<div class="alert alert-warning marginBottom30 flexSpaceBetween" role="alert" aria-live="assertive" aria-atomic="true"> Instructor has added extra ' +
                                this.extendedTime +
                                ' to the activity <button type="button" class="close padding0" data-dismiss="alert" aria-label="Close"> <i class="fa-solid fa-xmark fontSize18 fontBlack" aria-hidden="true" /></button></div>'
                        );
                    }
                };
                let c8 = window.Echo.private(`placementtest.${this.test.uuid}`).listen('StudentTimeExtended', h8);
                this.echoChannels.push({
                    channel: c8,
                    event: 'StudentTimeExtended',
                    handler: h8,
                });
                let h9 = (e) => {
                    this.test.myProgression.userTestStatus = e.userPlacementTestStatus;
                    /*Vue.set(
                        this.test.myProgression,
                        'userTestStatus',
                        e.userPlacementTestStatus
                    );*/
                    $('#timeUpModal').modal('show');

                    window.setTimeout(function () {
                        $('#timeUpModal').modal('hide');

                        that.$notify({
                            group: 'form',
                            type: 'success',
                            title: 'Success',
                            text: "Time's up! Pass your papers!",
                        });
                        if (that.test.type == 'trat' && that.test.settings.allowTeamClarifications) {
                            that.$router.push({
                                name: 'tests.clarification',
                                params: { id: that.test.uuid },
                            });
                        } else {
                            that.$router.push({ name: 'tests.index' });
                        }
                    }, 3000);
                };
                let c9 = window.Echo.private(`placementtest.${this.test.uuid}`).listen('StudentTestEndedWithExtraTime', h9);
                this.echoChannels.push({
                    channel: c9,
                    event: 'StudentTestEndedWithExtraTime',
                    handler: h9,
                });

                let h10 = (e) => {
                    if (!this.test.canEarlyStart) {
                        return;
                    }
                    Events.fire('trigger-test-restart');
                };
                let c10 = window.Echo.private(`test.${this.test.testUuid}.student`).listen('EarlyTestStarted', h10);
                this.echoChannels.push({
                    channel: c10,
                    event: 'EarlyTestStarted',
                    handler: h10,
                });
                let h11 = (e) => {
                    if (!this.test.canEarlyStart) {
                        return;
                    }
                    $('#teacherEndedModal').modal('show');
                    window.setTimeout(function () {
                        $('#teacherEndedModal').modal('hide');
                        that.$notify({
                            group: 'form',
                            type: 'success',
                            title: 'Success',
                            text: 'Teacher has ended the test',
                        });
                        that.$router.push({ name: 'tests.index' });
                    }, 3000);
                };
                let c11 = window.Echo.private(`test.${this.test.testUuid}.student`).listen('EarlyTestEnded', h11);
                this.echoChannels.push({
                    channel: c11,
                    event: 'EarlyTestEnded',
                    handler: h11,
                });
            }
            let h3 = (e) => {
                if (e.testUuids.includes(that.test.testUuid)) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Teacher has unpublished this activity.',
                    });
                    that.$router.push({ name: 'tests.index' });
                }
            };
            let c3 = window.Echo.private(`activity.${that.test.activityUuid}.student`).listen('ActivityUnpublished', h3);
            this.echoChannels.push({
                channel: c3,
                event: 'ActivityUnpublished',
                handler: h3,
            });

            let h4 = (e) => {
                if (e.test.uuid != that.test.testUuid) {
                    return false;
                }
                let allowStudentsToViewAttachments = that.test.allowStudentsToViewAttachments;
                that.test.allowStudentsToViewAttachments = e.test.allowStudentsToViewAttachments;
                if (allowStudentsToViewAttachments != that.test.allowStudentsToViewAttachments) {
                    Events.fire('trigger-test-restart');
                }
                /*if (
          !e.test.allowStudentsToPreviewQuestions &&
          !that.test._isPreview &&
          !that.test._isTeacherPreview
        ) {
          that.$notify({
            group: "form",
            type: "success",
            title: "Success",
            text: "Teacher has disabled viewing of test questions.",
          });
          that.$router.push({ name: "tests.index" });
        }*/
            };
            let c4 = window.Echo.private(`activity.${that.test.activityUuid}.student`).listen('TestVisibilityUpdated', h4);
            this.echoChannels.push({
                channel: c4,
                event: 'TestVisibilityUpdated',
                handler: h4,
            });

            let h5 = (e) => {
                this.test.clarificationStatus = e.clarificationStatus;
                if (this.test.clarificationStatus == 'released') {
                    var that = this;
                    this.fetchTest().then(function (response) {
                        let test = response.data.data;
                        for (var i = 0; i < that.test.questions.length; i++) {
                            for (var j = 0; j < that.test.questions[i].length; j++) {
                                that.test.questions[i][j].isAssignedToMe = test.questions[i][j].isAssignedToMe;
                                /*Vue.set(
                                    that.test.questions[i][j],
                                    'isAssignedToMe',
                                    test.questions[i][j].isAssignedToMe
                                );*/
                            }
                        }
                    });
                }
            };
            let c5 = window.Echo.private(`test.${this.test.testUuid}.student`).listen('TestClarificationStatusUpdated', h5);
            this.echoChannels.push({
                channel: c5,
                event: 'TestClarificationStatusUpdated',
                handler: h5,
            });

            let h6 = (e) => {
                if (e.testUuids.includes(that.test.testUuid)) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Teacher has deleted this activity.',
                    });
                    that.$router.push({ name: 'tests.index' });
                }
            };
            let c6 = window.Echo.private(`activity.${that.test.activityUuid}.student`).listen('ActivityDeleted', h6);
            this.echoChannels.push({
                channel: c6,
                event: 'ActivityDeleted',
                handler: h6,
            });
        },
        isTestCompleted(test) {
            if (
                (['completed', 'ended'].includes(test.status) && !this.isTimerExtended) ||
                ['finished', 'incomplete'].includes(test.myProgression.userTestStatus)
            ) {
                return true;
            }
            return false;
        },
        submitConfirmAccessibility(event) {
            var that = this;
            var test_submit_modal_buttons = $('#test_confirm_modal .buttonGroup button');
            switch (event.srcKey) {
                case 'test':
                    $('#test_confirm_modal').modal('toggle');
                    break;
                case 'left':
                case 'right':
                    if (!($('#test_confirm_modal').data('bs.modal') || {}).isShown) {
                        return;
                    }
                    let bias = 0;
                    if (that.accessibility.testSubmissionBtnOption === false) {
                        that.accessibility.testSubmissionBtnOption = 0;
                    } else if (event.srcKey == 'left') {
                        bias = -1;
                    } else if (event.srcKey == 'right') {
                        bias = 1;
                    }
                    that.accessibility.testSubmissionBtnOption += bias;
                    for (var i = 0; i < test_submit_modal_buttons.length; i++) {
                        if (i === Math.abs(that.accessibility.testSubmissionBtnOption % test_submit_modal_buttons.length)) {
                            $(test_submit_modal_buttons[i]).addClass('selected');
                        } else {
                            $(test_submit_modal_buttons[i]).removeClass('selected');
                        }
                    }
                    break;
                case 'enter':
                    if (!($('#test_confirm_modal').data('bs.modal') || {}).isShown) {
                        return;
                    }
                    for (var i = 0; i < test_submit_modal_buttons.length; i++) {
                        if ($(test_submit_modal_buttons[i]).hasClass('selected')) {
                            $(test_submit_modal_buttons[i]).click();
                        }
                    }
                    break;
            }
        },
        openPreviewChooseTestTypeModal() {
            $('#previewChooseTestTypeModal').modal('show');
        },
        processPreviewTestType(type) {
            if (type == 'irat') {
                this.test.type = 'irat';
                this.test.settings = this.test.settings.irat;
            }
            if (type == 'trat') {
                this.test.type = 'trat';
                this.test.settings = this.test.settings.trat;
            }

            if (!_.isArray(_.get(this.test, 'settings.attachments'))) {
                if (!this.test.settings) {
                    this.test.settings = {};
                }
                this.test.settings.hasTimeLimit = false;
                this.test.settings.attachments = [];
            }
            this.component_done_loading = true;
        },
        processTimerShown(isShown) {
            this.is_timer_shown = isShown;
        },
        submitTest() {
            var that = this;
            //var instance = axios.create({baseURL:''});
            //instance.post(process.env.VUE_APP_LOG_URL,{cluster:process.env.VUE_APP_APP_ENV,label:'submitTest',data:{userUuid:this.auth.user().uuid,testUuid:this.test.testUuid,userPlacementTestUuid:this.test.uuid}});
            $('#redirectingModal').modal('show');
            that.test._isSubmitting = true;
            axios.put('student/tests/' + that.test.uuid + '/submit').then((response) => {
                window.setTimeout(function () {
                    $('#redirectingModal').modal('hide');
                    if (that.test.type == 'trat' && _.get(that, 'test.settings.allowTeamClarifications')) {
                        that.$router.push({
                            name: 'tests.clarification',
                            params: { id: that.test.uuid },
                        });
                    } else {
                        that.$router.push({ name: 'tests.index' });
                    }
                }, 3000);
            });
        },
        processDisplayTestProper(stopDisplay) {
            if (!this.test.isPublished) {
                this.display_test_proper = true;
            } else {
                this.display_test_proper = !stopDisplay;
            }
        },
        fetchActivity() {
            return axios.get('activities/' + this.$route.params.id);
        },
        fetchTest() {
            if (this.$route.query.faker === undefined) {
                return axios.get('student/tests/' + this.$route.params.id);
            } else {
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        resolve({ data: { ...tObj } });
                    }, 300);
                });
            }
        },
        handleButtonClicked() {
            var that = this;

            $('.statusBar').toggleClass('sidebar--Collapse');
            $('.main').toggleClass('main--slide');
            $('#toggleIcon').toggleClass('rotate');
            this.sideBarClosed = !this.sideBarClosed;
            window.setTimeout(function () {
                $('.main').css('margin-left', that.sideBarElement.clientWidth + 'px');
                $('.main').css('width', 'calc(100% - ' + that.sideBarElement.clientWidth + 'px)');
            }, 500);
        },
        handleResize() {
            $('.test_content').css('padding-top', this.componentElement.offsetHeight + 'px');
            $('.main').css('margin-left', this.sideBarElement.clientWidth + 'px');
            $('.main').css('width', 'calc(100% - ' + this.sideBarElement.clientWidth + 'px)');
        },
    },
    components: {
        'stopping-div': require(`./partials/stopping-div.vue`).default,
        'test-progression': require(`./partials/progression.vue`).default,
        'test-topbar': require(`./partials/topbar.vue`).default,
        'test-sidebar': require(`./partials/sidebar.vue`).default,
        'question-groups': require(`./partials/questions/question-groups.vue`).default,
        'question-pagination-container': require(`./partials/question-pagination-container.vue`).default,
        'team-leadership': require(`./partials/team-leadership.vue`).default,
    },
    computed: {
        computedPaddingTop() {
            var that = this;
            return that.componentElement.offsetHeight + 'px';
        },
        computedMarginLeft() {
            var that = this;
            if (window.innerWidth >= 992) {
                return that.sideBarElement.clientWidth + 'px';
            }

            return false;
        },
        notMobile() {
            if (window.innerWidth >= 992) {
                return true;
            }

            return false;
        },
        isTimerExtended() {
            if (!this.test) {
                return false;
            } else {
                return _.get(this.test, 'expectedEndingTime', false);
            }
        },
        extendedTime() {
            const days = _.get(this.test, 'extraTimeValues.durationDays', 0);
            const hours = _.get(this.test, 'extraTimeValues.durationHours', 0);
            const minutes = _.get(this.test, 'extraTimeValues.durationMinutes', 0);

            const timeParts = [];

            if (days > 1) {
                timeParts.push(days + ' days');
            } else if (days == 1) {
                timeParts.push(days + ' day');
            }

            if (hours > 1) {
                timeParts.push(hours + ' hours');
            } else if (hours == 1) {
                timeParts.push(hours + ' hour');
            }

            if (minutes > 1) {
                timeParts.push(minutes + ' minutes');
            } else if (minutes == 1) {
                timeParts.push(minutes + ' minute');
            }

            return timeParts.length > 0 ? timeParts.join(' ') : '';
        },
    },
    beforeUnmount() {
        if ($(window).width() > 991) {
            $('body').removeClass('test').addClass('nav-sm').removeClass('nav-none');
        } else {
            $('body').removeClass('test').removeClass('nav-sm').addClass('nav-none');
        }
        Events.off('changed-test');
        Events.off('submit-test');
        //from mixin.
        Events.off('current-question-changed');

        window.removeEventListener('resize', this.handleResize);
    },
};
</script>
<style scoped lang="scss">
#test_confirm_modal .modal-body .message {
    text-align: center;
}
#test_confirm_modal .modal-body .message .fa {
    font-size: 40px;
    color: #e69618;
}
.individual {
    padding: 91px 25px 95px !important;
}
.group {
    padding: 91px 25px 95px !important;
}

@media (min-width: 992px) {
    .timer.individual,
    .timer.group {
        padding: 130px 25px 95px !important;
    }
}

@media (max-width: 991px) {
    .timer.individual,
    .timer.group {
        padding: 103px 25px 95px !important;
    }

    .test .right-container {
        padding: 105px 25px 73px 25px !important;
    }
}
#previewChooseTestTypeModal .modal-body .row:last-child {
    margin-top: 30px;
}

#previewChooseTestTypeModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#previewChooseTestTypeModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}

.print {
    display: none;
}
@media print {
    .print {
        display: block;
    }
}

.modal-countdown-number::after {
    content: 'Redirecting';
    width: 1ch;
    animation: countdown 3s step-end;
}
@keyframes countdown {
    /*0% {
    content: "10s";
  }
  10% {
    content: "9s";
  }
  20% {
    content: "8s";
  }
  30% {
    content: "7s";
  }
  40% {
    content: "6s";
  }
  0% {
    content: "5s";
  }
  20% {
    content: "4s";
  }*/
    0% {
        content: 'Redirecting in 3s';
    }
    33% {
        content: 'Redirecting in 2s';
    }
    67% {
        content: 'Redirecting in 1s';
    }
    100% {
        content: '0';
    }
}

.new-side-bar {
    display: flex;

    .main {
        -moz-transition: margin-left 0.55s ease-out;
        -o-transition: margin-left 0.55s ease-out;
        -webkit-transition: margin-left 0.55s ease-out;
        transition: margin-left 0.55s ease-out;
    }

    .main--slide {
        -moz-transition: margin-left 0.6s ease-out;
        -o-transition: margin-left 0.6s ease-out;
        -webkit-transition: margin-left 0.6s ease-out;
        transition: margin-left 0.6s ease-out;
    }
}

@media (max-width: 991px) {
    .new-side-bar {
        .main,
        .main--slide {
            margin-left: 0;
            width: 100%;
        }
    }
}
</style>
