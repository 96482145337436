<template>
    <div v-if="component_done_loading" class="col-xs-12 right-container">
        <div class="col-md-12 form-div">
            <kr-panel :with-footer="false">
                <template #title>
                    <div class="col-xs-6">EDIT COURSE</div>
                    <!--<div class="col-xs-6 flexRight">
						<div class="hidden-xs hidden-sm">
                            <button class="btn btn-success mainBtn" :disabled="(!canSend) ? true:undefined"  @click="submit()">
				               <i class="fa fa-check"></i>&nbsp; Update
				            </button>
				        </div>
				        <div class="hidden-md hidden-lg">
                            <button class="btn btn-success mainBtn" :disabled="(!canSend) ? true:undefined" @click="submit()">
				                <i class="fa fa-floppy-o"></i>
				            </button>
				        </div>
			        </div>-->
                </template>
                <template #content>
                    <div class="col-xs-12 col-md-6">
                        <course-form :form="form"></course-form>
                        <div class="buttonGroup">
                            <button class="btn btn-success mainBtn" :disabled="!canSend ? true : undefined" aria-label="Update" @click="submit()">
                                <i class="fa fa-check marginRight8" aria-hidden="true"></i>Update
                            </button>
                            <router-link
                                :to="{
                                    name: 'courses.view',
                                    params: { id: $route.params.id },
                                    query: { tab: 'overview' },
                                }"
                                tag="button"
                                class="btn btn-default mainBtn"
                                exact
                                aria-label="Cancel"
                            >
                                Cancel
                            </router-link>
                        </div>
                    </div>
                </template>
            </kr-panel>
        </div>
    </div>
</template>

<script>
import KrForm from '../../../components/forms/form';
import KrAuth from '../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            component_done_loading: false,
            form: new KrForm({
                fields: {
                    name: '',
                    code: '',
                    description: '',
                    descriptionIsHTML: '',
                    startDate: '',
                    endDate: '',
                    objectives: 'type!:array',
                    coverImage: '',
                },
                props: {},
                urls: {
                    put: 'courses/' + this.$route.params.id,
                },
            }),
        };
    },
    components: {
        'course-form': require(`./form-partials/partial.course.vue`).default,
    },
    computed: {
        canSend() {
            if (this.form.model.name && this.form.model.startDate && this.form.model.endDate) {
                return true;
            }
            return false;
        },
    },
    created() {
        var that = this;
        Events.fire('topbar_update', {
            title: '<i class="fas fa-graduation-cap"></i>&nbsp;Courses',
            breadcrumb: [{ name: 'Home' }, { name: 'Courses', link: 'courses.index' }, { name: 'Edit Course' }],
        });
        axios.get('/courses/' + this.$route.params.id).then(function (response) {
            that.form.setModel(response.data.data);
            if (!that.form.model.objectives) {
                that.form.model.objectives = [];
            }
            that.form.model.descriptionIsHTML = false;
            that.component_done_loading = true;
        });
    },
    methods: {
        submit() {
            var that = this;
            this.form.put().then(function (response) {
                if (!response.krFormError) {
                    that.$router.push({
                        name: 'courses.view',
                        params: { id: response.data.data.uuid },
                        query: { tab: 'overview' },
                    });
                }
            });
        },
    },
};
</script>
