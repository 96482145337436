<template>
    <div v-if="component_done_loading">
        <Teleport v-if="testObj.isPublished" to="#dashboard-top-nav">
            <div class="dashboard-tab">
                <button class="btn btn-default marginRight20" tabindex="-1" :disabled="canScrollLeft ? true : undefined" @click="tabLeft">
                    <i class="fa fa-caret-left" aria-hidden="true" />
                </button>

                <div class="dashboard-activities-nav">
                    <ul ref="dashboardNavUL">
                        <template v-for="(activity, activity_idx) in retrieveModulesData" :key="'activity' + activity_idx">
                            <template v-if="activity.tests.length === 0">
                                <li
                                    :class="{
                                        active: testObj.activityUuid == activity.uuid,
                                    }"
                                >
                                    <router-link
                                        v-tooltip="{
                                            content: activity.name,
                                            placement: 'right',
                                        }"
                                        :to="{
                                            name: 'courses.modules.activities.continue',
                                            params: {
                                                id: activity.uuid,
                                            },
                                        }"
                                        :aria-label="activity.type"
                                    >
                                        <template v-if="activity.type == 'prework'"> Prework </template>
                                        <template v-if="activity.type == 'irat'"> IRAT </template>
                                        <template v-if="activity.type == 'trat'"> TRAT </template>
                                        <template v-if="activity.type == 'iratntrat'"> IRAT & TRAT </template>
                                        <template v-if="activity.type == 'application'"> App. Case </template>
                                        <template v-if="activity.type == 'evaluation'"> 360 Degree Eval. </template>
                                        <template v-if="activity.type == 'michaelsenEvaluation'"> Peer Eval. </template>
                                    </router-link>
                                </li>
                            </template>

                            <template v-else>
                                <template v-for="(test, test_idx) in activity.tests" :key="test.name + '_' + test.uuid">
                                    <li
                                        :class="{
                                            active: testObj.uuid == test.uuid,
                                        }"
                                    >
                                        <router-link
                                            v-tooltip="{
                                                content: test.name,
                                                placement: 'right',
                                            }"
                                            :to="{
                                                name: 'activities.tests.dashboard',
                                                params: {
                                                    id: test.uuid,
                                                },
                                            }"
                                            :aria-label="test.type"
                                        >
                                            <span class="test_type">
                                                <template v-if="test.type == 'prework'"> Prework </template>
                                                <template v-if="test.type == 'irat'"> IRAT </template>
                                                <template v-if="test.type == 'trat'"> TRAT </template>
                                                <template v-if="test.type == 'iratntrat'"> TRAT & TRAT </template>
                                                <template v-if="test.type == 'application'"> App. Case </template>
                                                <template v-if="test.type == 'evaluation'"> 360 Degree Eval. </template>
                                                <template v-if="test.type == 'michaelsenEvaluation'"> Peer Eval. </template>
                                            </span>

                                            <!-- <span class="test_name">{{ test.name }} </span> -->
                                        </router-link>
                                    </li>
                                </template>
                            </template>
                        </template>
                    </ul>
                </div>

                <button class="btn btn-default" tabindex="-1" :disabled="canScrollRight ? true : undefined" @click="tabRight">
                    <i class="fa fa-caret-right" aria-hidden="true" />
                </button>
            </div>

            <!-- <div class="dashboard-nav width100">
        <ul class=" nav nav-pills marginBottom10">
          <template v-for="(activity, activity_idx) in retrieveModulesData">
            <template v-if="activity.tests.length === 0">
              <li class="nav-item" :key="'activity' + activity_idx">
                <router-link
                  :class="{ active: testObj.activityUuid == activity.uuid }"
                  class="nav-link"
                  :to="{
                    name: 'courses.modules.activities.continue',
                    params: {
                      id: activity.uuid,
                    },
                  }"
                  :aria-label="activity.type"
                >
                  <template v-if="activity.type == 'prework'">
                    Prework
                  </template>
                  <template v-if="activity.type == 'irat'">
                    IRAT
                  </template>
                  <template v-if="activity.type == 'trat'">
                    TRAT
                  </template>
                  <template v-if="activity.type == 'iratntrat'">
                    IRAT & TRAT
                  </template>
                  <template v-if="activity.type == 'application'">
                    App. Case
                  </template>
                  <template v-if="activity.type == 'evaluation'">
                    360 Degree Eval.
                  </template>
                  <template v-if="activity.type == 'michaelsenEvaluation'">
                    Peer Eval.
                  </template>

                  <span class="activity_name"
                    >&nbsp;- {{ activity.name }}
                  </span>
                </router-link>
              </li>
            </template>

            <template v-else>
              <template v-for="(test, test_idx) in activity.tests">
                <li class="nav-item" :key="test.name + '_' + test_idx">
                  <router-link
                    :class="{ active: testObj.uuid == test.uuid }"
                    class="nav-link"
                    :to="{
                      name: 'activities.tests.dashboard',
                      params: {
                        id: test.uuid,
                      },
                    }"
                    :aria-label="test.type"
                  >
                    <template v-if="test.type == 'prework'">
                      Prework
                    </template>
                    <template v-if="test.type == 'irat'">
                      IRAT
                    </template>
                    <template v-if="test.type == 'trat'">
                      TRAT
                    </template>
                    <template v-if="test.type == 'iratntrat'">
                      TRAT & TRAT
                    </template>
                    <template v-if="test.type == 'application'">
                      App. Case
                    </template>
                    <template v-if="test.type == 'evaluation'">
                      360 Degree Eval.
                    </template>
                    <template v-if="test.type == 'michaelsenEvaluation'">
                      Peer Eval.
                    </template>

                    <span class="test_name">&nbsp;- {{ test.name }} </span>
                  </router-link>
                </li>
              </template>
            </template>
          </template>
        </ul>

        <div v-if="numberOfDrops != 0" class="click" @click="toggleClick">
          More...
        </div>

        <div v-if="isNavDropDown" class="emptyNavDropdown">
          <ul></ul>
        </div>
      </div> -->
        </Teleport>

        <kr-panel :with-footer="false" :options="{ canToggle: false }">
            <template #title>
                <div class="col-xs-12 hidden-xs hidden-sm flexSpaceBetween align-items" style="padding-top: -218px">
                    <div class="flex align-items">
                        <div class="borderBlack marginRight20">
                            <template v-if="testObj.type == 'prework'"> Prework </template>
                            <template v-if="testObj.type == 'irat'"> IRAT </template>
                            <template v-if="testObj.type == 'trat'"> TRAT </template>
                            <template v-if="testObj.type == 'application'"> Application </template>
                            <template v-if="testObj.type == 'evaluation'"> 360 Degree Evaluation </template>
                            <template v-if="testObj.type == 'michaelsenEvaluation'"> Peer Evaluation: Michaelsen’s Method </template>
                        </div>
                        <div class="activityName">
                            {{ testObj.name }}
                        </div>
                    </div>
                    <div class="flex align-items">
                        <!-- <div class="form-dropdown minWidth0 width100px">
			                <select class="form-control padding6px12px subBtn">
						     	<option value="0">Section 1</option>
						     	<option value="1">Section 2</option>
						     </select>
			            </div> -->
                        <div class="borderGrey marginLeft10 capitalize">
                            {{ testObj.settings.type }}
                        </div>
                        <div class="marginLeft10">
                            <test-status :test="testObj" />
                            <!--<template v-if="testObj.status == 'not started'">
                <div class="statusTag statusTagDanger subBtn">
                  Not Started
                </div>
              </template>
              <template v-else-if="testObj.status == 'paused'">
                <div class="statusTag statusTagWarning subBtn">
                  Paused
                </div>
              </template>
              <template v-else-if="testObj.status == 'ongoing'">
                <div class="statusTag statusTagWarning subBtn">
                  Ongoing
                </div>
              </template>
              <template
                v-else-if="['ended', 'completed'].includes(testObj.status)"
              >
                <div class="statusTag statusTagDanger subBtn">
                  Ended
                </div>
              </template>
            </div>
            <div class="marginLeft10" v-else>
              <template v-if="testObj.status == 'scheduled'">
                <div class="statusTag statusTagDanger subBtn">
                  Scheduled
                </div>
              </template>
              <template v-else-if="testObj.status == 'ongoing'">
                <div class="statusTag statusTagWarning subBtn">
                  Ongoing
                </div>
              </template>
              <template
                v-else-if="['ended', 'completed'].includes(testObj.status)"
              >
                <div class="statusTag statusTagDanger subBtn">
                  Ended
                </div>
              </template>-->
                        </div>
                        <div v-if="testObj.isPublished" class="outlineSuccess marginLeft10">
                            <i class="fas fa-check-circle marginRight8" aria-hidden="true" />Shown to students
                        </div>
                        <div v-else class="outlineGrey marginLeft10"><i class="fas fa-times-circle marginRight8" aria-hidden="true" />Hidden to students</div>
                    </div>
                </div>
                <div class="col-xs-12 hidden-md hidden-lg flexSpaceBetween align-items">
                    <div class="flex align-items">
                        <div class="borderBlack marginRight10">
                            <template v-if="testObj.type == 'prework'"> Prework </template>
                            <template v-if="testObj.type == 'irat'"> IRAT </template>
                            <template v-if="testObj.type == 'trat'"> TRAT </template>
                            <template v-if="testObj.type == 'application'"> Application </template>
                            <template v-if="testObj.type == 'evaluation'"> 360 Degree Evaluation </template>
                            <template v-if="testObj.type == 'michaelsenEvaluation'"> Peer Evaluation: Michaelsen’s Method </template>
                        </div>
                        <div class="activityName">
                            {{ testObj.name }}
                        </div>
                    </div>
                    <test-status :test="testObj" />
                    <!--<div v-if="testObj.settings.type == 'synchronous'">
            <template v-if="testObj.status == 'not started'">
              <div class="statusTag statusTagDanger subBtn">
                Not Started
              </div>
            </template>
            <template v-else-if="testObj.status == 'paused'">
              <div class="statusTag statusTagWarning subBtn">
                Paused
              </div>
            </template>
            <template v-else-if="testObj.status == 'ongoing'">
              <div class="statusTag statusTagWarning subBtn">
                Ongoing
              </div>
            </template>
            <template
              v-else-if="['ended', 'completed'].includes(testObj.status)"
            >
              <div class="statusTag statusTagDanger subBtn">
                Ended
              </div>
            </template>
          </div>
          <div v-else>
            <template v-if="testObj.status == 'scheduled'">
              <div class="statusTag statusTagDanger subBtn">
                Scheduled
              </div>
            </template>
            <template v-else-if="testObj.status == 'ongoing'">
              <div class="statusTag statusTagWarning subBtn">
                Ongoing
              </div>
            </template>
            <template
              v-else-if="['ended', 'completed'].includes(testObj.status)"
            >
              <div class="statusTag statusTagDanger subBtn">
                Ended
              </div>
            </template>
          </div>-->
                </div>
            </template>
            <template #content>
                <template v-if="['prework', 'irat', 'trat', 'application', 'evaluation', 'michaelsenEvaluation'].includes(testObj.type)">
                    <div
                        v-if="
                            ['irat', 'trat', 'application', 'evaluation', 'michaelsenEvaluation'].includes(testObj.type) &&
                            testObj.isPublished &&
                            testObj.settings.type == 'synchronous' &&
                            testObj.settings.hasTimeLimit
                        "
                        class="row flex"
                    >
                        <div class="col-xs-12 hidden-md hidden-lg">
                            <topbar-timer
                                :test-obj="testObj"
                                :options="{
                                    showWindowLink: true,
                                    runAutoEnd: !testObj.symbolicTimer,
                                }"
                            />
                        </div>

                        <div class="hidden-md hidden-lg col-xs-12 marginBottom20">
                            <button
                                v-tooltip="{
                                    content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                }"
                                class="btn btn-default col-xs-12"
                                :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                @click="openExtendTimeModal"
                            >
                                Extend Activity Time<i class="fa fa-clock marginLeft8" aria-hidden="true" />
                            </button>
                        </div>

                        <div v-if="testObj.settings.hasTimeLimit" class="hidden-md hidden-lg col-xs-12 marginBottom20">
                            <button
                                v-tooltip="{
                                    content: (testObj.symbolicTimer ? 'Disable' : 'Enable') + ' symbolic timer',
                                }"
                                class="btn btn-default col-xs-12"
                                @click="openTimerSettingModal"
                            >
                                <i class="fas fa-cog" aria-hidden="true" />
                            </button>
                        </div>

                        <Teleport v-if="testObj.isPublished" to="#d-topbar-bottom">
                            <div
                                class="col-xs-12 padding20 flexOnly justifyCenter align-items timerTopBar"
                                :class="{
                                    'bg-light-blue': testObj.startedEarly && testObj.expectedEndingTime != null,
                                }"
                            >
                                <div>
                                    <topbar-timer :test-obj="testObj" :options="{ showWindowLink: true }" />
                                </div>
                                <button
                                    v-if="testObj.expectedEndingTime == null"
                                    v-tooltip="{
                                        content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                    }"
                                    class="btn btn-default marginLeft20"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    @click="openExtendTimeModal"
                                >
                                    Extend Activity Time<i class="fa fa-clock marginLeft8" aria-hidden="true" />
                                </button>
                                <button
                                    v-if="testObj.settings.hasTimeLimit && testObj.expectedEndingTime == null"
                                    v-tooltip="{
                                        content: (testObj.symbolicTimer ? 'Disable' : 'Enable') + ' symbolic timer',
                                    }"
                                    class="btn btn-default marginLeft20"
                                    :aria-label="(testObj.symbolicTimer ? 'Disable' : 'Enable') + ' symbolic timer Setting'"
                                    @click="openTimerSettingModal"
                                >
                                    <i class="fas fa-cog" aria-hidden="true" />
                                </button>
                            </div>
                        </Teleport>
                        <Teleport v-if="testObj.isPublished" to="d-topbar-fixed-timer">
                            <div>
                                <topbar-timer-fixed
                                    :test-obj="testObj"
                                    :options="{
                                        showWindowLink: false,
                                    }"
                                />
                            </div>
                        </Teleport>
                    </div>
                    <div class="hidden-xs hidden-sm">
                        <!-- <template>
							<div class="buttonGroup">
								<button class="btn btn-success mainBtn">
									Publish TRAT&nbsp;<i class="fa fa-caret-right"></i>
								</button>
								<button class="btn btn-primary mainBtn">
									Export IRAT Scores&nbsp;<i class="fa fa-download"></i>
								</button>
								<button class="btn btn-primary mainBtn">
									Release IRAT Scores&nbsp;<i class="fa fa-file"></i>
								</button>
							</div>
							<div class="flex">
								<div class="viewDropdown">
									<div class="dropdown">
										<button class="btn drop-down" type="button" id="viewDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<span>
												More Actions
											</span>
											<span class="marginLeft10">
												<i class="fa fa-caret-down"></i>
											</span>
										</button>
										<div class="dropdown-menu" aria-labelledby="viewDropdownMenuButton">
											<button class="btn btn-danger">
												<i class="fa fa-trash"></i>&nbsp;Archive
											</button>
										</div>
									</div>
								</div>		
							</div>	
						</template> -->
                        <div v-if="canStartEarly" class="inlineFlex">
                            <!-- <div
                  class="timerTag-earlyStart"
              >
                  <p>Early Start</p>
              </div>
              <div
                  class="timerTag-success"
              >
                  <p>Available</p>
              </div> -->
                            <p class="greyText">Early Start is enabled</p>
                        </div>

                        <!-- <div 
              v-else-if="testObj.startedEarly && testObj.expectedEndingTime != null"
              class="inlineFlex"
            >
              <div
                  class="timerTag-earlyStart"
              >
                  <p>Early Start</p>
              </div>
              <div
                  class="timerTag-primary"
              >
                  <p>Ongoing</p>
              </div>
            </div> -->

                        <!-- <div 
              v-else-if="testObj.startedEarly && testObj.expectedEndingTime == null && !['ended','completed'].includes(testObj.status)"
              class="inlineFlex"
            >
              <div
                  class="timerTag-earlyStart"
              >
                  <p>Early Start</p>
              </div>
              <div
                  class="timerTag-ended"
              >
                  <p>Ended</p>
              </div>
              <p class="greyText">Early Start has ended</p>
            </div> -->

                        <template v-if="testObj.isPublished">
                            <div class="flexSpaceBetween alignFlexStart">
                                <div class="autoFlex">
                                    <template v-if="testObj.type == 'prework'">
                                        <button
                                            v-if="['not started', 'scheduled', 'ongoing', 'completed', 'ended'].includes(testObj.status)"
                                            v-tooltip="{
                                                content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                            }"
                                            class="btn btn-success"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            aria-label="Update Open Period"
                                            @click="openAsyncTimeModal()"
                                        >
                                            Update Open Period
                                        </button>
                                        <button
                                            v-if="['ongoing'].includes(testObj.status)"
                                            v-tooltip="{
                                                content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                            }"
                                            class="btn btn-danger"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            aria-label="End Prework"
                                            @click="openEndConfirmationModal()"
                                        >
                                            <i class="fa fa-stop marginRight8" aria-hidden="true" />End Prework
                                        </button>
                                        <!--<div class="btn" :class="{'p-0-i':hasWritePrivilege(testObj.course)}" v-tooltip="{content: hasWritePrivilege(testObj.course)==-1?readOnlyLabel:''}">
										<button class="btn btn-success mainBtn" @click="openResetConfirmationModal()" :disabled="(hasWritePrivilege(testObj.course)==-1) ? true:undefined">
											<i class="fa fa-refresh"></i>&nbsp;Reset
										</button>
									</div>-->
                                    </template>
                                    <template v-if="testObj.type == 'irat'">
                                        <button
                                            v-if="
                                                ['not started', 'scheduled', 'ongoing', 'completed', 'ended'].includes(testObj.status) &&
                                                testObj.settings.type == 'asynchronous'
                                            "
                                            v-tooltip="{
                                                content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                            }"
                                            class="btn btn-success"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            aria-label="Update Open Period"
                                            @click="openAsyncTimeModal()"
                                        >
                                            Update Open Period
                                        </button>
                                        <button
                                            v-if="canStart"
                                            v-tooltip="{
                                                content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                            }"
                                            class="btn btn-success"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            @click="canStartEarly ? openStartSelectionsModal() : start()"
                                        >
                                            <i class="fa fa-play marginRight8" aria-hidden="true" />
                                            Start IRAT
                                        </button>
                                        <button
                                            v-if="canEndEarly"
                                            class="btn btn-danger"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            @click="openEarlyEndModal"
                                        >
                                            <i class="fa fa-stop marginRight8" aria-hidden="true" />End Early Start
                                        </button>
                                        <button
                                            v-if="['ongoing'].includes(testObj.status) && testObj.settings.type == 'synchronous'"
                                            v-tooltip="{
                                                content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                            }"
                                            class="btn btn-warning"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            aria-label="Pause IRAT"
                                            @click="pause()"
                                        >
                                            <i class="fa fa-pause marginRight8" aria-hidden="true" />Pause IRAT
                                        </button>
                                        <button
                                            v-if="['paused'].includes(testObj.status) && testObj.settings.type == 'synchronous'"
                                            v-tooltip="{
                                                content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                            }"
                                            class="btn btn-success"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            aria-label="Resume IRAT"
                                            @click="resume()"
                                        >
                                            <i class="fa fa-play marginRight8" aria-hidden="true" />Resume IRAT
                                        </button>
                                        <button
                                            v-if="['paused', 'ongoing'].includes(testObj.status)"
                                            v-tooltip="{
                                                content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                            }"
                                            class="btn btn-danger"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            aria-label="End IRAT"
                                            @click="openEndConfirmationModal()"
                                        >
                                            <i class="fa fa-stop marginRight8" aria-hidden="true" />End IRAT
                                        </button>
                                    </template>
                                    <template v-if="testObj.type == 'trat'">
                                        <!--<div style="width:162px;">
                      <div class="flexSpaceBetween align-items">
                        <label class="control-label marginBottom0">
                          <span>Enable Clarifications</span>
                        </label>
                        <label class="switch">
                          <input type="checkbox">
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>-->
                                        <button
                                            v-if="
                                                ['not started', 'scheduled', 'ongoing', 'completed', 'ended'].includes(testObj.status) &&
                                                testObj.settings.type == 'asynchronous'
                                            "
                                            v-tooltip="{
                                                content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                            }"
                                            class="btn btn-success"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            aria-label="Update Open Period"
                                            @click="openAsyncTimeModal()"
                                        >
                                            Update Open Period
                                        </button>
                                        <!--&&testObj.canStartTrat!==false-->
                                        <button
                                            v-if="canStart"
                                            v-tooltip="{
                                                content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                            }"
                                            class="btn btn-success"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            aria-label="Start TRAT"
                                            @click="start()"
                                        >
                                            <i class="fa fa-play marginRight8" aria-hidden="true" />Start TRAT
                                        </button>
                                        <button
                                            v-if="['ongoing'].includes(testObj.status) && testObj.settings.type == 'synchronous'"
                                            v-tooltip="{
                                                content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                            }"
                                            class="btn btn-warning"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            aria-label="Pause TRAT"
                                            @click="pause()"
                                        >
                                            <i class="fa fa-pause marginRight8" aria-hidden="true" />Pause TRAT
                                        </button>
                                        <button
                                            v-if="['paused'].includes(testObj.status) && testObj.settings.type == 'synchronous'"
                                            v-tooltip="{
                                                content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                            }"
                                            class="btn btn-success"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            aria-label="Resume TRAT"
                                            @click="resume()"
                                        >
                                            <i class="fa fa-play marginRight8" aria-hidden="true" />Resume TRAT
                                        </button>
                                        <button
                                            v-if="['paused', 'ongoing'].includes(testObj.status)"
                                            v-tooltip="{
                                                content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                            }"
                                            class="btn btn-danger"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            aria-label="End TRAT"
                                            @click="openEndConfirmationModal()"
                                        >
                                            <i class="fa fa-stop marginRight8" aria-hidden="true" />End TRAT
                                        </button>
                                        <button
                                            v-if="
                                                ['ongoing', 'paused', 'ended', 'completed'].includes(testObj.status) && testObj.settings.allowTeamClarifications
                                            "
                                            v-tooltip="{
                                                content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                            }"
                                            class="btn btn-default"
                                            :class="{
                                                disabled: hasWritePrivilege(testObj.course) == -1,
                                            }"
                                            @click="tratGoTo"
                                        >
                                            <i class="fas fa-external-link-alt marginRight8" aria-hidden="true" />Presentation Mode
                                        </button>

                                        <!-- <router-link
                      v-if="
                        ['ongoing', 'paused', 'ended', 'completed'].includes(
                          testObj.status
                        ) && testObj.settings.allowTeamClarifications
                      "
                      v-tooltip="{
                        content:
                          hasWritePrivilege(testObj.course) == -1
                            ? readOnlyLabel
                            : '',
                      }"
                      :to="{
                        name: 'activities.tests.dashboard.trat-presentation',
                        params: { id: testObj.uuid },
                        query: { tab: 'leaderboard' },
                      }"
                      tag="a"
                      target="_blank"
                      class="btn btn-default"
                      :disabled="(hasWritePrivilege(testObj.course) == -1) ? true:undefined"
                      aria-label="Presentation Mode"
                    >
                      <i
                        class="fas fa-external-link-alt marginRight8"
                        aria-hidden="true"
                      />
                      Presentation Mode
                    </router-link> -->
                                        <!--<button class="btn btn-primary mainBtn" v-if="['ended','completed'].includes(testObj.status)">
										  <i class="fa fa-file-alt"></i>&nbsp;Release TRAT Scores
									  </button>-->
                                    </template>
                                    <template v-if="testObj.type == 'application'">
                                        <button
                                            v-if="
                                                ['not started', 'scheduled', 'ongoing', 'completed', 'ended'].includes(testObj.status) &&
                                                testObj.settings.type == 'asynchronous'
                                            "
                                            v-tooltip="{
                                                content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                            }"
                                            class="btn btn-success"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            aria-label="Update Open Period"
                                            @click="openAsyncTimeModal()"
                                        >
                                            Update Open Period
                                        </button>
                                        <button
                                            v-if="canStart"
                                            v-tooltip="{
                                                content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                            }"
                                            class="btn btn-success"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            @click="canStartEarly ? openStartSelectionsModal() : start()"
                                        >
                                            <i class="fa fa-play marginRight8" aria-hidden="true" />Start Application
                                        </button>
                                        <button
                                            v-if="canEndEarly"
                                            class="btn btn-danger"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            @click="openEarlyEndModal"
                                        >
                                            <i class="fa fa-stop marginRight8" aria-hidden="true" />End Early Start
                                        </button>
                                        <button
                                            v-if="['ongoing'].includes(testObj.status) && testObj.settings.type == 'synchronous'"
                                            v-tooltip="{
                                                content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                            }"
                                            class="btn btn-warning"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            aria-label="Pause Application"
                                            @click="pause()"
                                        >
                                            <i class="fa fa-pause marginRight8" aria-hidden="true" />Pause Application
                                        </button>
                                        <button
                                            v-if="['paused'].includes(testObj.status) && testObj.settings.type == 'synchronous'"
                                            v-tooltip="{
                                                content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                            }"
                                            class="btn btn-success"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            aria-label="Resume Application"
                                            @click="resume()"
                                        >
                                            <i class="fa fa-play marginRight8" aria-hidden="true" />Resume Application
                                        </button>
                                        <button
                                            v-if="['paused', 'ongoing'].includes(testObj.status)"
                                            v-tooltip="{
                                                content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                            }"
                                            class="btn btn-danger"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            aria-label="End Application"
                                            @click="openEndConfirmationModal()"
                                        >
                                            <i class="fa fa-stop marginRight8" aria-hidden="true" />End Application
                                        </button>

                                        <button class="btn btn-default" @click="applicationGoTo">
                                            <i class="fas fa-external-link-alt marginRight8" aria-hidden="true" />Open Presentation / e-Gallery Walk
                                            Presentation Mode
                                        </button>

                                        <!-- <router-link
                      :to="{
                        name: 'activities.tests.questions',
                        params: { id: testObj.uuid },
                      }"
                      class="btn btn-default"
                      target="_blank"
                      aria-label="Open e-Gallery Walk Presentation Mode"
                    >
                      <i
                        class="fas fa-external-link-alt marginRight8"
                        aria-hidden="true"
                      />
                      Open Presentation / e-Gallery Walk Presentation Mode
                    </router-link> -->
                                    </template>

                                    <template v-if="testObj.type == 'evaluation' || testObj.type == 'michaelsenEvaluation'">
                                        <button
                                            v-if="
                                                ['not started', 'scheduled', 'ongoing', 'completed', 'ended'].includes(testObj.status) &&
                                                testObj.settings.type == 'asynchronous'
                                            "
                                            v-tooltip="{
                                                content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                            }"
                                            class="btn btn-success"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            aria-label="Update Open Period"
                                            @click="openAsyncTimeModal()"
                                        >
                                            Update Open Period
                                        </button>
                                        <button
                                            v-if="canStart"
                                            v-tooltip="{
                                                content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                            }"
                                            class="btn btn-success"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            @click="canStartEarly ? openStartSelectionsModal() : start()"
                                        >
                                            <i class="fa fa-play marginRight8" aria-hidden="true" />Start Evaluation
                                        </button>
                                        <button
                                            v-if="canEndEarly"
                                            class="btn btn-danger"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            @click="openEarlyEndModal"
                                        >
                                            <i class="fa fa-stop marginRight8" aria-hidden="true" />End Early Start
                                        </button>
                                        <button
                                            v-if="['ongoing'].includes(testObj.status) && testObj.settings.type == 'synchronous'"
                                            v-tooltip="{
                                                content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                            }"
                                            class="btn btn-warning"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            aria-label="Pause Evaluation"
                                            @click="pause()"
                                        >
                                            <i class="fa fa-pause marginRight8" aria-hidden="true" />Pause Evaluation
                                        </button>
                                        <button
                                            v-if="['paused'].includes(testObj.status) && testObj.settings.type == 'synchronous'"
                                            v-tooltip="{
                                                content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                            }"
                                            class="btn btn-success"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            aria-label="Resume Evaluation"
                                            @click="resume()"
                                        >
                                            <i class="fa fa-play marginRight8" aria-hidden="true" />Resume Evaluation
                                        </button>
                                        <button
                                            v-if="['paused', 'ongoing'].includes(testObj.status)"
                                            v-tooltip="{
                                                content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                            }"
                                            class="btn btn-danger"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            aria-label="End Evaluation"
                                            @click="openEndConfirmationModal()"
                                        >
                                            <i class="fa fa-stop marginRight8" aria-hidden="true" />End Evaluation
                                        </button>
                                    </template>
                                </div>
                                <div class="autoFlex justify-content-right">
                                    <template v-if="testObj.type == 'irat'">
                                        <router-link
                                            v-if="testObj.linkedTRATUuid"
                                            :to="{
                                                name: 'activities.tests.dashboard',
                                                params: {
                                                    id: testObj.linkedTRATUuid,
                                                },
                                            }"
                                            tag="button"
                                            class="btn btn-success"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            aria-label="Go to TRAT"
                                        >
                                            Go to TRAT
                                        </router-link>
                                    </template>

                                    <template v-if="testObj.type == 'trat'">
                                        <router-link
                                            v-if="testObj.linkedIRATUuid"
                                            v-tooltip="{
                                                content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                            }"
                                            :to="{
                                                name: 'activities.tests.dashboard',
                                                params: {
                                                    id: testObj.linkedIRATUuid,
                                                },
                                            }"
                                            tag="button"
                                            class="btn btn-success"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            aria-label="Go to IRAT"
                                        >
                                            Go to IRAT
                                        </router-link>
                                    </template>

                                    <button class="btn btn-default" aria-label="Visibility Control" @click.prevent="openSettingsModal">
                                        Visibility Control<i class="fas fa-cogs marginLeft8" aria-hidden="true" />
                                    </button>

                                    <button class="btn btn-default" aria-label="Activity Overview" @click.prevent="openInfoModal">
                                        Activity Overview<i class="fas fa-eye marginLeft8" aria-hidden="true" />
                                    </button>

                                    <div class="dropdown">
                                        <button
                                            id="viewDropdownMenuButton"
                                            class="btn"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            aria-label="More Actions"
                                            @click.prevent="toggle"
                                        >
                                            More Actions<i
                                                class="marginLeft8"
                                                :class="{
                                                    'fa fa-caret-up': isOpened,
                                                    'fa fa-caret-down': !isOpened,
                                                }"
                                                aria-hidden="true"
                                            />
                                        </button>

                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="viewDropdownMenuButton">
                                            <ul>
                                                <li>
                                                    <button
                                                        class="btn btn-danger"
                                                        :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                                        aria-label="Reset Activity"
                                                        @click="openResetConfirmationModal(testObj)"
                                                    >
                                                        <i class="fa fa-refresh marginRight8" aria-hidden="true" />Reset Activity
                                                    </button>
                                                </li>

                                                <template
                                                    v-if="
                                                        (['irat', 'trat'].includes(testObj.type) && ['ended', 'completed'].includes(testObj.status)) ||
                                                        (['application'].includes(testObj.type) && ['ended', 'completed'].includes(testObj.status)) ||
                                                        (['evaluation', 'michaelsenEvaluation'].includes(testObj.type) &&
                                                            ['ongoing', 'paused', 'ended', 'completed'].includes(testObj.status))
                                                    "
                                                >
                                                    <li>
                                                        <button
                                                            class="btn btn-primary"
                                                            aria-label="Export Grades"
                                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                                            @click="openGradesExportConfirmationModal"
                                                        >
                                                            <i class="fas fa-download marginRight8" aria-hidden="true" />Export Grades
                                                        </button>
                                                    </li>
                                                    <li v-if="canPostGradesToLms">
                                                        <button
                                                            class="btn btn-success"
                                                            aria-label="Post Grades to LMS"
                                                            @click="openGradesPassbackConfirmationModal"
                                                        >
                                                            <i class="fas fa-flask marginRight8" aria-hidden="true" />Post Grades to LMS
                                                        </button>
                                                    </li>

                                                    <li>
                                                        <router-link
                                                            :to="{
                                                                name: 'courses.view',
                                                                params: {
                                                                    id: testObj.course.uuid,
                                                                },
                                                                query: {
                                                                    tab: 'downloadables',
                                                                },
                                                            }"
                                                            tag="button"
                                                            class="btn btn-default"
                                                            aria-label="Go to Grades"
                                                        >
                                                            <i class="fa fa-arrow-right marginRight8" aria-hidden="true" />Go to Grades
                                                        </router-link>
                                                    </li>
                                                </template>
                                            </ul>

                                            <!-- <template v-if="testObj.type == 'irat'">
                        <button
                          class="btn btn-danger"
                          v-tooltip="{
                            content:
                              hasWritePrivilege(testObj.course) == -1
                                ? readOnlyLabel
                                : '',
                          }"
                          :disabled="(hasWritePrivilege(testObj.course) == -1) ? true:undefined"
                          @click="openResetConfirmationModal()"
                          aria-label="Reset Activity"
                        >
                          <i class="fa fa-refresh"></i>&nbsp;Reset Activity
                        </button>
                        <button
                          class="btn btn-primary"
                          v-if="['ended', 'completed'].includes(testObj.status)"
                          @click="openGradesExportConfirmationModal"
                          aria-label="Export Grades"
                        >
                          <i class="fas fa-download"></i>&nbsp;Export Grades
                        </button>
                        <router-link
                          v-if="['ended', 'completed'].includes(testObj.status)"
                          :to="{
                            name: 'courses.view',
                            params: { id: testObj.course.uuid },
                            query: { tab: 'downloadables' },
                          }"
                          tag="button"
                          class="btn btn-default"
                          aria-label="Go to Grades"
                        >
                          <i class="fa fa-external-link-alt"></i>&nbsp; Go to
                          Grades
                        </router-link>
                      </template>

                      <template v-if="testObj.type == 'trat'">
                        <button
                          class="btn btn-danger"
                          @click="openResetConfirmationModal()"
                          v-tooltip="{
                            content:
                              hasWritePrivilege(testObj.course) == -1
                                ? readOnlyLabel
                                : '',
                          }"
                          :disabled="(hasWritePrivilege(testObj.course) == -1) ? true:undefined"
                          aria-label="Reset Activity"
                        >
                          <i class="fa fa-refresh"></i>&nbsp;Reset Activity
                        </button>
                        <button
                          class="btn btn-primary"
                          v-if="['ended', 'completed'].includes(testObj.status)"
                          @click="openGradesExportConfirmationModal"
                        >
                          <i class="fas fa-download"></i>&nbsp;Export Grades
                        </button>
                        <router-link
                          v-if="['ended', 'completed'].includes(testObj.status)"
                          :to="{
                            name: 'courses.view',
                            params: { id: testObj.course.uuid },
                            query: { tab: 'downloadables' },
                          }"
                          tag="button"
                          class="btn btn-default"
                          aria-label="Go to Grades"
                        >
                          <i class="fa fa-external-link-alt"></i>&nbsp;Go to
                          Grades
                        </router-link>
                      </template>

                      <template v-if="testObj.type == 'application'">
                        <button
                          class="btn btn-danger"
                          @click="openResetConfirmationModal()"
                          v-tooltip="{
                            content:
                              hasWritePrivilege(testObj.course) == -1
                                ? readOnlyLabel
                                : '',
                          }"
                          :disabled="(hasWritePrivilege(testObj.course) == -1) ? true:undefined"
                          aria-label="Reset Activity"
                        >
                          <i class="fa fa-refresh"></i>&nbsp;Reset Activity
                        </button>
                        <button
                          class="btn btn-primary"
                          v-if="
                            ['ended', 'completed'].includes(testObj.status) &&
                              testObj.others.isApplicationGraded
                          "
                          @click="openGradesExportConfirmationModal"
                          aria-label="Export Grades"
                        >
                          <i class="fas fa-download"></i>&nbsp;Export Grades
                        </button>
                        <router-link
                          v-if="
                            ['ended', 'completed'].includes(testObj.status) &&
                              testObj.others.isApplicationGraded
                          "
                          :to="{
                            name: 'courses.view',
                            params: { id: testObj.course.uuid },
                            query: { tab: 'downloadables' },
                          }"
                          tag="button"
                          class="btn btn-default"
                          aria-label="Go to Grades"
                        >
                          <i class="fa fa-external-link-alt"></i>&nbsp;Go to
                          Grades
                        </router-link>
                      </template>

                      <template
                        v-if="
                          testObj.type == 'evaluation' ||
                            testObj.type == 'michaelsenEvaluation'
                        "
                      >
                        <button
                          class="btn btn-danger mainBtn"
                          @click="openResetConfirmationModal()"
                          v-tooltip="{
                            content:
                              hasWritePrivilege(testObj.course) == -1
                                ? readOnlyLabel
                                : '',
                          }"
                          :disabled="(hasWritePrivilege(testObj.course) == -1) ? true:undefined"
                          aria-label="Reset Activity"
                        >
                          <i class="fa fa-refresh"></i>&nbsp;Reset Activity
                        </button>
                        <button
                          class="btn btn-primary"
                          v-if="
                            [
                              'ongoing',
                              'paused',
                              'ended',
                              'completed',
                            ].includes(testObj.status)
                          "
                          @click="openGradesExportConfirmationModal"
                          aria-label="Export Grades"
                        >
                          <i class="fas fa-download"></i>&nbsp;Export Grades
                        </button>
                        <router-link
                          v-if="
                            [
                              'ongoing',
                              'paused',
                              'ended',
                              'completed',
                            ].includes(testObj.status)
                          "
                          :to="{
                            name: 'courses.view',
                            params: { id: testObj.course.uuid },
                            query: { tab: 'downloadables' },
                          }"
                          tag="button"
                          class="btn btn-default"
                          aria-label="Go to Grades"
                        >
                          <i class="fa fa-external-link-alt"></i>&nbsp;Go to
                          Grades
                        </router-link>
                      </template> -->
                                        </div>
                                    </div>
                                    <!-- <template v-if="['not started','scheduled','ended','completed'].includes(testObj.status)">
									<button class="btn btn-danger mainBtn" @click="togglePublish()">
										Hide
									</button>
								</template> -->
                                </div>
                                <!--<div class="flex">
								<div class="viewDropdown">
									<div class="dropdown">
										<button class="btn drop-down" type="button" id="viewDropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<span>
												More Actions
											</span>
											<span class="marginLeft10">
												<i class="fa fa-caret-down"></i>
											</span>
										</button>
										<button class="btn btn-warning mainBtn" v-if="['ongoing'].includes(testObj.status)">
											<i class="fa fa-caret-right"></i>&nbsp;Pause IRAT
										</button>
									</div>
								</div>		
							</div>-->
                            </div>
                            <!-- <div class="clear"></div> -->
                        </template>
                        <div v-else class="autoFlex floatRight">
                            <template v-if="testObj.type == 'irat'">
                                <router-link
                                    v-if="testObj.linkedTRATUuid"
                                    v-tooltip="{
                                        content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                    }"
                                    :to="{
                                        name: 'activities.tests.dashboard',
                                        params: { id: testObj.linkedTRATUuid },
                                    }"
                                    tag="button"
                                    class="btn btn-success"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    aria-label="Go to TRAT"
                                >
                                    Go to TRAT
                                </router-link>
                            </template>
                            <template v-if="testObj.type == 'trat'">
                                <router-link
                                    v-if="testObj.linkedIRATUuid"
                                    v-tooltip="{
                                        content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                    }"
                                    :to="{
                                        name: 'activities.tests.dashboard',
                                        params: { id: testObj.linkedIRATUuid },
                                    }"
                                    tag="button"
                                    class="btn btn-success"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    aria-label="Go to IRAT"
                                >
                                    Go to IRAT
                                </router-link>
                            </template>

                            <button class="btn btn-default" aria-label="Visibility Control" @click.prevent="openSettingsModal">
                                Visibility Control<i class="fas fa-cogs marginLeft8" aria-hidden="true" />
                            </button>

                            <button class="btn btn-default" aria-label="Activity Overview" @click.prevent="openInfoModal">
                                Activity Overview<i class="fas fa-eye marginLeft8" aria-hidden="true" />
                            </button>

                            <div class="marginLeft20 dropdown">
                                <button
                                    id="viewDropdownMenuButton"
                                    class="btn"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    aria-label="More Actions"
                                    @click.prevent="toggle"
                                >
                                    More Actions
                                    <i
                                        class="marginLeft8"
                                        :class="{
                                            'fa fa-caret-up': isOpened,
                                            'fa fa-caret-down': !isOpened,
                                        }"
                                        aria-hidden="true"
                                    />
                                </button>
                                <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="viewDropdownMenuButton">
                                    <ul>
                                        <li>
                                            <button
                                                class="btn btn-danger"
                                                :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                                aria-label="Reset Activity"
                                                @click="openResetConfirmationModal(testObj)"
                                            >
                                                <i class="fa fa-refresh marginRight8" aria-hidden="true" />Reset Activity
                                            </button>
                                        </li>

                                        <template
                                            v-if="
                                                (['irat', 'trat'].includes(testObj.type) && ['ended', 'completed'].includes(testObj.status)) ||
                                                (['application'].includes(testObj.type) &&
                                                    ['ended', 'completed'].includes(testObj.status) &&
                                                    testObj.others.isApplicationGraded) ||
                                                (['evaluation', 'michaelsenEvaluation'].includes(testObj.type) &&
                                                    ['ongoing', 'paused', 'ended', 'completed'].includes(testObj.status))
                                            "
                                        >
                                            <li>
                                                <button
                                                    class="btn btn-primary"
                                                    aria-label="Export Grades"
                                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                                    @click="openGradesExportConfirmationModal"
                                                >
                                                    <i class="fas fa-download marginRight8" aria-hidden="true" />Export Grades
                                                </button>
                                            </li>

                                            <li>
                                                <router-link
                                                    :to="{
                                                        name: 'courses.view',
                                                        params: {
                                                            id: testObj.course.uuid,
                                                        },
                                                        query: {
                                                            tab: 'downloadables',
                                                        },
                                                    }"
                                                    tag="button"
                                                    class="btn btn-default"
                                                    aria-label="Go to Grades"
                                                >
                                                    <i class="fa fa-arrow-right marginRight8" aria-hidden="true" />Go to Grades
                                                </router-link>
                                            </li>
                                        </template>
                                    </ul>
                                    <!-- <template v-if="testObj.type == 'irat'">
                    <button
                      class="btn btn-danger"
                      v-tooltip="{
                        content:
                          hasWritePrivilege(testObj.course) == -1
                            ? readOnlyLabel
                            : '',
                      }"
                      :disabled="(hasWritePrivilege(testObj.course) == -1) ? true:undefined"
                      @click="openResetConfirmationModal()"
                      aria-label="Reset Activity"
                    >
                      <i class="fa fa-refresh"></i>&nbsp;Reset Activity
                    </button>
                    <button
                      class="btn btn-primary"
                      v-if="['ended', 'completed'].includes(testObj.status)"
                      @click="openGradesExportConfirmationModal"
                      aria-label="Export Grades"
                    >
                      <i class="fas fa-download"></i>&nbsp;Export Grades
                    </button>
                    <router-link
                      v-if="['ended', 'completed'].includes(testObj.status)"
                      :to="{
                        name: 'courses.view',
                        params: { id: testObj.course.uuid },
                        query: { tab: 'downloadables' },
                      }"
                      tag="button"
                      class="btn btn-default"
                      aria-label="Go to Grades"
                    >
                      <i class="fa fa-external-link-alt"></i>&nbsp;Go to
                      Grades
                    </router-link>
                  </template>
                  <template v-if="testObj.type == 'trat'">
                    <button
                      class="btn btn-danger"
                      v-tooltip="{
                        content:
                          hasWritePrivilege(testObj.course) == -1
                            ? readOnlyLabel
                            : '',
                      }"
                      :disabled="(hasWritePrivilege(testObj.course) == -1) ? true:undefined"
                      @click="openResetConfirmationModal()"
                      aria-label="Reset Activity"
                    >
                      <i class="fa fa-refresh"></i>&nbsp;Reset Activity
                    </button>
                    <button
                      class="btn btn-primary"
                      v-if="['ended', 'completed'].includes(testObj.status)"
                      @click="openGradesExportConfirmationModal"
                      aria-label="Export Grades"
                    >
                      <i class="fas fa-download"></i>&nbsp;Export Grades
                    </button>
                    <router-link
                      v-if="['ended', 'completed'].includes(testObj.status)"
                      :to="{
                        name: 'courses.view',
                        params: { id: testObj.course.uuid },
                        query: { tab: 'downloadables' },
                      }"
                      tag="button"
                      class="btn btn-default"
                      aria-label="Go to Grades"
                    >
                      <i class="fa fa-external-link-alt"></i>&nbsp;Go to
                      Grades
                    </router-link>
                  </template>
                  <template v-if="testObj.type == 'application'">
                    <button
                      class="btn btn-danger"
                      v-tooltip="{
                        content:
                          hasWritePrivilege(testObj.course) == -1
                            ? readOnlyLabel
                            : '',
                      }"
                      :disabled="(hasWritePrivilege(testObj.course) == -1) ? true:undefined"
                      @click="openResetConfirmationModal()"
                      aria-label="Reset Activity"
                    >
                      <i class="fa fa-refresh"></i>&nbsp;Reset Activity
                    </button>
                    <button
                      class="btn btn-primary"
                      v-if="
                        ['ended', 'completed'].includes(testObj.status) &&
                          testObj.others.isApplicationGraded
                      "
                      @click="openGradesExportConfirmationModal"
                      aria-label="Export Grades"
                    >
                      <i class="fas fa-download"></i>&nbsp;Export Grades
                    </button>
                    <router-link
                      v-if="
                        ['ended', 'completed'].includes(testObj.status) &&
                          testObj.others.isApplicationGraded
                      "
                      :to="{
                        name: 'courses.view',
                        params: { id: testObj.course.uuid },
                        query: { tab: 'downloadables' },
                      }"
                      tag="button"
                      class="btn btn-default"
                      aria-label="Go to Grades"
                    >
                      <i class="fa fa-external-link-alt"></i>&nbsp;Go to
                      Grades
                    </router-link>
                  </template>

                  <template
                    v-if="
                      testObj.type == 'evaluation' ||
                        testObj.type == 'michaelsenEvaluation'
                    "
                  >
                    <button
                      class="btn btn-danger mainBtn"
                      v-tooltip="{
                        content:
                          hasWritePrivilege(testObj.course) == -1
                            ? readOnlyLabel
                            : '',
                      }"
                      :disabled="(hasWritePrivilege(testObj.course) == -1) ? true:undefined"
                      @click="openResetConfirmationModal()"
                      aria-label="Reset Activity"
                    >
                      <i class="fa fa-refresh"></i>&nbsp;Reset Activity
                    </button>
                    <button
                      class="btn btn-primary"
                      v-if="
                        ['ongoing', 'paused', 'ended', 'completed'].includes(
                          testObj.status
                        )
                      "
                      @click="openGradesExportConfirmationModal"
                      aria-label="Export Grades"
                    >
                      <i class="fas fa-download"></i>&nbsp;Export Grades
                    </button>
                    <router-link
                      v-if="
                        ['ongoing', 'paused', 'ended', 'completed'].includes(
                          testObj.status
                        )
                      "
                      :to="{
                        name: 'courses.view',
                        params: { id: testObj.course.uuid },
                        query: { tab: 'downloadables' },
                      }"
                      tag="button"
                      class="btn btn-default"
                      aria-label="Go to Grades"
                    >
                      <i class="fa fa-external-link-alt"></i>&nbsp;Go to
                      Grades
                    </router-link>
                  </template> -->
                                </div>
                            </div>
                        </div>
                        <div class="clear"></div>
                    </div>
                    <div class="hidden-md hidden-lg">
                        <template v-if="testObj.isPublished">
                            <template v-if="testObj.type == 'prework'">
                                <button
                                    v-if="['not started', 'scheduled', 'ongoing', 'completed', 'ended'].includes(testObj.status)"
                                    class="col-xs-12 btn btn-success"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    aria-label="Update Open Period"
                                    @click="openAsyncTimeModal()"
                                >
                                    Update Open Period
                                </button>
                                <button
                                    v-if="['ongoing'].includes(testObj.status)"
                                    class="col-xs-12 btn btn-danger"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    aria-label="End Prework"
                                    @click="openEndConfirmationModal()"
                                >
                                    End Prework<i class="fa fa-stop marginLeft8" aria-hidden="true" />
                                </button>
                            </template>
                            <template v-if="testObj.type == 'irat'">
                                <button
                                    v-if="
                                        ['not started', 'scheduled', 'completed', 'ended'].includes(testObj.status) && testObj.settings.type == 'asynchronous'
                                    "
                                    class="btn btn-success"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    aria-label="Update Open Period"
                                    @click="openAsyncTimeModal()"
                                >
                                    Update Open Period
                                </button>
                                <button
                                    v-if="canStart"
                                    class="col-xs-12 btn btn-success"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    @click="canStartEarly ? openStartSelectionsModal() : start()"
                                >
                                    <i class="fa fa-play marginRight8" aria-hidden="true" />Start IRAT
                                </button>
                                <button
                                    v-if="canEndEarly"
                                    class="col-xs-12 btn btn-danger"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    @click="openEarlyEndModal"
                                >
                                    <i class="fa fa-stop marginRight8" aria-hidden="true" />End Early Start
                                </button>
                                <button
                                    v-if="['ongoing'].includes(testObj.status) && testObj.settings.type == 'synchronous'"
                                    class="col-xs-12 btn btn-warning"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    aria-label="Pause IRAT"
                                    @click="pause()"
                                >
                                    <i class="fa fa-pause marginRight8" aria-hidden="true" />Pause IRAT
                                </button>
                                <button
                                    v-if="['ongoing'].includes(testObj.status)"
                                    class="col-xs-12 btn btn-danger"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    aria-label="End IRAT"
                                    @click="openEndConfirmationModal()"
                                >
                                    <i class="fa fa-stop marginRight8" aria-hidden="true" />End IRAT
                                </button>
                                <button
                                    v-if="['paused'].includes(testObj.status) && testObj.settings.type == 'synchronous'"
                                    class="col-xs-12 btn btn-success"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    aria-label="Resume IRAT"
                                    @click="resume()"
                                >
                                    <i class="fa fa-play marginRight8" aria-hidden="true" />Resume IRAT
                                </button>
                            </template>
                            <template v-if="testObj.type == 'trat'">
                                <button
                                    v-if="
                                        ['not started', 'scheduled', 'ongoing', 'completed', 'ended'].includes(testObj.status) &&
                                        testObj.settings.type == 'asynchronous'
                                    "
                                    class="btn btn-success"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    aria-label="Update Open Period"
                                    @click="openAsyncTimeModal()"
                                >
                                    Update Open Period
                                </button>
                                <!--&&testObj.canStartTrat!==false-->
                                <button
                                    v-if="canStart"
                                    class="col-xs-12 btn btn-success"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    aria-label="Start TRAT"
                                    @click="start()"
                                >
                                    <i class="fa fa-play marginRight8" aria-hidden="true" />Start TRAT
                                </button>
                                <button
                                    v-if="['ongoing'].includes(testObj.status) && testObj.settings.type == 'synchronous'"
                                    class="col-xs-12 btn btn-warning"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    aria-label="Pause TRAT"
                                    @click="pause()"
                                >
                                    <i class="fa fa-pause marginRight8" aria-hidden="true" />Pause TRAT
                                </button>
                                <button
                                    v-if="['ongoing'].includes(testObj.status)"
                                    class="col-xs-12 btn btn-danger"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    aria-label="End TRAT"
                                    @click="openEndConfirmationModal()"
                                >
                                    <i class="fa fa-stop marginRight8" aria-hidden="true" />End TRAT
                                </button>
                                <button
                                    v-if="['paused'].includes(testObj.status) && testObj.settings.type == 'synchronous'"
                                    class="col-xs-12 btn btn-success"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    aria-label="Resume TRAT"
                                    @click="resume()"
                                >
                                    <i class="fa fa-play marginRight8" aria-hidden="true" />Resume TRAT
                                </button>
                                <button
                                    v-if="['ongoing', 'paused', 'ended', 'completed'].includes(testObj.status) && testObj.settings.allowTeamClarifications"
                                    v-tooltip="{
                                        content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                    }"
                                    class="btn btn-default"
                                    :class="{
                                        disabled: hasWritePrivilege(testObj.course) == -1,
                                    }"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    @click="tratGoTo"
                                >
                                    <i class="fas fa-external-link-alt marginRight8" aria-hidden="true" />Presentation Mode
                                </button>
                                <!-- <router-link
                  v-if="
                    ['ongoing', 'paused', 'ended', 'completed'].includes(
                      testObj.status
                    ) && testObj.settings.allowTeamClarifications
                  "
                  :to="{
                    name: 'activities.tests.dashboard.clarification',
                    params: { id: testObj.uuid },
                    query: { tab: 'leaderboard' },
                  }"
                  tag="a"
                  target="_blank"
                  class="col-xs-12 btn btn-default"
                  :disabled="(hasWritePrivilege(testObj.course) == -1) ? true:undefined"
                  aria-label="Presentation Mode"
                >
                  <i
                    class="fas fa-external-link-alt marginRight8"
                    aria-hidden="true"
                  />
                  Presentation Mode
                </router-link> -->
                                <!--<button class="col-xs-12 btn btn-primary mainBtn" v-if="['ended','completed'].includes(testObj.status)">
									<i class="fa fa-file-alt"></i>&nbsp;Release TRAT Scores
								</button>-->
                            </template>
                            <template v-if="testObj.type == 'application'">
                                <button
                                    v-if="
                                        ['not started', 'scheduled', 'ongoing', 'completed', 'ended'].includes(testObj.status) &&
                                        testObj.settings.type == 'asynchronous'
                                    "
                                    class="btn btn-success"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    aria-label="Update Open Period"
                                    @click="openAsyncTimeModal()"
                                >
                                    Update Open Period
                                </button>
                                <button
                                    v-if="canStart"
                                    class="col-xs-12 btn btn-success"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    @click="canStartEarly ? openStartSelectionsModal() : start()"
                                >
                                    <i class="fa fa-play marginRight8" aria-hidden="true" />Start Application
                                </button>
                                <button
                                    v-if="canEndEarly"
                                    class="col-xs-12 btn btn-danger"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    @click="openEarlyEndModal"
                                >
                                    <i class="fa fa-stop marginRight8" aria-hidden="true" />End Early Start
                                </button>
                                <button
                                    v-if="['ongoing'].includes(testObj.status) && testObj.settings.type == 'synchronous'"
                                    class="col-xs-12 btn btn-warning"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    aria-label="Pause Application"
                                    @click="pause()"
                                >
                                    <i class="fa fa-pause marginRight8" aria-hidden="true" />Pause Application
                                </button>
                                <button
                                    v-if="['ongoing'].includes(testObj.status)"
                                    class="col-xs-12 btn btn-danger"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    aria-label="End Application"
                                    @click="openEndConfirmationModal()"
                                >
                                    <i class="fa fa-stop marginRight8" aria-hidden="true" />End Application
                                </button>
                                <button
                                    v-if="['paused'].includes(testObj.status) && testObj.settings.type == 'synchronous'"
                                    class="col-xs-12 btn btn-success"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    aria-label="Resume Application"
                                    @click="resume()"
                                >
                                    <i class="fa fa-play marginRight8" aria-hidden="true" />Resume Application
                                </button>

                                <router-link
                                    :to="{
                                        name: 'activities.tests.questions',
                                        params: { id: testObj.uuid },
                                    }"
                                    class="col-xs-12 btn btn-default"
                                    aria-label="Open e-Gallery Walk Presentation
                  Mode"
                                >
                                    <i class="fas fa-external-link-alt marginRight8" aria-hidden="true" />Open Presentation
                                </router-link>
                            </template>
                            <template v-if="testObj.type == 'evaluation' || testObj.type == 'michaelsenEvaluation'">
                                <button
                                    v-if="
                                        ['not started', 'scheduled', 'ongoing', 'completed', 'ended'].includes(testObj.status) &&
                                        testObj.settings.type == 'asynchronous'
                                    "
                                    class="btn btn-success"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    aria-label="Update Open Period"
                                    @click="openAsyncTimeModal()"
                                >
                                    Update Open Period
                                </button>
                                <button
                                    v-if="canStart"
                                    class="col-xs-12 btn btn-success"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    @click="canStartEarly ? openStartSelectionsModal() : start()"
                                >
                                    <i class="fa fa-play marginRight8" aria-hidden="true" />Start Evaluation
                                </button>
                                <button
                                    v-if="canEndEarly"
                                    class="col-xs-12 btn btn-danger"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    @click="openEarlyEndModal"
                                >
                                    <i class="fa fa-stop marginRight8" aria-hidden="true" />End Early Start
                                </button>
                                <button
                                    v-if="['ongoing'].includes(testObj.status) && testObj.settings.type == 'synchronous'"
                                    class="col-xs-12 btn btn-warning"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    aria-label="Pause Evaluation"
                                    @click="pause()"
                                >
                                    <i class="fa fa-pause marginRight8" aria-hidden="true" />Pause Evaluation
                                </button>
                                <button
                                    v-if="['ongoing'].includes(testObj.status)"
                                    class="col-xs-12 btn btn-danger"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    aria-label="End Evaluation"
                                    @click="openEndConfirmationModal()"
                                >
                                    <i class="fa fa-stop marginRight8" aria-hidden="true" />End Evaluation
                                </button>
                                <button
                                    v-if="['paused'].includes(testObj.status) && testObj.settings.type == 'synchronous'"
                                    class="col-xs-12 btn btn-success"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    aria-label="Resume Evaluation"
                                    @click="resume()"
                                >
                                    <i class="fa fa-play marginRight8" aria-hidden="true" />Resume Evaluation
                                </button>
                            </template>
                            <button class="col-xs-12 btn btn-default" aria-label="Visibility Control" @click.prevent="openSettingsModal">
                                Visibility Control<i class="fas fa-cogs marginLeft8" aria-hidden="true" />
                            </button>
                            <button class="col-xs-12 btn btn-default" aria-label="Activity Overview" @click.prevent="openInfoModal">
                                Activity Overview<i class="fas fa-eye marginLeft8" aria-hidden="true" />
                            </button>
                            <div class="marginTop20 dropdown col-xs-12">
                                <button
                                    id="viewDropdownMenuButton"
                                    class="col-xs-12 btn"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    @click.prevent="toggle"
                                >
                                    More Actions
                                    <i
                                        class="marginLeft8"
                                        :class="{
                                            'fa fa-caret-up': isOpened,
                                            'fa fa-caret-down': !isOpened,
                                        }"
                                        aria-hidden="true"
                                    />
                                </button>
                                <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="viewDropdownMenuButton">
                                    <ul>
                                        <li>
                                            <button
                                                class="btn btn-danger"
                                                :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                                aria-label="Reset Activity"
                                                @click="openResetConfirmationModal(testObj)"
                                            >
                                                <i class="fa fa-refresh marginRight8" aria-hidden="true" />Reset Activity
                                            </button>
                                        </li>

                                        <template
                                            v-if="
                                                (['irat', 'trat'].includes(testObj.type) && ['ended', 'completed'].includes(testObj.status)) ||
                                                (['application'].includes(testObj.type) && ['ended', 'completed'].includes(testObj.status)) ||
                                                (['evaluation', 'michaelsenEvaluation'].includes(testObj.type) &&
                                                    ['ongoing', 'paused', 'ended', 'completed'].includes(testObj.status))
                                            "
                                        >
                                            <li>
                                                <button
                                                    class="btn btn-primary"
                                                    aria-label="Export Grades"
                                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                                    @click="openGradesExportConfirmationModal"
                                                >
                                                    <i class="fas fa-download marginRight8" aria-hidden="true" />Export Grades
                                                </button>
                                            </li>

                                            <li>
                                                <router-link
                                                    :to="{
                                                        name: 'courses.view',
                                                        params: {
                                                            id: testObj.course.uuid,
                                                        },
                                                        query: {
                                                            tab: 'downloadables',
                                                        },
                                                    }"
                                                    tag="button"
                                                    class="btn btn-default"
                                                    aria-label="Go to Grades"
                                                >
                                                    <i class="fa fa-arrow-right marginRight8" aria-hidden="true" />Go to Grades
                                                </router-link>
                                            </li>
                                        </template>
                                    </ul>

                                    <!-- <template v-if="testObj.type == 'irat'">
                    <button
                      class="btn btn-danger"
                      v-tooltip="{
                        content:
                          hasWritePrivilege(testObj.course) == -1
                            ? readOnlyLabel
                            : '',
                      }"
                      :disabled="(hasWritePrivilege(testObj.course) == -1) ? true:undefined"
                      @click="openResetConfirmationModal()"
                      aria-label="Reset Activity"
                    >
                      <i class="fa fa-refresh"></i>&nbsp;Reset Activity
                    </button>
                    <button
                      class="btn btn-primary"
                      v-if="['ended', 'completed'].includes(testObj.status)"
                      @click="openGradesExportConfirmationModal"
                      aria-label="Export Grades"
                    >
                      <i class="fas fa-download"></i>&nbsp;Export Grades
                    </button>
                    <router-link
                      v-if="['ended', 'completed'].includes(testObj.status)"
                      :to="{
                        name: 'courses.view',
                        params: { id: testObj.course.uuid },
                        query: { tab: 'downloadables' },
                      }"
                      tag="button"
                      class="btn btn-default"
                      aria-label="Go to Grades"
                    >
                      <i class="fa fa-external-link-alt"></i>&nbsp;Go to Grades
                    </router-link>
                  </template>
                  <template v-if="testObj.type == 'trat'">
                    <button
                      class="btn btn-danger"
                      v-tooltip="{
                        content:
                          hasWritePrivilege(testObj.course) == -1
                            ? readOnlyLabel
                            : '',
                      }"
                      :disabled="(hasWritePrivilege(testObj.course) == -1) ? true:undefined"
                      @click="openResetConfirmationModal()"
                      aria-label="Reset Activity"
                    >
                      <i class="fa fa-refresh"></i>&nbsp;Reset Activity
                    </button>
                    <button
                      class="btn btn-primary"
                      v-if="['ended', 'completed'].includes(testObj.status)"
                      @click="openGradesExportConfirmationModal"
                      aria-label="Export Grades"
                    >
                      <i class="fas fa-download"></i>&nbsp;Export Grades
                    </button>
                    <router-link
                      v-if="['ended', 'completed'].includes(testObj.status)"
                      :to="{
                        name: 'courses.view',
                        params: { id: testObj.course.uuid },
                        query: { tab: 'downloadables' },
                      }"
                      tag="button"
                      class="btn btn-default"
                      aria-label="Go to Grades"
                    >
                      <i class="fa fa-external-link-alt"></i>&nbsp;Go to Grades
                    </router-link>
                  </template>
                  <template v-if="testObj.type == 'application'">
                    <button
                      class="btn btn-danger"
                      v-tooltip="{
                        content:
                          hasWritePrivilege(testObj.course) == -1
                            ? readOnlyLabel
                            : '',
                      }"
                      :disabled="(hasWritePrivilege(testObj.course) == -1) ? true:undefined"
                      @click="openResetConfirmationModal()"
                      aria-label="Reset Activity"
                    >
                      <i class="fa fa-refresh"></i>&nbsp;Reset Activity
                    </button>
                    <button
                      class="btn btn-primary"
                      v-if="
                        ['ended', 'completed'].includes(testObj.status) &&
                          testObj.others.isApplicationGraded
                      "
                      @click="openGradesExportConfirmationModal"
                      aria-label="Export Grades"
                    >
                      <i class="fas fa-download"></i>&nbsp;Export Grades
                    </button>
                    <router-link
                      v-if="
                        ['ended', 'completed'].includes(testObj.status) &&
                          testObj.others.isApplicationGraded
                      "
                      :to="{
                        name: 'courses.view',
                        params: { id: testObj.course.uuid },
                        query: { tab: 'downloadables' },
                      }"
                      tag="button"
                      class="btn btn-default"
                      aria-label="Go to Grades"
                    >
                      <i class="fa fa-external-link-alt"></i>&nbsp;Go to Grades
                    </router-link>
                  </template>

                  <template
                    v-if="
                      testObj.type == 'evaluation' ||
                        testObj.type == 'michaelsenEvaluation'
                    "
                  >
                    <button
                      class="btn btn-danger mainBtn"
                      v-tooltip="{
                        content:
                          hasWritePrivilege(testObj.course) == -1
                            ? readOnlyLabel
                            : '',
                      }"
                      :disabled="(hasWritePrivilege(testObj.course) == -1) ? true:undefined"
                      @click="openResetConfirmationModal()"
                      aria-label="Reset Activity"
                    >
                      <i class="fa fa-refresh"></i>&nbsp;Reset Activity
                    </button>
                    <button
                      class="btn btn-primary"
                      v-if="
                        ['ongoing', 'paused', 'ended', 'completed'].includes(
                          testObj.status
                        )
                      "
                      @click="openGradesExportConfirmationModal"
                      aria-label="Export Grades"
                    >
                      <i class="fas fa-download"></i>&nbsp;Export Grades
                    </button>
                    <router-link
                      v-if="
                        ['ongoing', 'paused', 'ended', 'completed'].includes(
                          testObj.status
                        )
                      "
                      :to="{
                        name: 'courses.view',
                        params: { id: testObj.course.uuid },
                        query: { tab: 'downloadables' },
                      }"
                      tag="button"
                      class="btn btn-default"
                      aria-label="Go to Grades"
                    >
                      <i class="fa fa-external-link-alt"></i>&nbsp;Go to Grades
                    </router-link>
                  </template> -->
                                </div>
                            </div>
                            <!-- <template v-if="['not started','scheduled','ended','completed'].includes(testObj.status)">
								<button class="col-xs-12 btn btn-danger mainBtn" @click="togglePublish()">
									Hide
								</button>
							</template> -->
                        </template>
                        <template v-else>
                            <button class="col-xs-12 btn btn-default" aria-label="Visibility Control" @click.prevent="openSettingsModal">
                                Visibility Control<i class="fas fa-cogs marginLeft8" aria-hidden="true" />
                            </button>
                            <button class="col-xs-12 btn btn-default" aria-label="Activity Overview" @click.prevent="openInfoModal">
                                Activity Overview<i class="fas fa-eye marginLeft8" aria-hidden="true" />
                            </button>
                            <div class="marginTop20 dropdown col-xs-12">
                                <button
                                    id="viewDropdownMenuButton"
                                    class="col-xs-12 btn"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    @click.prevent="toggle"
                                >
                                    More Actions
                                    <i
                                        class="marginLeft8"
                                        :class="{
                                            'fa fa-caret-up': isOpened,
                                            'fa fa-caret-down': !isOpened,
                                        }"
                                        aria-hidden="true"
                                    />
                                </button>
                                <div class="dropdown-menu dropdown-menu-new" aria-labelledby="viewDropdownMenuButton">
                                    <ul>
                                        <li>
                                            <button
                                                class="btn btn-danger"
                                                :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                                aria-label="Reset Activity"
                                                @click="openResetConfirmationModal(testObj)"
                                            >
                                                <i class="fa fa-refresh marginRight8" aria-hidden="true" />Reset Activity
                                            </button>
                                        </li>

                                        <template
                                            v-if="
                                                (['irat', 'trat'].includes(testObj.type) && ['ended', 'completed'].includes(testObj.status)) ||
                                                (['application'].includes(testObj.type) && ['ended', 'completed'].includes(testObj.status)) ||
                                                (['evaluation', 'michaelsenEvaluation'].includes(testObj.type) &&
                                                    ['ongoing', 'paused', 'ended', 'completed'].includes(testObj.status))
                                            "
                                        >
                                            <li>
                                                <button
                                                    class="btn btn-primary"
                                                    aria-label="Export Grades"
                                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                                    @click="openGradesExportConfirmationModal"
                                                >
                                                    <i class="fas fa-download marginRight8" aria-hidden="true" />Export Grades
                                                </button>
                                            </li>

                                            <li>
                                                <router-link
                                                    :to="{
                                                        name: 'courses.view',
                                                        params: {
                                                            id: testObj.course.uuid,
                                                        },
                                                        query: {
                                                            tab: 'downloadables',
                                                        },
                                                    }"
                                                    tag="button"
                                                    class="btn btn-default"
                                                    aria-label="Go to Grades"
                                                >
                                                    <i class="fa fa-arrow-right marginRight8" aria-hidden="true" />Go to Grades
                                                </router-link>
                                            </li>
                                        </template>
                                    </ul>
                                    <!-- <template v-if="testObj.type == 'irat'">
                    <button
                      class="btn btn-danger"
                      v-tooltip="{
                        content:
                          hasWritePrivilege(testObj.course) == -1
                            ? readOnlyLabel
                            : '',
                      }"
                      :disabled="(hasWritePrivilege(testObj.course) == -1) ? true:undefined"
                      @click="openResetConfirmationModal()"
                      aria-label="Reset Activity"
                    >
                      <i class="fa fa-refresh"></i>&nbsp;Reset Activity
                    </button>
                    <button
                      class="btn btn-primary"
                      v-if="['ended', 'completed'].includes(testObj.status)"
                      @click="openGradesExportConfirmationModal"
                      aria-label="Export Grades"
                    >
                      <i class="fas fa-download"></i>&nbsp;Export Grades
                    </button>
                    <router-link
                      v-if="['ended', 'completed'].includes(testObj.status)"
                      :to="{
                        name: 'courses.view',
                        params: { id: testObj.course.uuid },
                        query: { tab: 'downloadables' },
                      }"
                      tag="button"
                      class="btn btn-default"
                      aria-label="Go to Grades"
                    >
                      <i class="fa fa-external-link-alt"></i>&nbsp;Go to Grades
                    </router-link>
                  </template>
                  <template v-if="testObj.type == 'trat'">
                    <button
                      class="btn btn-danger"
                      v-tooltip="{
                        content:
                          hasWritePrivilege(testObj.course) == -1
                            ? readOnlyLabel
                            : '',
                      }"
                      :disabled="(hasWritePrivilege(testObj.course) == -1) ? true:undefined"
                      @click="openResetConfirmationModal()"
                      aria-label="Reset Activity"
                    >
                      <i class="fa fa-refresh"></i>&nbsp;Reset Activity
                    </button>
                    <button
                      class="btn btn-primary"
                      v-if="['ended', 'completed'].includes(testObj.status)"
                      @click="openGradesExportConfirmationModal"
                      aria-label="Export Grades"
                    >
                      <i class="fas fa-download"></i>&nbsp;Export Grades
                    </button>
                    <router-link
                      v-if="['ended', 'completed'].includes(testObj.status)"
                      :to="{
                        name: 'courses.view',
                        params: { id: testObj.course.uuid },
                        query: { tab: 'downloadables' },
                      }"
                      tag="button"
                      class="btn btn-default"
                      aria-label="Go to Grades"
                    >
                      <i class="fa fa-external-link-alt"></i>&nbsp;Go to Grades
                    </router-link>
                  </template>
                  <template v-if="testObj.type == 'application'">
                    <button
                      class="btn btn-danger"
                      v-tooltip="{
                        content:
                          hasWritePrivilege(testObj.course) == -1
                            ? readOnlyLabel
                            : '',
                      }"
                      :disabled="(hasWritePrivilege(testObj.course) == -1) ? true:undefined"
                      @click="openResetConfirmationModal()"
                      aria-label="Reset Activity"
                    >
                      <i class="fa fa-refresh"></i>&nbsp;Reset Activity
                    </button>
                    <button
                      class="btn btn-primary"
                      v-if="
                        ['ended', 'completed'].includes(testObj.status) &&
                          testObj.others.isApplicationGraded
                      "
                      @click="openGradesExportConfirmationModal"
                      aria-label="Export Grades"
                    >
                      <i class="fas fa-download"></i>&nbsp;Export Grades
                    </button>
                    <router-link
                      v-if="
                        ['ended', 'completed'].includes(testObj.status) &&
                          testObj.others.isApplicationGraded
                      "
                      :to="{
                        name: 'courses.view',
                        params: { id: testObj.course.uuid },
                        query: { tab: 'downloadables' },
                      }"
                      tag="button"
                      class="btn btn-default"
                      aria-label="Go to Grades"
                    >
                      <i class="fa fa-external-link-alt"></i>&nbsp;Go to Grades
                    </router-link>
                  </template>

                  <template
                    v-if="
                      testObj.type == 'evaluation' ||
                        testObj.type == 'michaelsenEvaluation'
                    "
                  >
                    <button
                      class="btn btn-danger mainBtn"
                      v-tooltip="{
                        content:
                          hasWritePrivilege(testObj.course) == -1
                            ? readOnlyLabel
                            : '',
                      }"
                      :disabled="(hasWritePrivilege(testObj.course) == -1) ? true:undefined"
                      @click="openResetConfirmationModal()"
                      aria-label="Reset Activity"
                    >
                      <i class="fa fa-refresh"></i>&nbsp;Reset Activity
                    </button>
                    <button
                      class="btn btn-primary"
                      v-if="
                        ['ongoing', 'paused', 'ended', 'completed'].includes(
                          testObj.status
                        )
                      "
                      @click="openGradesExportConfirmationModal"
                      aria-label="Export Grades"
                    >
                      <i class="fas fa-download"></i>&nbsp;Export Grades
                    </button>
                    <router-link
                      v-if="
                        ['ongoing', 'paused', 'ended', 'completed'].includes(
                          testObj.status
                        )
                      "
                      :to="{
                        name: 'courses.view',
                        params: { id: testObj.course.uuid },
                        query: { tab: 'downloadables' },
                      }"
                      tag="button"
                      class="btn btn-default"
                      aria-label="Go to Grades"
                    >
                      <i class="fa fa-external-link-alt"></i>&nbsp;Go to Grades
                    </router-link>
                  </template> -->
                                </div>
                            </div>
                        </template>
                    </div>
                </template>
            </template>
        </kr-panel>

        <div id="otherInfoModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="otherInfoModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="otherInfoModal-title" class="modal-title">Overview</h2>
                    </div>
                    <div class="modal-body">
                        <h2>{{ testObj.name }}</h2>

                        <div v-if="testObj.settings.enableMichaelsenPointDistribution" class="col-xs-12 marginTop10">
                            <h4>Michaelsen Point Distribution</h4>
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <th>Section</th>
                                        <th>Team</th>
                                        <th>Team Size</th>
                                        <th>Points To Distribute</th>
                                    </thead>
                                    <tbody>
                                        <template
                                            v-for="(team, team_idx) in testObj.settings.michaelsenPointDistribution"
                                            :key="team_idx + '-michaelsen-table-topbar'"
                                        >
                                            >
                                            <tr>
                                                <td>{{ team.section }}</td>
                                                <td>{{ team.name }}</td>
                                                <td>
                                                    {{ team.members.length }}
                                                </td>
                                                <td>{{ team.point }}</td>
                                            </tr>
                                        </template>
                                        <tr v-if="testObj.settings.michaelsenPointDistribution.length == 0">
                                            <td colspan="4" style="text-align: center">No Team</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="control-label">Description</label>
                            <template v-if="testObj.description">
                                <p class="form-control-static">
                                    {{ testObj.description }}
                                </p>
                            </template>
                            <template v-else>
                                <p class="form-control-static">-</p>
                            </template>
                        </div>

                        <!-- <div class="row flex">
              <template v-if="testObj.settings.type == 'asynchronous'">
                <div>
                  <div class="form-group">
                    <label class="control-label">OPEN PERIOD IS FROM</label>
                    <p class="form-control-static margin0">
                      <span>
                        <i class="far fa-calendar-alt"></i>&nbsp;
                        <template
                          v-if="!['not started'].includes(testObj.status)"
                        >
                          {{
                            convertToReadableDate(
                              testObj.startTime,
                              "DD MMM YYYY, h:mm a"
                            ).date
                          }}
                          ({{
                            convertToReadableDate(
                              testObj.startTime,
                              "DD MMM YYYY, h:mm a"
                            ).current_timezone
                          }})
                        </template>
                        <template v-else>
                          -
                        </template>
                      </span>
                      &nbsp; to &nbsp;
                      <span>
                        <i class="far fa-calendar-alt"></i>&nbsp;
                        <template
                          v-if="
                            [
                              'ongoing',
                              'paused',
                              'completed',
                              'scheduled',
                            ].includes(testObj.status)
                          "
                        >
                          {{
                            convertToReadableDate(
                              testObj.endTime,
                              "DD MMM YYYY, h:mm a"
                            ).date
                          }}
                          ({{
                            convertToReadableDate(
                              testObj.endTime,
                              "DD MMM YYYY, h:mm a"
                            ).current_timezone
                          }})
                        </template>
                        <template
                          v-else-if="['ended'].includes(testObj.status)"
                        >
                          {{
                            convertToReadableDate(
                              testObj.timeEnded,
                              "DD MMM YYYY, h:mm a"
                            ).date
                          }}
                          ({{
                            convertToReadableDate(
                              testObj.timeEnded,
                              "DD MMM YYYY, h:mm a"
                            ).current_timezone
                          }})
                        </template>
                        <template v-else>
                          -
                        </template>
                      </span>
                    </p>
                  </div>
                </div>
              </template>
              <template v-if="testObj.settings.type == 'synchronous'">
                <div class="col-xs-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">STARTS FROM</label>
                    <p class="form-control-static margin0">
                      <i class="far fa-calendar-alt"></i>&nbsp;

                      <template
                        v-if="!['not started'].includes(testObj.status)"
                      >
                        {{
                          convertToReadableDate(
                            testObj.startTime,
                            "DD MMM YYYY, h:mm a"
                          ).date
                        }}
                        ({{
                          convertToReadableDate(
                            testObj.startTime,
                            "DD MMM YYYY, h:mm a"
                          ).current_timezone
                        }})
                      </template>
                      <template v-else>
                        -
                      </template>
                    </p>
                  </div>
                </div>
                <div class="col-xs-12 col-md-6">
                  <div class="form-group">
                    <label class="control-label">ENDS ON</label>
                    <p class="form-control-static margin0">
                      <i class="far fa-calendar-alt"></i>&nbsp;
                      <template
                        v-if="
                          ['ongoing', 'paused', 'completed'].includes(
                            testObj.status
                          )
                        "
                      >
                        {{
                          convertToReadableDate(
                            testObj.endTime,
                            "DD MMM YYYY, h:mm a"
                          ).date
                        }}
                        ({{
                          convertToReadableDate(
                            testObj.endTime,
                            "DD MMM YYYY, h:mm a"
                          ).current_timezone
                        }})
                      </template>
                      <template v-else-if="['ended'].includes(testObj.status)">
                        {{
                          convertToReadableDate(
                            testObj.timeEnded,
                            "DD MMM YYYY, h:mm a"
                          ).date
                        }}
                        ({{
                          convertToReadableDate(
                            testObj.timeEnded,
                            "DD MMM YYYY, h:mm a"
                          ).current_timezone
                        }})
                      </template>
                      <template v-else>
                        -
                      </template>
                    </p>
                  </div>
                </div>
              </template>
            </div> -->

                        <div class="form-group">
                            <label class="control-label">INSTRUCTIONS</label>
                            <template v-if="testObj.settings.instruction">
                                <p v-if="testObj.settings.instructionIsHTML" class="form-control-static" v-html="testObj.settings.instruction" />
                                <p v-else class="form-control-static">
                                    {{ testObj.settings.instruction }}
                                </p>
                            </template>
                            <template v-else>
                                <p class="form-control-static">-</p>
                            </template>
                        </div>
                        <div v-if="testObj.settings.attachments.length > 0" class="col-xs-12 col-md-6 marginBottom20">
                            <div class="flex align-items attachmentFile">
                                <div class="col-xs-12">
                                    <template v-for="(attachment, idx) in testObj.settings.attachments" :key="idx">
                                        <div class="flexSpaceBetween">
                                            <div>
                                                <span>{{ idx + 1 }}.</span>
                                                <span>
                                                    <i class="far fa-file-pdf" aria-hidden="true" />
                                                </span>
                                                <a :href="attachment.url" target="_blank" class="attachmentLink">{{ attachment.filename }}</a>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="control-label">PARENT COURSE</label>
                            <p class="form-control-static">
                                {{ testObj.course.name + (testObj.course.code ? ' (' + testObj.course.code + ')' : '') }}
                            </p>
                        </div>
                        <div class="form-group">
                            Go to
                            <router-link
                                class="link"
                                target="_blank"
                                :to="{
                                    name: 'courses.view',
                                    params: { id: testObj.course.uuid },
                                    query: { tab: 'lms' },
                                }"
                                aria-label="LMS Integration"
                            >
                                LMS INTEGRATION
                            </router-link>
                        </div>
                        <div v-if="auth.user().account.paymentMethod != 'Student-Paid'" class="form-group">
                            Go to
                            <router-link
                                class="link"
                                target="_blank"
                                :to="{
                                    name: 'courses.view',
                                    params: { id: testObj.course.uuid },
                                    query: { tab: 'signin' },
                                }"
                                aria-label="Self Sign in Url and QR Code"
                            >
                                SELF SIGN IN URL AND QR CODE
                            </router-link>
                        </div>
                    </div>
                    <div class="modal-footer" />
                </div>
            </div>
        </div>

        <div id="settingsModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="settingsModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button v-show="!anonymityConfirmation" type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="settingsModal-title" class="modal-title">
                            <template v-if="!anonymityConfirmation"> Visibility Control </template>

                            <template v-else> Anonymity For This Activity </template>
                        </h2>
                    </div>
                    <div v-if="!anonymityConfirmation" class="modal-body">
                        <fieldset tabindex="0">
                            <legend class="visible-hidden">Visibility Control table</legend>

                            <div v-if="['not started', 'scheduled', 'ongoing', 'ended', 'completed'].includes(testObj.status)" class="flexOnly">
                                <label
                                    v-tooltip="{
                                        content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                    }"
                                    class="form-switch marginRight20"
                                >
                                    <input
                                        v-model="testObj.isPublished"
                                        type="checkbox"
                                        :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                        aria-label="Students will be able to see this activity listed on their Intedashboard home page"
                                        @change="togglePublish()"
                                    />
                                    <span class="slider round" />
                                </label>

                                <div>
                                    <div class="fontBold">Allow students to see this activity</div>
                                    <div class="toggleDescriptWidth">Students will be able to see this activity listed on their InteDashboard home page.</div>
                                </div>
                            </div>

                            <template
                                v-if="['irat', 'trat', 'application', 'evaluation', 'michaelsenEvaluation'].includes(testObj.type) && testObj.isPublished"
                            >
                                <div
                                    v-if="['not started', 'scheduled', 'ongoing', 'ended', 'completed'].includes(testObj.status)"
                                    class="paddingTop20 borderTop1pxGrey marginTop20 flexOnly"
                                >
                                    <label
                                        v-tooltip="{
                                            content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                        }"
                                        class="form-switch marginRight20"
                                    >
                                        <input
                                            v-model="testObj.allowStudentsToViewAnswer"
                                            type="checkbox"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            aria-label="Students can view the questions and their answer choices for this activity"
                                            @change="toggleViewAnswer()"
                                        />
                                        <span class="slider round" />
                                    </label>

                                    <div>
                                        <div class="fontBold">Allow students to view their answers</div>
                                        <div class="toggleDescriptWidth">Students can view the questions and their answer choices for this activity.</div>
                                    </div>
                                </div>

                                <div
                                    v-if="
                                        ['ongoing', 'ended', 'completed'].includes(testObj.status) &&
                                        (testObj.type != 'application' || (testObj.type == 'application' && testObj.others.isApplicationGraded))
                                    "
                                    class="paddingTop20 borderTop1pxGrey marginTop20 flexOnly"
                                >
                                    <label
                                        v-tooltip="{
                                            content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                        }"
                                        class="form-switch marginRight20"
                                    >
                                        <input
                                            v-model="testObj.allowStudentsToViewScore"
                                            type="checkbox"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            aria-label="Students can view their total score and percentage correct for this activity. If you have allowed students to view their answers, they will also be able to see their scores on each individual question for this activity."
                                            @change="toggleViewScore()"
                                        />
                                        <span class="slider round" />
                                    </label>

                                    <div>
                                        <div class="fontBold">Allow students to view their scores</div>
                                        <div class="toggleDescriptWidth">
                                            Students can view their total score and percentage correct for this activity. If you have allowed students to view
                                            their answers, they will also be able to see their scores on each individual question of this activity.
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="
                                        ['ongoing', 'ended', 'completed'].includes(testObj.status) &&
                                        ['evaluation', 'michaelsenEvaluation'].includes(testObj.type) &&
                                        testObj.allowStudentsToViewScore
                                    "
                                    class="paddingTop20 borderTop1pxGrey marginTop20 flexOnly"
                                >
                                    <label
                                        v-tooltip="{
                                            content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                        }"
                                        class="form-switch marginRight20"
                                    >
                                        <input
                                            v-model="testObj.allowStudentsToViewPafScores"
                                            type="checkbox"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            aria-label="Allow students to view their Peer Assessment Factor (PAF)"
                                            @change="toggleViewPaf()"
                                        />
                                        <span class="slider round" />
                                    </label>

                                    <div>
                                        <div class="fontBold">Allow students to view their Peer Assessment Factor (PAF)</div>
                                        <div class="toggleDescriptWidth"></div>
                                    </div>
                                </div>
                                <div
                                    v-if="
                                        ['ongoing', 'ended', 'completed'].includes(testObj.status) &&
                                        ['evaluation', 'michaelsenEvaluation'].includes(testObj.type) &&
                                        testObj.allowStudentsToViewScore
                                    "
                                    class="paddingTop20 borderTop1pxGrey marginTop20 flexOnly"
                                >
                                    <label
                                        v-tooltip="{
                                            content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                        }"
                                        class="form-switch marginRight20"
                                    >
                                        <input
                                            v-model="testObj.allowStudentsToViewSapaScores"
                                            type="checkbox"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            aria-label="Allow students to view their Self-Assessment over Peer Assessment score"
                                            @change="toggleViewSapa()"
                                        />
                                        <span class="slider round" />
                                    </label>

                                    <div>
                                        <div class="fontBold">Allow students to view their Self-Assessment over Peer Assessment score</div>
                                        <div class="toggleDescriptWidth"></div>
                                    </div>
                                </div>
                                <div
                                    v-if="
                                        ['not started', 'scheduled', 'ongoing', 'ended', 'completed'].includes(testObj.status) && testObj.type == 'application'
                                    "
                                    class="paddingTop20 borderTop1pxGrey marginTop20 flexOnly"
                                >
                                    <label
                                        v-tooltip="{
                                            content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                        }"
                                        class="form-switch marginRight20"
                                    >
                                        <input
                                            v-model="testObj.allowStudentsToViewAttachments"
                                            type="checkbox"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            aria-label="Student can view  (and optionally download based on your settings) attachments such as PDF files"
                                            @change="toggleViewAttachments()"
                                        />
                                        <span class="slider round" />
                                    </label>

                                    <div>
                                        <div class="fontBold">Allow students to access attachments</div>
                                        <div class="toggleDescriptWidth">
                                            Students can view (and optionally download based on your settings) attachments such as PDF files
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="
                                        ['ended', 'completed'].includes(testObj.status) &&
                                        (testObj.type == 'evaluation' || testObj.type == 'michaelsenEvaluation') &&
                                        testObj.allowStudentsToViewScore
                                    "
                                    class="paddingTop20 borderTop1pxGrey marginTop20 flexOnly"
                                >
                                    <label
                                        v-tooltip="{
                                            content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                                        }"
                                        class="form-switch marginRight20"
                                    >
                                        <input
                                            v-model="testObj.enableAnonymity"
                                            type="checkbox"
                                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                            aria-label="Students can view the names of those who evaluated them"
                                            :true-value="false"
                                            :false-value="true"
                                            @change="anonymityConfirmation = true"
                                        />
                                        <span class="slider round" />
                                    </label>

                                    <div>
                                        <div class="fontBold">Remove anonymity of the evaluation</div>
                                        <div class="toggleDescriptWidth">Students can view the names of those who evaluated them</div>
                                    </div>
                                </div>
                                <div
                                    v-if="
                                        [
                                            'scheduled',
                                            'not started',
                                            'ongoing',
                                            'ended',
                                            'completed',
                                        ].includes(testObj.status) &&
                                        ['trat'].includes(testObj.type)
                                    "
                                    class="paddingTop20 borderTop1pxGrey marginTop20 flexOnly"
                                >
                                    <label
                                        v-tooltip="{
                                            content:
                                                hasWritePrivilege(
                                                    testObj.course
                                                ) == -1
                                                    ? readOnlyLabel
                                                    : '',
                                        }"
                                        class="form-switch marginRight20"
                                    >
                                        <input
                                            v-model="
                                                testObj.enableAnonymityInPresentationMode
                                            "
                                            type="checkbox"
                                            :disabled="
                                                hasWritePrivilege(
                                                    testObj.course
                                                ) == -1
                                            "
                                            aria-label="Students cannot view the names on
                                            presentation mode"
                                            @change="
                                                togglePresentationAnonymity()
                                            "
                                        />
                                        <span class="slider round" />
                                    </label>

                                    <div>
                                        <div class="fontBold">
                                            Enable anonymity of the presentation
                                            mode for students
                                        </div>
                                        <div class="toggleDescriptWidth">
                                            Students cannot view the names on
                                            presentation mode
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="
                                        [
                                            'scheduled',
                                            'not started',
                                            'ongoing',
                                            'ended',
                                            'completed',
                                        ].includes(testObj.status) &&
                                        testObj.type == 'application'
                                    "
                                    class="paddingTop20 borderTop1pxGrey marginTop20 flexOnly"
                                >
                                    <label
                                        v-tooltip="{
                                            content:
                                                hasWritePrivilege(
                                                    testObj.course
                                                ) == -1
                                                    ? readOnlyLabel
                                                    : '',
                                        }"
                                        class="form-switch marginRight20"
                                    >
                                        <input
                                            v-model="
                                                testObj.enableAnonymityInEGalleryWalk
                                            "
                                            type="checkbox"
                                            :disabled="
                                                hasWritePrivilege(
                                                    testObj.course
                                                ) == -1
                                            "
                                            aria-label="Students cannot view the names on
                                            e-gallery-walk"
                                            @change="toggleEgalleryAnonymity()"
                                        />
                                        <span class="slider round" />
                                    </label>

                                    <div>
                                        <div class="fontBold">
                                            Enable anonymity of the
                                            e-gallery-walk for students
                                        </div>
                                        <div class="toggleDescriptWidth">
                                            Students cannot view the names on
                                            e-gallery-walk
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="flex">
								<button class="btn btn-success mainBtn">
									<i class="far fa-save"></i>&nbsp;Save Changes
								</button>
								<button class="btn btn-default mainBtn">
									<i class="fas fa-times"></i>&nbsp;Cancel
								</button>
							</div> -->
                                <!-- <div class="col-xs-12 col-md-4 marginTop20 marginBottom20"  v-if="['not started','scheduled'].includes(testObj.status)">
								<div class="flexSpaceBetween">
									<label class="control-label">
										<span>ALLOW STUDENTS TO PREVIEW QUESTIONS</span>
									</label>
									<label class="switch">
										<input type="checkbox" v-model="testObj.allowStudentsToPreviewQuestions" @change="togglePreviewQuestion()">
										<span class="slider round"></span>
									</label>
								</div>
							</div> -->
                            </template>
                        </fieldset>
                    </div>

                    <div v-else class="modal-body">
                        <div class="row">
                            <div class="col-xs-12 textAlignCenter">
                                <div v-if="!testObj.enableAnonymity">
                                    Are you sure you want to make this test non-anonymous?
                                    <br />
                                    To confirm, type out your InteDashboard email in the text area below.
                                </div>
                                <div v-else>
                                    Are you sure you want to make this test anonymous?
                                    <br />
                                    To confirm, type out your InteDashboard email in the text area below.
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="grey checkboxField justifyCenter align-items marginBottom20">
                                <input v-model="agree" type="checkbox" />
                                <span class="marginLeft10 lineHeight08">I understand and accept the risk!</span>
                            </div>
                            <div class="col-xs-12 col-md-12 marginBottom10">
                                <div class="form-group">
                                    <input v-model="resetConfirmationField" class="form-control" :placeholder="`Type ${auth.user().email}`" />
                                </div>
                            </div>
                            <div class="hidden-xs hidden-sm flex">
                                <div class="col-xs-12 col-md-6">
                                    <button
                                        id="modal-logout-btn"
                                        class="col-xs-12 btn btn-danger"
                                        :disabled="!canReset ? true : undefined"
                                        aria-label="Yes"
                                        @click.prevent="
                                            toggleAnonymity();
                                            anonymityConfirmation = false;
                                        "
                                    >
                                        Yes
                                    </button>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <button
                                        id="modal-dismiss-btn"
                                        class="col-xs-12 btn btn-default"
                                        aria-label="No"
                                        @click="
                                            resetConfirmationField = '';
                                            agree = false;
                                            anonymityConfirmation = false;
                                            testObj.enableAnonymity = !testObj.enableAnonymity;
                                        "
                                    >
                                        No
                                    </button>
                                </div>
                            </div>
                            <div class="hidden-md hidden-lg">
                                <button
                                    id="modal-logout-btn"
                                    class="col-xs-12 btn btn-danger"
                                    :disabled="!canReset ? true : undefined"
                                    aria-label="Yes"
                                    @click.prevent="
                                        toggleAnonymity();
                                        anonymityConfirmation = false;
                                    "
                                >
                                    Yes
                                </button>
                                <button
                                    id="modal-dismiss-btn"
                                    class="col-xs-12 btn btn-default"
                                    aria-label="No"
                                    @click="
                                        resetConfirmationField = '';
                                        agree = false;
                                        anonymityConfirmation = false;
                                        testObj.enableAnonymity = !testObj.enableAnonymity;
                                    "
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer" />
                </div>
            </div>
        </div>

        <div id="timerSettingModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="timerSettingModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="timerSettingModal-title" class="modal-title">Symbolic Timer</h2>
                    </div>

                    <div class="modal-body">
                        <div class="flexOnly">
                            <label class="form-switch marginRight20">
                                <input
                                    v-model="testObj.symbolicTimer"
                                    type="checkbox"
                                    :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                                    aria-label="If turned on, simply use the timer as a guide. 
                  If turned off, students will be kicked out of the activity when the timer ends."
                                    @change="toggleSymbolicTimer()"
                                />
                                <span class="slider round" />
                            </label>
                            <div>
                                <div class="fontBold">Students remain in activity after timer ends.</div>
                                <div class="toggleDescriptWidth">
                                    If turned on, simply use the timer as a guide. If turned off, students will be kicked out of the activity when the timer
                                    ends.
                                </div>
                                <!-- <label class="switch marginLeft20">
                  <input
                    type="checkbox"
                    v-model="testObj.symbolicTimer"
                    @change="toggleSymbolicTimer()"
                    :disabled="(hasWritePrivilege(testObj.course) == -1) ? true:undefined"
                    aria-label="If turned on, students will be kicked out of the activity when
                  the timer ends. Turn this off to simply use the timer as a
                  guide."
                    :true-value="false"
                    :false-value="true"
                  />
                  <span class="slider round"></span>
                </label> -->
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer" />
                </div>
            </div>
        </div>

        <div id="activityExtendTimeModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="activityExtendTimeModal-title">
            <div class="modal-dialog widthAuto minWidthAuto">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="activityExtendTimeModal-title" class="modal-title">Extend Time for Activity</h2>
                    </div>
                    <div class="modal-body" tabindex="0">
                        <div class="justifyCenter flex">
                            <div class="inlineFlex">
                                <div class="paddingRight10">
                                    <div class="timeText">HOURS</div>
                                    <kr-field
                                        class="no-error"
                                        :form="timer_form"
                                        name="durationHours"
                                        :options="{
                                            type: 'number',
                                            min: 0,
                                            dateWidth: true,
                                            id: `durationHoursActivity`,
                                        }"
                                    />
                                </div>
                                <div class="paddingRight10">
                                    <div class="timeText">MINUTES</div>
                                    <kr-field
                                        class="no-error"
                                        :form="timer_form"
                                        name="durationMinutes"
                                        :options="{
                                            type: 'number',
                                            min: 0,
                                            max: 59,
                                            dateWidth: true,
                                            id: 'durationMinutesActivity',
                                        }"
                                    />
                                </div>
                                <div class="paddingTop25">
                                    <!-- <button @click.prevent="extendTime()" class="btn btn-primary mainBtn">
										Apply
									</button> -->
                                    <div class="dropdown">
                                        <button class="btn btn-primary" type="button" data-toggle="dropdown">
                                            Actions<i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-new">
                                            <ul>
                                                <li>
                                                    <!-- <button
                            class="btn btn-primary subBtn"
                            @click="extendTime()"
                            aria-label="Extend"
                          >
                            Extend
                          </button> -->
                                                    <a class="primary-state" href="#" role="button" @click="extendTime()" @keyup.space="extendTime()">
                                                        <i class="fa fa-plus marginRight8" aria-hidden="true" />Extend
                                                    </a>
                                                </li>

                                                <li>
                                                    <!-- <button
                            class="btn btn-danger subBtn"
                            @click="extendTime('-')"
                            aria-label="Deduct"
                          >
                            Deduct
                          </button> -->
                                                    <a class="danger-state" href="#" role="button" @click="extendTime('-')" @keyup.space="extendTime('-')">
                                                        <i class="fa fa-minus marginRight8" aria-hidden="true" />Deduct
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer" />
                </div>
            </div>
        </div>

        <div id="dashboardResetModal" class="modal alert-modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="dashboardResetModal-title">
            <div class="modal-dialog">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="dashboardResetModal-title" class="modal-title">Reset Activity</h2>
                    </div>

                    <div class="modal-body">
                        <img src="/img/warning.png" class="warning-icon" alt=" " />

                        <h1 class="textAlignCenter marginTop40">Reset</h1>

                        <h2 class="marginBottom20 textAlignCenter fw-normal">
                            {{ activityToReset.name }}
                        </h2>

                        <h3 class="textAlignCenter fw-normal">
                            Current data in
                            <b class="important">all</b> activity sections
                        </h3>

                        <h3 class="textAlignCenter fw-normal">will be <b class="important">lost</b> upon reset.</h3>

                        <h3 class="textAlignCenter fw-normal marginBottom10">This <b class="important">cannot be reversed</b>.</h3>

                        <h4 class="textAlignCenter fw-normal marginBottom5 marginTop30">
                            We recommend that you download <br />
                            the activity data first.
                        </h4>

                        <div class="grey checkboxField alignFlexStart marginTop30 marginBottom5">
                            <input v-model="agree" type="checkbox" />
                            <label class="fw-normal">I have downloaded the data for this activity.</label>
                        </div>

                        <label for="email">
                            <b> Type your email below to continue. </b>
                        </label>

                        <div class="col-xs-12 col-md-12">
                            <div class="form-group">
                                <input id="email" v-model="resetConfirmationField" class="form-control" :placeholder="`${auth.user().email}`" />
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer bg-white padding20 paddingTop10">
                        <div class="hidden-xs hidden-sm">
                            <button
                                id="modal-dismiss-btn"
                                class="btn btn-default"
                                data-dismiss="modal"
                                aria-label="Go Back"
                                @click="
                                    resetConfirmationField = '';
                                    agree = false;
                                    downloadedGrade = null;
                                "
                            >
                                Go Back
                            </button>

                            <button
                                id="modal-logout-btn"
                                class="btn btn-danger"
                                data-dismiss="modal"
                                :disabled="!canReset ? true : undefined"
                                aria-label="Reset Activity"
                                @click.prevent="
                                    downloadedGrade = null;
                                    reset();
                                "
                            >
                                Reset Activity
                            </button>
                        </div>
                        <div class="hidden-md hidden-lg">
                            <button
                                id="modal-dismiss-btn"
                                class="col-xs-12 btn btn-default"
                                data-dismiss="modal"
                                aria-label="Go Back"
                                @click="
                                    resetConfirmationField = '';
                                    agree = false;
                                    downloadedGrade = null;
                                "
                            >
                                Go Back
                            </button>
                            <button
                                id="modal-logout-btn"
                                class="col-xs-12 btn btn-danger"
                                data-dismiss="modal"
                                :disabled="!canReset ? true : undefined"
                                aria-label="Reset Activity"
                                @click.prevent="
                                    downloadedGrade = null;
                                    reset();
                                "
                            >
                                Reset Activity
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="dashboardEndModal"
            class="modal danger-modal dashboardEndModal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="dashboardEndModal-title"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="dashboardEndModal-title" class="modal-title">End Activity Confirmation</h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt=" " />
                        <h2 class="margin0 marginTop40">You are ending</h2>
                        <h2 class="marginBottom20 textAlignCenter fw-normal">
                            {{ testObj.name }}
                        </h2>
                        <br />
                        <p>
                            <b>Are you sure you want to continue?</b>
                        </p>
                        <p class="important">
                            <b>You will not be able to reverse this action.</b>
                        </p>
                    </div>
                    <div class="modal-footer bg-white padding20 paddingTop10">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Cancel</button>
                        <button class="btn btn-danger" type="button" data-dismiss="modal" @click="end()">End Activity</button>
                    </div>
                </div>
            </div>
        </div>

        <div id="setActivityPasswordModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="setActivityPasswordModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="setActivityPasswordModal-title" class="modal-title">ACTIVITY PASSWORD</h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-xs-12" style="text-align: center">
                                <div>Would you like to set a password for this activity?</div>
                                <div class="marginTop20">
                                    <kr-field :options="{ hasLabel: false }" :form="activity_password_form" name="password" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 buttonGroupInline">
                                <button
                                    class="btn btn-success"
                                    :disabled="activity_password_form.model.password == '' ? true : undefined"
                                    data-dismiss="modal"
                                    aria-label="Set"
                                    @click="start()"
                                >
                                    Set
                                </button>
                                <button
                                    id="modal-dismiss-btn"
                                    class="btn btn-default"
                                    data-dismiss="modal"
                                    aria-label="Skip"
                                    @click="
                                        activity_password_form.model.password = '';
                                        start();
                                    "
                                >
                                    Skip
                                </button>
                                <button id="modal-dismiss-btn" class="btn btn-default" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" tabindex="-1" role="dialog" aria-labelledby="clarificationModal-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="clarificationModal-title" class="modal-title">Clarification Toggle</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 col-md-10 col-md-offset-1 textAlignCenter">
                            <div>
                                You are about to enable clarifications for
                                <span class="fontBold">Interaction Design activityName.</span>
                            </div>
                            <div>Students will be able to clarify questions about this activity.</div>
                            <div>Are you sure?</div>
                        </div>
                        <div class="marginTop20 buttonGroup hidden-xs hidden-sm">
                            <button class="btn btn-success" aria-label="Start Allowing Students to Clarify">
                                <i class="fa fa-play marginRight8" aria-hidden="true" />Start Allowing Students to Clarify
                            </button>
                            <!-- <button class="btn btn-danger mainBtn">
			      				Disable Clarification
			      			</button> -->
                            <button class="btn btn-default" aria-label="Cancel"><i class="fa fa-time marginRight8" aria-hidden="true" />Cancel</button>
                        </div>
                        <div class="marginTop20 hidden-md hidden-lg">
                            <button class="col-xs-12 btn btn-success" aria-label="Start Allowing Students to Clarify">
                                <i class="fa fa-play marginRight8" aria-hidden="true" />Start Allowing Students to Clarify
                            </button>
                            <!-- <button class="col-xs-12 btn btn-danger mainBtn">
			      				Disable Clarification
			      			</button> -->
                            <button class="col-xs-12 btn btn-default" aria-label="Cancel">
                                <i class="fa fa-time marginRight8" aria-hidden="true" />Cancel
                            </button>
                        </div>
                    </div>
                    <div class="modal-footer" />
                </div>
            </div>
        </div>

        <div id="updateAsyncTimeModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="updateAsyncTimeModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="updateAsyncTimeModal-title" class="modal-title">Update Test Open Period</h2>
                    </div>
                    <div class="modal-body">
                        <div class="row create">
                            <div class="col-xs-12 flex">
                                <div v-if="['not started', 'scheduled'].includes(testObj.status)" class="col-xs-12 col-md-6">
                                    <kr-date
                                        display="ACTIVITY STARTS FROM"
                                        :form="async_time_form"
                                        name="startDate"
                                        :options="{
                                            parentEl: '#updateAsyncTimeModal',
                                            startDate: async_time_form.model.startDate,
                                            maxDate: async_time_form.model.endDate,
                                            time: true,
                                            placeholder: 'Select module start date',
                                        }"
                                    />
                                </div>
                                <div v-else class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label">ACTIVITY STARTS FROM</label>
                                        <p class="form-control-static">
                                            <i class="far fa-calendar-alt marginRight8" aria-hidden="true" />
                                            {{ convertToReadableDate(testObj.settings.startDate, 'YYYY-MM-DD hh:mm:ss').date }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <kr-date
                                        display="ACTIVITY ENDS ON"
                                        :form="async_time_form"
                                        name="endDate"
                                        :options="{
                                            parentEl: '#updateAsyncTimeModal',
                                            startDate: async_time_form.model.endDate,
                                            minDate: minDate,
                                            time: true,
                                            placeholder: 'Select module end date',
                                        }"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="marginTop30">
                            <div class="col-xs-12 buttonGroupInline">
                                <button class="btn btn-success" data-dismiss="modal" aria-label="Update" @click="updateAsyncTime()">Update</button>
                                <button id="modal-dismiss-btn" class="btn btn-default" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="gradesExportConfirmationModal"
            class="modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="gradesExportConfirmationModal-title"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="gradesExportConfirmationModal-title" class="modal-title">Generate Activity Grades Report</h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-xs-12" style="text-align: left">
                                <!--<div>We will generate the Grades Report for this activity and notify you once finished (approx. 2-3 minutes). You can download the grades at the <router-link :to="{name:'courses.view',params:{id:testObj.course.uuid},query:{tab:'downloadables'}}"target="_blank">Course Downloadables</router-link> section.</div>-->
                                <div>You will be notified when your Grades Report is ready (approx. 1-2 minutes).</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="flex">
                                <div class="col-xs-12 col-md-6">
                                    <button
                                        id="modal-logout-btn"
                                        class="btn btn-success"
                                        style="width: 100%; padding-right: 10px"
                                        data-dismiss="modal"
                                        aria-label="Yes, Proceed"
                                        @click="emailGrades()"
                                    >
                                        Yes, Proceed
                                    </button>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <button id="modal-dismiss-btn" class="btn btn-default" style="width: 100%" data-dismiss="modal" aria-label="Cancel">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            id="gradesPassbackConfirmationModal"
            class="modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="gradesPassbackConfirmationModal-title"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="gradesPassbackConfirmationModal-title" class="modal-title">
                            Important Notice: Posting Grades to LMS ({{ testObj.course.ltiCourseContext }})
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-xs-12" style="text-align: left">
                                <div>
                                    <span class="fontBold fontSize22"
                                        >You are about to share the grades for this test within your Learning Management System ({{
                                            testObj.course.ltiCourseContext
                                        }}).</span
                                    ><br /><br />
                                    Please be aware that this functionality is currently in the beta phase and is part of LTI 1.3 Assignment and Grades Services
                                    (Grade Passback). <br /><br />
                                    To proceed, ensure that you are connected as a teacher using LTI 1.3. This action will create an assignment in your LMS and
                                    post the grades accordingly.
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="flex">
                                <div class="col-xs-12 col-md-6">
                                    <button
                                        id="modal-logout-btn"
                                        class="btn btn-success"
                                        style="width: 100%; padding-right: 10px"
                                        data-dismiss="modal"
                                        aria-label="Yes, Proceed"
                                        @click="processGradePassback()"
                                    >
                                        Yes, Proceed
                                    </button>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <button id="modal-dismiss-btn" class="btn btn-default" style="width: 100%" data-dismiss="modal" aria-label="Cancel">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="earlyStartModal"
            class="modal warning-modal earlyStartModal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="earlyStartModal-title"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal" @click="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="earlyStartModal-title" class="modal-title">Early Start</h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt=" " />
                        <h2 class="margin0 marginTop40">You are starting this activity</h2>
                        <h2 class="margin0">
                            <span class="color-dark-blue">
                                {{ testObj.earlyStartDurationInMinutes }}
                                minutes
                            </span>
                            early for
                        </h2>
                        <br />

                        <!-- <dl>
                        <dt>Student Name:</dt>
                        <dd v-for="student in selectedStudents" class="marginTop10">
                            <span class="inlineFlex">
                                {{ student.fullname }}

                                <div
                                    v-tooltip="{
                                        content:
                                            'Has extra time',
                                    }"
                                    class="attendanceTag-purple flex align-items marginLeft10"
                                >
                                    <i
                                        class="fa fa-clock marginRight8"
                                        aria-hidden="true"
                                    />
                                    <p v-if="student.extraTimeValues">
                                        {{
                                            extendedTime(
                                                student.extraTimeValues
                                            )
                                        }}
                                    </p>
                                    <p v-else>
                                        -
                                    </p>
                                </div>
                            </span>
                        </dd>
                    </dl> -->
                        <p>
                            <b>If students submit during Early Start,</b>
                        </p>
                        <p>
                            <b>they will not be allowed to enter the activity again.</b>
                        </p>
                        <p class="important">
                            <b>Ending the test manually will apply to all students.</b>
                        </p>
                    </div>
                    <div class="modal-footer bg-white padding20 paddingTop10">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Cancel</button>
                        <button class="btn btn-success" type="button" data-dismiss="modal" @click="earlyStart()">Start Activity</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="noticeEarlyStartModal"
            class="modal danger-modal noticeEarlyStartModal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="noticeEarlyStartModal-title"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="noticeEarlyStartModal-title" class="modal-title">Start IRAT</h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt=" " />
                        <h2 class="margin0 marginTop40">You are starting this activity</h2>
                        <h2 class="margin0">
                            without
                            <span class="color-dark-blue"> Early Start </span>
                        </h2>
                        <br />
                        <p>
                            <b>Are you sure you want to continue?</b>
                        </p>
                        <p class="important">
                            <b>You will not be able to reverse this action.</b>
                        </p>
                    </div>
                    <div class="modal-footer bg-white padding20 paddingTop10">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Cancel</button>
                        <button class="btn btn-success" type="button" data-dismiss="modal" @click="start()">Start Activity</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="earlyEndModal"
            class="modal danger-modal earlyEndModal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="earlyEndModal-title"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="earlyEndModal-title" class="modal-title">End Early Start Confirmation</h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt=" " />
                        <h2 class="margin0 marginTop40">
                            You are ending
                            <span class="color-dark-blue"> Early Start </span>
                        </h2>
                        <br />
                        <br />
                        <p>Ending Early Start will only end the early start session.</p>
                        <p>You can still proceed to start the actual activity after this.</p>
                        <br />
                        <br />
                        <p>
                            <b>Are you sure you want to continue?</b>
                        </p>
                        <p class="important">
                            <b>You will not be able to reverse this action.</b>
                        </p>
                    </div>
                    <div class="modal-footer bg-white padding20 paddingTop10">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Cancel</button>
                        <button class="btn btn-danger" type="button" data-dismiss="modal" @click="earlyEnd()">End Early Start</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="startSelectionsModal"
            class="modal warning-modal startSelectionsModal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="startSelectionsModal-title"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal" @click="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="startSelectionsModal-title" class="modal-title">Start Options</h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" class="warning-icon" alt=" " />
                        <h2 class="margin0 marginTop40">How would you like to start this activity?</h2>

                        <div class="displayInlineBlock maxWidth560px marginTop30 marginBottom30">
                            <button
                                class="btn btn-default modalSelections d-block whiteSpaceInherit width100"
                                :class="{
                                    activatedPrimary: startType == 'early start',
                                }"
                                role="radio"
                                :aria-checked="startType == 'early start' ? true : false"
                                @click="startType = 'early start'"
                            >
                                <div class="inlineFlex align-items">
                                    <i v-if="startType == 'early start'" class="fa fa-play marginRight8" aria-hidden="true" />
                                    <label> Activate Early Start </label>
                                    <div class="attendanceTag-blue inlineFlex align-items marginLeft8 fw-normal fontBlack paddingBottom3">
                                        <i class="fa fa-clock marginRight8" aria-hidden="true" />
                                        {{ testObj.earlyStartDurationInMinutes }}
                                        mins
                                    </div>
                                </div>
                                <p class="fw-normal fontSize14">
                                    Early Start allows selected students to access the activity for a designated duration ahead of other students.
                                </p>
                            </button>

                            <button
                                class="btn btn-default d-block modalSelections whiteSpaceInherit marginTop20 marginLeft0 width100"
                                :class="{
                                    activatedPrimary: startType == 'start irat',
                                }"
                                role="radio"
                                :aria-checked="startType == 'start irat' ? true : false"
                                @click="startType = 'start irat'"
                            >
                                <div class="inlineFlex align-items">
                                    <i v-if="startType == 'start irat'" class="fa fa-play marginRight8" aria-hidden="true" />
                                    <label>
                                        Start
                                        <template v-if="testObj.type == 'irat'"> IRAT </template>
                                        <template v-else-if="testObj.type == 'application'"> Application </template>
                                        <template v-else-if="testObj.type == 'evaluation'"> 360 Degree Evaluation </template>
                                        <template v-else-if="testObj.type == 'michaelsenEvaluation'"> Peer Evaluation: Michaelsen’s Method </template>
                                        for all
                                    </label>
                                </div>
                                <p class="fw-normal fontSize14">
                                    Start
                                    <template v-if="testObj.type == 'irat'"> IRAT </template>
                                    <template v-else-if="testObj.type == 'application'"> Application </template>
                                    <template v-else-if="testObj.type == 'evaluation'"> 360 Degree Evaluation </template>
                                    <template v-else-if="testObj.type == 'michaelsenEvaluation'"> Peer Evaluation: Michaelsen’s Method </template>
                                    starts the activity for all students without an Early Start window.
                                </p>
                            </button>
                        </div>

                        <p>
                            After Early Start ends, you will be able to
                            <br />
                            Start
                            <template v-if="testObj.type == 'irat'"> IRAT </template>
                            <template v-else-if="testObj.type == 'application'"> Application </template>
                            <template v-else-if="testObj.type == 'evaluation'"> 360 Degree Evaluation </template>
                            <template v-else-if="testObj.type == 'michaelsenEvaluation'"> Peer Evaluation: Michaelsen’s Method </template>
                            as per normal.
                        </p>
                        <br />
                        <p>
                            <b>Start the activity now?</b>
                        </p>
                    </div>
                    <div class="modal-footer bg-white padding20 paddingTop10">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Go Back</button>
                        <button
                            v-tooltip="startType == null ? 'Please select at least 1 option' : ''"
                            class="btn btn-success"
                            :class="{
                                'disabled hasToolTip': startType == null,
                            }"
                            type="button"
                            data-dismiss="modal"
                            @click="startType == null ? '' : startType == 'early start' ? earlyStart() : start()"
                        >
                            <i class="fa fa-play marginRight8" aria-hidden="true" />Start
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import KrForm from '../../../../../../../components/forms/form';
import KrAuth from '../../../../../../../components/auth/auth';
export default {
    components: {
        'topbar-timer': require(`./topbar-timer.vue`).default,
        'topbar-timer-fixed': require(`./topbar-timer-fixed.vue`).default,
    },
    props: ['testObj'],
    data() {
        let auth = new KrAuth();
        return {
            component_done_loading: true,
            auth: auth,
            agree: false,
            isOpened: false,
            timer_form: new KrForm({
                fields: {
                    //durationType:'',//extend,deduct
                    durationDays: 'default!:0',
                    durationHours: 'default!:0',
                    durationMinutes: 'default!:5',
                },
                props: {
                    successMessage: false,
                    failureMessage: false,
                },
                urls: {
                    post: 'activities',
                },
            }),
            activity_password_form: new KrForm({
                fields: {
                    password: '',
                },
                props: {
                    successMessage: false,
                    failureMessage: false,
                },
            }),
            async_time_form: new KrForm({
                fields: {
                    startDate: '',
                    endDate: '',
                },
                props: {
                    successMessage: false,
                    failureMessage: false,
                },
            }),
            readOnlyLabel: 'You have read-only access',
            resetConfirmationField: '',
            anonymityConfirmation: false,
            retrieveModulesData: null,
            numberOfDrops: null,
            sectionInnerHeight: null,
            top: null,
            isNavDropDown: false,
            minDate: '',
            activityToReset: {},
            scrollDelta: 0,
            scrollTime: 300,
            scrollLeft: 0,
            tabContainerUL: null,
            tabContainer: '',
            startType: null,
        };
    },
    computed: {
        canStart() {
            return (
                ['not started', 'scheduled'].includes(this.testObj.status) && this.testObj.settings.type == 'synchronous' && !this.testObj.expectedEndingTime
            );
        },
        canStartEarly() {
            return (
                ['not started'].includes(this.testObj.status) &&
                this.testObj.earlyStartDurationInMinutes &&
                !this.testObj.expectedEndingTime &&
                !this.testObj.startedEarly &&
                (['irat', 'evaluation', 'michaelsenEvaluation'].includes(this.testObj.type) ||
                    (this.testObj.type == 'application' && this.testObj.others.applicationType == 'individual'))
            );
        },
        canEndEarly() {
            if (this.testObj.expectedEndingTime && this.testObj.startedEarly) {
                return true;
            }
            return false;
        },
        canReset() {
            if (this.agree && this.resetConfirmationField.toLowerCase() == this.auth.user().email.toLowerCase()) {
                return true;
            }
            return false;
        },
        canScrollLeft() {
            if (this.tabContainerUL <= $('.dashboard-activities-nav').width()) {
                return true;
            } else if (this.scrollLeft <= 0) {
                return true;
            }

            return false;
        },
        canScrollRight() {
            if (this.tabContainerUL <= $('.dashboard-activities-nav').width()) {
                return true;
            } else if (this.scrollDelta + this.scrollLeft >= this.tabContainerUL) {
                return true;
            }

            return false;
        },
        canPostGradesToLms() {
            if (
                ([
                    '08299f88-6223-49aa-84e7-1958d703de6e',
                    'd1505db9-f490-4d30-81c8-0501410aecee',
                    '8171c7da-3725-4f43-b871-dc52272610ec',
                    '937caf1e-aa76-4f46-a391-86aef3941638',
                    '58be2094-6f74-4087-bba5-ace0b7e3b651',
                    '8171c7da-3725-4f43-b871-dc52272610ec',
                ].includes(this.auth.user().account.uuid) ||
                    process.env.VUE_APP_ENV == 'staging') &&
                this.testObj.course.ltiCourseContext &&
                !['evaluation', 'michaelsenEvaluation'].includes(this.testObj.type) &&
                !(this.testObj.type == 'application' && !this.testObj.others.isApplicationGraded)
            ) {
                return true;
            }
            return false;
        },
    },
    created() {
        const titleCase = (s) => s.replace(/\b\w/g, (c) => c.toUpperCase());
        var toUpperCase = titleCase(this.testObj.type);

        if (['irat', 'trat'].includes(this.testObj.type)) {
            toUpperCase = this.testObj.type.toUpperCase();
        } else if (this.testObj.type == 'michaelsenEvaluation') {
            toUpperCase = 'Evaluation - Michaelsen';
        } else if (this.testObj.type == 'evaluation') {
            toUpperCase = 'Evaluation - 360’';
        }

        document.title = `${this.testObj.name} - Dashboard | ${toUpperCase} | ${this.testObj.moduleName}  | InteDashboard | TBL Makes Teams Work`;
        // this.fetch();
    },
    mounted() {
        var that = this;

        $(window).on('resize.extraNav', function () {
            that.extraNav();
        });

        this.fetch().then(() => {
            that.$nextTick(() => {
                if (that.$refs.dashboardNavUL) {
                    that.tabContainerUL = that.$refs.dashboardNavUL.scrollWidth;
                }
            });
        });
    },
    updated() {
        var that = this;

        that.extraNav();
    },
    beforeUnmount() {
        $(window).off('resize.extraNav');
    },
    methods: {
        emailGrades() {
            var that = this;
            axios({
                url: '/tests/' + this.testObj.uuid + '/grades',
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                /*const url = window.URL.createObjectURL(new Blob([response.data]));
	              const link = document.createElement('a');
	              link.href = url;
	              link.setAttribute('download',course.name+'_'+moment().format('YYYY_MM_DD')+'.xlsx');
	              document.body.appendChild(link);
	              link.click();
	              link.remove();*/
                that.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'We will generate the Grades Report for this activity and notify you once finished',
                });
            });
        },
        processGradePassback() {
            var that = this;
            axios({
                url: `lti/1p3/tests/${this.testObj.uuid}/grade-passback`,
                method: 'POST',
            })
                .then((response) => {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'We will process grade passback for this activity and notify you once finished',
                    });
                })
                .catch(function (error) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Something went wrong, please try again later',
                    });
                });
        },
        openGradesExportConfirmationModal() {
            $('#gradesExportConfirmationModal').modal('show');
        },
        openGradesPassbackConfirmationModal() {
            $('#gradesPassbackConfirmationModal').modal('show');
        },
        openInfoModal() {
            //return;
            $('#otherInfoModal').modal('show');
        },
        openSettingsModal() {
            //return;
            this.agree = false;
            this.resetConfirmationField = '';
            $('#settingsModal').modal('show');
        },
        openExtendTimeModal() {
            //return;
            $('#activityExtendTimeModal').modal('show');
        },
        openTimerSettingModal() {
            $('#timerSettingModal').modal('show');
        },
        updateAsyncTime() {
            var that = this;
            axios
                .post('tests/' + that.testObj.uuid + '/update-period', this.async_time_form.model)
                .then(function (response) {
                    if (that.testObj.status == 'ended') {
                        that.testObj.status = 'ongoing';
                    }
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Updated open period',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to update open period at this moment, please try again later',
                    });
                });
        },
        openAsyncTimeModal() {
            //this.openStartPasswordModal();
            this.minDate = moment().utc().format('YYYY-MM-DD HH:mm:ss');
            this.async_time_form.model.startDate = this.testObj.settings.startDate;
            this.async_time_form.model.endDate = this.testObj.settings.endDate;
            //return;
            $('#updateAsyncTimeModal').modal('show');
        },
        openEndConfirmationModal() {
            $('.dashboardEndModal').modal('show');
        },
        end() {
            var that = this;
            axios
                .post('tests/' + that.testObj.uuid + '/end')
                .then(function (response) {
                    that.testObj.status = 'ended';
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Test has been ended',
                    });

                    $('.dashboardEndModal').modal('hide');
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to end test at this moment, please try again later',
                    });
                });
        },
        openResetConfirmationModal(activity) {
            //this.openStartPasswordModal();
            //return;

            this.activityToReset = activity;
            $('#dashboardResetModal').modal('show');
        },
        reset() {
            var that = this;
            axios
                .post('activities/' + that.testObj.activityUuid + '/unpublish')
                .then(function (response) {
                    //that.testObj.status = 'ongoing';
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Activity has been reset',
                    });
                    that.$router.push({
                        name: 'courses.modules.activities.republish',
                        params: { id: that.testObj.activityUuid },
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to reset activity at this moment, please try again later',
                    });
                });
        },
        resume() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/resume')
                .then(function (response) {
                    that.testObj.status = 'ongoing';
                    var endTime = that.convertToReadableDate(_.get(that.testObj, 'endTime', '')).dateObj;
                    var timePaused = that.convertToReadableDate(_.get(that.testObj, 'timePaused', '')).dateObj;
                    var distance = moment.duration(endTime.diff(timePaused));
                    that.testObj.endTime = moment().add(distance, 'seconds').utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z';
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Test has been resumed',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to resume test at this moment, please try again later',
                    });
                });
        },
        pause() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/pause')
                .then(function (response) {
                    that.testObj.status = 'paused';
                    that.testObj.timePaused = moment().utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z';
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Test has been paused',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to pause test at this moment, please try again later',
                    });
                });
        },
        openStartPasswordModal() {
            this.activity_password_form.model.password = this.testObj.password ? this.testObj.password : '';
            $('#setActivityPasswordModal').modal('show');
        },
        start() {
            var that = this;
            //Vue.set(this.testObj,password,this.activity_password_form.model.password);
            //alert('here');
            axios
                .post('tests/' + this.testObj.uuid + '/start')
                .then(function (response) {
                    that.testObj.startTime = moment();
                    that.testObj.endTime = moment().add(that.testObj.plannedDurationInSeconds, 'seconds').utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z';
                    that.testObj.status = 'ongoing';
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Test has been started',
                    });

                    $('.noticeEarlyStartModal').modal('hide');
                    $('.startSelectionsModal').modal('hide');
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to start test at this moment, please try again later',
                    });
                });
        },
        earlyStart() {
            var that = this;
            //Vue.set(this.testObj,password,this.activity_password_form.model.password);
            //alert('here');
            axios
                .post('tests/' + this.testObj.uuid + '/early-start')
                .then(function (response) {
                    that.testObj.startedEarly = true;
                    that.testObj.expectedEndingTime =
                        moment().add(that.testObj.earlyStartDurationInMinutes, 'minutes').utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z';
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Test has been started early',
                    });

                    $('.earlyStartModal').modal('hide');
                    $('.startSelectionsModal').modal('hide');
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to start test early at this moment, please try again later',
                    });
                });
        },
        earlyEnd() {
            var that = this;
            //Vue.set(this.testObj,password,this.activity_password_form.model.password);
            //alert('here');
            axios
                .post('tests/' + this.testObj.uuid + '/early-start/end')
                .then(function (response) {
                    that.testObj.startedEarly = true;
                    that.testObj.expectedEndingTime = null;

                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Early start test has been ended',
                    });

                    $('.earlyEndModal').modal('hide');
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to end test early at the moment, please try again later',
                    });
                });
        },
        visible() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/visible')
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Test visibility has been successfully changed',
                    });
                })
                .catch(function (errors) {
                    that.testObj.isVisible = !that.testObj.isVisible;
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to change test visibility at this moment, please try again later',
                    });
                });
        },
        toggleViewAnswer() {
            var that = this;
            var data = {};
            axios
                .post('tests/' + this.testObj.uuid + '/answer-visibility')
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Test answer visibility has been successfully changed',
                    });
                    if (that.testObj.allowStudentsToViewAnswer) {
                        if (!that.testObj.isPublished) {
                            that.togglePublish();
                        }
                    }
                })
                .catch(function (errors) {
                    that.testObj.allowStudentsToViewAnswer = !that.testObj.allowStudentsToViewAnswer;
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to change test answer visibility at this moment, please try again later',
                    });
                });
        },
        toggleViewScore() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/score-visibility')
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Test score visibility has been successfully changed',
                    });
                    if (that.testObj.allowStudentsToViewAnswer) {
                        if (!that.testObj.isPublished) {
                            that.togglePublish();
                        }
                    }
                })
                .catch(function (errors) {
                    that.testObj.allowStudentsToViewScore = !that.testObj.allowStudentsToViewScore;
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to change test score visibility at this moment, please try again later',
                    });
                });
        },
        toggleViewPaf() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/paf-scores-visibility')
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Test paf visibility has been successfully changed',
                    });
                })
                .catch(function (errors) {
                    that.testObj.allowStudentsToViewPafScores = !that.testObj.allowStudentsToViewPafScores;
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to change test paf visibility at this moment, please try again later',
                    });
                });
        },
        toggleViewSapa() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/sapa-scores-visibility')
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Test sapa visibility has been successfully changed',
                    });
                })
                .catch(function (errors) {
                    that.testObj.allowStudentsToViewSapaScores = !that.testObj.allowStudentsToViewPafScores;
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to change test sapa visibility at this moment, please try again later',
                    });
                });
        },
        toggleViewAttachments() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/attachment-visibility')
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Test attachments visibility has been successfully changed',
                    });
                    if (that.testObj.allowStudentsToViewAnswer) {
                        if (!that.testObj.isPublished) {
                            that.togglePublish();
                        }
                    }
                })
                .catch(function (errors) {
                    that.testObj.allowStudentsToViewAttachments = !that.testObj.allowStudentsToViewAttachments;
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to change test attachments visibility at this moment, please try again later',
                    });
                });
        },
        toggleAnonymity() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/student-anonymity')
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Test student anonymity has been successfully changed',
                    });
                    that.resetConfirmationField = '';
                    that.agree = false;
                })
                .catch(function (errors) {
                    that.testObj.enableAnonymity = !that.testObj.enableAnonymity;
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to change test student anonymity at this moment, please try again later',
                    });
                });
        },
        togglePresentationAnonymity() {
            var that = this;
            axios
                .post(
                    'tests/' +
                        this.testObj.uuid +
                        '/anonymity-in-presentation-mode'
                )
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Test student presentation anonymity has been successfully changed',
                    });
                })
                .catch(function (errors) {
                    that.testObj.enableAnonymityInPresentationMode =
                        !that.testObj.enableAnonymityInPresentationMode;
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to change test student presentation anonymity at this moment, please try again later',
                    });
                });
        },
        toggleEgalleryAnonymity() {
            var that = this;
            axios
                .post(
                    'tests/' + this.testObj.uuid + '/anonymity-in-egallery-walk'
                )
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Test student e-gallery anonymity has been successfully changed',
                    });
                })
                .catch(function (errors) {
                    that.testObj.enableAnonymityInEGalleryWalk =
                        !that.testObj.enableAnonymityInEGalleryWalk;
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to change test student e-gallery anonymity at this moment, please try again later',
                    });
                });
        },
        togglePreviewQuestion() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/question-preview')
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Preview question visibility has been successfully changed',
                    });
                })
                .catch(function (errors) {
                    that.testObj.allowStudentsToPreviewQuestions = !that.testObj.allowStudentsToPreviewQuestions;
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to change test preview question visibility at this moment, please try again later',
                    });
                });
        },
        togglePublish() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/publish', {})
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Test publish has been successfully changed',
                    });
                })
                .catch(function (errors) {
                    that.testObj.isPublished = !that.testObj.isPublished;
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to change publish status at this moment, please try again later',
                    });
                });
        },
        toggleSymbolicTimer() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/symbolic-timer', {})
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Symbolic timer has been successfully changed',
                    });
                })
                .catch(function (errors) {
                    that.testObj.symbolicTimer = !that.testObj.symbolicTimer;
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to change symbolic timer at this moment, please try again later',
                    });
                });
        },
        extendTime(type = '') {
            var that = this;
            if (type == '-') {
                that.timer_form.model.durationDays = 0;
                that.timer_form.model.durationHours = parseInt(that.timer_form.model.durationHours) * -1;
                that.timer_form.model.durationMinutes = parseInt(that.timer_form.model.durationMinutes) * -1;
            }
            let days = that.timer_form.model.durationDays;
            let hours = that.timer_form.model.durationHours;
            let minutes = that.timer_form.model.durationMinutes;

            this.timer_form.post('tests/' + this.testObj.uuid + '/extend').then(function (response) {
                if (!response.krFormError) {
                    var endTime = that.convertToReadableDate(_.get(that.testObj, 'endTime', '')).dateObj;
                    let offset = moment();
                    if (['completed', 'ended'].includes(that.testObj.status)) {
                        offset = moment();
                        that.testObj.status = 'ongoing';
                    } else {
                        offset = endTime;
                    }
                    that.testObj.endTime = offset.add(days, 'days').add(hours, 'hours').add(minutes, 'minutes').utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z';
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: type == '-' ? 'Main Activity Time has been deducted' : 'Main Activity Time has been extended',
                    });

                    that.timer_form.model.durationDays = 0;
                    that.timer_form.model.durationHours = 0;
                    that.timer_form.model.durationMinutes = 5;
                    $('#activityExtendTimeModal').modal('hide');
                } else {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to extend test at this moment, please try again later',
                    });
                }
            });
        },
        toggle(event) {
            this.isOpened = !this.isOpened;
        },
        async fetch() {
            let course;
            let moduleData;

            let url = '/courses/' + this.testObj.course.uuid;
            await axios.get(url).then(function (response) {
                course = response.data.data;
            });

            if (course.isArchived == false) {
                await axios.get(url + '/modules?isArchived=0').then(function (response) {
                    moduleData = response.data.data;
                });
            }
            /*console.log(moduleData)
      var k = moduleData.flatMap((moduleData, i) => {
        const j = moduleData.activities.findIndex(
          (z) => z.uuid === this.testObj.activityUuid
        );

        // return j > -1 ? [i, j] : [];
        return j > -1 ? i : [];
      });
      console.log(k)*/
            let k = -1;
            for (var i = 0; i < moduleData.length; i++) {
                let j = moduleData[i].activities.findIndex((z) => z.uuid === this.testObj.activityUuid);
                if (j != -1) {
                    k = i;
                    break;
                }
            }
            this.retrieveModulesData = k != -1 ? moduleData[k].activities : [];
        },
        extraNav() {
            // var that = this;
            // var highest;
            // var of;
            // var sectionInnerHeight;
            // var top = 0;
            // that.val = 0;
            // that.h = 32;
            // highest = 0;
            // $(".dashboard-nav .nav.nav-pills li.nav-item").each(function() {
            //   of =
            //     $(this).offset().top -
            //     $(".dashboard-nav .nav.nav-pills").offset().top;
            //   if (of > 20) {
            //     $(this).addClass("drop");
            //     $(this).css("top", "calc(100% + " + that.val + "px)");
            //     sectionInnerHeight = $(this).innerHeight();
            //     $(this).css("top", "calc(100% + " + top + "px)");
            //     top += sectionInnerHeight;
            //     highest = Math.max(highest, parseFloat($(this).width()));
            //     console.log("this is the highest width " + highest);
            //     $(".drop").css("width", highest + "px");
            //     that.val += that.h;
            //   }
            //   that.numberOfDrops = $(".drop").length;
            // });
            // if (!$(".dashboard-nav .nav.nav-pills").hasClass("show")) {
            //   $(".drop").css("visibility", "hidden");
            // }
            // if (!$(".dashboard-nav .nav.nav-pills").hasClass("show")) {
            //   $(".drop").css("visibility", "hidden");
            // }

            var that = this;
            // that.numberOfDrops = 0;
            // that.top = 0;

            // if (this.isNavDropDown) {
            //   console.log($(".addToDrops").length);
            //   $(".dashboard-nav .nav.nav-pills li.nav-item.addToDrops").each(
            //     function() {
            //       $(this)
            //         .clone()
            //         .appendTo(".emptyNavDropdown ul");
            //     }
            //   );
            // } else {
            //   $(".emptyNavDropdown ul").empty();
            // }

            $('.emptyNavDropdown ul').empty();

            $('.dashboard-nav .nav.nav-pills li.nav-item').each(function () {
                var of = $(this).offset().top - $('.dashboard-nav .nav.nav-pills').offset().top;

                if (of > 20) {
                    $(this).addClass('addToDrops');
                    // that.sectionInnerHeight = $(this).innerHeight();
                    // $(this).css("top", "calc(100% + " + that.top + "px)");
                    // that.top += that.sectionInnerHeight;

                    // $(this).css("top", "calc(100% + " + that.val + "px)");
                    // that.val += that.h;
                } else {
                    $(this).removeClass('addToDrops');
                }
            });

            // if (this.isNavDropDown) {
            //   console.log(this.isNavDropDown);
            //   console.log($(".addToDrops").length);
            //   // $(".addToDrops")
            //   //   .clone()
            //   //   .appendTo(".emptyNavDropdown");
            //   $(".addToDrops").appendTo(".emptyNavDropdown ul");
            // } else {
            //   $(".addToDrops").appendTo(".dashboard-nav .nav.nav-pills");
            // }

            that.numberOfDrops = $('.addToDrops').length;
            // console.log(that.numberOfDrops);

            Vue.nextTick(function () {
                var clonetype = $('.dashboard-nav .nav.nav-pills li.nav-item.addToDrops').clone(true);

                $('.emptyNavDropdown ul').append(clonetype);
            });
        },
        toggleClick() {
            // if ($(".dashboard-nav .nav.nav-pills").hasClass("show")) {
            //   $(".dashboard-nav .nav.nav-pills").removeClass("show");
            //   $(".dashboard-nav .nav.nav-pills li.nav-item").removeClass("drop");
            //   return;
            // }

            // this.extraNav();
            // $(".dashboard-nav .nav.nav-pills").addClass("show");

            // if ($(".dashboard-nav .nav.nav-pills").hasClass("show")) {
            //   $(".dashboard-nav .nav.nav-pills").removeClass("show");
            //   $(".dashboard-nav .nav.nav-pills li.nav-item").removeClass("drop");
            //   return;
            // }

            // this.extraNav();

            // $(".dashboard-nav .nav.nav-pills li.nav-item").each(function() {
            //   var of =
            //     $(this).offset().top -
            //     $(".dashboard-nav .nav.nav-pills").offset().top;
            //   if (of > 20) {
            //     $(this).addClass("drop");
            //   }
            // });

            // $(".dashboard-nav .nav.nav-pills").addClass("show");
            this.isNavDropDown = !this.isNavDropDown;
            // console.log(this.isNavDropDown);
        },
        scrollHeader(delta) {
            var headers = $('.dashboard-activities-nav ul');
            var oldPosition = headers.scrollLeft();
            headers.animate(
                {
                    scrollLeft: oldPosition + delta,
                },
                this.scrollTime
            );

            this.scrollLeft = oldPosition + delta;
        },
        tabLeft(e) {
            this.scrollDelta = $('.dashboard-activities-nav').width();
            this.scrollHeader(-this.scrollDelta);
            e.preventDefault();
        },
        tabRight(e) {
            this.scrollDelta = $('.dashboard-activities-nav').width();
            this.scrollHeader(this.scrollDelta);
            e.preventDefault();
        },
        openEarlyStartModal() {
            $('.earlyStartModal').modal('show');
        },
        tratGoTo() {
            this.$router.push({
                name: 'activities.tests.dashboard.trat-presentation',
                params: { id: this.testObj.uuid },
                query: { tab: 'leaderboard' },
            });
        },
        applicationGoTo() {
            this.$router.push({
                name: 'activities.tests.questions',
                params: { id: this.testObj.uuid },
            });
        },
        openNoticeEarlyStartModal() {
            $('.noticeEarlyStartModal').modal('show');
        },
        openEarlyEndModal() {
            $('.earlyEndModal').modal('show');
        },
        openStartSelectionsModal() {
            $('.startSelectionsModal').modal('show');
        },
    },
    components: {
        'topbar-timer': require(`./topbar-timer.vue`).default,
        'topbar-timer-fixed': require(`./topbar-timer-fixed.vue`).default,
    },
};
</script>

<style scoped lang="scss">
.outlineSuccess {
    color: #0f9b81;
    border: 1px solid #0f9b81;
    padding: 6px 12px;
    font-size: 0.88rem;
    border-radius: 3px;
}
.outlineDanger {
    color: #dc3c42;
    border: 1px solid #dc3c42;
    padding: 6px 12px;
    font-size: 0.88rem;
    border-radius: 3px;
}
.outlineGrey {
    color: #767676;
    border: 1px solid #989b9c;
    padding: 6px 12px;
    font-size: 0.88rem;
    border-radius: 3px;
}

a .fa-stack,
button .fa-stack {
    width: 18px;
    height: 18px;
    line-height: 1;
}

a.mainBtn {
    line-height: 23px;
}
.timeText {
    color: #222;
    // font-size: 12px;
    font-size: 0.667rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: -17px;
}
#dashboardResetModal .modal-body .row:last-child {
    margin-top: 20px;
}

#dashboardResetModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#dashboardResetModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}

.timerTopBar {
    background: rgb(255, 255, 255);
    border: 1px solid #d8d8d8;
    border-radius: 3px;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);

    &.bg-light-blue {
        background: #dbfbff;
    }
    .btn {
        flex: inherit;
    }
}

.krToggle {
    display: flex;
    align-items: center;
}

.krToggle .control-label {
    margin-bottom: 0;
    line-height: 0.8;
}

.toggleDescriptWidth {
    width: calc(100% - 60px);
}
#gradesExportConfirmationModal .modal-body .row:last-child {
    margin-top: 30px;
}

#gradesExportConfirmationModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#gradesExportConfirmationModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}

#gradesPassbackConfirmationModal .modal-body .row:last-child {
    margin-top: 30px;
}

#gradesPassbackConfirmationModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#gradesPassbackConfirmationModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}
#settingsModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#settingsModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}

// 25 Mar
.dashboard-nav {
    position: relative;

    .nav.nav-pills {
        overflow: hidden;
        max-height: 34px;

        & > li + li {
            margin-left: 10px;
        }

        &.show {
            overflow: visible;
        }

        .nav-item {
            .nav-link {
                line-height: 1;
                padding: 0.5rem 1rem;
                border: 1px solid #989b9c;
                background: #fff;
                border-radius: 0.25rem;

                .activity_name,
                .test_name {
                    display: none;
                }

                &.active,
                &:hover {
                    color: #fff;
                    background-color: #007bff;
                    border: 1px solid #007bff;
                }

                &:hover {
                    .activity_name,
                    .test_name {
                        display: block;
                    }
                }
            }

            &.drop {
                width: 150px;
                max-width: 200px;
                display: block;
                position: absolute;
                right: 0;
                z-index: 20;
                margin-left: 0;

                .nav-link {
                    border: none;
                    border-radius: 0;

                    &.active,
                    &:hover {
                        color: #007bff;
                        background-color: #fff;
                        border: none;
                    }
                }

                &::after {
                    content: none;
                }

                &:first-child {
                    margin-top: 10px;
                    .nav-link {
                        border-top-left-radius: 0.25rem;
                        border-top-right-radius: 0.25rem;
                    }
                }

                &:last-child {
                    .nav-link {
                        border-bottom-left-radius: 0.25rem;
                        border-bottom-right-radius: 0.25rem;
                    }
                }
            }
        }
    }

    .nav > li > a {
        display: flex;
    }

    .click {
        position: absolute;
        line-height: 1;
        padding: 0.5rem 1rem;
        border: 1px solid #989b9c;
        background: #fff;
        border-radius: 0.25rem;
        color: #767676;
        top: 0;
        right: 0;

        &:hover {
            cursor: pointer;
        }
    }

    .emptyNavDropdown {
        position: absolute;
        right: 0;
        background: #fff;
        border-radius: 4px;
        z-index: 20;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            .nav-item {
                padding: 0.25rem 1rem;
                max-width: 150px;
                line-height: 1.2;

                .activity_name,
                .test_name {
                    display: none;
                }

                &.active,
                &:hover {
                    .nav-link {
                        color: #007bff;
                        background-color: #fff;
                        border: none;
                    }
                }

                &:hover {
                    .activity_name,
                    .test_name {
                        display: block;
                    }
                }
            }
        }
    }
}

.dashboard-tab {
    display: inline-flex;
    align-items: center;
    width: 100%;
    max-width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;

    .dashboard-activities-nav {
        max-width: 100%;
        width: calc(100% - 52px - 52px);
        border-bottom: 4px solid #ddd;
        // overflow: auto;

        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow: -moz-scrollbars-none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .links {
        margin-bottom: 0;
        border-bottom: none;
    }

    ul {
        margin: 0;
        position: relative;
        color: white;
        display: inline-block;
        padding: 0;
        outline: 0;
        display: inline-flex;
        list-style: none;
        white-space: nowrap;

        max-width: 100%;
        width: auto;
        overflow: auto;
        margin-bottom: -4px;
        padding: 0 4px;

        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow: -moz-scrollbars-none;

        &::-webkit-scrollbar {
            display: none;
        }

        li {
            // margin-bottom: -0.67em;
            padding: 3px;
            border-bottom: 4px solid transparent;

            &:not(:first-child) {
                margin-left: 25px;
            }

            &.active {
                border-bottom: 4px solid #1991eb;
            }

            a {
                // text-transform: uppercase;
                font-size: 0.88rem;
                font-weight: bold;
            }
        }
    }
}

.tableStatusTag,
.borderGrey,
.outlineSuccess {
    display: flex;
    align-items: center;
    line-height: 0.8;
}
</style>

<style lang="scss">
.form-switch {
    & ~ div {
        width: calc(100% - 84px);
    }
}
</style>
