<template>
    <div v-if="component_done_loading" class="col-xs-12 right-container">
        <!-- <div class="page-title">
            <div class="col-xs-12 col-md-6" style="margin-bottom:30px;">
                <router-link v-if="!isFromAccount" :to="{name:'teachers.index'}">Teachers</router-link>
                <router-link v-else :to="{name:'accounts.view',params:{id:teacher.accountUuid}}">Account</router-link>
                / View Teacher
                <h3 style="margin-bottom:0;"></h3>
            </div>
            <div class="col-xs-12 col-md-6 listingHeaderBtns">
                <router-link v-if="!isFromAccount" :to="{name:'teachers.index'}" style="color:#222;" class="btn btn-action btn-default"><i class="fa fa-chevron-left"></i> Back to Teacher List</router-link>
                <router-link v-else :to="{name:'accounts.view',params:{id:teacher.accountUuid}}" style="color:#222;" class="btn btn-action btn-default"><i class="fa fa-chevron-left"></i> Back to Account</router-link>
            </div>   
        </div>
        <div class="clearfix"></div> -->
        <div v-if="auth.user().roleId <= 3" class="links">
            <ul>
                <li class="active">
                    <a class="nav-link" data-toggle="tab" href="#teacher" aria-label="Teacher Details">TEACHER DETAILS</a>
                </li>
                <li>
                    <a class="nav-link" data-toggle="tab" href="#courses" aria-label="Courses">COURSES</a>
                </li>
                <!-- <li>
                    <a class="nav-link" data-toggle="tab" href="#log">ACTIVITY LOG</a>
                </li> -->
            </ul>
        </div>
        <div v-if="auth.user().roleId <= 3" class="row tab-content">
            <div id="teacher" class="tab-pane fade in active">
                <kr-panel :with-footer="false">
                    <template #title>
                        <template v-if="isProfile">
                            <div class="col-xs-6">MY PROFILE</div>
                        </template>
                        <template v-else>
                            <div class="col-xs-6">
                                {{ teacher.displayName }}
                                <p>{{ teacher.email }}</p>
                            </div>
                        </template>
                        <div class="col-xs-6 textAlignRight">
                            <div v-if="auth.user().roleId < 3 || isProfile || (auth.user().canAddTeacher && teacher.roleId == 3)" class="hidden-xs hidden-sm">
                                <router-link
                                    v-if="isProfile"
                                    :to="{
                                        name: 'profile.edit',
                                        params: $route.params.id,
                                    }"
                                    tag="button"
                                    class="btn btn-default mainBtn"
                                    :disabled="auth.isImpersonation() ? true : undefined"
                                    aria-label="Edit"
                                >
                                    <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true"></i>Edit
                                </router-link>
                                <router-link
                                    v-else-if="!isFromAccount"
                                    :to="{
                                        name: 'teachers.edit',
                                        params: $route.params.id,
                                    }"
                                    tag="button"
                                    class="btn btn-default mainBtn"
                                    :disabled="auth.isImpersonation() ? true : undefined"
                                    aria-label="Edit"
                                >
                                    <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true"></i>Edit
                                </router-link>
                                <router-link
                                    v-else
                                    :to="{
                                        name: 'accounts.teachers.edit',
                                        params: $route.params.id,
                                    }"
                                    tag="button"
                                    class="btn btn-default mainBtn"
                                    :disabled="auth.isImpersonation() ? true : undefined"
                                    aria-label="Edit"
                                >
                                    <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true"></i>Edit
                                </router-link>
                                <button
                                    v-if="!teacher.isSuspended && !isProfile"
                                    class="btn btn-default mainBtn"
                                    aria-label="Reset Password"
                                    @click.prevent="openResetPasswordModal()"
                                >
                                    <i class="fa fa-key marginRight8" aria-hidden="true"></i>Reset Password
                                </button>
                                <!--<router-link v-if="!isFromAccount" :to="{name:'teachers.index'}" tag="button" class="btn btn-default mainBtn" exact>
                                    <i class="fa fa-caret-left"></i>&nbsp;Back
                                </router-link>
                                <router-link v-else :to="{name:'accounts.view',params:{id:teacher.account.uuid}}" tag="button" class="btn btn-default mainBtn" exact>
                                    <i class="fa fa-caret-left"></i>&nbsp;Back
                                </router-link>-->
                            </div>
                            <div v-if="auth.user().roleId < 3 || isProfile || (auth.user().canAddTeacher && teacher.roleId == 3)" class="hidden-md hidden-lg">
                                <router-link
                                    v-if="isProfile"
                                    :to="{
                                        name: 'profile.edit',
                                        params: $route.params.id,
                                    }"
                                    tag="button"
                                    class="btn btn-default mainBtn"
                                    :disabled="auth.isImpersonation() ? true : undefined"
                                    aria-label="Edit"
                                >
                                    <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true"></i>Edit
                                </router-link>
                                <router-link
                                    v-else-if="!isFromAccount"
                                    v-tooltip="{ content: 'Edit Teacher' }"
                                    :to="{
                                        name: 'teachers.edit',
                                        params: $route.params.id,
                                    }"
                                    tag="button"
                                    class="btn btn-default mainBtn"
                                    :disabled="auth.isImpersonation() ? true : undefined"
                                    aria-label="Edit"
                                >
                                    <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                </router-link>
                                <router-link
                                    v-else
                                    v-tooltip="{ content: 'Edit Teacher' }"
                                    :to="{
                                        name: 'accounts.teachers.edit',
                                        params: $route.params.id,
                                    }"
                                    tag="button"
                                    class="btn btn-default mainBtn"
                                    :disabled="auth.isImpersonation() ? true : undefined"
                                    aria-label="Edit"
                                >
                                    <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                </router-link>
                                <button
                                    v-if="!teacher.isSuspended && !isProfile"
                                    v-tooltip="{ content: 'Reset Password' }"
                                    class="btn btn-default mainBtn"
                                    aria-label="Reset Password"
                                    @click.prevent="openResetPasswordModal()"
                                >
                                    <i class="fa fa-key" aria-hidden="true"></i>
                                </button>
                                <!-- <router-link v-if="!isFromAccount" :to="{name:'teachers.index'}" tag="button" class="btn btn-default mainBtn" exact  v-tooltip="{content: 'Back'}">
                                    <i class="fa fa-caret-left"></i>
                                </router-link>
                                <router-link v-else :to="{name:'accounts.view',params:{id:teacher.account.uuid}}" tag="button" class="btn btn-default mainBtn" exact v-tooltip="{content: 'Back'}">
                                    <i class="fa fa-caret-left"></i>
                                </router-link>
                                    -->
                            </div>
                        </div>
                    </template>
                    <template #content>
                        <div class="row flex col-xs-12">
                            <div class="profileImg">
                                <div>
                                    <template v-if="teacher.avatar">
                                        <div class="actual-image" :style="'background-image:url(' + teacher.avatar + ');'"></div>
                                    </template>
                                    <template v-else>
                                        <div class="profile_pic">
                                            <div
                                                class="badge_profile"
                                                style="width: 100px; line-height: 100px; height: 100px; background: #2a3f54; color: white"
                                            >
                                                <span> {{ initials }} </span>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>

                            <div class="col-xs-12 col-md-6 viewFullDetails">
                                <div class="col-xs-12 flex">
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">ROLE</label>
                                            <p class="form-control-static">
                                                <template v-if="teacher.role == 'Super Admin'"> Super Admin </template>
                                                <template v-else-if="teacher.canAddTeacher"> Admin Teacher </template>
                                                <template v-else> Teacher </template>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">EXPRESS SIGN IN ENABLED</label>
                                            <p class="form-control-static">
                                                {{ teacher.canUseExpressSignIn ? 'YES' : 'NO' }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 flex">
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">FULL NAME</label>
                                            <p class="form-control-static">
                                                {{ teacher.displayName }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">EMAIL</label>
                                            <p class="form-control-static">
                                                {{ teacher.email }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 flex">
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">STATUS</label>
                                            <p class="form-control-static">
                                                <template v-if="!teacher.isSuspended">
                                                    <i class="fa fa-circle activeCircle marginRight8" aria-hidden="true"></i>Active
                                                    <!--<template v-if="teacher.dateActivated">
                            <i class="fa fa-circle activeCircle"></i
                            >&nbsp;Active
                          </template>
                          <template v-else-if="!teacher.dateActivated">
                            <i class="fa fa-circle readOnlyCircle"></i
                            >&nbsp;Invite Sent
                          </template>-->
                                                </template>
                                                <template v-else>
                                                    <i class="fa fa-circle suspendCircle marginRight8" aria-hidden="true"></i>Suspended
                                                </template>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">ACTIVATED ON</label>
                                            <p class="form-control-static">
                                                <template v-if="teacher.dateActivated">
                                                    {{ convertToReadableDate(teacher.dateActivated, 'DD MMM YYYY, hh:mm a').date }}
                                                    ({{ convertToReadableDate(teacher.dateActivated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                                </template>
                                                <template v-else> - </template>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 flex">
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">JOB TITLE</label>
                                            <p class="form-control-static">
                                                {{ teacher.jobTitle || 'N/A' }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">ACADEMIC DISCIPLINE</label>
                                            <p class="form-control-static">
                                                {{ teacher.academicDiscipline || 'N/A' }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 flex">
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">CREATED BY</label>
                                            <p class="form-control-static">
                                                {{ teacher.createdBy }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">CREATED ON</label>
                                            <p class="form-control-static">
                                                <template v-if="teacher.dateCreated">
                                                    {{ convertToReadableDate(teacher.dateCreated, 'DD MMM YYYY, hh:mm a').date }}
                                                    ({{ convertToReadableDate(teacher.dateCreated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                                </template>
                                                <template v-else> - </template>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 flex">
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">ACCOUNT LAST LOGIN</label>
                                            <p class="form-control-static">
                                                <template v-if="teacher.dateLastLogin">
                                                    {{ convertToReadableDate(teacher.dateLastLogin, 'DD MMM YYYY, hh:mm a').date }}
                                                    ({{ convertToReadableDate(teacher.dateLastLogin, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                                </template>
                                                <template v-else> - </template>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">ACCOUNT LAST MODIFIED</label>
                                            <p class="form-control-static">
                                                <template v-if="teacher.lastModified">
                                                    {{ convertToReadableDate(teacher.lastModified, 'DD MMM YYYY, hh:mm a').date }}
                                                    ({{ convertToReadableDate(teacher.lastModified, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                                </template>
                                                <template v-else> - </template>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="teacher.isSuspended" class="col-xs-12 flex">
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">SUSPENDED ON</label>
                                            <p class="form-control-static">
                                                {{ convertToReadableDate(teacher.dateSuspended, 'DD MMM YYYY, hh:mm a').date }}
                                                ({{ convertToReadableDate(teacher.dateSuspended, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="auth.user().roleId < 3" class="col-xs-12 flex">
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">CAN VIEW QUESTION BANK</label>
                                            <p class="form-control-static">
                                                {{ teacher.canViewQuestionBank ? 'YES' : 'NO' }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </kr-panel>
                <div v-if="(auth.user().roleId < 3 && !isProfile) || (auth.user().canAddTeacher && teacher.roleId == 3 && !isProfile)" class="flexRight">
                    <div class="dropup col-xs-2">
                        <button
                            id="viewDropdownMenuButton"
                            class="mainBtn width100 flexSpaceBetween align-items"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            More Actions
                            <i class="fa fa-caret-down marginLeft8" aria-hidden="true"></i>
                        </button>

                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="viewModuleDropdownMenuButton">
                            <ul>
                                <li v-if="!teacher.isSuspended">
                                    <a
                                        v-tooltip="'Suspend Teacher'"
                                        class="danger-state"
                                        href="#"
                                        aria-label="Suspend Teacher"
                                        @click.prevent="openSuspendModal()"
                                    >
                                        <span> <i class="fa fa-archive marginRight8" aria-hidden="true"></i>Suspend Teacher </span>
                                    </a>
                                </li>

                                <li v-else>
                                    <a v-tooltip="'Reinstate'" class="primary-state" href="#" aria-label="Reinstate" @click.prevent="openSuspendModal()">
                                        <span> <i class="fa fa-refresh marginRight8" aria-hidden="true"></i>Reinstate </span>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <!-- <div
              class="dropdown-menu"
              aria-labelledby="viewDropdownMenuButton"
              style="margin-top:0;"
            >
              <button
                class="btn btn-danger"
                v-if="!teacher.isSuspended"
                @click.prevent="openSuspendModal()"
                aria-label="Suspend Teacher"
              >
                <i class="fa fa-archive"></i>&nbsp;Suspend Teacher
              </button>
              <button
                class="btn btn-success"
                v-else
                @click.prevent="openSuspendModal()"
                aria-label="Reinstate"
              >
                <i class="fa fa-refresh"></i>&nbsp;Reinstate
              </button>
            </div> -->
                    </div>
                </div>
            </div>
            <div id="courses" class="tab-pane fade in">
                <div class="row col-xs-12">
                    <div>
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th colspan="12">
                                            Course that
                                            {{ teacher.displayName }} is in ({{ teacher.courses.length }})
                                        </th>
                                    </tr>
                                    <tr v-if="teacher.courses.length != 0">
                                        <th>COURSE</th>
                                        <th>CODE</th>
                                        <th>COURSE ACCESS LEVEL</th>
                                        <th v-if="auth.user().roleId > 1"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="teacher.courses.length == 0">
                                        <td>{{ teacher.displayName }} is not associated with any courses yet</td>
                                    </tr>
                                    <tr v-for="course in teacher.courses" v-else>
                                        <td>
                                            <router-link
                                                v-if="auth.user().roleId > 1"
                                                v-tooltip="{
                                                    content: 'View Course',
                                                }"
                                                tag="a"
                                                :to="{
                                                    name: 'courses.view',
                                                    params: { id: course.uuid },
                                                }"
                                                class="link"
                                            >
                                                {{ course.name }}
                                            </router-link>
                                            <template v-else>
                                                {{ course.name }}
                                            </template>
                                        </td>
                                        <td>
                                            <router-link
                                                v-if="auth.user().roleId > 1"
                                                v-tooltip="{
                                                    content: 'View Course',
                                                }"
                                                tag="a"
                                                :to="{
                                                    name: 'courses.view',
                                                    params: { id: course.uuid },
                                                }"
                                                class="link"
                                            >
                                                {{ course.code }}
                                            </router-link>
                                            <template v-else>
                                                {{ course.code }}
                                            </template>
                                        </td>
                                        <td>
                                            <template v-if="course.accountType == 'Owner'">
                                                <i class="fa fa-circle ownerCircle marginRight8" aria-hidden="true"></i>Owner
                                            </template>
                                            <template v-if="course.accountType == 'Full Access'">
                                                <i class="fa fa-circle fullAccessCircle marginRight8" aria-hidden="true"></i>Full Access
                                            </template>
                                            <template v-if="course.accountType == 'Read Only'">
                                                <i class="fa fa-circle readOnlyCircle marginRight8" aria-hidden="true"></i>Read Only
                                            </template>
                                        </td>
                                        <td v-if="auth.user().roleId > 1">
                                            {{ auth.user().courses }}
                                            <router-link
                                                v-if="auth.user().roleId < 3"
                                                v-tooltip="{
                                                    content: 'View Course',
                                                }"
                                                tag="button"
                                                :to="{
                                                    name: 'courses.view',
                                                    params: { id: course.uuid },
                                                }"
                                                class="btn btn-primary subBtn hidden-xs hidden-sm"
                                                aria-label="View"
                                            >
                                                <i class="fas fa-eye marginRight8" aria-hidden="true"></i>Go to Course
                                            </router-link>

                                            <router-link
                                                v-if="auth.user().roleId < 3"
                                                v-tooltip="{
                                                    content: 'View Course',
                                                }"
                                                tag="button"
                                                :to="{
                                                    name: 'courses.view',
                                                    params: { id: course.uuid },
                                                }"
                                                class="btn btn-primary mainBtn hidden-md hidden-lg"
                                                aria-label="View"
                                            >
                                                <i class="fas fa-eye" aria-hidden="true"></i>
                                            </router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!--<div class="displayNo marginTop20">
                        Displaying 5 of 5 courses
                    </div>-->
                </div>
            </div>
            <div v-if="false" id="log" class="tab-pane fade in">
                <kr-panel :with-footer="false">
                    <template #title>
                        <div class="col-xs-6">ACTIVITY LOG</div>
                        <div class="col-xs-6 flexRight">
                            <div class="col-xs-2 buttonGroup">
                                <div class="hidden-xs hidden-sm">
                                    <router-link
                                        v-if="!isFromAccount"
                                        :to="{ name: 'teachers.index' }"
                                        tag="button"
                                        class="btn btn-default mainBtn"
                                        exact
                                        aria-label="Back"
                                    >
                                        <i class="fa fa-caret-left marginRight8" aria-hidden="true"></i>Back
                                    </router-link>
                                    <router-link
                                        v-else
                                        :to="{
                                            name: 'accounts.view',
                                            params: { id: teacher.accountUuid },
                                        }"
                                        tag="button"
                                        class="btn btn-default mainBtn"
                                        exact
                                        aria-label="Back"
                                    >
                                        <i class="fa fa-caret-left marginRight8" aria-hidden="true"></i>Back
                                    </router-link>
                                </div>
                                <div class="hidden-md hidden-lg">
                                    <router-link
                                        v-if="!isFromAccount"
                                        v-tooltip="{ content: 'Back' }"
                                        :to="{ name: 'teachers.index' }"
                                        tag="button"
                                        class="btn btn-default mainBtn"
                                        exact
                                        aria-label="Back"
                                    >
                                        <i class="fa fa-caret-left" aria-hidden="true"></i>
                                    </router-link>
                                    <router-link
                                        v-else
                                        v-tooltip="{ content: 'Back' }"
                                        :to="{
                                            name: 'accounts.view',
                                            params: { id: teacher.accountUuid },
                                        }"
                                        tag="button"
                                        class="btn btn-default mainBtn"
                                        exact
                                        aria-label="Back"
                                    >
                                        <i class="fa fa-caret-left" aria-hidden="true"></i>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template #content>
                        <kr-search
                            name="log_listing"
                            :url="{ url: '/users/activity-log/index' }"
                            :columns="{
                                user: 'display:NAME|sortable:false|width:35%|mobile:hide',
                                event: 'display:ACTION|sortable:false|width:30%',
                                dateCreated: 'display:TIMESTAMP|sortable:false|width:45%',
                            }"
                            :options="{
                                trash_field: false,
                                suspended_field: false,
                                action_column: false,
                                search_field: false,
                                query_fields: query_fields,
                            }"
                            @mounted="
                                initStartDatepicker();
                                initEndDatepicker();
                            "
                        >
                            <template #lower-left>
                                <div class="form-group periodBars">
                                    <label class="col-form-label">VIEW PERIOD</label>
                                    <div class="form-date">
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="start_log_daterange"
                                            placeholder="Select start period"
                                            aria-label="Select Start Period"
                                        />
                                    </div>
                                    <div class="form-date">
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="end_log_daterange"
                                            placeholder="Select end period"
                                            aria-label="Select End Period"
                                        />
                                    </div>
                                </div>
                            </template>
                            <!-- <template slot="top-display-from-to" slot-scope="props">
                                <div class="displayNo">
                                    {{props.model.from}}-{{props.model.to}} of {{props.model.meta.paginator.total}}
                                </div>
                            </template> -->
                            <template #table-title="props">
                                <th colspan="6">All Logs ( {{ props.model.from }}-{{ props.model.to }} of {{ props.model.meta.paginator.total }} )</th>
                            </template>
                            <template #bottom-display-from-to="props">
                                <div class="displayNo">
                                    {{ props.model.from }}-{{ props.model.to }}
                                    of
                                    {{ props.model.meta.paginator.total }}
                                </div>
                            </template>
                            <template #cdata="props">
                                <td class="hidden-xs hidden-sm">
                                    {{ props.model.user }}
                                </td>
                                <td>
                                    {{ props.model.event }}
                                </td>
                                <td>
                                    <template v-if="props.model.dateCreated">
                                        {{ convertToReadableDate(props.model.dateCreated, 'DD MMM YYYY, hh:mm a').date }}
                                        ({{ convertToReadableDate(props.model.dateCreated, 'DD MMM YYYY, hh:mm a').current_timezone }})
                                    </template>
                                    <template v-else> - </template>
                                </td>
                            </template>
                        </kr-search>
                    </template>
                </kr-panel>
            </div>
        </div>
        <!-- <div class="row" v-if="auth.user().roleId==3">
            <kr-panel :withFooter="false">
                <template #title>
                    <template v-if="isProfile"> 
                        <div class="col-xs-6">
                            MY PROFILE
                        </div>		
                    </template>
                    <template v-else> 
                        <div class="col-xs-6">
                            {{teacher.displayName}}
                            <p>{{teacher.email}}</p>
                        </div>		
                    </template>
                    <template v-if="auth.user().uuid==teacher.uuid">
                        <div class="col-xs-6 buttonGroupInline flexRight">
                            <div class="hidden-xs hidden-sm">
                                <template v-if="auth.user().uuid==teacher.uuid">
                                    <router-link  v-if="isProfile" :to="{name:'profile.edit',params:$route.params.id}" tag="button" class="btn btn-default mainBtn" :disabled="(auth.isImpersonation()) ? true:undefined" aria-label="Edit">
                                        <i class="fa fa-pencil-square-o"></i>&nbsp;Edit
                                    </router-link>
                                </template>
                                <template v-else>
                                    <router-link  v-if="!isFromAccount" :to="{name:'teachers.edit',params:$route.params.id}" tag="button" class="btn btn-default mainBtn" :disabled="(auth.isImpersonation()) ? true:undefined" aria-label="Edit">
                                        <i class="fa fa-pencil-square-o"></i>&nbsp;Edit
                                    </router-link>
                                    <router-link  v-else :to="{name:'accounts.teachers.edit',params:$route.params.id}" tag="button" class="btn btn-default mainBtn" :disabled="(auth.isImpersonation()) ? true:undefined" aria-label="Edit">
                                        <i class="fa fa-pencil-square-o"></i>&nbsp;Edit
                                    </router-link>
                                </template>	
                                <router-link v-if="!isFromAccount" :to="{name:'teachers.index'}" tag="button" class="btn btn-default mainBtn" exact>
                                    <i class="fa fa-caret-left"></i>&nbsp;Back
                                </router-link>
                                <router-link v-else :to="{name:'accounts.view',params:{id:teacher.accountUuid}}" tag="button" class="btn btn-default mainBtn" exact>
                                    <i class="fa fa-caret-left"></i>&nbsp;Back
                                </router-link>
                            </div>
                            <div class="hidden-md hidden-lg">
                                <template v-if="auth.user().uuid==teacher.uuid">
                                    <router-link  v-if="isProfile" :to="{name:'profile.edit',params:$route.params.id}" tag="button" class="btn btn-default mainBtn" :disabled="(auth.isImpersonation()) ? true:undefined" aria-label="Edit">
                                        <i class="fa fa-pencil-square-o"></i>
                                    </router-link>
                                </template>
                                <template v-else>
                                    <router-link  v-if="!isFromAccount" :to="{name:'teachers.edit',params:$route.params.id}" tag="button" class="btn btn-default mainBtn" v-tooltip="{content: 'Edit Teacher'}" :disabled="(auth.isImpersonation()) ? true:undefined" aria-label="Edit">
                                        <i class="fa fa-pencil-square-o"></i>
                                    </router-link>
                                    <router-link  v-else :to="{name:'accounts.teachers.edit',params:$route.params.id}" tag="button" class="btn btn-default mainBtn" v-tooltip="{content: 'Edit Teacher'}" :disabled="(auth.isImpersonation()) ? true:undefined" aria-label="Edit">
                                        <i class="fa fa-pencil-square-o"></i>
                                    </router-link>
                                </template>	
                                <router-link v-if="!isFromAccount" :to="{name:'teachers.index'}" tag="button" class="btn btn-default mainBtn" exact v-tooltip="{content: 'Back'}">
                                    <i class="fa fa-caret-left"></i>
                                </router-link>
                                <router-link v-else :to="{name:'accounts.view',params:{id:teacher.accountUuid}}" tag="button" class="btn btn-default mainBtn" exact v-tooltip="{content: 'Back'}">
                                    <i class="fa fa-caret-left"></i>
                                </router-link>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="col-xs-6 flexRight">
                            <div class="col-xs-2 buttonGroup">
                                <div class="hidden-xs hidden-sm">
                                    <router-link v-if="!isFromAccount" :to="{name:'teachers.index'}" tag="button" class="btn btn-default mainBtn" exact v-tooltip="{content: 'Back'}">
                                        <i class="fa fa-caret-left"></i>&nbsp;Back
                                    </router-link>
                                    <router-link v-else :to="{name:'accounts.view',params:{id:teacher.accountUuid}}" tag="button" class="btn btn-default mainBtn" exact v-tooltip="{content: 'Back'}">
                                        <i class="fa fa-caret-left"></i>&nbsp;Back
                                    </router-link>
                                </div>
                                <div class="hidden-md hidden-lg">
                                    <router-link v-if="!isFromAccount" :to="{name:'teachers.index'}" tag="button" class="btn btn-default mainBtn" exact v-tooltip="{content: 'Back'}">
                                        <i class="fa fa-caret-left"></i>
                                    </router-link>
                                    <router-link v-else :to="{name:'accounts.view',params:{id:teacher.accountUuid}}" tag="button" class="btn btn-default mainBtn" exact v-tooltip="{content: 'Back'}">
                                        <i class="fa fa-caret-left"></i>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
                <template #content>
                    <template v-if="isProfile">
                        <div class="row flex col-xs-12">
                            <div class="profileImg">
                                <div>
                                  <template v-if="teacher.avatar">
                                    <div class="actual-image" :style="'background-image:url('+teacher.avatar+');'">
                                    </div>
                                  </template>
                                  <template v-else>
                                    <div class="profile_pic">
                                      <div class="badge_profile" style="width:100px;line-height:100px;height:100px;background:#2A3F54;color:white">{{initials}}</div>
                                    </div>
                                  </template> 
                                </div>  
                            </div>
                        
                            <div class="col-xs-12 col-md-6 viewFullDetails">
                                <div class="col-xs-12 flex">
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">FULL NAME</label>
                                            <p class="form-control-static">{{teacher.displayName}}</p>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">EMAIL</label>
                                            <p class="form-control-static">{{teacher.email}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 flex">
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">STATUS</label>
                                            <p class="form-control-static">
                                                <template v-if="!teacher.isSuspended">
                                                    <i class="fa fa-circle activeCircle"></i>&nbsp;Active
                                                </template>
                                                <template v-else>
                                                    <i class="fa fa-circle suspendCircle"></i>&nbsp;Suspended
                                                </template>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">ACTIVATED ON</label>
                                            <p class="form-control-static" >
                                                <template v-if="teacher.dateActivated">
                                                    {{convertToReadableDate(teacher.dateActivated,'DD MMM YYYY, hh:mm a').date}} ({{convertToReadableDate(teacher.dateActivated,'DD MMM YYYY, hh:mm a').current_timezone}})
                                                </template> 
                                                <template v-else>
                                                    -
                                                </template>
                                            </p>
                                        </div>
                                    </div>
                                </div>	
                                <div class="col-xs-12 flex">
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">CREATED BY</label>
                                            <p class="form-control-static">{{teacher.createBy}}</p>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">CREATED ON</label>
                                            <p class="form-control-static">
                                                <template v-if="teacher.dateCreated"> 
                                                    {{convertToReadableDate(teacher.dateCreated,'DD MMM YYYY, hh:mm a').date}} ({{convertToReadableDate(teacher.dateCreated,'DD MMM YYYY, hh:mm a').current_timezone}})
                                                </template> 
                                                <template v-else>
                                                    -
                                                </template>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 flex">
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">ACCOUNT LAST LOGIN</label>
                                            <p class="form-control-static">
                                                <template v-if="teacher.dateLastLogin"> 
                                                    {{convertToReadableDate(teacher.dateLastLogin,'DD MMM YYYY, hh:mm a').date}} ({{convertToReadableDate(teacher.dateLastLogin,'DD MMM YYYY, hh:mm a').current_timezone}})
                                                </template> 
                                                <template v-else>
                                                    -
                                                </template>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">ACCOUNT LAST MODIFIED</label>
                                            <p class="form-control-static">
                                                <template v-if="teacher.lastModified"> 
                                                    {{convertToReadableDate(teacher.lastModified,'DD MMM YYYY, hh:mm a').date}} ({{convertToReadableDate(teacher.lastModified,'DD MMM YYYY, hh:mm a').current_timezone}})
                                                </template> 
                                                <template v-else>
                                                    -
                                                </template>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-12 flex" v-if="teacher.isSuspended">
                                    <div class="col-xs-12 col-md-6">
                                        <div class="form-group">
                                            <label class="control-label text-uppercase">SUSPENDED ON</label>
                                                <p class="form-control-static">
                                                    {{convertToReadableDate(teacher.dateSuspended,'DD MMM YYYY, hh:mm a').date}} ({{convertToReadableDate(teacher.dateSuspended,'DD MMM YYYY, hh:mm a').current_timezone}})
                                                </p>
                                        </div>
                                    </div>	
                                </div>
                            </div>
                        
                        </div> 
                    </template>
                    <div class="row col-xs-12" v-else>
                        <div>
                            <div class="table-responsive">
                                  <table class="table">
                                    <thead>
                                        <tr>
                                            <th colspan="6">All Courses ({{teacher.courses.length}})</th>
                                        </tr>
                                        <tr>
                                            <th>COURSE</th>
                                            <th>CODE</th>
                                            <th>ACCESS LEVEL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="course in teacher.courses">
                                            <td> <router-link tag="a" v-tooltip="{content: 'View Course'}":to="{name:'courses.view',params:{id:course.uuid}}" class="link" :aria-label="course.name">
                                                {{course.name}}
                                                    </router-link>
                                                </td>
                                            <td> <router-link tag="a" v-tooltip="{content: 'View Course'}":to="{name:'courses.view',params:{id:course.uuid}}" class="link" :aria-label="course.code">
                                                {{course.code}}
                                            </router-link></td>
                                            <td>
                                                <template v-if="course.accountType=='Owner'">
                                                    <i class="fa fa-circle ownerCircle"></i>&nbsp;Owner
                                                </template>
                                                <template v-if="course.accountType=='Full Access'">
                                                    <i class="fa fa-circle fullAccessCircle"></i>&nbsp;Full Access
                                                </template>
                                                <template v-if="course.accountType=='Read Only'">	
                                                    <i class="fa fa-circle readOnlyCircle"></i>&nbsp;Read Only
                                                </template>
                                            </td>
                                        </tr>
                                        <tr v-if="teacher.courses.length==0">
                                            <td colspan="3" style="text-align:center">
                                                NO DATA
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>	
                        <div class="displayNo marginTop20">
                            1-5 of 5
                        </div>
                    </div>
                </template>
            </kr-panel>
        </div> -->
        <!-- <div class="row">
            <div class="col-md-6 col-xs-12">
                <kr-panel :withFooter="false">
                    <template #title>
                        <div class="x_title">
                            TEACHER OVERVIEW
                            <template v-if="auth.user().uuid==teacher.uuid||auth.user().roleId==2">
                                <span style="float:right" v-if="!isFromAccount">
                                    <router-link :to="{name:'teachers.edit',params:{id:teacher.uuid}}"><i class="fa fa-pencil-square-o"></i> Edit</router-link>
                                </span>
                                <span style="float:right" v-else>
                                    <router-link :to="{name:'accounts.teachers.edit',params:{id:teacher.uuid}}"><i class="fa fa-pencil-square-o"></i> Edit</router-link>
                                </span>
                            </template>	
                        </div>	
                    </template>
                    <template #content>
                        <div class="row">
                            <div class="col-xs-6" style="margin-top:10px;">
                                <div class="form-group">
                                    <label class="control-label text-uppercase">ACCOUNT</label>
                                    <p class="form-control-static">{{teacher.account}}</p>
                                </div>
                            </div>
                            <div class="col-xs-6" style="margin-top:10px;">
                                <div class="form-group">
                                    <label class="control-label text-uppercase">ADMIN</label>
                                    <p class="form-control-static">{{teacher.role=='Super Admin'?'YES':'NO'}}</p>
                                </div>
                            </div>
                            <div class="col-xs-6">
                                <div class="form-group">
                                    <label class="control-label text-uppercase">NAME</label>
                                    <p class="form-control-static">{{teacher.displayName}}</p>
                                </div>
                            </div>
                            <div class="col-xs-6">
                                <div class="form-group">
                                    <label class="control-label text-uppercase">IDENTITY</label>
                                    <p class="form-control-static">{{teacher.identity}}</p>
                                </div>
                            </div>
                            <div class="col-xs-6">
                                <div class="form-group">
                                    <label class="control-label text-uppercase">EMAIL</label>
                                    <p class="form-control-static">{{teacher.email}}</p>
                                </div>
                            </div>
                            <div class="col-xs-6">
                                <div class="form-group">
                                    <label class="control-label text-uppercase">CREATED BY</label>
                                    <p class="form-control-static">{{teacher.createBy}}</p>
                                </div>
                            </div>
                            <div class="col-xs-6">
                                <div class="form-group">
                                    <label class="control-label text-uppercase">LAST UPDATED BY</label>
                                    <p class="form-control-static">{{teacher.lastUpdatedBy}}</p>
                                </div>
                            </div>
                        </div>
                    </template>
                </kr-panel>
            </div>
        </div> -->
        <div id="suspendModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="suspendModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="suspendModal-title" class="modal-title">
                            <template v-if="!teacher.isSuspended"> Suspend </template>
                            <template v-else> Reinstate </template>
                            {{ teacher.displayName }}
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div v-if="!teacher.isSuspended" class="col-xs-12">
                                <div class="textAlignCenter">
                                    Are you sure you want to suspend
                                    <span class="fontBold">{{ teacher.displayName }}</span
                                    >?
                                </div>
                                <div class="textAlignCenter">
                                    He/She will no longer be able to sign in to InteDashboard. No notifications will be sent to him/her.
                                </div>
                            </div>
                            <div v-else class="col-xs-12">
                                <div class="textAlignCenter">
                                    You are about to reinstate
                                    {{ teacher.displayName }}
                                </div>
                                <div class="textAlignCenter">Are you sure ?</div>
                            </div>
                        </div>
                        <div class="row">
                            <!--<div v-if="suspendObject.status=='success'" class="alert alert-success" role="alert" style="margin-bottom:10px">
                            <i class="fa fa-check"></i>&nbsp; You have successfully performed action
                        </div>
                        <div  v-if="suspendObject.status=='fail'" class="alert alert-danger" role="alert" style="margin-bottom:10px">
                            <i class="fa fa-cross"></i>&nbsp; Error in your action please try again!
                        </div>-->
                            <div class="flex">
                                <div v-if="!teacher.isSuspended" class="col-xs-12 col-md-6">
                                    <button class="btn btn-danger mainBtn" style="width: 100%" aria-label="Suspend" @click.prevent="suspend()">
                                        <i class="fa fa-archive marginRight8" aria-hidden="true"></i>Suspend
                                    </button>
                                </div>
                                <div v-else class="col-xs-12 col-md-6">
                                    <button class="btn btn-success mainBtn" style="width: 100%" aria-label="Reinstate" @click.prevent="unsuspend()">
                                        <i class="fa fa-refresh marginRight8" aria-hidden="true"></i>Reinstate
                                    </button>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <button class="btn btn-default mainBtn" style="width: 100%" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                                </div>
                            </div>
                        </div>
                        <!--<div class="row" v-else>
                        <div class="col-xs-12" style="text-align:center">
                           <template v-if="!superuser.isSuspended">
                                   Suspending..
                           </template>
                           <template v-else>
                                   Reinstating
                           </template>
                        </div>
                    </div>-->
                    </div>
                </div>
            </div>
        </div>
        <kr-reset-password></kr-reset-password>
    </div>
</template>

<script>
import KrForm from '../../../components/forms/form';
import KrAuth from '../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            component_done_loading: false,
            teacher: null,
            isFromAccount: false,
            auth: auth,
            query_fields: { dateFrom: '', dateTo: '' },
            initials: '',
        };
    },
    computed: {
        isProfile() {
            return this.$route.params.id == this.auth.user().uuid;
        },
    },
    created() {
        if (this.isProfile) {
            Events.fire('topbar_update', {
                title: '<i class="fas fa-user"></i>&nbsp;My Profile',
                breadcrumb: [{ name: 'My Profile' }],
            });
        } else if (this.$route.name == 'accounts.teachers.view') {
            Events.fire('topbar_update', {
                title: '<i class="fas fa-chalkboard-teacher"></i>&nbsp;Teachers',
            });
            this.isFromAccount = true;
        } else {
            Events.fire('topbar_update', {
                title: '<i class="fas fa-chalkboard-teacher"></i>&nbsp;Teachers',
            });
        }
        this.fetch();

        document.title = `${this.isProfile ? 'My Profile | InteDashboard | TBL Makes Teams Work' : 'Teachers Profile | InteDashboard | TBL Makes Teams Work'}`;
    },
    methods: {
        openSuspendModal() {
            $('#suspendModal').modal('show');
        },
        suspend() {
            $('#suspendModal').modal('hide');
            var success = function (response) {
                this.teacher.isSuspended = true;
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Account has been suspended',
                });
            }.bind(this);
            var del = function () {
                this.teacher.isSuspended = false;
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .post('/users/' + this.teacher.uuid + '/suspend', {})
                .then(function (response) {
                    success(response);
                })
                .catch(function (errors) {
                    del();
                });
        },
        unsuspend() {
            $('#suspendModal').modal('hide');
            var success = function (response) {
                var that = this;
                this.teacher.isSuspended = false;
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Account has been reinstated',
                });
            }.bind(this);
            var del = function () {
                this.teacher.isSuspended = true;
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .post('/users/' + this.teacher.uuid + '/reinstate', {})
                .then(function (response) {
                    success(response);
                })
                .catch(function (errors) {
                    del();
                });
        },
        search() {
            Events.fire('log_listing_refresh');
        },
        initStartDatepicker() {
            var that = this;
            let options = {
                maxDate: that.query_fields.dateTo,
                singleDatePicker: true,
                timePicker: false,
                cancelClass: '',
                showDropdowns: true,
                autoUpdateInput: false,
                locale: {
                    format: 'YYYY-MM-DD',
                },
            };
            if (that.query_fields.dateFrom != '') {
                options.startDate = moment.utc(that.query_fields.dateFrom).format('YYYY-MM-DD');
            }
            $('input[name="start_log_daterange"]').daterangepicker(options);
            $('input[name="start_log_daterange"]').on('apply.daterangepicker', function (ev, picker) {
                _.set(that.query_fields, 'dateFrom', picker.startDate.format('YYYY-MM-DD'));
                $('input[name="start_log_daterange"]').val(picker.startDate.format('LL'));
                that.search();
                Vue.nextTick(function () {
                    that.initEndDatepicker();
                });
            });
            $('input[name="start_log_daterange"]').on('cancel.daterangepicker', function (ev, picker) {
                $(this).val('');
                _.set(that.query_fields, 'dateFrom', '');
                that.search();
                Vue.nextTick(function () {
                    that.initEndDatepicker();
                });
            });
        },
        initEndDatepicker() {
            var that = this;
            let options = {
                minDate: that.query_fields.dateFrom,
                singleDatePicker: true,
                timePicker: false,
                cancelClass: '',
                showDropdowns: true,
                autoUpdateInput: false,
                locale: {
                    format: 'YYYY-MM-DD',
                },
            };
            if (that.query_fields.dateTo != '') {
                options.startDate = moment.utc(that.query_fields.dateTo).format('YYYY-MM-DD');
            }
            $('input[name="end_log_daterange"]').daterangepicker(options);
            $('input[name="end_log_daterange"]').on('apply.daterangepicker', function (ev, picker) {
                _.set(that.query_fields, 'dateTo', picker.startDate.format('YYYY-MM-DD'));
                $('input[name="end_log_daterange"]').val(picker.startDate.format('LL'));
                that.search();
                Vue.nextTick(function () {
                    that.initStartDatepicker();
                });
            });
            $('input[name="end_log_daterange"]').on('cancel.daterangepicker', function (ev, picker) {
                $(this).val('');
                _.set(that.query_fields, 'dateTo', '');
                that.search();
                Vue.nextTick(function () {
                    that.initStartDatepicker();
                });
            });
        },
        openResetPasswordModal() {
            Events.fire('open-reset-password-modal', {
                user: this.teacher,
                organisation: this.teacher.account.organisationName,
            });
        },
        fetch() {
            let that = this;
            let route = '/teachers';
            if (that.$route.name == 'accounts.teachers.view') {
                route = '/users';
            }
            axios.get(route + '/' + that.$route.params.id).then(function (response) {
                that.teacher = response.data.data;
                that.initials =
                    (that.teacher.firstname ? that.teacher.firstname.charAt(0) : '') + (that.teacher.lastname ? that.teacher.lastname.charAt(0) : '');
                if (that.isProfile) {
                } else if (that.$route.name == 'accounts.teachers.view') {
                    Events.fire('topbar_update', {
                        breadcrumb: [
                            { name: 'Home' },
                            {
                                name: 'Account',
                                link: 'accounts.view',
                                params: { id: that.teacher.account.uuid },
                            },
                            { name: that.teacher.displayName },
                        ],
                    });
                } else {
                    Events.fire('topbar_update', {
                        breadcrumb: [{ name: 'Home' }, { name: 'Teachers', link: 'teachers.index' }, { name: that.teacher.displayName }],
                    });
                }
                that.component_done_loading = true;
            });
        },
    },
};
</script>
<style scoped>
/* .drop-down {
  width: 250px;
  height: 36px;
  border-radius: 3px;
  background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
  border: 1px solid #d8d8d8;
}

.dropdown .dropdown-menu {
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  width: 250px;
}

#viewDropdownMenuButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.viewDropdown {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  min-height: 10px;
} */

@media (min-width: 992px) {
    .periodBars {
        width: 524px;
    }
}
</style>
<style lang="scss">
.viewFullDetails {
    .col-xs-12.flex:last-child {
        .col-xs-12.col-md-6:last-child p {
            margin-bottom: 0;
        }
    }
}
</style>
