<template>
    <div class="mb-3 d-flex align-items-center">
        <input
            v-for="(cell, idx) in cells"
            :key="cell.key"
            :ref="'pin-code-input-' + idx"
            v-model.trim="cell.value"
            class="vue-pincode-input"
            maxlength="1"
            :disabled="disabled ? true : undefined"
            @focus="focusedCell = idx"
            @keydown.delete="onCellErase(idx, $event)"
            @keyup="onKeyDown(idx, $event)"
            @change="onCellChanged(idx)"
            @keypress="isNumber"
        />
    </div>
</template>

<script>
/*Port from https://github.com/Seokky/vue-pincode-input*/
export default {
    props: {
        value: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            focusedCell: 0,
            cells: [],
            watchers: [],
        };
    },
    computed: {
        internalValue: function () {
            return this.cells.reduce((pin, cell) => pin + cell.value, '');
        },
    },
    watch: {
        value: {
            handler: function () {
                let length = this.value ? this.value.length : 0;
                this.cells = [];
                for (var i = 0; i < 6; i++) {
                    this.cells.push({
                        key: `vue-pincode-${i}`,
                        value: i < length ? this.value[i] : '',
                    });
                }
            },
        },
    },
    created() {
        let length = this.value ? this.value.length : 0;
        for (var i = 0; i < 6; i++) {
            this.cells.push({
                key: `vue-pincode-${i}`,
                value: i < length ? this.value[i] : '',
            });
        }
    },
    methods: {
        isNumber(evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        onCellChanged(idx) {
            if (this.cells[idx].value.length != 0) {
                this.focusNextCell();
            }
            this.$emit('input', this.internalValue);
            if (this.internalValue.length == 6) {
                this.$emit('completed');
            }
        },
        onCellErase(idx, e) {
            const isThisCellFilled = this.cells[idx].value.length;
            if (!isThisCellFilled) {
                this.focusPreviousCell();
                e.preventDefault();
            }
        },
        focusCellByIndex(idx) {
            const el = this.$refs['pin-code-input-' + idx][0];
            el.focus();
            el.select();
            this.focusedCell = idx;
        },
        focusPreviousCell() {
            if (!this.focusedCell) return;
            this.focusCellByIndex(this.focusedCell - 1);
        },
        focusNextCell() {
            if (this.focusedCell === 5) return;
            this.focusCellByIndex(this.focusedCell + 1);
        },
        onKeyDown(idx, e) {
            switch (e.keyCode) {
                case 37:
                    this.focusPreviousCell();
                    break;
                case 39:
                    this.focusNextCell();
                    break;
                default:
                    break;
            }
            /*if(e.key!='Backspace'&&e.key!='Tab'){
                this.focusNextCell();
            }*/
            this.$forceUpdate();
            if (!['Backspace', 'Tab'].includes(e.key) && this.isNumber(e)) {
                this.$nextTick(function () {
                    this.onCellChanged(idx);
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.vue-pincode-input {
    outline: none;
    margin-left: 4px;
    margin-right: 4px;
    padding: 6px;
    width: 36px;
    text-align: center;
    font-size: 28px;
    background: #fff;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    line-height: 0.8;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);

    &:nth-child(3n + 3) {
        margin-right: 10px;
    }

    &:nth-child(4) {
        margin-left: 10px;
    }

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
}

.vue-pincode-input:focus {
    border: 1px solid #3b82f6;
}

.vue-pincode-input:disabled {
    background: #dfe3e9;
    cursor: not-allowed;
    color: #767676;
    border: none;
    box-shadow: none;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.align-items-center {
    align-items: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.d-flex {
    display: flex !important;
}
</style>
