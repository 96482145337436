<template>
    <div class="modal" v-bind="$attrs" data-backdrop="false" tabindex="-1" role="dialog" aria-labelledby="calculator-title">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                        <i class="fa-solid fa-xmark" aria-hidden="true" />
                    </button>

                    <h2 id="calculator-title" class="modal-title"><i class="fa fa-arrows marginRight8" aria-hidden="true" />Calculator</h2>
                </div>
                <div class="modal-body">
                    <div class="calculator">
                        <div class="answer">
                            {{ answer }}
                        </div>
                        <div class="display">
                            {{ logList + current }}
                        </div>

                        <button id="clear" class="btn operator" @click="clear">C</button>

                        <button id="sign" class="btn operator" @click="sign">+/-</button>

                        <button id="percent" class="btn operator" @click="percent">%</button>

                        <button id="divide" class="btn operator" aria-label="divide" @click="divide">/</button>

                        <button id="n7" class="btn" @click="append('7')">7</button>

                        <button id="n8" class="btn" @click="append('8')">8</button>

                        <button id="n9" class="btn" @click="append('9')">9</button>

                        <button id="times" class="btn operator" aria-label="times" @click="times">*</button>

                        <button id="n4" class="btn" @click="append('4')">4</button>

                        <button id="n5" class="btn" @click="append('5')">5</button>

                        <button id="n6" class="btn" @click="append('6')">6</button>

                        <button id="minus" class="btn operator" aria-label="minus" @click="minus">-</button>

                        <button id="n1" class="btn" @click="append('1')">1</button>

                        <button id="n2" class="btn" @click="append('2')">2</button>

                        <button id="n3" class="btn" @click="append('3')">3</button>

                        <button id="plus" class="btn operator" aria-label="plus" @click="plus">+</button>

                        <button id="n0" class="zero" @click="append('0')">0</button>

                        <button id="dot" class="btn" @click="dot">.</button>

                        <button id="equal" class="btn operator" aria-label="equal" @click="equal">=</button>

                        <div :id="'append-answer-alert'" class="visible-hidden" />

                        <button v-if="canAnswer && answer != ''" class="btn" style="width: 280px; height: 60px" data-dismiss="modal" @click="setAnswer">
                            Append to answer
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['canAnswer'],
    data() {
        return {
            logList: '',
            current: '',
            answer: '',
            operatorClicked: true,
        };
    },
    mounted() {
        var that = this;
        Vue.nextTick(function () {
            $(`#${that.$attrs.id} .modal-dialog`).draggable({
                handle: '.modal-header',
            });
        });
    },
    methods: {
        setAnswer() {
            this.$emit('answer', this.answer + '');
        },
        open() {
            this.clear();
            $(`#${this.$attrs.id}`).modal('show');
        },
        close() {
            $(`#${this.$attrs.id}`).modal('hide');
        },
        append(number) {
            if (this.operatorClicked) {
                this.current = '';
                this.operatorClicked = false;
            }
            this.current = `${this.current}${number}`;
        },
        addtoLog(operator) {
            if (this.operatorClicked == false) {
                this.logList += `${this.current} ${operator} `;
                this.current = '';
                this.operatorClicked = true;
            }
        },
        animateNumber(number) {
            let tl = anime.timeline({
                targets: `#${number}`,
                duration: 250,
                easing: 'easeInOutCubic',
            });
            tl.add({ backgroundColor: '#c1e3ff' });
            tl.add({ backgroundColor: '#f4faff' });
        },
        animateOperator(operator) {
            let tl = anime.timeline({
                targets: `#${operator}`,
                duration: 250,
                easing: 'easeInOutCubic',
            });
            tl.add({ backgroundColor: '#a6daff' });
            tl.add({ backgroundColor: '#d9efff' });
        },
        clear() {
            this.current = '';
            this.answer = '';
            this.logList = '';
            this.operatorClicked = false;
        },
        sign() {
            if (this.current != '') {
                this.current = this.current.charAt(0) === '-' ? this.current.slice(1) : `-${this.current}`;
            }
        },
        percent() {
            if (this.current != '') {
                this.current = `${parseFloat(this.current) / 100}`;
            }
        },
        dot() {
            if (this.current.indexOf('.') === -1) {
                this.append('.');
            }
        },
        divide() {
            this.addtoLog('/');
        },
        times() {
            this.addtoLog('*');
        },
        minus() {
            this.addtoLog('-');
        },
        plus() {
            this.addtoLog('+');
        },
        equal() {
            if (this.operatorClicked == false) {
                this.answer = eval(this.logList + this.current);
            } else {
                this.answer = 'WHAT?!!';
            }

            $('#append-answer-alert').attr('role', 'alert');
            $('#append-answer-alert').html(this.answer);
        },
    },
};
</script>
<style scoped>
.modal-content {
    width: 400px !important;
}
.modal-body {
    width: 400px;
}
.calculator {
    display: grid;
    grid-template-rows: repeat(7, minmax(60px, auto));
    grid-template-columns: repeat(4, 60px);
    grid-gap: 12px;
    padding: 35px;
    font-weight: 300;
    font-size: 18px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 3px 80px -30px rgba(13, 81, 134, 1);
}
.btn {
    margin-left: 0px;
}
.btn,
.zero {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #484848;
    background-color: #f4faff;
    border-radius: 5px;
}

.display,
.answer {
    grid-column: 1 / 5;
    display: flex;
    align-items: center;
}

.display {
    color: #a3a3a3;
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: 15px;
    overflow: hidden;
    text-overflow: clip;
}

.answer {
    font-weight: 500;
    color: #146080;
    font-size: 55px;
    height: 65px;
}

.zero {
    grid-column: 1 / 3;
}

.operator {
    background-color: #d9efff;
    color: #3fa9fc;
}
</style>
