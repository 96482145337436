<template>
    <div v-if="component_done_loading" class="container p-0 mt-0 height-100">
        <a href="#" class="d-block invoice-header text-center fw-bold container mt-0">
            <img src="../../../../public/img/whiteLogo.png" height="40px" alt="Intedashboard Logo" />
            <h1 class="fw-bold m-0 printOnly">Intedashboard</h1>
        </a>

        <div class="p-5 invoice-content">
            <h2 class="text-center fw-bold">Invoice</h2>

            <div class="row">
                <div class="col-12 col-sm-6">
                    <div class="p-1">
                        <label>Name</label>
                        <!-- @ -->
                        <p>{{ invoice.name }}</p>
                    </div>
                </div>
                <div class="col-12 col-sm-6">
                    <div class="p-1">
                        <label>Email Address</label>
                        <!-- @ -->
                        <p>{{ invoice.email }}</p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-sm-6">
                    <div class="p-1">
                        <label>Institution</label>
                        <!-- @ -->
                        <p>{{ invoice.accountName }}</p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-sm-6">
                    <div class="p-1">
                        <label>Order Number</label>
                        <!-- @ -->
                        <p>{{ invoice.transactionId }}</p>
                    </div>
                </div>
                <div class="col-12 col-sm-6">
                    <div class="p-1">
                        <label>Order Date</label>
                        <!-- @ -->
                        <p>
                            {{ convertToReadableDate(invoice.startDate, 'DD MMM YYYY').date }}
                            ({{ convertToReadableDate(invoice.startDate, 'DD MMM YYYY').current_timezone }})
                        </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-sm-6">
                    <div class="p-1">
                        <label>Expiry Date</label>
                        <!-- @ -->
                        <p>
                            {{ convertToReadableDate(invoice.endDate, 'DD MMM YYYY').date }}
                            ({{ convertToReadableDate(invoice.endDate, 'DD MMM YYYY').current_timezone }})
                        </p>
                    </div>
                </div>
            </div>

            <h3 class="fw-bold mt-3">Order Summary</h3>

            <div class="table-div">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Item</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Intedashboard Student License</td>
                            <td>1</td>
                            <!-- @ -->
                            <td>{{ invoice.amount }}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td class="fw-bold">Total</td>
                            <!-- @ -->
                            <td>{{ invoice.currency }} {{ invoice.amount }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="invoice-footer text-center container mt-0">
            <!-- @ Dynamically change year -->
            <p>CognaLearn Pte Ltd 2021</p>
            <p>75 Ayer Rajah Crescent, #01-01 Singapore 139953</p>
            <p>US IRS Tax Number (FEIN): 98-1382543</p>
            <p>Singapore Registration: 201537005E</p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            invoice: {},
            component_done_loading: false,
        };
    },
    watch: {
        component_done_loading: {
            handler: function () {
                Vue.nextTick(function () {
                    setTimeout(window.print, 1500);
                });
            },
        },
    },
    created() {
        var that = this;
        axios.get(`/invoices/${this.$route.params.id}`).then(function (response) {
            that.invoice = response.data.data;
            that.component_done_loading = true;
        });
    },
};
</script>

<style scoped>
html,
body,
#app,
.container,
.container > div {
    height: auto;
}

.container {
    width: 100%;
    padding-right: var(1.5rem, 0.75rem);
    padding-left: var(1.5rem, 0.75rem);
    margin-right: auto;
    margin-left: auto;
    box-shadow: 0px 0px 16px rgb(0 0 0 / 16%);
    margin-top: 20px;
    padding: 30px;
    background: #fff;
}

*,
::after,
::before {
    box-sizing: border-box;
}
p {
    margin: 0;
}

h3,
h2 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

h2 {
    font-size: calc(1.375rem + 1.5vw);
    margin-top: 0;
    margin-bottom: 20px;
}

h3 {
    font-size: 24px;
}

label,
p {
    font-size: 16px;
    line-height: 1.5;
}

.table-div {
    overflow-x: auto;
}

table {
    caption-side: bottom;
    border-collapse: collapse;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}

.table {
    width: 100%;
    color: #212529;
    vertical-align: top;
}

thead tr:only-child {
    background-image: none;
    background: none;
}

table,
table tr,
table th,
table td {
    border: none;
    font-size: 16px;
    line-height: 1.5;
}

.table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
}

table > tbody > tr:first-child > td {
    border-top: 1px solid black;
}

.fw-bold {
    font-weight: 700 !important;
}

.text-center {
    text-align: center !important;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(0rem * -1);
    margin-right: calc(2.5rem * -0.5);
    margin-left: calc(2.5rem * -0.5);
}

.row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(2.5rem * 0.5);
    padding-left: calc(2.5rem * 0.5);
    margin-top: var(0);
}

.m-0 {
    margin: 0rem !important;
}

.mt-0 {
    margin-top: 0rem !important;
}

.mt-3 {
    margin-top: 1rem !important;
}

.p-0 {
    padding: 0rem !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.p-5 {
    padding: 2rem !important;
}

.col-12 {
    flex: 0 0 auto;
    width: 100%;
}

.invoice-header,
.invoice-footer {
    padding: 20px 0;
    text-align: center;
    vertical-align: middle;
    background: #495060;
    color: #fff;
}

.invoice-header {
    position: fixed;
    top: 0;
    z-index: 2;
}

.invoice-content {
    position: relative;
    top: 80px;
    z-index: 1;
    background: #fff;
    padding-bottom: 142px !important;
}

.invoice-footer {
    position: fixed;
    bottom: 0;
    z-index: 2;
}

.invoice-footer p {
    font-size: 12px;
}

.height-100 {
    height: 100% !important;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }

    .col-sm-6 {
        flex: 0 0 auto;
        width: 50%;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

.printOnly {
    display: none;
}

@media print {
    .printOnly {
        display: block;
    }
}
</style>
