<template>
    <div v-if="component_done_loading" class="test">
        <test-topbar :test-obj="test"></test-topbar>
        <div class="col-xs-12 right-container">
            <div class="flexSpaceBetween hidden-xs hidden-sm">
                <div class="reportFlex">
                    <div class="gradeReport">
                        <div class="control-label">TOTAL SCORE</div>
                        <div class="gradientReport">
                            <div>
                                {{ test.userOrTeamScore }}<span>/{{ test.possibleTotalScore }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="gradeReport">
                        <div class="control-label">OVERALL</div>
                        <div class="gradientReport">
                            <div>{{ round(test.overall) }}%</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4">
                    <!--<div class="flexSpaceBetween align-items">
						<div class="flexOnly align-items">
							<label class="control-label">DISPLAY</label>
							<div class="form-group-select-wrapper form-dropdown marginLeft10">
						        <select class="form-control">
							        	<option>
							                5
							            </option>
						        </select>
						    </div> 
						</div>
						<div class="displayNo">
	                        Displaying 5 of 10 questions
	                    </div>	
					</div>-->
                    <div v-if="test.allowStudentsToViewAnswer">
                        <div class="legend marginTop20">
                            <div class="legendHeader marginBottom15">Legend</div>
                            <div class="flex">
                                <div><i class="fas fa-minus successColor marginRight8" aria-hidden="true"></i>100%</div>
                                <div class="marginLeft20"><i class="fas fa-minus primaryColor marginRight8" aria-hidden="true"></i>> 50% and &lt; 100%</div>
                                <div class="marginLeft20"><i class="fas fa-minus warningColor marginRight8" aria-hidden="true"></i>≤ 50% and > 0%</div>
                                <div class="marginLeft20"><i class="fas fa-minus dangerColor marginRight8" aria-hidden="true"></i>0%</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="hidden-md hidden-lg">
                <div class="col-xs-12 reportFlex">
                    <div class="gradeReport">
                        <div class="control-label">TOTAL SCORE</div>
                        <div class="gradientReport">
                            <div>
                                {{ test.userOrTeamScore }}<span>/{{ test.possibleTotalScore }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="gradeReport">
                        <div class="control-label">OVERALL</div>
                        <div class="gradientReport">
                            <div>{{ round(test.overall) }}%</div>
                        </div>
                    </div>
                </div>
                <div v-if="test.allowStudentsToViewAnswer" class="col-xs-12 marginTop20">
                    <!--<div class="flexSpaceBetween align-items">
						<div class="flexOnly align-items">
							<label class="control-label">DISPLAY</label>
							<div class="form-group-select-wrapper form-dropdown marginLeft10">
						        <select class="form-control">
							        	<option>
							                5
							            </option>
						        </select>
						    </div> 
						</div>
						<div class="displayNo">
	                        Displaying 5 of 10 questions
	                    </div>	
					</div>-->
                    <div>
                        <div class="legend marginTop20">
                            <div class="legendHeader marginBottom15">Legend</div>
                            <div class="flex">
                                <div><i class="fas fa-minus successColor marginRight8" aria-hidden="true"></i>100%</div>
                                <div class="marginLeft20"><i class="fas fa-minus primaryColor marginRight8" aria-hidden="true"></i>> 50% and &lt; 100%</div>
                                <div class="marginLeft20"><i class="fas fa-minus warningColor marginRight8" aria-hidden="true"></i>≤ 50% and > 0%</div>
                                <div class="marginLeft20"><i class="fas fa-minus dangerColor marginRight8" aria-hidden="true"></i>0%</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <table v-if="test.allowStudentsToViewAnswer" class="table marginTop30">
                <thead>
                    <tr>
                        <th colspan="12">Questions ({{ test.questions.length }})</th>
                    </tr>
                    <tr>
                        <th style="width: 35%">QUESTION</th>
                        <th>SCORE</th>
                        <th>% CORRECT</th>
                        <th class="hidden-xs hidden-sm">MY ANSWER CHOICES IN SEQUENCE</th>
                        <th class="hidden-xs hidden-sm">NARRATIVE FEEDBACK</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(question, idx) in test.questions">
                        <tr>
                            <td>
                                <button
                                    class="btn gradient btn-default subBtn"
                                    :aria-label="`Question ${idx + 1}`"
                                    @click.prevent="openQuestionInfoModal(question)"
                                >
                                    Question {{ idx + 1 }}<i class="fa fa-info-circle marginLeft8" aria-hidden="true"></i>
                                </button>
                                <kr-math class="marginTop10" :input="question.question" :safe="!question.questionIsHTML"></kr-math>
                                <div v-if="question.attachments && question.attachments.length != 0" tabindex="0">
                                    <div class="marginTop20 fontBold">
                                        <i class="fa fa-paperclip marginRight8" aria-hidden="true"></i>QUESTION ATTACHMENT(S)
                                    </div>
                                    <div class="textLink">
                                        <div v-for="(file, idx) in question.attachments" :key="'mcq-' + idx + '-' + file.filename" class="flex">
                                            <i class="fa fa-file-image-o marginRight8" aria-hidden="true"></i>
                                            <template v-if="test.allowStudentsToViewAttachments">
                                                <template v-if="isPdf(file)">
                                                    <template v-if="test.settings.allowPdfDownload">
                                                        <a class="textLink" :href="file.url" target="_blank" download :aria-label="file.filename">
                                                            {{ file.filename }}
                                                        </a>
                                                    </template>
                                                    <template v-else>
                                                        <a class="textLink" :aria-label="file.filename" @click.prevent="openViewPdfModal(file)">{{
                                                            file.filename
                                                        }}</a>
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <a class="textLink" :href="file.url" target="_blank" download :aria-label="file.filename">{{
                                                        file.filename
                                                    }}</a>
                                                </template>
                                            </template>
                                            <template v-else>
                                                <a
                                                    v-tooltip="{
                                                        content: 'Viewing disabled',
                                                    }"
                                                    class="textLink disabled"
                                                    :aria-label="file.filename"
                                                    >{{ file.filename }}
                                                </a>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                {{ round(question.answer.point ? question.answer.point : 0, 2) }}
                            </td>
                            <td>
                                <div
                                    class="statusTag"
                                    :class="{
                                        successBackground: question.answer.percent == 100,
                                        primaryBackground: question.answer.percent >= 50 && question.answer.percent < 100,
                                        warningBackground: question.answer.percent < 50 && question.answer.percent > 0,
                                        dangerBackground: question.answer.percent == 0,
                                    }"
                                >
                                    {{ round(question.answer.percent) }}%
                                </div>
                            </td>
                            <td class="hidden-xs hidden-sm">
                                <template v-if="!test.settings.confidenceBasedTesting || ['mcqm', 'openended'].includes(question.type)">
                                    <div v-html="question.answer.answer ? question.answer.answer : '-'"></div>
                                </template>
                                <template v-else>
                                    {{ getPointSpreadingAttempts(question) }}
                                </template>
                            </td>
                            <td class="hidden-xs hidden-sm">
                                {{ question.answer.narrativeFeedback }}
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <div
            id="teamAnalysisQuestionInfoModal"
            class="modal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            aria-labelledby="teamAnalysisQuestionInfoModal-title"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true"></i>
                        </button>

                        <h2 id="teamAnalysisQuestionInfoModal-title" class="modal-title">View Question</h2>
                    </div>
                    <div class="modal-body">
                        <question-display :question="previewQuestion"></question-display>
                        <div class="col-xs-12 buttonGroup marginTop30">
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <!-- <div style="font-weight:bold;font-size:12px;text-align:left;">QUESTION LAST UPDATED ON</div>
			      	<div style="font-size:14px;text-align:left;margin-top:10px;">{{convertToReadableDate(previewQuestion.lastModified,'LLLL').date}}</div>
			      	<div style="margin-top:20px;" v-if="!previewQuestion.isTrashed">
				        <button @click.prevent="editQuestion(previewQuestion)" class="btn btn-secondary btn-action hidden-xs hidden-sm modalEditBtn" style="width:70%;line-height:1;padding:10px;margin:0;" data-dismiss="modal"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit Question</button>
				        <button class="btn btn-danger hidden-xs hidden-sm" style="width:28%;height:36px;" @click.prevent="remove(previewQuestion.uuid)"><i class="fa fa-trash-o"></i> Delete</button>
				         <button @click.prevent="editQuestion(previewQuestion)" class="btn btn-secondary btn-action hidden-md hidden-lg modalEditBtn" style="width:100%;line-height:1;padding:10px;margin-bottom:10px;"><i class="fa fa-pencil-square-o" aria-hidden="true" data-dismiss="modal"></i> Edit Question</button>
				        <button class="btn deleteBtn hidden-md hidden-lg" style="width:100%;height:36px;" @click.prevent="remove(previewQuestion.uuid)"><i class="fa fa-trash-o"></i> Delete</button>
				    </div>
				    <div style="margin-top:20px;" v-else>
				        <button class="btn btn-outline-info btn-action" style="width:100%;line-height:1;padding:10px;;margin-bottom:10px;" @click.prevent="reinstate(previewQuestion.uuid)"><i class="fa fa-refresh" aria-hidden="true"></i> Reinstate</button>
				    </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            component_done_loading: false,
            test: {},
            previewQuestion: {},
        };
    },
    mounted() {
        var that = this;
        this.fetchTest().then(function (response) {
            that.init(response);
            that.processEchoListener();
            that.component_done_loading = true;
        });
    },
    created() {
        var that = this;
        $('body').addClass('test').removeClass('nav-sm').addClass('nav-none');
    },
    methods: {
        processEchoListener() {
            var that = this;
            let h1 = (e) => {
                if (e.test.uuid != that.test.uuid) {
                    return false;
                }
                that.test.allowStudentsToViewAnswer = e.test.allowStudentsToViewAnswer;
                that.test.allowStudentsToViewScore = e.test.allowStudentsToViewScore;
                let allowStudentsToViewAttachments = that.test.allowStudentsToViewAttachments;
                that.test.allowStudentsToViewAttachments = e.test.allowStudentsToViewAttachments;
                that.test.allowStudentsToPreviewQuestions = e.test.allowStudentsToPreviewQuestions;
                if (!e.test.allowStudentsToViewScore) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Teacher has disabled viewing of test results.',
                    });
                    that.$router.push({ name: 'tests.index' });
                } else {
                    if (allowStudentsToViewAttachments != that.test.allowStudentsToViewAttachments) {
                        this.fetchTest().then(function (response) {
                            that.init(response);
                        });
                    }
                }
            };
            let c1 = window.Echo.private(`activity.${that.test.activityUuid}.student`).listen('TestVisibilityUpdated', h1);
            this.echoChannels.push({
                channel: c1,
                event: 'TestVisibilityUpdated',
                handler: h1,
            });
        },
        init(response) {
            var that = this;
            that.test = response.data.data;
            for (var i = 0; i < that.test.questions.length; i++) {
                if (!that.test.questions[i].answer) {
                    that.test.questions[i].answer = {
                        answer: '',
                        attempts: [],
                        isCorrect: false,
                        percent: 0,
                        point: '0.00',
                    };
                }
            }
        },
        getPointSpreadingAttempts(question) {
            let attempts = _.get(question.answer, 'attempts', []);
            if (attempts == null) {
                attempts = [];
            }
            if (attempts.length > 0) {
                let ret = '';
                let attempt = attempts[question.answer.attempts.length - 1];
                for (var k in attempt) {
                    if (attempt.hasOwnProperty(k)) {
                        ret += k + ':' + attempt[k] + ' ';
                    }
                }
                return ret;
            } else {
                return '-';
            }
        },
        openQuestionInfoModal(question) {
            this.previewQuestion = question;
            $('#teamAnalysisQuestionInfoModal').modal('show');
        },
        fetchTest() {
            return axios.get('student/tests/' + this.$route.params.id + '/report');
        },
        openViewPdfModal(attachment) {
            var that = this;
            Events.fire('view-pdf-modal', attachment, {
                canDownload: that.test.settings.allowPdfDownload,
            });
        },
    },
    components: {
        'test-topbar': require(`./../partials/topbar.vue`).default,
        'question-display': require(`./../../../questions/partials/question-display.vue`).default,
    },
    beforeUnmount() {
        if ($(window).width() > 991) {
            $('body').removeClass('test').addClass('nav-sm').removeClass('nav-none');
        } else {
            $('body').removeClass('test').removeClass('nav-sm').addClass('nav-none');
        }
    },
};
</script>

<style scoped>
.reportFlex {
    display: flex;
}

.reportFlex .gradeReport {
    background: #fff;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
}

.gradeReport .control-label {
    text-align: center;
}

.reportFlex .gradeReport:first-child {
    margin-left: 0;
}

.gradientReport {
    background: linear-gradient(to left, #546ea9 70%, #f4f4f4 100%);
    border-radius: 40px;
    box-sizing: border-box;
    color: #222;
    display: block;
    letter-spacing: 1px;
    margin: 0 auto;
    padding: 4px;
    position: relative;
    text-transform: uppercase;
    z-index: 2;
}

.gradientReport div {
    align-items: center;
    background: #f8f8f8;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-weight: bold;
}

.gradientReport span {
    color: #767676;
    font-size: 14px;
}

.legend {
    padding: 15px;
    border: 1px solid #d8d8d8;
    border-radius: 3px;
    background: #fff;
}

.legend .legendHeader {
    text-transform: uppercase;
    font-weight: bold;
}

.legend div:last-child div {
    display: flex;
    align-items: center;
    line-height: 0.8;
}

.legend .fas {
    font-size: 15px;
}

@media (max-width: 991px) {
    .reportFlex .gradeReport {
        padding: 10px;
    }

    .reportFlex .gradeReport {
        width: 31.33%;
        margin-left: 3%;
    }

    .gradientReport {
        height: 70px;
        width: 70px;
        font-size: 20px;
    }

    .gradeReport .control-label,
    .gradientReport span {
        font-size: 12px;
    }
}

@media (min-width: 992px) {
    .reportFlex .gradeReport {
        padding: 10px 25px;
    }

    .reportFlex .gradeReport {
        width: 130px;
        margin-left: 30px;
    }

    .gradientReport {
        height: 80px;
        width: 80px;
        font-size: 24px;
    }
}
</style>
