<template>
    <div>
        <div class="payment-wrapper">
            <section class="payment-section">
                <div class="payment-container">
                    <div class="payment-logo-container">
                        <a href="https://www.intedashboard.com">
                            <div class="payment-logo" style="background-image: url('/img/whiteLogo.png')"></div>
                        </a>
                    </div>
                    <div class="payment-body-wrapper">
                        <div v-if="!isPaymentConfirmed" class="marginBottom30">
                            <router-link :to="{ path: '/' }" class="btn btn-default subBtn" tag="button"
                                ><i class="fas fa-arrow-left marginRight8" aria-hidden="true"></i>Back to Dashboard</router-link
                            >
                            <button v-if="auth.user().role == 'Student'" class="btn btn-danger subBtn" aria-label="Log Out" @click="logout">Log out</button>
                        </div>
                        <template v-if="!isPaymentConfirmed">
                            <div v-if="paypalFormError" id="paypalFormErrorDiv" class="alert alert-danger marginBottom20" style="font-size: 16px">
                                <i class="fas fa-exclamation-triangle marginRight8" aria-hidden="true"></i>{{ paypalFormError }}
                            </div>
                            <div class="flex">
                                <div class="col-xs-12 col-md-6">
                                    <label class="control-label">FIRST NAME</label>
                                    <p class="form-control-static">
                                        {{ student.firstname }}
                                    </p>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <label class="control-label">LAST NAME</label>
                                    <p class="form-control-static">
                                        {{ student.lastname }}
                                    </p>
                                </div>
                            </div>
                            <div>
                                <label class="control-label">EMAIL</label>
                                <p class="form-control-static">
                                    {{ student.email }}
                                </p>
                            </div>
                            <div class="colsGap">
                                <label class="control-label">PLEASE SELECT PAYMENT METHOD <span class="important">*</span></label>
                                <div class="mdFlex">
                                    <div class="col-xs-12 col-md-6">
                                        <button
                                            class="col-xs-12 btn btn-default height60px"
                                            :class="{
                                                active: form.model.paymentMode == 'access',
                                            }"
                                            aria-label="Access Code"
                                            @click.prevent="changeMode(1)"
                                        >
                                            <i v-if="form.model.paymentMode == 'access'" class="fa fa-check-circle blue marginRight8" aria-hidden="true"></i>
                                            <i v-else class="far fa-circle greyText marginRight8" aria-hidden="true"></i>Access code
                                        </button>
                                    </div>
                                    <div class="col-xs-12 col-md-6">
                                        <button
                                            class="col-xs-12 btn btn-default height60px"
                                            :class="{
                                                active: form.model.paymentMode == 'paypal',
                                            }"
                                            aria-label="Paypal"
                                            @click.prevent="changeMode(2)"
                                        >
                                            <i v-if="form.model.paymentMode == 'paypal'" class="fa fa-check-circle blue marginRight8" aria-hidden="true"></i>
                                            <i v-else class="far fa-circle greyText marginRight8" aria-hidden="true"></i>
                                            <img class="paypalLogo" src="/img/stripe.png" alt="paypal logo" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div v-show="form.model.paymentMode == 'access'" class="marginTop30">
                                <div class="flexSpaceBetween">
                                    <label class="control-label">ACCESS CODE <span class="important">*</span></label>
                                    <div
                                        style="cursor: pointer"
                                        @click.prevent="
                                            form.model.code = '';
                                            form.errors.clear('code');
                                        "
                                    >
                                        <i class="fa fa-repeat marginRight8" aria-hidden="true"></i>Clear
                                    </div>
                                </div>
                                <div>
                                    <kr-field
                                        :form="form"
                                        name="code"
                                        :options="{
                                            hasLabel: false,
                                            hideHelpBlockIfNoErrors: true,
                                        }"
                                    ></kr-field>
                                </div>
                            </div>

                            <div v-show="form.model.paymentMode == 'paypal'" class="marginTop30 colsGap">
                                <label class="control-label">
                                    PLEASE SELECT PLAN TYPE
                                    <span class="important">*</span>
                                </label>
                                <div class="mdFlex">
                                    <div v-if="plans.semester" class="col-xs-12 col-md-6">
                                        <div
                                            class="new-stripe-design textCenteralize"
                                            :class="{
                                                active: planType == 'semester',
                                            }"
                                            @click.prevent="planType = 'semester'"
                                        >
                                            <div class="flexSpaceBetween">
                                                <div>
                                                    {{ plans.semester.plan }}
                                                </div>
                                                <div>{{ plans.semester.currency }}{{ plans.semester.price }}</div>
                                            </div>

                                            <div class="marginTop20">
                                                <i class="fas fa-calendar-minus" aria-hidden="true"></i>
                                            </div>

                                            <button class="marginTop20" aria-label="Semester Plan">Semester Plan</button>
                                        </div>
                                    </div>
                                    <div v-if="plans.yearly" class="col-xs-12 col-md-6">
                                        <div
                                            class="new-stripe-design textCenteralize"
                                            :class="{
                                                active: planType == 'yearly',
                                            }"
                                            @click.prevent="planType = 'yearly'"
                                        >
                                            <div class="flexSpaceBetween">
                                                <div>
                                                    {{ plans.yearly.plan }}
                                                </div>
                                                <div>{{ plans.yearly.currency }}{{ plans.yearly.price }}</div>
                                            </div>

                                            <div class="marginTop20">
                                                <i class="fas fa-calendar-alt" aria-hidden="true"></i>
                                            </div>

                                            <button class="marginTop20" aria-label="Yearly Plan">Yearly Plan</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex marginTop30">
                                <button
                                    v-if="form.model.paymentMode == 'access'"
                                    class="btn btn-success mainBtn"
                                    :disabled="!canSend || paypalFormBusy ? true : undefined"
                                    aria-label="Confirm"
                                    @click="confirmPayment"
                                >
                                    Confirm
                                </button>
                                <button
                                    v-if="form.model.paymentMode == 'paypal'"
                                    class="btn btn-success mainBtn col-xs-12"
                                    aria-label="Checkout via Stripe"
                                    @click="getStripe"
                                >
                                    <template v-if="!paypalFormBusy"> Checkout via Stripe </template>
                                    <template v-else>
                                        <span class="fa fa-spinner fa-spin" aria-hidden="true"></span>
                                    </template>
                                </button>
                            </div>
                        </template>
                        <!-- Successfully Paymember -->
                        <div v-else>
                            <div class="textAlignCenter">
                                <div class="darkGreen lineHeight1 fontSize26">
                                    <i class="fas fa-check-circle" aria-hidden="true"></i>
                                </div>
                                <div class="fontBold lineHeight1 marginTop10 fontSize20">The payment was successfully made!</div>
                                <div class="marginTop10">Thank you for choosing InteDashboard.</div>
                            </div>
                            <div class="marginTop20 colsGap flex">
                                <!--<div class="col-xs-6">
		                    		<button class="col-xs-12 btn btn-primary mainBtn">
		                    			View Receipt
		                    		</button>
	                    		</div>-->
                                <div class="col-xs-12">
                                    <router-link :to="{ name: '/' }" tag="button" class="col-xs-12 btn btn-primary mainBtn">
                                        Go to my InteDashboard<i class="fas fa-caret-right marginLeft8" aria-hidden="true"></i>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import KrForm from '../../components/forms/form';
import KrAuth from '../../components/auth/auth';
const card_validator = require('card-validator');
const countries = require('i18n-iso-countries');
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
export default {
    data() {
        var auth = new KrAuth();
        var student = auth.user();
        return {
            component_done_loading: false,
            form: new KrForm({
                fields: {
                    paymentMode: 'default!:access',
                    identity: 'default!:' + student.identity,
                    code: '',
                },
                props: {},
                urls: {
                    post: '',
                },
            }),
            auth: auth,
            isPaymentConfirmed: false,
            paypalFormBusy: false,
            paypalFormError: '',
            plans: {},
            plan_idx: 0,
            student: student,
            planType: 'yearly',
        };
    },
    computed: {
        canSend() {
            if (this.form.model.paymentMode == 'access') {
                if (!this.form.model.code) {
                    return false;
                }
            }
            if (this.form.errors.any()) {
                return false;
            }
            return true;
        },
    },
    created() {
        if (this.auth.user().role == 'Student') {
            this.processIsStudent();
        } else {
            this.fetchStudent();
        }
    },
    mounted() {
        if ($(window).width() > 350) {
            Vue.nextTick(function () {
                var sectionHeight = $('.payment-section').height();

                if (sectionHeight < window.height) {
                    $('.payment-wrapper').css('height', '100%');
                } else {
                    $('.payment-wrapper').css('height', sectionHeight + 'px');
                }
            });
        } else {
            $('.payment-wrapper').css('height', '100%');
        }
    },
    methods: {
        getPaymentPlans() {
            return axios.get(`/student/payment-plans`);
        },
        getStripe() {
            let d = this.planType;
            axios
                .get(`/payments/stripe/${d}${this.auth.user().role == 'Student' ? '' : '?gift=true&studentUuid=' + this.student.uuid}`)
                .then(function (response) {
                    location.href = response.data.url;
                })
                .catch(function (errors) {});
        },
        processIsStudent() {
            let shouldPay = false;
            var that = this;
            if (this.student.role == 'Student') {
                if (['Not Yet Invited', 'Active (Not Yet Paid)', 'Expired', 'Lapsed', 'Invite Sent'].includes(this.student.status)) {
                    if (this.student.account.paymentMethod == 'Student-Paid' && !this.student.hasTeacherAccount) {
                        shouldPay = true;
                    }
                }
                if (!shouldPay) {
                    this.$router.push({ path: '/' });
                    return;
                }
                this.getPaymentPlans().then(function (response) {
                    that.plans = response.data.data;
                    if (!that.plans.yearly) {
                        that.planType = 'semester';
                    }
                    that.component_done_loading = true;
                });
            }
        },
        fetchStudent() {
            var that = this;
            axios.get('/users/' + that.$route.params.id).then(function (response) {
                that.student = response.data.data;
                that.form.model.identity = that.student.identity;
                that.processIsStudent();
            });
        },
        logout() {
            Events.fire('logout', { redirect: { name: 'auth.login' } });
        },
        changeMode(mode) {
            this.form.errors.clear();
            if (mode == 1) {
                this.form.model.paymentMode = 'access';
                //student mode
            } else {
                this.form.model.paymentMode = 'paypal';
            }

            if ($(window).width() > 350) {
                Vue.nextTick(function () {
                    var sectionHeight = $('.payment-section').height();

                    if (sectionHeight < window.height) {
                        $('.payment-wrapper').css('height', '100%');
                    } else {
                        $('.payment-wrapper').css('height', sectionHeight + 'px');
                    }
                });
            }

            $(window).resize(function () {
                if ($(window).width() > 350) {
                    Vue.nextTick(function () {
                        var sectionHeight = $('.payment-section').height();

                        if (sectionHeight < window.height) {
                            $('.payment-wrapper').css('height', '100%');
                            //console.log('im in here true')
                        } else {
                            $('.payment-wrapper').css('height', sectionHeight + 'px');
                            //console.log('im in here false')
                        }
                    });
                }
            });
        },
        confirmPayment() {
            var that = this;
            that.paypalFormError = '';
            if (this.form.model.paymentMode == 'access') {
                axios
                    .post('/payments/checkout', {
                        gift: that.auth.user().role == 'Student' ? false : true,
                        paymentMode: this.form.model.paymentMode,
                        identity: this.form.model.identity,
                        code: this.form.model.code,
                    })
                    .then(function (response) {
                        if (that.auth.user().role == 'Student') {
                            that.$store.commit('status', 'Active (Paid)');
                        }
                        that.isPaymentConfirmed = true;
                    })
                    .catch(function (errors) {
                        that.form.errors.append('code', [_.get(errors, 'response.data.message', 'Please enter valid access code')]);
                    });
            }
        },
    },
};
</script>
<style lang="scss">
@media (min-width: 992px) {
    .mdFlex {
        display: flex;
    }

    .mdFlex .col-md-6:first-child {
        padding-right: 10px;
    }

    .mdFlex .col-md-6:last-child {
        padding-left: 10px;
    }
}
@media (max-width: 991px) {
    .mdFlex .col-md-6:last-child {
        margin-top: 30px;
    }
}

@media (min-width: 351px) {
    .payment-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .payment-section {
        width: 670px;
    }
}

.payment-container {
    max-width: 670px;
    background: #fff;
    border: solid 1px #dfdfdf;
    margin: 100px auto;
    margin: 0 auto;
    overflow: hidden;
    box-shadow: -1px 1px 5px 0 rgba(202, 202, 202, 0.5);
}

.payment-logo-container {
    background-color: #495060;
    color: white;
    height: 95px;
    padding: 15px;
    text-align: center;
}

.payment-logo {
    width: 270px;
    height: 68px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
    display: inline-block;
}

.payment-body-wrapper {
    width: 100%;
    padding: 30px 20px;
}

.paypalLogo {
    width: 100px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
    display: inline-block;
}

.colsGap .col-xs-6:first-child {
    padding-right: 10px;
}

.colsGap .col-xs-6:last-child {
    padding-left: 10px;
}

.colsGap .col-md-8:first-child {
    padding-right: 10px;
}

.colsGap .col-md-4:last-child {
    padding-left: 10px;
}

.height60px {
    height: 60px;
}

.colsGap .btn.height60px {
    font-size: 20px;
}

.colsGap .btn.height60px i {
    font-size: 15px;
}

.colsGap .btn-default:active,
.colsGap .btn-default:hover,
.colsGap .btn-default:focus,
.colsGap .btn-default.active {
    border: 1px solid #1991eb;
}

.totalAmount {
    font-size: 24px;
    font-weight: bold;
}

.new-stripe-design {
    border: 1px solid #d8d8d8;
    border-radius: 8px;
    padding: 10px;

    .flexSpaceBetween {
        align-items: center;
        div {
            &:first-child {
                font-size: 16px;
                font-weight: bold;
                text-transform: uppercase;
            }

            &:last-child {
                font-size: 28px;
                font-weight: bold;
                color: #1991eb;
            }
        }
    }

    i {
        font-size: 66px;
        color: #d8d8d8;
    }

    button {
        background: #ededed;
        border-radius: 20px;
        padding: 15px 20px;
        text-transform: uppercase;
    }

    &:hover,
    &.active {
        border: 1px solid #1991eb;

        i {
            background: -webkit-linear-gradient(#40d5e6, #5577ff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        button {
            background: #1991eb;
            color: #fff;
        }
    }
}
</style>
