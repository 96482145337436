<template>
    <div>
        <transition name="slide-fade" mode="out-in">
            <div class="container body">
                <div
                    v-if="auth.isImpersonation()"
                    style="
                        z-index: 30;
                        position: fixed;
                        top: 0;
                        width: 100%;
                        background-color: #4682b4;
                        color: white;
                        text-align: center;
                        height: 40px;
                        line-height: 40px;
                        font-size: 19px;
                    "
                >
                    You are currently in Technical Support Mode ({{ auth.user().displayName }}).
                    <a style="font-weight: bold; color: white; text-decoration: underline" href="#" @click="logout">Exit</a>
                </div>
                <div v-if="auth.isImpersonation()" style="height: 40px" />
                <div class="main_container">
                    <sidebar class="hidden-xs hidden-sm" />
                    <topbar />
                    <main class="right_col">
                        <div v-if="notPaid && !isExpired" class="payment-alert sticky marginTop20">
                            <router-link
                                tag="div"
                                :to="{ name: 'payments' }"
                                class="alert alert-danger flexSpaceBetween align-items"
                                role="alert"
                                style="padding-left: 10px"
                            >
                                <div class="flexOnly">
                                    <i class="fas fa-exclamation-triangle marginRight8 paddingTop3" aria-hidden="true" />
                                    <template v-if="paidOffset > 0">
                                        ALERT: Payment has been overdue for
                                        {{ abs(paidOffset) }} day(s). Click&nbsp; <span class="td-underline">here</span>&nbsp;to complete your payment.
                                    </template>
                                    <template v-else>
                                        ALERT: Your free trial expires in
                                        {{ abs(paidOffset) }} day(s). Click&nbsp; <span class="td-underline">here</span>&nbsp;to complete your payment.
                                    </template>
                                </div>
                            </router-link>
                        </div>

                        <div v-if="isExpired" class="payment-alert sticky marginTop20">
                            <router-link
                                tag="div"
                                :to="{ name: 'payments' }"
                                class="alert alert-danger flexSpaceBetween align-items"
                                role="alert"
                                style="padding-left: 10px"
                            >
                                <div class="flexOnly">
                                    <i class="fas fa-exclamation-triangle marginRight8 paddingTop3" aria-hidden="true" />
                                    ALERT: Your account has expired already. Please click&nbsp;
                                    <span class="td-underline">here</span>&nbsp;to renew.
                                </div>
                            </router-link>
                        </div>
                        <transition name="slide-fade" mode="out-in">
                            <router-view />
                        </transition>
                    </main>
                </div>
                <foot />
            </div>
        </transition>

        <notifications group="form" :max="1" role="alert" />
        <notifications group="profile-update-success" position="top right" :max="1">
            <template #body="props">
                <div class="notification profile-update-notification">
                    <a class="close" @click="props.close">
                        <i class="fa fa-fw fa-close" aria-hidden="true" />
                    </a>
                    <div class="row">
                        <div class="col-xs-12" style="padding-left: 0px">
                            <div v-if="auth.isLoggedIn()" class="notification-img col-xs-2 col-sm-1">
                                <template v-if="auth.user().avatar">
                                    <img :src="auth.user().avatar" style="width: 24px; height: 24px" class="img-circle profile_img" alt="profile image" />
                                </template>
                                <template v-else>
                                    <span class="badge_profile"
                                        ><span> {{ initials }} </span></span
                                    >
                                </template>
                            </div>
                            <div class="col-xs-10 col-sm-10">
                                <div class="notification-title">
                                    {{ props.item.title }}
                                </div>
                                <div class="notification-content" v-html="props.item.text" />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </notifications>
        <notifications group="download-report" position="top right">
            <template #body="props">
                <div class="vue-notification-template vue-notification success">
                    <div class="notification-title">
                        {{ props.item.title }}
                        <div class="close" @click="props.close">
                            <i class="fa fa-fw fa-close" aria-hidden="true" />
                        </div>
                    </div>

                    <div class="notification-content">
                        <span v-html="props.item.text" />&nbsp;
                        <template v-if="props.item.title == 'Success'">
                            Click
                            <a @click.prevent="downloadReport(props.item.data)">
                                <b>here</b>
                            </a>
                            to download.
                        </template>
                    </div>
                </div>
            </template>
        </notifications>

        <supportWidget v-if="auth.user().role != 'Student'" />

        <div id="statusUpdateModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="statusUpdateModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="statusUpdateModal-title" class="modal-title">Status Updates</h2>
                    </div>
                    <div class="modal-body">
                        <b>Status Update:</b>
                        <p class="marginBottom8">Related to answer selection and activity submission on IRAT & TRAT's</p>

                        <b>Issue/s</b>
                        <p class="marginBottom8">
                            Recently there were some incidents related to learners experiencing errors during the IRAT and TRAT activity process. These errors
                            caused abnormal usage behaviour, intermittent time outs, and login issues.
                        </p>

                        <b>Action/s Taken</b>
                        <p class="marginBottom8">
                            We have disabled the function/s that kicks learners out of activities. This means that when the IRAT/TRAT timer reaches zero or
                            timeout, learners will NOT be kicked out or log out; instead, they will still be allowed to continue with the activities. This will
                            allow the teacher to manually manage the time taken provided to the learners to complete. This action will minimise the
                            inconvenience of the “Issue/s” stated.
                        </p>

                        <b>What’s Next</b>
                        <p class="marginBottom8">We have identified and rectifying the issue/s with high priority assigned to it.</p>

                        <b>Timeline Recovery</b>
                        <p>
                            We are expecting that the issue/s to be solved within the next 72 hours; or a little more time is anticipated depending on the
                            server/coding restriction. Meanwhile, please
                            <a class="link" href="mailto:support@intedashboard.com">email</a>
                            us if you need assistance and we will get back to you as soon as we possibly can!
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div id="paymentAlertModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="paymentAlertModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="paymentAlertModal-title" class="modal-title">Alert</h2>
                    </div>
                    <div class="modal-body">
                        <img src="/img/expired.png" width="20%" alt=" " />

                        <div v-if="notPaid && !isExpired">
                            <template v-if="paidOffset > 0">
                                <h2 class="fontBold">
                                    Payment has been overdue for
                                    <span class="dangerColor">{{ abs(paidOffset) }} day(s)</span>.
                                </h2>
                            </template>
                            <template v-else>
                                <h2 class="fontBold">
                                    Your free trial expires in
                                    <span class="dangerColor">{{ abs(paidOffset) }} day(s)</span>.
                                </h2>
                            </template>
                            <p>
                                Click
                                <span class="td-underline">Make Payment</span>
                                to complete your payment.
                            </p>
                        </div>

                        <div v-else-if="isExpired">
                            <h2 class="fontBold">Your account has expired already.</h2>
                            <p>
                                Please click on
                                <span class="td-underline">Make Payment</span>
                                to renew.
                            </p>
                        </div>

                        <div class="col-xs-12 marginTop20 buttonGroup">
                            <router-link tag="button" :to="{ name: 'payments' }" class="btn btn-primary mainBtn" data-dismiss="modal" aria-label="Close">
                                Make Payment
                            </router-link>
                        </div>
                    </div>
                    <div class="modal-footer" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import KrAuth from './../../../components/auth/auth';
export default {
    components: {
        topbar: require(`./partials/topbar.vue`).default,
        sidebar: require(`./partials/sidebar.vue`).default,
        foot: require(`./partials/footer.vue`).default,
        supportWidget: require(`./partials/supportWidget.vue`).default,
    },
    data() {
        let auth = new KrAuth();
        return {
            auth: auth,
            initials: (auth.user().firstname ? auth.user().firstname.charAt(0) : '') + (auth.user().lastname ? auth.user().lastname.charAt(0) : ''),
            paidOffset: 0,
        };
    },
    computed: {
        notPaid() {
            //return true;
            if (this.auth.user().role == 'Student') {
                if (!['Active (Paid)'].includes(this.auth.user().status) && !['Expired'].includes(this.auth.user().status)) {
                    if (this.auth.user().account.paymentMethod == 'Student-Paid' && !this.auth.user().hasTeacherAccount) {
                        return true;
                    }
                }
                return false;
            }
            return false;
        },
        isExpired() {
            if (this.auth.user().role == 'Student') {
                if (this.auth.user().status == 'Expired') {
                    if (this.auth.user().account.paymentMethod == 'Student-Paid' && !this.auth.user().hasTeacherAccount) {
                        return true;
                    }
                }
                return false;
            }
            return false;
        },
        isLapsed() {
            if (this.auth.user().role == 'Student') {
                if (this.auth.user().status == 'Lapsed') {
                    if (this.auth.user().account.paymentMethod == 'Student-Paid' && !this.auth.user().hasTeacherAccount) {
                        return true;
                    }
                }
                return false;
            }
            return false;
        },
    },
    created() {
        $('body').removeClass('login');
        $('body').attr('class', 'nav-sm chatbar-hidden');
        //$("body").attr("class", "nav-none chatbar-hidden");
        $('#app').attr('class', 'wrapper');
        this.updateUI();
        if (this.notPaid) {
            let a = moment();
            let b = moment.utc(this.auth.user().dueDate, 'YYYY-MM-DDThh:mm:ssZ').local();
            this.paidOffset = a.diff(b, 'days');
        }
    },
    mounted() {
        this.updateUI();
        if (this.notPaid || this.isExpired) {
            $('#paymentAlertModal').modal('show');
        }
    },
    afterUpdate() {
        this.updateUI();
        if (this.notPaid || this.isExpired) {
            $('#paymentAlertModal').modal('show');
        }
    },
    methods: {
        abs(num) {
            return Math.abs(num);
        },
        downloadReport(report) {
            axios({
                url: report.link,
                method: 'POST',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', report.filename);
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
        },
        logout() {
            Events.fire('logout', { redirect: { name: 'auth.login' } });
        },
        updateUI() {
            var that = this;
            var CURRENT_URL = window.location.href.split('?')[0],
                $BODY = $('body'),
                $MENU_TOGGLE = $('#menu_toggle'),
                $SIDEBAR_MENU = $('#sidebar-menu'),
                $SIDEBAR_FOOTER = $('.sidebar-footer'),
                $LEFT_COL = $('.left_col'),
                // $RIGHT_COL = $('.right_col'),
                $NAV_MENU = $('.nav_menu'),
                $FOOTER = $('footer');
            // var setContentHeight =
            //     // reset height
            //     _.debounce(function()
            //         {
            //             $RIGHT_COL.css('min-height', 'calc(100vh - 47px)');
            //             var bodyHeight = $BODY.outerHeight(),
            //                 footerHeight = $BODY.hasClass('footer_fixed') ? 0 : $FOOTER.height(),
            //                 leftColHeight = $LEFT_COL.eq(1).height() + $SIDEBAR_FOOTER.height(),
            //                 contentHeight = bodyHeight < leftColHeight ? leftColHeight : bodyHeight;
            //             // normalize content
            //             contentHeight -= $NAV_MENU.height() + footerHeight;
            //             $RIGHT_COL.css('min-height', contentHeight);
            //         },1000, {
            //             'leading': true,
            //             'trailing': false
            //         });
            var menu_toggle = function (bit = 0) {
                if (bit == 0 && $BODY.hasClass('nav-none') && $BODY.hasClass('chatbar-visible')) {
                    $BODY.toggleClass('chatbar-visible chatbar-hidden');
                }
                if ($BODY.hasClass('nav-sm')) {
                    $SIDEBAR_MENU.find('li.active ul').hide();
                    $SIDEBAR_MENU.find('li.active').addClass('active-sm').removeClass('active');
                } else {
                    $SIDEBAR_MENU.find('li.active-sm ul').show();
                    $SIDEBAR_MENU.find('li.active-sm').addClass('active').removeClass('active-sm');
                }
                $BODY.toggleClass('nav-sm nav-none');
                //setContentHeight();
            };
            // toggle small or large menu
            $MENU_TOGGLE.on('click', function () {
                menu_toggle(0);
            });
            $(document).ready(function () {
                window.addEventListener('resize', function (event) {
                    // do stuff here
                    if ($(window).width() > 991) {
                        if (!$BODY.hasClass('test')) {
                            $BODY.addClass('nav-sm').removeClass('nav-none');
                        }
                    } else {
                        $BODY.addClass('nav-none').removeClass('nav-sm');
                    }
                });
            });
        },
        openStatusUpdateModal() {
            $('#statusUpdateModal').modal('show');
        },
    },
};
</script>

<style>
.badge_profile {
    background: #2a3f54;
    color: white;
    width: 24px;
    height: 24px;
    line-height: 1;
    font-size: 24px;
}
</style>
<style scoped>
.payment-alert {
    cursor: pointer;
}
.notification-content a {
    color: #1991eb;
}
.alert-danger {
    padding-left: 25px;
}
.sticky {
    position: sticky;
    top: 60px;
    z-index: 100;
}
</style>
