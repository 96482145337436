<template>
    <kr-panel :with-footer="false">
        <template #title>
            <div class="col-xs-12 flexSpaceBetween align-items">
                <div class="col-xs-6">
                    <h2>ENROLLMENT SETTINGS</h2>
                </div>
            </div>
        </template>
        <template #content>
            <div class="col-xs-12 enrollmentSetting w-auto">
                <kr-toggle :options="{ reverse: true, showLabel: false }" name="isLtiEnabled" :form="form" class="marginTop5" @changed="updateIsLtiEnabled" />
                <div class="paddingLeft20">
                    <label class="fs-24px marginBottom8">Enable LTI Integration with my LMS</label>
                    <template v-if="form.model.isLtiEnabled">
                        <p>
                            Use the following LTI configuration and credentials to set up the LTI link between your LMS and InteDashboard.
                            <a
                                href="https://help.intedashboard.com/kb-search-results?term=LTI"
                                class="link"
                                target="newTab"
                                aria-label="Click here for more information of on LTI configuration"
                            >
                                More information on LTI configuration</a
                            >.
                        </p>
                        <div class="LMSRow">
                            <div class="LMSSubCon">
                                <div class="LMSContainer">
                                    <div class="LMSTitle">
                                        <label>CONFIGURATION URL</label>
                                        <span class="hidden-xs hidden-sm">:</span>
                                    </div>
                                    <div class="LMSContent w-auto">
                                        <p>{{ API_URL }}/lti/config/{{ course.consumerKeyLTI }}</p>
                                    </div>
                                </div>
                                <div class="LMSBtn">
                                    <button
                                        v-clipboard:copy="API_URL + '/lti/config/' + course.consumerKeyLTI"
                                        v-clipboard:success="onCopyClipboard"
                                        v-clipboard:error="onErrorClipboard"
                                        class="btn btn-primary subBtn hidden-xs hidden-sm"
                                        aria-label="COPY CONFIGURATION URL"
                                    >
                                        <i class="fa fa-files-o marginRight8" aria-hidden="true" />Copy Link
                                    </button>
                                    <div class="hidden-md hidden-lg">
                                        <button
                                            v-clipboard:copy="API_URL + '/lti/config/' + course.consumerKeyLTI"
                                            v-clipboard:success="onCopyClipboard"
                                            v-clipboard:error="onErrorClipboard"
                                            v-tooltip="{ content: 'Copy Link' }"
                                            class="btn btn-primary mainBtn"
                                            aria-label="COPY CONFIGURATION URL"
                                        >
                                            <i class="fa fa-files-o" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="LMSRow">
                            <div class="LMSSubCon">
                                <div class="LMSContainer">
                                    <div class="LMSTitle">
                                        <label>LAUNCH URL</label>
                                        <span class="hidden-xs hidden-sm">:</span>
                                    </div>
                                    <div class="LMSContent w-auto">
                                        <p>{{ API_URL }}/lti/launch/{{ course.consumerKeyLTI }}</p>
                                    </div>
                                </div>
                                <div class="LMSBtn">
                                    <button
                                        v-clipboard:copy="API_URL + '/lti/launch/' + course.consumerKeyLTI"
                                        v-clipboard:success="onCopyClipboard"
                                        v-clipboard:error="onErrorClipboard"
                                        class="btn btn-primary subBtn hidden-xs hidden-sm"
                                        aria-label="copy LAUNCH URL"
                                    >
                                        <i class="fa fa-files-o marginRight8" aria-hidden="true" />Copy Link
                                    </button>
                                    <div class="hidden-md hidden-lg">
                                        <button
                                            v-clipboard:copy="API_URL + '/lti/launch/' + course.consumerKeyLTI"
                                            v-clipboard:success="onCopyClipboard"
                                            v-clipboard:error="onErrorClipboard"
                                            v-tooltip="{ content: 'Copy Link' }"
                                            class="btn btn-primary mainBtn"
                                            aria-label="copy LAUNCH URL"
                                        >
                                            <i class="fa fa-files-o" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="LMSRow">
                            <div class="LMSSubCon">
                                <div class="LMSContainer">
                                    <div class="LMSTitle">
                                        <label>CONSUMER KEY</label>
                                        <span class="hidden-xs hidden-sm">:</span>
                                    </div>
                                    <div class="LMSContent">
                                        <p>{{ course.consumerKeyLTI }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="LMSRow">
                            <div class="LMSSubCon">
                                <div class="LMSContainer">
                                    <div class="LMSTitle">
                                        <label>SHARED SECRET</label>
                                        <span class="hidden-xs hidden-sm">:</span>
                                    </div>
                                    <div class="LMSContent">
                                        <p>{{ course.sharedSecretLTI }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <template v-if="auth.user().canUseExpressSignIn">
                <div class="col-xs-12 enrollmentSetting">
                    <kr-toggle
                        :options="{ reverse: true, showLabel: false }"
                        name="isSelfSignIn"
                        :form="form"
                        class="marginTop5"
                        @changed="updateIsSelfSignIn"
                    />
                    <div class="paddingLeft20">
                        <label class="fs-24px marginBottom8">Enable Express Sign In</label>
                        <template v-if="form.model.isSelfSignIn">
                            <p>Students will be able to self-register for this course at the following URL:</p>
                            <div class="LMSRow">
                                <div class="LMSSubCon">
                                    <div class="LMSContainer">
                                        <div class="LMSTitle">
                                            <label>EXPRESS SIGN IN URL</label>
                                            <span class="hidden-xs hidden-sm">:</span>
                                        </div>
                                        <div class="LMSContent">
                                            <p>{{ AUTH_URL }}/express-sign-in/{{ course.urlCode }}</p>
                                        </div>
                                    </div>
                                    <div class="LMSBtn">
                                        <button
                                            v-clipboard:copy="AUTH_URL + '/express-sign-in/' + course.urlCode"
                                            v-clipboard:success="onCopyClipboard"
                                            v-clipboard:error="onErrorClipboard"
                                            class="btn btn-primary subBtn hidden-xs hidden-sm"
                                            aria-label="copy EXPRESS SIGN IN URL"
                                        >
                                            <i class="fa fa-files-o marginRight8" aria-hidden="true" />Copy Link
                                        </button>
                                        <div class="hidden-md hidden-lg">
                                            <button
                                                v-clipboard:copy="AUTH_URL + '/express-sign-in/' + course.urlCode"
                                                v-clipboard:success="onCopyClipboard"
                                                v-clipboard:error="onErrorClipboard"
                                                v-tooltip="{
                                                    content: 'Copy Link',
                                                }"
                                                class="btn btn-primary mainBtn"
                                                aria-label="copy EXPRESS SIGN IN URL"
                                            >
                                                <i class="fa fa-files-o" aria-hidden="true" />
                                            </button>
                                        </div>
                                        <button class="btn btn-primary subBtn" aria-label="Preview">Preview</button>
                                    </div>
                                </div>
                            </div>
                            <div class="LMSRow">
                                <div class="LMSSubCon">
                                    <div class="LMSContainer">
                                        <div class="LMSTitle">
                                            <span>SIGN IN QR CODE</span>
                                            <span class="hidden-xs hidden-sm">:</span>
                                        </div>
                                        <div class="flex LMSContent" style="margin-left: 5px">
                                            <vue-qrcode :value="AUTH_URL + '/express-sign-in/' + course.urlCode" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p class="marginTop8">
                                Students can alternatively sign in to the course from our Sign in Page by choosing "Express Sign in" and then entering the
                                following Course ID
                            </p>
                            <div class="LMSRow">
                                <div class="LMSSubCon">
                                    <div class="LMSContainer">
                                        <div class="LMSTitle">
                                            <label>COURSE ID</label>
                                            <span class="hidden-xs hidden-sm">:</span>
                                        </div>
                                        <div class="LMSContent">
                                            <p>
                                                {{ course.urlCode }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
            <div class="col-xs-12 enrollmentSetting">
                <kr-toggle
                    :options="{ reverse: true, showLabel: false }"
                    name="selfAssignmentEnabled"
                    :form="form"
                    class="marginTop5"
                    :disabled="!(form.model.isLtiEnabled || form.model.isSelfSignIn) ? true : undefined"
                    @changed="updateIsSelfAssigned"
                />
                <div class="paddingLeft20">
                    <label class="fs-24px marginBottom8">Allow students to self enroll into sections and teams</label>
                    <template v-if="!form.model.selfAssignmentEnabled">
                        <p>
                            This option is only available to students to sign in via LTI or Express Sign in. Teachers must explicitly assign sections and teams
                            to students enrolled via manual-mode or bulk CSV uploads.
                        </p>
                        <div><b>Allowed Sections</b></div>
                        <template v-for="section in course.sections" :key="section.uuid">
                            <div class="checkboxField flexOnly align-items">
                                <input
                                    :id="section.name"
                                    v-model="selectedSections"
                                    type="checkbox"
                                    :value="section.uuid"
                                    :disabled="selectedSections.length == 1 && isSectionIncluded(section.uuid) ? true : undefined"
                                    @change="updateIsSelfAssigned"
                                />
                                <label class="fs-18px" :for="section.name"> Section {{ section.name }} </label>
                            </div>
                        </template>
                    </template>
                </div>
            </div>
            <div class="col-xs-12 enrollmentSetting">
                <kr-toggle
                    :options="{ reverse: true, showLabel: false }"
                    name="selfEnrollmentEnabled"
                    :form="form"
                    class="marginTop5"
                    @changed="updateSelfEnrollment"
                />
                <div class="paddingLeft20">
                    <label class="fs-24px marginBottom8">Allow teachers to toggle enrollment at the course level</label>
                    <p>Allow students who sign in with LTI v1.3 to self enroll into this course</p>
                </div>
            </div>
            <template v-if="auth.user().canUseExpressSignIn">
                <div v-if="form.model.isSelfSignIn" class="col-xs-12 enrollmentSetting">
                    <kr-toggle
                        v-if="auth.user().account.paymentMethod != 'Student-Paid'"
                        :options="{
                            trueval: false,
                            falseval: true,
                            reverse: true,
                            showLabel: false,
                        }"
                        name="anonymous"
                        :form="form"
                        class="marginTop5"
                        @changed="updateOthers"
                    />
                    <template v-else>
                        <div class="marginRight10" style="width: 60px; padding-top: 8px; padding-left: 15px">
                            <b>Yes</b>
                        </div>
                    </template>
                    <div class="paddingLeft20">
                        <label class="fs-24px marginBottom8">Require students to set an email and password</label>
                        <p>
                            If this is turned off, students only need to enter their name/nickname to enter the course. Turn this off only if you are running
                            ad-hoc workshops and convenience of access is more important than security.
                            <a
                                href="https://help.intedashboard.com/how-do-i-enable-learners-to-self-register-with-an-email-and-password"
                                class="link"
                                target="newTab"
                                aria-label="click here on how do I enable students to self register with an email and password?"
                            >
                                Click here
                            </a>
                            for more information. <br /><br />
                            Enter allowed domains, separated by commas. If you leave this empty, all domains are accepted.
                        </p>
                        <textarea
                            v-model="form.model.allowedDomains"
                            placeholder="E.g. intedashboard.com, intelearner.com"
                            :disabled="form.model.anonymous ? true : undefined"
                            @blur="updateOthers"
                            @keyup="debouncedSubmit"
                            @paste="updateOthers"
                        />
                    </div>
                </div>

                <div v-if="form.model.isSelfSignIn && form.model.anonymous" class="col-xs-12 enrollmentSetting">
                    <kr-toggle
                        :options="{ reverse: true, showLabel: false }"
                        name="isSecretPin"
                        :form="form"
                        class="marginTop5"
                        @changed="form.model.isSecretPin == false ? updateOthers() : ''"
                    />
                    <div class="paddingLeft20">
                        <label>Students are required to enter a secret pin to register</label>
                        <p>Enter a minimum of 6 numeric characters below.</p>
                        <p
                            :class="{
                                red: form.model.secretPin != null && form.model.secretPin.length < 6,
                                green: form.model.secretPin != null && form.model.secretPin.length >= 6,
                            }"
                        >
                            {{ form.model.secretPin != null ? form.model.secretPin.length : 0 }}
                            character/s &nbsp; 6 characters required
                        </p>
                        <pin-code v-model="form.model.secretPin" :disabled="!form.model.isSecretPin ? true : undefined" @completed="updateOthers" />
                    </div>
                </div>

                <div v-if="form.model.isSelfSignIn && !form.model.anonymous" class="col-xs-12 enrollmentSetting">
                    <kr-toggle :options="{ reverse: true, showLabel: false }" name="ssoMicrosoft" :form="form" class="marginTop5" @changed="updateOthers" />
                    <div class="paddingLeft20">
                        <label class="fs-24px marginBottom8">Enable sign in with a Microsoft Teams Account</label>
                        <p>Enter allowed domains, separated by commas. If you leave this empty, all domains are accepted.</p>
                        <textarea
                            v-model="form.model.ssoMicrosoftAllowedDomains"
                            :disabled="!form.model.ssoMicrosoft ? true : undefined"
                            placeholder="E.g. intedashboard.com, intelearner.com"
                            @blur="updateOthers"
                            @keyup="debouncedSubmit"
                            @paste="updateOthers"
                        />
                    </div>
                </div>

                <div v-if="form.model.isSelfSignIn && !form.model.anonymous" class="col-xs-12 enrollmentSetting">
                    <kr-toggle :options="{ reverse: true, showLabel: false }" name="ssoGoogle" :form="form" class="marginTop5" @changed="updateOthers()" />
                    <div class="paddingLeft20">
                        <label class="fs-24px marginBottom8">Enable sign-in with a Google Classroom Account</label>
                        <p>Enter allowed domains, separated by commas. If you leave this empty, all domains are accepted.</p>
                        <textarea
                            v-model="form.model.ssoGoogleAllowedDomains"
                            :disabled="!form.model.ssoGoogle ? true : undefined"
                            placeholder="E.g. intedashboard.com, intelearner.com"
                            @blur="updateOthers"
                            @keyup="debouncedSubmit"
                            @paste="updateOthers"
                        />
                    </div>
                </div>
            </template>
        </template>
    </kr-panel>
</template>
<script>
import KrForm from '../../../../components/forms/form';
import KrAuth from '../../../../components/auth/auth';
export default {
    props: {
        course: {
            type: Object,
            required: true,
        },
    },
    data() {
        var auth = new KrAuth();
        let debouncedSubmit = _.debounce(this.updateOthers, 3000);
        return {
            API_URL: auth.credential().API_URL,
            APP_URL: process.env.VUE_APP_APP_URL,
            AUTH_URL: process.env.VUE_APP_AUTH_URL,
            auth: auth,
            mode: 'view',
            form: new KrForm({
                fields: {
                    isLtiEnabled: '',
                    anonymous: '',
                    isSelfSignIn: '',
                    allowedDomains: '',
                    isSecretPin: '',
                    secretPin: '',
                    ssoGoogle: '',
                    ssoGoogleAllowedDomains: '',
                    ssoMicrosoft: '',
                    ssoMicrosoftAllowedDomains: '',
                    selfAssignmentEnabled: '',
                    selfEnrollmentEnabled: '',
                },
            }),
            debouncedSubmit: debouncedSubmit,
            latestModelCopy: {},
            selectedSections: [],
        };
    },
    created() {
        this.form.setModel(this.course);
        /*Initialize selectedSections*/
        for (var i = 0; i < this.course.sections.length; i++) {
            let section = this.course.sections[i];
            let found = false;
            if (this.course.selfAssignmentBlacklist) {
                found = this.course.selfAssignmentBlacklist.find((element) => element == section.uuid);
            }
            if (!found) {
                this.selectedSections.push(section.uuid);
            }
        }
        this.form.model.isSecretPin = this.course.secretPin ? true : false;
        if (this.course.allowedDomains != null) {
            this.form.model.allowedDomains = this.form.model.allowedDomains.toString();
        }
        if (this.course.ssoGoogleAllowedDomains != null) {
            this.form.model.ssoGoogleAllowedDomains = this.form.model.ssoGoogleAllowedDomains.toString();
        }
        if (this.course.ssoMicrosoftAllowedDomains != null) {
            this.form.model.ssoMicrosoftAllowedDomains = this.form.model.ssoMicrosoftAllowedDomains.toString();
        }
    },
    methods: {
        isSectionIncluded(sectionUuid) {
            return this.selectedSections.includes(sectionUuid);
        },
        isNumber(evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        updateIsLtiEnabled() {
            var that = this;
            axios
                .post('/courses/' + this.course.uuid + '/lti', {
                    isLtiEnabled: this.form.model.isLtiEnabled,
                })
                .then(function (response) {
                    that.course.isLtiEnabled = that.form.model.isLtiEnabled;
                    if (!(that.form.model.isLtiEnabled || that.form.model.isSelfSignIn)) {
                        that.form.model.selfAssignmentEnabled = false;
                        that.updateIsSelfAssigned();
                    }
                })
                .catch(function (errors) {});
        },
        updateIsSelfAssigned() {
            var that = this;
            this.form.model.selfAssignmentBlacklist = [];
            if (!this.form.model.selfAssignmentEnabled) {
                this.selectedSections = this.course.sections.map((a) => a.uuid);
            }
            for (var i = 0; i < this.course.sections.length; i++) {
                let section = this.course.sections[i];
                let found = this.selectedSections.find((element) => element == section.uuid);
                if (!found) {
                    this.form.model.selfAssignmentBlacklist.push(section.uuid);
                }
            }
            axios
                .post('/courses/' + this.course.uuid + '/self-assigned', {
                    selfAssignmentEnabled: this.form.model.selfAssignmentEnabled,
                    selfAssignmentBlacklist: this.form.model.selfAssignmentBlacklist,
                })
                .then(function (response) {
                    that.course.selfAssignmentEnabled = that.form.model.selfAssignmentEnabled;
                })
                .catch(function (errors) {});
        },
        updateIsSelfSignIn() {
            var that = this;
            axios
                .post('/courses/' + this.course.uuid + '/express-sign-in', {
                    isSelfSignIn: this.form.model.isSelfSignIn,
                })
                .then(function (response) {
                    that.course.isSelfSignIn = that.form.model.isSelfSignIn;
                    if (!(that.form.model.isLtiEnabled || that.form.model.isSelfSignIn)) {
                        that.form.model.selfAssignmentEnabled = false;
                        that.updateIsSelfAssigned();
                    }
                })
                .catch(function (errors) {});
        },
        updateOthers() {
            var that = this;

            if (this.form.model.anonymous) {
                this.form.model.allowedDomains = '';
                this.form.model.ssoGoogle = false;
                this.form.model.ssoMicrosoft = false;
            }
            if (!this.form.model.ssoGoogle) {
                this.form.model.ssoGoogleAllowedDomains = '';
            }
            if (!this.form.model.ssoMicrosoft) {
                this.form.model.ssoMicrosoftAllowedDomains = '';
            }
            let allowedDomains = [];
            if (this.form.model.allowedDomains != null && this.form.model.allowedDomains.trim().length !== 0) {
                allowedDomains = this.form.model.allowedDomains.split(',');
            }
            let ssoGoogleAllowedDomains = [];
            if (this.form.model.ssoGoogleAllowedDomains != null && this.form.model.ssoGoogleAllowedDomains.trim().length !== 0) {
                ssoGoogleAllowedDomains = this.form.model.ssoGoogleAllowedDomains.split(',');
            }
            let ssoMicrosoftAllowedDomains = [];
            if (this.form.model.ssoMicrosoftAllowedDomains != null && this.form.model.ssoMicrosoftAllowedDomains.trim().length !== 0) {
                ssoMicrosoftAllowedDomains = this.form.model.ssoMicrosoftAllowedDomains.split(',');
            }

            if (!this.form.model.anonymous) {
                this.form.model.isSecretPin = false;
            }
            if (!this.form.model.isSecretPin) {
                this.form.model.secretPin = '';
            }
            if (_.isEqual(this.form.model, this.latestModelCopy)) {
                //console.log('same')
                return;
            } else {
                //console.log('not same')
                this.latestModelCopy = _.cloneDeep(this.form.model);
            }
            axios
                .patch('/courses/' + this.course.uuid + '/express-sign-in', {
                    anonymous: this.form.model.anonymous,
                    allowedDomains: allowedDomains,
                    secretPin: this.form.model.secretPin,
                    ssoGoogle: this.form.model.ssoGoogle,
                    ssoGoogleAllowedDomains: ssoGoogleAllowedDomains,
                    ssoMicrosoft: this.form.model.ssoMicrosoft,
                    ssoMicrosoftAllowedDomains: ssoMicrosoftAllowedDomains,
                })
                .then(function (response) {
                    that.course.anonymous = that.form.model.isSelfSignIn;
                    that.course.allowedDomains = that.form.model.allowedDomains;
                    that.course.secretPin = that.form.model.secretPin;
                    that.course.ssoGoogle = that.form.model.ssoGoogle;
                    that.course.ssoGoogleAllowedDomains = that.form.model.ssoGoogleAllowedDomains;
                    that.course.ssoMicrosoftAllowedDomains = that.form.model.ssoMicrosoftAllowedDomains;
                })
                .catch(function (errors) {});
        },
        updateSelfEnrollment() {
            var that = this;
            axios
                .post(`courses/${this.course.uuid}/self-enrollment`, {
                    selfEnrollmentEnabled: that.form.model.selfEnrollmentEnabled,
                })
                .catch(function (errors) {});
        },
        onCopyClipboard(e) {
            this.$notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Copied to clipboard',
            });
        },
        onErrorClipboard(e) {
            this.$notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Error copying to clipboard',
            });
        },
    },
};
</script>
<style scoped lang="scss">
.LMSRow {
    padding: 20px;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    border: 1px solid #d8d8d8;
    border-bottom: none;

    &:last-child {
        border-bottom: 1px solid #d8d8d8;
    }

    &:first-of-type {
        border: 1px solid #d8d8d8;
    }
}
.LMSTitle {
    font-weight: bold;
}

@media (min-width: 992px) {
    .LMSSubCon {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .LMSContainer {
        display: inline-flex;
        width: 73%;
    }

    .LMSTitle {
        // width: 150px;
        min-width: 9em;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }

    .LMSContent {
        margin-left: 20px;
        width: 22.5em;
        word-wrap: break-word;
    }

    .LMSBtn {
        width: 27%;
        text-align: right;
        padding-left: 10px;
    }
}

@media (max-width: 991px) {
    .LMSSubCon {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        position: relative;
    }

    .LMSContainer {
        width: 100%;
        overflow: hidden;
    }

    .LMSBtn {
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;
        background-image: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0.5));
        width: 100px;
        justify-content: flex-end;
        height: 100%;
    }

    .LMSBtn .hidden-md.hidden-lg {
        display: flex;
    }

    .LMSBtn .hidden-md.hidden-lg .mainBtn {
        margin-left: 0;
    }
}
.enrollmentSetting {
    // width: 75%;
    display: flex;
    align-items: flex-start;

    @media only screen and (max-width: 600px) {
        width: 100%;

        & > div:last-child {
            width: calc(100% - 70px);
        }
    }

    &:not(first-child) {
        margin-top: 20px;
    }

    p {
        margin-bottom: 8px;
    }

    .LMSTitle {
        label {
            // font-size: 14px;
            font-size: 0.875em;
            margin: 0;
        }
    }

    .LMSContent {
        p {
            margin: 0;
        }
    }
}

.w-auto {
    width: auto;
}
</style>
