<template>
    <div v-if="component_done_loading" id="account-create" class="col-xs-12 right-container">
        <div class="form-div">
            <kr-panel :with-footer="false">
                <template #title>
                    <div class="col-xs-6">EDIT ESTIMATED USAGE</div>
                    <div class="col-xs-6 flexRight">
                        <div class="hidden-xs hidden-sm">
                            <button
                                id="top-send-invitation-btn"
                                class="btn btn-success mainBtn"
                                :disabled="!canSend ? true : undefined"
                                aria-label="Update"
                                @click="submit"
                            >
                                <i class="fa fa-check marginRight8" aria-hidden="true"></i>Update
                            </button>
                            <!--<button id="top-preview-email-btn" class="btn btn-default mainBtn" :disabled="(!canSend) ? true:undefined" @click.prevent="openEmailSampleModal()">
				                Preview Email
				            </button>
				            <router-link id="top-back-btn" :to="{name:'accounts.index'}" tag="button" class="btn btn-default mainBtn" exact>
				            	Back
				            </router-link>-->
                        </div>
                        <div class="hidden-md hidden-lg">
                            <button
                                id="top-mobile-send-invitation-btn"
                                v-tooltip="{ content: 'Update' }"
                                class="btn btn-primary mainBtn"
                                :disabled="!canSend ? true : undefined"
                                aria-label="Update"
                                @click="submit"
                            >
                                <i class="fa fa-check" aria-hidden="true"></i>
                            </button>
                            <!--<button id="top-mobile-preview-email-btn" class="btn btn-default mainBtn" :disabled="(!canSend) ? true:undefined" @click.prevent="openEmailSampleModal()">
				                <i class="fa fa-eye"></i>
				            </button>
				             <router-link id="top-mobile-back-btn" :to="{name:'accounts.index'}" tag="button" class="btn btn-default mainBtn" exact>
				            	<i class="fa fa-caret-left"></i>
				            </router-link>-->
                        </div>
                    </div>
                </template>
                <template #content>
                    <div class="col-xs-12 col-md-6">
                        <usage-form :form="form" :account="account"></usage-form>
                        <div class="buttonGroup">
                            <button
                                id="bottom-send-invitation-btn"
                                class="btn btn-success mainBtn"
                                :disabled="!canSend ? true : undefined"
                                aria-label="Update"
                                @click="submit"
                            >
                                <i class="fa fa-check marginRight8" aria-hidden="true"></i>Update
                            </button>
                            <router-link
                                id="bottom-back-btn"
                                :to="{
                                    name: 'accounts.view',
                                    params: { id: $route.params.id },
                                }"
                                tag="button"
                                class="btn btn-default mainBtn"
                                exact
                                aria-label="Cancel"
                            >
                                Cancel
                            </router-link>
                        </div>
                    </div>
                </template>
                <!-- <template slot="footer">
			      <div>
			        <button :disabled="(!canSend) ? true:undefined" class="btn pull-right btn-success" type="submit"@click="submit"> Create </button>
			      </div>  
			    </template> -->
            </kr-panel>
        </div>
    </div>
</template>

<script>
import KrForm from '../../../../components/forms/form';
import KrAuth from '../../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            component_done_loading: false,
            form: new KrForm({
                fields: {
                    noOfFaculty: '',
                    noOfStudentLicences: '',
                    activityInterests: '',
                    usageType: 'default!:Online-Only',
                    expansionPotential: '',
                    courseStartDate: '',
                },
                props: {},
                urls: {
                    put: 'accounts/' + this.$route.params.id + '/estimated-usage',
                },
            }),
            account: {},
        };
    },
    created() {
        Events.fire('topbar_update', {
            title: '<i class="fas fa-id-card"></i>&nbsp;Accounts',
            breadcrumb: [{ name: 'Home' }, { name: 'Accounts', link: 'accounts.index' }],
        });
        this.fetchAccount();
    },
    components: {
        'usage-form': require(`./form-partials/partial.usage.vue`).default,
    },
    methods: {
        submit() {
            var that = this;
            this.form.put().then(function (response) {
                if (!response.krFormError) {
                    that.$router.push({
                        name: 'accounts.view',
                        params: { id: that.$route.params.id },
                    });
                }
            });
        },
        fetchAccount() {
            var that = this;
            axios.get('/accounts/' + this.$route.params.id).then(function (response) {
                that.account = response.data.data;
                Events.fire('topbar_update', {
                    breadcrumb: [
                        { name: 'Home' },
                        { name: 'Accounts', link: 'accounts.index' },
                        {
                            name: that.account.organisationName,
                            link: 'accounts.view',
                            params: { id: that.$route.params.id },
                        },
                        { name: 'Edit Estimated Usage' },
                    ],
                });
                that.form.setModel(that.account.estimatedUsage);
                that.component_done_loading = true;
            });
        },
    },
    computed: {
        canSend() {
            /*if(!this.form.model.quantity||!this.form.model.bookstoreName||!this.form.model.prefix||!this.form.model.validityPeriod||!this.form.model.validForRefundDate){
	    			return false;
	    		}*/
            return true;
        },
    },
};
</script>
