<template>
    <div class="testProgression">
        <div class="hidden-xs hidden-sm flex alignFlexStart">
            <h5 class="margin0 fontBlack marginRight20">
                <b>
                    <template v-if="testObj.type == 'trat' || (testObj.type == 'application' && testObj.others.applicationType == 'team')">
                        Your team has
                    </template>
                    <template v-else> You’ve </template> completed {{ percentage }}% of the test
                </b>
            </h5>

            <div class="bar">
                <div class="barProgress" :style="'width:' + percentage + '%'" />
            </div>
        </div>

        <div class="hidden-md hidden-lg" @click.prevent="openQuestionStatusModal">
            <button class="padding0 textLink marginBottom20">
                <template v-if="canClarify"> Clarifications & Assignments<i class="fas fa-caret-right marginLeft8" aria-hidden="true" /> </template>
                <template v-else-if="canDiscuss"> Question Discussions<i class="fas fa-caret-right marginLeft8" aria-hidden="true" /> </template>
                <template v-else> Question Status<i class="fas fa-caret-right marginLeft8" aria-hidden="true" /> </template>
            </button>

            <div class="col-xs-12 flexSpaceBetween marginBottom5 alignBaseline">
                <h5 class="margin0 fontBlack">
                    <b>
                        <template v-if="testObj.type == 'trat' || (testObj.type == 'application' && testObj.others.applicationType == 'team')">
                            Your team has
                        </template>
                        <template v-else> You’ve </template> completed {{ percentage }}% of the test
                    </b>
                </h5>
            </div>
            <div class="bar">
                <div class="barProgress" :style="'width:' + percentage + '%'" />
            </div>
        </div>
    </div>
</template>

<script>
import { testMixins } from './../../../../../mixins/test';
export default {
    mixins: [testMixins],
    computed: {
        canClarify() {
            if (this.testObj.type == 'trat' && this.isTestCompleted) {
                return true;
            }
            return false;
        },
        canDiscuss() {
            if (this.testObj.type == 'application' && this.isTestCompleted) {
                return true;
            }
            return false;
        },
    },
    methods: {
        openQuestionStatusModal() {
            Events.fire('open-test-sidebar-modal');
        },
    },
};
</script>
<style scoped lang="scss">
@media (max-width: 991px) {
    // .testProgression {
    //     h5 {
    //         &:last-child {
    //             font-weight: bold;
    //             color: #1991eb;
    //         }
    //     }
    // }
}
</style>
