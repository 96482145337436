<template>
    <div>
        <kr-panel :with-footer="false" :options="{ canToggle: false }">
            <template #title>
                <div class="col-xs-6">{{ testObj.activityStatus.finished }} / {{ testObj.activityStatus.entered }} Finished Teams</div>
            </template>
            <template #content>
                <kr-boxplot-chart :chartdata="boxplotData" :options="options"></kr-boxplot-chart>
            </template>
        </kr-panel>
    </div>
</template>
<script>
export default {
    props: ['testObj'],
    data() {
        const colors = ['#0a3ea6', '#187fd9', '#3dd1f2'];
        var that = this;
        return {
            boxplotData: {
                // define label tree
                labels: ['TRAT'],
                datasets: [
                    {
                        label: 'Mean',
                        data: [that.getMean()],
                        type: 'scatter',
                        borderColor: 'white',
                        fillColor: 'white',
                        fill: true,
                    },
                    {
                        barPercentage: 0.1,
                        categoryPercentage: 0.1,
                        barThickness: 50,
                        label: '',
                        backgroundColor: colors,
                        borderColor: colors,
                        medianColor: 'white',
                        borderWidth: 1,
                        outlierColor: colors,
                        padding: 30,
                        itemRadius: 0,
                        data: [_.get(this.testObj, 'teamAnalysis.teamFinishedQuestionPercentCorrect', [])],
                        type: 'boxplot',
                    },
                ],
            },
            options: {
                responsive: true,
                tooltips: {
                    callbacks: {
                        label: function (t, d) {
                            var xLabel = d.datasets[t.datasetIndex].label;
                            var yLabel = t.yLabel;
                            if (t.datasetIndex === 1) {
                                let data = d.datasets[t.datasetIndex].data[0];
                                //console.log(data);
                                return (
                                    xLabel +
                                    '(' +
                                    'Median: ' +
                                    Math.round(data.__stats.median, 1).toFixed(1) +
                                    '% ,' +
                                    'High: ' +
                                    Math.round(data.__stats.max, 1).toFixed(1) +
                                    '%,' +
                                    'Low: ' +
                                    Math.round(data.__stats.min, 1).toFixed(1) +
                                    '%,' +
                                    'Q1: ' +
                                    Math.round(data.__stats.q1, 1).toFixed(1) +
                                    '%,' +
                                    'Q3: ' +
                                    Math.round(data.__stats.q3, 1).toFixed(1) +
                                    '%)'
                                );
                            } else if (t.datasetIndex === 0) return 'Mean:' + Math.round(yLabel, 1).toFixed(1) + '%';
                        },
                    },
                },
                legend: {
                    display: false,
                },
                legendCallback: function (chart) {
                    var text = [];
                    text.push('<p>');
                    for (var i = 0; i < chart.data.datasets.length; i++) {
                        let data = chart.data.datasets[i].data[0];
                        if (i == 0) {
                            text.push('<b>Mean:</b> ' + Math.round(data, 1).toFixed(1) + '%');
                        } else {
                            let min = !isNaN(_.get(data, '__stats.min')) ? Math.round(data.__stats.min, 1) + '%' : 'N/A';
                            let q1 = !isNaN(_.get(data, '__stats.q1')) ? Math.round(data.__stats.q1, 1) + '%' : 'N/A';
                            let median = !isNaN(_.get(data, '__stats.median')) ? Math.round(data.__stats.median, 1) + '%' : 'N/A';
                            let q3 = !isNaN(_.get(data, '__stats.q3')) ? Math.round(data.__stats.q3, 1) + '%' : 'N/A';
                            let max = !isNaN(_.get(data, '__stats.max')) ? Math.round(data.__stats.max, 1) + '%' : 'N/A';
                            text.push('&nbsp;&nbsp;&nbsp;<b>Median:</b> ' + median);
                            text.push('&nbsp;&nbsp;&nbsp;<b>High:</b> ' + max);
                            text.push('&nbsp;&nbsp;&nbsp;<b>Low:</b> ' + min);
                            text.push('&nbsp;&nbsp;&nbsp;<b>Q1:</b> ' + q1);
                            text.push('&nbsp;&nbsp;&nbsp;<b>Q3:</b> ' + q3);
                        }
                    }
                    text.push('</p>');
                    return text.join('');
                },
                responsive: true,
                animation: {
                    onComplete: function () {
                        var chartInstance = this.chart,
                            ctx = chartInstance.ctx;
                        ctx.fillStyle = '#464646';
                        ctx.font = '400 10px Open Sans';
                        ctx.textAlign = 'center';
                        this.data.datasets.forEach(function (dataset, i) {
                            var meta = chartInstance.controller.getDatasetMeta(i);
                            meta.data.forEach(function (bar, index) {
                                if (i == 1) {
                                    var data = dataset.data[index];
                                    ctx.fillText(Math.round(data.__stats.median * 100) / 100 + ' %', bar._model.x + 50, bar._view.boxplot.median);
                                    if (data.__stats.max != data.__stats.q1) {
                                        ctx.fillText(Math.round(data.__stats.q1 * 100) / 100 + ' %', bar._model.x + 50, bar._view.boxplot.q1);
                                    }
                                    if (data.__stats.min != data.__stats.q3) {
                                        ctx.fillText(Math.round(data.__stats.q3 * 100) / 100 + ' %', bar._model.x + 50, bar._view.boxplot.q3);
                                    }
                                    ctx.fillText(Math.round(data.__stats.max * 100) / 100 + ' %', bar._model.x + 50, bar._view.boxplot.max);
                                    ctx.fillText(Math.round(data.__stats.min * 100) / 100 + ' %', bar._model.x + 50, bar._view.boxplot.min);
                                }
                            });
                        });
                    },
                },
                legend: {
                    display: false,
                },
                scales: {
                    yAxes: [
                        {
                            afterFit: function (scaleInstance) {
                                scaleInstance.textAlign = 'left';
                            },
                            gridLines: {
                                drawOnChartArea: false,
                                offsetGridLines: true,
                                drawBorder: true,
                                color: '#d8d8d8',
                            },
                            ticks: {
                                //beginAtZero:true,
                                fontSize: 11,
                                fontColor: '#222',
                                //min: 0,
                                max: 100,
                                callback: function (value) {
                                    return value + '%';
                                },
                                //callback: function(value, index, values) {
                                //return value
                                //}
                            },
                        },
                    ],
                    xAxes: [
                        {
                            display: true,
                            gridLines: {
                                drawOnChartArea: false,
                                offsetGridLines: true,
                                color: '#d8d8d8',
                                drawBorder: true,
                            },
                            ticks: {
                                color: '#222',
                            },
                        },
                    ],
                },
            },
        };
    },
    watch: {
        testObj: {
            handler: function () {
                this.boxplotData.datasets[0].data = [this.getMean()];
                this.boxplotData.datasets[1].data = [_.get(this.testObj, 'teamAnalysis.teamFinishedQuestionPercentCorrect', 0)];
                let boxplotData = _.clone(this.boxplotData);
                this.boxplotData = null;
                this.boxplotData = boxplotData;
            },
            deep: true,
        },
    },
    created() {},
    methods: {
        getMean() {
            let data = [_.get(this.testObj, 'teamAnalysis.teamFinishedQuestionPercentCorrect', 0)];
            let total = 0;
            let count = 0;
            for (var i = 0; i < data[0].length; i++) {
                if (data[0][i] != null) {
                    total += data[0][i];
                    count++;
                }
            }
            if (count > 0) {
                return total / count;
            } else {
                return null;
            }
        },
    },
};
</script>

<style></style>
