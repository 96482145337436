<template>
    <div v-if="component_done_loading" id="superuser-edit" class="col-xs-12 right-container">
        <div class="pageTitle">
            <h1>Global Settings</h1>
            <p class="col-md-6">
                <!--
        The settings page allows you to enable 2FA for students and teachers,
        and to select or update TRAT default point settings.
-->
                This screen allows only you, the Super Admin to change the global settings for your institution. Changes are saved automatically.
            </p>
        </div>
        <div class="form-div">
            <kr-panel :with-footer="false">
                <template #title>
                    <div class="col-xs-6">General Settings</div>

                    <!--<div class="col-xs-6 flexRight">
            <div class="hidden-xs hidden-sm">
              <button
                id="top-save-btn"
                class="btn btn-success mainBtn"
                :disabled="(!canSend) ? true:undefined"
                @click="submit()"
                aria-label="Update"
              >
                <i class="fa fa-check"></i>&nbsp;Update
              </button>
              <router-link id="top-back-btn" :to="{name:'superusers.index'}" tag="button" class="btn btn-default mainBtn" exact>
				            	Back
				            </router-link>
            </div>
            <div class="hidden-md hidden-lg">
              <button
                id="top-mobile-save-btn"
                class="btn btn-success mainBtn"
                :disabled="(!canSend) ? true:undefined"
                @click="submit()"
                aria-label="Update"
              >
                <i class="fa fa-check"></i>
              </button>
              <router-link id="top-mobile-back-btn"  :to="{name:'superusers.index'}" tag="button" class="btn btn-default mainBtn" exact>
				            	<i class="fa fa-caret-left"></i>
				            </router-link>
            </div>
          </div>-->
                </template>
                <template #content>
                    <div class="col-xs-12 col-md-6">
                        <settings-form :form="form" @instantUpdate="submit" @debouncedUpdate="debouncedSubmit" />
                        <!--<div class="buttonGroup marginTop30">
              <button
                id="bottom-save-btn"
                class="btn btn-success mainBtn"
                :disabled="(!canSend) ? true:undefined"
                @click="submit()"
                aria-label="Update"
              >
                <i class="fa fa-check"></i>&nbsp;Update
              </button>
              <router-link
                id="bottom-back-btn"
                :to="{ path: '/' }"
                tag="button"
                class="btn btn-default mainBtn"
                exact
                aria-label="Cancel"
              >
                Cancel
              </router-link>
            </div>-->
                    </div>
                </template>
            </kr-panel>

            <kr-panel :with-footer="false">
                <template #title>
                    <div class="col-xs-6">Messages</div>
                </template>
                <template #content>
                    <div class="col-xs-12 col-md-6">
                        <set-message-form :form="form" @instantUpdate="submit" @debouncedUpdate="debouncedSubmit" />
                    </div>
                </template>
            </kr-panel>

            <kr-panel :with-footer="false">
                <template #title>
                    <div class="col-xs-6">Security Settings</div>
                </template>
                <template #content>
                    <div class="col-xs-12 col-md-6">
                        <security-settings-form :form="form" @instantUpdate="submit" @debouncedUpdate="debouncedSubmit" />
                    </div>
                </template>
            </kr-panel>
            <kr-panel :with-footer="false">
                <template #title>
                    <div class="col-xs-6">Default IRAT Settings</div>
                </template>
                <template #content>
                    <div class="col-xs-12 col-md-6">
                        <irat-settings-form :form="form" @instantUpdate="submit" @debouncedUpdate="debouncedSubmit" />
                    </div>
                </template>
            </kr-panel>
            <kr-panel :with-footer="false">
                <template #title>
                    <div class="col-xs-6">Default TRAT Settings</div>
                </template>
                <template #content>
                    <div class="col-xs-12 col-md-6">
                        <trat-settings-form :form="form" @instantUpdate="submit" @debouncedUpdate="debouncedSubmit" />
                    </div>
                </template>
            </kr-panel>
            <kr-panel :with-footer="false">
                <template #title>
                    <div class="col-xs-6">Default Application Settings</div>
                </template>
                <template #content>
                    <div class="col-xs-12 col-md-6">
                        <application-settings-form :form="form" @instantUpdate="submit" @debouncedUpdate="debouncedSubmit" />
                    </div>
                </template>
            </kr-panel>
            <kr-panel :with-footer="false">
                <template #title>
                    <div class="col-xs-6">LTI v1.3 Settings</div>
                </template>
                <template #content>
                    <div class="col-xs-12 marginBottom30 ltiSetting">
                        <div class="LMSRow">
                            <div class="LMSSubCon">
                                <div class="LMSContainer">
                                    <div class="LMSTitle">
                                        <label>JSON URL</label>
                                        <span class="hidden-xs hidden-sm">:</span>
                                    </div>
                                    <div class="LMSContent w-auto">
                                        <p>{{ ltiConfig.config_url }}</p>
                                    </div>
                                </div>
                                <div class="LMSBtn">
                                    <button
                                        v-clipboard:copy="ltiConfig.config_url"
                                        v-clipboard:success="onCopyClipboard"
                                        v-clipboard:error="onErrorClipboard"
                                        class="btn btn-primary subBtn hidden-xs hidden-sm"
                                        aria-label="Copy Link"
                                    >
                                        <i class="fa fa-files-o marginRight8" aria-hidden="true" />Copy Link
                                    </button>
                                    <div class="hidden-md hidden-lg">
                                        <button
                                            v-clipboard:copy="ltiConfig.config_url"
                                            v-clipboard:success="onCopyClipboard"
                                            v-clipboard:error="onErrorClipboard"
                                            v-tooltip="{ content: 'Copy Link' }"
                                            class="btn btn-primary mainBtn"
                                            aria-label="Copy Link"
                                        >
                                            <i class="fa fa-files-o" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="LMSRow">
                            <div class="LMSSubCon">
                                <div class="LMSContainer">
                                    <div class="LMSTitle">
                                        <label>AUTHENTICATION URL</label>
                                        <span class="hidden-xs hidden-sm">:</span>
                                    </div>
                                    <div class="LMSContent w-auto">
                                        <p>
                                            {{ ltiConfig.authentication_url }}
                                        </p>
                                    </div>
                                </div>
                                <div class="LMSBtn">
                                    <button
                                        v-clipboard:copy="ltiConfig.authentication_url"
                                        v-clipboard:success="onCopyClipboard"
                                        v-clipboard:error="onErrorClipboard"
                                        class="btn btn-primary subBtn hidden-xs hidden-sm"
                                        aria-label="Copy Link"
                                    >
                                        <i class="fa fa-files-o marginRight8" aria-hidden="true" />Copy Link
                                    </button>
                                    <div class="hidden-md hidden-lg">
                                        <button
                                            v-clipboard:copy="ltiConfig.authentication_url"
                                            v-clipboard:success="onCopyClipboard"
                                            v-clipboard:error="onErrorClipboard"
                                            v-tooltip="{ content: 'Copy Link' }"
                                            class="btn btn-primary mainBtn"
                                            aria-label="Copy Link"
                                        >
                                            <i class="fa fa-files-o" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="LMSRow">
                            <div class="LMSSubCon">
                                <div class="LMSContainer">
                                    <div class="LMSTitle">
                                        <label>AUTHORIZATION URL</label>
                                        <span class="hidden-xs hidden-sm">:</span>
                                    </div>
                                    <div class="LMSContent w-auto">
                                        <p>{{ ltiConfig.authorization_url }}</p>
                                    </div>
                                </div>
                                <div class="LMSBtn">
                                    <button
                                        v-clipboard:copy="ltiConfig.authorization_url"
                                        v-clipboard:success="onCopyClipboard"
                                        v-clipboard:error="onErrorClipboard"
                                        class="btn btn-primary subBtn hidden-xs hidden-sm"
                                        aria-label="Copy Link"
                                    >
                                        <i class="fa fa-files-o marginRight8" aria-hidden="true" />Copy Link
                                    </button>
                                    <div class="hidden-md hidden-lg">
                                        <button
                                            v-clipboard:copy="ltiConfig.authorization_url"
                                            v-clipboard:success="onCopyClipboard"
                                            v-clipboard:error="onErrorClipboard"
                                            v-tooltip="{ content: 'Copy Link' }"
                                            class="btn btn-primary mainBtn"
                                            aria-label="Copy Link"
                                        >
                                            <i class="fa fa-files-o" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="LMSRow">
                            <div class="LMSSubCon">
                                <div class="LMSContainer">
                                    <div class="LMSTitle">
                                        <label>JSON WEBKEY URL</label>
                                        <span class="hidden-xs hidden-sm">:</span>
                                    </div>
                                    <div class="LMSContent w-auto">
                                        <p>{{ ltiConfig.json_webkey_url }}</p>
                                    </div>
                                </div>
                                <div class="LMSBtn">
                                    <button
                                        v-clipboard:copy="ltiConfig.json_webkey_url"
                                        v-clipboard:success="onCopyClipboard"
                                        v-clipboard:error="onErrorClipboard"
                                        class="btn btn-primary subBtn hidden-xs hidden-sm"
                                        aria-label="Copy Link"
                                    >
                                        <i class="fa fa-files-o marginRight8" aria-hidden="true" />Copy Link
                                    </button>
                                    <div class="hidden-md hidden-lg">
                                        <button
                                            v-clipboard:copy="ltiConfig.json_webkey_url"
                                            v-clipboard:success="onCopyClipboard"
                                            v-clipboard:error="onErrorClipboard"
                                            v-tooltip="{ content: 'Copy Link' }"
                                            class="btn btn-primary mainBtn"
                                            aria-label="Copy Link"
                                        >
                                            <i class="fa fa-files-o" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="LMSRow">
                            <div class="LMSSubCon">
                                <div class="LMSContainer">
                                    <div class="LMSTitle">
                                        <label>LAUNCH URL</label>
                                        <span class="hidden-xs hidden-sm">:</span>
                                    </div>
                                    <div class="LMSContent w-auto">
                                        <p>{{ ltiConfig.launch_url }}</p>
                                    </div>
                                </div>
                                <div class="LMSBtn">
                                    <button
                                        v-clipboard:copy="ltiConfig.launch_url"
                                        v-clipboard:success="onCopyClipboard"
                                        v-clipboard:error="onErrorClipboard"
                                        class="btn btn-primary subBtn hidden-xs hidden-sm"
                                        aria-label="Copy Link"
                                    >
                                        <i class="fa fa-files-o marginRight8" aria-hidden="true" />Copy Link
                                    </button>
                                    <div class="hidden-md hidden-lg">
                                        <button
                                            v-clipboard:copy="ltiConfig.launch_url"
                                            v-clipboard:success="onCopyClipboard"
                                            v-clipboard:error="onErrorClipboard"
                                            v-tooltip="{ content: 'Copy Link' }"
                                            class="btn btn-primary mainBtn"
                                            aria-label="Copy Link"
                                        >
                                            <i class="fa fa-files-o" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-12">
                        <kr-search
                            name="lti_settings_listing"
                            :url="{ url: '/platforms' }"
                            :columns="{
                                record_name: 'display:PLATFORM NAME|sortable:false|width:15%',
                                issuer_id: 'display:PLATFORM ID|sortable:false|width:25%|mobile:hide',
                                client_id: 'display:CLIENT ID|sortable:false|width:25%|mobile:hide',
                                /*deployment_id:
                  'display:DEPLOYMENT ID|sortable:false|width:25%|mobile:hide',*/
                                action: 'display:|sortable:false|width:132px',
                                /*authorization_url :'display:AUTHORIZATION URL|sortable:false|width:15%|mobile:hide',
                  authentication_url :'display:AUTHENTICATION URL|sortable:false|width:15%|mobile:hide',
                  json_webkey_url :'display:JSON WEBKEY URL|sortable:false|width:15%|mobile:hide',
                  signature_method :'display:SIGNATURE METHOD|sortable:false|width:15%|mobile:hide',*/
                            }"
                            :options="{
                                search_field: false,
                                lti3_setting: true,
                                action_column: false,
                            }"
                        >
                            <template #upper-right>
                                <div class="hidden-xs hidden-sm">
                                    <router-link id="top-add-lti-btn" :to="{ name: 'settings.lti.create' }" tag="button" class="btn btn-primary mainBtn">
                                        <i class="fa fa-plus marginRight8" aria-hidden="true" />New Platform
                                    </router-link>
                                </div>
                                <div class="hidden-md hidden-lg">
                                    <router-link
                                        id="top-mobile-add-lti-btn"
                                        :to="{ name: 'settings.lti.create' }"
                                        tag="button"
                                        class="btn btn-primary mainBtn"
                                        aria-label="New Platform"
                                    >
                                        <i class="fa fa-plus" aria-hidden="true" />
                                    </router-link>
                                </div>
                            </template>

                            <template #cdata="props">
                                <td>
                                    <a class="link" @click.prevent="openPlatformInfoModal(props.model)">{{ props.model.record_name }}</a>
                                </td>
                                <td class="hidden-xs hidden-sm">
                                    {{ props.model.issuer_id }}
                                </td>
                                <td class="hidden-xs hidden-sm">
                                    {{ props.model.client_id }}
                                </td>
                                <!--<td class="hidden-xs hidden-sm">
                  {{ props.model.deployment_id }}
                </td>-->
                                <!--<td class="hidden-xs hidden-sm">{{props.model.authorization_url}}</td>
                <td class="hidden-xs hidden-sm">{{props.model.authentication_url}}</td>
                <td class="hidden-xs hidden-sm">{{props.model.json_webkey_url}}</td>
                <td class="hidden-xs hidden-sm">{{props.model.signature_method}}</td>-->
                                <td class="py-9px">
                                    <div class="dropup">
                                        <button
                                            id="viewDropdownMenuButton"
                                            class="btn floatRight"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            Actions<i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                                        </button>

                                        <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                            <ul>
                                                <li>
                                                    <router-link
                                                        v-tooltip="'Edit'"
                                                        class="primary-state"
                                                        :to="{
                                                            name: 'settings.lti.edit',
                                                            params: {
                                                                id: props.model.uuid,
                                                            },
                                                        }"
                                                        aria-label="Edit"
                                                    >
                                                        <span> <i class="fa fa-pencil-square-o marginRight8" aria-hidden="true" />Edit </span>

                                                        <!-- <span>⇧⌘D</span> -->
                                                    </router-link>
                                                </li>

                                                <hr />

                                                <li>
                                                    <a class="danger-state" href="#" aria-label="Delete" @click.prevent="deleteLtiModal(props.model)">
                                                        <span> <i class="fas fa-trash-alt marginRight8" aria-hidden="true" />Delete </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>

                                        <!-- <div
                      class="dropdown-menu dropdown-menu-right"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <router-link
                        id="lti-edit-btn"
                        v-tooltip="{
                          content: 'Edit',
                        }"
                        :to="{
                          name: 'settings.lti.edit',
                          params: { id: props.model.uuid },
                        }"
                        class="btn btn-default subBtn"
                        tag="button"
                      >
                        Edit&nbsp;<i class="fa fa-pencil-square-o"></i>
                      </router-link>
                      <button
                        class="btn btn-danger"
                        @click.prevent="deleteLtiModal(props.model)"
                        aria-label="Delete"
                      >
                        Delete&nbsp;<i class="fas fa-trash-alt"></i>
                      </button>
                    </div> -->
                                    </div>
                                </td>
                            </template>
                        </kr-search>
                    </div>
                    <div class="col-xs-12 col-md-6 marginTop30">
                        <div>
                            <div class="col-xs-12">
                                <label> Choose which courses students who sign in with LTI v1.3 can enroll into respectively </label>
                            </div>
                            <table class="table tableBodyFixedHeight-lti-course-list">
                                <thead>
                                    <tr>
                                        <th class="selectAllFixedWidth">
                                            <div v-if="courses.length != 0" class="checkboxField justifyCenter align-items">
                                                <input id="selectAllStudents" type="checkbox" :checked="isAllToggled" @click="toggleAll($event)" />
                                                <label for="selectAllStudents" class="paddingTop2"> Select All </label>
                                            </div>
                                        </th>
                                        <th>
                                            <label class="paddingTop2"> Courses </label>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="course in courses">
                                        <tr>
                                            <td class="selectAllFixedWidth">
                                                <div class="checkboxField justifyCenter">
                                                    <input v-model="selectedCourses" type="checkbox" :value="course.uuid" />
                                                </div>
                                            </td>
                                            <td>
                                                <p class="word-wrap-break-word">
                                                    {{ course.name }}
                                                </p>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-if="courses.length == 0">
                                        <tr>
                                            <td colspan="2" style="text-align: center">
                                                <b>No courses to add</b>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <div class="buttonGroup marginTop30">
                            <button
                                class="btn btn-success mainBtn"
                                :disabled="isEqual(selectedCourses, oldSelectedCourses) ? true : undefined"
                                aria-label="Confirm"
                                @click="updateCourseSelfEnrollment"
                            >
                                <i class="fa fa-check marginRight8" aria-hidden="true" />Update
                            </button>
                        </div>
                    </div>
                </template>
            </kr-panel>
        </div>
        <div id="delete-lti-modal" class="modal" style="z-index: 1000000" tabindex="-1" role="dialog" aria-labelledby="deleteLtiModal-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="deleteLtiModal-title" class="modal-title">Delete LTI Setting</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 marginBottom15 textAlignCenter">
                            <div class="fontBold">You are about to delete this lti setting</div>
                        </div>
                        <div class="col-xs-12 col-md-6 col-md-offset-3 textAlignCenter">
                            <div>Are you sure?</div>
                        </div>

                        <div class="flex marginTop30">
                            <button class="btn btn-danger mainBtn" data-dismiss="modal" aria-label="Confirm" @click="deleteLti">
                                <i class="fas fa-check marginRight8" aria-hidden="true" />Confirm
                            </button>
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="platformInfoModal" class="modal" data-backdrop="static" role="dialog">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 class="modal-title">
                            {{ platform.record_name }}
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div class="create col-xs-12">
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label"> Platform Name </label>
                                        <p class="form-control-static">
                                            {{ platform.record_name }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label"> PLATFORM ID </label>
                                        <p class="form-control-static">
                                            {{ platform.issuer_id }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label"> Client ID </label>
                                        <p class="form-control-static">
                                            {{ platform.client_id }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label"> Deployment ID </label>
                                        <p class="form-control-static">
                                            {{ platform.deployment_id }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label"> AUTHORIZATION URL </label>
                                        <p class="form-control-static">
                                            {{ platform.authorization_url }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label"> AUTHENTICATION URL </label>
                                        <p class="form-control-static">
                                            {{ platform.authentication_url }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 flex">
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label"> JSON WEBKEY URL </label>
                                        <p class="form-control-static">
                                            {{ platform.json_webkey_url }}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <div class="form-group">
                                        <label class="control-label"> SIGNATURE METHOD </label>
                                        <p class="form-control-static">
                                            {{ platform.signature_method }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 flex hidden-xs hidden-sm">
                                <div class="col-xs-12 col-md-6">
                                    <router-link
                                        v-if="platform.uuid"
                                        tag="button"
                                        :to="{
                                            name: 'settings.lti.edit',
                                            params: { id: platform.uuid },
                                        }"
                                        class="col-xs-12 btn btn-default mainBtn"
                                    >
                                        <i class="fa fa-edit marginRight8" aria-hidden="true" />Edit
                                    </router-link>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <button class="col-xs-12 btn btn-danger mainBtn" aria-label="Delete" @click.prevent="deleteLtiModal(platform)">
                                        Delete<i class="fas fa-trash-alt marginLeft8" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                            <div class="hidden-md hidden-lg">
                                <div>
                                    <router-link
                                        v-if="platform.uuid"
                                        :to="{
                                            name: 'settings.lti.edit',
                                            params: { id: platform.uuid },
                                        }"
                                        class="col-xs-12 btn btn-default mainBtn"
                                    >
                                        <i class="fa fa-edit marginRight8" aria-hidden="true" />Edit
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import KrForm from '../../../components/forms/form';
import KrAuth from '../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        let debouncedSubmit = _.debounce(this.submit, 3000);
        return {
            auth: auth,
            component_done_loading: false,
            superuser: {},
            form: new KrForm({
                fields: {
                    settings: '',
                    coverImage: '',
                },
                props: {},
                urls: {
                    put: `/accounts/${auth.user().account.uuid}/settings`,
                },
            }),
            latestModelCopy: {},
            debouncedSubmit,
            ltiToDelete: {},
            API_URL: process.env.VUE_APP_API_URL,
            ltiConfig: {},
            platform: {},
            courses: {},
            oldSelectedCourses: [],
            selectedCourses: [],
        };
    },
    components: {
        'settings-form': require(`./form-partials/partial.settings.vue`).default,
        'irat-settings-form': require(`./form-partials/partial.irat-settings.vue`).default,
        'trat-settings-form': require(`./form-partials/partial.trat-settings.vue`).default,
        'application-settings-form': require(`./form-partials/partial.application-settings.vue`).default,
        'security-settings-form': require(`./form-partials/partial.security.settings.vue`).default,
        'set-message-form': require(`./form-partials/partial.messages.settings.vue`).default,
    },
    computed: {
        canSend() {
            return true;
        },
        isAllToggled() {
            return this.selectedCourses.length == this.courses.length && this.selectedCourses.length != 0;
        },
    },
    created() {
        Events.fire('topbar_update', {
            title: 'Settings',
            breadcrumb: [{ name: 'Home' }, { name: 'Global Settings' }],
        });
        this.form.model.settings = {
            mfaForTeachers: false,
            mfaForStudents: false,
            maxFailedSignInAttempts: 3,
            allowGenericUsers: false,
            enableLti: false,
            defaultIratSettings: {
                durationDays: 0,
                durationHours: 0,
                durationMinutes: 20,
            },
            defaultTratSettings: {
                points: [5, 3, 1, 0],
                durationDays: 0,
                durationHours: 0,
                durationMinutes: 20,
            },
            defaultApplicationSettings: {
                durationDays: 0,
                durationHours: 0,
                durationMinutes: 20,
            },
            defaultMessagesSettings: {
                message: 'Please be patient. Your activities will appear once your instructors have published them.',
            },
        };
        this.fetch();
    },
    methods: {
        fetch() {
            var that = this;
            axios.all([axios.get(`/accounts/${that.auth.user().account.uuid}`), axios.get('/lti/1p3/settings'), axios.get('/courses/self-enrollment')]).then(
                axios.spread((account, config, courses) => {
                    if (account.data.data.settings) {
                        if (!account.data.data.settings.hasOwnProperty('defaultIratSettings')) {
                            account.data.data.settings.defaultIratSettings = {
                                durationDays: 0,
                                durationHours: 0,
                                durationMinutes: 20,
                            };
                        }
                        if (!account.data.data.settings.hasOwnProperty('defaultTratSettings') || _.isArray(account.data.data.settings.defaultTratSettings)) {
                            let points = [5, 3, 1, 0];
                            if (_.isArray(account.data.data.settings.defaultTratSettings)) {
                                points = account.data.data.settings.defaultTratSettings;
                            }
                            account.data.data.settings.defaultTratSettings = {
                                points: points,
                                durationDays: 0,
                                durationHours: 0,
                                durationMinutes: 20,
                            };
                        }
                        if (!account.data.data.settings.hasOwnProperty('defaultApplicationSettings')) {
                            account.data.data.settings.defaultApplicationSettings = {
                                durationDays: 0,
                                durationHours: 0,
                                durationMinutes: 20,
                            };
                        }
                        if (!account.data.data.settings.hasOwnProperty('defaultMessagesSettings')) {
                            account.data.data.settings.defaultMessagesSettings = {
                                message: 'Please be patient. Your activities will appear once your instructors have published them.',
                            };
                        }
                        that.form.setModel(account.data.data);
                    }
                    that.ltiConfig = config.data.data;
                    that.courses = courses.data.data;
                    for (var i = 0; i < that.courses.length; i++) {
                        if (that.courses[i].selfEnrollmentEnabled) {
                            that.selectedCourses.push(that.courses[i].uuid);
                        }
                    }
                    that.oldSelectedCourses = _.cloneDeep(that.selectedCourses);
                    that.component_done_loading = true;
                })
            );
        },
        isEqual(arr1, arr2) {
            return _.isEqual(arr1, arr2);
        },
        toggleAll() {
            if (this.isAllToggled) {
                this.selectedCourses = [];
            } else {
                this.selectedCourses = [];
                for (var i = 0; i < this.courses.length; i++) {
                    this.selectedCourses.push(this.courses[i].uuid);
                }
            }
        },
        updateCourseSelfEnrollment() {
            var that = this;
            axios
                .post(`courses/self-enrollment`, {
                    allowCourseSelfEnrollmentUuidList: that.selectedCourses,
                })
                .then(function () {
                    that.oldSelectedCourses = _.cloneDeep(that.selectedCourses);
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Allowed courses have been updated',
                    });
                })
                .catch(function (errors) {});
        },
        deleteLtiModal(model) {
            this.ltiToDelete = model;
            $('#delete-lti-modal').modal('show');
        },
        deleteLti() {
            var that = this;
            axios
                .delete(`/platforms/${this.ltiToDelete.uuid}`)
                .then(function (response) {
                    Events.fire('lti_settings_listing_refresh');
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'LTI setting has been deleted',
                    });
                })
                .catch(function () {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Something went wrong, please try again later ',
                    });
                });
            $('#platformInfoModal').modal('hide');
        },
        submit() {
            if (_.isEqual(this.form.model, this.latestModelCopy)) {
                //console.log('same')
                //alert('equal!')
                return;
            } else {
                this.latestModelCopy = _.cloneDeep(this.form.model);
            }
            this.form.put().then(function (response) {
                if (!response.krFormError) {
                }
            });
        },
        onCopyClipboard(e) {
            this.$notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Copied to clipboard',
            });
        },
        onErrorClipboard(e) {
            this.$notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Error copying to clipboard',
            });
        },
        openPlatformInfoModal(platform) {
            this.platform = platform;
            $('#platformInfoModal').modal('show');
        },
    },
};
</script>
<style scoped lang="scss">
.enrollmentSetting {
    // width: 75%;
    display: flex;
    align-items: flex-start;

    @media only screen and (max-width: 600px) {
        width: 100%;

        & > div:last-child {
            width: calc(100% - 70px);
        }
    }

    &:not(first-child) {
        margin-top: 20px;
    }

    label {
        font-size: 24px;
        margin-bottom: 8px;
    }

    p {
        margin-bottom: 8px;
    }

    .LMSTitle {
        label {
            // font-size: 14px;
            font-size: 0.875em;
            margin: 0;
        }
    }

    .LMSContent {
        p {
            margin: 0;
        }
    }
}
.LMSRow {
    padding: 20px;
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
    border: 1px solid #d8d8d8;
    border-bottom: none;

    &:last-child {
        border-bottom: 1px solid #d8d8d8;
    }
}

.LMSTitle {
    font-weight: bold;
}

@media (min-width: 992px) {
    .LMSSubCon {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .LMSContainer {
        display: inline-flex;
        width: 73%;
    }

    .LMSTitle {
        // width: 150px;
        min-width: 9em;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }

    .LMSContent {
        margin-left: 20px;
        width: 22.5em;
        word-wrap: break-word;
    }

    .LMSBtn {
        width: 27%;
        text-align: right;
        padding-left: 10px;
    }
}

@media (max-width: 991px) {
    .LMSSubCon {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        position: relative;
    }

    .LMSContainer {
        width: 100%;
        overflow: hidden;
    }

    .LMSBtn {
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;
        background-image: linear-gradient(to left, #ffffff, rgba(255, 255, 255, 0.5));
        width: 100px;
        justify-content: flex-end;
        height: 100%;
    }

    .LMSBtn .hidden-md.hidden-lg {
        display: flex;
    }

    .LMSBtn .hidden-md.hidden-lg .mainBtn {
        margin-left: 0;
    }
}
.ltiSetting {
    @media only screen and (max-width: 600px) {
        width: 100%;

        & > div:last-child {
            width: calc(100% - 70px);
        }
    }

    label {
        font-size: 24px;
        margin-bottom: 8px;
    }

    p {
        margin-bottom: 8px;
    }

    .LMSTitle {
        label {
            // font-size: 14px;
            font-size: 0.875em;
            margin: 0;
        }
    }

    .LMSContent {
        p {
            margin: 0;
        }
    }
}

.w-auto {
    width: auto;
}

.dropdown.open #viewDropdownMenuButton {
    margin-bottom: 0;
}

.setting .dropdown-menu {
    top: inherit;
}
.dropup .dropdown-menu {
    margin-bottom: 10px;
}
</style>

<style>
#superuser-edit .table-responsive {
    overflow-x: inherit;
}
</style>
