<template>
    <!--PUT HTML HERE-->
    <div>
        <div ref="infoBox" class="col-xs-12 right-container course-table-responsive">
            <div class="pageTitle">
                <h1>Courses</h1>
                <p class="col-md-6">
                    This is the Course Listing page that contains all courses that you have created and collaborate with. You can filter the list between
                    "Active" or "
                    <a href="https://help.intedashboard.com/how-do-i-archive-a-course" target="newTab">
                        <i class="fas fa-info-circle" aria-hidden="true" />
                        Archived Course
                    </a>
                    " from the dropdown on the top-right corner of the page.
                    <a href="https://help.intedashboard.com/how-do-i-create-a-course" target="newTab">
                        <i class="fas fa-info-circle" aria-hidden="true" />
                        Create and manage courses.
                    </a>
                </p>
            </div>

            <kr-search
                name="courses_listing"
                :url="{ url: '/courses' }"
                :columns="{
                    name: 'display:NAME|sortable:true|width:calc(100% - 142px - 142px - 142px - 142px - 165px - 148px)',
                    code: 'display:CODE|sortable:true|mobile:hide|width:142px',
                    startsFrom: 'display:STARTS FROM|sortable:true|src:startDate|width:142px|mobile:hide',
                    endsOn: 'display:ENDS ON |sortable:true|src:endDate|width:142px|mobile:hide',
                    owner: 'display:OWNER|sortable:false|mobile:hide|width:142px',
                    myRole: 'display:MY ROLE|sortable:false|mobile:hide|width:165px',
                    action: 'display:|sortable:false|width:148px',
                    //dateCreated:'display:CREATED ON|sortable:false|width:15%|mobile:hide',
                    //admin_teacher:'display:Admin Teacher|sortable:false|width:10%',
                }"
                :options="{
                    archived_field: true,
                    drop_page_number: false,
                    action_column: false,
                    sort: 'startDate',
                    order: 'desc',
                    pageName: 'Courses',
                }"
                @retrieveData="getSearchData"
            >
                <template #upper-right>
                    <router-link
                        tag="button"
                        :to="{ name: 'courses.create' }"
                        class="hidden-xs hidden-sm btn btn-success mainBtn"
                        :disabled="auth.isImpersonation() ? true : undefined"
                        aria-label="Add New Course"
                    >
                        <i class="fa fa-plus marginRight8" aria-hidden="true" />New Course
                    </router-link>
                    <router-link
                        tag="button"
                        :to="{ name: 'courses.create' }"
                        class="hidden-md hidden-lg btn btn-success mainBtn"
                        :disabled="auth.isImpersonation() ? true : undefined"
                        aria-label="Add New Course"
                    >
                        <i class="fa fa-plus marginRight8" aria-hidden="true" />Course
                    </router-link>
                </template>
                <!-- <template slot="top-display-from-to" slot-scope="props">
          <div class="displayNo">
            {{ props.model.from }} - {{ props.model.to }} of
            {{ props.model.meta.paginator.total }}
          </div>
        </template> -->
                <template #table-title="props">
                    <div>
                        <!-- <template v-if="searchData.query.isArchived == '0'">
              Active
            </template>
            <template v-if="searchData.query.isArchived == '1'">
              Archived
            </template>
            <template v-if="searchData.query.isArchived == '*'">All</template>
            Courses -->
                        <template v-if="props.model.meta.paginator.total > 0">
                            Showing {{ props.model.from }} - {{ props.model.to }} of
                            {{ props.model.meta.paginator.total }}
                        </template>
                    </div>
                </template>

                <template #bottom-display-from-to="props">
                    <div v-if="props.model.meta.paginator.total > 0" class="displayNo">
                        Showing {{ props.model.from }} - {{ props.model.to }} of
                        {{ props.model.meta.paginator.total }}
                    </div>
                </template>

                <template #ndata>
                    <tr>
                        <td class="borderNone">
                            No courses yet,
                            <router-link
                                v-if="!auth.isImpersonation()"
                                :to="{ name: 'courses.create' }"
                                tag="a"
                                class="link"
                                aria-labelledby="Create a course"
                                aria-label="Click here to create one"
                            >
                                click here
                            </router-link>
                            to create one.
                        </td>
                    </tr>
                </template>
                <template #cdata="props">
                    <td>
                        <!-- <template>
                            <i class="fa fa-star star"></i>
                        </template>
                        <template>
                            <i class="fa fa-star starred"></i>
                        </template> -->
                        <router-link
                            v-tooltip="{ content: 'View' }"
                            tag="a"
                            :to="{
                                name: 'courses.view',
                                params: { id: props.model.course.uuid },
                            }"
                            class="link"
                            :aria-label="
                                'course name ' +
                                props.model.firstname +
                                (props.model.lastname ? ' ' + props.model.lastname : '' + ' click to view modules and activities page')
                            "
                            v-html="highlight(props.model.course.name)"
                        />
                    </td>
                    <td class="hidden-xs hidden-sm">
                        <router-link
                            v-if="props.model.course.code"
                            v-tooltip="{ content: 'View' }"
                            tag="a"
                            :to="{
                                name: 'courses.view',
                                params: { id: props.model.course.uuid },
                            }"
                            class="link"
                            :aria-label="'course code ' + props.model.course.code"
                            v-html="highlight(props.model.course.code)"
                        />
                    </td>
                    <td class="hidden-xs hidden-sm" :aria-label="`starts from ${convertToReadableDate(props.model.course.startDate, 'DD MMM YYYY').date}`">
                        {{ convertToReadableDate(props.model.course.startDate, 'DD MMM YYYY').date }}
                        <!-- ({{
              convertToReadableDate(props.model.course.startDate, "DD MMM YYYY")
                .current_timezone
            }}) -->
                    </td>
                    <td class="hidden-xs hidden-sm" :aria-label="`ends on ${convertToReadableDate(props.model.course.endDate, 'DD MMM YYYY').date}`">
                        {{ convertToReadableDate(props.model.course.endDate, 'DD MMM YYYY').date }}
                        <!-- ({{
              convertToReadableDate(props.model.course.endDate, "DD MMM YYYY")
                .current_timezone
            }}) -->
                    </td>
                    <td
                        v-if="props.model.course.owner"
                        class="hidden-xs hidden-sm whiteSpaceNowrap"
                        :aria-label="'owner ' + props.model.course.owner"
                        v-html="highlight(props.model.course.owner.length > 30 ? truncate(props.model.course.owner, 28) : props.model.course.owner)"
                    />
                    <td v-else class="hidden-xs hidden-sm whiteSpaceNowrap" :aria-label="'owner'" />
                    <td class="hidden-xs hidden-sm" :aria-label="`my role ${getPrivilege(props.model)}`">
                        <template v-if="getPrivilege(props.model) == 'Owner'">
                            <i class="fa fa-circle ownerCircle marginRight8" aria-hidden="true" />Course Owner
                        </template>
                        <template v-if="getPrivilege(props.model) == 'Full Access'">
                            <i class="fa fa-circle fullAccessCircle marginRight8" aria-hidden="true" />Full Access
                        </template>
                        <template v-if="getPrivilege(props.model) == 'Read Only'">
                            <i class="fa fa-circle readOnlyCircle marginRight8" aria-hidden="true" />Read Only
                        </template>
                    </td>
                    <!--<td class="hidden-xs hidden-sm">{{convertToReadableDate(props.model.course.dateCreated,'D MMM YYYY').date}}</td>-->
                    <!-- <td>{{props.model.accountAdmins[0].displayName}}</td> -->
                    <td class="py-9px">
                        <div class="dropdown">
                            <button
                                id="viewDropdownMenuButton"
                                class="btn drop-down"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                aria-label="Actions"
                                @click="dropdownToggle"
                            >
                                Actions<i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                            </button>
                            <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                <ul>
                                    <li>
                                        <router-link
                                            class="primary-state"
                                            :to="{
                                                name: 'courses.view',
                                                params: {
                                                    id: props.model.course.uuid,
                                                },
                                            }"
                                            :aria-labelledby="'View ' + props.model.firstname + (props.model.lastname ? ' ' + props.model.lastname : '')"
                                            aria-label="View Course"
                                        >
                                            <span> <i class="fas fa-eye marginRight8" aria-hidden="true" />View Course </span>

                                            <!-- <span>⇧⌘D</span> -->
                                        </router-link>
                                    </li>
                                    <li>
                                        <a
                                            v-tooltip="{
                                                content: hasWritePrivilege(props.model) == -1 ? readOnlyLabel : 'Export Grades',
                                            }"
                                            class="primary-state"
                                            :class="{
                                                disabled: hasWritePrivilege(props.model) == -1,
                                            }"
                                            href="#"
                                            :aria-labelledby="
                                                'Download ' + props.model.firstname + (props.model.lastname ? ' ' + props.model.lastname : '') + 'Report'
                                            "
                                            aria-label="Generate Course Grades Report"
                                            @click="hasWritePrivilege(props.model) == -1 ? '' : openEmailGradesConfirmationModal(props.model)"
                                        >
                                            <span> <i class="fa fa-download marginRight8" aria-hidden="true" />Export Grades </span>

                                            <!-- <span>⇧⌘D</span> -->
                                        </a>
                                    </li>

                                    <li>
                                        <a
                                            v-tooltip="{
                                                content: hasWritePrivilege(props.model) == -1 ? readOnlyLabel : 'Make a Copy',
                                            }"
                                            class="primary-state"
                                            :class="{
                                                disabled: hasWritePrivilege(props.model) == -1,
                                            }"
                                            href="#"
                                            :aria-labelledby="
                                                'Make a copy of ' + props.model.firstname + (props.model.lastname ? ' ' + props.model.lastname : '')
                                            "
                                            aria-label="Make a Copy"
                                            @click="hasWritePrivilege(props.model) == -1 ? '' : openCopyCourseConfirmationModal(props.model)"
                                        >
                                            <span> <i class="fa fa-files-o marginRight8" aria-hidden="true" />Duplicate </span>

                                            <!-- <span>⇧⌘D</span> -->
                                        </a>
                                    </li>

                                    <hr />

                                    <li v-if="searchData.query.isArchived === '0'">
                                        <a
                                            v-tooltip="{
                                                content: !hasWritePrivilege(props.model) == -1 ? readOnlyLabel : 'Archive',
                                            }"
                                            class="danger-state"
                                            :class="{
                                                disabled: !hasWritePrivilege(props.model) == -1,
                                            }"
                                            href="#"
                                            :aria-labelledby="'Archive'"
                                            aria-label="Archive"
                                            @click="hasWritePrivilege(props.model) == -1 ? '' : openSuspendModal(props.model)"
                                        >
                                            <span> <i class="fa fa-archive marginRight8" aria-hidden="true" />Archive </span>

                                            <!-- <span>⇧⌘D</span> -->
                                        </a>
                                    </li>

                                    <li v-if="searchData.query.isArchived === '1'">
                                        <a
                                            v-tooltip="{
                                                content: hasWritePrivilege(props.model) == -1 ? readOnlyLabel : 'Reinstate',
                                            }"
                                            class="primary-state"
                                            :class="{
                                                disabled: hasWritePrivilege(props.model) == -1,
                                            }"
                                            href="#"
                                            :aria-labelledby="'Reinstate'"
                                            aria-label="Reinstate"
                                            @click="hasWritePrivilege(props.model) == -1 ? '' : openSuspendModal(props.model)"
                                        >
                                            <span> <i class="fa fa-refresh marginRight8" aria-hidden="true" />Reinstate </span>

                                            <!-- <span>⇧⌘D</span> -->
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <!-- <div class="dropdown-menu dropdown-menu-right"
                aria-labelledby="dropdownMenuButton">
                <router-link
									tag="button"
									v-tooltip="{ content: 'Edit' }"
									v-if="!props.model.course.isTrashed && hasWritePrivilege(props.model)"
									:to="{ name: 'courses.edit', params: { id: props.model.course.uuid } }"
									class="btn btn-default subBtn"
								>
									<i class="fa fa-pencil-square-o"></i>&nbsp;Edit
								</router-link> 

                <router-link
                  tag="button"
                  :to="{
                    name: 'courses.view',
                    params: { id: props.model.course.uuid },
                  }"
                  class="btn btn-primary subBtn"
                  :aria-labelledby="
                    'View ' +
                      props.model.firstname +
                      (props.model.lastname ? ' ' + props.model.lastname : '')
                  "
                  aria-label="View"
                >
                  <i class="fa fa-eye"></i>&nbsp;View
                </router-link>

                <button
                  class="btn btn-default subBtn primaryHover"
                  @click="openEmailGradesConfirmationModal(props.model)"
                  :disabled="
                    hasWritePrivilege(props.model) == -1 &&
                      !auth.isImpersonation()
                  "
                  :aria-labelledby="
                    'Download ' +
                      props.model.firstname +
                      (props.model.lastname ? ' ' + props.model.lastname : '') +
                      'Report'
                  "
                  aria-label="Generate Course Grades Report"
                >
                  <i class="fa fa-download"></i>&nbsp;Export Grades
                </button>

                <button
                  class="btn btn-default subBtn primaryHover"
                  @click="openCopyCourseConfirmationModal(props.model)"
                  :disabled="(hasWritePrivilege(props.model) == -1) ? true:undefined"
                  :aria-labelledby="
                    'Make a copy of ' +
                      props.model.firstname +
                      (props.model.lastname ? ' ' + props.model.lastname : '')
                  "
                  aria-label="Make a Copy"
                >
                  <i class="fa fa-files-o"></i>&nbsp;Duplicate
                </button>

                <button
                  v-if="searchData.query.isArchived === '0'"
                  class="btn btn-danger subBtn "
                  :aria-labelledby="'Archive'"
                  aria-label="Archive"
                  :disabled="(hasWritePrivilege(props.model) == -1) ? true:undefined"
                  @click="openSuspendModal(props.model)"
                >
                  <i class="fa fa-archive"></i>&nbsp;Archive
                </button>

                <button
                  v-if="searchData.query.isArchived === '1'"
                  class="btn btn-success subBtn "
                  :aria-labelledby="'Reinstate'"
                  aria-label="Reinstate"
                  :disabled="(hasWritePrivilege(props.model) == -1) ? true:undefined"
                  @click="openSuspendModal(props.model)"
                >
                  <i class="fa fa-refresh"></i>&nbsp;Reinstate
                </button>
              </div> -->
                        </div>
                        <!-- <div class="buttonGroupInline hidden-xs hidden-sm">
							<router-link
								tag="button"
								v-tooltip="{ content: 'View Course' }"
								:to="{ name: 'courses.view', params: { id: props.model.course.uuid } }"
								class="btn btn-primary subBtn"
								:aria-labelledby="
									'View ' +
										props.model.firstname +
										(props.model.lastname ? ' ' + props.model.lastname : '')
								"
								aria-label="View"
							>
								<i class="fa fa-eye"></i>&nbsp;View
							</router-link>
							<router-link tag="button" v-tooltip="{content: 'Edit'}" v-if="!props.model.course.isTrashed&&hasWritePrivilege(props.model)" :to="{name:'courses.edit',params:{id:props.model.course.uuid}}" class="btn btn-default subBtn">
                                <i class="fa fa-pencil-square-o"></i>&nbsp;Edit
                            </router-link>
							<div
								class="btn"
								:class="{ 'p-0-i': hasWritePrivilege(props.model) }"
								v-tooltip="{
									content:
										hasWritePrivilege(props.model) == -1 && !auth.isImpersonation()
											? readOnlyLabel
											: 'Generate Course Grades Report',
								}"
							>
								<button
									class="btn btn-default subBtn primaryHover"
									@click="openEmailGradesConfirmationModal(props.model)"
									:disabled="(hasWritePrivilege(props.model) == -1 && !auth.isImpersonation()) ? true:undefined"
									:aria-labelledby="
										'Download ' +
											props.model.firstname +
											(props.model.lastname ? ' ' + props.model.lastname : '') +
											'Report'
									"
									aria-label="Generate Course Grades Report"
								>
									<i class="fa fa-download"></i>
								</button>
							</div>
							<div
								class="btn"
								:class="{ 'p-0-i': hasWritePrivilege(props.model) }"
								v-tooltip="{
									content: hasWritePrivilege(props.model) == -1 ? readOnlyLabel : 'Make a Copy',
								}"
							>
								<button
									class="btn btn-default subBtn primaryHover"
									@click="openCopyCourseConfirmationModal(props.model)"
									:disabled="(hasWritePrivilege(props.model) == -1) ? true:undefined"
									:aria-labelledby="
										'Make a copy of ' +
											props.model.firstname +
											(props.model.lastname ? ' ' + props.model.lastname : '')
									"
									aria-label="Make a Copy"
								>
									<i class="fa fa-files-o"></i>
								</button>
							</div>
						</div>
						<div class="buttonGroupInline hidden-md hidden-lg">
							<router-link
								tag="button"
								v-tooltip="{ content: 'View Course' }"
								:to="{ name: 'courses.view', params: { id: props.model.course.uuid } }"
								class="btn btn-primary subBtn"
								:aria-labelledby="
									'View ' +
										props.model.firstname +
										(props.model.lastname ? ' ' + props.model.lastname : '')
								"
								aria-label="View"
							>
								<i class="fa fa-eye"></i>
							</router-link>
							<router-link tag="button" v-tooltip="{content: 'Edit'}" v-if="!props.model.course.isTrashed&&hasWritePrivilege(props.model)" :to="{name:'courses.edit',params:{id:props.model.course.uuid}}" class="btn btn-default subBtn">
                                <i class="fa fa-pencil-square-o"></i>
                            </router-link>
							<div
								class="btn"
								:class="{ 'p-0-i': hasWritePrivilege(props.model) }"
								v-tooltip="{
									content:
										hasWritePrivilege(props.model) == -1 && !auth.isImpersonation()
											? readOnlyLabel
											: 'Download Course Grades Report',
								}"
							>
								<button
									class="btn btn-default subBtn primaryHover"
									@click="openEmailGradesConfirmationModal(props.model)"
									:disabled="(hasWritePrivilege(props.model) == -1 && !auth.isImpersonation()) ? true:undefined"
									:aria-labelledby="
										'Download ' +
											props.model.firstname +
											(props.model.lastname ? ' ' + props.model.lastname : '') +
											'Report'
									"
									aria-label="Generate Course Grades Report"
								>
									<i class="fa fa-download"></i>
								</button>
							</div>
							<div
								class="btn"
								:class="{ 'p-0-i': hasWritePrivilege(props.model) }"
								v-tooltip="{
									content: hasWritePrivilege(props.model) == -1 ? readOnlyLabel : 'Make a Copy',
								}"
							>
								<button
									class="btn btn-default subBtn primaryHover"
									@click="openCopyCourseConfirmationModal(props.model)"
									:disabled="(hasWritePrivilege(props.model) == -1) ? true:undefined"
									:aria-labelledby="
										'Make a copy of ' +
											props.model.firstname +
											(props.model.lastname ? ' ' + props.model.lastname : '')
									"
									aria-label="Make a Copy"
								>
									<i class="fa fa-files-o"></i>
								</button>
							</div>
						</div> -->
                    </td>
                </template>
            </kr-search>
        </div>
        <div id="copyCourseModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="copyCourseModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="copyCourseModal-title" class="modal-title">Copy Course Confirmation</h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-xs-12">
                                <div class="marginBottom10">Are you sure you want to make a copy of this course?</div>
                                <div class="checkboxField">
                                    <input
                                        v-model="copyCourseForm.copyModules"
                                        type="checkbox"
                                        aria-label="Copy modules to the new course"
                                        @change="toggleCourseDuplicate"
                                    />&nbsp;Copy modules to the new course
                                </div>
                                <div class="checkboxField">
                                    <input
                                        v-model="copyCourseForm.copyActivities"
                                        type="checkbox"
                                        :disabled="!copyCourseForm.copyModules ? true : undefined"
                                        :aria-label="
                                            copyCourseForm.copyModules ? 'Copy activities to the new course' : 'Copy activities to the new course disabled'
                                        "
                                        @change="toggleCourseDuplicate"
                                    />&nbsp;Copy activities to the new course
                                </div>
                                <div class="checkboxField">
                                    <input
                                        v-model="copyCourseForm.copyActivityQuestions"
                                        type="checkbox"
                                        :disabled="!copyCourseForm.copyActivities ? true : undefined"
                                        :aria-label="
                                            copyCourseForm.copyActivities ? 'Copy questions to the new course' : 'Copy questions to the new course disabled'
                                        "
                                        @change="toggleCourseDuplicate"
                                    />&nbsp;Copy questions to the new course
                                </div>
                            </div>
                        </div>
                        <div class="row" style="margin-top: 10px">
                            <div class="flex">
                                <div class="col-xs-12 col-md-6">
                                    <button
                                        id="modal-logout-btn"
                                        class="btn btn-success mainBtn"
                                        style="width: 100%; padding-right: 10px"
                                        data-dismiss="modal"
                                        aria-label="Yes, Copy Course"
                                        @click.prevent="copyCourse()"
                                    >
                                        Yes, Copy Course
                                    </button>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <button id="modal-dismiss-btn" class="btn btn-default mainBtn" style="width: 100%" data-dismiss="modal" aria-label="Cancel">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            id="emailGradesConfirmationModal"
            class="modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="emailGradesConfirmationModal-title"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="emailGradesConfirmationModal-title" class="modal-title">Generate Course Grades Report</h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-xs-12" style="text-align: left">
                                <!--<div>We will generate the Grades Report for this course and notify you once finished (approx. 2-3 minutes). You can download the grades at the <router-link :to="{name:'courses.view',params:{id:course.uuid},query:{tab:'downloadables'}}" target="_blank">Course Downloadables</router-link> section.</div>-->
                                <div>You will be notified when your Grades Report is ready (approx. 2-3 minutes).</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="flex">
                                <div class="col-xs-12 col-md-6">
                                    <button
                                        id="modal-logout-btn"
                                        class="btn btn-success mainBtn"
                                        style="width: 100%; padding-right: 10px"
                                        data-dismiss="modal"
                                        aria-label="Yes, Proceed"
                                        @click="emailGrades()"
                                    >
                                        Yes, Proceed
                                    </button>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <button id="modal-dismiss-btn" class="btn btn-default mainBtn" style="width: 100%" data-dismiss="modal" aria-label="Cancel">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="suspendModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="suspendModal-title">
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>
                        <h2 id="suspendModal-title" class="modal-title">
                            <template v-if="searchData.query.isArchived == '0'"> Archive </template>
                            <template v-else> Reinstate </template>
                            {{ course.name }}
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div v-if="searchData.query.isArchived == '0'" class="col-xs-12" style="text-align: center">
                                <div>Are you sure you want to archive this course?</div>
                                <div>You can reinstate it later by filtering your courses and showing Archived Courses.</div>
                            </div>
                            <div v-else class="col-xs-12" style="text-align: center">
                                <div>Are you sure you want to reinstate this course?</div>
                            </div>
                        </div>
                        <div class="row">
                            <!--<div v-if="suspendObject.status=='success'" class="alert alert-success" role="alert" style="margin-bottom:10px">
                            <i class="fa fa-check"></i>&nbsp; You have successfully performed action
                        </div>
                        <div  v-if="suspendObject.status=='fail'" class="alert alert-danger" role="alert" style="margin-bottom:10px">
                            <i class="fa fa-cross"></i>&nbsp; Error in your action please try again!
                        </div>-->
                            <div class="flex">
                                <div v-if="searchData.query.isArchived == '0'" class="col-xs-12 col-md-6">
                                    <button class="btn btn-danger mainBtn" style="width: 100%" aria-label="Yes, Archive" @click.prevent="archive()">
                                        <i class="fa fa-archive marginRight8" aria-hidden="true" />Yes, Archive
                                    </button>
                                </div>
                                <div v-else class="col-xs-12 col-md-6">
                                    <button class="btn btn-success mainBtn" style="width: 100%" aria-label="Yes, Reinstate" @click.prevent="reinstate()">
                                        Yes, Reinstate
                                    </button>
                                </div>
                                <div class="col-xs-12 col-md-6">
                                    <button class="btn btn-default mainBtn" style="width: 100%" data-dismiss="modal" aria-label="No, Cancel">No, Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import KrAuth from '../../../components/auth/auth';
import KrForm from '../../../components/forms/form';
export default {
    data() {
        var auth = new KrAuth();
        return {
            auth: auth,
            isBusy: false,
            searchData: {
                meta: { paginator: { total: 0 }, filters: {} },
                query: { isTrashed: '0', isArchived: '0' },
            },
            course: {},
            copyCourseForm: {
                copyModules: true,
                copyActivities: true,
                copyActivityQuestions: true,
            },
            readOnlyLabel: 'You have read-only access',
            rightContainerHeight: null,
        };
    },
    created() {
        console.log(this);
        var that = this;
        Events.fire('topbar_update', {
            breadcrumb: [{ name: 'Home' }, { name: 'Courses', link: 'courses.index' }],
        });
    },
    methods: {
        openSuspendModal(course) {
            this.course = course.course;
            $('#suspendModal').modal('show');
        },
        archive() {
            $('#suspendModal').modal('hide');
            var success = function () {
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'You have sucessfully removed course from list',
                });
                Events.fire('courses_listing_refresh');
            }.bind(this);
            var del = function () {
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .patch('/courses/' + this.course.uuid)
                .then(function (response) {
                    success();
                })
                .catch(function (errors) {
                    del();
                });
        },
        reinstate() {
            $('#suspendModal').modal('hide');
            var success = function () {
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Course has been reinstated',
                });
                Events.fire('courses_listing_refresh');
            }.bind(this);
            var del = function () {
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .patch('/courses/' + this.course.uuid)
                .then(function (response) {
                    success();
                })
                .catch(function (errors) {
                    del();
                });
        },
        toggleCourseDuplicate() {
            if (this.copyCourseForm.copyModules == false) {
                this.copyCourseForm.copyActivities = false;
                this.copyCourseForm.copyActivityQuestions = false;
            }
            if (this.copyCourseForm.copyActivities == false) {
                this.copyCourseForm.copyActivityQuestions = false;
            }
        },
        openEmailGradesConfirmationModal(course) {
            this.course = course.course;
            $('#emailGradesConfirmationModal').modal('show');
        },
        emailGrades() {
            var that = this;
            axios({
                url: '/courses/' + this.course.uuid + '/grades',
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                /*const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download',course.name+'_'+moment().format('YYYY_MM_DD')+'.xlsx');
              document.body.appendChild(link);
              link.click();
              link.remove();*/
                that.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'We will generate the Grades Report for this course and notify you once finished',
                });
            });
        },
        openCopyCourseConfirmationModal(course) {
            this.course = course.course;
            this.copyCourseForm = {
                copyModules: true,
                copyActivities: true,
                copyActivityQuestions: true,
            };
            $('#copyCourseModal').modal('show');
        },
        copyCourse() {
            var that = this;
            axios
                .post('courses/' + that.course.uuid + '/duplicate', this.copyCourseForm)
                .then(function (response) {
                    that.$router.push({
                        name: 'courses.view',
                        params: { id: response.data.data.uuid },
                    });
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Course has been successfully copied',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to copy course at this moment, please try again later',
                    });
                });
        },
        getSearchData(data) {
            this.searchData = data;
        },
        highlight(text) {
            if (this.searchData.query.q != '' && text != null) {
                return text.replace(new RegExp(this.searchData.query.q, 'gi'), '<span class="table-keyword-highlight">$&</span>');
            } else {
                return text;
            }
        },
        dropdownToggle() {
            if ($('table tbody tr').length >= 6) {
                $('table tbody tr:nth-last-child(-n+4) td:last-child div').addClass('dropup');
            } else if ($('table tbody tr').length == 5) {
                $('table tbody tr:nth-last-child(-n+2) td:last-child div').addClass('dropup');
            }
        },
        truncate(str, characters = 100) {
            return _.truncate(str, {
                length: characters,
                omission: '',
            });
        },
    },
};
</script>

<style scoped>
#copyCourseModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#copyCourseModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}
#emailGradesConfirmationModal .modal-body .row:last-child {
    margin-top: 30px;
}
#emailGradesConfirmationModal .modal-body .row .col-md-6 {
    padding-right: 10px;
    padding-left: 0;
}
#emailGradesConfirmationModal .modal-body .row .col-md-6:last-child {
    padding-left: 10px;
    padding-right: 0;
}
/* .checkboxField input[type="checkbox"]:disabled {
  opacity: 0.3;
} */

/* .setting .dropdown-menu {
  top: inherit;
} */

.setting .marginBottom0 {
    margin-bottom: 0 !important;
}

/* .dropdown-menu button:not(:first-child) {
  margin-top: 10px;
} */
</style>
