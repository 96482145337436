<template>
    <div>
        <div class="exTab container marginTop20 hidden-xs hidden-sm">
            <template v-if="type == 'peer'">
                <div style="overflow: auto">
                    <ul class="nav nav-pills flexOnly">
                        <li
                            v-for="(model, model_idx) in question.peers"
                            :key="'evaluation-peer-' + model_idx"
                            :class="{
                                active: question._currentTab == model_idx,
                            }"
                            @click="question._currentTab = model_idx"
                        >
                            <a
                                :href="question.uuid + '-' + model_idx"
                                data-toggle="tab"
                                :aria-label="
                                    'peer ' +
                                    (!model.isMe ? model.fullname : 'myself') +
                                    ' ' +
                                    (question._currentTab == model_idx ? 'is Selected' : 'not selected')
                                "
                            >
                                <div class="flexOnly align-items">
                                    <i
                                        class="fas marginRight8"
                                        :class="{
                                            'fa-circle greyText': !isCorrect(model_idx),
                                            'fa-check-circle darkGreen': isCorrect(model_idx),
                                        }"
                                        aria-hidden="true"
                                    ></i>
                                    <div class="profile_image marginRight5">
                                        <div class="badge_profile img-circle">
                                            {{ initials(model.fullname) }}
                                        </div>
                                    </div>
                                    <div :class="{ warningColor: model.isMe }">
                                        <template v-if="!model.isMe"> {{ model.fullname }} </template><template v-if="model.isMe">Me</template>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="tab-content clearfix">
                    <div
                        v-for="(model, model_idx) in question.peers"
                        :id="question.uuid + '-' + model_idx"
                        :key="'evaluation-peer-' + model_idx + '-textarea'"
                        class="tab-pane"
                        :class="{ active: question._currentTab == model_idx }"
                    >
                        <textarea
                            v-model="model.response"
                            placeholder="Input your answers here"
                            row="5"
                            cols="50"
                            :disabled="disabled ? true : undefined"
                            :aria-label="
                                'Add message for your peer ' +
                                model.fullname +
                                ' ' +
                                ((computeNoOfWords(model.response) < question.minimum && question.minimum != 0
                                    ? 'Please enter ' +
                                      (question.minimum - computeNoOfWords(model.response)) +
                                      ' more ' +
                                      (question.minimum - computeNoOfWords(model.response) == 1 ? ' word' : ' words')
                                    : '') ||
                                    (computeNoOfWords(model.response) <= question.maximum
                                        ? computeNoOfWords(model.response) +
                                          ' ' +
                                          (computeNoOfWords(model.response) < 2 ? ' word ' : ' words ') +
                                          ' (max ' +
                                          question.maximum +
                                          ' )'
                                        : '') ||
                                    (computeNoOfWords(model.response) > question.maximum
                                        ? computeNoOfWords(model.response) +
                                          ' ' +
                                          (computeNoOfWords(model.response) < 2 ? ' word ' : ' words ') +
                                          ' (max ' +
                                          question.maximum +
                                          ' )'
                                        : ''))
                            "
                            @keyup="$emit('changed', question.peers)"
                        >
                        </textarea>
                        <!-- <div class="fontSize12 marginTop10">
								{{computeNoOfWords(model.response)}} word/s &nbsp;<template v-if="question.minimum!=0">(min.&nbsp;{{question.minimum}})</template><template v-if="question.maximum">/{{question.maximum}}</template>
							</div> -->
                        <div
                            class="fontSize16 marginTop10"
                            :class="{
                                dangerColor: !isCorrect(model_idx),
                                greyColor: isCorrect(model_idx),
                            }"
                            aria-role="alert"
                        >
                            <template v-if="computeNoOfWords(model.response) < question.minimum && question.minimum != 0">
                                Please enter
                                {{ question.minimum - computeNoOfWords(model.response) }}
                                more
                                {{ question.minimum - computeNoOfWords(model.response) == 1 ? 'word' : 'words' }}
                            </template>
                            <template v-else-if="computeNoOfWords(model.response) <= question.maximum">
                                {{ computeNoOfWords(model.response) }}
                                {{ computeNoOfWords(model.response) < 2 ? 'word' : 'words' }}
                                (max {{ question.maximum }})
                            </template>
                            <template v-else-if="computeNoOfWords(model.response) > question.maximum">
                                {{ computeNoOfWords(model.response) }}
                                {{ computeNoOfWords(model.response) < 2 ? 'word' : 'words' }}
                                (max {{ question.maximum }})
                            </template>
                        </div>

                        <div class="col-xs-12 alignRight">
                            <div class="inlineFlex align-items">
                                <div class="greyText" tabindex="0" :aria-label="correctTabs + ' out of ' + question.peers.length + ' completed'">
                                    {{ correctTabs }} / {{ question.peers.length }} Completed
                                </div>
                                <template v-if="question.peers.length > 1">
                                    <button
                                        class="btn btn-default mainBtn"
                                        :disabled="model_idx == 0 ? true : undefined"
                                        :aria-label="model_idx == 0 ? 'Previous Button Disabled' : 'Previous Peer'"
                                        @click="question._currentTab--"
                                    >
                                        <i class="fas fa-caret-left marginRight8" aria-hidden="true"></i>Previous
                                    </button>
                                    <button
                                        class="btn btn-default mainBtn"
                                        :disabled="model_idx >= question.peers.length - 1 ? true : undefined"
                                        :aria-label="model_idx >= question.peers.length - 1 ? 'Next Button Disabled' : 'Next Peer'"
                                        @click="question._currentTab++"
                                    >
                                        Next<i class="fas fa-caret-right marginLeft8" aria-hidden="true"></i>
                                    </button>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="type == 'team'">
                <div style="overflow: auto">
                    <ul class="nav nav-pills flexOnly">
                        <li
                            v-for="(model, model_idx) in question.teams"
                            :key="'evaluation-team-' + model_idx"
                            :class="{
                                active: question._currentTab == model_idx,
                            }"
                            @click="question._currentTab = model_idx"
                        >
                            <a
                                :href="question.uuid + '-' + model_idx"
                                data-toggle="tab"
                                :aria-label="
                                    model.name + ' ' + model.isMe ? 'my team' : '' + (question._currentTab == model_idx ? 'is Selected' : 'not selected')
                                "
                            >
                                <div class="flexOnly align-items">
                                    <i
                                        class="fas marginRight8"
                                        :class="{
                                            'fa-circle greyText': !isCorrect(model_idx),
                                            'fa-check-circle darkGreen': isCorrect(model_idx),
                                        }"
                                        aria-hidden="true"
                                    ></i>
                                    <div class="profile_image marginRight5">
                                        <div class="badge_profile img-circle">
                                            {{ initials(model.name) }}
                                        </div>
                                    </div>
                                    <div :class="{ warningColor: model.isMe }">
                                        {{ model.name }}
                                        <template v-if="model.isMe">(Me)</template>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="tab-content clearfix">
                    <div
                        v-for="(model, model_idx) in question.teams"
                        :id="question.uuid + '-' + model_idx"
                        :key="'evaluation-team-' + model_idx + '-textarea'"
                        class="tab-pane"
                        :class="{ active: question._currentTab == model_idx }"
                    >
                        <textarea
                            v-model="model.response"
                            placeholder="Input your answers here"
                            row="5"
                            cols="50"
                            :disabled="disabled ? true : undefined"
                            :aria-label="
                                'Add message for your team ' + model.name + ' ' + model.isMe
                                    ? 'my team'
                                    : '' +
                                      ' ' +
                                      ((computeNoOfWords(model.response) < question.minimum && question.minimum != 0
                                          ? 'Please enter ' +
                                            (question.minimum - computeNoOfWords(model.response)) +
                                            ' more ' +
                                            (question.minimum - computeNoOfWords(model.response) == 1 ? ' word' : ' words')
                                          : '') ||
                                          (computeNoOfWords(model.response) <= question.maximum
                                              ? computeNoOfWords(model.response) +
                                                ' ' +
                                                (computeNoOfWords(model.response) < 2 ? ' word ' : ' words ') +
                                                ' (max ' +
                                                question.maximum +
                                                ' )'
                                              : '') ||
                                          (computeNoOfWords(model.response) > question.maximum
                                              ? computeNoOfWords(model.response) +
                                                ' ' +
                                                (computeNoOfWords(model.response) < 2 ? ' word ' : ' words ') +
                                                ' (max ' +
                                                question.maximum +
                                                ' )'
                                              : ''))
                            "
                            @keyup="$emit('changed', question.teams)"
                        ></textarea>
                        <!-- <div class="fontSize12 marginTop10">
								{{computeNoOfWords(model.response)}} word/s &nbsp;<template v-if="question.minimum!=0">(min.&nbsp;{{question.minimum}})</template><template v-if="question.maximum">/{{question.maximum}}</template>
							</div> -->
                        <div
                            class="fontSize16 marginTop10"
                            :class="{
                                dangerColor: !isCorrect(model_idx),
                                greyColor: isCorrect(model_idx),
                            }"
                        >
                            <template v-if="computeNoOfWords(model.response) < question.minimum && question.minimum != 0">
                                Please enter
                                {{ question.minimum - computeNoOfWords(model.response) }}
                                more
                                {{ question.minimum - computeNoOfWords(model.response) == 1 ? 'word' : 'words' }}
                            </template>
                            <template v-else-if="computeNoOfWords(model.response) <= question.maximum">
                                {{ computeNoOfWords(model.response) }}
                                {{ computeNoOfWords(model.response) < 2 ? 'word' : 'words' }}
                                (max {{ question.maximum }})
                            </template>
                            <template v-else-if="computeNoOfWords(model.response) > question.maximum">
                                {{ computeNoOfWords(model.response) }}
                                {{ computeNoOfWords(model.response) < 2 ? 'word' : 'words' }}
                                (max {{ question.maximum }})
                            </template>
                        </div>

                        <div class="col-xs-12 alignRight">
                            <div class="inlineFlex align-items">
                                <div class="greyText" tabindex="0" :aria-label="correctTabs + ' out of ' + question.teams.length + ' completed'">
                                    {{ correctTabs }} / {{ question.teams.length }} Completed
                                </div>
                                <template v-if="question.teams.length > 1">
                                    <button
                                        class="btn btn-default mainBtn"
                                        :disabled="model_idx == 0 ? true : undefined"
                                        :aria-label="model_idx == 0 ? 'Previous Button Disabled' : 'Previous teammate'"
                                        @click="question._currentTab--"
                                    >
                                        <i class="fas fa-caret-left marginRight8" aria-hidden="true"></i>Previous
                                    </button>
                                    <button
                                        class="btn btn-default mainBtn"
                                        :disabled="model_idx >= question.teams.length - 1 ? true : undefined"
                                        :aria-label="model_idx >= question.teams.length - 1 ? 'Next Button Disabled' : 'Next teammate'"
                                        @click="question._currentTab++"
                                    >
                                        Next<i class="fas fa-caret-right marginLeft8" aria-hidden="true"></i>
                                    </button>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="type == 'instructor'">
                <div style="overflow: auto">
                    <ul class="nav nav-pills flexOnly">
                        <li
                            v-for="(model, model_idx) in question.instructors"
                            :key="'evaluation-instructor-' + model_idx"
                            :class="{
                                active: question._currentTab == model_idx,
                            }"
                            @click="question._currentTab = model_idx"
                        >
                            <a
                                :href="question.uuid + '-' + model_idx"
                                data-toggle="tab"
                                :aria-label="
                                    'instructor ' + model.name + ' ' + model.isMe
                                        ? 'me'
                                        : '' + ' ' + (question._currentTab == model_idx ? 'is Selected' : 'not selected')
                                "
                            >
                                <div class="flexOnly align-items">
                                    <i
                                        class="fas marginRight8"
                                        :class="{
                                            'fa-circle greyText': !isCorrect(model_idx),
                                            'fa-check-circle darkGreen': isCorrect(model_idx),
                                        }"
                                        aria-hidden="true"
                                    ></i>
                                    <div class="profile_image marginRight5">
                                        <div class="badge_profile img-circle">
                                            {{ initials(model.name) }}
                                        </div>
                                    </div>
                                    <div :class="{ warningColor: model.isMe }">
                                        {{ model.name }}
                                        <template v-if="model.isMe">(Me)</template>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="tab-content clearfix">
                    <div
                        v-for="(model, model_idx) in question.instructors"
                        :id="question.uuid + '-' + model_idx"
                        :key="'evaluation-instructor-' + model_idx + '-textarea'"
                        class="tab-pane"
                        :class="{ active: question._currentTab == model_idx }"
                    >
                        <textarea
                            v-model="model.response"
                            placeholder="Input your answers here"
                            row="5"
                            cols="50"
                            :disabled="disabled ? true : undefined"
                            :aria-label="
                                'Add message for your peer ' +
                                model.fullname +
                                ' ' +
                                ((computeNoOfWords(model.response) < question.minimum && question.minimum != 0
                                    ? 'Please enter ' +
                                      (question.minimum - computeNoOfWords(model.response)) +
                                      ' more ' +
                                      (question.minimum - computeNoOfWords(model.response) == 1 ? ' word' : ' words')
                                    : '') ||
                                    (computeNoOfWords(model.response) <= question.maximum
                                        ? computeNoOfWords(model.response) +
                                          ' ' +
                                          (computeNoOfWords(model.response) < 2 ? ' word ' : ' words ') +
                                          ' (max ' +
                                          question.maximum +
                                          ' )'
                                        : '') ||
                                    (computeNoOfWords(model.response) > question.maximum
                                        ? computeNoOfWords(model.response) +
                                          ' ' +
                                          (computeNoOfWords(model.response) < 2 ? ' word ' : ' words ') +
                                          ' (max ' +
                                          question.maximum +
                                          ' )'
                                        : ''))
                            "
                            @keyup="$emit('changed', question.instructors)"
                        ></textarea>
                        <!-- <div class="fontSize12 marginTop10">
								{{computeNoOfWords(model.response)}} word/s &nbsp;<template v-if="question.minimum!=0">(min.&nbsp;{{question.minimum}})</template><template v-if="question.maximum">/{{question.maximum}}</template>
							</div> -->
                        <div
                            class="fontSize16 marginTop10"
                            :class="{
                                dangerColor: !isCorrect(model_idx),
                                greyColor: isCorrect(model_idx),
                            }"
                        >
                            <template v-if="computeNoOfWords(model.response) < question.minimum && question.minimum != 0">
                                Please enter
                                {{ question.minimum - computeNoOfWords(model.response) }}
                                more
                                {{ question.minimum - computeNoOfWords(model.response) == 1 ? 'word' : 'words' }}
                            </template>
                            <template v-else-if="computeNoOfWords(model.response) <= question.maximum">
                                {{ computeNoOfWords(model.response) }}
                                {{ computeNoOfWords(model.response) < 2 ? 'word' : 'words' }}
                                (max {{ question.maximum }})
                            </template>
                            <template v-else-if="computeNoOfWords(model.response) > question.maximum">
                                {{ computeNoOfWords(model.response) }}
                                {{ computeNoOfWords(model.response) < 2 ? 'word' : 'words' }}
                                (max {{ question.maximum }})
                            </template>
                        </div>

                        <div class="col-xs-12 alignRight">
                            <div class="inlineFlex align-items">
                                <div class="greyText" tabindex="0" :aria-label="correctTabs + ' out of ' + question.instructors.length + ' completed'">
                                    {{ correctTabs }} / {{ question.instructors.length }} Completed
                                </div>

                                <template v-if="question.instructors.length > 1">
                                    <button
                                        class="btn btn-default mainBtn"
                                        :disabled="model_idx == 0 ? true : undefined"
                                        :aria-label="model_idx == 0 ? 'Previous Button Disabled' : 'Previous Instructor'"
                                        @click="question._currentTab--"
                                    >
                                        <i class="fas fa-caret-left marginRight8" aria-hidden="true"></i>Previous
                                    </button>
                                    <button
                                        class="btn btn-default mainBtn"
                                        :disabled="model_idx >= question.instructors.length - 1 ? true : undefined"
                                        :aria-label="model_idx >= question.instructors.length - 1 ? 'Next Button Disabled' : 'Next Instructor'"
                                        @click="question._currentTab++"
                                    >
                                        Next<i class="fas fa-caret-right marginLeft8" aria-hidden="true"></i>
                                    </button>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <div class="hidden-md hidden-lg">
            <template v-if="type == 'peer'">
                <template v-for="(model, model_idx) in question.peers">
                    <div class="panel marginTop20">
                        <div class="mini-panel">
                            <div class="panel-heading collapsed" data-toggle="collapse" :data-target="'#openPeer_' + question.uuid + '_' + model_idx">
                                <button class="flexSpaceBetween align-items width100 padding0">
                                    <div class="flexOnly align-items">
                                        <i
                                            class="fas marginRight8"
                                            :class="{
                                                'fa-circle greyText': !isCorrect(model_idx),
                                                'fa-check-circle darkGreen': isCorrect(model_idx),
                                            }"
                                            aria-hidden="true"
                                        ></i>
                                        <div class="profile_image marginRight5">
                                            <div class="badge_profile img-circle">
                                                {{ initials(model.fullname) }}
                                            </div>
                                        </div>
                                        <div
                                            class="capitalize fontSize14"
                                            :class="{
                                                'warningColor fontBold': model.isMe,
                                                'fontBlack fontNormal': !model.isMe,
                                            }"
                                        >
                                            {{ model.fullname }}
                                            <template v-if="model.isMe">(Me)</template>
                                        </div>
                                    </div>

                                    <div class="toggleArrow"></div>
                                </button>

                                <!-- <div>
                  <ul class="nav">
                    <li
                      :class="{ active: question._currentTab == model_idx }"
                      @click="question._currentTab = model_idx"
                    >
                      <a
                        class="padding0"
                        :href="question.uuid + '-' + model_idx"
                        data-toggle="tab"
                      >
                        <div class="flexOnly align-items">
                          <i
                            class="fas"
                            :class="{
                              'fa-circle greyText': !isCorrect(model_idx),
                              'fa-check-circle darkGreen': isCorrect(model_idx),
                            }"
                          ></i
                          >&nbsp;
                          <div class="profile_image marginRight5">
                            <template>
                              <div class="badge_profile img-circle">
                                {{ initials(model.fullname) }}
                              </div>
                            </template>
                          </div>
                          <div
                            class="capitalize fontSize14"
                            :class="{
                              'warningColor fontBold': model.isMe,
                              'fontBlack fontNormal': !model.isMe,
                            }"
                          >
                            {{ model.fullname }}
                            <template v-if="model.isMe">(Me)</template>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>

                <div>
                  <div class="toggleArrow"></div>
                </div> -->
                            </div>

                            <div :id="'openPeer_' + question.uuid + '_' + model_idx" class="panel-body collapse">
                                <textarea
                                    v-model="model.response"
                                    placeholder="Input your answers here"
                                    row="5"
                                    cols="50"
                                    :disabled="disabled ? true : undefined"
                                    aria-label="Answer"
                                    @keyup="$emit('changed', question.peers)"
                                ></textarea>

                                <div
                                    class="fontSize16 marginTop10"
                                    :class="{
                                        dangerColor: !isCorrect(model_idx),
                                        greyColor: isCorrect(model_idx),
                                    }"
                                >
                                    <template v-if="computeNoOfWords(model.response) < question.minimum && question.minimum != 0">
                                        Please enter
                                        {{ question.minimum - computeNoOfWords(model.response) }}
                                        more
                                        {{ question.minimum - computeNoOfWords(model.response) == 1 ? 'word' : 'words' }}
                                    </template>
                                    <template v-else-if="computeNoOfWords(model.response) <= question.maximum">
                                        {{ computeNoOfWords(model.response) }}
                                        {{ computeNoOfWords(model.response) < 2 ? 'word' : 'words' }}
                                        (max {{ question.maximum }})
                                    </template>
                                    <template v-else-if="computeNoOfWords(model.response) > question.maximum">
                                        {{ computeNoOfWords(model.response) }}
                                        {{ computeNoOfWords(model.response) < 2 ? 'word' : 'words' }}
                                        (max {{ question.maximum }})
                                    </template>
                                </div>

                                <div class="marginTop5 marginBottom5 greyText">{{ correctTabs }} / {{ question.peers.length }} Completed</div>

                                <div class="col-xs-12 alignRight">
                                    <div class="inlineFlex align-items">
                                        <button
                                            class="btn btn-default mainBtn"
                                            :disabled="model_idx == 0 ? true : undefined"
                                            :aria-label="model_idx == 0 ? 'Previous Button Disabled' : 'Previous'"
                                            @click.prevent="newTabExpand('#openPeer_' + question.uuid + '_' + (model_idx - 1))"
                                        >
                                            <i class="fas fa-caret-left marginRight8" aria-hidden="true"></i>Previous
                                        </button>
                                        <button
                                            class="btn btn-default mainBtn"
                                            :disabled="model_idx >= question.peers.length - 1 ? true : undefined"
                                            :aria-label="model_idx >= question.peers.length - 1 ? 'Next Button Disabled' : 'Next'"
                                            @click.prevent="newTabExpand('#openPeer_' + question.uuid + '_' + (model_idx + 1))"
                                        >
                                            Next<i class="fas fa-caret-right marginLeft8" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </template>

            <template v-if="type == 'team'">
                <template v-for="(model, model_idx) in question.teams">
                    <div class="panel marginTop20">
                        <div class="mini-panel">
                            <div class="panel-heading collapsed" data-toggle="collapse" :data-target="'#openTeam_' + question.uuid + '_' + model_idx">
                                <button class="flexSpaceBetween align-items width100 padding0">
                                    <div class="flexOnly align-items">
                                        <i
                                            class="fas marginRight8"
                                            :class="{
                                                'fa-circle greyText': !isCorrect(model_idx),
                                                'fa-check-circle darkGreen': isCorrect(model_idx),
                                            }"
                                            aria-hidden="true"
                                        ></i>
                                        <div class="profile_image marginRight5">
                                            <div class="badge_profile img-circle">
                                                {{ initials(model.name) }}
                                            </div>
                                        </div>
                                        <div
                                            class="capitalize fontSize14"
                                            :class="{
                                                'warningColor fontBold': model.isMe,
                                                'fontBlack fontNormal': !model.isMe,
                                            }"
                                        >
                                            {{ model.name }}
                                            <template v-if="model.isMe">(Me)</template>
                                        </div>
                                    </div>

                                    <div class="toggleArrow"></div>
                                </button>

                                <!-- <div>
                  <ul class="nav">
                    <li
                      :class="{ active: question._currentTab == model_idx }"
                      @click="question._currentTab = model_idx"
                    >
                      <a
                        class="padding0"
                        :href="question.uuid + '-' + model_idx"
                        data-toggle="tab"
                      >
                        <div class="flexOnly align-items">
                          <i
                            class="fas"
                            :class="{
                              'fa-circle greyText': !isCorrect(model_idx),
                              'fa-check-circle darkGreen': isCorrect(model_idx),
                            }"
                          ></i
                          >&nbsp;
                          <div class="profile_image marginRight5">
                            <template>
                              <div class="badge_profile img-circle">
                                {{ initials(model.name) }}
                              </div>
                            </template>
                          </div>
                          <div
                            class="capitalize fontSize14"
                            :class="{
                              'warningColor fontBold': model.isMe,
                              'fontBlack fontNormal': !model.isMe,
                            }"
                          >
                            {{ model.name }}
                            <template v-if="model.isMe">(Me)</template>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <div class="toggleArrow"></div>
                </div> -->
                            </div>

                            <div :id="'openTeam_' + question.uuid + '_' + model_idx" class="panel-body collapse">
                                <textarea
                                    v-model="model.response"
                                    placeholder="Input your answers here"
                                    row="5"
                                    cols="50"
                                    :disabled="disabled ? true : undefined"
                                    aria-label="Answer"
                                    @keyup="$emit('changed', question.teams)"
                                ></textarea>
                                <div
                                    class="fontSize16 marginTop10"
                                    :class="{
                                        dangerColor: !isCorrect(model_idx),
                                        greyColor: isCorrect(model_idx),
                                    }"
                                >
                                    <template v-if="computeNoOfWords(model.response) < question.minimum && question.minimum != 0">
                                        Please enter
                                        {{ question.minimum - computeNoOfWords(model.response) }}
                                        more
                                        {{ question.minimum - computeNoOfWords(model.response) == 1 ? 'word' : 'words' }}
                                    </template>
                                    <template v-else-if="computeNoOfWords(model.response) <= question.maximum">
                                        {{ computeNoOfWords(model.response) }}
                                        {{ computeNoOfWords(model.response) < 2 ? 'word' : 'words' }}
                                        (max {{ question.maximum }})
                                    </template>
                                    <template v-else-if="computeNoOfWords(model.response) > question.maximum">
                                        {{ computeNoOfWords(model.response) }}
                                        {{ computeNoOfWords(model.response) < 2 ? 'word' : 'words' }}
                                        (max {{ question.maximum }})
                                    </template>
                                </div>
                                <div class="marginTop5 marginBottom5 greyText">{{ correctTabs }} / {{ question.teams.length }} Completed</div>

                                <div class="col-xs-12 alignRight">
                                    <div class="inlineFlex align-items">
                                        <button
                                            class="btn btn-default mainBtn"
                                            :disabled="model_idx == 0 ? true : undefined"
                                            :aria-label="model_idx == 0 ? 'Previous Button Disabled' : 'Previous'"
                                            @click.prevent="newTabExpand('#openTeam_' + question.uuid + '_' + (model_idx - 1))"
                                        >
                                            <i class="fas fa-caret-left marginRight8" aria-hidden="true"></i>Previous
                                        </button>
                                        <button
                                            class="btn btn-default mainBtn"
                                            :disabled="model_idx >= question.teams.length - 1 ? true : undefined"
                                            :aria-label="model_idx >= question.teams.length - 1 ? 'Next Button Disabled' : 'Next'"
                                            @click.prevent="newTabExpand('#openTeam_' + question.uuid + '_' + (model_idx + 1))"
                                        >
                                            Next<i class="fas fa-caret-right marginLeft8" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </template>

            <template v-if="type == 'instructor'">
                <template v-for="(model, model_idx) in question.instructors">
                    <div class="panel marginTop20">
                        <div class="mini-panel">
                            <div class="panel-heading collapsed" data-toggle="collapse" :data-target="'#openInstructor_' + question.uuid + '_' + model_idx">
                                <button class="flexSpaceBetween align-items width100 padding0">
                                    <div class="flexOnly align-items">
                                        <i
                                            class="fas marginRight8"
                                            :class="{
                                                'fa-circle greyText': !isCorrect(model_idx),
                                                'fa-check-circle darkGreen': isCorrect(model_idx),
                                            }"
                                            aria-hidden="true"
                                        ></i>
                                        <div class="profile_image marginRight5">
                                            <div class="badge_profile img-circle">
                                                {{ initials(model.name) }}
                                            </div>
                                        </div>
                                        <div
                                            class="capitalize fontSize14"
                                            :class="{
                                                'warningColor fontBold': model.isMe,
                                                'fontBlack fontNormal': !model.isMe,
                                            }"
                                        >
                                            {{ model.name }}
                                            <template v-if="model.isMe">(Me)</template>
                                        </div>
                                    </div>

                                    <div class="toggleArrow"></div>
                                </button>

                                <!-- <div>
                  <ul class="nav">
                    <li
                      :class="{ active: question._currentTab == model_idx }"
                      @click="question._currentTab = model_idx"
                    >
                      <a
                        class="padding0"
                        :href="question.uuid + '-' + model_idx"
                        data-toggle="tab"
                      >
                        <div class="flexOnly align-items">
                          <i
                            class="fas"
                            :class="{
                              'fa-circle greyText': !isCorrect(model_idx),
                              'fa-check-circle darkGreen': isCorrect(model_idx),
                            }"
                          ></i
                          >&nbsp;
                          <div class="profile_image marginRight5">
                            <template>
                              <div class="badge_profile img-circle">
                                {{ initials(model.name) }}
                              </div>
                            </template>
                          </div>
                          <div
                            class="capitalize fontSize14"
                            :class="{
                              'warningColor fontBold': model.isMe,
                              'fontBlack fontNormal': !model.isMe,
                            }"
                          >
                            {{ model.name }}
                            <template v-if="model.isMe">(Me)</template>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <div
                    class="toggleArrow collapsed"
                    data-toggle="collapse"
                    :data-target="
                      '#openInstructor_' + question.uuid + '_' + model_idx
                    "
                  ></div>
                </div> -->
                            </div>

                            <div :id="'openInstructor_' + question.uuid + '_' + model_idx" class="panel-body collapse">
                                <textarea
                                    v-model="model.response"
                                    placeholder="Input your answers here"
                                    row="5"
                                    cols="50"
                                    :disabled="disabled ? true : undefined"
                                    @keyup="$emit('changed', question.instructors)"
                                ></textarea>
                                <div
                                    class="fontSize16 marginTop10"
                                    :class="{
                                        dangerColor: !isCorrect(model_idx),
                                        greyColor: isCorrect(model_idx),
                                    }"
                                >
                                    <template v-if="computeNoOfWords(model.response) < question.minimum && question.minimum != 0">
                                        Please enter
                                        {{ question.minimum - computeNoOfWords(model.response) }}
                                        more
                                        {{ question.minimum - computeNoOfWords(model.response) == 1 ? 'word' : 'words' }}
                                    </template>
                                    <template v-else-if="computeNoOfWords(model.response) <= question.maximum">
                                        {{ computeNoOfWords(model.response) }}
                                        {{ computeNoOfWords(model.response) < 2 ? 'word' : 'words' }}
                                        (max {{ question.maximum }})
                                    </template>
                                    <template v-else-if="computeNoOfWords(model.response) > question.maximum">
                                        {{ computeNoOfWords(model.response) }}
                                        {{ computeNoOfWords(model.response) < 2 ? 'word' : 'words' }}
                                        (max {{ question.maximum }})
                                    </template>
                                </div>
                                <div class="marginTop5 marginBottom5 greyText">{{ correctTabs }} / {{ question.instructors.length }} Completed</div>

                                <div class="col-xs-12 alignRight">
                                    <div class="inlineFlex align-items">
                                        <button
                                            class="btn btn-default mainBtn"
                                            :disabled="model_idx == 0 ? true : undefined"
                                            :aria-label="model_idx == 0 ? 'Previous Button Disabled' : 'Previous'"
                                            @click.prevent="newTabExpand('#openInstructor_' + question.uuid + '_' + (model_idx - 1))"
                                        >
                                            <i class="fas fa-caret-left marginRight8" aria-hidden="true"></i>Previous
                                        </button>
                                        <button
                                            class="btn btn-default mainBtn"
                                            :disabled="model_idx >= question.instructors.length - 1 ? true : undefined"
                                            :aria-label="model_idx >= question.instructors.length - 1 ? 'Next Button Disabled' : 'Next'"
                                            @click.prevent="newTabExpand('#openInstructor_' + question.uuid + '_' + (model_idx + 1))"
                                        >
                                            Next<i class="fas fa-caret-right marginLeft8" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: ['testObj', 'question', 'disabled', 'type', 'question_idx'],
    data() {
        return {
            correctTabs: 0,
            initialLoad: true,
        };
    },
    watch: {
        question: {
            handler: function () {
                if (this.question._currentTab == null) {
                    this.question._currentTab = 0;
                    //Vue.set(this.question, '_currentTab', 0);
                }
                if (this.type == 'peer') {
                    this.correctTabs = 0;
                    for (var i = 0; i < this.question.peers.length; i++) {
                        if (this.isCorrect(i)) {
                            this.correctTabs++;
                        }
                    }
                    if (this.correctTabs != this.question.peers.length) {
                        this.question._isCorrect = false;
                    } else {
                        this.question._isCorrect = true;
                    }
                } else if (this.type == 'team') {
                    this.correctTabs = 0;
                    for (var i = 0; i < this.question.teams.length; i++) {
                        if (this.isCorrect(i)) {
                            this.correctTabs++;
                        }
                    }
                    if (this.correctTabs != this.question.teams.length) {
                        this.question._isCorrect = false;
                    } else {
                        this.question._isCorrect = true;
                    }
                } else if (this.type == 'instructor') {
                    this.correctTabs = 0;
                    for (var i = 0; i < this.question.instructors.length; i++) {
                        if (this.isCorrect(i)) {
                            this.correctTabs++;
                        }
                    }
                    if (this.correctTabs != this.question.instructors.length) {
                        this.question._isCorrect = false;
                    } else {
                        this.question._isCorrect = true;
                    }
                }
            },
            deep: true,
        },
        'question._currentTab': {
            handler: function () {
                var that = this;
                if (!this.initialLoad && this.question._currentTab != null) {
                    Vue.nextTick(function () {
                        $(`#${that.question.uuid}-${that.question._currentTab} textarea`).focus();
                    });
                }
                this.initialLoad = false;
            },
        },
    },
    mounted() {
        if (this.question._currentTab == null) {
            this.question._currentTab = 0;
            //Vue.set(this.question, '_currentTab', 0);
            // this.question.isRequired = true;
        }
        if (this.question._isCorrect == null) {
            this.question._isCorrect = false;
            //Vue.set(this.question, '_isCorrect', false);
        }
    },
    methods: {
        isCorrect(tab = 0) {
            let noOfWords = 0;
            if (this.type == 'peer') {
                noOfWords = this.computeNoOfWords(this.question.peers[tab].response);
            } else if (this.type == 'team') {
                noOfWords = this.computeNoOfWords(this.question.teams[tab].response);
            } else if (this.type == 'instructor') {
                noOfWords = this.computeNoOfWords(this.question.instructors[tab].response);
            }
            if ((noOfWords < this.question.minimum && this.question.minimum != 0) || (noOfWords > this.question.maximum && this.question.maximum != null)) {
                return false;
            }
            return true;
        },
        computeNoOfWords(str = '') {
            str = String(str ? str : '');
            var length = str.split(/[^\s]+/).length - 1;
            return length;
        },
        initials(name) {
            return name.charAt(0);
        },
        newTabExpand(newTab) {
            var th_hgt = $('.stickyTop66px').height() + 160;

            $(newTab).collapse('show');

            $('html, body').animate(
                {
                    scrollTop: $(newTab).offset().top - th_hgt,
                },
                1000
            );

            Vue.nextTick(function () {
                $(newTab + ' textarea').focus();
            });
        },
    },
};
</script>
<style scoped lang="scss">
.profile_image.marginRight5 {
    margin-right: 5px;
}
.exTab .nav-pills > li > a {
    width: 250px;
    border-radius: 0;
    padding: 20px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom: 1px solid #fff;
    border: solid 1px #d8d8d8;
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    color: #222;
}

.exTab .nav-pills > li.active > a {
    border: 1px solid #1991eb;
    background-image: none;
    background: #fff;
    border-bottom: none;
    border-top: 3px solid #1991eb;
}

.exTab .tab-content {
    padding: 20px;
    border: 1px solid #1991eb;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background: #fff;
}

.panel-heading.collapsed .fa-chevron-down,
.panel-heading .fa-chevron-right {
    display: none;
}

.panel-heading.collapsed .fa-chevron-right,
.panel-heading .fa-chevron-down {
    display: inline-block;
}

.greyColor {
    color: grey;
}

.badge_profile {
    font-size: 12px;
}
</style>
