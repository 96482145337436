s
<template>
    <div class="dashboardQGroup">
        <template v-for="(question, idx) in testObj.questions[current_question_group_idx]">
            <question :test-obj="testObj" :question="question" :question_group_idx="current_question_group_idx" :question_idx="idx"></question>
        </template>
    </div>
</template>
<script>
export default {
    props: ['testObj'],
    components: {
        question: require(`./question/question.vue`).default,
    },
    data() {
        return {
            current_question_group_idx: 0,
            current_question_idx: 0,
        };
    },
    mounted() {
        var that = this;
        Events.listen('change-question', function (to) {
            that.changeQuestion(to, false);
        });
        this.processEchoListeners();
        Vue.nextTick(function () {
            Events.fire('current-question-changed', {
                question_group_idx: that.current_question_group_idx,
                question_idx: that.current_question_idx,
            });
        });
    },
    beforeUnmount() {
        Events.off('change-question');
    },
    methods: {
        processEchoListeners() {
            let h1 = (e) => {
                this.testObj.eGalleryProgression.currentQuestionUuid = e.eGalleryCurrentQuestionUuid;
                for (var i = 0; i < this.testObj.questions.length; i++) {
                    for (var j = 0; j < this.testObj.questions[i].length; j++) {
                        //get current saved question position
                        if (this.testObj.questions[i][j].uuid == this.testObj.eGalleryProgression.currentQuestionUuid) {
                            this.changeQuestion({ question_group_idx: i, question_idx: j }, false);
                            return;
                        }
                    }
                }
            };
            let c1 = window.Echo.private(`test.${this.testObj.uuid}.teacher`).listen('DiscussionCurrentQuestionUpdated', h1);
            this.echoChannels.push({
                channel: c1,
                event: 'DiscussionCurrentQuestionUpdated',
                handler: h1,
            });

            let h2 = (e) => {
                //console.log(e)
                for (var i = 0; i < this.testObj.questions.length; i++) {
                    for (var j = 0; j < this.testObj.questions[i].length; j++) {
                        //get current saved question position
                        this.testObj.questions[i][j].displayAnswer = e.displayAnswer;
                        this.testObj.questions[i][j].displayAnswerStatistics = e.displayAnswerStatistics;
                    }
                }
            };
            let c2 = window.Echo.private(`test.${this.testObj.uuid}.teacher`).listen('DiscussionQuestionsSyncToggled', h2);
            this.echoChannels.push({
                channel: c2,
                event: 'DiscussionQuestionsSyncToggled',
                handler: h2,
            });
        },
        init() {
            var that = this;
            let currentQuestionUuid = _.get(this.testObj, 'eGalleryProgression.currentQuestionUuid', false);
            if (currentQuestionUuid == null) {
                Vue.nextTick(function () {
                    that.changeQuestion({
                        question_group_idx: 0,
                        question_idx: 0,
                    });
                });
            }
            for (var i = 0; i < this.testObj.questions.length; i++) {
                for (var j = 0; j < this.testObj.questions[i].length; j++) {
                    //get current saved question position
                    if (currentQuestionUuid == this.testObj.questions[i][j].uuid) {
                        var q_group_idx = i;
                        var q_idx = j;
                        Vue.nextTick(function () {
                            that.changeQuestion(
                                {
                                    question_group_idx: q_group_idx,
                                    question_idx: q_idx,
                                },
                                false
                            );
                        });
                    }
                }
            }
        },
        changeQuestion(to, sendToAPI = true) {
            this.current_question_group_idx = to.question_group_idx;
            this.current_question_idx = to.question_idx;
            Events.fire('current-question-changed', {
                question_group_idx: to.question_group_idx,
                question_idx: to.question_idx,
            });
            if (sendToAPI) {
                axios
                    .put('tests/' + this.testObj.uuid + '/discussions/current-question', {
                        currentQuestionUuid: this.testObj.questions[this.current_question_group_idx][this.current_question_idx].uuid,
                    })
                    .then(function (response) {});
            }
        },
    },
};
</script>
<style>
.dashboardQGroup .panel {
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
    border: 1px solid #ededed;
}

.dashboardQGroup .panel-heading {
    background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
    padding: 20px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1;
    border-bottom: 1px solid #d8d8d8;
    box-shadow: 0 2px 4px 0 rgba(202, 202, 202, 0.5);
}

.dashboardQGroup .panel-body {
    overflow: hidden;
    overflow-y: auto;
    white-space: pre-line;
    padding: 15px 20px;
}

.dashboardQGroup .panel-footer {
    padding: 20px;
    border-top: 1px solid #d8d8d8;
    box-shadow: 0 -2px 4px 0 rgba(168, 168, 168, 0.5);
    background: #fff;
}

.dashboardQGroup .panel-body.height100 {
    height: 100%;
}
</style>
