x
<template>
    <div v-if="component_done_loading" class="col-xs-12 right-container">
        <div class="form-div">
            <kr-panel>
                <template #title>
                    <div class="col-xs-6">EDIT TEACHER</div>
                    <!-- <div class="col-xs-6 textAlignRight">
						<div class="hidden-xs hidden-sm">
				             <button id="top-save-btn" class="btn btn-success mainBtn" :disabled="(!canSend) ? true:undefined" @click="submit()">
				                 <i class="fa fa-check"></i>&nbsp;Update
				            </button>
				             <router-link v-if="!isFromAccount" :to="{name:'teachers.view',params:$route.params.id}" tag="button" class="btn btn-default mainBtn" exact>
					        	Back
				            </router-link>
				            <router-link v-else :to="{name:'accounts.view',params:{id:form.model.accountUuid}}" tag="button" class="btn btn-default mainBtn" exact>
				            	Back
				            </router-link>
				        </div>
				        <div class="hidden-md hidden-lg">
				            <button id="top-save-btn" class="btn btn-success mainBtn" :disabled="(!canSend) ? true:undefined" @click="submit()">
				                 <i class="fa fa-check"></i>&nbsp;Update
				            </button>
				            <router-link v-if="!isFromAccount" :to="{name:'teachers.view',params:$route.params.id}" tag="button" class="btn btn-default mainBtn" exact>
					            	<i class="fa fa-caret-left"></i>
				            </router-link>
				            <router-link v-else :to="{name:'accounts.view',params:{id:form.model.accountUuid}}" tag="button" class="btn btn-default mainBtn" exact>
				            	<i class="fa fa-caret-left"></i>
				            </router-link>
				        </div>
			        </div> -->
                </template>
                <template #content>
                    <div class="col-xs-12">
                        <teacher-form :form="form"></teacher-form>
                        <div class="profileBtns buttonGroup">
                            <button
                                id="bottom-save-btn"
                                class="btn btn-success mainBtn"
                                :disabled="!canSend ? true : undefined"
                                aria-label="Update"
                                @click="submit()"
                            >
                                <i class="fa fa-check marginRight8" aria-hidden="true"></i>Update
                            </button>
                            <router-link
                                v-if="!isFromAccount"
                                id="bottom-back-btn"
                                :to="{
                                    name: 'teachers.view',
                                    params: $route.params.id,
                                }"
                                tag="button"
                                class="btn btn-default mainBtn"
                                exact
                                aria-label="Cancel"
                            >
                                Cancel
                            </router-link>
                            <router-link
                                v-else
                                id="bottom-back-btn"
                                :to="{
                                    name: 'accounts.view',
                                    params: { id: teacher.account.uuid },
                                }"
                                tag="button"
                                class="btn btn-default mainBtn"
                                exact
                                aria-label="Cancel"
                            >
                                Cancel
                            </router-link>
                        </div>
                    </div>
                </template>
            </kr-panel>
        </div>
        <!-- <div class="page-title">
	        <div class="col-xs-12 col-md-6" style="margin-bottom:30px;">
	        	<router-link v-if="!isFromAccount":to="{name:'teachers.index'}">Teachers</router-link>
	        	<router-link v-else :to="{name:'accounts.view',params:{id:form.model.accountUuid}}">Account</router-link>
	            / Edit Teacher
	            <h3 style="margin-bottom:0;">Edit Teacher</h3>
	        </div>
    	</div>
    	<div class="clearfix"></div>
    	<div class="col-md-12 form-div">
			<kr-panel>
				<template #title>
					<div class="x_title">
						EDIT Teacher
					</div>	
				</template>
			    <template #content>
			         <teacher-form :form="form" mode="update" :accounts="accounts" :isSetAccount="isSetAccount"></teacher-form>
			    </template>

			    <template slot="footer">
			      <div class="col-xs-12">
			        <button :disabled="(!canSend) ? true:undefined" class="btn pull-right btn-success" type="submit"@click="submit"> Update </button>
			      </div>  
			    </template>
		    </kr-panel>
		</div> -->
    </div>
</template>
<script>
import KrForm from '../../../components/forms/form';
import KrAuth from '../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            auth: auth,
            component_done_loading: false,
            isSetAccount: true,
            isFromAccount: false,
            accounts: [],
            teacher: {},
            form: new KrForm({
                fields: {
                    avatar: '',
                    firstname: '',
                    lastname: '',
                    displayName: '',
                    email: '',
                    roleId: 'default!:3',
                    password: 'sendNull!:false',
                    password_confirmation: 'sendNull!:false',
                    identity: '',
                    canAddTeacher: '',
                    canUseExpressSignIn: '',
                    canViewQuestionBank: '',
                    academicDiscipline: 'sendNull!:false',
                    jobTitle: 'sendNull!:false',
                },
                props: {},
                urls: {
                    put: '/teachers/' + this.$route.params.id,
                },
            }),
        };
    },
    components: {
        'teacher-form': require(`./form-partials/partial.teacher.vue`).default,
    },
    computed: {
        canSend() {
            if (
                (!this.form.errors.any() &&
                    this.form.model.email &&
                    this.form.model.firstname &&
                    this.form.model.lastname &&
                    this.form.model.password &&
                    this.form.model.password_confirmation) ||
                !this.form.model.password
            ) {
                return true;
            }
            return false;
        },
    },
    created() {
        if (this.$route.name == 'accounts.teachers.edit') {
            this.isFromAccount = true;
        }
        this.fetch();
    },
    methods: {
        fetch() {
            var that = this;
            let route = '/teachers';
            if (that.$route.name == 'accounts.teachers.edit') {
                route = '/users';
            }
            axios.get(route + '/' + that.$route.params.id).then(function (user) {
                that.teacher = user.data.data;
                that.form.setModel(user.data.data);
                if (that.$route.name == 'accounts.teachers.edit') {
                    Events.fire('topbar_update', {
                        breadcrumb: [
                            { name: 'Home' },
                            {
                                name: 'Account',
                                link: 'accounts.view',
                                params: { id: user.data.data.account.uuid },
                            },
                            { name: 'Edit Teacher' },
                        ],
                    });
                } else {
                    Events.fire('topbar_update', {
                        breadcrumb: [
                            { name: 'Home' },
                            { name: 'Teachers', link: 'teachers.index' },
                            {
                                name: user.data.data.displayName,
                                link: 'teachers.view',
                                params: { id: user.data.data.uuid },
                            },
                            { name: 'Edit' },
                        ],
                    });
                }
                that.component_done_loading = true;
                // Both requests are now complete
            });
        },
        submit() {
            var that = this;
            this.form.model.displayName = this.form.model.firstname + (this.form.model.lastname ? ' ' + this.form.model.lastname : '');
            this.form.put().then(function (response) {
                if (!response.krFormError) {
                    if (!that.isFromAccount) {
                        that.$router.push({
                            name: 'teachers.view',
                            params: { id: that.$route.params.id },
                        });
                    } else {
                        that.$router.push({
                            name: 'accounts.teachers.view',
                            params: { id: that.$route.params.id },
                        });
                    }
                }
            });
        },
    },
};
</script>
