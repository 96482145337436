<template>
    <div class="create row">
        <div class="col-xs-12">
            <div class="form-group">
                <label class="control-label">Send Invites To <a v-if="a.length > 0" class="link" @click.prevent="clearChoices">Clear Selection</a></label>
                <multiselect
                    v-model="a"
                    :options="options"
                    :multiple="true"
                    group-label="language"
                    placeholder="Search student here"
                    track-by="userUuid"
                    label="displayName"
                    @select="select"
                    @remove="remove"
                >
                    <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                </multiselect>
                <span v-if="form.errors.has('recipients')" class="help-block">
                    <strong>{{ form.errors.get('recipients') }}</strong>
                </span>
            </div>
            <div class="marginTop30">
                <kr-field
                    display="Email Title"
                    :form="form"
                    name="title"
                    :options="{
                        placeholder: 'Activate your InteDashboard student account',
                    }"
                ></kr-field>
            </div>
            <kr-field display="Email Content" :form="form" name="emailContent" :options="{ htmlEditor: true }"></kr-field>
            <div class="width224px marginBottom30">
                <kr-toggle display="Send Invites Immediately" :form="form" name="isInviteNotScheduled"></kr-toggle>
            </div>
            <div v-if="!form.model.isInviteNotScheduled" class="marginBottom30">
                <kr-date display="Schedule Email Date/Time" :form="form" name="scheduledAt" :options="{ time: true }"></kr-date>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect';

export default {
    components: {
        Multiselect,
    },
    props: ['form', 'course', 'new_students', 'all_students'],
    data() {
        return {
            options: [
                {
                    userUuid: -1,
                    displayName: `Select All Students (${this.new_students.concat(this.all_students).length})`,
                },
                {
                    userUuid: -2,
                    displayName: `Select Not Yet Invited Students (${this.getStudents('Not Yet Invited').length})`,
                },
                ...this.new_students.concat(this.all_students),
                /*{
                        language: 'Select All Students',
                        libs: 
                    },
                    {
                        language: 'Select All Uninvited Students',
                        libs: this.getStudents('Invite Sent'),
                    },*/
            ],
            invites: [],
            a: [],
        };
    },
    watch: {
        invites: {
            deep: true,
            handler: function () {
                this.form.model.recipients = [];
                for (var i = 0; i < this.invites.length; i++) {
                    this.form.model.recipients.push(this.invites[i].userUuid);
                }
            },
        },
    },
    methods: {
        clearChoices() {
            this.a = [];
            this.invites = [];
        },
        select(option, id) {
            var that = this;
            if (option.userUuid == -1) {
                Vue.nextTick(function (response) {
                    that.invites = that.new_students.concat(that.all_students);
                    that.a = that.invites;
                });
            } else if (option.userUuid == -2) {
                Vue.nextTick(function (response) {
                    that.invites = that.getStudents('Not Yet Invited');
                    that.a = that.invites;
                });
            } else {
                Vue.nextTick(function (response) {
                    that.invites = that.a;
                    //console.log(that.invites)
                });
            }
        },
        remove(option) {
            let idx = _.findIndex(this.invites, function (o) {
                return o.userUuid == option.userUuid;
            });
            if (idx != -1) {
                this.invites.splice(idx, 1);
            }
        },
        getStudents(status) {
            return _.filter(this.new_students.concat(this.all_students), function (o) {
                return o.status == status;
            });
        },
    },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__input,
.multiselect__single {
    background: transparent;
}

.multiselect {
    min-height: auto !important;
}

.multiselect__tags {
    border: 1px solid #d8d8d8 !important;
    min-height: auto !important;
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9) !important;
}

.multiselect__tag {
    margin-bottom: 0;
}

.multiselect__content-wrapper {
    font-size: 14px;
    color: #222;
}

.multiselect__option--group {
    font-weight: bold;
    background: none;
}

.multiselect__option {
    line-height: 1;
    min-height: auto;
}

.multiselect__option:after {
    line-height: 36px;
}

.width224px {
    width: 224px;
}
</style>
