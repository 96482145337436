<template>
    <div v-if="component_done_loading" :id="question.uuid + '-question'" class="panel">
        <div class="panel-heading flexSpaceBetween">
            <div>Question {{ getQuestionNo(question_group_idx, question_idx) }}</div>
            <div v-if="['mcqs', 'mcqm'].includes(question.type)" class="hidden-xs hidden-sm buttonGroupInline">
                <!-- <button v-if="!question.displayAnswer" class="btn btn-success mainBtn" @click="toggleAnswers()">
          Show Answers&nbsp;<i class="fa fa-eye"></i>
        </button>
        <button v-else class="btn btn-warning mainBtn" @click="toggleAnswers()">
          Hide Answers&nbsp;<i class="fa fa-eye-slash"></i>
        </button> -->
                <div class="switchToggle flexOnly align-items marginRight20">
                    <label for="showCorrectAnswer" class="control-label marginBottom0 marginTop10"> Show Correct Answer </label>
                    <label
                        v-tooltip="{
                            content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                        }"
                        class="form-switch marginLeft8"
                    >
                        <input
                            id="showCorrectAnswer"
                            v-model="question.displayAnswer"
                            type="checkbox"
                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                            @change="toggleAnswers()"
                        />
                        <span role="switch" aria-checked="true" class="slider round" />
                    </label>
                </div>
                <div class="inlineFlex align-items">
                    <label for="showAnswerChoicesStatistic" class="control-label marginBottom0 marginTop10"> Show Answer Choices Statistic </label>
                    <label
                        v-tooltip="{
                            content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                        }"
                        class="form-switch marginLeft8"
                    >
                        <input
                            id="showAnswerChoicesStatistic"
                            v-model="question.displayAnswerStatistics"
                            type="checkbox"
                            :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                            @change="toggleStatistics()"
                        />
                        <span role="switch" aria-checked="true" class="slider round" />
                    </label>
                </div>
                <button class="btn btn-default" arial-label="sync" @click="toggleSync()">
                    <i class="fa fa-refresh marginRight8" aria-hidden="true" />SYNC
                </button>
            </div>
            <div class="hidden-md hidden-lg">
                <div
                    v-tooltip="{
                        content: hasWritePrivilege(testObj.course) == -1 ? readOnlyLabel : '',
                    }"
                    class="dropdown viewDropdown"
                >
                    <button
                        id="viewDropdownMenuButton"
                        class="btn drop-down flex"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        :disabled="hasWritePrivilege(testObj.course) == -1 ? true : undefined"
                        :aria-label="hasWritePrivilege(testObj.course) == -1 ? 'More Actions Button Disabled' : 'More Actions'"
                    >
                        More Actions
                        <i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                    </button>
                    <div class="dropdown-menu dropdown-menu-right width250px padding10" aria-labelledby="viewDropdownMenuButton">
                        <div class="col-xs-12 switchToggle flexSpaceBetween align-items">
                            <label for="showCorrectAnswer" class="control-label margin0"> Show Correct Answer </label>
                            <label class="form-switch marginLeft8">
                                <input id="showCorrectAnswer" v-model="question.displayAnswer" type="checkbox" @change="toggleAnswers()" />
                                <span role="switch" aria-checked="true" class="slider round" />
                            </label>
                        </div>
                        <div class="col-xs-12 switchToggle flexSpaceBetween align-items marginTop20">
                            <label for="showAnswerChoicesStatistic" class="control-label margin0"> Show Answer Choices Statistic </label>
                            <label class="form-switch marginLeft8">
                                <input
                                    id="showAnswerChoicesStatistic"
                                    v-model="question.displayAnswerStatistics"
                                    type="checkbox"
                                    @change="toggleStatistics()"
                                />
                                <span role="switch" aria-checked="true" class="slider round" />
                            </label>
                        </div>

                        <button class="btn btn-default marginLeft0 marginTop20" aria-label="sync" @click="toggleSync()">
                            <i class="fa fa-refresh marginRight8" aria-label="true" />SYNC
                        </button>
                        <!--<div class="col-xs-12 switchToggle flexSpaceBetween align-items marginTop20">
              <label class="control-label">
                <span>SYNC TOGGLE</span>
              </label>
              <label class="switch">
                <input type="checkbox"  v-model="question.isSync"  @change="toggleSync()">
                <span class="slider round"></span>
              </label>
            </div>-->
                    </div>
                </div>
            </div>
        </div>
        <div class="panel-body eGallery-panel">
            <template v-if="question.type == 'mcqs' || question.type == 'mcqm'">
                <mcq :test-obj="testObj" :question="question" :options="options" />
            </template>
            <template v-if="question.type == 'openended'">
                <openended :test-obj="testObj" :question="question" :options="options" />
            </template>
            <template v-if="question.type == 'vsa'">
                <openended :test-obj="testObj" :question="question" :options="options" />
            </template>
        </div>

        <div
            :id="question.uuid + '-enlarge-image-modal'"
            class="modal modal-manual"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'enlargeImageModal-' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'enlargeImageModal-' + question.uuid + '-title'" class="modal-title">
                            <i class="fa fa-arrows marginRight8" aria-hidden="true" />Enlarged Image
                        </h2>
                    </div>
                    <div class="modal-body padding0">
                        <img :key="srcValue" :src="srcValue" width="100%" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { testMixins } from './../../../../../../../../../../mixins/test';
import KrAuth from '../../../../../../../../../../components/auth/auth';
export default {
    mixins: [testMixins],
    props: ['testObj', 'question', 'question_group_idx', 'question_idx'],
    data() {
        let auth = new KrAuth();
        return {
            component_done_loading: false,
            options: { showAnswersStatistics: false, isSync: false },
            auth: auth,
            readOnlyLabel: 'You have read-only access',
            srcValue: '',
        };
    },
    created() {
        this.component_done_loading = true;
        var that = this;
        Vue.nextTick(function () {
            $('#' + that.question.uuid + '-enlarge-image-modal').draggable();
        });
    },
    components: {
        mcq: require(`./partials/mcq.vue`).default,
        openended: require(`./partials/openended.vue`).default,
        vsa: require(`./partials/vsa.vue`).default,
    },
    mounted() {
        window.addEventListener('click', (event) => {
            const isImg = event.target.nodeName === 'IMG';
            if (!isImg) {
                return;
            }

            var questionUUID = event.target.closest('.panel').getAttribute('id').slice(0, 36);
            this.openEnlargeImageModal(event.target.src, questionUUID);
        });
    },
    beforeUnmount() {
        window.removeEventListener('click', (event) => {
            const isImg = event.target.nodeName === 'IMG';
            if (!isImg) {
                return;
            }

            var questionUUID = event.target.closest('.panel').getAttribute('id').slice(0, 36);
            this.openEnlargeImageModal(event.target.src, questionUUID);
        });
    },
    methods: {
        toggleSync() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/sync', {
                    allowDisplayAnswer: that.question.displayAnswer,
                    allowDisplayAnswerStatistics: that.question.displayAnswerStatistics,
                })
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Question answers are now synched',
                    });
                })
                .catch(function (errors) {
                    that.question.isSync = !that.question.isSync;
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to sync question answers at this moment, please try again later',
                    });
                });
        },
        toggleAnswers() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/display-answer', {
                    activityQuestionUuid: this.question.uuid,
                })
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Question answers are now' + (that.question.displayAnswer ? ' shown' : ' hidden.'),
                    });
                })
                .catch(function (errors) {
                    that.question.displayAnswer = !that.question.displayAnswer;
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to show question answers at this moment, please try again later',
                    });
                });
        },
        toggleStatistics() {
            var that = this;
            axios
                .post('tests/' + this.testObj.uuid + '/discussions/display-answer-statistics', { activityQuestionUuid: this.question.uuid })
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Question statistics are now' + (that.question.displayAnswerStatistics ? ' shown' : ' hidden.'),
                    });
                })
                .catch(function (errors) {
                    that.question.displayAnswerStatistics = !that.question.displayAnswerStatistics;
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to show question statistics at this moment, please try again later',
                    });
                });
        },
        openEnlargeImageModal(src, id) {
            if (id == this.question.uuid) {
                $('#' + this.question.uuid + '-enlarge-image-modal').modal('show');

                this.srcValue = src;

                $('#' + this.question.uuid + '-enlarge-image-modal').resizable({
                    handles: 'ne, se, sw, nw',
                });
            }
        },
    },
};
</script>
<style scoped></style>
