<template>
    <kr-panel v-if="component_done_loading" :with-footer="false">
        <template #title>
            <div class="col-xs-12 flexSpaceBetween align-items">
                <div class="col-xs-6">
                    <h2>LTI 1.3 SETTINGS</h2>
                </div>
            </div>
        </template>
        <template #content>
            <div class="col-xs-12 col-md-3 enrollmentSetting w-auto">
                <kr-select
                    class="no-error"
                    display="COURSE CONTEXT"
                    name="context_id"
                    :form="form"
                    :items="contexts"
                    item_value_idx="value"
                    item_desc_idx="description"
                    @changed="updateSettings"
                >
                    <template #option="props">
                        <option :value="null" disabled>Select an Option</option>
                        <template v-for="context in props.model">
                            <option :value="context.id">{{ context.title }} - {{ context.label }}</option>
                        </template>
                    </template>
                </kr-select>
            </div>
        </template>
    </kr-panel>
</template>
<script>
import KrForm from '../../../../components/forms/form';
import KrAuth from '../../../../components/auth/auth';
export default {
    props: {
        course: {
            type: Object,
            required: true,
        },
    },
    data() {
        var auth = new KrAuth();
        return {
            auth: auth,
            component_done_loading: false,
            form: new KrForm({
                fields: {
                    context_id: '',
                },
            }),
            contexts: [],
        };
    },
    created() {
        var that = this;
        this.form.model.context_id = this.course.context_id;
        axios.get(`/lti/1p3/courses/${this.course.uuid}/contexts`).then(function (response) {
            that.contexts = response.data.data;
            that.component_done_loading = true;
        });
    },
    methods: {
        updateSettings() {
            var that = this;
            axios
                .post(`/lti/1p3/courses/${this.course.uuid}/contexts`, {
                    context_id: this.form.model.context_id,
                })
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Context has been updated',
                    });
                })
                .catch(function (error) {
                    (that.form.model.context_id = null),
                        that.$notify({
                            group: 'form',
                            type: 'error',
                            title: 'Error',
                            text: 'Something went wrong, please try again later ',
                        });
                });
        },
    },
};
</script>
