<template>
    <!--PUT HTML HERE-->
    <div>
        <div class="col-xs-12 right-container">
            <!-- <div class="stepsNaviFixed nav-tabsContainer">
                <div class="links">
                    <ul>
                        <li class="active">
                            <a
                                id="question-tab"
                                class="nav-link"
                                data-toggle="tab"
                                href="#question"
                                role="tab"
                                aria-controls="question"
                                aria-selected="true"
                            >
                                Questions
                            </a>
                        </li>
                    </ul>
                </div>
            </div> -->

            <div class="tab-content">
                <div id="question" class="tab-pane fade show active in" role="tabpanel" aria-labelledby="question-tab">
                    <div class="pageTitle width100" tabindex="0">
                        <h2>Questions</h2>
                        <p class="col-md-6">
                            Questions are the foundation for tests and exercises. In this space, you will be able to create new questions for your activities,
                            import questions using simple templates and filter/view the questions you have already stored in your question bank.
                            <a href="https://help.intedashboard.com/how-do-i-add-questions-to-an-activity-using-the-question-bank-method" target="newTab">
                                <i class="fas fa-info-circle" aria-hidden="true" />
                                Basic Information on the InteDashboard Question Bank Feature
                            </a>
                        </p>
                    </div>
                    <div class="flex questionIndex">
                        <div class="col-xs-12 col-md-3">
                            <kr-panel :with-footer="false">
                                <template #title>
                                    <div class="flex flexSpaceBetween width100">
                                        <div>
                                            <label> Filter By </label>
                                        </div>

                                        <div class="textAlignRight">
                                            <button
                                                class="btn btn-default"
                                                @click.prevent="
                                                    query_fields.q = '';
                                                    query_fields.type = '';
                                                    query_fields.topics = '';
                                                    query_fields.difficultyLevel = '';
                                                    query_fields.creatorUuid = '';
                                                    search();
                                                "
                                            >
                                                Clear All
                                            </button>
                                        </div>
                                    </div>
                                </template>
                                <template #content>
                                    <div class="questionFilterColumn">
                                        <div class="form-group form-search has-feedback has-feedback-left">
                                            <input
                                                v-model="query_fields.q"
                                                type="text"
                                                class="form-control"
                                                placeholder="Search..."
                                                @keyup="debounced_search"
                                            />
                                            <i class="fa fa-search form-control-feedback" aria-hidden="true" />
                                        </div>
                                        <div class="form-group">
                                            <label class="control-label"> Type </label>
                                            <!-- <div class="form-dropdown">
                                                <select
                                                    v-model="query_fields.type"
                                                    class="form-control"
                                                    :aria-label="
                                                        query_fields.type
                                                    "
                                                    @change="search"
                                                >
                                                    <option value="">
                                                        All Types of Question
                                                    </option>
                                                    <template
                                                        v-for="(
                                                            type, idx
                                                        ) in filters.types"
                                                    >
                                                        <option
                                                            :key="idx"
                                                            :value="type"
                                                        >
                                                            <template
                                                                v-if="
                                                                    type ==
                                                                    'mcqs'
                                                                "
                                                            >
                                                                MCQ Single
                                                                Answer
                                                            </template>
                                                            <template
                                                                v-else-if="
                                                                    type ==
                                                                    'mcqm'
                                                                "
                                                            >
                                                                MCQ Multiple
                                                                Answer
                                                            </template>
                                                            <template
                                                                v-else-if="
                                                                    type ==
                                                                    'openended'
                                                                "
                                                            >
                                                                Free Response
                                                                Answer
                                                            </template>
                                                            <template
                                                                v-else-if="
                                                                    type ==
                                                                    'rating'
                                                                "
                                                            >
                                                                Rating
                                                            </template>
                                                            <template
                                                                v-else-if="
                                                                    type ==
                                                                    'vsa'
                                                                "
                                                            >
                                                                VSA
                                                            </template>
                                                        </option>
                                                    </template>
                                                </select>
                                            </div> -->
                                            <div class="dropdown new-custom-search-select d-block">
                                                <button
                                                    id="viewDropdownMenuButton"
                                                    class="btn"
                                                    type="button"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <template v-if="query_fields.type">
                                                        <template v-if="query_fields.type == 'mcqs'"> MCQ Single Answer </template>
                                                        <template v-else-if="query_fields.type == 'mcqm'"> MCQ Multiple Answers </template>
                                                        <template v-else-if="query_fields.type == 'openended'"> Free Response </template>
                                                        <template v-else-if="query_fields.type == 'rating'"> Rating </template>
                                                        <template v-else-if="query_fields.type == 'vsa'"> VSA (Very Short Answers) </template>
                                                    </template>
                                                    <template v-else> Select question type </template>
                                                    <i class="fa fa-caret-down marginLeft8" aria-hidden="true"></i>
                                                </button>
                                                <div
                                                    class="dropdown-menu dropdown-menu-right dropdown-menu-new width100"
                                                    aria-labelledby="viewDropdownMenuButton"
                                                >
                                                    <ul role="listbox" class="maxHeight255px overflowAuto">
                                                        <li role="option" class="paddingTop10 paddingBottom10 withHover">
                                                            <button
                                                                @click="
                                                                    query_fields.type = '';
                                                                    search();
                                                                "
                                                            >
                                                                Select question type
                                                            </button>
                                                        </li>
                                                        <li v-for="(type, idx) in filters.types" role="option" class="paddingTop10 paddingBottom10 withHover">
                                                            <button
                                                                @click="
                                                                    query_fields.type = type;
                                                                    search();
                                                                "
                                                            >
                                                                <template v-if="type == 'mcqs'"> MCQ Single Answer </template>
                                                                <template v-else-if="type == 'mcqm'"> MCQ Multiple Answers </template>
                                                                <template v-else-if="type == 'openended'"> Free Response </template>
                                                                <template v-else-if="type == 'rating'"> Rating </template>
                                                                <template v-else-if="type == 'vsa'"> VSA (Very Short Answers) </template>
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="flex flexSpaceBetween marginBottom10 align-items">
                                                <label class="control-label marginBottom0 paddingTop5"> Creator </label>
                                                <div class="checkboxField">
                                                    <label for="showOnlyMine-questionBank" class="marginRight8"> Show only mine </label>
                                                    <input
                                                        id="showOnlyMine-questionBank"
                                                        v-model="query_fields.creatorUuid"
                                                        class="marginRight0"
                                                        type="checkbox"
                                                        :true-value="auth.user().uuid"
                                                        false-value=""
                                                        @change="search()"
                                                    />
                                                </div>
                                            </div>
                                            <!-- <div class="form-dropdown">
                                                <select
                                                    class="form-control"
                                                    :value="
                                                        query_fields.creatorUuid
                                                    "
                                                    :aria-label="
                                                        query_fields.creatorUuid
                                                    "
                                                    @change="
                                                        changeCreator($event)
                                                    "
                                                >
                                                    <option value="">
                                                        Select creator
                                                    </option>
                                                    <template
                                                        v-for="(
                                                            creator, idx
                                                        ) in filters.creators"
                                                    >
                                                        <option
                                                            :key="idx"
                                                            :value="
                                                                creator.uuid
                                                            "
                                                        >
                                                            {{ creator.name }}
                                                        </option>
                                                    </template>
                                                </select>
                                            </div> -->

                                            <div class="dropdown new-custom-search-select d-block">
                                                <button
                                                    id="viewDropdownMenuButton"
                                                    class="btn"
                                                    type="button"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <template v-if="query_fields.creatorUuid">
                                                        {{ creatorName }}
                                                    </template>
                                                    <template v-else> Select creator </template>
                                                    <i class="fa fa-caret-down marginLeft8" aria-hidden="true"></i>
                                                </button>
                                                <div
                                                    class="dropdown-menu dropdown-menu-right dropdown-menu-new width100"
                                                    aria-labelledby="viewDropdownMenuButton"
                                                >
                                                    <ul role="listbox" class="maxHeight255px overflowAuto">
                                                        <li role="option" class="paddingTop10 paddingBottom10 withHover">
                                                            <button
                                                                @click="
                                                                    query_fields.creatorUuid = '';
                                                                    search();
                                                                "
                                                            >
                                                                Select creator
                                                            </button>
                                                        </li>
                                                        <li
                                                            v-for="(creator, idx) in filters.creators"
                                                            role="option"
                                                            class="paddingTop10 paddingBottom10 withHover"
                                                        >
                                                            <button
                                                                @click="
                                                                    query_fields.creatorUuid = creator.uuid;
                                                                    search();
                                                                "
                                                            >
                                                                {{ creator.name }}
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="control-label"> Topic </label>
                                            <!-- <div class="form-dropdown">
                                                <select
                                                    v-model="
                                                        query_fields.topics
                                                    "
                                                    class="form-control"
                                                    :aria-label="
                                                        query_fields.topics
                                                    "
                                                    @change="search"
                                                >
                                                    <option value="">
                                                        Select topic
                                                    </option>
                                                    <template
                                                        v-for="(
                                                            topic, idx
                                                        ) in filters.topics"
                                                    >
                                                        <option
                                                            :key="idx"
                                                            :value="topic"
                                                        >
                                                            {{ topic }}
                                                        </option>
                                                    </template>
                                                </select>
                                            </div> -->
                                            <div class="dropdown new-custom-search-select d-block">
                                                <button
                                                    id="viewDropdownMenuButton"
                                                    class="btn"
                                                    type="button"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <template v-if="query_fields.topics">
                                                        {{ query_fields.topics }}
                                                    </template>
                                                    <template v-else> Select topic </template>
                                                    <i class="fa fa-caret-down marginLeft8" aria-hidden="true"></i>
                                                </button>
                                                <div
                                                    class="dropdown-menu dropdown-menu-right dropdown-menu-new width100"
                                                    aria-labelledby="viewDropdownMenuButton"
                                                >
                                                    <ul role="listbox" class="maxHeight255px overflowAuto">
                                                        <li role="option" class="paddingTop10 paddingBottom10 withHover">
                                                            <button
                                                                @click="
                                                                    query_fields.topics = '';
                                                                    search();
                                                                "
                                                            >
                                                                Select topic
                                                            </button>
                                                        </li>
                                                        <li v-for="(topic, idx) in filters.topics" role="option" class="paddingTop10 paddingBottom10 withHover">
                                                            <button
                                                                @click="
                                                                    query_fields.topics = topic;
                                                                    search();
                                                                "
                                                            >
                                                                {{ topic }}
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="control-label"> Difficulty </label>
                                            <!-- <div class="form-dropdown">
                                                <select
                                                    v-model="
                                                        query_fields.difficultyLevel
                                                    "
                                                    class="form-control"
                                                    :aria-label="
                                                        query_fields.difficultyLevel
                                                    "
                                                    @change="search"
                                                >
                                                    <option value="">
                                                        Select difficulty level
                                                    </option>
                                                    <template
                                                        v-for="(
                                                            difficultyLevel, idx
                                                        ) in filters.difficultyLevels"
                                                    >
                                                        <option
                                                            :key="idx"
                                                            :value="
                                                                difficultyLevel
                                                            "
                                                        >
                                                            {{
                                                                difficultyLevel
                                                            }}
                                                        </option>
                                                    </template>
                                                </select>
                                            </div> -->
                                            <div class="dropdown new-custom-search-select d-block">
                                                <button
                                                    id="viewDropdownMenuButton"
                                                    class="btn"
                                                    type="button"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <template v-if="query_fields.difficultyLevel">
                                                        {{ query_fields.difficultyLevel }}
                                                    </template>
                                                    <template v-else> Select difficulty level </template>
                                                    <i class="fa fa-caret-down marginLeft8" aria-hidden="true"></i>
                                                </button>
                                                <div
                                                    class="dropdown-menu dropdown-menu-right dropdown-menu-new width100"
                                                    aria-labelledby="viewDropdownMenuButton"
                                                >
                                                    <ul role="listbox" class="maxHeight255px overflowAuto">
                                                        <li role="option" class="paddingTop10 paddingBottom10 withHover">
                                                            <button
                                                                @click="
                                                                    query_fields.difficultyLevel = '';
                                                                    search();
                                                                "
                                                            >
                                                                Select difficulty level
                                                            </button>
                                                        </li>
                                                        <li
                                                            v-for="(difficultyLevel, idx) in filters.difficultyLevels"
                                                            role="option"
                                                            class="paddingTop10 paddingBottom10 withHover"
                                                        >
                                                            <button
                                                                @click="
                                                                    query_fields.difficultyLevel = difficultyLevel;
                                                                    search();
                                                                "
                                                            >
                                                                {{ difficultyLevel }}
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div>
                                            <button
                                                class="btn btn-primary mainBtn"
                                                aria-label="Apply Filter"
                                                @click.prevent="search"
                                            >
                                                Apply Filter
                                            </button>
                                            <button
                                                class="btn btn-default mainBtn"
                                                aria-label="Clear All"
                                                @click.prevent="
                                                    query_fields.q = '';
                                                    query_fields.type = '';
                                                    query_fields.topics = '';
                                                    query_fields.difficultyLevel =
                                                        '';
                                                    query_fields.creatorUuid =
                                                        '';
                                                    search();
                                                "
                                            >
                                                Clear All
                                            </button>
                                        </div> -->
                                    </div>
                                </template>
                            </kr-panel>
                        </div>
                        <div class="questionIndexPanel cardsCon col-xs-12 col-md-9">
                            <kr-panel :with-footer="false">
                                <template #title>
                                    <div class="col-xs-6">Question Bank ({{ questionSearchData.meta.paginator.total }})</div>
                                    <div class="col-xs-6 flexRight">
                                        <div class="col-xs-4 buttonGroup">
                                            <div class="hidden-xs hidden-sm">
                                                <!--<button
                                                    class="btn btn-success mainBtn"
                                                    :disabled="selectedQuestions.length == 0 ? true : undefined"
                                                    aria-label="exportQuestions"
                                                    @click.prevent="exportQuestions"
                                                >
                                                    <i class="fa fa-file-export marginRight8" aria-hidden="true" />Export Questions ({{
                                                        selectedQuestions.length
                                                    }})
                                                </button>-->
                                                <button
                                                    class="btn btn-primary mainBtn"
                                                    :disabled="auth.isImpersonation() ? true : undefined"
                                                    aria-label="add New Question"
                                                    @click.prevent="createQuestion()"
                                                >
                                                    <i class="fa fa-plus marginRight8" aria-hidden="true" />New Question
                                                </button>
                                                <!--<button class="btn btn-primary" @click="downloadQuestions" aria-label="Export">
										<i class="fa fa-download"></i>&nbsp;Export
									</button>-->
                                            </div>
                                            <div class="hidden-md hidden-lg">
                                                <!--<button
                                                    class="btn btn-success mainBtn"
                                                    :disabled="selectedQuestions.length == 0 ? true : undefined"
                                                    aria-label="exportQuestions"
                                                    @click.prevent="exportQuestions"
                                                >
                                                    <i
                                                        class="fa fa-file-export marginRight8"
                                                        aria-hidden="true"
                                                    />Questions ({{
                                                        selectedQuestions.length
                                                    }})
                                                </button>-->
                                                <button
                                                    class="btn btn-primary mainBtn"
                                                    :disabled="auth.isImpersonation() ? true : undefined"
                                                    aria-label="add New Question"
                                                    @click.prevent="createQuestion()"
                                                >
                                                    <i class="fa fa-plus marginRight8" aria-hidden="true" />Question
                                                </button>
                                                <!--<button class="btn btn-primary" @click="downloadQuestions" aria-label="Export">
										<i class="fa fa-download"></i>
									</button>
									-->
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template #content>
                                    <div
                                        class="flexSpaceBetween marginBottom30 align-items paddingLeft20"
                                    >
                                        <!--<div
                                            v-if="!viewSelectedInQuestionBank"
                                            class="checkboxField flex align-items"
                                        >
                                            <input
                                                id="selectAll"
                                                type="checkbox"
                                                aria-label="Select All"
                                                @click="selectAllQuestions()"
                                            />
                                            <label for="selectAll">
                                                Select All
                                            </label>
                                        </div>-->
                                        <div>
                                            <!--<button
                                                v-if="
                                                    !viewSelectedInQuestionBank
                                                "
                                                class="btn btn-primary subBtn"
                                                :disabled="selectedQuestions.length == 0 ? true : undefined"
                                                @click.prevent="viewSelectedInQuestionBank = true"
                                            >
                                                Only Show Selected ({{ selectedQuestions.length }})
                                            </button>
                                            <button
                                                v-else
                                                class="btn btn-default subBtn"
                                                aria-label="Back to Question Bank"
                                                @click.prevent="viewSelectedInQuestionBank = false"
                                            >
                                                Back to Question Bank
                                            </button>-->
                                        </div>
                                    </div>
                                    <kr-search
                                        name="questions_listing"
                                        :url="{ url: '/questions/bank' }"
                                        :options="{
                                            per_page: 12,
                                            page: pagination.page,
                                            query_fields: query_fields,
                                            newServerMode: {
                                                includes: ['q', 'isSuspended', 'sort', 'order', 'creatorUuid', 'type', 'topics', 'difficultyLevel'],
                                            },
                                        }"
                                        @retrieveData="getQuestionData"
                                    >
                                        <template #listing-top>
                                            <div class="displayNone">&nbsp;</div>
                                        </template>
                                        <template #content="props">
                                            <div v-if="!viewSelectedInQuestionBank" class="flex" role="group">
                                                <template v-if="questionSearchData.data.data.data.length == 0">
                                                    <div class="paddingLeft20">
                                                        <p>No questions match your search / filter</p>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <template v-for="question in questionSearchData.data.data.data" :key="question.uuid">
                                                        <div class="cardCons-question-bank">
                                                            <div class="card">
                                                                <div class="card-header flex">
                                                                    <div v-if="question._isCopied" class="right-corner-triangle">Copy</div>

                                                                    <div>
                                                                        <i class="fa fa-list-ul marginRight8" />
                                                                        <template v-if="question.type == 'mcqs'"> MCQ Single Answer </template>
                                                                        <template v-if="question.type == 'mcqm'"> MCQ Multiple Answers </template>
                                                                        <template v-if="question.type == 'openended'"> Free Response </template>
                                                                        <template v-if="question.type == 'rating'"> Rating </template>
                                                                        <template v-if="question.type == 'vsa'"> VSA </template>
                                                                    </div>
                                                                </div>
                                                                <div class="card-body" @click.prevent="openQuestionInfoModal(question)">
                                                                    <div :id="'questionStem_' + question.uuid" class="question-area col-xs-11 col-md-10">
                                                                        <label class="control-label fs-14px"> Question Stem </label>

                                                                        <template v-if="fontResized == 'small'">
                                                                            <kr-math
                                                                                :input="truncate(question.question, 160)"
                                                                                :safe="truncate(!question.questionIsHTML, 160)"
                                                                            />
                                                                        </template>

                                                                        <template v-else-if="fontResized == 'normal'">
                                                                            <kr-math
                                                                                :input="truncate(question.question, 80)"
                                                                                :safe="truncate(!question.questionIsHTML, 80)"
                                                                            />
                                                                        </template>

                                                                        <template v-else-if="fontResized == 'large'">
                                                                            <kr-math
                                                                                :input="truncate(question.question, 60)"
                                                                                :safe="truncate(!question.questionIsHTML, 60)"
                                                                            />
                                                                        </template>
                                                                    </div>
                                                                    <div class="moreLink col-xs-1 col-md-2">
                                                                        <button aria-label="show more of " :aria-describedby="'questionStem_' + question.uuid">
                                                                            <div class="custom-arrow-icon">
                                                                                <div class="custom-arrow-icon-top"></div>
                                                                                <div class="custom-arrow-icon-bottom"></div>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div class="card-footer">
                                                                    <div class="flexSpaceBetween">
                                                                        <div>
                                                                            <button
                                                                                class="btn primary-text"
                                                                                data-toggle="modal"
                                                                                data-target=".showMoreDetailModal"
                                                                                @click.prevent="openShowMoreDetailModal(question)"
                                                                            >
                                                                                Details
                                                                            </button>
                                                                        </div>
                                                                        <div v-if="!auth.isImpersonation()" class="dropdown">
                                                                            <button
                                                                                class="vertical6DotsButton paddingTop5"
                                                                                data-toggle="dropdown"
                                                                                aria-haspopup="true"
                                                                                aria-expanded="true"
                                                                            >
                                                                                <i class="fas fa-grip-vertical" aria-hidden="true" />
                                                                            </button>

                                                                            <div
                                                                                class="dropdown-menu dropdown-menu-new dropdown-menu-right"
                                                                                aria-labelledby="dropdownMenuButton"
                                                                            >
                                                                                <ul>
                                                                                    <li>
                                                                                        <button
                                                                                            class="buttonRole primary-state"
                                                                                            @click.prevent="openCopyQuestionModal(question)"
                                                                                        >
                                                                                            <i class="material-symbols-outlined" aria-hidden="true">
                                                                                                content_copy
                                                                                            </i>
                                                                                            Make a Copy
                                                                                        </button>
                                                                                    </li>

                                                                                    <li>
                                                                                        <button
                                                                                            class="buttonRole primary-state"
                                                                                            @click.prevent="editQuestion(question)"
                                                                                        >
                                                                                            <i class="fas fa-edit" aria-hidden="true"> </i>
                                                                                            Edit
                                                                                        </button>
                                                                                    </li>

                                                                                    <hr />

                                                                                    <li>
                                                                                        <button
                                                                                            class="buttonRole danger-state"
                                                                                            @click.prevent="openDeleteQuestionModal(question)"
                                                                                        >
                                                                                            <i class="fa fa-trash" aria-hidden="true" />
                                                                                            Delete
                                                                                        </button>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="cardBtns"
                                                            >
                                                                <!--<button
                                                                    v-if="
                                                                        isQuestionSelected(
                                                                            question
                                                                        )
                                                                    "
                                                                    v-tooltip="{
                                                                        content:
                                                                            'Remove Selection',
                                                                    }"
                                                                    class="btn btn-danger mainBtn"
                                                                    aria-label="Delete"
                                                                    @click.prevent="
                                                                        deleteFromSelectedQuestions(
                                                                            question
                                                                        )
                                                                    "
                                                                >
                                                                    <i
                                                                        class="fa fa-times"
                                                                        aria-hidden="true"
                                                                    />
                                                                </button>
                                                                <button
                                                                    v-if="
                                                                        !isQuestionSelected(
                                                                            question
                                                                        )
                                                                    "
                                                                    v-tooltip="{
                                                                        content:
                                                                            'Add Selection',
                                                                    }"
                                                                    class="btn btn-default mainBtn"
                                                                    aria-label="Add"
                                                                    @click.prevent="
                                                                        addToSelectedQuestions(
                                                                            question
                                                                        )
                                                                    "
                                                                >
                                                                    <i
                                                                        class="fa fa-plus"
                                                                        aria-hidden="true"
                                                                    />
                                                                </button>-->
                                                            </div>
                                                        </div>
                                                    </template>
                                                </template>
                                            </div>
                                            <div v-else class="flex">
                                                <template v-for="question in selectedQuestions" :key="question.uuid">
                                                    <div class="cardCons">
                                                        <div class="card">
                                                            <div class="card-header flex">
                                                                <div class="col-xs-8" style="text-transform: uppercase">
                                                                    {{ question.topic }}
                                                                </div>
                                                                <div class="col-xs-4 alignRight">
                                                                    Level
                                                                    {{ question.difficultyLevel }}
                                                                </div>
                                                            </div>
                                                            <div class="card-body">
                                                                <div class="card-body-cons">
                                                                    <kr-math
                                                                        :input="truncate(question.question, 50)"
                                                                        :safe="truncate(!question.questionIsHTML, 50)"
                                                                    />
                                                                    <div>More<i class="fa fa-caret-down marginLeft8" aria-hidden="true" /></div>
                                                                </div>
                                                            </div>
                                                            <div class="card-footer">
                                                                <div>
                                                                    <i class="fa fa-list-ul" />&nbsp;&nbsp;
                                                                    <template v-if="question.type == 'mcqs'"> MCQ Single Answer </template>
                                                                    <template v-if="question.type == 'mcqm'"> MCQ Multiple Answers </template>
                                                                    <template v-if="question.type == 'openended'"> Free Response </template>
                                                                    <template v-if="question.type == 'rating'"> Rating </template>
                                                                </div>
                                                                <div><i class="fas fa-pen-alt marginRight8" aria-hidden="true" />{{ question.createBy }}</div>
                                                            </div>

                                                            <div
                                                                class="cardBtns"
                                                            >
                                                                <!--<button
                                                                    v-if="
                                                                        isQuestionSelected(
                                                                            question
                                                                        )
                                                                    "
                                                                    v-tooltip="{
                                                                        content: 'Remove Selection',
                                                                    }"
                                                                    class="btn btn-danger mainBtn"
                                                                    aria-label="Delete"
                                                                    @click.prevent="deleteFromSelectedQuestions(question)"
                                                                >
                                                                    <i class="fa fa-times" aria-hidden="true" />
                                                                </button>
                                                                <button
                                                                    v-if="!isQuestionSelected(question)"
                                                                    class="btn btn-default mainBtn"
                                                                    aria-label="Add"
                                                                    @click.prevent="addToSelectedQuestions(question)"
                                                                >
                                                                    <i
                                                                        class="fa fa-plus"
                                                                        aria-hidden="true"
                                                                    />
                                                                </button>-->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                        </template>
                                        <template #pagination="props">
                                            <div v-if="viewSelectedInQuestionBank">&nbsp;</div>
                                            <div v-else class="paginationCons flex align-items marginTop10 paddingLeft20 paddingBottom20">
                                                <div
                                                    class="col-xs-12"
                                                    :class="{
                                                        marginBottom10: props.model.meta.paginator.current_page > 1,
                                                    }"
                                                >
                                                    <div class="displayNo">
                                                        {{ props.model.from }} - {{ props.model.to }} of
                                                        {{ props.model.meta.paginator.total }}
                                                    </div>
                                                </div>
                                                <div class="col-xs-12 buttonGroupInline">
                                                    <!-- <button class="btn" v-if="props.model.meta.paginator.current_page>2" :disabled="(props.model.meta.paginator.current_page==1) ? true:undefined" @click.prevent="pagination.page =1">
												First
											</button> -->
                                                    <div v-if="props.model.meta.paginator.current_page > 2" class="width36px marginRight20">
                                                        <button
                                                            class="btn btn-default mainBtn padding16px width100"
                                                            :disabled="props.model.meta.paginator.current_page == 1 ? true : undefined"
                                                            aria-label="Back"
                                                            @click.prevent="pagination.page = 1"
                                                        >
                                                            <i class="fas fa-backward" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                    <!-- <button class="btn" v-if="props.model.meta.paginator.current_page>1" :disabled="(props.model.meta.paginator.current_page-1<1) ? true:undefined" @click.prevent="pagination.page = props.model.meta.paginator.current_page-1">
												<i class="fa fa-caret-left"></i>
												&nbsp;Previous
											</button> -->
                                                    <div v-if="props.model.meta.paginator.current_page > 1" class="width36px">
                                                        <button
                                                            class="btn btn-default mainBtn padding16px width100"
                                                            :disabled="props.model.meta.paginator.current_page - 1 < 1 ? true : undefined"
                                                            aria-label="Back"
                                                            @click.prevent="pagination.page = props.model.meta.paginator.current_page - 1"
                                                        >
                                                            <i class="fa fa-caret-left" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                    <ul
                                                        v-if="props.model.meta.paginator.current_page == 1"
                                                        class="pagination text-listing-pagination marginLeft0"
                                                    >
                                                        <template v-for="page in props.model.pagination">
                                                            <li
                                                                v-if="page != '...'"
                                                                :key="page.uuid"
                                                                :class="{
                                                                    active: page == props.model.meta.paginator.current_page,
                                                                }"
                                                            >
                                                                <a :aria-label="page" @click.prevent="pagination.page = page">{{ page }}</a>
                                                            </li>
                                                            <li v-else :key="page.uuid">
                                                                <a @click.prevent="">{{ page }}</a>
                                                            </li>
                                                        </template>
                                                    </ul>
                                                    <ul v-if="props.model.meta.paginator.current_page > 1" class="pagination text-listing-pagination">
                                                        <template v-for="page in props.model.pagination">
                                                            <li
                                                                v-if="page != '...'"
                                                                :key="page.uuid"
                                                                :class="{
                                                                    active: page == props.model.meta.paginator.current_page,
                                                                }"
                                                            >
                                                                <a :aria-label="page" @click.prevent="pagination.page = page">{{ page }}</a>
                                                            </li>
                                                            <li v-else :key="page.uuid">
                                                                <a @click.prevent="">{{ page }}</a>
                                                            </li>
                                                        </template>
                                                    </ul>
                                                    <div v-if="props.model.meta.paginator.current_page > 1" class="width36px marginLeft20">
                                                        <button
                                                            class="btn btn-default mainBtn padding16px width100"
                                                            :disabled="
                                                                props.model.meta.paginator.current_page + 1 > props.model.meta.paginator.total_pages
                                                                    ? true
                                                                    : undefined
                                                            "
                                                            aria-label="Forward"
                                                            @click.prevent="pagination.page = props.model.meta.paginator.current_page + 1"
                                                        >
                                                            <i class="fa fa-caret-right" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                    <!-- <button class="btn" v-if="props.model.meta.paginator.current_page>1" :disabled="(props.model.meta.paginator.current_page+1 > props.model.meta.paginator.total_pages) ? true:undefined" @click.prevent="pagination.page = props.model.meta.paginator.current_page+1">Next&nbsp;<i class="fa fa-caret-right"></i></button> -->
                                                    <div v-if="props.model.meta.paginator.current_page > 2" class="width36px marginLeft20">
                                                        <button
                                                            class="btn btn-default mainBtn padding16px width100"
                                                            :disabled="
                                                                props.model.meta.paginator.current_page == props.model.meta.paginator.total_pages
                                                                    ? true
                                                                    : undefined
                                                            "
                                                            aria-label="Forward"
                                                            @click.prevent="pagination.page = props.model.meta.paginator.total_pages"
                                                        >
                                                            <i class="fas fa-forward" aria-hidden="true" />
                                                        </button>
                                                    </div>
                                                    <!-- <button class="btn" v-if="props.model.meta.paginator.current_page>2" :disabled="(props.model.meta.paginator.current_page==props.model.meta.paginator.total_pages) ? true:undefined" @click.prevent="pagination.page =props.model.meta.paginator.total_pages">
												Last
											</button> -->
                                                </div>
                                                <!-- <div class="col-xs-12 hidden-md hidden-lg flexSpaceBetween">
											<button v-if="props.model.meta.paginator.current_page>2" :disabled="(props.model.meta.paginator.current_page==1) ? true:undefined" @click.prevent="pagination.page =1">
												<i class="fa fa-backward"></i>
											</button>
											<button v-if="props.model.meta.paginator.current_page>1" :disabled="(props.model.meta.paginator.current_page-1<1) ? true:undefined" @click.prevent="pagination.page = props.model.meta.paginator.current_page-1">
												<i class="fa fa-caret-left"></i>
											</button>
											<ul class="pagination text-listing-pagination">
												<template v-for="page in props.model.pagination">
								            		<li v-if="page!='...'" :class="{active:page==props.model.meta.paginator.current_page}">
								            			<a @click.prevent="pagination.page = page;">{{page}}</a>
								            		</li>
								            		<li v-else>
								            			<a @click.prevent="return false;">{{page}}</a>
								            		</li>
								            	</template>
								            </ul>
											<button v-if="props.model.meta.paginator.current_page>1" :disabled="(props.model.meta.paginator.current_page+1 > props.model.meta.paginator.total_pages) ? true:undefined" @click.prevent="pagination.page = props.model.meta.paginator.current_page+1">
												<i class="fa fa-caret-right"></i>
											</button>
											<button v-if="props.model.meta.paginator.current_page>2" :disabled="(props.model.meta.paginator.current_page==props.model.meta.paginator.total_pages) ? true:undefined" @click.prevent="pagination.page =props.model.meta.paginator.total_pages">
												<i class="fa fa-forward"></i>
											</button>	
										</div> -->
                                            </div>
                                        </template>
                                    </kr-search>
                                </template>
                            </kr-panel>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="deleteQuestionModal"
            class="modal danger-modal deleteQuestionModal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="deleteQuestionModal-title"
        >
            <div class="modal-dialog">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="deleteQuestionModal-title" class="modal-title">Delete Question</h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <img src="/img/warning.png" width="10%" class="warning-icon" alt=" " />

                        <h2 class="margin0 marginTop20">You are deleting this question</h2>
                        <h2 class="margin0">from Question Bank.</h2>
                        <br />

                        <p>Are you sure?</p>
                    </div>

                    <div class="modal-footer bg-white padding20 paddingTop10">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Go Back</button>
                        <button class="btn btn-danger" data-dismiss="modal" @click="remove(previewQuestion.uuid)">Yes, Delete</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="questionInfoModal"
            class="modal questionInfoModal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            aria-labelledby="questionInfoModal-title"
        >
            <div class="modal-dialog maxWidth100 widthAuto paddingLeft20 paddingRight20">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="questionInfoModal-title" class="modal-title">View Question</h2>
                    </div>
                    <div class="modal-body">
                        <question-display :question="previewQuestion" />
                        <div class="col-xs-12 buttonGroup marginTop30">
                            <button
                                class="btn btn-default mainBtn"
                                data-dismiss="modal"
                                :disabled="auth.isImpersonation() ? true : undefined"
                                aria-label="Edit"
                                @click.prevent="editQuestion(previewQuestion)"
                            >
                                <i class="fas fa-edit marginRight8" aria-hidden="true" />Edit
                            </button>
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="copyQuestionModal"
            class="modal default-modal copyQuestionModal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="copyQuestionModal-title"
        >
            <div class="modal-dialog">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal" @click="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="copyQuestionModal-title" class="modal-title">Copy Question</h2>
                    </div>
                    <div class="modal-body">
                        <!-- <img
                            src="/img/warning.png"
                            width="10%"
                            class="warning-icon"
                            alt=" "
                        > -->

                        <h2 class="margin0 marginTop20 textAlignCenter">You are making a copy of this question</h2>
                        <br />

                        <div class="bg-grey padding15">
                            <div class="inlineFlex align-items">
                                <i class="fa fa-list-ul marginRight8" aria-hidden="true" />
                                <template v-if="previewQuestion.type == 'mcqs'"> MCQ Single Answer </template>
                                <template v-if="previewQuestion.type == 'mcqm'"> MCQ Multiple Answers </template>
                                <template v-if="previewQuestion.type == 'openended'"> Free Response </template>
                                <template v-if="previewQuestion.type == 'rating'"> Rating </template>
                                <template v-if="previewQuestion.type == 'vsa'"> VSA </template>
                            </div>
                            <br />
                            <label class="control-label marginTop20"> Question Stem </label>
                            <question-display :question="previewQuestion" />
                        </div>

                        <br />
                        <p class="textAlignCenter">
                            <b>Continue?</b>
                        </p>
                    </div>

                    <div class="modal-footer bg-white padding20 paddingTop10">
                        <button class="btn btn-outline-default" data-dismiss="modal" @click="closeAllModal()">Cancel</button>
                        <button class="btn btn-success" data-dismiss="modal" @click="makeACopy(previewQuestion.uuid)">Make a Copy</button>
                    </div>
                </div>
            </div>
        </div>

        <div
            id="showMoreDetailModal"
            class="modal default-modal showMoreDetailModal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            aria-labelledby="showMoreDetailModal-title"
        >
            <div class="modal-dialog minWidth350px width350px maxWidth350px">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal" @click="closeAllModal()">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 id="showMoreDetailModal-title" class="modal-title">Details</h2>
                    </div>
                    <div class="modal-body">
                        <div class="marginBottom20">
                            <label class="control-label"> Created By </label>
                            <p class="questionBankModalText">
                                {{ previewQuestion.createBy }}
                            </p>
                        </div>

                        <div class="marginBottom20">
                            <label class="control-label"> Difficulty Level </label>
                            <p class="questionBankModalText">Level {{ previewQuestion.difficultyLevel }}</p>
                        </div>

                        <div>
                            <label class="control-label"> Topics </label>

                            <template v-if="topicsLength == 0">
                                <p class="questionBankModalText">No Topic</p>
                            </template>
                            <ul v-else>
                                <li v-for="topic in previewQuestion.topics" class="questionBankModalText">
                                    {{ topic }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <question-cru @created="search" @updated="search" />

        <div
            id="exportKeyModal"
            class="modal default-modal exportKeyModal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exportKeyModal-title"
        >
            <div class="modal-dialog">
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 id="copyQuestionModal-title" class="modal-title">Question export key</h2>
                    </div>
                    <div class="modal-body">
                        <p>{{ exportKey }}</p>
                    </div>

                    <div class="modal-footer bg-white padding20 paddingTop10">
                        <button
                            v-clipboard:copy="exportKey"
                            v-clipboard:success="onCopyClipboard"
                            v-clipboard:error="onErrorClipboard"
                            class="btn btn-primary subBtn hidden-xs hidden-sm"
                            aria-label="Copy Key"
                        >
                            <i class="fa fa-files-o marginRight8" aria-hidden="true" />Copy Key
                        </button>
                        <button class="btn btn-outline-default" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KrAuth from '../../../components/auth/auth';
export default {
    components: {
        'question-display': require(`./partials/question-display.vue`).default,
        'question-cru': require(`./cru.vue`).default,
    },
    data() {
        var auth = new KrAuth();
        return {
            auth: auth,
            isBusy: false,
            previewQuestion: {},
            displayMode: 'summary',
            search_params: {
                type: '',
                q: '',
                page: 1,
                per_page: 20,
                isTrashed: '0',
            },
            debounced_search: null,
            questionSearchData: {
                data: { data: { data: [] } },
                meta: {
                    paginator: { total: 0 },
                    filters: {
                        creators: [],
                        difficultyLevels: [],
                        types: [],
                        topics: [],
                    },
                },
            },
            pagination: { page: 1 },
            query_fields: {
                q: '',
                type: '',
                creatorUuid: '',
                difficultyLevel: '',
                topics: '',
            },
            filters: {
                types: [],
                creators: [],
                topics: [],
                difficultyLevels: [],
            },
            topicsLength: 0,
            activeElementBeforeModal: null,
            // question_options:{type:'create',question:{}}
            fontResized: localStorage.getItem('textSize'),
            selectedQuestions: [],
            exportKey: '',
            selectedAll: false,
            viewSelectedInQuestionBank: false,
        };
    },
    computed: {
        creatorName() {
            if (this.query_fields.creatorUuid) {
                const filter = this.filters.creators.filter((creator) => creator.uuid == this.query_fields.creatorUuid);
                return filter[0].name;
            }
            return false;
        },
    },
    beforeUnmount() {
        $('#questionInfoModal').modal('hide');
    },
    created() {
        Events.fire('topbar_update', {
            title: '<i class="fa fa-list-ul"></i>&nbsp;Questions',
            breadcrumb: [{ name: 'Home' }, { name: 'Library', link: 'questions.index' }],
        });
        var search = function () {
            Events.fire('questions_listing_refresh');
        }.bind(this);
        this.getFilters();
        this.debounced_search = _.debounce(search, 500);

        this.$root.$on('fontResized', (newTextSize) => {
            this.fontResized = newTextSize;
        });
    },
    methods: {
        getFilters() {
            var that = this;
            axios
                .get('/questions/bank/filters')
                .then(function (response) {
                    that.filters = response.data;
                    if (
                        _.findIndex(that.filters.creators, function (o) {
                            return o.uuid == that.auth.user().uuid;
                        }) == -1
                    ) {
                        that.filters.creators.push({
                            uuid: that.auth.user().uuid,
                            name: that.auth.user().name,
                        });
                    }
                })
                .catch(function (errors) {});
        },
        onCopyClipboard(e) {
            this.$notify({
                group: 'form',
                type: 'success',
                title: 'Success',
                text: 'Copied to clipboard',
            });
        },
        onErrorClipboard(e) {
            this.$notify({
                group: 'form',
                type: 'error',
                title: 'Error',
                text: 'Error copying to clipboard',
            });
        },
        selectAllQuestions() {
            let questions = _.get(this.questionSearchData, 'data.data.data', []);

            this.selectedAll = !this.selectedAll;

            if (this.selectedAll) {
                for (var i = 0; i < questions.length; i++) {
                    this.addToSelectedQuestions(questions[i]);
                }
            } else {
                for (var i = 0; i < questions.length; i++) {
                    this.deleteFromSelectedQuestions(questions[i]);
                }
            }
        },
        exportQuestions() {
            var that = this;
            let questionUuids = _.map(this.selectedQuestions, 'uuid');
            axios
                .post('questions/intedashboard-export', {
                    questionUuids: questionUuids,
                })
                .then(function (response) {
                    that.selectedQuestions = [];
                    that.exportKey = response.data.data.key;
                    $('#exportKeyModal').modal('show');
                    that.viewSelectedInQuestionBank = false;
                })
                .catch(function (errors) {
                    console.log(errors);
                });
        },
        //for bank selection methods
        addToSelectedQuestions(question) {
            let idx = _.findIndex(this.selectedQuestions, function (o) {
                return o.uuid == question.uuid;
            });
            if (idx == -1 && !this.isQuestionSelected(question)) {
                this.selectedQuestions.push(question);
            }
        },
        deleteFromSelectedQuestions(question) {
            let idx = _.findIndex(this.selectedQuestions, function (o) {
                return o.uuid == question.uuid;
            });
            if (idx == -1) {
                this.selectedQuestions.push(question);
            }
            this.selectedQuestions.splice(idx, 1);
        },
        //for question bank tagging
        isQuestionSelected(question) {
            let idx = _.findIndex(this.selectedQuestions, function (o) {
                return o.uuid == question.uuid;
            });
            if (idx == -1) {
                return false;
            }
            return true;
        },
        changeCreator(e) {
            this.query_fields.creatorUuid = e.target.value;
            this.search();
        },
        downloadQuestions() {
            var that = this;
            let d = moment().format('YYYY[_]MM[_]DD');
            axios({
                url: 'questions/bank/export',
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Questions_' + d + '.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
        },
        openDeleteQuestionModal(question) {
            this.previewQuestion = question;
            //this.openStartPasswordModal();
            //return;
            $('.deleteQuestionModal').modal('show');
        },
        editQuestion(question) {
            Events.fire('open-question-edit', { question: question });
        },
        createQuestion() {
            Events.fire('open-question-create-select');
        },
        getQuestionData(data) {
            this.questionSearchData = data;
        },
        search() {
            Events.fire('questions_listing_refresh');
        },
        openQuestionInfoModal(question) {
            this.previewQuestion = question;
            $('#questionInfoModal').modal('show');
        },
        remove(id) {
            $('#questionInfoModal').modal('hide');
            var success = function () {
                var that = this;
                this.isBusy = false;
                Events.fire('questions_listing_refresh');
            }.bind(this);
            var del = function () {
                this.isBusy = true;
                axios.delete('/questions/' + id).then(function (response) {
                    success();
                });
            }.bind(this);
            del();
        },
        truncate(str, characters = 10) {
            return _.truncate(str, {
                length: characters,
                omission: '...',
            });
        },
        openCopyQuestionModal(question) {
            this.previewQuestion = question;
            $('.copyQuestionModal').modal('show');
        },
        makeACopy(id) {
            var that = this;
            axios
                .post(`/questions/${id}/duplicate`)
                .then(function (response) {
                    if (_.get(that.questionSearchData, 'data.data.data')) {
                        response.data.data._isCopied = true;
                        that.questionSearchData.data.data.data.unshift(response.data.data);
                    }
                    //Events.fire('questions_listing_refresh');
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Question has been successfully copied',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to copy question at this moment, please try again later',
                    });
                });

            $('.copyQuestionModal').modal('hide');
        },
        openShowMoreDetailModal(question) {
            this.previewQuestion = question;
            this.topicsLength = question.topics.length;
            // $('.showMoreDetailModal').modal('show');
        },
    },
};
</script>

<style scoped lang="scss">
.questionFilterColumn > div {
    margin-bottom: 20px;
}

.questionFilterColumn > div:last-child {
    margin-bottom: 0;
}

.questionFilterColumn .btn {
    margin-left: 0;
    width: 100%;
}

.questionFilterColumn .btn:first-child {
    margin-bottom: 10px;
}

.cardCons {
    margin: 0 0 20px 20px;
    width: calc(100% - 20px);

    font-size: inherit;
    text-align: inherit;
    font-weight: inherit;
    padding: inherit;
    height: 100%;

    .card {
        border-radius: 3px;
        background-color: #ffffff;
        border: 1px solid #d8d8d8;
        box-shadow: 4px 4px 4px 0 rgba(202, 202, 202, 0.5);
        position: relative;
    }

    .card-header {
        background-color: #495060;
        color: #ffffff;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 10px 15px;
        line-height: 1;

        div {
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .card-body {
        & > div {
            padding: 10px 15px;
            height: 80px;

            div:first-child {
                max-height: 72%;
                overflow: hidden;
            }

            div:last-child {
                line-height: 1;
                font-weight: bold;
                font-size: 12px;
                margin-top: 5px;
            }
        }
    }

    .card-footer {
        background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
        border-top: 1px solid #d8d8d8;
        box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
        padding: 15px;
        color: #767676;
        font-size: 12px;

        .fa {
            font-size: 10px;
            width: 10px;
            height: 10px;

            &.fontSize14 {
                font-size: 14px;
                width: inherit;
                height: inherit;
            }
        }
    }

    &:hover {
        cursor: pointer;

        .card {
            border: 1px solid #1991eb;
        }

        .card-footer {
            border-top: 1px solid #1991eb;
        }
    }
}
.cardBtns button {
    border-radius: 100px;
    padding: 6px 8.93px;
}

.cardBtns {
    position: absolute;
    right: 15px;
    bottom: 56px;
}
.questionIndexPanel .paginationCons {
    margin-top: 30px;
}

.flexSpaceBetween > button {
    border: none;
    padding: none;
}

.flexSpaceBetween > button .fa {
    color: #767676;
}

@media (min-width: 992px) {
    .questionIndexPanel {
        padding-left: 20px;
    }

    .flexSpaceBetween > button {
        border: 1px solid #d8d8d8;
        text-align: center;
        padding: 10px 20px;
        line-height: 1;
        border-radius: 3px;
    }

    .questionIndexPanel .pagination {
        margin-left: 20px;
    }

    .questionIndexPanel .pagination.marginLeft0 {
        margin-left: 0;
    }

    .flexSpaceBetween > button .fa {
        font-size: 12px;
    }
}
</style>
