<template>
    <div>
        <div class="buttonGroupInline">
            <button
                class="btn btn-default"
                :class="{
                    disabled: !canChangeQuestion(previous_question_group_idx, previous_question_idx),
                }"
                :aria-label="!canChangeQuestion(previous_question_group_idx, previous_question_idx) ? 'Previous Page Button Disabled' : 'Previous Page'"
                @click.prevent="
                    !canChangeQuestion(previous_question_group_idx, previous_question_idx)
                        ? ''
                        : moveToDifferentQuestion(previous_question_group_idx, previous_question_idx)
                "
                @keyup.space.prevent="
                    !canChangeQuestion(previous_question_group_idx, previous_question_idx)
                        ? ''
                        : moveToDifferentQuestion(previous_question_group_idx, previous_question_idx)
                "
            >
                <i class="fa fa-caret-left" aria-hidden="true"></i>
            </button>
            <div class="flexOnly align-items marginLeft10">
                <ul class="flexOnly pagination">
                    <li
                        v-for="(question, idx) in pagination"
                        :key="'page-' + idx"
                        :class="{
                            active: question.question_group_idx == current_question_group_idx && question.question_idx == question.question_idx,
                            first: question.question_idx == 0,
                            last: idx == pagination.length - 1 || pagination[idx + 1].question_group_idx != question.question_group_idx,
                        }"
                    >
                        <template v-if="question.isDot"><a>...</a></template>
                        <template v-else>
                            <template v-if="canChangeQuestion(question.question_group_idx, question.question_idx)">
                                <a
                                    :aria-label="'page ' + question.label"
                                    tabindex="0"
                                    @click.prevent="moveToDifferentQuestion(question.question_group_idx, question.question_idx)"
                                    @keyup.space.prevent="moveToDifferentQuestion(question.question_group_idx, question.question_idx)"
                                >
                                    {{ question.label }}
                                </a>
                            </template>
                            <template v-else>
                                <a class="disabled">{{ question.label }}</a>
                            </template>
                        </template>
                    </li>
                </ul>
            </div>
            <button
                class="btn btn-default marginLeft10"
                :class="{
                    disabled: !canChangeQuestion(next_question_group_idx, next_question_idx),
                }"
                :aria-label="!canChangeQuestion(next_question_group_idx, next_question_idx) ? 'Next Page Button Disabled' : 'Next Page'"
                @click.prevent="
                    !canChangeQuestion(next_question_group_idx, next_question_idx) ? '' : moveToDifferentQuestion(next_question_group_idx, next_question_idx)
                "
                @keyup.space.prevent="
                    !canChangeQuestion(next_question_group_idx, next_question_idx) ? '' : moveToDifferentQuestion(next_question_group_idx, next_question_idx)
                "
            >
                <i class="fa fa-caret-right" aria-hidden="true"></i>
            </button>
        </div>
    </div>
</template>

<script>
import { testPaginationMixins } from './../../../../../../../../mixins/test';
export default {
    mixins: [testPaginationMixins],
    methods: {
        //override from mixin check if we can change to different question
        canChangeQuestion(question_group_idx, question_idx) {
            if (question_group_idx == this.current_question_group_idx && question_idx == this.current_question_idx) {
                return false;
            }
            return true;
        },
    },
};
</script>

<style>
.paginator button,
.paginator .buttons,
.paginator .btn,
.paginator router-link {
    font-weight: normal;
}
</style>
<style scoped>
a.disabled {
    opacity: 0.3;
}

.width36px {
    width: 36px;
}

.padding16px {
    padding: 10px 15.5px !important;
}
</style>
