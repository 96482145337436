<template>
    <div>
        <div class="question col-xs-12 col-md-10">
            <kr-math :input="question.question" :safe="!question.questionIsHTML" />
        </div>
        <template v-if="question.attachments && question.attachments.length != 0">
            <div class="marginTop20 fontBold"><i class="fa fa-paperclip marginRight8" aria-hidden="true" />Question Attachment(s)</div>
            <div class="textLink">
                <div v-for="(file, idx) in question.attachments" class="flex">
                    <i class="fa fa-file-image-o marginRight8" aria-hidden="true" />
                    <a class="textLink" :href="file.url" target="_blank" download :aria-label="file.filename">{{ file.filename }}</a>
                </div>
            </div>
        </template>

        <!-- <div class="marginTop20" v-if="[null,'preparing','cancelled'].includes(question.eGallery.status)">
		<div class="mini-panel panel col-xs-12 col-md-4" v-if="!question.displayAnswerStatistics">
			<template v-if="question.studentAnswered">
				<div class="panel-heading flexSpaceBetween">
					<div>SUBMITTED ({{question.studentAnswered.length}})</div>
				<div><a class="link">View All&nbsp;<i class="fa fa-caret-right"></i></a></div>
				</div>
				<div class="panel-body flex">
					<template v-for="(student,student_idx) in question.studentAnswered">
						<div class="btnSections buttonGroup">
							<button v-if="auth.user().uuid!=student.uuid" class="btn gradient btn-default subBtn">
								Student {{student.fullname}}&nbsp;<i class="fa fa-user"></i>
							</button>
							<button v-else class="btn gradient btn-default-border-warning subBtn">
								Student {{student.fullname}}&nbsp;<i class="fa fa-user"></i>
							</button>
						</div>
					</template>
				</div>
			</template>
			<template v-if="question.teamsAnswered">
				<div class="panel-heading flexSpaceBetween">
					<div>SUBMITTED ({{question.teamsAnswered.length}})</div>
				<div><a class="link">View All&nbsp;<i class="fa fa-caret-right"></i></a></div>
				</div>
				<div class="panel-body flex padding5px10px">
					<template v-for="(team,team_idx) in question.teamsAnswered">
						<div class="marginTop10 marginBottom10 marginLeft5 marginRight5">
							<button v-if="testObj.myProgression.team.uuid!=team.uuid" class="btn gradient btn-default subBtn" @click="openTeamInfoModal(team)">
								{{team.name}}&nbsp;<i class="fa fa-users"></i>
							</button>
							<button v-else class="btn gradient btn-default-border-warning subBtn" @click="openTeamInfoModal(team)">
								{{team.name}}&nbsp;<i class="fa fa-users"></i>
							</button>
						</div>
					</template>
				</div>
			</template> 
		</div>
		<div class="col-xs-12 col-md-9 mini-table" v-else>
			<table class='table'>
				<thead>
					<tr>
						<th>
							<template v-if="testObj.others.applicationType=='team'">TEAM</template>
							<template v-else>STUDENT</template>
						</th>
					</tr>
				</thead>
				<tbody>
					<div class="padding20">
						<template v-if="question.studentAnswered">
							<tr v-for="(student,student_idx) in question.studentAnswered" class="discussionTableRows">
								<div class="goodAnswerDiv">
									<button v-if="auth.user().uuid!=student.uuid" class="btn gradient btn-default subBtn">
										Student {{student.fullname}}&nbsp;<i class="fa fa-user"></i>
									</button>
									<button v-else class="btn gradient btn-default-border-warning subBtn">
										Student {{student.fullname}}&nbsp;<i class="fa fa-user"></i>
									</button>
								</div>
								<div class="marginTop10 hidden-md wordBreakWord whiteSpacePreWrap marginBottom20" v-html="student.answer">
								</div>
							</tr>
							<template v-if="question.studentAnswered.length==0">
								<tr>
									<td>NO DATA</td>
								</tr>
							</template>		
						</template>
						<template v-if="question.teamsAnswered">
							<tr v-for="(team,team_idx) in question.teamsAnswered" class="discussionTableRows">
								<div class="goodAnswerDiv inlineFlex">
									<button v-if="testObj.myProgression.team.uuid!=team.uuid" class="btn gradient btn-default subBtn" @click="openTeamInfoModal(team)"> 
										{{team.name}}&nbsp;<i class="fa fa-users"></i>
									</button>
									<button v-else class="btn gradient btn-default-border-warning subBtn" @click="openTeamInfoModal(team)"> 
										{{team.name}}&nbsp;<i class="fa fa-users"></i>
									</button>
								</div>
								<div class="marginTop10 hidden-md wordBreakWord whiteSpacePreWrap marginBottom20" v-html="team.answer" @click="openTeamInfoModal(team)">
								</div>
							</tr>
							<template v-if="question.teamsAnswered.length==0">
								<tr>
									<td>NO DATA</td>
								</tr>
							</template>	
						</template>	
					</div>				
				</tbody>
			</table>
		</div>
	</div> -->
        <template v-if="[null, 'preparing', 'cancelled'].includes(question.eGallery.status)">
            <div class="marginTop20">
                <div class="hidden-xs hidden-sm">
                    <template v-for="(answer, answer_idx) in question.answers">
                        <template v-if="answer.isSelected">
                            <div
                                class="flex new-discussion"
                                :class="{
                                    padding0: !question.displayAnswer,
                                    'solid-warning':
                                        (testObj.others.applicationType == 'team' && testObj.myProgression.team.uuid == answer.team.uuid) ||
                                        (testObj.others.applicationType == 'individual' && auth.user().uuid == answer.student.userPlacementTestUuid),
                                    'border-black': answer.answer != null,
                                }"
                            >
                                <template v-if="!question.displayAnswer">
                                    <div class="flex align-items padding10">
                                        <template v-if="testObj.others.applicationType == 'team'">
                                            <div
                                                class="goodAnswerDiv"
                                                :class="{
                                                    inlineFlex: answer.isGoodAnswer,
                                                }"
                                            >
                                                <div>
                                                    <div class="dropdown">
                                                        <button
                                                            id="popOver"
                                                            class="btn gradient btn-default subBtn"
                                                            :class="{
                                                                'btn-default-border-warning':
                                                                    testObj
                                                                        .myProgression
                                                                        .team
                                                                        .uuid ==
                                                                    answer.team
                                                                        .uuid,
                                                            }"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                            :aria-label="
                                                                getDisplayName(
                                                                    answer.team
                                                                        .name
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                getDisplayName(
                                                                    answer.team
                                                                        .name
                                                                )
                                                            }}<i
                                                                v-if="
                                                                    answer.comment !=
                                                                        null ||
                                                                    (answer.attachments &&
                                                                        answer
                                                                            .attachments
                                                                            .length >
                                                                            0)
                                                                "
                                                                class="far fa-comment-dots marginLeft8"
                                                                aria-hidden="true"
                                                            />
                                                        </button>
                                                        <div
                                                            class="dropdown-menu dropdown-disucssion"
                                                            aria-labelledby="popOver"
                                                        >
                                                            <div>
                                                                <div
                                                                    class="flexOnly marginBottom5 align-items"
                                                                >
                                                                    <i
                                                                        class="fas fa-comments marginRight5 greyText"
                                                                    />
                                                                    <span
                                                                        class="lineHeight08 fontBold"
                                                                        >Elaboration</span
                                                                    >
                                                                </div>
                                                                <div
                                                                    v-if="
                                                                        answer.comment
                                                                    "
                                                                >
                                                                    <span
                                                                        class="whiteSpacePreWrap"
                                                                        >{{
                                                                            truncate(
                                                                                answer.comment,
                                                                                400
                                                                            )
                                                                        }}</span
                                                                    >
                                                                    <template
                                                                        v-if="
                                                                            answer
                                                                                .comment
                                                                                .length >
                                                                            400
                                                                        "
                                                                    >
                                                                        View More
                                                                    </template>

                                                                </div>
                                                                <div v-else>-</div>
                                                            </div>
                                                        </div>
                                                        <div class="marginTop20">
                                                            <div class="flexOnly marginBottom5 align-items">
                                                                <i class="fas fa-paperclip marginRight5 greyText" />
                                                                <span class="lineHeight08 fontBold">Attachment(s)</span>
                                                            </div>
                                                            <div>
                                                                <template v-if="answer.attachments && answer.attachments.length != 0">
                                                                    <div v-for="(file, idx) in answer.attachments" class="flex maxHeight100px">
                                                                        <span class="marginRight5">{{ idx + 1 }}.</span>
                                                                        <a
                                                                            :href="file.url"
                                                                            target="_blank"
                                                                            class="textLink"
                                                                            download
                                                                            :aria-label="file.filename"
                                                                            >{{ file.filename }}</a
                                                                        >
                                                                    </div>
                                                                    <template v-if="answer.attachments.length > 4">
                                                                        <div
                                                                            class="marginTop10 blue pointer"
                                                                            aria-label="View More"
                                                                            @click="openModelInfoModal(answer)"
                                                                        >
                                                                            View More
                                                                        </div>
                                                                    </template>
                                                                </template>
                                                                <template v-else> - </template>
                                                            </div>
                                                        </div>
                                                        <div class="marginTop20 previewModelTeam">
                                                            <div class="flexOnly marginBottom5 align-items">
                                                                <i class="fas fa-users marginRight8 greyText" aria-hidden="true" />
                                                                <span class="lineHeight08 fontBold">Team Members</span>
                                                            </div>
                                                            <div
                                                                class="marginTop20 previewModelTeam"
                                                            >
                                                                <div
                                                                    class="flexOnly marginBottom5 align-items"
                                                                >
                                                                    <i
                                                                        class="fas fa-users marginRight8 greyText"
                                                                        aria-hidden="true"
                                                                    />
                                                                    <span
                                                                        class="lineHeight08 fontBold"
                                                                        >Team
                                                                        Members</span
                                                                    >
                                                                </div>
                                                                <div
                                                                    class="maxHeight100px"
                                                                >
                                                                    <ul
                                                                        v-for="(
                                                                            student,
                                                                            idx
                                                                        ) in answer
                                                                            .team
                                                                            .members"
                                                                    >
                                                                        <li>
                                                                            {{
                                                                                getDisplayName(
                                                                                    student.displayName,
                                                                                    'student',
                                                                                    idx
                                                                                )
                                                                            }}
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <template
                                                                    v-if="
                                                                        answer
                                                                            .team
                                                                            .members
                                                                            .length >
                                                                        4
                                                                    "
                                                                >
                                                                    <div
                                                                        class="blue pointer"
                                                                        aria-label="View More"
                                                                        @click="
                                                                            openModelInfoModal(
                                                                                answer
                                                                            )
                                                                        "
                                                                    >
                                                                        View
                                                                        More
                                                                    </div>
                                                                </template>
                                                            </div>
                                                            <template v-if="answer.team.members.length > 4">
                                                                <div class="blue pointer" aria-label="View More" @click="openModelInfoModal(answer)">
                                                                    View More
                                                                </div>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="answer.isGoodAnswer" class="statusTag statusTagSuccess subBtn goodAnswers">
                                                    Good Answer<i class="fa fa-thumbs-up marginLeft8" aria-hidden="true" />
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div
                                                class="goodAnswerDiv"
                                                :class="{
                                                    inlineFlex: answer.isGoodAnswer,
                                                }"
                                            >
                                                <template v-if="answer.comment != null || (answer.attachments && answer.attachments.length > 0)">
                                                    <div class="dropdown">
                                                        <button
                                                            id="popOver"
                                                            class="btn gradient btn-default subBtn"
                                                            :class="{
                                                                'btn-default-border-warning': auth.user().uuid == answer.student.userPlacementTestUuid,
                                                            }"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                            :aria-label="
                                                                getDisplayName(
                                                                    answer
                                                                        .student
                                                                        .fullname,
                                                                    'student'
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                getDisplayName(
                                                                    answer
                                                                        .student
                                                                        .fullname,
                                                                    'student'
                                                                )
                                                            }}<i
                                                                class="far fa-comment-dots marginLeft8"
                                                                aria-hidden="true"
                                                            />
                                                        </button>
                                                        <div class="dropdown-menu dropdown-disucssion padding10" aria-labelledby="popOver">
                                                            <div>
                                                                <div class="flexOnly marginBottom5 align-items">
                                                                    <i class="fas fa-comments marginRight5 greyText" />
                                                                    <span class="lineHeight08 fontBold">Elaboration</span>
                                                                </div>
                                                                <div v-if="answer.comment">
                                                                    <span class="whiteSpacePreWrap">{{ truncate(answer.comment, 400) }}</span>
                                                                    <template v-if="answer.comment.length > 400">
                                                                        <div
                                                                            class="marginTop10 blue pointer"
                                                                            aria-label="View More"
                                                                            @click="openModelInfoModal(answer, 'student')"
                                                                        >
                                                                            View More
                                                                        </div>
                                                                    </template>
                                                                </div>
                                                                <div v-else>-</div>
                                                            </div>
                                                            <div class="marginTop20">
                                                                <div class="flexOnly marginBottom5 align-items">
                                                                    <i class="fas fa-paperclip marginRight5 greyText" />
                                                                    <span class="lineHeight08 fontBold">Attachment(s)</span>
                                                                </div>
                                                                <div>
                                                                    <template v-if="answer.attachments && answer.attachments.length != 0">
                                                                        <div v-for="(file, idx) in answer.attachments" class="flex maxHeight100px">
                                                                            <span class="marginRight5">{{ idx + 1 }}.</span>
                                                                            <a
                                                                                :href="file.url"
                                                                                target="_blank"
                                                                                class="textLink"
                                                                                download
                                                                                :aria-label="file.filename"
                                                                                >{{ file.filename }}</a
                                                                            >
                                                                        </div>
                                                                        <template v-if="answer.attachments.length > 4">
                                                                            <div
                                                                                class="marginTop10 blue pointer"
                                                                                aria-label="View More"
                                                                                @click="openModelInfoModal(answer, 'student')"
                                                                            >
                                                                                View More
                                                                            </div>
                                                                        </template>
                                                                    </template>
                                                                    <template v-else> - </template>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <button
                                                        class="btn gradient btn-default subBtn cursor-norm"
                                                        :class="{
                                                            'btn-default-border-warning': auth.user().uuid == answer.student.userPlacementTestUuid,
                                                        }"
                                                        :aria-label="
                                                            getDisplayName(
                                                                answer.student
                                                                    .fullname,
                                                                'student'
                                                            )
                                                        "
                                                    >
                                                        {{
                                                            getDisplayName(
                                                                answer.student
                                                                    .fullname,
                                                                'student'
                                                            )
                                                        }}
                                                    </button>
                                                </template>
                                                <div v-if="answer.isGoodAnswer" class="statusTag statusTagSuccess subBtn goodAnswers">
                                                    Good Answer<i class="fa fa-thumbs-up marginLeft8" aria-hidden="true" />
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </template>
                                <template v-if="question.displayAnswer">
                                    <div>
                                        <template v-if="testObj.others.applicationType == 'team'">
                                            <div
                                                class="goodAnswerDiv"
                                                :class="{
                                                    inlineFlex: answer.isGoodAnswer,
                                                }"
                                            >
                                                <div>
                                                    <div class="dropdown">
                                                        <button
                                                            id="popOver"
                                                            class="btn gradient btn-default subBtn"
                                                            :class="{
                                                                'btn-default-border-warning':
                                                                    testObj
                                                                        .myProgression
                                                                        .team
                                                                        .uuid ==
                                                                    answer.team
                                                                        .uuid,
                                                            }"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                            :aria-label="
                                                                getDisplayName(
                                                                    answer.team
                                                                        .name
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                getDisplayName(
                                                                    answer.team
                                                                        .name
                                                                )
                                                            }}<i
                                                                v-if="
                                                                    answer.comment !=
                                                                        null ||
                                                                    (answer.attachments &&
                                                                        answer
                                                                            .attachments
                                                                            .length >
                                                                            0)
                                                                "
                                                                class="far fa-comment-dots marginLeft8"
                                                                aria-hidden="true"
                                                            />
                                                        </button>
                                                        <div
                                                            class="dropdown-menu dropdown-disucssion padding10"
                                                            aria-labelledby="popOver"
                                                        >
                                                            <div>
                                                                <div
                                                                    class="flexOnly marginBottom5 align-items"
                                                                >
                                                                    <i
                                                                        class="fas fa-comments marginRight5 greyText"
                                                                    />
                                                                    <span
                                                                        class="lineHeight08 fontBold"
                                                                        >Elaboration</span
                                                                    >
                                                                </div>
                                                                <div
                                                                    v-if="
                                                                        answer.comment
                                                                    "
                                                                >
                                                                    <span
                                                                        class="whiteSpacePreWrap"
                                                                        >{{
                                                                            truncate(
                                                                                answer.comment,
                                                                                400
                                                                            )
                                                                        }}</span
                                                                    >
                                                                    <template
                                                                        v-if="
                                                                            answer
                                                                                .comment
                                                                                .length >
                                                                            400
                                                                        "
                                                                    >
                                                                        View More
                                                                        </template>
                                                                    
                                                                </div>
                                                                <div v-else>-</div>
                                                            </div>
                                                            
                                                        </div>
                                                        <div class="marginTop20">
                                                            <div class="flexOnly marginBottom5 align-items">
                                                                <i class="fas fa-paperclip marginRight5 greyText" />
                                                                <span class="lineHeight08 fontBold">Attachment(s)</span>
                                                            </div>
                                                            <div>
                                                                <template v-if="answer.attachments && answer.attachments.length != 0">
                                                                    <div v-for="(file, idx) in answer.attachments" class="flex maxHeight100px">
                                                                        <span class="marginRight5">{{ idx + 1 }}.</span>
                                                                        <a
                                                                            :href="file.url"
                                                                            target="_blank"
                                                                            class="textLink"
                                                                            download
                                                                            :aria-label="file.filename"
                                                                            >{{ file.filename }}</a
                                                                        >
                                                                    </div>
                                                                    <template v-if="answer.attachments.length > 4">
                                                                        <div
                                                                            class="marginTop10 blue pointer"
                                                                            aria-label="View More"
                                                                            @click="openModelInfoModal(answer)"
                                                                        >
                                                                            View More
                                                                        </div>
                                                                    </template>
                                                                </template>
                                                                <template v-else> - </template>
                                                            </div>
                                                        </div>
                                                        <div class="marginTop20 previewModelTeam">
                                                            <div class="flexOnly marginBottom5 align-items">
                                                                <i class="fas fa-users marginRight8 greyText" aria-hidden="true" />
                                                                <span class="lineHeight08 fontBold">Team Members</span>
                                                            </div>
                                                            <div
                                                                class="marginTop20 previewModelTeam"
                                                            >
                                                                <div
                                                                    class="flexOnly marginBottom5 align-items"
                                                                >
                                                                    <i
                                                                        class="fas fa-users marginRight8 greyText"
                                                                        aria-hidden="true"
                                                                    />
                                                                    <span
                                                                        class="lineHeight08 fontBold"
                                                                        >Team
                                                                        Members</span
                                                                    >
                                                                </div>
                                                                <div
                                                                    class="maxHeight100px"
                                                                >
                                                                    <ul
                                                                        v-for="(
                                                                            student,
                                                                            idx
                                                                        ) in answer
                                                                            .team
                                                                            .members"
                                                                    >
                                                                        <li>
                                                                            {{
                                                                                getDisplayName(
                                                                                    student.displayName,
                                                                                    'student',
                                                                                    idx
                                                                                )
                                                                            }}
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <template
                                                                    v-if="
                                                                        answer
                                                                            .team
                                                                            .members
                                                                            .length >
                                                                        4
                                                                    "
                                                                >
                                                                    <div
                                                                        class="blue pointer"
                                                                        aria-label="View More"
                                                                        @click="
                                                                            openModelInfoModal(
                                                                                answer
                                                                            )
                                                                        "
                                                                    >
                                                                        View
                                                                        More
                                                                    </div>
                                                                </template>
                                                            </div>
                                                            <template v-if="answer.team.members.length > 4">
                                                                <div class="blue pointer" aria-label="View More" @click="openModelInfoModal(answer)">
                                                                    View More
                                                                </div>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="answer.isGoodAnswer" class="statusTag statusTagSuccess subBtn goodAnswers">
                                                    Good Answer<i class="fa fa-thumbs-up marginLeft8" aria-hidden="true" />
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div
                                                class="goodAnswerDiv"
                                                :class="{
                                                    inlineFlex: answer.isGoodAnswer,
                                                }"
                                            >
                                                <template v-if="answer.comment != null || (answer.attachments && answer.attachments.length > 0)">
                                                    <div class="dropdown">
                                                        <button
                                                            id="popOver"
                                                            class="btn gradient btn-default subBtn"
                                                            :class="{
                                                                'btn-default-border-warning': auth.user().uuid == answer.student.userPlacementTestUuid,
                                                            }"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                            :aria-label="
                                                                getDisplayName(
                                                                    answer
                                                                        .student
                                                                        .fullname,
                                                                    'student'
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                getDisplayName(
                                                                    answer
                                                                        .student
                                                                        .fullname,
                                                                    'student'
                                                                )
                                                            }}<i
                                                                class="far fa-comment-dots marginLeft8"
                                                                aria-hidden="true"
                                                            />
                                                        </button>
                                                        <div class="dropdown-menu dropdown-disucssion padding10" aria-labelledby="popOver">
                                                            <div>
                                                                <div class="flexOnly marginBottom5 align-items">
                                                                    <i class="fas fa-comments marginRight5 greyText" />
                                                                    <span class="lineHeight08 fontBold">Elaboration</span>
                                                                </div>
                                                                <div v-if="answer.comment">
                                                                    <span class="whiteSpacePreWrap">{{ truncate(answer.comment, 400) }}</span>
                                                                    <template v-if="answer.comment.length > 400">
                                                                        <div
                                                                            class="marginTop10 blue pointer"
                                                                            aria-label="View More"
                                                                            @click="openModelInfoModal(answer, 'student')"
                                                                        >
                                                                            View More
                                                                        </div>
                                                                    </template>
                                                                </div>
                                                                <div v-else>-</div>
                                                            </div>
                                                            <div class="marginTop20">
                                                                <div class="flexOnly marginBottom5 align-items">
                                                                    <i class="fas fa-paperclip marginRight5 greyText" />
                                                                    <span class="lineHeight08 fontBold">Attachment(s)</span>
                                                                </div>
                                                                <div>
                                                                    <template v-if="answer.attachments && answer.attachments.length != 0">
                                                                        <div v-for="(file, idx) in answer.attachments" class="flex maxHeight100px">
                                                                            <span class="marginRight5">{{ idx + 1 }}.</span>
                                                                            <a
                                                                                :href="file.url"
                                                                                target="_blank"
                                                                                class="textLink"
                                                                                download
                                                                                :aria-label="file.filename"
                                                                                >{{ file.filename }}</a
                                                                            >
                                                                        </div>
                                                                        <template v-if="answer.attachments.length > 4">
                                                                            <div
                                                                                class="marginTop10 blue pointer"
                                                                                aria-label="View More"
                                                                                @click="openModelInfoModal(answer, 'student')"
                                                                            >
                                                                                View More
                                                                            </div>
                                                                        </template>
                                                                    </template>
                                                                    <template v-else> - </template>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <button
                                                        class="btn gradient btn-default subBtn cursor-norm"
                                                        :class="{
                                                            'btn-default-border-warning': auth.user().uuid == answer.student.userPlacementTestUuid,
                                                        }"
                                                        :aria-label="
                                                            getDisplayName(
                                                                answer.student
                                                                    .fullname,
                                                                'student'
                                                            )
                                                        "
                                                    >
                                                        {{
                                                            getDisplayName(
                                                                answer.student
                                                                    .fullname,
                                                                'student'
                                                            )
                                                        }}
                                                    </button>
                                                </template>
                                                <div v-if="answer.isGoodAnswer" class="statusTag statusTagSuccess subBtn goodAnswers">
                                                    Good Answer<i class="fa fa-thumbs-up marginLeft8" aria-hidden="true" />
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                    <div class="new-discussion-ans width100 marginTop20">
                                        <template v-if="answer.answer != null">
                                            <div class="wordBreakWord whiteSpacePreWrap">
                                                <span v-html="truncate(answer.answer, 800)" />
                                                <template v-if="answer.answer.length > 800">
                                                    <div class="marginTop10 blue pointer" aria-label="View More" @click="openQuestionModal(answer.answer)">
                                                        View More
                                                    </div>
                                                </template>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="fontItalic greyText">
                                                <template v-if="testObj.others.applicationType == 'team'"> Team </template>
                                                <template v-else> Student </template>
                                                Has Not Answered Yet.
                                            </div>
                                        </template>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </template>
                </div>
                <div class="hidden-xs hidden-md hidden-lg">
                    <template v-for="(answer, answer_idx) in question.answers">
                        <template v-if="answer.isSelected">
                            <div
                                class="new-discussion padding5"
                                :class="{
                                    'solid-warning':
                                        (testObj.others.applicationType == 'team' && testObj.myProgression.team.uuid == answer.team.uuid) ||
                                        (testObj.others.applicationType == 'individual' && auth.user().uuid == answer.student.userPlacementTestUuid),
                                    'border-black': answer.answer != null,
                                }"
                            >
                                <template v-if="!question.displayAnswer">
                                    <div class="flexSpaceBetween">
                                        <div class="flex align-items">
                                            <template v-if="testObj.others.applicationType == 'team'">
                                                <div
                                                    class="goodAnswerDiv"
                                                    :class="{
                                                        inlineFlex: answer.isGoodAnswer,
                                                    }"
                                                >
                                                        <button
                                                            class="btn gradient btn-default subBtn"
                                                            :class="{
                                                                'btn-default-border-warning':
                                                                    testObj
                                                                        .myProgression
                                                                        .team
                                                                        .uuid ==
                                                                    answer.team
                                                                        .uuid,
                                                            }"
                                                            :aria-label="
                                                                getDisplayName(
                                                                    answer.team
                                                                        .name
                                                                )
                                                            "
                                                            @click="
                                                                openModelInfoModal(
                                                                    answer
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                getDisplayName(
                                                                    answer.team
                                                                        .name
                                                                )
                                                            }}<i
                                                                v-if="
                                                                    answer.comment !=
                                                                        null ||
                                                                    (answer.attachments &&
                                                                        answer
                                                                            .attachments
                                                                            .length >
                                                                            0)
                                                                "
                                                                class="far fa-comment-dots marginLeft8"
                                                                aria-hidden="true"
                                                            />
                                                        </button>
                                                    <div
                                                        v-if="
                                                            answer.isGoodAnswer
                                                        "
                                                        class="statusTag statusTagSuccess subBtn goodAnswers"
                                                    >
                                                        {{ answer.team.name
                                                        }}<i
                                                            v-if="answer.comment != null || (answer.attachments && answer.attachments.length > 0)"
                                                            class="far fa-comment-dots marginLeft8"
                                                            aria-hidden="true"
                                                        />
                                                    </div>

                                                    <div v-if="answer.isGoodAnswer" class="statusTag statusTagSuccess subBtn goodAnswers">
                                                        Good Answer<i class="fa fa-thumbs-up marginLeft8" aria-hidden="true" />
                                                    </div>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div
                                                    class="goodAnswerDiv"
                                                    :class="{
                                                        inlineFlex: answer.isGoodAnswer,
                                                    }"
                                                >
                                                        <button
                                                            class="btn gradient btn-default subBtn"
                                                            :class="{
                                                                'btn-default-border-warning':
                                                                    auth.user()
                                                                        .uuid ==
                                                                    answer
                                                                        .student
                                                                        .userPlacementTestUuid,
                                                            }"
                                                            :aria-label="
                                                                getDisplayName(
                                                                    answer
                                                                        .student
                                                                        .fullname,
                                                                    'student'
                                                                )
                                                            "
                                                            @click="
                                                                openModelInfoModal(
                                                                    answer,
                                                                    'student'
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                getDisplayName(
                                                                    answer
                                                                        .student
                                                                        .fullname,
                                                                    'student'
                                                                )
                                                            }}<i
                                                                v-if="
                                                                    answer.comment !=
                                                                        null ||
                                                                    (answer.attachments &&
                                                                        answer
                                                                            .attachments
                                                                            .length >
                                                                            0)
                                                                "
                                                                class="far fa-comment-dots marginLeft8"
                                                                aria-hidden="true"
                                                            />
                                                        </button>
                                                    <div
                                                        v-if="
                                                            answer.isGoodAnswer
                                                        "
                                                        class="statusTag statusTagSuccess subBtn goodAnswers"
                                                    >
                                                        {{ answer.student.fullname
                                                        }}<i
                                                            v-if="answer.comment != null || (answer.attachments && answer.attachments.length > 0)"
                                                            class="far fa-comment-dots marginLeft8"
                                                            aria-hidden="true"
                                                        />
                                                    </div>

                                                    <div v-if="answer.isGoodAnswer" class="statusTag statusTagSuccess subBtn goodAnswers">
                                                        Good Answer<i class="fa fa-thumbs-up marginLeft8" aria-hidden="true" />
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="flexSpaceBetween">
                                        <div class="flex align-items">
                                            <template v-if="testObj.others.applicationType == 'team'">
                                                <div
                                                    class="goodAnswerDiv"
                                                    :class="{
                                                        inlineFlex: answer.isGoodAnswer,
                                                    }"
                                                >
                                                        <button
                                                            class="btn gradient btn-default subBtn"
                                                            :class="{
                                                                'btn-default-border-warning':
                                                                    testObj
                                                                        .myProgression
                                                                        .team
                                                                        .uuid ==
                                                                    answer.team
                                                                        .uuid,
                                                            }"
                                                            :aria-label="
                                                                getDisplayName(
                                                                    answer.team
                                                                        .name
                                                                )
                                                            "
                                                            @click="
                                                                openModelInfoModal(
                                                                    answer
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                getDisplayName(
                                                                    answer.team
                                                                        .name
                                                                )
                                                            }}<i
                                                                v-if="
                                                                    answer.comment !=
                                                                        null ||
                                                                    (answer.attachments &&
                                                                        answer
                                                                            .attachments
                                                                            .length >
                                                                            0)
                                                                "
                                                                class="far fa-comment-dots marginLeft8"
                                                                aria-hidden="true"
                                                            />
                                                        </button>
                                                    <div
                                                        v-if="
                                                            answer.isGoodAnswer
                                                        "
                                                        class="statusTag statusTagSuccess subBtn goodAnswers"
                                                    >
                                                        {{ answer.team.name
                                                        }}<i
                                                            v-if="answer.comment != null || (answer.attachments && answer.attachments.length > 0)"
                                                            class="far fa-comment-dots marginLeft8"
                                                            aria-hidden="true"
                                                        />
                                                    </div>

                                                    <div v-if="answer.isGoodAnswer" class="statusTag statusTagSuccess subBtn goodAnswers">
                                                        Good Answer<i class="fa fa-thumbs-up marginLeft8" aria-hidden="true" />
                                                    </div>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div
                                                    class="goodAnswerDiv"
                                                    :class="{
                                                        inlineFlex: answer.isGoodAnswer,
                                                    }"
                                                >
                                                        <button
                                                            class="btn gradient btn-default subBtn"
                                                            :class="{
                                                                'btn-default-border-warning':
                                                                    auth.user()
                                                                        .uuid ==
                                                                    answer
                                                                        .student
                                                                        .userPlacementTestUuid,
                                                            }"
                                                            :aria-label="
                                                                getDisplayName(
                                                                    answer
                                                                        .student
                                                                        .fullname,
                                                                    'student'
                                                                )
                                                            "
                                                            @click="
                                                                openModelInfoModal(
                                                                    answer,
                                                                    'student'
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                getDisplayName(
                                                                    answer
                                                                        .student
                                                                        .fullname,
                                                                    'student'
                                                                )
                                                            }}<i
                                                                v-if="
                                                                    answer.comment !=
                                                                        null ||
                                                                    (answer.attachments &&
                                                                        answer
                                                                            .attachments
                                                                            .length >
                                                                            0)
                                                                "
                                                                class="far fa-comment-dots marginLeft8"
                                                                aria-hidden="true"
                                                            />
                                                        </button>
                                                    <div
                                                        v-if="
                                                            answer.isGoodAnswer
                                                        "
                                                        class="statusTag statusTagSuccess subBtn goodAnswers"
                                                    >
                                                        {{ answer.student.fullname
                                                        }}<i
                                                            v-if="answer.comment != null || (answer.attachments && answer.attachments.length > 0)"
                                                            class="far fa-comment-dots marginLeft8"
                                                            aria-hidden="true"
                                                        />
                                                    </div>

                                                    <div v-if="answer.isGoodAnswer" class="statusTag statusTagSuccess subBtn goodAnswers">
                                                        Good Answer<i class="fa fa-thumbs-up marginLeft8" aria-hidden="true" />
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="new-discussion-ans width100 marginTop20">
                                        <div>
                                            <template v-if="answer.answer != null">
                                                <div class="wordBreakWord whiteSpacePreWrap">
                                                    <span v-html="truncate(answer.answer, 800)" />
                                                    <template v-if="answer.answer.length > 800">
                                                        <div class="marginTop10 blue pointer" aria-label="View More" @click="openQuestionModal(answer.answer)">
                                                            View More
                                                        </div>
                                                    </template>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div class="fontItalic greyText">
                                                    <template v-if="testObj.others.applicationType == 'team'"> Team </template>
                                                    <template v-else> Student </template>
                                                    Has Not Answered Yet.
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </template>
                </div>

                <div class="hidden-sm hidden-md hidden-lg">
                    <template v-for="(answer, answer_idx) in question.answers">
                        <template v-if="answer.isSelected">
                            <div
                                class="new-discussion padding5"
                                :class="{
                                    'solid-warning':
                                        (testObj.others.applicationType == 'team' && testObj.myProgression.team.uuid == answer.team.uuid) ||
                                        (testObj.others.applicationType == 'individual' && auth.user().uuid == answer.student.userPlacementTestUuid),
                                    'border-black': answer.answer != null,
                                }"
                            >
                                <div>
                                    <template v-if="testObj.others.applicationType == 'team'">
                                        <div>
                                                <button
                                                    class="btn gradient btn-default subBtn"
                                                    :class="{
                                                        'btn-default-border-warning':
                                                            testObj
                                                                .myProgression
                                                                .team.uuid ==
                                                            answer.team.uuid,
                                                    }"
                                                    :aria-label="
                                                        getDisplayName(
                                                            answer.team.name
                                                        )
                                                    "
                                                    @click="
                                                        openModelInfoModal(
                                                            answer
                                                        )
                                                    "
                                                >
                                                    {{
                                                        getDisplayName(
                                                            answer.team.name
                                                        )
                                                    }}<i
                                                        v-if="
                                                            answer.comment !=
                                                                null ||
                                                            (answer.attachments &&
                                                                answer
                                                                    .attachments
                                                                    .length > 0)
                                                        "
                                                        class="far fa-comment-dots marginLeft8"
                                                        aria-hidden="true"
                                                    />
                                                </button>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div>
                                                <button
                                                    class="btn gradient btn-default subBtn"
                                                    :class="{
                                                        'btn-default-border-warning':
                                                            auth.user().uuid ==
                                                            answer.student
                                                                .userPlacementTestUuid,
                                                    }"
                                                    :aria-label="
                                                        getDisplayName(
                                                            answer.student
                                                                .fullname,
                                                            'student'
                                                        )
                                                    "
                                                    @click="
                                                        openModelInfoModal(
                                                            answer,
                                                            'student'
                                                        )
                                                    "
                                                >
                                                    {{
                                                        getDisplayName(
                                                            answer.student
                                                                .fullname,
                                                            'student'
                                                        )
                                                    }}<i
                                                        v-if="
                                                            answer.comment !=
                                                                null ||
                                                            (answer.attachments &&
                                                                answer
                                                                    .attachments
                                                                    .length > 0)
                                                        "
                                                        class="far fa-comment-dots marginLeft8"
                                                        aria-hidden="true"
                                                    />
                                                </button>
                                        </div>
                                    </template>
                                    <template v-if="answer.isGoodAnswer">
                                        <button class="statusTag statusTagSuccess subBtn goodAnswers marginTop20">
                                            Good Answer<i class="fa fa-thumbs-up marginLeft8" aria-hidden="true" />
                                        </button>
                                    </template>
                                </div>

                                <div v-if="question.displayAnswer" class="new-discussion-ans width100 marginTop20">
                                    <div>
                                        <template v-if="answer.answer != null">
                                            <div class="wordBreakWord whiteSpacePreWrap">
                                                <span v-html="truncate(answer.answer, 800)" />
                                                <template v-if="answer.answer.length > 800">
                                                    <div class="marginTop10 blue pointer" aria-label="View More" @click="openQuestionModal(answer.answer)">
                                                        View More
                                                    </div>
                                                </template>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="fontItalic greyText">
                                                <template v-if="testObj.others.applicationType == 'team'"> Team </template>
                                                <template v-else> Student </template>
                                                Has Not Answered Yet.
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                </div>
            </div>
        </template>
        <div v-if="(question.eGallery.status == 'ongoing') | ['ended'].includes(question.eGallery.status) | ['released'].includes(question.eGallery.status)">
            <template v-if="question.eGallery.status == 'ongoing'">
                <table class="table">
                    <thead>
                        <tr>
                            <th style="width: 112px">Team</th>
                            <th style="width: 50%">Answer</th>
                            <th style="width: 80px">Vote</th>
                            <th>Comments</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(answer, answer_idx) in question.answers">
                            <tr v-if="answer.uuid" :key="answer.uuid">
                                <td>
                                    <template v-if="testObj.others.applicationType == 'team'">
                                        <div
                                            class="goodAnswerDiv"
                                            :class="{
                                                inlineFlex: answer.isGoodAnswer,
                                            }"
                                        >
                                                <button
                                                    class="btn gradient btn-default subBtn"
                                                    :class="{
                                                        'btn-default-border-warning':
                                                            testObj
                                                                .myProgression
                                                                .team.uuid ==
                                                            answer.team.uuid,
                                                    }"
                                                    :aria-label="
                                                        getDisplayName(
                                                            answer.team.name
                                                        )
                                                    "
                                                    @click="
                                                        openModelInfoModal(
                                                            answer
                                                        )
                                                    "
                                                >
                                                    {{
                                                        getDisplayName(
                                                            answer.team.name
                                                        )
                                                    }}<i
                                                        v-if="
                                                            answer.comment !=
                                                                null ||
                                                            (answer.attachments &&
                                                                answer
                                                                    .attachments
                                                                    .length > 0)
                                                        "
                                                        class="far fa-comment-dots marginLeft8"
                                                        aria-hidden="true"
                                                    />
                                                </button>
                                            <div
                                                v-if="answer.isGoodAnswer"
                                                class="statusTag statusTagSuccess subBtn goodAnswers whiteSpaceNowrap"
                                            >
                                                {{ answer.team.name
                                                }}<i
                                                    v-if="answer.comment != null || (answer.attachments && answer.attachments.length > 0)"
                                                    class="far fa-comment-dots marginLeft8"
                                                    aria-hidden="true"
                                                />
                                            </div>

                                            <div v-if="answer.isGoodAnswer" class="statusTag statusTagSuccess subBtn goodAnswers whiteSpaceNowrap">
                                                Good Answer<i class="fa fa-thumbs-up marginLeft8" aria-hidden="true" />
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div
                                            class="goodAnswerDiv"
                                            :class="{
                                                inlineFlex: answer.isGoodAnswer,
                                            }"
                                        >
                                                <button
                                                    class="btn gradient btn-default subBtn"
                                                    :class="{
                                                        'btn-default-border-warning':
                                                            auth.user().uuid ==
                                                            answer.student
                                                                .userPlacementTestUuid,
                                                    }"
                                                    :aria-label="
                                                        getDisplayName(
                                                            answer.student
                                                                .fullname,
                                                            'student'
                                                        )
                                                    "
                                                    @click="
                                                        openModelInfoModal(
                                                            answer,
                                                            'student'
                                                        )
                                                    "
                                                >
                                                    {{
                                                        getDisplayName(
                                                            answer.student
                                                                .fullname,
                                                            'student'
                                                        )
                                                    }}<i
                                                        v-if="
                                                            answer.comment !=
                                                                null ||
                                                            (answer.attachments &&
                                                                answer
                                                                    .attachments
                                                                    .length > 0)
                                                        "
                                                        class="far fa-comment-dots marginLeft8"
                                                        aria-hidden="true"
                                                    />
                                                </button>
                                            <div
                                                v-if="answer.isGoodAnswer"
                                                class="statusTag statusTagSuccess subBtn goodAnswers whiteSpaceNowrap"
                                            >
                                                {{ answer.student.fullname
                                                }}<i
                                                    v-if="answer.comment != null || (answer.attachments && answer.attachments.length > 0)"
                                                    class="far fa-comment-dots marginLeft8"
                                                    aria-hidden="true"
                                                />
                                            </div>

                                            <div v-if="answer.isGoodAnswer" class="statusTag statusTagSuccess subBtn goodAnswers whiteSpaceNowrap">
                                                Good Answer<i class="fa fa-thumbs-up marginLeft8" aria-hidden="true" />
                                            </div>
                                        </div>
                                    </template>
                                </td>
                                <td>
                                    <template v-if="answer.answer != null">
                                        <div class="wordBreakWord whiteSpacePreWrap">
                                            <span v-html="truncate(answer.answer, 800)" />
                                            <template v-if="answer.answer.length > 800">
                                                <div class="marginTop10 blue pointer" aria-label="View More" @click="openQuestionModal(answer.answer)">
                                                    View More
                                                </div>
                                            </template>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="fontItalic greyText">
                                            <template v-if="testObj.others.applicationType == 'team'"> Team </template>
                                            <template v-else> Student </template>
                                            Has Not Answered Yet.
                                        </div>
                                    </template>
                                </td>
                                <td>
                                    <template v-if="!isMyAnswer(answer)">
                                        <div v-if="testObj.others.applicationType == 'individual' || isTeamLeader" class="radioField flex justifyCenter">
                                            <input
                                                :id="answer_idx"
                                                v-model="answer.myVote"
                                                type="radio"
                                                :value="true"
                                                aria-label="Vote"
                                                @change="toggleBestAnswer(answer)"
                                            />
                                        </div>
                                        <template v-else> - </template>
                                    </template>
                                    <template v-else>
                                        <div class="textAlignCenter">-</div>
                                    </template>
                                </td>
                                <td>
                                    <div v-if="!isMyAnswer(answer)" class="discussionComment">
                                        <!--answer._editComment -->
                                        <template v-if="testObj.others.applicationType == 'individual' || isTeamLeader">
                                            <!--@keydown="debouncedCommentAutosaves[answer.uuid](answer)"-->
                                            <label
                                                v-if="
                                                    (question.eGalleryWalkSettings.teamsMustCommentForAllAnswers && !answer.myComment) ||
                                                    (question.eGalleryWalkSettings.teamsMustCommentForVotedAnswersOnly && answer.myVote && !answer.myComment)
                                                "
                                                class="important fontNormal flexRight marginBottom5"
                                            >
                                                * Required
                                            </label>
                                            <label v-else class="greyText fontNormal flexRight marginBottom5"> (Optional) </label>
                                            <textarea
                                                v-model="answer.myComment"
                                                cols="3"
                                                class="form-control js-autoresize"
                                                placeholder="CLICK HERE TO TYPE YOUR COMMENTS."
                                                aria-label="Comments"
                                                :class="{
                                                    danger:
                                                        (question.eGalleryWalkSettings.teamsMustCommentForAllAnswers && !answer.myComment) ||
                                                        (question.eGalleryWalkSettings.teamsMustCommentForVotedAnswersOnly &&
                                                            answer.myVote &&
                                                            !answer.myComment),
                                                }"
                                                @keydown="answer._isTouched = true"
                                            />
                                        </template>
                                        <template v-else>
                                            <div class="flexSpaceBetween">
                                                <div>
                                                    <template v-if="answer.myComment">
                                                        <div class="whiteSpacePreWrap" v-html="answer.myComment" />
                                                    </template>
                                                    <template v-else> - </template>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                    <template v-else> - </template>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
                <button
                    :disabled="!canSubmitVote() ? true : undefined"
                    class="btn btn-success btn-default subBtn col-xs-12"
                    :aria-label="canSubmitVote() ? 'Update Vote & Comments' : 'Update Vote & Comments Disabled'"
                    @click="submit()"
                >
                    <template v-if="question.eGallery.hasSubmitted"> Update Vote & Comments </template>
                    <template v-else> Submit Vote & Comments </template>
                </button>
            </template>
            <template v-if="['ended'].includes(question.eGallery.status)"> Please wait for teacher to release results </template>
            <template v-if="['released'].includes(question.eGallery.status)">
                <div class="hidden-xs hidden-sm">
                    <template
                        v-for="(answer, answer_idx) in reorderedAnswers(
                            question
                        )"
                    >
                        <template v-if="answer.isSelected">
                            <div
                                v-if="answer.answer != null"
                                class="flex new-discussion"
                                :class="{
                                    'solid-warning':
                                        (testObj.others.applicationType ==
                                            'team' &&
                                            testObj.myProgression.team.uuid ==
                                                answer.team.uuid) ||
                                        (testObj.others.applicationType ==
                                            'individual' &&
                                            auth.user().uuid ==
                                                answer.student
                                                    .userPlacementTestUuid),
                                    'border-black': answer.answer != null,
                                }"
                            >
                                    <div>
                                        <template
                                            v-if="
                                                testObj.others
                                                    .applicationType == 'team'
                                            "
                                        >
                                            <div
                                                class="goodAnswerDiv"
                                                :class="{
                                                    inlineFlex:
                                                        answer.isGoodAnswer,
                                                }"
                                            >
                                                    <div class="dropdown">
                                                        <button
                                                            id="popOver"
                                                            class="btn gradient btn-default subBtn"
                                                            :class="{
                                                                'btn-default-border-warning':
                                                                    testObj
                                                                        .myProgression
                                                                        .team
                                                                        .uuid ==
                                                                    answer.team
                                                                        .uuid,
                                                            }"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                            :aria-label="
                                                                getDisplayName(
                                                                    answer.team
                                                                        .name
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                getDisplayName(
                                                                    answer.team
                                                                        .name
                                                                )
                                                            }}<i
                                                                v-if="
                                                                    answer.comment !=
                                                                        null ||
                                                                    (answer.attachments &&
                                                                        answer
                                                                            .attachments
                                                                            .length >
                                                                            0)
                                                                "
                                                                class="far fa-comment-dots marginLeft8"
                                                                aria-hidden="true"
                                                            />
                                                        </button>
                                                        <div
                                                            class="dropdown-menu dropdown-disucssion padding10"
                                                            aria-labelledby="popOver"
                                                        >
                                                            <div
                                                                v-if="
                                                                    question.displayComments
                                                                "
                                                            >
                                                                <div
                                                                    class="flexOnly marginBottom5 align-items"
                                                                >
                                                                    <i
                                                                        class="fas fa-comments marginRight5 greyText"
                                                                    />
                                                                    <span
                                                                        class="lineHeight08 fontBold"
                                                                        >Elaboration</span
                                                                    >
                                                                </div>
                                                                <div
                                                                    v-if="
                                                                        answer.comment
                                                                    "
                                                                >
                                                                    <span
                                                                        class="whiteSpacePreWrap"
                                                                        >{{
                                                                            truncate(
                                                                                answer.comment,
                                                                                400
                                                                            )
                                                                        }}</span
                                                                    >
                                                                    <template
                                                                        v-if="
                                                                            answer
                                                                                .comment
                                                                                .length >
                                                                            400
                                                                        "
                                                                    >
                                                                        <div
                                                                            class="marginTop10 blue pointer"
                                                                            aria-label="View More"
                                                                            @click="
                                                                                openModelInfoModal(
                                                                                    answer
                                                                                )
                                                                            "
                                                                        >
                                                                            View
                                                                            More
                                                                        </div>
                                                                    </template>
                                                                </div>
                                                                <div v-else>
                                                                    -
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="marginTop20"
                                                            >
                                                                <div
                                                                    class="flexOnly marginBottom5 align-items"
                                                                >
                                                                    <i
                                                                        class="fas fa-paperclip marginRight5 greyText"
                                                                    />
                                                                    <span
                                                                        class="lineHeight08 fontBold"
                                                                        >Attachment(s)</span
                                                                    >
                                                                </div>
                                                                <div>
                                                                    <template
                                                                        v-if="
                                                                            answer.attachments &&
                                                                            answer
                                                                                .attachments
                                                                                .length !=
                                                                                0
                                                                        "
                                                                    >
                                                                        <div
                                                                            v-for="(
                                                                                file,
                                                                                idx
                                                                            ) in answer.attachments"
                                                                            class="flex maxHeight100px"
                                                                        >
                                                                            <span
                                                                                class="marginRight5"
                                                                                >{{
                                                                                    idx +
                                                                                    1
                                                                                }}.</span
                                                                            >
                                                                            <a
                                                                                :href="
                                                                                    file.url
                                                                                "
                                                                                target="_blank"
                                                                                class="textLink"
                                                                                download
                                                                                :aria-label="
                                                                                    file.filename
                                                                                "
                                                                                >{{
                                                                                    file.filename
                                                                                }}</a
                                                                            >
                                                                        </div>
                                                                        <template
                                                                            v-if="
                                                                                answer
                                                                                    .attachments
                                                                                    .length >
                                                                                4
                                                                            "
                                                                        >
                                                                            <div
                                                                                class="marginTop10 blue pointer"
                                                                                aria-label="View More"
                                                                                @click="
                                                                                    openModelInfoModal(
                                                                                        answer
                                                                                    )
                                                                                "
                                                                            >
                                                                                View
                                                                                More
                                                                            </div>
                                                                        </template>
                                                                    </template>
                                                                    <template
                                                                        v-else
                                                                    >
                                                                        -
                                                                    </template>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="marginTop20 previewModelTeam"
                                                            >
                                                                <div
                                                                    class="flexOnly marginBottom5 align-items"
                                                                >
                                                                    <i
                                                                        class="fas fa-users marginRight8 greyText"
                                                                        aria-hidden="true"
                                                                    />
                                                                    <span
                                                                        class="lineHeight08 fontBold"
                                                                        >Team
                                                                        Members</span
                                                                    >
                                                                </div>
                                                                <div
                                                                    class="maxHeight100px"
                                                                >
                                                                    <ul
                                                                        v-for="(
                                                                            student,
                                                                            idx
                                                                        ) in answer
                                                                            .team
                                                                            .members"
                                                                    >
                                                                        <li>
                                                                            {{
                                                                                getDisplayName(
                                                                                    student.displayName,
                                                                                    'student',
                                                                                    idx
                                                                                )
                                                                            }}
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <template
                                                                    v-if="
                                                                        answer
                                                                            .team
                                                                            .members
                                                                            .length >
                                                                        4
                                                                    "
                                                                >
                                                                    <div
                                                                        class="blue pointer"
                                                                        aria-label="View More"
                                                                        @click="
                                                                            openModelInfoModal(
                                                                                answer
                                                                            )
                                                                        "
                                                                    >
                                                                        View
                                                                        More
                                                                    </div>
                                                                </template>
                                                            </div>
                                                        </div>
                                                    </div>
                                                <div
                                                    v-if="answer.isGoodAnswer"
                                                    class="statusTag statusTagSuccess subBtn goodAnswers"
                                                >
                                                    Good Answer<i
                                                        class="fa fa-thumbs-up marginLeft8"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div
                                                class="goodAnswerDiv"
                                                :class="{
                                                    inlineFlex:
                                                        answer.isGoodAnswer,
                                                }"
                                            >
                                                <template
                                                    v-if="
                                                        answer.comment !=
                                                            null ||
                                                        (answer.attachments &&
                                                            answer.attachments
                                                                .length > 0)
                                                    "
                                                >
                                                    <div class="dropdown">
                                                        <button
                                                            id="popOver"
                                                            class="btn gradient btn-default subBtn"
                                                            :class="{
                                                                'btn-default-border-warning':
                                                                    auth.user()
                                                                        .uuid ==
                                                                    answer
                                                                        .student
                                                                        .userPlacementTestUuid,
                                                            }"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                            :aria-label="
                                                                getDisplayName(
                                                                    answer
                                                                        .student
                                                                        .fullname,
                                                                    'student'
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                getDisplayName(
                                                                    answer
                                                                        .student
                                                                        .fullname,
                                                                    'student'
                                                                )
                                                            }}<i
                                                                class="far fa-comment-dots marginLeft8"
                                                                aria-hidden="true"
                                                            />
                                                        </button>
                                                        <div
                                                            class="dropdown-menu dropdown-disucssion padding10"
                                                            aria-labelledby="popOver"
                                                        >
                                                            <div
                                                                v-if="
                                                                    question.displayComments
                                                                "
                                                            >
                                                                <div
                                                                    class="flexOnly marginBottom5 align-items"
                                                                >
                                                                    <i
                                                                        class="fas fa-comments marginRight5 greyText"
                                                                    />
                                                                    <span
                                                                        class="lineHeight08 fontBold"
                                                                        >Elaboration</span
                                                                    >
                                                                </div>
                                                                <div
                                                                    v-if="
                                                                        answer.comment
                                                                    "
                                                                >
                                                                    <span
                                                                        class="whiteSpacePreWrap"
                                                                        >{{
                                                                            truncate(
                                                                                answer.comment,
                                                                                400
                                                                            )
                                                                        }}</span
                                                                    >
                                                                    <template
                                                                        v-if="
                                                                            answer
                                                                                .comment
                                                                                .length >
                                                                            400
                                                                        "
                                                                    >
                                                                        <div
                                                                            class="marginTop10 blue pointer"
                                                                            aria-label="View More"
                                                                            @click="
                                                                                openModelInfoModal(
                                                                                    answer,
                                                                                    'student'
                                                                                )
                                                                            "
                                                                        >
                                                                            View
                                                                            More
                                                                        </div>
                                                                    </template>
                                                                </div>
                                                                <div v-else>
                                                                    -
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="marginTop20"
                                                            >
                                                                <div
                                                                    class="flexOnly marginBottom5 align-items"
                                                                >
                                                                    <i
                                                                        class="fas fa-paperclip marginRight5 greyText"
                                                                    />
                                                                    <span
                                                                        class="lineHeight08 fontBold"
                                                                        >Attachment(s)</span
                                                                    >
                                                                </div>
                                                                <div>
                                                                    <template
                                                                        v-if="
                                                                            answer.attachments &&
                                                                            answer
                                                                                .attachments
                                                                                .length !=
                                                                                0
                                                                        "
                                                                    >
                                                                        <div
                                                                            v-for="(
                                                                                file,
                                                                                idx
                                                                            ) in answer.attachments"
                                                                            class="flex maxHeight100px"
                                                                        >
                                                                            <span
                                                                                class="marginRight5"
                                                                                >{{
                                                                                    idx +
                                                                                    1
                                                                                }}.</span
                                                                            >
                                                                            <a
                                                                                :href="
                                                                                    file.url
                                                                                "
                                                                                target="_blank"
                                                                                class="textLink"
                                                                                download
                                                                                :aria-label="
                                                                                    file.filename
                                                                                "
                                                                                >{{
                                                                                    file.filename
                                                                                }}</a
                                                                            >
                                                                        </div>
                                                                        <template
                                                                            v-if="
                                                                                answer
                                                                                    .attachments
                                                                                    .length >
                                                                                4
                                                                            "
                                                                        >
                                                                            <div
                                                                                class="marginTop10 blue pointer"
                                                                                aria-label="View More"
                                                                                @click="
                                                                                    openModelInfoModal(
                                                                                        answer,
                                                                                        'student'
                                                                                    )
                                                                                "
                                                                            >
                                                                                View
                                                                                More
                                                                            </div>
                                                                        </template>
                                                                    </template>
                                                                    <template
                                                                        v-else
                                                                    >
                                                                        -
                                                                    </template>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <button
                                                        class="btn gradient btn-default subBtn cursor-norm"
                                                        :class="{
                                                            'btn-default-border-warning':
                                                                auth.user()
                                                                    .uuid ==
                                                                answer.student
                                                                    .userPlacementTestUuid,
                                                        }"
                                                        :aria-label="
                                                            getDisplayName(
                                                                answer.student
                                                                    .fullname,
                                                                'student'
                                                            )
                                                        "
                                                    >
                                                        {{
                                                            getDisplayName(
                                                                answer.student
                                                                    .fullname,
                                                                'student'
                                                            )
                                                        }}
                                                    </button>
                                                </template>
                                                <div
                                                    v-if="answer.isGoodAnswer"
                                                    class="statusTag statusTagSuccess subBtn goodAnswers"
                                                >
                                                    Good Answer<i
                                                        class="fa fa-thumbs-up marginLeft8"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                    <div
                                        class="new-discussion-ans width100 marginTop20 flexOnly"
                                    >
                                        <div
                                            v-if="question.displayAnswer"
                                            class="minusWidth179px"
                                        >
                                            <template
                                                v-if="answer.answer != null"
                                            >
                                                <div
                                                    class="wordBreakWord whiteSpacePreWrap"
                                                >
                                                    <span
                                                        v-html="
                                                            truncate(
                                                                answer.answer,
                                                                800
                                                            )
                                                        "
                                                    />
                                                    <template
                                                        v-if="
                                                            answer.answer
                                                                .length > 800
                                                        "
                                                    >
                                                        <div
                                                            class="marginTop10 blue pointer"
                                                            aria-label="View More"
                                                            @click="
                                                                openQuestionModal(
                                                                    answer.answer
                                                                )
                                                            "
                                                        >
                                                            View More
                                                        </div>
                                                    </template>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div
                                                    class="fontItalic greyText"
                                                >
                                                    <template
                                                        v-if="
                                                            testObj.others
                                                                .applicationType ==
                                                            'team'
                                                        "
                                                    >
                                                        Team
                                                    </template>
                                                    <template v-else>
                                                        Student
                                                    </template>
                                                    Has Not Answered Yet.
                                                </div>
                                            </template>
                                        </div>
                                        <div
                                            v-if="question.displayVotes"
                                            class="width179px textAlignCenter"
                                        >
                                            <div class="fontBold">
                                                VOTE RESULTS
                                            </div>
                                            <div class="big-bold-text">
                                                {{ answer.votes.length }}
                                            </div>
                                        </div>
                                        <div
                                            v-if="question.displayComments"
                                            class="width400px textAlignCenter"
                                        >
                                            <div class="fontBold">COMMENT</div>
                                            <div
                                                class="marginTop10 textAlignLeft comment-comment"
                                            >
                                                <template
                                                    v-for="(
                                                        comment, comment_idx
                                                    ) in answer.comments"
                                                >
                                                    <div v-if="comment.team">
                                                        <div
                                                            class="marginBottom10"
                                                        >
                                                            <button
                                                                class="btn gradient btn-default subBtn"
                                                                :aria-label="
                                                                    getDisplayName(
                                                                        comment
                                                                            .team
                                                                            .name
                                                                    )
                                                                "
                                                                @click="
                                                                    openCommentInfoModal(
                                                                        comment
                                                                    )
                                                                "
                                                            >
                                                                {{
                                                                    getDisplayName(
                                                                        comment
                                                                            .team
                                                                            .name
                                                                    )
                                                                }}<i
                                                                    class="fa fa-info-circle marginLeft8"
                                                                    aria-hidden="true"
                                                                />
                                                            </button>
                                                        </div>
                                                        <span
                                                            class="whiteSpacePreWrap"
                                                            >{{
                                                                truncate(
                                                                    comment.comment,
                                                                    100
                                                                )
                                                            }}</span
                                                        >
                                                        <template
                                                            v-if="
                                                                comment.comment &&
                                                                comment.comment
                                                                    .length >
                                                                    100
                                                            "
                                                        >
                                                            <div
                                                                class="marginTop10 blue pointer"
                                                                aria-label="View More"
                                                                @click="
                                                                    openCommentInfoModal(
                                                                        comment
                                                                    )
                                                                "
                                                            >
                                                                View More
                                                            </div>
                                                        </template>
                                                    </div>
                                                    <div v-if="comment.student">
                                                        <div
                                                            class="marginBottom10"
                                                        >
                                                            <button
                                                                class="btn gradient btn-default subBtn"
                                                                :aria-label="
                                                                    getDisplayName(
                                                                        comment
                                                                            .student
                                                                            .fullname,
                                                                        'student'
                                                                    )
                                                                "
                                                                @click="
                                                                    openCommentInfoModal(
                                                                        comment,
                                                                        'student'
                                                                    )
                                                                "
                                                            >
                                                                {{
                                                                    getDisplayName(
                                                                        comment
                                                                            .student
                                                                            .fullname,
                                                                        'student'
                                                                    )
                                                                }}<i
                                                                    class="fa fa-info-circle marginLeft8"
                                                                    aria-hidden="true"
                                                                />
                                                            </button>
                                                        </div>
                                                        <span
                                                            class="whiteSpacePreWrap"
                                                            >{{
                                                                truncate(
                                                                    comment.comment,
                                                                    100
                                                                )
                                                            }}</span
                                                        >
                                                        <template
                                                            v-if="
                                                                comment.comment &&
                                                                comment.comment
                                                                    .length >
                                                                    100
                                                            "
                                                        >
                                                            <div
                                                                class="marginTop10 blue pointer"
                                                                aria-label="View More"
                                                                @click="
                                                                    openCommentInfoModal(
                                                                        comment,
                                                                        'student'
                                                                    )
                                                                "
                                                            >
                                                                View More
                                                            </div>
                                                        </template>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>

                                    <template v-if="answer.answer != null">
                                        <div
                                            v-if="question.displayVotes"
                                            class="discussion-bar marginTop20 width100"
                                        >
                                            <template
                                                v-if="
                                                    percentVoted(answer_idx) >=
                                                    10
                                                "
                                            >
                                                <div
                                                    class="barProgress"
                                                    :style="
                                                        'width:' +
                                                        percentVoted(
                                                            answer_idx
                                                        ) +
                                                        '%'
                                                    "
                                                >
                                                        {{
                                                            percentVoted(
                                                                answer_idx
                                                            )
                                                        }}%
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div class="col-xs-12">
                                                        {{
                                                            percentVoted(
                                                                answer_idx
                                                            )
                                                        }}%
                                                </div>
                                            </template>
                                        </div>
                                        <div
                                            v-if="question.displayVotes"
                                            class="marginTop10 flexOnly width100 paddingLeft10"
                                        >
                                            <template
                                                v-if="
                                                    testObj.others
                                                        .applicationType ==
                                                    'team'
                                                "
                                            >
                                                <div
                                                    class="responseFont col-xs-2 maxWidth50px"
                                                >
                                                    {{ answer.votes.length }}
                                                    VOTE<template
                                                        v-if="
                                                            answer.votes
                                                                .length > 1
                                                        "
                                                    >
                                                        S
                                                    </template>
                                                </div>

                                                <div class="discussion-buttons">
                                                    <template
                                                        v-for="(
                                                            model, model_idx
                                                        ) in answer.votes"
                                                    >
                                                        <div class="dropdown">
                                                            <button
                                                                id="popOver"
                                                                class="btn gradient btn-default subBtn marginBottom10"
                                                                :class="{
                                                                    'btn-default-border-warning':
                                                                        testObj
                                                                            .myProgression
                                                                            .team
                                                                            .uuid ==
                                                                        model.uuid,
                                                                }"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                                :aria-label="
                                                                    getDisplayName(
                                                                        model.name,
                                                                        'team',
                                                                        model_idx
                                                                    )
                                                                "
                                                            >
                                                                {{
                                                                    getDisplayName(
                                                                        truncate(
                                                                            model.name
                                                                        ),
                                                                        'team',
                                                                        model_idx
                                                                    )
                                                                }}
                                                            </button>
                                                            <div
                                                                class="dropdown-menu dropdown-disucssion padding10"
                                                                aria-labelledby="popOver"
                                                            >
                                                                <div
                                                                    v-if="
                                                                        testObj
                                                                            .others
                                                                            .applicationType ==
                                                                        'team'
                                                                    "
                                                                    class="previewModelTeam"
                                                                >
                                                                    <div
                                                                        class="flexOnly marginBottom5 align-items"
                                                                    >
                                                                        <i
                                                                            class="fas fa-users marginRight8 greyText"
                                                                            aria-hidden="true"
                                                                        />
                                                                        <span
                                                                            class="lineHeight08 fontBold"
                                                                            >Team
                                                                            Members</span
                                                                        >
                                                                    </div>
                                                                    <div
                                                                        class="maxHeight100px"
                                                                    >
                                                                        <ul
                                                                            v-for="(
                                                                                student,
                                                                                idx
                                                                            ) in model.members"
                                                                        >
                                                                            <li>
                                                                                {{
                                                                                    getDisplayName(
                                                                                        student.displayName,
                                                                                        'student',
                                                                                        idx
                                                                                    )
                                                                                }}
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <template
                                                                        v-if="
                                                                            model
                                                                                .members
                                                                                .length >
                                                                            4
                                                                        "
                                                                    >
                                                                        <div
                                                                            class="blue pointer"
                                                                            aria-label="View More"
                                                                            @click="
                                                                                openTeamInfoModal(
                                                                                    model
                                                                                )
                                                                            "
                                                                        >
                                                                            View
                                                                            More
                                                                        </div>
                                                                    </template>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </div>
                                            </template>

                                            <template v-else>
                                                <div
                                                    class="responseFont col-xs-2 maxWidth50px"
                                                >
                                                    {{ answer.votes.length }}
                                                    VOTE<template
                                                        v-if="
                                                            answer.votes
                                                                .length > 1
                                                        "
                                                    >
                                                        S
                                                    </template>
                                                </div>

                                                <div class="discussion-buttons">
                                                    <template
                                                        v-for="(
                                                            model, model_idx
                                                        ) in answer.votes"
                                                    >
                                                        <div class="dropdown">
                                                            <button
                                                                id="popOver"
                                                                class="btn gradient btn-default subBtn marginBottom10"
                                                                :class="{
                                                                    'btn-default-border-warning':
                                                                        testObj.uuid ==
                                                                        model.uuid,
                                                                }"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                                :aria-label="
                                                                    model.fullname
                                                                "
                                                            >
                                                                {{
                                                                    truncate(
                                                                        model.fullname
                                                                    )
                                                                }}
                                                            </button>
                                                            <div
                                                                class="dropdown-menu dropdown-disucssion padding10"
                                                                aria-labelledby="popOver"
                                                            >
                                                                {{
                                                                    model.fullname
                                                                }}
                                                            </div>
                                                        </div>
                                                    </template>
                                                </div>
                                            </template>
                                        </div>
                                    </template>
                            </div>
                        </template>
                    </template>
                </div>

                <div class="hidden-md hidden-lg">
                    <template v-for="(answer, answer_idx) in question.answers">
                        <template v-if="answer.isSelected">
                            <div
                                v-if="answer.answer != null"
                                class="flex new-discussion"
                                :class="{
                                    'solid-warning':
                                        (testObj.others.applicationType ==
                                            'team' &&
                                            testObj.myProgression.team.uuid ==
                                                answer.team.uuid) ||
                                        (testObj.others.applicationType ==
                                            'individual' &&
                                            auth.user().uuid ==
                                                answer.student
                                                    .userPlacementTestUuid),
                                    'border-black': answer.answer != null,
                                }"
                            >
                                    <div>
                                        <template
                                            v-if="
                                                testObj.others
                                                    .applicationType == 'team'
                                            "
                                        >
                                            <div
                                                class="goodAnswerDiv"
                                                :class="{
                                                    inlineFlex:
                                                        answer.isGoodAnswer,
                                                }"
                                            >
                                                    <button
                                                        id="popOver"
                                                        class="btn gradient btn-default subBtn"
                                                        :class="{
                                                            'btn-default-border-warning':
                                                                testObj
                                                                    .myProgression
                                                                    .team
                                                                    .uuid ==
                                                                answer.team
                                                                    .uuid,
                                                        }"
                                                        :aria-label="
                                                            getDisplayName(
                                                                answer.team.name
                                                            )
                                                        "
                                                        @click="
                                                            openModelInfoModal(
                                                                answer
                                                            )
                                                        "
                                                    >
                                                        {{
                                                            getDisplayName(
                                                                answer.team.name
                                                            )
                                                        }}<i
                                                            v-if="
                                                                answer.comment !=
                                                                    null ||
                                                                (answer.attachments &&
                                                                    answer
                                                                        .attachments
                                                                        .length >
                                                                        0)
                                                            "
                                                            class="far fa-comment-dots marginLeft8"
                                                            aria-hidden="true"
                                                        />
                                                    </button>
                                                <div
                                                    v-if="answer.isGoodAnswer"
                                                    class="statusTag statusTagSuccess subBtn goodAnswers"
                                                >
                                                    Good Answer<i
                                                        class="fa fa-thumbs-up marginLeft8"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div
                                                class="goodAnswerDiv"
                                                :class="{
                                                    inlineFlex:
                                                        answer.isGoodAnswer,
                                                }"
                                            >
                                                    <button
                                                        class="btn gradient btn-default subBtn"
                                                        :class="{
                                                            'btn-default-border-warning':
                                                                auth.user()
                                                                    .uuid ==
                                                                answer.student
                                                                    .userPlacementTestUuid,
                                                        }"
                                                        :aria-label="
                                                            getDisplayName(
                                                                answer.student
                                                                    .fullname,
                                                                'student'
                                                            )
                                                        "
                                                        @click="
                                                            openModelInfoModal(
                                                                answer,
                                                                'student'
                                                            )
                                                        "
                                                    >
                                                        {{
                                                            getDisplayName(
                                                                answer.student
                                                                    .fullname,
                                                                'student'
                                                            )
                                                        }}<i
                                                            v-if="
                                                                answer.comment !=
                                                                    null ||
                                                                (answer.attachments &&
                                                                    answer
                                                                        .attachments
                                                                        .length >
                                                                        0)
                                                            "
                                                            class="far fa-comment-dots marginLeft8"
                                                            aria-hidden="true"
                                                        />
                                                    </button>
                                                <div
                                                    v-if="answer.isGoodAnswer"
                                                    class="statusTag statusTagSuccess subBtn goodAnswers"
                                                >
                                                    Good Answer<i
                                                        class="fa fa-thumbs-up marginLeft8"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                    <div
                                        class="new-discussion-ans width100 marginTop20"
                                    >
                                        <div v-if="question.displayAnswer">
                                            <template
                                                v-if="answer.answer != null"
                                            >
                                                <div
                                                    class="wordBreakWord whiteSpacePreWrap"
                                                >
                                                    <span
                                                        v-html="
                                                            truncate(
                                                                answer.answer,
                                                                800
                                                            )
                                                        "
                                                    />
                                                    <template
                                                        v-if="
                                                            answer.answer
                                                                .length > 800
                                                        "
                                                    >
                                                        <div
                                                            class="marginTop10 blue pointer"
                                                            aria-label="View More"
                                                            @click="
                                                                openQuestionModal(
                                                                    answer.answer
                                                                )
                                                            "
                                                        >
                                                            View More
                                                        </div>
                                                    </template>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div
                                                    class="fontItalic greyText"
                                                >
                                                    <template
                                                        v-if="
                                                            testObj.others
                                                                .applicationType ==
                                                            'team'
                                                        "
                                                    >
                                                        Team
                                                    </template>
                                                    <template v-else>
                                                        Student
                                                    </template>
                                                    Has Not Answered Yet.
                                                </div>
                                            </template>
                                        </div>
                                        <div
                                            v-if="question.displayVotes"
                                            class="textAlignCenter"
                                        >
                                            <div class="fontBold">
                                                VOTE RESULTS
                                            </div>
                                            <div class="big-bold-text">
                                                {{ answer.votes.length }}
                                            </div>
                                        </div>
                                        <div
                                            v-if="question.displayComments"
                                            class="textAlignCenter"
                                        >
                                            <div class="fontBold">COMMENT</div>
                                            <div
                                                class="marginTop10 textAlignLeft comment-comment"
                                            >
                                                <template
                                                    v-for="(
                                                        comment, comment_idx
                                                    ) in answer.comments"
                                                >
                                                    <div v-if="comment.team">
                                                        <div
                                                            class="marginBottom10"
                                                        >
                                                            <button
                                                                class="btn gradient btn-default subBtn"
                                                                :aria-label="
                                                                    getDisplayName(
                                                                        comment
                                                                            .team
                                                                            .name
                                                                    )
                                                                "
                                                                @click="
                                                                    openCommentInfoModal(
                                                                        comment
                                                                    )
                                                                "
                                                            >
                                                                {{
                                                                    getDisplayName(
                                                                        comment
                                                                            .team
                                                                            .name
                                                                    )
                                                                }}<i
                                                                    class="fa fa-info-circle marginLeft8"
                                                                    aria-hidden="true"
                                                                />
                                                            </button>
                                                        </div>
                                                        <span
                                                            class="whiteSpacePreWrap"
                                                            >{{
                                                                truncate(
                                                                    comment.comment,
                                                                    100
                                                                )
                                                            }}</span
                                                        >
                                                        <template
                                                            v-if="
                                                                comment.comment &&
                                                                comment.comment
                                                                    .length >
                                                                    100
                                                            "
                                                        >
                                                            <div
                                                                class="marginTop10 blue pointer"
                                                                aria-label="View More"
                                                                @click="
                                                                    openCommentInfoModal(
                                                                        comment
                                                                    )
                                                                "
                                                            >
                                                                View More
                                                            </div>
                                                        </template>
                                                    </div>
                                                    <div v-if="comment.student">
                                                        <div
                                                            class="marginBottom10"
                                                        >
                                                            <button
                                                                class="btn gradient btn-default subBtn"
                                                                :aria-label="
                                                                    getDisplayName(
                                                                        comment
                                                                            .student
                                                                            .fullname,
                                                                        'student'
                                                                    )
                                                                "
                                                                @click="
                                                                    openCommentInfoModal(
                                                                        comment,
                                                                        'student'
                                                                    )
                                                                "
                                                            >
                                                                {{
                                                                    getDisplayName(
                                                                        comment
                                                                            .student
                                                                            .fullname,
                                                                        'student'
                                                                    )
                                                                }}<i
                                                                    class="fa fa-info-circle marginLeft8"
                                                                    aria-hidden="true"
                                                                />
                                                            </button>
                                                        </div>
                                                        <span
                                                            class="whiteSpacePreWrap"
                                                            >{{
                                                                truncate(
                                                                    comment.comment,
                                                                    100
                                                                )
                                                            }}</span
                                                        >
                                                        <template
                                                            v-if="
                                                                comment.comment &&
                                                                comment.comment
                                                                    .length >
                                                                    100
                                                            "
                                                        >
                                                            <div
                                                                class="marginTop10 blue pointer"
                                                                aria-label="View More"
                                                                @click="
                                                                    openCommentInfoModal(
                                                                        comment,
                                                                        'student'
                                                                    )
                                                                "
                                                            >
                                                                View More
                                                            </div>
                                                        </template>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>

                                    <template v-if="answer.answer != null">
                                        <div
                                            v-if="question.displayVotes"
                                            class="discussion-bar marginTop20 width100"
                                        >
                                            <template
                                                v-if="
                                                    percentVoted(answer_idx) >=
                                                    10
                                                "
                                            >
                                                <div
                                                    class="barProgress"
                                                    :style="
                                                        'width:' +
                                                        percentVoted(
                                                            answer_idx
                                                        ) +
                                                        '%'
                                                    "
                                                >
                                                        {{
                                                            percentVoted(
                                                                answer_idx
                                                            )
                                                        }}%
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div class="col-xs-12">
                                                        {{
                                                            percentVoted(
                                                                answer_idx
                                                            )
                                                        }}%
                                                </div>
                                            </template>
                                        </div>
                                        <div
                                            v-if="question.displayVotes"
                                            class="marginTop10 flexOnly width100 paddingLeft10"
                                        >
                                            <template
                                                v-if="
                                                    testObj.others
                                                        .applicationType ==
                                                    'team'
                                                "
                                            >
                                                <div
                                                    class="responseFont col-xs-2 maxWidth50px"
                                                >
                                                    {{ answer.votes.length }}
                                                    VOTE<template
                                                        v-if="
                                                            answer.votes
                                                                .length > 1
                                                        "
                                                    >
                                                        S
                                                    </template>
                                                </div>

                                                <div class="discussion-buttons">
                                                    <template
                                                        v-for="(
                                                            model, model_idx
                                                        ) in answer.votes"
                                                    >
                                                        <div class="dropdown">
                                                            <button
                                                                id="popOver"
                                                                class="btn gradient btn-default subBtn marginBottom10"
                                                                :class="{
                                                                    'btn-default-border-warning':
                                                                        testObj
                                                                            .myProgression
                                                                            .team
                                                                            .uuid ==
                                                                        model.uuid,
                                                                }"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                                :aria-label="
                                                                    getDisplayName(
                                                                        model.name,
                                                                        'team',
                                                                        model_idx
                                                                    )
                                                                "
                                                            >
                                                                {{
                                                                    getDisplayName(
                                                                        truncate(
                                                                            model.name
                                                                        ),
                                                                        'team',
                                                                        model_idx
                                                                    )
                                                                }}
                                                            </button>
                                                            <div
                                                                class="dropdown-menu dropdown-disucssion padding10"
                                                                aria-labelledby="popOver"
                                                            >
                                                                <div
                                                                    v-if="
                                                                        testObj
                                                                            .others
                                                                            .applicationType ==
                                                                        'team'
                                                                    "
                                                                    class="previewModelTeam"
                                                                >
                                                                    <div
                                                                        class="flexOnly marginBottom5 align-items"
                                                                    >
                                                                        <i
                                                                            class="fas fa-users marginRight8 greyText"
                                                                            aria-hidden="true"
                                                                        />
                                                                        <span
                                                                            class="lineHeight08 fontBold"
                                                                            >Team
                                                                            Members</span
                                                                        >
                                                                    </div>
                                                                    <div
                                                                        class="maxHeight100px"
                                                                    >
                                                                        <ul
                                                                            v-for="(
                                                                                student,
                                                                                idx
                                                                            ) in model.members"
                                                                        >
                                                                            <li>
                                                                                {{
                                                                                    getDisplayName(
                                                                                        student.displayName,
                                                                                        'student',
                                                                                        idx
                                                                                    )
                                                                                }}
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <template
                                                                        v-if="
                                                                            model
                                                                                .members
                                                                                .length >
                                                                            4
                                                                        "
                                                                    >
                                                                        <div
                                                                            class="blue pointer"
                                                                            @click="
                                                                                openTeamInfoModal(
                                                                                    model
                                                                                )
                                                                            "
                                                                        >
                                                                            View
                                                                            More
                                                                        </div>
                                                                    </template>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </div>
                                            </template>

                                            <template v-else>
                                                <div
                                                    class="responseFont col-xs-2 maxWidth50px"
                                                >
                                                    {{ answer.votes.length }}
                                                    VOTE<template
                                                        v-if="
                                                            answer.votes
                                                                .length > 1
                                                        "
                                                    >
                                                        S
                                                    </template>
                                                </div>

                                                <div class="discussion-buttons">
                                                    <template
                                                        v-for="(
                                                            model, model_idx
                                                        ) in answer.votes"
                                                    >
                                                        <div class="dropdown">
                                                            <button
                                                                id="popOver"
                                                                class="btn gradient btn-default subBtn marginBottom10"
                                                                :class="{
                                                                    'btn-default-border-warning':
                                                                        testObj.uuid ==
                                                                        model.uuid,
                                                                }"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                                :aria-label="
                                                                    model.fullname
                                                                "
                                                            >
                                                                {{
                                                                    getDisplayName(
                                                                        truncate(
                                                                            model.fullname
                                                                        ),
                                                                        'student'
                                                                    )
                                                                }}
                                                            </button>
                                                            <div
                                                                class="dropdown-menu dropdown-disucssion padding10"
                                                                aria-labelledby="popOver"
                                                            >
                                                                {{
                                                                    getDisplayName(
                                                                        truncate(
                                                                            model.fullname
                                                                        ),
                                                                        'student'
                                                                    )
                                                                }}
                                                            </div>
                                                        </div>
                                                    </template>
                                                </div>
                                            </template>
                                        </div>
                                    </template>
                            </div>
                        </template>
                    </template>
                </div>
            </template>
        </div>
        <div
            :id="'eGalleryPreparingModal' + question.uuid"
            class="modal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryPreparingModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'eGalleryPreparingModal' + question.uuid + '-title'" class="modal-title">It's time for e-Gallery Walk!</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 textAlignCenter">
                            <i class="fas fa-edit blue font30" aria-hidden="true" />
                        </div>
                        <!--<div class="col-xs-12 textAlignCenter">
						<i class="fas fa-ellipsis-h blue font20"></i>
					</div>-->
                        <div class="col-xs-12 textAlignCenter">
                            Your teacher is preparing
                            <strong>e-Gallery Walk</strong> now.
                        </div>
                        <div class="col-xs-12 textAlignCenter">Please get ready to participate!</div>
                    </div>
                </div>
            </div>
        </div>
        <div
            :id="'eGalleryAttachmentsModal' + question.uuid"
            class="modal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryAttachmentsModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'eGalleryAttachmentsModal' + question.uuid + '-title'" class="modal-title">Attachments</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 col-md-6">
                            <div v-if="previewAttachments && previewAttachments.length != 0" class="flex align-items">
                                <div class="col-xs-12">
                                    <!-- <span style="font-weight:bold;">Uploaded Files</span> -->
                                    <div v-for="(file, idx) in previewAttachments" class="flexSpaceBetween">
                                        <div>
                                            <span>{{ idx + 1 }}.</span>
                                            <span>
                                                <i class="fa fa-file-image-o" aria-hidden="true" />
                                            </span>
                                            <a :href="file.url" target="_blank" download :aria-label="file.filename">{{ file.filename }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <template v-else>
                                <div>-</div>
                            </template>
                        </div>
                        <div class="col-xs-12 buttonGroup marginTop20">
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer" />
                </div>
            </div>
        </div>
        <div
            :id="'eGalleryTeamInfoModal' + question.uuid"
            class="modal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryTeamInfoModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2
                            :id="
                                'eGalleryTeamInfoModal' +
                                question.uuid +
                                '-title'
                            "
                            class="modal-title"
                        >
                            View {{ getDisplayName(previewTeam.name) }}
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div v-if="previewModel.type == 'team'" class="previewModelTeam">
                            <div class="flexOnly marginBottom5 align-items">
                                <i class="fas fa-users marginRight8 greyText" aria-hidden="true" />
                                <span class="lineHeight08 fontBold">Team Members</span>
                            </div>
                            <div>
                                <ul
                                    v-for="(
                                        student, idx
                                    ) in previewTeam.members"
                                >
                                    <li>
                                        {{
                                            getDisplayName(
                                                student.displayName,
                                                'student',
                                                idx
                                            )
                                        }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-xs-12 marginTop20 buttonGroup">
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer" />
                </div>
            </div>
        </div>

        <div
            :id="'eGalleryVotersInfoModal' + question.uuid"
            class="modal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryVotersInfoModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'eGalleryVotersInfoModal' + question.uuid + '-title'" class="modal-title">Voted By</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12">
                            <template v-if="testObj.others.applicationType == 'individual'">
                                <ul v-for="(student, idx) in previewVoters">
                                    <li>
                                        {{
                                            getDisplayName(
                                                student.displayName,
                                                'student',
                                                idx
                                            )
                                        }}
                                    </li>
                                </ul>
                            </template>
                            <template v-if="testObj.others.applicationType == 'team'">
                                <ul v-for="(team, idx) in previewVoters">
                                    <li>
                                        {{
                                            getDisplayName(
                                                team.name,
                                                'team',
                                                idx
                                            )
                                        }}
                                    </li>
                                </ul>
                            </template>
                        </div>
                        <div class="col-xs-12 marginTop20 buttonGroup">
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer" />
                </div>
            </div>
        </div>

        <div
            :id="'eGalleryQnsModal' + question.uuid"
            class="modal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryQnsModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'eGalleryQnsModal' + question.uuid + '-title'" class="modal-title">
                            <i class="fa fa-arrows marginRight8" aria-hidden="true" />View Answer
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 wordBreakWord whiteSpacePreWrap imgMax100" v-html="questionModal" />
                        <div class="col-xs-12 buttonGroup marginTop30">
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer" />
                </div>
            </div>
        </div>

        <div
            :id="'eGalleryModelModal' + question.uuid"
            class="modal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryModelModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2
                            :id="
                                'eGalleryModelModal' + question.uuid + '-title'
                            "
                            class="modal-title"
                        >
                            <template v-if="previewModel.type == 'team'">
                                View
                                {{ getDisplayName(previewModel.team.name) }}
                            </template>
                            <template v-if="previewModel.type == 'student'">
                                View {{ previewModel.student.fullname }}
                            </template>
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="flexOnly marginBottom5 align-items">
                                <i class="fas fa-comments marginRight8 greyText" aria-hidden="true" />
                                <span class="lineHeight08 fontBold">Elaboration</span>
                            </div>
                            <div v-if="previewModel.comment">
                                <span class="whiteSpacePreWrap">{{ previewModel.comment }}</span>
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div class="marginTop20">
                            <div class="flexOnly marginBottom5 align-items">
                                <i class="fas fa-paperclip marginRight8 greyText" aria-hidden="true" />
                                <span class="lineHeight08 fontBold">Attachment(s)</span>
                            </div>
                            <div>
                                <template v-if="previewModel.attachments && previewModel.attachments.length != 0">
                                    <div v-for="(file, idx) in previewModel.attachments" class="flex">
                                        <span class="marginRight5">{{ idx + 1 }}.</span>
                                        <a :href="file.url" target="_blank" class="textLink" download :aria-label="file.filename">{{ file.filename }}</a>
                                    </div>
                                </template>
                                <template v-else> - </template>
                            </div>
                        </div>
                        <div v-if="previewModel.type == 'team'" class="marginTop20 previewModelTeam">
                            <div class="flexOnly marginBottom5 align-items">
                                <i class="fas fa-users marginRight8 greyText" aria-hidden="true" />
                                <span class="lineHeight08 fontBold">Team Members</span>
                            </div>
                            <div>
                                <ul
                                    v-for="(student, idx) in previewModel.team
                                        .members"
                                >
                                    <li>
                                        {{
                                            getDisplayName(
                                                student.displayName,
                                                'student',
                                                idx
                                            )
                                        }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-xs-12 marginTop20 buttonGroup">
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer" />
                </div>
            </div>
        </div>

        <div
            :id="'eGalleryWalkModal' + question.uuid"
            class="modal"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryWalkModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="preview modal-content">
                    <div class="modal-header">
                        <h2 :id="'eGalleryWalkModal' + question.uuid + '-title'" class="modal-title">e-Gallery WALK</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 textAlignCenter">
                            <div class="marginBottom20">The answer you have selected is from</div>
                            <button class="btn btn-default subBtn cursor-norm">
                                <template v-for="(model, model_idx) in question.answers">
                                    <template v-if="model.myVote == true">
                                        <template v-if="model.team">
                                            {{
                                                getDisplayName(model.team.name)
                                            }}
                                        </template>
                                        <template v-if="model.student">
                                            {{ model.student.fullname }}
                                        </template>
                                    </template>
                                </template>
                            </button>
                            <div class="marginTop20">You will <span class="fontBold">NOT</span> be able to change your vote once you have submitted it</div>
                        </div>
                        <div class="flex marginTop30">
                            <button id="modal-dismiss-btn" class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                            <button id="modal-logout-btn" class="btn btn-success mainBtn" data-dismiss="modal" aria-label="Vote" @click.prevent="submit()">
                                Vote
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            :id="'eGalleryCommentModal' + question.uuid"
            class="modal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'eGalleryCommentModal' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2
                            :id="
                                'eGalleryCommentModal' +
                                question.uuid +
                                '-title'
                            "
                            class="modal-title"
                        >
                            <template v-if="previewModel.type == 'team'">
                                View
                                {{ getDisplayName(previewModel.team.name) }}
                            </template>
                            <template v-if="previewModel.type == 'student'">
                                View
                                {{
                                    getDisplayName(
                                        previewModel.student.fullname,
                                        'student'
                                    )
                                }}
                            </template>
                        </h2>
                    </div>
                    <div class="modal-body">
                        <div>
                            <div class="flexOnly marginBottom5 align-items">
                                <i class="fas fa-comments marginRight8 greyText" aria-hidden="true" />
                                <span class="lineHeight08 fontBold">Comment</span>
                            </div>
                            <div v-if="previewModel.comment">
                                <span class="whiteSpacePreWrap">{{ previewModel.comment }}</span>
                            </div>
                            <div v-else>-</div>
                        </div>
                        <div v-if="previewModel.type == 'team'" class="marginTop20 previewModelTeam">
                            <div class="flexOnly marginBottom5 align-items">
                                <i class="fas fa-users marginRight8 greyText" aria-hidden="true" />
                                <span class="lineHeight08 fontBold">Team Members</span>
                            </div>
                            <div>
                                <ul
                                    v-for="(student, idx) in previewModel.team
                                        .members"
                                >
                                    <li>
                                        {{
                                            getDisplayName(
                                                student.displayName,
                                                'student',
                                                idx
                                            )
                                        }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-xs-12 marginTop20 buttonGroup">
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer" />
                </div>
            </div>
        </div>
        

        
    </div>
</template>

<script>
import KrAuth from '../../../../../../../../../components/auth/auth';
import { testMixins } from './../../../../../../../../../mixins/test';
import { setResizeListeners } from './../../../../../../../../../mixins/auto-resize';
export default {
    mixins: [testMixins],
    props: ['testObj', 'question'],

    data() {
        return {
            auth: new KrAuth(),
            previewTeam: {},
            previewVoters: {},
            previewAttachments: [],
            previewModel: {
                type: 'team',
                student: {},
                team: {},
                comment: {},
                attachments: [],
            },
            questionModal: '',
            debouncedCommentAutosaves: {},
            lastEGalleryStatus: false,
        };
    },
    computed: {
        isAnonymous() {
            return this.testObj.enableAnonymityInEGalleryWalk;
        },
    },
    watch: {
        question: {
            handler: function () {
                var that = this;
                if (
                    [null, 'preparing', 'cancelled'].includes(this.question.eGallery.status) &&
                    this.testObj.eGalleryProgression.currentQuestionUuid == this.question.uuid
                ) {
                    $('#eGalleryPreparingModal' + this.question.uuid).modal('show');
                } else {
                    Vue.nextTick(function () {
                        $('#eGalleryPreparingModal' + that.question.uuid).modal('hide');
                    });
                }
            },
            deep: true,
        },
        'question.answers': {
            handler: function () {
                this.init();
            },
            deep: true,
        },
    },
    created() {
        this.init();
    },
    mounted() {
        setResizeListeners(this.$el, '.js-autoresize');
    },
    methods: {
        getDisplayName(name = '', type = 'team', idx = null) {
            if (this.isAnonymous) {
                return (
                    'Anonymous ' +
                    (type == 'team' ? 'Team' : 'Student') +
                    (idx != null ? ' ' + (idx + 1) : '')
                );
            } else return name;
        },
        isMyAnswer(answer) {
            if (this.testObj.others.applicationType == 'team') {
                return this.testObj.myProgression.team.uuid == answer.team.uuid;
            } else {
                return this.auth.user().uuid == answer.student.userPlacementTestUuid;
            }
        },
        canSubmitVote() {
            if (!(this.testObj.others.applicationType == 'individual' || this.isTeamLeader)) {
                return false;
            }
            let hasTouched = false;
            for (var i = 0; i < this.question.answers.length; i++) {
                if (this.question.answers[i]._isTouched) {
                    hasTouched = true;
                    break;
                }
            }
            if (!hasTouched) {
                return false;
            }
            let hasVoted = false;
            for (var i = 0; i < this.question.answers.length; i++) {
                if (this.isMyAnswer(this.question.answers[i]) || !this.question.answers[i].uuid) {
                    continue;
                }
                if (this.question.answers[i].myVote) {
                    hasVoted = true;
                }
                if (this.question.eGalleryWalkSettings.teamsMustCommentForAllAnswers) {
                    if (!this.question.answers[i].myComment) {
                        return false;
                    }
                }
                if (this.question.eGalleryWalkSettings.teamsMustCommentForVotedAnswersOnly) {
                    if (this.question.answers[i].myVote) {
                        if (!this.question.answers[i].myComment) {
                            return false;
                        }
                    }
                }
                // if (
                //   !this.question.eGalleryWalkSettings.teamsMustCommentForAllAnswers &&
                //   !this.question.eGalleryWalkSettings
                //     .teamsMustCommentForVotedAnswersOnly
                // ) {
                //   if (this.question.answers[i].myVote) {
                //     return true;
                //   }
                // }
            }
            return hasVoted;
        },
        submitComment(answer) {
            var that = this;
            axios
                .post('student/tests/' + this.testObj.uuid + '/discussions/save-comment', { answerUuid: answer.uuid, answerComment: answer.myComment })
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'comment has been updated',
                    });
                })
                .catch(function (errors) {
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Failed to update comment, please try again later',
                    });
                });
        },
        reorderedAnswers(q) {
            if (q.type != 'openended' || !['released'].includes(q.eGallery.status)) {
                return;
            }
            let question = _.cloneDeep(q);
            for (var i = 0; i < question.answers.length; i++) {
                question.answers[i]._name = question.answers[i].team ? question.answers[i].team.name : question.answers[i].student.fullname;
                let votes = question.answers[i].votes || [];
                question.answers[i]._voteCount = votes.length;
            }
            this.natSort(question.answers, '_name');

            for (var i = 0; i < question.answers.length; i++) {
                question.answers[i]._natSortIndex = i;
            }
            question.answers = _.orderBy(question.answers, ['_voteCount', '_natSortIndex'], ['desc', 'asc']);
            return question.answers;
        },
        openAttachmentsModal(attachments) {
            var that = this;
            this.previewAttachments = attachments;
            $('#eGalleryAttachmentsModal' + this.question.uuid).modal('show');
            $('#eGalleryAttachmentsModal' + this.question.uuid).on('hidden.bs.modal', function () {
                that.previewAttachments = [];
            });
        },
        openTeamInfoModal(team) {
            var that = this;
            this.previewTeam = team;
            $('#eGalleryTeamInfoModal' + this.question.uuid).modal('show');

            $('#eGalleryTeamInfoModal' + this.question.uuid).on('hidden.bs.modal', function () {
                that.previewTeam = {};
            });
        },
        openVotersInfoModal(voters) {
            var that = this;
            this.previewVoters = voters;
            $('#eGalleryVotersInfoModal' + this.question.uuid).modal('show');

            $('#eGalleryVotersInfoModal' + this.question.uuid).on('hidden.bs.modal', function () {
                that.previewVoters = {};
            });
        },
        highlightAnswerKeywords(answer = '') {
            var res = answer.split(' ');
            var ret = '';
            for (var i = 0; i < res.length; i++) {
                if (
                    _.find(this.question.tags, function (o) {
                        return o.toLowerCase() === res[i];
                    })
                ) {
                    ret += '<span class="highlight">' + res[i] + '</span>&nbsp;';
                } else {
                    ret += res[i] + '&nbsp;';
                }
            }
            return ret;
            //return ret;
        },
        toggleBestAnswer(answer) {
            answer._isTouched = true;
            for (var i = 0; i < this.question.answers.length; i++) {
                let b = this.question.answers[i];
                if (answer.uuid != b.uuid) {
                    if (b.myVote) {
                        b.myVote = false;
                        b._isTouched = true;
                    }
                }
            }
        },
        isRankTaken(question, rank) {
            let answerWithRank = _.find(question.answers, function (o) {
                return o.myRank == rank;
            });
            if (answerWithRank) {
                return true;
            }
            return false;
        },
        init() {
            if (this.lastEGalleryStatus === false) {
                this.lastEGalleryStatus = this.question.eGallery.status;
            }
            if ([null, 'preparing'].includes(this.lastEGalleryStatus) && this.question.eGallery.status == 'ongoing') {
                this.clear();
            }
            this.lastEGalleryStatus = this.question.eGallery.status;
            for (var i = 0; i < this.question.answers.length; i++) {
                let answer = this.question.answers[i];
                if (!answer.myComment) {
                    answer._editComment = true;
                } else {
                    //answer._editComment = false;
                }
                //create debounce function for comment
                if (!this.debouncedCommentAutosaves[answer.uuid] && answer.uuid) {
                    this.debouncedCommentAutosaves[answer.uuid] = _.debounce(this.submitComment, 1000);
                }
                if (!answer._isTouched) {
                    answer._isTouched = false;
                }
            }
            var that = this;

            if (['preparing'].includes(this.question.eGallery.status) && this.testObj.eGalleryProgression.currentQuestionUuid == this.question.uuid) {
                Vue.nextTick(function () {
                    $('#eGalleryPreparingModal' + that.question.uuid).modal('show');
                });
            } else {
                Vue.nextTick(function () {
                    $('#eGalleryPreparingModal' + that.question.uuid).modal('hide');
                });
            }
        },
        clear() {
            for (var i = 0; i < this.question.answers.length; i++) {
                let answer = this.question.answers[i];
                answer.myRank = null;
                answer.myComment = null;
                answer.myVote = false;
            }
        },
        submit() {
            var that = this;
            let data = {};
            // data.votingMethod = this.question.eGallery.votingMethod;
            data.answers = [];
            for (var i = 0; i < this.question.answers.length; i++) {
                let answer = this.question.answers[i];
                if (!answer.uuid || !answer._isTouched) {
                    continue;
                }
                /*if (answer.myVote || answer.myComment) {
          data.answers.push({
            answerUuid: answer.uuid,
            vote: answer.myVote ? 1 : 0,
            comment: answer.myComment ? answer.myComment : "",
          });
        }*/
                data.answers.push({
                    answerUuid: answer.uuid,
                    vote: answer.myVote ? 1 : 0,
                    comment: answer.myComment ? answer.myComment : '',
                });
                answer._isTouched = false;
                /*if (answer.myComment) {
          axios.post(
            "student/tests/" + this.testObj.uuid + "/discussions/save-comment",
            { answerUuid: answer.uuid, answerComment: answer.myComment }
          );
        }*/

                /*if(data.votingMethod=='rank'){
        		data.answers.push({uuid:answer.uuid,value:answer.myRank,comment:answer.myComment?answer.myComment:''});
        	}
        if (data.votingMethod == "best") {
          data.answers.push({uuid: answer.uuid,value: answer.myVote ? 1 : 0,comment: answer.myComment ? answer.myComment : ""});
        }*/
            }
            if (data.answers.length != 0) {
                data.activityQuestionUuid = that.question.uuid;
                axios
                    .post('/student/tests/' + this.testObj.uuid + '/discussions/vote', data)
                    .then(function (response) {
                        that.question.eGallery.hasSubmitted = true;
                        that.$forceUpdate();
                        that.$notify({
                            group: 'form',
                            type: 'success',
                            title: 'Success',
                            text: 'Vote/Comments have been updated',
                        });
                    })
                    .catch(function (errors) {
                        that.$forceUpdate();
                        that.$notify({
                            group: 'form',
                            type: 'error',
                            title: 'Error',
                            text: 'Failed to update vote/comments, please try again later',
                        });
                    });
            }
        },
        truncate(str, characters = 100) {
            return _.truncate(str, {
                length: characters,
                omission: '...',
            });
        },
        openModelInfoModal(answer, type = 'team') {
            var that = this;
            if (type == 'team') {
                this.previewModel.type = 'team';
                this.previewModel.team = answer.team;
            } else {
                this.previewModel.type = 'student';
                this.previewModel.student = answer.student;
            }
            this.previewModel.comment = answer.comment;
            this.previewModel.attachments = answer.attachments ? answer.attachments : [];
            $('#eGalleryModelModal' + this.question.uuid).modal('show');

            $('#eGalleryModelModal' + this.question.uuid).on('hidden.bs.modal', function () {
                that.previewModel = {
                    type: 'team',
                    student: {},
                    team: {},
                    comment: {},
                    attachments: [],
                };
            });
        },
        openQuestionModal(str) {
            this.questionModal = str;
            $('#eGalleryQnsModal' + this.question.uuid).modal('show');
        },
        openEgalleryWalkModal() {
            $('#eGalleryWalkModal' + this.question.uuid).modal('show');
        },
        openCommentInfoModal(comment, type = 'team') {
            var that = this;
            if (type == 'team') {
                this.previewModel.type = 'team';
                this.previewModel.team = comment.team;
            } else {
                this.previewModel.type = 'student';
                this.previewModel.student = comment.student;
            }
            this.previewModel.comment = comment.comment;
            $('#eGalleryCommentModal' + this.question.uuid).modal('show');

            $('#eGalleryCommentModal' + this.question.uuid).on('hidden.bs.modal', function () {
                that.previewModel = {
                    type: 'team',
                    student: {},
                    team: {},
                    comment: {},
                    attachments: [],
                };
            });
        },
        percentVoted(answer_idx) {
            let present = this.question.answers.length;
            if (present == 0) {
                return null;
            }
            let votes = this.question.answers[answer_idx].votes || [];
            return Math.round((votes.length / present) * 100);
        },
    },
};
</script>

<style>
body.test .panel {
    height: 100%;
}

body.test .panel.heightAuto {
    height: auto;
}

.panel-body.heightAuto {
    height: auto;
}
</style>

<style lang="scss" scoped>
.discussionOption div:first-child {
    font-weight: bold;
}

.discussionOption div:last-child {
    margin-left: 10px;
}

.discussionOption {
    margin-top: 20px;
}

.discussionOption:first-child {
    margin-top: 0;
}

body.test .mini-panel .panel-heading {
    font-size: 14px;
}

body.test .mini-panel .panel-heading .link {
    // color: #1991eb;
    text-transform: capitalize;
}

.mini-panel .btnSections {
    width: 30.6%;
    margin-right: 4%;
}

.mini-panel .btnSections:nth-child(3n) {
    margin-right: 0;
}

.btnSections {
    margin-top: 10px;
}

.btnSections:nth-child(-n + 3) {
    margin-top: 0;
}

body.test .discussion .panel,
body.test .mini-panel.panel {
    box-shadow: 0 0 2px 0 rgba(202, 202, 202, 0.5);
}

body.test .mini-panel.panel {
    margin-bottom: 0;
}

.commentTeams {
    white-space: normal;
}

.goodAnswerDiv .btn,
.goodAnswerDiv .statusTag {
    margin-left: 10px;
}

.goodAnswerDiv .btn:first-child {
    margin-left: 0;
}
.btnDiscussionTeams {
    margin-top: 10px;
}

.btnDiscussionTeams:first-child {
    margin-top: 0;
}

.numberInput {
    position: relative;
    width: 60px;
}

.quantity-nav {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 10px;
    color: #222;
    z-index: 4;
    height: 100%;
    max-height: 100%;
}

.quantity-nav .disabled {
    color: #dfe3e9;
    cursor: not-allowed;
}

.quantity-button {
    min-height: 18px;
    height: 50%;
    display: flex;
    align-items: center;
    width: 22px;
    justify-content: center;
    background-color: white !important;
    // border: 1px solid #dfe3e9 !important;
    border: 1px solid #767676 !important;
}

.quantity-button:last-child {
    // border: 1px solid #dfe3e9 !important;
    border-top: 1px solid #767676 !important;
}

.discussionComment textarea {
    height: auto;
    box-shadow: none;
    padding: 0;
    min-height: 32px;
    max-height: 300px;
    padding: 10px;
    border-radius: 8px;

    &.danger {
        border: 1px solid red;
    }
}

.rankDiv {
    margin-left: 20px;
}

.rankDiv:first-child {
    margin-left: 0;
}

.width156px {
    width: 156px;
}

.width280px {
    width: 280px;
}

@media (min-width: 992px) {
    .commentTeams div {
        margin-top: 15px;
    }

    .commentTeams div:first-child {
        margin-top: 0;
    }
}
.discussionTableRows:last-child .marginBottom20 {
    margin-bottom: 0;
}

body.test .panel-body.padding5px15px {
    padding: 5px 15px;
}

.new-discussion {
    margin-top: 20px;
    line-height: 1.2;
    background: #fff;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    padding: 10px;
}

.new-discussion-dotted {
    border: 1px dotted #d8d8d8;
}

.new-discussion-ans > div {
    border: 1px solid #d8d8d8;
    padding: 10px;
}

.border-black {
    border: 1px solid #222;
}

.solid-warning {
    border: 1px solid #f59d00;
}

.minusWidth179px {
    width: calc(100% - 179px);
}

.width179px {
    width: 179px;
}

.disabled-background .new-discussion-ans > div,
.background-warning .new-discussion-ans > div {
    border: 1px solid #fff;
}

.big-bold-text {
    font-size: 28px;
    font-weight: bold;
}

.comment-comment div:not(:first-child) {
    margin-top: 10px;
}

.discussion-buttons .btn {
    margin-left: 0;
    margin-right: 20px;
}

.responseFont {
    margin-top: 8px;
    margin-right: 10px;
    font-weight: bold;
}

.background-warning {
    background-color: rgba(230, 155, 35, 0.5);
}
</style>
