<template>
    <div class="create row">
        <div v-if="mode == 'create'" class="col-xs-12">
            <div class="marginBottom30">
                <!--<kr-select display="TEACHER" :form="form" name="userUuid" :items="not_in_teachers" item_value_idx="value" item_desc_idx="description">
                    <template #option="props">
                        <option  :value="null" disabled>Please select a teacher</option>
                        <template v-for="teacher in props.model">
                            <option :value="teacher.uuid" >
                                {{teacher.displayName}}
                            </option>
                        </template>
                    </template>
                </kr-select>
                -->

                <table class="table table-bordered">
                    <tr>
                        <th>TEACHER</th>
                        <th>
                            <label v-if="not_in_teachers.length != 0" class="checkboxField">
                                <input type="checkbox" :checked="isAllToggled" @click="toggleAll($event)" />
                            </label>
                        </th>
                    </tr>
                    <tbody>
                        <template v-for="teacher in not_in_teachers">
                            <tr>
                                <td>{{ teacher.displayName }}</td>
                                <td>
                                    <label class="checkboxField">
                                        <input v-model="form.model.userUuids" type="checkbox" :value="teacher.uuid" />
                                    </label>
                                </td>
                            </tr>
                        </template>
                        <template v-if="not_in_teachers.length == 0">
                            <tr>
                                <td colspan="2" style="text-align: center">
                                    <b>No teachers to add</b>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
            <kr-select
                :form="form"
                display="ACCESS"
                name="accountType"
                class="marginBottom30"
                :items="[
                    { value: 'Full Access', description: 'Full access' },
                    { value: 'Read Only', description: 'Read Only' },
                ]"
                item_value_idx="value"
                item_desc_idx="description"
            />
        </div>
        <div v-if="mode == 'update'" class="col-xs-12">
            <div class="col-xs-12 flex">
                <div class="col-xs-12 col-md-6">
                    <kr-field
                        display="FIRST NAME <span class='important'>*</span>"
                        :form="form"
                        name="firstname"
                        aria-label="first name"
                        :options="{ placeholder: 'Enter teacher’s first name' }"
                    />
                </div>
                <div class="col-xs-12 col-md-6">
                    <kr-field
                        display="LAST NAME <span class='important'>*</span>"
                        :form="form"
                        name="lastname"
                        aria-label="last name"
                        :options="{ placeholder: 'Enter teacher’s last name' }"
                    />
                </div>
            </div>
            <div class="form-group">
                <label class="control-label">EMAIL</label>
                <p class="form-control-static">
                    {{ form.model.email }}
                </p>
            </div>
            <kr-select
                v-if="form.model.accountType != 'Owner'"
                :form="form"
                display="ACCESS"
                name="accountType"
                class="marginBottom30"
                :items="[
                    { value: 'Full Access', description: 'Full access' },
                    { value: 'Read Only', description: 'Read Only' },
                ]"
                item_value_idx="value"
                item_desc_idx="description"
            />
            <div v-else class="form-group">
                <label class="control-label">ACCOUNT TYPE</label>
                <p class="form-control-static">
                    {{ form.model.accountType }}
                </p>
            </div>
        </div>
        <div v-if="mode == 'update'" class="col-xs-12 flex">
            <div class="col-xs-12 col-md-6">
                <kr-field display="JOB TITLE" :form="form" name="jobTitle" aria-label="job title" :options="{ placeholder: 'Enter job title' }" />
            </div>
            <div class="col-xs-12 col-md-6">
                <kr-select
                    class="no-error"
                    display="ACADEMIC DISCIPLINE"
                    name="academicDiscipline"
                    :form="form"
                    :items="kr_var.academicDisciplines"
                    item_value_idx="value"
                    item_desc_idx="description"
                >
                    <template #option="props">
                        <template v-for="option in props.model" :key="option.value">
                            <option :value="option.value">
                                {{ option.description }}
                            </option>
                        </template>
                    </template>
                </kr-select>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['form', 'not_in_teachers', 'mode'],
    computed: {
        isAllToggled() {
            return this.form.model.userUuids.length == this.not_in_teachers.length && this.form.model.userUuids.length != 0;
        },
    },
    methods: {
        toggleAll(event) {
            if (this.isAllToggled) {
                this.form.model.userUuids = [];
            } else {
                this.form.model.userUuids = [];
                for (var i = 0; i < this.not_in_teachers.length; i++) {
                    this.form.model.userUuids.push(this.not_in_teachers[i].uuid);
                }
            }
        },
    },
};
</script>
