<template>
    <div class="newPagination">
        <div class="inlineFlex">
            <!--<button class="btn btn-default mainBtn" @click.prevent="moveToDifferentQuestion(0,0)" :disabled="(!canChangeQuestion(0,0)) ? true:undefined">
				First
			</button>-->
            <!-- <button
        v-if="pagination.length > 1"
        class="btn btn-outline-primary fw-normal paginationLeft"
        :class="{
          disabled: !canChangeQuestion(
            previous_question_group_idx,
            previous_question_idx
          ),
        }"
        @click.prevent="
          !canChangeQuestion(previous_question_group_idx, previous_question_idx)
            ? ''
            : moveToDifferentQuestion(
                previous_question_group_idx,
                previous_question_idx
              )
        "
        @keyup.space.prevent="
          !canChangeQuestion(previous_question_group_idx, previous_question_idx)
            ? ''
            : moveToDifferentQuestion(
                previous_question_group_idx,
                previous_question_idx
              )
        "
        :aria-label="
          canChangeQuestion(previous_question_group_idx, previous_question_idx)
            ? 'Previous Page'
            : 'Previous Page Disabled'
        "
      >
        <span class="hidden-xs hidden-sm">
          <i class="fa fa-long-arrow-left marginRight8" aria-hidden="true"></i>
        </span>
        <span class="hidden-md hidden-lg">
          <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
        </span>
      </button> -->
            <template v-if="!canChangeQuestion(previous_question_group_idx, previous_question_idx)">
                <a v-if="pagination.length > 1" class="disabled" aria-label="'Previous Question Disabled'">
                    <i class="fas fa-arrow-left-long" aria-hidden="true" />
                </a>
            </template>
            <template v-else>
                <a
                    v-if="pagination.length > 1"
                    href=""
                    class="link borderRadius8"
                    aria-label="'Previous Question Disabled'"
                    @click.prevent="
                        !canChangeQuestion(previous_question_group_idx, previous_question_idx)
                            ? ''
                            : moveToDifferentQuestion(previous_question_group_idx, previous_question_idx, 'scrollToQns')
                    "
                >
                    <i class="fas fa-arrow-left-long" aria-hidden="true" />
                </a>
            </template>

            <div class="flexOnly align-items marginLeft10">
                <ul class="flexOnly pagination">
                    <li
                        v-for="(question, idx) in pagination"
                        :key="'page-' + idx"
                        :class="{
                            active: question.question_group_idx == current_question_group_idx && question.question_idx == question.question_idx,
                            first: question.question_idx == 0,
                            last: idx == pagination.length - 1 || pagination[idx + 1].question_group_idx != question.question_group_idx,
                        }"
                    >
                        <template v-if="question.isDot">
                            <a>...</a>
                        </template>
                        <template v-else>
                            <template v-if="canChangeQuestion(question.question_group_idx, question.question_idx)">
                                <a
                                    href=""
                                    class="link"
                                    :aria-label="'page ' + question.label"
                                    @click.prevent="moveToDifferentQuestion(question.question_group_idx, question.question_idx, 'scrollToQns')"
                                    @keyup.space.prevent="moveToDifferentQuestion(question.question_group_idx, question.question_idx, 'scrollToQns')"
                                >
                                    {{ question.label }}
                                </a>
                            </template>
                            <template v-else>
                                <a class="disabled" :aria-label="question.label">{{ question.label }}</a>
                            </template>
                        </template>
                    </li>
                </ul>
            </div>

            <template v-if="!canChangeQuestion(next_question_group_idx, next_question_idx)">
                <a v-if="pagination.length > 1" class="disabled" aria-label="'Next Page Disabled'">
                    <i class="fas fa-arrow-right-long" aria-hidden="true" />
                </a>
            </template>
            <template v-else>
                <a
                    v-if="pagination.length > 1"
                    href=""
                    class="link borderRadius8"
                    aria-label="'Next Question'"
                    @click.prevent="
                        !canChangeQuestion(next_question_group_idx, next_question_idx)
                            ? ''
                            : moveToDifferentQuestion(next_question_group_idx, next_question_idx, 'scrollToQns')
                    "
                >
                    <i class="fas fa-arrow-right-long" aria-hidden="true" />
                </a>
            </template>

            <!-- <button
        v-if="pagination.length > 1"
        class="btn btn-outline-primary marginLeft10 fw-normal paginationRight"
        :class="{
          disabled: !canChangeQuestion(
            next_question_group_idx,
            next_question_idx
          ),
        }"
        @click.prevent="
          !canChangeQuestion(next_question_group_idx, next_question_idx)
            ? ''
            : moveToDifferentQuestion(
                next_question_group_idx,
                next_question_idx
              )
        "
        @keyup.space.prevent="
          !canChangeQuestion(next_question_group_idx, next_question_idx)
            ? ''
            : moveToDifferentQuestion(
                next_question_group_idx,
                next_question_idx
              )
        "
        :aria-label="
          canChangeQuestion(next_question_group_idx, next_question_idx)
            ? 'Next Page'
            : 'Next Page Disabled'
        "
      >
        <span class="hidden-xs hidden-sm">
          <i class="fa fa-long-arrow-right marginLeft8" aria-hidden="true"></i>
        </span>
        <span class="hidden-md hidden-lg">
          <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
        </span>
      </button> -->
            <!--<button class="btn btn-default mainBtn" @click.prevent="moveToDifferentQuestion(testObj.questions.length-1,testObj.questions[testObj.questions.length-1].length -1)" :disabled="(!canChangeQuestion(testObj.questions.length-1,testObj.questions[testObj.questions.length-1].length -1)) ? true:undefined">
				Last
			</button>-->
        </div>
        <!-- <div class="col-xs-12 hidden-md hidden-lg flexSpaceBetween align-items paginator">
			<button class="btn btn-default mainBtn" @click.prevent="moveToDifferentQuestion(0,0)" :disabled="(!canChangeQuestion(0,0)) ? true:undefined">
				<i class="fa fa-backward"></i>
			</button>
			<button class="btn btn-default mainBtn width36px"  @click.prevent="moveToDifferentQuestion(previous_question_group_idx,previous_question_idx)" :disabled="(!canChangeQuestion(previous_question_group_idx,previous_question_idx)) ? true:undefined"></i>
				<i class="fa fa-caret-left"></i>
			</button>	
			<div class="btn padding0">
				<ul class="flexOnly pagination text-listing-pagination justifyCenter">
					<template v-for="(question,idx) in pagination">
						<li :class="{'active':question.question_group_idx==current_question_group_idx&&question.question_idx==question.question_idx}">
							<template v-if="question.isDot"><a>...</a></template>
							<template v-else>
								<template v-if="canChangeQuestion(question.question_group_idx,question.question_idx)">
									<a @click.prevent="moveToDifferentQuestion(question.question_group_idx,question.question_idx)">{{question.label}}</a>
								</template>	
								<template v-else>
									<a class="disabled">{{question.label}}</a>
								</template>	
							</template>
						</li>
					</template>	
		        </ul>	
		    </div>
			<button class="btn btn-default mainBtn width36px" @click.prevent="moveToDifferentQuestion(next_question_group_idx,next_question_idx)" :disabled="(!canChangeQuestion(next_question_group_idx,next_question_idx)) ? true:undefined">
				<i class="fa fa-caret-right"></i>
			</button>
			<button class="btn btn-default mainBtn" @click.prevent="moveToDifferentQuestion(testObj.questions.length-1,testObj.questions[testObj.questions.length-1].length -1)" :disabled="(!canChangeQuestion(testObj.questions.length-1,testObj.questions[testObj.questions.length-1].length -1)) ? true:undefined">
				<i class="fa fa-forward"></i>
			</button>
		</div> -->
    </div>
</template>

<script>
import { testPaginationMixins } from './../../../../../mixins/test';
export default {
    mixins: [testPaginationMixins],
};
</script>
<style>
.paginator button,
.paginator .buttons,
.paginator .btn,
.paginator router-link {
    font-weight: normal;
}
</style>

<style scoped lang="scss">
// a.disabled {
//   opacity: 0.3;
// }

.newPagination {
    a {
        padding: 12px 16.45px;
        height: auto;
        width: auto;
        line-height: 1;
        border: none;
        background: none;

        &.link {
            color: #0071eb;
        }

        &:hover {
            line-height: 1;
            color: #0000ff;
            font-weight: bold;
            text-decoration: underline;
            background: #e7dcf9;
        }

        &:active {
            color: #ff00ff;
            background: none;
        }

        // &:visited {
        //   text-decoration-color: #9331C4;
        //   background: none;
        // }

        &.disabled {
            color: #8b8b8f;
        }
    }

    li {
        &.first.last {
            a {
                border-radius: 8px;
            }
        }
    }

    .active {
        a {
            box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.24);
            border: none;
            background: #fff;

            &.disabled {
                color: #3c3c43;
            }
        }

        &.first {
            a {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &.last {
            a {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }
        }
    }
}

.borderRadius8 {
    border-radius: 8px;
}
</style>
