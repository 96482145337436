<template>
    <div v-if="auth.isLoggedIn() && auth.user().account.version == 'lite' && auth.user().role != 'Student'" class="notification-div">
        <div class="col-xs-12 col-md-12">
            <div class="alert alert-black alert-dismissible" role="alert" style="margin-top: 20px">
                You are currently using InteDashboard Lite. Click here to upgrade to the full version.
            </div>
        </div>
    </div>
</template>

<script>
import KrAuth from './../../../components/auth/auth';
export default {
    data() {
        let auth = new KrAuth();
        return {
            auth: auth,
        };
    },
};
</script>
<style scoped>
.notification-div {
    padding-left: 25px;
    padding-right: 25px;
    margin-left: 95px;
}
</style>
