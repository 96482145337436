<template>
    <div v-if="component_done_loading" class="col-xs-12 right-container">
        <div class="form-div">
            <kr-panel>
                <template #title>
                    <div class="col-xs-6">EDIT TEACHER</div>
                    <div class="col-xs-6 flexRight">
                        <div class="hidden-xs hidden-sm">
                            <button class="btn btn-success mainBtn" :disabled="!canSend ? true : undefined" aria-label="Save" @click="submit">
                                <i class="far fa-save marginRight8" aria-hidden="true"></i>Save
                            </button>

                            <!-- <router-link :to="{name:'courses.view',params:{id:$route.params.course_id},query:{tab:'permission'}}" tag="button" class="btn btn-default mainBtn" exact>
				            	Back
				            </router-link>	 -->
                        </div>
                        <div class="hidden-md hidden-lg">
                            <button class="btn btn-success mainBtn" :disabled="!canSend ? true : undefined" aria-label="Save" @click="submit">
                                <i class="fa fa-check"></i>
                            </button>
                            <!-- <router-link :to="{name:'courses.view',params:{id:$route.params.course_id},query:{tab:'permission'}}" tag="button" class="btn btn-default mainBtn" exact>
				            	<i class="fa fa-caret-left"></i>
				            </router-link>	 -->
                        </div>
                    </div>
                </template>
                <template #content>
                    <div class="col-xs-12 col-md-6">
                        <teacher-form :form="form" mode="update" :not_in_teachers="not_in_teachers"></teacher-form>
                        <div class="buttonGroup marginTop30">
                            <button class="btn btn-success mainBtn" :disabled="!canSend ? true : undefined" aria-label="Confirm" @click="submit">
                                <i class="far fa-save marginRight8" aria-hidden="true"></i>Save
                            </button>
                            <router-link
                                :to="{
                                    name: 'courses.view',
                                    params: { id: $route.params.course_id },
                                    query: { tab: 'permission' },
                                }"
                                tag="button"
                                class="btn btn-default mainBtn"
                                exact
                                aria-label="Cancel"
                            >
                                Cancel
                            </router-link>
                        </div>
                    </div>
                </template>
            </kr-panel>
        </div>
    </div>
</template>

<script>
import KrForm from '../../../../components/forms/form';
import KrAuth from '../../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            component_done_loading: false,
            auth: auth,
            form: new KrForm({
                fields: {
                    firstname: '',
                    lastname: '',
                    email: '',
                    userUuid: '',
                    accountType: 'default!:Full Access',
                    academicDiscipline: 'sendNull!:false',
                    jobTitle: 'sendNull!:false',
                },
                urls: {
                    put: '/courses/' + this.$route.params.course_id + '/users',
                },
            }),
            not_in_teachers: [],
        };
    },
    components: {
        'teacher-form': require(`./form-partials/partial.teacher.vue`).default,
    },
    computed: {
        canSend() {
            if (this.form.model.userUuid && this.form.model.accountType) {
                return true;
            }
            return false;
        },
    },
    created() {
        var that = this;
        Events.fire('topbar_update', {
            title: '<i class="fas fa-graduation-cap"></i>&nbsp;Courses',
            breadcrumb: [
                { name: 'Home' },
                { name: 'Courses', link: 'courses.index' },
                {
                    name: 'Course',
                    link: 'courses.view',
                    params: { id: this.$route.params.course_id },
                    query: { tab: 'permission' },
                },
                { name: 'Update Teacher' },
            ],
        });

        axios.get('/users/' + that.$route.params.id).then(function (response) {
            that.form.setModel(response.data.data);
            that.form.model.userUuid = response.data.data.uuid;
            let course = _.find(response.data.data.courses, function (o) {
                return o.uuid == that.$route.params.course_id;
            });
            that.form.model.accountType = course.accountType;
            that.component_done_loading = true;
        });
    },
    methods: {
        submit() {
            var that = this;
            this.form.put().then(function (response) {
                if (!response.krFormError) {
                    that.$router.push({
                        name: 'courses.view',
                        params: { id: that.$route.params.course_id },
                        query: { tab: 'permission' },
                    });
                }
            });
        },
    },
};
</script>
<style scoped></style>
