<template>
    <div class="col-xs-12 right-container">
        <div class="col-md-12 form-div">
            <kr-panel :with-footer="false">
                <template #title>
                    <div class="col-xs-6">NEW COURSE</div>
                    <!--<div class="col-xs-6 flexRight">
						<div class="hidden-xs hidden-sm">
				            <button class="btn btn-success mainBtn" :disabled="(!canSend) ? true:undefined" @click.prevent="submit">
				                <i class="fa fa-check"></i>&nbsp;Save
				            </button>
				            
				        </div>
				        <div class="hidden-md hidden-lg">
				            <button class="btn btn-default mainBtn" :disabled="(!canSend) ? true:undefined"@click.prevent="submit"
				                <i class="fa fa-check"></i>
				            </button>
				        </div>
			        </div>-->
                </template>
                <template #content>
                    <div class="col-xs-12 col-md-6">
                        <course-form :form="form"></course-form>
                        <div class="buttonGroup marginTop30">
                            <button class="btn btn-success mainBtn" :disabled="!canSend ? true : undefined" aria-label="Save" @click.prevent="submit">
                                <i class="far fa-save marginRight8" aria-hidden="true"></i>Save
                            </button>
                            <router-link :to="{ name: 'courses.index' }" tag="button" class="btn btn-default mainBtn" exact aria-label="Cancel">
                                Cancel
                            </router-link>
                        </div>
                    </div>
                </template>
            </kr-panel>
        </div>
    </div>
</template>

<script>
import swal from 'sweetalert2';
import KrForm from '../../../components/forms/form';
import KrAuth from '../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            component_done_loading: false,
            form: new KrForm({
                fields: {
                    name: '',
                    code: '',
                    description: '',
                    descriptionIsHTML: '',
                    startDate: '',
                    endDate: '',
                    objectives: 'type!:array',
                    coverImage: '',
                },
                props: {},
                urls: {
                    post: 'courses',
                },
            }),
        };
    },
    components: {
        'course-form': require(`./form-partials/partial.course.vue`).default,
    },
    computed: {
        canSend() {
            if (this.form.model.name && this.form.model.startDate && this.form.model.endDate) {
                return true;
            }
            return false;
        },
    },
    created() {
        Events.fire('topbar_update', {
            title: '<i class="fas fa-graduation-cap"></i>&nbsp;Courses',
            breadcrumb: [{ name: 'Home' }, { name: 'Courses', link: 'courses.index' }, { name: 'New Course' }],
        });
        this.form.model.descriptionIsHTML = false;
        this.form.model.startDate = moment().startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z';
        this.form.model.endDate = moment().endOf('day').utc().add(6, 'months').format('YYYY-MM-DDTHH:mm:ss') + 'Z';
    },
    methods: {
        submit() {
            var that = this;
            this.form.post().then(function (response) {
                if (!response.krFormError) {
                    that.$router.push({
                        name: 'courses.view',
                        params: { id: response.data.data.uuid },
                    });
                }
            });
        },
    },
};
</script>
