<template>
    <div id="profile-edit">
        <div v-if="component_done_loading" class="col-xs-12 right-container" :class="{ isStudent: auth.user().role == 'Student' }">
            <div class="form-div">
                <kr-panel>
                    <template #title>
                        <div class="col-xs-6">EDIT MY PROFILE</div>
                        <!-- <div class="col-xs-6 flexRight">
						<div class="hidden-xs hidden-sm">
				            <button id="top-save-btn" class="btn btn-success mainBtn" :disabled="(!canSend) ? true:undefined" @click="submit()">
				                <i class="fa fa-check"></i>&nbsp;Update
				            </button>
				            <router-link id="top-back-btn" :to="{name:'/'}" tag="button" class="btn btn-default mainBtn" exact>
				            	Back
				            </router-link>
				        </div>
				        <div class="hidden-md hidden-lg">
				            <button id="top-mobile-save-btn" class="btn btn-success mainBtn" :disabled="(!canSend) ? true:undefined" @click="submit()">
				                <i class="fa fa-check"></i>
				            </button>
				             <router-link id="top-mobile-back-btn" :to="{name:'/'}" tag="button" class="btn btn-default mainBtn" exact>
				            	<i class="fa fa-caret-left"></i>
				            </router-link>
				        </div>
			        </div> -->
                    </template>
                    <template #content>
                        <div class="col-xs-12">
                            <basic-info-form :form="form" @selected="onSelected"></basic-info-form>

                            <div class="profileBtns buttonGroup marginTop30">
                                <button id="bottom-save-btn" class="btn btn-success mainBtn" :disabled="!canSend ? true : undefined" @click="submit()">
                                    <i class="fa fa-check marginRight8" aria-hidden="true"></i>Update
                                </button>
                                <router-link
                                    id="bottom-back-btn"
                                    :to="{ name: 'profile.view' }"
                                    tag="button"
                                    class="btn btn-default mainBtn"
                                    exact
                                    aria-label="Cancel"
                                >
                                    Cancel
                                </router-link>
                            </div>
                        </div>
                    </template>
                </kr-panel>
            </div>
            <!-- <div class="col-xs-12 right-container">
			<div class="page-title">
		        <div class="title_left">
		            <h3>My Profile</h3>
		        </div>
	    	</div>
	    	<div class="col-xs-12 profileNavi" style="padding:0;margin-bottom:20px;">
	    		<button class="btn emptyBtn active">
	    			<i class="fa fa-user" style="margin-right:5px;"></i>MY PROFILE
	    		</button>
	    		<button class="btn emptyBtn">
	    			<i class="fa fa-cogs" style="margin-right:5px;"></i>EDIT SETTINGS
	    		</button>
	    	</div>
			<div class="col-xs-12 editProfile profileTable" style="padding-left:0px;padding-right:0px;">
				<kr-panel title="Basic Profile Information" :withFooter="false" :withTitle="false">
				    <template #content>
				        <basic-info-form :form="form"></basic-info-form>
				    </template>
				</kr-panel>
				<div class="col-xs-10 hidden-xs hidden-sm" style="padding:0;margin-top:30px;">
	      			<button class="btn btn-success pull-left col-xs-5" :disabled="(form.isBusy()||!canSend) ? true:undefined" @click.prevent="openConfirmModal()" style="margin-right:25px;">
						<template v-if="form.isBusy()"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></template>
						<template v-else>Save Changes</template>
					</button>
					<button @click.prevent="$router.push({name:'profile.view'})" class="btn whiteBtn btn-action pull-left col-xs-5">
						Cancel
					</button>
			    </div> 
			    <div class="col-xs-12 hidden-lg hidden-md" style="padding:0;margin:30px 0 50px 0;">
			        <button class="btn btn-success" :disabled="(form.isBusy()||!canSend) ? true:undefined" @click.prevent="openConfirmModal" style="width:100%">
						<template v-if="form.isBusy()"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></template>
						<template v-else>Save Changes</template>
					</button>
					<button @click.prevent="$router.push({name:'profile.view'})" class="btn btn-secondary btn-action" style="width:100%;margin-top:10px;">
						Cancel
					</button>
			    </div>    
			</div>	
		</div> -->
        </div>
        <div
            v-if="component_done_loading"
            id="profile_confirm_modal"
            class="modal fade"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            aria-labelledby="profileConfirmModal-title"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true"></i>
                        </button>

                        <h2 id="profileConfirmModal-title" class="modal-title">Save Changes</h2>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-xs-12">
                                <span>You have made changes to your profile. Are you sure?</span>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="row">
                            <div class="col-xs-12">
                                <button type="button" class="btn btn-success" data-dismiss="modal" aria-label="Save Changes" @click="submit">
                                    Save Changes
                                </button>
                                <button type="button" style="margin-top: -5px" class="btn btn-secondary" data-dismiss="modal" aria-label="Cancel">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KrForm from './../../../components/forms/form';
import KrAuth from './../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            component_done_loading: false,
            auth: auth,
            form: new KrForm({
                fields: {
                    avatar: '',
                    firstname: '',
                    lastname: '',
                    email: '',
                    displayName: '',
                    old_password: 'sendNull!:false',
                    password: 'sendNull!:false',
                    password_confirmation: 'sendNull!:false',
                    identity: '',
                    academicDiscipline: 'sendNull!:false',
                    jobTitle: 'sendNull!:false',
                },
                urls: {
                    put: '/profile',
                },
                props: {
                    successMessage: false,
                },
            }),
            user: {},
            colorPicked: null,
        };
    },
    components: {
        'basic-info-form': require(`./form-partials/partial.basic_info.vue`).default,
    },
    computed: {
        canSend() {
            if (this.form.model.email && !this.form.model.lastname) {
                return false;
            }
            if (
                (!this.form.errors.any() &&
                    this.form.model.firstname &&
                    this.form.model.old_password &&
                    this.form.model.password &&
                    this.form.model.password_confirmation) ||
                !this.form.model.password
            ) {
                return true;
            }
            return false;
        },
    },
    created() {
        Events.fire('topbar_update', {
            breadcrumb: [{ name: 'Home' }, { name: 'Edit My Profile' }],
        });
        this.fetchUser();
    },
    beforeUnmount() {
        Events.fire('profileURL_close');
    },
    methods: {
        openConfirmModal() {
            $('#profile_confirm_modal').modal('show');
        },
        fetchUser() {
            var that = this;
            axios.get('/profile').then(function (response) {
                that.user = response.data.data;
                that.form.setModel(that.user);
                that.component_done_loading = true;
            });
        },
        submit() {
            var that = this;
            this.form.model.displayName = this.form.model.firstname + (this.form.model.lastname ? ' ' + this.form.model.lastname : '');
            this.form.put().then(function (response) {
                if (!response.krFormError) {
                    let profile = that.auth.user();
                    profile.avatar = that.form.model.avatar;
                    profile.firstname = that.form.model.firstname;
                    profile.lastname = that.form.model.lastname;
                    profile.email = that.form.model.email;
                    profile.displayName = that.form.model.displayName;
                    profile.jobTitle = that.form.model.jobTitle;
                    profile.academicDiscipline = that.form.model.academicDiscipline;
                    that.$store.commit('profile', profile);
                    that.$notify({
                        group: 'profile-update-success',
                        title: 'Awesome, ' + that.auth.user().firstname + (that.auth.user().lastname ? ' ' + that.auth.user().lastname : '') + '!',
                        text: 'Your profile has been updated successfully',
                        duration: 2000,
                    });
                    that.$router.push({ name: 'profile.view' });
                }
            });

            if (this.colorPicked == '#17244A') {
                document.documentElement.style.setProperty('--dark-highlight-color', '#fff');
            } else {
                document.documentElement.style.setProperty('--dark-highlight-color', '#222');
            }

            localStorage.setItem('highlightColor', this.colorPicked);
            document.documentElement.style.setProperty('--highlight-color', this.colorPicked);
        },
        /*
    		fetchData () {
    			var onSuccess= function(response){
    				this.form.setModel(response.data.data);
    				this.component_done_loading  = true;
    				console.log(response.data.data);
    			}.bind(this);
    			axios.get('users/'+this.$route.params.id).
    			then(function(response){onSuccess(response)});
    		}
    		*/
        onSelected(value) {
            this.colorPicked = value;
        },
    },
};
</script>
