<template>
    <div :id="uuid + '-container'" style="position: relative">
        <div v-show="pasting" tabindex="0" class="confirmDiv" @keyup.enter="sanitizeContent(false)" @keyup.esc="sanitizeContent(true)">
            Do you want to retain formatting of pasted content?
            <div class="flex">
                <button id="modal-logout-btn" class="btn btn-success mainBtn" aria-label="Yes" @click.prevent="sanitizeContent(false)">Yes</button>
                <button id="modal-dismiss-btn" class="btn btn-default mainBtn" style="width: 100%" aria-label="No" @click.prevent="sanitizeContent(true)">
                    No
                </button>
            </div>
        </div>
        <div :class="{ pasting: pasting }">
            <editor :id="uuid" v-model="value" :init="editorOptions" model-events="SetContent change keydown keyup blur focus paste" />
        </div>
        <div :id="uuid + '-retain-markup-modal'" class="modal" data-backdrop="static" tabindex="-1" role="dialog">
            <div :id="uuid + '-confirm-dialog-modal'" class="modal-dialog" style="position: fixed">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <h2 class="modal-title">Retain markup</h2>
                    </div>
                    <div class="modal-body">
                        <div class="row marginBottom20">
                            <div class="col-xs-12" style="text-align: center">
                                <div>do you want to retain formatting of pasted content?</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="buttonGroup">
                                <button
                                    id="modal-logout-btn"
                                    class="btn btn-success mainBtn paddingBottom10"
                                    aria-label="Yes"
                                    @click.prevent="sanitizeContent(false)"
                                >
                                    Yes
                                </button>
                                <button id="modal-dismiss-btn" class="btn btn-default mainBtn" aria-label="No" @click.prevent="sanitizeContent(true)">
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KrAuth from '../../auth/auth';
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/models/dom';
import 'tinymce/skins/ui/oxide/skin.css';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/code';
import Editor from '@tinymce/tinymce-vue';
import * as filestack from 'filestack-js';
import { v4 as uuidv4 } from 'uuid';
Editor.compatConfig = {
    MODE: 3,
};
export default {
    compatConfig: {
        MODE: 3, // opt-in to Vue 3 behavior for this component only
    },
    components: {
        Editor: Editor,
    },
    props: {
        modelValue: {
            type: String,
        },
        options: {
            type: Object,
            required: false,
            default: function () {
                return {};
            },
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            auth: new KrAuth(),
            uuid: '',
            filestack_client: null,
            editorOptions: {},
            sanitizeOptions: {
                allowedTags: [],
                /*allowedTags: [
          "address",
          "article",
          "aside",
          "footer",
          "header",
          "h1",
          "h2",
          "h3",
          "h4",
          "h5",
          "h6",
          "hgroup",
          "main",
          "nav",
          "section",
          "blockquote",
          "dd",
          "div",
          "dl",
          "dt",
          "figcaption",
          "figure",
          //"hr",
          "li",
          "main",
          "ol",
          "p",
          "pre",
          "ul",
          //"a",
          "abbr",
          //"b",
          "bdi",
          "bdo",
          "br",
          "cite",
          "code",
          "data",
          "dfn",
          "em",
          //"i",
          "kbd",
          "mark",
          "q",
          "rb",
          "rp",
          "rt",
          "rtc",
          "ruby",
          "s",
          "samp",
          "small",
          "span",
          //"strong",
          "sub",
          "sup",
          "time",
          //"u",
          "var",
          "wbr",
          "caption",
          "col",
          "colgroup",
          "table",
          "tbody",
          "td",
          "tfoot",
          "th",
          "thead",
          "tr",
          //"img",
        ],*/
                disallowedTagsMode: 'discard',
                allowedAttributes: {
                    a: ['href'],
                },
                // Lots of these won't come up by default because we don't allow them
                selfClosing: ['img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta'],
                // URL schemes we permit
                allowedSchemes: ['http', 'https', 'ftp', 'mailto', 'tel'],
                allowedSchemesByTag: {},
                allowedSchemesAppliedToAttributes: ['href', 'src', 'cite'],
                allowProtocolRelative: true,
                enforceHtmlBoundary: false,
                parseStyleAttributes: true,
            },
            contentToSanitize: '',
            pasting: false,
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            },
        },
        shouldDisablePasteConfirmation() {
            if (
                ['005292c1-fc59-47e6-890b-61190120bcff'].includes(this.auth.user().account.uuid) ||
                [
                    'ea3f3651-ab0f-4b55-bef9-528dd0151dba',
                    '5341be59-580e-4594-8bc1-7f7a58d76675',
                    '999b4226-f810-427a-a130-3aec6aaa10a3',
                    '4a84cc5b-7432-455e-b5ac-fe1afd936559',
                    'c1404fee-a3eb-460a-bfc4-e98b90744ebd',
                ].includes(this.auth.user().uuid) ||
                process.env.VUE_APP_ENV == 'local'
            ) {
                return true;
            }
            return false;
        },
    },
    watch: {
        disabled: {
            handler: function () {
                let a = this.disabled ? 'readonly' : 'design';
                tinymce.get(this.uuid).mode.set(a);
            },
            deep: true,
        },
    },
    created() {
        this.uuid = 'editor-' + uuidv4();
        this.init();
    },
    mounted() {
        /*Focus issue on chrome fix*/
        $(document).on('focusin', function (e) {
            if ($(e.target).closest('.mce-window').length) {
                e.stopImmediatePropagation();
            }
        });
    },
    methods: {
        getOffset(el) {
            var _x = 0;
            var _y = 0;
            while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
                _x += el.offsetLeft;
                _y += el.offsetTop;
                el = el.offsetParent;
            }
            return { top: _y, left: _x };
        },
        sanitizeContent(sanitize = true) {
            this.pasting = false;
            let currentContent = tinymce.get(this.uuid).getContent();
            if (sanitize) {
                tinymce.get(this.uuid).setContent(currentContent + this.$sanitize(this.contentToSanitize, this.sanitizeOptions));
            } else {
                this.contentToSanitize = this.contentToSanitize.replace(/<img[^>"']*((("[^"]*")|('[^']*'))[^"'>]*)*>/g, '');
                tinymce.get(this.uuid).setContent(currentContent + this.contentToSanitize);
            }
            //$("#" + this.uuid + "-retain-markup-modal").modal("hide");
        },
        uploadImageToEditor(callback, value, meta) {
            this.filestack_client
                .picker({
                    accept: ['.jpg', '.jpeg', '.gif', '.png', '.heic'],
                    maxFiles: 1,
                    uploadInBackground: false,
                    onFileUploadFinished: function (file) {
                        callback(file.url, { alt: file.filename });
                    },
                    onFileSelected(file) {
                        console.log(file);
                        // It's important to return a new file by the end of this function.
                        //return { ...file, name: 'foo' };
                    },
                })
                .open();
        },
        init() {
            var that = this;
            this.filestack_client = filestack.init(process.env.VUE_APP_FILESTACK_API_KEY);
            this.editorOptions = {
                content_css: '/src/sass/components/layout.scss',
                skin: false,
                selector: 'textarea',
                min_height: _.get(this.options, 'min_height', 250),
                // max_height: _.get(this.options, "max_height"),
                plugins: _.get(this.options, 'plugins', 'advlist autolink link image lists charmap preview autoresize code'),
                autoresize_overflow_padding: 5,
                autoresize_bottom_margin: 25,
                external_plugins: {
                    mathjax: '/plugins/@dimakorotkov/tinymce-mathjax/plugin.js',
                },
                mathjax: {
                    lib: '/mathjax/es5/tex-mml-chtml.js', //required path to mathjax
                    symbols: { start: '\\(', end: '\\)' }, //optional: mathjax symbols
                    //className: "math-tex", //optional: mathjax element class
                    configUrl: '/plugins/@dimakorotkov/tinymce-mathjax/config.js', //optional: mathjax config js
                },
                toolbar1: _.get(this.options, 'toolbar1', 'subscript superscript | cut copy pastetext | code | mathjax'),
                toolbar2: _.get(
                    this.options,
                    'toolbar2',
                    'undo redo | styles fontsize | bold italic removeformat underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | image | link '
                ),
                menubar: false,
                file_picker_callback: function (callback, value, meta) {
                    that.uploadImageToEditor(callback, value, meta);
                },
                default_link_target: '_blank',
                // image_dimensions: false,
                paste_preprocess: function (plugin, args) {
                    that.contentToSanitize = args.content;
                    args.content = '';
                    if (that.shouldDisablePasteConfirmation) {
                        that.sanitizeContent(false);
                        return;
                    }
                    that.pasting = true;
                    let container = $('#' + that.uuid + '-container')[0];
                    var rect = container.getBoundingClientRect();
                    let a = $(container).find('div.confirmDiv')[0];
                    if (a) {
                        $(a).css('top', rect.height / 2);
                        $(a).css('width', rect.width);
                    }
                    that.$nextTick(function () {
                        a.focus();
                    });
                    /*var offset = that.getOffset(container);
          $(`#${that.uuid}-confirm-dialog-modal`).css(
            "top",
            window.innerWidth / 2
          );
          $(`#${that.uuid}-confirm-dialog-modal`).css(
            "left",
            window.innerHeight / 2
          );
          that.$nextTick(function() {
            $(`#${that.uuid}-retain-markup-modal`).modal("show");
          });*/
                    that.pasting = true;
                },
                /*init_instance_callback: function(editor) {
          editor.on("BeforeSetContent", function(e) {
            e.content = e.content.trim();
            let sanitizedContent = that.$sanitize(
              e.content,
              that.sanitizeOptions
            );
            e.content = sanitizedContent;
          });
        },*/
                setup: (editor) => {
                    editor.on('init', (e) => {
                        let a = this.disabled ? 'readonly' : 'design';
                        editor.mode.set(a);
                    });
                },
            };
        },
    },
};
</script>
<style>
.tox-statusbar__branding {
    display: none;
}
.wrs_imageContainer.wrs_button {
    padding: 0px !important;
}
.mce-notification.mce-in {
    opacity: 0 !important;
}
div[aria-label='Insert link'] .mce-btn.mce-open {
    display: none;
}
</style>
<style scoped>
.confirmDiv {
    padding: 0 20px;
    position: absolute;
    z-index: 1;
}
.pasting {
    opacity: 0.2;
}
</style>
