<template>
    <div v-if="component_done_loading" id="overViewBlock" class="height100">
        <kr-panel :with-footer="false" :options="{ canToggle: false }" class="height100">
            <template #title>
                <div class="col-xs-6">Activity Settings</div>
                <!--<div class="buttonGroup">
                    <div class="hidden-xs hidden-sm">
                        <button class="btn btn-primary mainBtn">
                            <i class="fa fa-pencil-square-o"></i>&nbsp;Edit
                        </button>
                    </div>
                    <div class="hidden-md hidden-lg">
                        <button class="btn btn-primary mainBtn">
                            <i class="fa fa-pencil-square-o"></i>
                        </button>
                    </div>
                </div>-->
            </template>
            <template #content>
                <div class="row flex">
                    <!--<div class="col-xs-12">
						<div class="form-group">
		                    <label class="control-label">MODULE</label>
		                    <p class="form-control-static margin0">{{testObj.moduleName}}
		                    </p>
		                </div>
		            </div>-->
                    <div v-if="testObj.type == 'prework'" class="col-xs-12">
                        <div class="form-group">
                            <label class="control-label">Type</label>
                            <p class="form-control-static text-capitalize">
                                {{ testObj.others.preworkType }}
                            </p>
                        </div>
                    </div>
                    <!-- <div
                        v-if="testObj.type == 'application'"
                        class="col-xs-12 flex"
                    >
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group">
                                <label class="control-label">Type</label>
                                <p class="form-control-static text-capitalize">
                                    {{ testObj.others.applicationType }}
                                </p>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group">
                                <label class="control-label">Graded</label>
                                <p
                                    class="form-control-static text-capitalize"
                                    :class="{
                                        green: testObj.others
                                            .isApplicationGraded,
                                        greyText:
                                            !testObj.others.isApplicationGraded,
                                    }"
                                >
                                    <template
                                        v-if="
                                            testObj.others.isApplicationGraded
                                        "
                                    >
                                        Yes
                                    </template>
                                    <template v-else> No </template>
                                </p>
                            </div>
                        </div>
                    </div> -->
                </div>

                <div v-if="['evaluation', 'michaelsenEvaluation'].includes(testObj.type)" class="row flex">
                    <div class="col-xs-12">
                        <div class="form-group">
                            <label class="control-label">Components</label>
                        </div>

                        <div class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Teammate Evaluation </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: testObj.evaluationSettings.evaluateTeammates,
                                    greyText: !testObj.evaluationSettings.evaluateTeammates,
                                }"
                            >
                                <template v-if="testObj.evaluationSettings.evaluateTeammates"> YES </template>
                                <template v-else>NO</template>
                            </label>
                        </div>

                        <div class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Self Evaluation </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: testObj.evaluationSettings.evaluateThemselves,
                                    greyText: !testObj.evaluationSettings.evaluateThemselves,
                                }"
                            >
                                <template v-if="testObj.evaluationSettings.evaluateThemselves"> YES </template>
                                <template v-else>NO</template>
                            </label>
                        </div>

                        <div class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Team Evaluation </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: testObj.evaluationSettings.evaluateTeam,
                                    greyText: !testObj.evaluationSettings.evaluateTeam,
                                }"
                            >
                                <template v-if="testObj.evaluationSettings.evaluateTeam"> YES </template>
                                <template v-else>NO</template>
                            </label>
                        </div>

                        <div class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Instructor Evaluation </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: testObj.evaluationSettings.evaluateInstructor,
                                    greyText: !testObj.evaluationSettings.evaluateInstructor,
                                }"
                            >
                                <template v-if="testObj.evaluationSettings.evaluateInstructor"> YES </template>
                                <template v-else>NO</template>
                            </label>
                        </div>

                        <div class="flexSpaceBetween">
                            <label class="control-label fontBlack col-md-10"> Has Time Limit </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: testObj.settings.hasTimeLimit,
                                    greyText: !testObj.settings.hasTimeLimit,
                                }"
                            >
                                <template v-if="testObj.settings.hasTimeLimit">YES</template>
                                <template v-else>NO</template>
                            </label>
                        </div>
                    </div>
                </div>

                <div v-if="testObj.type != 'prework'" class="row flex marginBottom20">
                    <div class="col-xs-12">
                        <div class="form-group">
                            <label class="control-label">Settings</label>
                        </div>
                    </div>

                    <div v-if="testObj.type == 'irat'" class="col-xs-12">
                        <div class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Enable Immediate Feedback </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: testObj.settings.immediateFeedback,
                                    greyText: !testObj.settings.immediateFeedback,
                                }"
                            >
                                <template v-if="testObj.settings.immediateFeedback"> YES </template>
                                <template v-else>NO</template>
                            </label>
                        </div>

                        <div class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Confidence-Based Testing </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight greyText"
                                :class="{
                                    green: testObj.settings.confidenceBasedTesting,
                                }"
                            >
                                <template v-if="testObj.settings.confidenceBasedTesting"> YES </template>
                                <template v-else>NO</template>
                            </label>
                        </div>

                        <div class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Allow students to change answers before final submission </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: testObj.settings.allowChangeAnswersBeforeFinalSubmission,
                                    greyText: !testObj.settings.allowChangeAnswersBeforeFinalSubmission,
                                }"
                            >
                                <template v-if="testObj.settings.allowChangeAnswersBeforeFinalSubmission"> YES </template>
                                <template v-else>NO</template>
                            </label>
                        </div>

                        <div class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Students must answer questions in sequence </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: !testObj.settings.allowViewQuestionsAhead,
                                    greyText: testObj.settings.allowViewQuestionsAhead,
                                }"
                            >
                                <template v-if="!testObj.settings.allowViewQuestionsAhead"> YES </template>
                                <template v-else>NO</template>
                            </label>
                        </div>

                        <div v-if="!testObj.settings.allowViewQuestionsAhead" class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Allow students to view previous questions </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: testObj.settings.allowViewPreviousQuestions,
                                    greyText: !testObj.settings.allowViewPreviousQuestions,
                                }"
                            >
                                <template v-if="testObj.settings.allowViewPreviousQuestions"> YES </template>
                                <template v-else>NO</template>
                            </label>
                        </div>

                        <div class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Randomize answer option order </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: testObj.settings.randomizeAnswerOptionOrder,
                                    greyText: !testObj.settings.randomizeAnswerOptionOrder,
                                }"
                            >
                                <template v-if="testObj.settings.randomizeAnswerOptionOrder"> YES </template>
                                <template v-else>NO</template>
                            </label>
                        </div>

                        <div v-if="testObj.settings.randomizeAnswerOptionOrder" class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Hide answer choice key </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: testObj.settings.hideQuestionOptionLabels,
                                    greyText: !testObj.settings.hideQuestionOptionLabels,
                                }"
                            >
                                <template v-if="testObj.settings.hideQuestionOptionLabels"> YES </template>
                                <template v-else>NO</template>
                            </label>
                        </div>

                        <div class="flexSpaceBetween">
                            <label
                                class="control-label fontBlack col-md-10"
                                :class="{
                                    marginBottom10: accommodationsStudentsCount > 0,
                                }"
                            >
                                Has Time Limit
                            </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: testObj.settings.hasTimeLimit,
                                    greyText: !testObj.settings.hasTimeLimit,
                                }"
                            >
                                <template v-if="testObj.settings.hasTimeLimit">YES</template>
                                <template v-else>NO</template>
                            </label>
                        </div>

                        <div v-if="accommodationsStudentsCount > 0" class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Students with Accommodations </label>

                            <label class="static-label-toggle col-md-2 alignRight green"> YES ({{ accommodationsStudentsCount }}) </label>
                        </div>

                        <div v-if="accommodationsStudentsCount > 0" class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Enable Early Start </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: canStartEarly || earlyStartOngoing,
                                    greyText: earlyStartEnded || !testObj.earlyStartDurationInMinutes,
                                }"
                            >
                                <template v-if="canStartEarly || earlyStartOngoing"> YES ({{ numberOfEarlyStartStudent }}) </template>
                                <template v-else-if="earlyStartEnded"> ENDED </template>
                                <template v-else-if="!testObj.earlyStartDurationInMinutes"> NO </template>
                            </label>
                        </div>
                    </div>

                    <div v-if="testObj.type == 'trat'" class="col-xs-12">
                        <div class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Allow Team Clarifications </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: testObj.settings.allowTeamClarifications,
                                    greyText: !testObj.settings.allowTeamClarifications,
                                }"
                            >
                                <template v-if="testObj.settings.allowTeamClarifications"> YES </template>
                                <template v-else>NO</template>
                            </label>
                        </div>

                        <div class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Allow students to upload files </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: testObj.settings.allowStudentsToUploadFiles,
                                    greyText: !testObj.settings.allowStudentsToUploadFiles,
                                }"
                            >
                                <template v-if="testObj.settings.allowStudentsToUploadFiles"> YES </template>
                                <template v-else>NO</template>
                            </label>
                        </div>

                        <div class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Students must answer questions in sequence </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: !testObj.settings.allowViewQuestionsAhead,
                                    greyText: testObj.settings.allowViewQuestionsAhead,
                                }"
                            >
                                <template v-if="!testObj.settings.allowViewQuestionsAhead"> YES </template>
                                <template v-else>NO</template>
                            </label>
                        </div>

                        <div v-if="!testObj.settings.allowViewQuestionsAhead" class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Allow students to view previous questions </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: testObj.settings.allowViewPreviousQuestions,
                                    greyText: !testObj.settings.allowViewPreviousQuestions,
                                }"
                            >
                                <template v-if="testObj.settings.allowViewPreviousQuestions"> YES </template>
                                <template v-else>NO</template>
                            </label>
                        </div>
                        <div class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Randomize answer option order </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: testObj.settings.randomizeAnswerOptionOrder,
                                    greyText: !testObj.settings.randomizeAnswerOptionOrder,
                                }"
                            >
                                <template v-if="testObj.settings.randomizeAnswerOptionOrder"> YES </template>
                                <template v-else>NO</template>
                            </label>
                        </div>

                        <div v-if="testObj.settings.randomizeAnswerOptionOrder" class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Hide answer choice key </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: testObj.settings.hideQuestionOptionLabels,
                                    greyText: !testObj.settings.hideQuestionOptionLabels,
                                }"
                            >
                                <template v-if="testObj.settings.hideQuestionOptionLabels"> YES </template>
                                <template v-else>NO</template>
                            </label>
                        </div>
                        <div class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Has Time Limit </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: testObj.settings.hasTimeLimit,
                                    greyText: !testObj.settings.hasTimeLimit,
                                }"
                            >
                                <template v-if="testObj.settings.hasTimeLimit">YES</template>
                                <template v-else>NO</template>
                            </label>
                        </div>

                        <div class="flexSpaceBetween">
                            <label
                                class="control-label fontBlack col-md-10"
                                :class="{
                                    marginBottom10: accommodationsStudentsCount > 0,
                                }"
                            >
                                During Team-based activities, team members will see what the team leader sees
                            </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: testObj.settings.isSyncToTeamQuestion,
                                    greyText: !testObj.settings.isSyncToTeamQuestion,
                                }"
                            >
                                <template v-if="testObj.settings.isSyncToTeamQuestion"> YES </template>
                                <template v-else>NO</template>
                            </label>
                        </div>
                    </div>

                    <div v-if="testObj.type == 'application'" class="col-xs-12">
                        <div class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Type </label>
                            <label class="static-label-toggle col-md-2 alignRight text-capitalize">
                                {{ testObj.others.applicationType }}
                            </label>
                        </div>

                        <div class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Graded </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: testObj.others.isApplicationGraded,
                                    greyText: !testObj.others.isApplicationGraded,
                                }"
                            >
                                <template v-if="testObj.others.isApplicationGraded"> YES </template>
                                <template v-else>NO</template>
                            </label>
                        </div>

                        <div class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Allow students to upload files </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: testObj.settings.allowStudentsToUploadFiles,
                                    greyText: !testObj.settings.allowStudentsToUploadFiles,
                                }"
                            >
                                <template v-if="testObj.settings.allowStudentsToUploadFiles"> YES </template>
                                <template v-else>NO</template>
                            </label>
                        </div>

                        <div class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Allow students to change answers before final submission </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: testObj.settings.allowChangeAnswersBeforeFinalSubmission,
                                    greyText: !testObj.settings.allowChangeAnswersBeforeFinalSubmission,
                                }"
                            >
                                <template v-if="testObj.settings.allowChangeAnswersBeforeFinalSubmission"> YES </template>
                                <template v-else>NO</template>
                            </label>
                        </div>

                        <div class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Students must answer questions in sequence </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: !testObj.settings.allowViewQuestionsAhead,
                                    greyText: testObj.settings.allowViewQuestionsAhead,
                                }"
                            >
                                <template v-if="!testObj.settings.allowViewQuestionsAhead"> YES </template>
                                <template v-else>NO</template>
                            </label>
                        </div>

                        <div v-if="!testObj.settings.allowViewQuestionsAhead" class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Allow students to view previous questions </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: testObj.settings.allowViewPreviousQuestions,
                                    greyText: !testObj.settings.allowViewPreviousQuestions,
                                }"
                            >
                                <template v-if="testObj.settings.allowViewPreviousQuestions"> YES </template>
                                <template v-else>NO</template>
                            </label>
                        </div>

                        <div class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Has Time Limit </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: testObj.settings.hasTimeLimit,
                                    greyText: !testObj.settings.hasTimeLimit,
                                }"
                            >
                                <template v-if="testObj.settings.hasTimeLimit">YES</template>
                                <template v-else>NO</template>
                            </label>
                        </div>

                        <div v-if="testObj.others.applicationType == 'team'" class="flexSpaceBetween">
                            <label class="control-label fontBlack col-md-10">
                                During Team-based activities, team members will see what the team leader sees
                            </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: testObj.settings.isSyncToTeamQuestion,
                                    greyText: !testObj.settings.isSyncToTeamQuestion,
                                }"
                            >
                                <template v-if="testObj.settings.isSyncToTeamQuestion"> YES </template>
                                <template v-else>NO</template>
                            </label>
                        </div>

                        <div v-if="testObj.others.applicationType != 'team'" class="flexSpaceBetween">
                            <label
                                class="control-label fontBlack col-md-10"
                                :class="{
                                    marginBottom10: accommodationsStudentsCount > 0,
                                }"
                            >
                                Confidence-Based Testing
                            </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: testObj.settings.confidenceBasedTesting,
                                    greyText: !testObj.settings.confidenceBasedTesting,
                                }"
                            >
                                <template v-if="testObj.settings.confidenceBasedTesting"> YES </template>
                                <template v-else>NO</template>
                            </label>
                        </div>

                        <div v-if="accommodationsStudentsCount > 0 && testObj.others.applicationType == 'individual'" class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Students with Accommodations </label>

                            <label class="static-label-toggle col-md-2 alignRight green"> YES ({{ accommodationsStudentsCount }}) </label>
                        </div>

                        <div v-if="accommodationsStudentsCount > 0 && testObj.others.applicationType == 'individual'" class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Enable Early Start </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: canStartEarly || earlyStartOngoing,
                                    greyText: earlyStartEnded || !testObj.earlyStartDurationInMinutes,
                                }"
                            >
                                <template v-if="canStartEarly || earlyStartOngoing"> YES ({{ numberOfEarlyStartStudent }}) </template>
                                <template v-else-if="earlyStartEnded"> ENDED </template>
                                <template v-else-if="!testObj.earlyStartDurationInMinutes"> NO </template>
                            </label>
                        </div>
                    </div>

                    <div v-if="['evaluation', 'michaelsenEvaluation'].includes(testObj.type)" class="col-xs-12">
                        <div class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Michaelsen Point Distribution </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: testObj.settings.enableMichaelsenPointDistribution,
                                    greyText: !testObj.settings.enableMichaelsenPointDistribution,
                                }"
                            >
                                <template v-if="testObj.settings.enableMichaelsenPointDistribution"> YES </template>
                                <template v-else>NO</template>
                            </label>
                        </div>

                        <div class="flexSpaceBetween">
                            <label
                                class="control-label fontBlack col-md-10"
                                :class="{
                                    marginBottom10: accommodationsStudentsCount > 0,
                                }"
                            >
                                Has Time Limit
                            </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: testObj.settings.hasTimeLimit,
                                    greyText: !testObj.settings.hasTimeLimit,
                                }"
                            >
                                <template v-if="testObj.settings.hasTimeLimit">YES</template>
                                <template v-else>NO</template>
                            </label>
                        </div>

                        <div v-if="accommodationsStudentsCount > 0" class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Students with Accommodations </label>

                            <label class="static-label-toggle col-md-2 alignRight green"> YES ({{ accommodationsStudentsCount }}) </label>
                        </div>

                        <div v-if="accommodationsStudentsCount > 0" class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10"> Enable Early Start </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: canStartEarly || earlyStartOngoing,
                                    greyText: earlyStartEnded || !testObj.earlyStartDurationInMinutes,
                                }"
                            >
                                <template v-if="canStartEarly || earlyStartOngoing"> YES ({{ numberOfEarlyStartStudent }}) </template>
                                <template v-else-if="earlyStartEnded"> ENDED </template>
                                <template v-else-if="!testObj.earlyStartDurationInMinutes"> NO </template>
                            </label>
                        </div>
                    </div>
                    <div v-if="!['evaluation', 'michaelsenEvaluation'].includes(testObj.type) && testObj.settings.allowSafeExamBrowserOnly" class="col-xs-12">
                        <div class="flexSpaceBetween">
                            <label class="control-label fontBlack marginBottom10 col-md-10 marginTop10">
                                <template v-if="testObj.type == 'trat' || (testObj.type == 'application' && testObj.others.applicationType == 'team')">
                                    Teams
                                </template>
                                <template v-else> Students </template>
                                must use Safe Exam Browser
                            </label>
                            <label
                                class="static-label-toggle col-md-2 alignRight"
                                :class="{
                                    green: testObj.settings.allowSafeExamBrowserOnly,
                                    greyText: !testObj.settings.allowSafeExamBrowserOnly,
                                }"
                            >
                                <template v-if="testObj.settings.allowSafeExamBrowserOnly"> YES </template>
                                <template v-else>NO</template>
                            </label>
                        </div>
                    </div>
                </div>

                <!--<div class="row flex">
					<template v-if="testObj.settings.type=='asynchronous'">
						<div class="col-xs-12 col-md-12">
							<div class="form-group">
			                    <label class="control-label">STARTS FROM</label>
			                    <p class="form-control-static margin0"><i class="far fa-calendar-alt"></i>&nbsp;
			                    {{convertToReadableDate(testObj.settings.startDate,'DD MMM YYYY, h:mm a').date}} ({{convertToReadableDate(testObj.settings.startDate,'DD MMM YYYY, h:mm a').current_timezone}})</p>
			                </div>
			            </div>
			            <div class="col-xs-12 col-md-12">
							<div class="form-group">
			                    <label class="control-label">ENDS ON</label>
			                    <p class="form-control-static margin0"><i class="far fa-calendar-alt"></i>&nbsp;
			                    	 {{convertToReadableDate(testObj.settings.endDate,'DD MMM YYYY, h:mm a').date}} ({{convertToReadableDate(testObj.settings.endDate,'DD MMM YYYY, h:mm a').current_timezone}})
			                    </p>
			                </div>
			           </div> 
			        </template>
			        <template v-if="testObj.settings.type=='synchronous'">
						<div class="col-xs-12 col-md-12">
							<div class="form-group">
			                    <label class="control-label">STARTS FROM</label>
			                    <p class="form-control-static margin0"><i class="far fa-calendar-alt"></i>&nbsp;
			                    
			                    <template v-if="!['not started'].includes(testObj.status)">
			                    	{{convertToReadableDate(testObj.startTime,'DD MMM YYYY, h:mm a').date}} ({{convertToReadableDate(testObj.startTime,'DD MMM YYYY, h:mm a').current_timezone}})
			                    </template>
			                    <template v-else>
			                    	-
			                    </template>
			                	</p>
			                </div>
			            </div>
			            <div class="col-xs-12 col-md-12">
							<div class="form-group">
			                    <label class="control-label">ENDS ON</label>
			                    <p class="form-control-static margin0"><i class="far fa-calendar-alt"></i>&nbsp;
				                    <template v-if="['ongoing','paused','completed'].includes(testObj.status)">
				                    	{{convertToReadableDate(testObj.endTime,'DD MMM YYYY, h:mm a').date}} ({{convertToReadableDate(testObj.endTime,'DD MMM YYYY, h:mm a').current_timezone}})
				                    </template>
				                     <template v-else-if="['ended'].includes(testObj.status)">
				                    	{{convertToReadableDate(testObj.timeEnded,'DD MMM YYYY, h:mm a').date}} ({{convertToReadableDate(testObj.timeEnded,'DD MMM YYYY, h:mm a').current_timezone}})
				                    </template>
				                    <template v-else>
				                    	-
				                    </template>
				               </p>
			                </div>
			           </div> 
			        </template>
                </div>-->

                <template v-if="testObj.settings.type == 'asynchronous'">
                    <div>
                        <div class="form-group">
                            <label class="control-label">Open Period Is From</label>
                            <p class="form-control-static">
                                <i class="far fa-calendar-alt marginRight8" aria-hidden="true"></i>
                                <template v-if="!['not started'].includes(testObj.status)">
                                    {{ convertToReadableDate(testObj.startTime, 'DD MMM YYYY, h:mm a').date }}
                                    ({{ convertToReadableDate(testObj.startTime, 'DD MMM YYYY, h:mm a').current_timezone }}) to
                                </template>
                                <template v-else> - to </template>
                                <template v-if="['ongoing', 'paused', 'completed', 'scheduled', 'ended'].includes(testObj.status)">
                                    {{ convertToReadableDate(testObj.endTime, 'DD MMM YYYY, h:mm a').date }}
                                    ({{ convertToReadableDate(testObj.endTime, 'DD MMM YYYY, h:mm a').current_timezone }})
                                </template>
                                <template v-else> - </template>
                            </p>
                        </div>
                    </div>
                </template>
                <template v-if="testObj.settings.type == 'synchronous' && testObj.settings.hasTimeLimit">
                    <div class="flex">
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group">
                                <label class="control-label">Starts From</label>
                                <p class="form-control-static">
                                    <i class="far fa-calendar-alt marginRight8" aria-hidden="true"></i>

                                    <template v-if="!['not started'].includes(testObj.status)">
                                        {{ convertToReadableDate(testObj.startTime, 'DD MMM YYYY, h:mm a').date }}
                                        ({{ convertToReadableDate(testObj.startTime, 'DD MMM YYYY, h:mm a').current_timezone }})
                                    </template>
                                    <template v-else> - </template>
                                </p>
                            </div>
                        </div>
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group">
                                <label class="control-label">Ends On</label>
                                <p class="form-control-static">
                                    <i class="far fa-calendar-alt marginRight8" aria-hidden="true"></i>
                                    <template v-if="['ongoing', 'paused', 'completed', 'ended'].includes(testObj.status)">
                                        {{ convertToReadableDate(testObj.endTime, 'DD MMM YYYY, h:mm a').date }}
                                        ({{ convertToReadableDate(testObj.endTime, 'DD MMM YYYY, h:mm a').current_timezone }})
                                    </template>
                                    <template v-else> - </template>
                                </p>
                            </div>
                        </div>
                    </div>
                </template>

                <template
                    v-if="
                        (['irat', 'trat', 'application'].includes(testObj.type) ||
                            ((testObj.type == 'evaluation' || testObj.type == 'michaelsenEvaluation') && testObj.settings.type == 'synchronous')) &&
                        testObj.settings.hasTimeLimit
                    "
                >
                    <div class="row flex">
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group">
                                <label class="control-label"> Test Duration </label>
                                <b class="d-block hidden-md hidden-lg">Planned</b>
                                <p class="form-control-static">
                                    {{ testObj.plannedDuration }}
                                    <b class="hidden-xs hidden-sm marginLeft5">Planned</b>
                                </p>
                            </div>
                        </div>
                        <div v-if="['ended', 'completed'].includes(testObj.status) && testObj.settings.type == 'synchronous'" class="col-xs-12 col-md-6">
                            <div class="form-group">
                                <label class="control-label"></label>
                                <b class="hidden-md hidden-lg">Actual</b>
                                <p class="form-control-static">
                                    {{ testObj.actualDuration }}
                                    <b class="hidden-xs hidden-sm marginLeft5">Actual</b>
                                </p>
                            </div>
                        </div>
                    </div>
                </template>
                <template
                    v-if="
                        (['irat', 'evaluation'].includes(testObj.type) || (testObj.type == 'application' && testObj.others.applicationType == 'individual')) &&
                        testObj.settings.type == 'synchronous'
                    "
                >
                    <div class="row flex">
                        <div class="col-xs-12 col-md-6">
                            <div class="form-group">
                                <label class="control-label"> Early Start Duration </label>
                                <p class="form-control-static">
                                    <template v-if="testObj.earlyStartDurationInMinutes">
                                        {{ testObj.earlyStartDurationInMinutes }}
                                        Min
                                    </template>
                                    <template v-else> N/A </template>
                                </p>
                            </div>
                        </div>
                    </div>
                </template>
                <!-- <div
          v-if="
            (['irat', 'trat', 'application'].includes(testObj.type) ||
              ((testObj.type == 'evaluation' ||
                testObj.type == 'michaelsenEvaluation') &&
                testObj.settings.type == 'synchronous')) &&
              testObj.settings.hasTimeLimit
          "
          class="row flex"
        >
          <div class="form-group">
            <label class="control-label">TEST DURATION</label>
          </div>
          <div class="col-xs-12 col-md-6">
            <b class="hidden-md hidden-lg">Planned</b>
            <p class="form-control-static">
              {{ testObj.plannedDuration }}
              <b class="hidden-xs hidden-sm">Planned</b>
            </p>
          </div>
          <div
            class="col-xs-12 col-md-6"
            v-if="
              ['ended', 'completed'].includes(testObj.status) &&
                testObj.settings.type == 'synchronous'
            "
          >
            <b class="hidden-md hidden-lg">Actual</b>
            <p class="form-control-static">
              {{ testObj.actualDuration }}
              <b class="hidden-xs hidden-sm">Actual</b>
            </p>
          </div>
        </div> -->

                <div v-if="['irat', 'trat', 'application'].includes(testObj.type)" class="row flex">
                    <div class="form-group">
                        <label class="control-label">Average Completion Time</label>
                    </div>
                    <div class="col-xs-6 col-md-12">
                        <div class="form-group">
                            <p class="form-control-static" :class="{ margin0: !testObj.settings.password }">
                                {{ testObj.totalAverageCompletionPerQuestion }}
                                <b class="marginLeft5">Per Question</b>
                            </p>
                        </div>
                    </div>
                    <!--<div class="col-xs-6">
						<div class="form-group">
		                    <p class="form-control-static margin0">
		                    	{{testObj.totalAverageCompletion}}  <b>In Total</b>
		                    </p>
		                </div>
		           </div> -->
                </div>

                <div v-if="testObj.settings.password" class="row flex">
                    <div class="col-xs-12 col-sm-6">
                        <div class="form-group">
                            <label class="control-label">Activity Password</label>
                            <p class="form-control-static margin0">
                                {{ testObj.settings.password }}
                            </p>
                        </div>
                    </div>
                </div>
            </template>
        </kr-panel>
    </div>
</template>
<script>
export default {
    props: ['testObj'],
    data() {
        return {
            component_done_loading: true,
            accommodationsStudentsCount: null,
        };
    },
    computed: {
        canStartEarly() {
            return (
                ['not started'].includes(this.testObj.status) &&
                this.testObj.earlyStartDurationInMinutes &&
                !this.testObj.expectedEndingTime &&
                !this.testObj.startedEarly &&
                (['irat', 'evaluation', 'michaelsenEvaluation'].includes(this.testObj.type) ||
                    (this.testObj.type == 'application' && this.testObj.others.applicationType == 'individual'))
            );
        },
        earlyStartOngoing() {
            return this.testObj.startedEarly && this.testObj.expectedEndingTime;
        },
        earlyStartEnded() {
            return this.testObj.startedEarly && !this.testObj.expectedEndingTime;
        },
        numberOfEarlyStartStudent() {
            var attendance = this.testObj.attendance.groupedStudents;
            let count = 0;

            for (const team in attendance) {
                if (attendance.hasOwnProperty(team)) {
                    const members = attendance[team].members;
                    if (Array.isArray(members)) {
                        for (const student of members) {
                            if (student.canEarlyStart === 1) {
                                count++;
                            }
                        }
                    }
                }
            }

            return count;
        },
    },
    created() {
        this.getAccommodations();
    },
    methods: {
        getAccommodations() {
            var that = this;

            axios.get(`courses/${this.testObj.course.uuid}/accommodations`).then(function (response) {
                const students = response.data.data;

                that.accommodationsStudentsCount = students.reduce((total, student) => {
                    if (student.accommodations.multiple != null) {
                        return total + 1;
                    }
                    return total;
                }, 0);
            });
        },
    },
};
</script>

<style scoped>
.margin0 {
    margin: 0 !important;
}
.static-label-toggle {
    line-height: 1.2;
}
</style>
