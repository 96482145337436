<template>
    <div v-if="component_done_loading" class="col-xs-12 right-container">
        <div class="col-md-12 form-div">
            <kr-panel :with-footer="false">
                <template #title>
                    <div class="col-xs-6">EDIT MODULE</div>
                    <!-- <div class="col-xs-6 flexRight">
						<div class="hidden-xs hidden-sm">
				            <button class="btn btn-success mainBtn" :disabled="(!canSend) ? true:undefined" @click.prevent="submit">
								<i class="fa fa-check"></i>&nbsp;Update
				            </button>
				            <router-link v-if="isSetCourse" :to="{name:'courses.view',params:{id:form.model.courseUuid},query:{tab:'module'}}" tag="button" class="btn btn-default mainBtn" exact>
				            	<i class="fa fa-caret-left"></i>&nbsp;Back
				            </router-link>
				        </div>
				        <div class="hidden-md hidden-lg">
				            <button class="btn btn-success mainBtn" :disabled="(!canSend) ? true:undefined"@click.prevent="submit"
				                <i class="fa fa-check"></i>
				            </button>
				            <router-link v-if="isSetCourse" :to="{name:'courses.view',params:{id:form.model.courseUuid},query:{tab:'module'}}" tag="button" class="btn btn-default mainBtn" exact>
				            	<i class="fa fa-caret-left"></i>
				            </router-link>
				        </div>
			        </div> -->
                </template>
                <template #content>
                    <div class="col-xs-12">
                        <module-form :form="form" :course="course"></module-form>
                    </div>
                    <div class="col-xs-12 col-md-6">
                        <div class="buttonGroup">
                            <button class="btn btn-success mainBtn" :disabled="!canSend ? true : undefined" aria-label="Update" @click.prevent="submit">
                                <i class="fa fa-check marginRight8" aria-hidden="true"></i>Update
                            </button>
                            <router-link
                                :to="{
                                    name: 'courses.view',
                                    params: { id: form.model.courseUuid },
                                    query: { tab: 'module' },
                                }"
                                tag="button"
                                class="btn btn-default mainBtn"
                                exact
                                aria-label="Cancel"
                            >
                                Cancel
                            </router-link>
                        </div>
                    </div>
                </template>
            </kr-panel>
            <div class="viewDropdown flexRight">
                <div class="dropup col-xs-6 col-md-2">
                    <button
                        id="viewDropdownMenuButton"
                        class="mainBtn width100 flexSpaceBetween align-items"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        aria-label="More Actions"
                    >
                        More Actions
                        <i class="fa fa-caret-down marginLeft8" aria-hidden="true"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                        <ul>
                            <li v-if="!module.isArchived">
                                <a
                                    v-tooltip="'Archive This Module'"
                                    class="danger-state"
                                    href="#"
                                    aria-label="Archive This Module"
                                    @click.prevent="openArchiveModal()"
                                >
                                    <span> <i class="fa fa-trash marginRight8" aria-hidden="true"></i>Archive This Module </span>

                                    <!-- <span>⇧⌘D</span> -->
                                </a>
                            </li>
                            <li v-else>
                                <a v-tooltip="'Reinstate'" class="primary-state" href="#" aria-label="Reinstate" @click.prevent="reinstate()">
                                    <span> <i class="fa fa-refresh marginRight8" aria-hidden="true"></i>Reinstate </span>

                                    <!-- <span>⇧⌘D</span> -->
                                </a>
                            </li>
                        </ul>
                    </div>

                    <!-- <div class="dropdown-menu" aria-labelledby="viewDropdownMenuButton">
            <button
              v-if="!module.isArchived"
              id="dropdown-suspend-module-btn"
              class="btn btn-danger mainBtn"
              @click.prevent="openArchiveModal()"
              aria-label="Archive This Module"
            >
              <i class="fa fa-trash"></i>&nbsp;Archive This Module
            </button>
            <button
              v-else
              id="modal-reinstate-btn"
              class="btn btn-success mainBtn"
              @click.prevent="reinstate()"
              aria-label="Reinstate"
            >
              <i class="fa fa-refresh"></i>&nbsp;Reinstate
            </button>
          </div> -->
                </div>
            </div>
        </div>
        <div id="suspendModal" class="modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="suspendModal-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h2 id="suspendModal-title" class="modal-title">Archive This Module</h2>
                    </div>
                    <div class="modal-body textAlignCenter">
                        <div>Are you sure you want to archive this module?</div>
                        <div>All associated activities will also be hidden until you reinstate this module.</div>
                        <div class="flex marginTop30">
                            <button class="btn btn-danger mainBtn" aria-label="Archive" @click.prevent="archive()">
                                <i class="fa fa-trash marginRight8" aria-hidden="true"></i>Archive
                            </button>
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Cancel">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KrForm from '../../../../components/forms/form';
import KrAuth from '../../../../components/auth/auth';
export default {
    data() {
        var auth = new KrAuth();
        return {
            component_done_loading: false,
            module: {},
            form: new KrForm({
                fields: {
                    courseUuid: '',
                    name: '',
                    //code:'',
                    description: '',
                    startDate: '',
                    endDate: '',
                    objectives: 'type!:array',
                    descriptionIsHTML: '',
                },
                props: {},
                urls: {
                    put: 'modules/' + this.$route.params.id,
                },
            }),
            course: {},
        };
    },
    components: {
        'module-form': require(`./form-partials/partial.module.vue`).default,
    },
    computed: {
        canSend() {
            if (this.form.model.name && this.form.model.startDate && this.form.model.endDate) {
                return true;
            }
            return false;
        },
    },
    created() {
        var that = this;
        Events.fire('topbar_update', {
            title: '<i class="fas fa-graduation-cap"></i>&nbsp;Courses',
            breadcrumb: [{ name: 'Home' }, { name: 'Courses', link: 'courses.index' }],
        });
        axios.get('/modules/' + that.$route.params.id).then(function (response) {
            //parse dates first
            that.form.setModel(response.data.data);
            that.module = response.data.data;
            that.course = that.module.course;
            that.form.model.courseUuid = that.course.uuid;
            that.form.model.descriptionIsHTML = false;
            Events.fire('topbar_update', {
                breadcrumb: [
                    { name: 'Home' },
                    { name: 'Courses', link: 'courses.index' },
                    {
                        name: that.course.name + '(' + that.course.code + ')',
                        link: 'courses.view',
                        params: { id: that.course.uuid },
                        query: { tab: 'module' },
                    },
                    { name: 'Edit Module' },
                ],
            });
            that.component_done_loading = true;
        });
    },
    methods: {
        openArchiveModal() {
            $('#suspendModal').modal('show');
        },
        archive() {
            $('#suspendModal').modal('hide');
            var success = function () {
                this.module.isArchived = true;
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'You have sucessfully archived this module',
                });
                this.$router.push({
                    name: 'courses.view',
                    params: { id: this.module.courseUuid },
                });
            }.bind(this);
            var del = function () {
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .patch('/modules/' + this.$route.params.id)
                .then(function (response) {
                    success();
                })
                .catch(function (errors) {
                    del();
                });
        },
        reinstate() {
            $('#suspendModal').modal('hide');
            var success = function () {
                this.module.isArchived = false;
                this.$notify({
                    group: 'form',
                    type: 'success',
                    title: 'Success',
                    text: 'Module has been reinstated',
                });
            }.bind(this);
            var del = function () {
                this.$notify({
                    group: 'form',
                    type: 'error',
                    title: 'Error',
                    text: 'Something went wrong, please try again later ',
                });
            }.bind(this);
            axios
                .patch('/modules/' + this.$route.params.id)
                .then(function (response) {
                    success();
                })
                .catch(function (errors) {
                    del();
                });
        },
        submit() {
            var that = this;
            this.form.put().then(function (response) {
                if (!response.krFormError) {
                    that.$router.push({
                        name: 'courses.view',
                        params: { id: that.course.uuid },
                        query: { tab: 'module', moduleUuid: that.module.uuid },
                    });
                }
            });
        },
    },
};
</script>
