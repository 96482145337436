<template>
    <div class="create flex">
        <div class="col-xs-12 col-md-6">
            <!--<kr-select display="Course" :form="form" name="courseID" :items="courses" item_value_idx="value" item_desc_idx="description" v-if="!isSetCourse" @changed="changedCourse">
                <template #option="props">
                    <template v-for="course in courses">
                        <option :value="course.course.uuid" >
                            {{course.course.code?course.course.code:''}}{{course.course.name?course.course.name:''}}
                        </option>
                    </template>
                </template>
            </kr-select>-->
            <!--<div class="form-group" v-else>
                <label class="control-label text-uppercase">COURSE</label>
                <p class="form-control-static">{{course.code?course.code:''}}{{course.name?course.name:''}}</p>
            </div>-->
            <div>
                <kr-field
                    :form="form"
                    name="name"
                    :options="{ placeholder: 'e.g., My module' }"
                    display="NAME <span class='important'>*</span>"
                    aria-label="name"
                />
            </div>
            <!--<div>    
               <kr-field :form="form" display="MODULE CODE" :options="{placeholder:'Enter a module code for your module'}" name="code"></kr-field>
            </div>-->
            <div>
                <label class="control-label">PERIOD <span class="important">*</span></label>
            </div>
            <div class="hidden-xs hidden-sm col-xs-12 flex marginBottom20">
                <!-- <kr-date-range display="PERIOD <span class='important'>*</span>" name="range" :form="form" start="startDate" end="endDate" :options="{time:true}"></kr-date-range> -->
                <!--<div class="col-xs-12 col-md-6">
                   <kr-date :form="form" name="startDate" :options="{startDate:form.model.startDate?form.model.startDate:course.startDate,minDate:course.startDate,maxDate:form.model.endDate?form.model.endDate:course.endDate,time:false,placeholder:'Select module start date',hasLabel:false}"></kr-date>
                </div>
                <div class="col-xs-12 col-md-6">
                    <kr-date :form="form" name="endDate" :options="{startDate:form.model.endDate?form.model.endDate:course.endDate,maxDate:course.endDate,minDate:form.model.startDate?form.model.startDate:course.startDate,time:false,placeholder:'Select module end date',hasLabel:false}"></kr-date>
                </div>-->
                <div class="col-xs-12 col-md-6">
                    <kr-date
                        :form="form"
                        name="startDate"
                        :options="{
                            startDate: form.model.startDate,
                            maxDate: form.model.endDate,
                            time: false,
                            placeholder: 'Select module start date',
                            hasLabel: false,
                        }"
                    />
                </div>
                <div class="col-xs-12 col-md-6">
                    <kr-date
                        :form="form"
                        name="endDate"
                        :options="{
                            startDate: form.model.endDate,
                            minDate: form.model.startDate,
                            time: false,
                            placeholder: 'Select module end date',
                            hasLabel: false,
                        }"
                    />
                </div>
            </div>
            <div class="hidden-md hidden-lg flex marginBottom20">
                <!-- <kr-date-range display="PERIOD <span class='important'>*</span>" name="range" :form="form" start="startDate" end="endDate" :options="{time:true}"></kr-date-range> -->
                <!--<div class="col-xs-12 col-md-6">
                   <kr-date :form="form" name="startDate" :options="{startDate:form.model.startDate?form.model.startDate:course.startDate,minDate:course.startDate,maxDate:form.model.endDate?form.model.endDate:course.endDate,time:false,placeholder:'Select module start date',hasLabel:false}"></kr-date>
                </div>
                <div class="col-xs-12 col-md-6">
                    <kr-date :form="form" name="endDate" :options="{startDate:form.model.endDate?form.model.endDate:course.endDate,maxDate:course.endDate,minDate:form.model.startDate?form.model.startDate:course.startDate,time:false,placeholder:'Select module end date',hasLabel:false}"></kr-date>
                </div>-->
                <div class="col-xs-12 marginBottom10">
                    <kr-date
                        :form="form"
                        name="startDate"
                        :options="{
                            startDate: form.model.startDate,
                            maxDate: form.model.endDate,
                            time: false,
                            placeholder: 'Select module start date',
                            hasLabel: false,
                        }"
                    />
                </div>
                <div class="col-xs-12">
                    <kr-date
                        :form="form"
                        name="endDate"
                        :options="{
                            startDate: form.model.endDate,
                            minDate: form.model.startDate,
                            time: false,
                            placeholder: 'Select module end date',
                            hasLabel: false,
                        }"
                    />
                </div>
            </div>
            <kr-field
                display="DESCRIPTION"
                :form="form"
                name="description"
                :options="{
                    type: 'text-area',
                    rows: 5,
                    placeholder: 'e.g., Knowledge is power',
                }"
            />
            <!--<div class="objectiveContainer">
                <label class="control-label">LEARNING OBJECTIVES</label>
                <template v-for="(objective,idx) in form.model.objectives">
                    <div class="objectiveRow">
                        <span>{{idx+1}}</span>
                        <kr-field :form="form" :name="'objectives.'+idx" :options="{placeholder:'e.g.,Explain relativity',hasLabel:false,showError:false}" @keyup.enter="addObjective()"></kr-field>
                        <button class="btn btn-default addBtn" v-if="form.model.objectives.length-1==idx" @click.prevent="addObjective()">
                            <i class="fa fa-plus"></i>
                        </button>
                        <button v-if="form.model.objectives.length!=1" class="btn btn-default deleteBtn" @click.prevent="deleteObjective(idx)">
                            <i class="fa fa-trash"></i>
                        </button>
                    </div>
                </template>    
            </div>-->
        </div>
        <div class="col-xs-12 col-md-6 hidden-xs hidden-sm">
            <div class="rightPanel">
                <div class="header">PARENT COURSE INFO</div>
                <div class="content">
                    <div>
                        <div class="header">Course</div>
                        <div class="text">
                            {{ course.name }}
                            <template v-if="course.code"> ({{ course.code }}) </template>
                        </div>
                    </div>
                    <!-- <div>
                        <div class="header">COURSE CODE</div>
                        <div class="text">{{course.code}}</div>
                    </div> -->
                    <div>
                        <div class="header">START FROM</div>
                        <div class="text">
                            {{ convertToReadableDate(course.startDate, 'DD MMM YYYY, hh:mm a').date }}
                            ({{ convertToReadableDate(course.startDate, 'DD MMM YYYY, hh:mm a').current_timezone }})
                        </div>
                    </div>
                    <div>
                        <div class="header">ENDS ON</div>
                        <div class="text">
                            {{ convertToReadableDate(course.endDate, 'DD MMM YYYY, hh:mm a').date }}
                            ({{ convertToReadableDate(course.endDate, 'DD MMM YYYY, hh:mm a').current_timezone }})
                        </div>
                    </div>
                    <div v-if="course.description">
                        <div class="header">DESCRIPTION</div>
                        <div class="text">
                            {{ course.description }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['form', 'course', 'isSetCourse'],
    data() {
        return {};
    },
    watch: {
        form: {
            handler: function () {
                /*FORCE*/
                if (this.form.model.objectives.length == 0) {
                    this.addObjective();
                }
            },
            deep: true,
        },
    },
    created() {
        //this.changedCourse();
        if (this.form.model.objectives.length == 0) {
            this.addObjective();
        }
    },
    methods: {
        /*changedCourse(){
                let course_id = this.form.model.courseUuid; // The value of the selected option
                this.course = _.find(this.courses,function(o){
                    return o.course.uuid ==course_id;
                }).course;
            },*/
        addObjective() {
            this.form.model.objectives.push('');
        },
        deleteObjective(idx) {
            this.form.model.objectives.splice(idx, 1);
        },
    },
};
</script>
<style scoped>
.objectiveContainer {
    margin-bottom: 30px;
}

.objectiveRow {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.objectiveRow:first-child {
    margin-bottom: 0;
}

.objectiveRow span {
    font-weight: bold;
    margin-right: 10px;
}

.create .rightPanel .header {
    font-weight: bold;
    text-transform: uppercase;
    color: #767676;
    margin-bottom: 10px;
}

.create .rightPanel .content {
    background: #fff;
    /* border: 1px solid #dfe3e9; */
    border: 1px solid #767676;
    padding: 15px;
    color: #767676;
}

.create .rightPanel .content > div {
    margin-top: 15px;
}

.create .rightPanel .content > div:first-child {
    margin-top: 0;
}

.create.flex .col-md-6:first-child {
    padding-right: 10px;
}

.create.flex .col-md-6:last-child {
    padding-left: 10px;
}
</style>
