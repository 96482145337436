<template>
    <div
        class="statusBar no-print"
        :class="{
            needClarification: isTestCompleted && testObj.type == 'trat' && canClarify,
            isReleased: isTestCompleted && testObj.type == 'trat' && canClarify && testObj.clarificationStatus == 'released',
        }"
    >
        <div class="panel hidden-xs hidden-sm">
            <div class="panel-heading flexSpaceBetween align-items">
                <div>
                    <button
                        class="btn marginLeft0"
                        :aria-expanded="sideBarClosedValue ? 'false' : 'true'"
                        aria-label="Question Status"
                        @click.prevent="handleButtonClick"
                    >
                        <template v-if="sideBarClosedValue"> Qns </template>
                        <template v-else> Question Status </template>
                        <i id="toggleIcon" class="fas fa-angles-right marginLeft8" aria-hidden="true" />
                    </button>
                </div>
                <!-- <template v-if="canClarify">
          <div class="marginRight10 menuText">
            Clarifications & Assignments
            <span class="visible-hidden" aria-hidden="true"> table </span>
          </div>
        </template>

        <div v-else-if="canDiscuss" tabindex="0" class="marginRight10 menuText">
          Question Discussions
          <span class="visible-hidden" aria-hidden="true"> table </span>
        </div>

        <div v-else tabindex="0" class="marginRight10 menuText">
          Questions Statuses
          <span class="visible-hidden" aria-hidden="true"> table </span>
        </div> -->

                <div class="flexRight">
                    <router-link
                        v-if="canClarify && !sideBarClosedValue"
                        v-tooltip="'View All Clarifications'"
                        :to="{
                            name: 'tests.clarification',
                            params: { id: testObj.uuid },
                        }"
                        tag="button"
                        class="btn btn-outline-primary"
                        aria-label="View All Clarifications"
                    >
                        <i class="fas fa-eye" aria-hidden="true" />
                    </router-link>
                    <router-link
                        v-if="canDiscuss && !sideBarClosedValue"
                        v-tooltip="'View Discussions'"
                        tag="button"
                        :to="{
                            name: 'tests.discussion',
                            params: { id: testObj.uuid },
                        }"
                        class="btn btn-outline-primary menuText"
                        aria-label="View Discussions"
                    >
                        <i class="fas fa-eye" aria-hidden="true" />
                    </router-link>
                </div>
            </div>

            <div class="panel-body test-sideBar-panel-body" :class="{ 'panel-body-closed': sideBarClosedValue }">
                <div v-if="!sideBarClosedValue" class="dropdown menuText marginBottom20 width100">
                    <button
                        id="viewDropdownMenuButton"
                        class="btn width100 flexSpaceBetween alignBaseline capitalize"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        {{ sort }}
                        <i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                    </button>
                    <div class="dropdown-menu dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                        <ul>
                            <li>
                                <a
                                    :class="{
                                        'active disabled': sort == 'all',
                                    }"
                                    href="#"
                                    @click="
                                        sort = 'all';
                                        getNumberOfQns();
                                    "
                                >
                                    All
                                </a>
                            </li>
                            <li>
                                <a
                                    :class="{
                                        'active disabled': sort == 'answered',
                                    }"
                                    href="#"
                                    @click="
                                        sort = 'answered';
                                        getNumberOfQns();
                                    "
                                >
                                    Answered
                                </a>

                                <ul>
                                    <li v-if="testObj.type == 'trat'">
                                        <i class="fa-solid fa-turn-up fa-rotate-90" aria-hidden="true" />
                                        <a
                                            :class="{
                                                'active disabled': sort == 'wrong answer',
                                            }"
                                            href="#"
                                            @click="
                                                sort = 'wrong answer';
                                                getNumberOfQns();
                                            "
                                        >
                                            Wrong Answer
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a
                                    :class="{
                                        'active disabled': sort == 'not answered',
                                    }"
                                    href="#"
                                    @click="
                                        sort = 'not answered';
                                        getNumberOfQns();
                                    "
                                >
                                    Not Answered
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <template v-else>
                    <div class="marginBottom20">
                        <template v-if="sort == 'all'">
                            <div class="tableStatusTag whiteSpaceNowrap default">All</div>
                        </template>
                        <template v-if="sort == 'not answered'">
                            <div class="tableStatusTag whiteSpaceNowrap danger">Not Answered</div>
                        </template>
                        <template v-if="sort == 'answered'">
                            <div class="tableStatusTag whiteSpaceNowrap answered">Answered</div>
                        </template>
                        <template v-if="sort == 'wrong answer'">
                            <div class="tableStatusTag whiteSpaceNowrap danger">Wrong Answer</div>
                        </template>
                    </div>
                </template>

                <ul>
                    <template v-if="getNumbers == 0">
                        <template v-if="sort == 'not answered'">
                            <p>You have <b>Answered</b> all the questions.</p>
                        </template>
                        <template v-if="sort == 'answered'">
                            <p>You have <b>Not Answered</b> any question.</p>
                        </template>
                        <template v-if="sort == 'wrong answer'">
                            <p>You have <b>Not Get Wrong Answer</b> for any question.</p>
                        </template>
                    </template>
                    <template v-for="(questionGroup, question_group_idx) in testObj.questions">
                        <template v-for="(question, question_idx) in questionGroup">
                            <li
                                v-if="canDisplayQuestion(question)"
                                :key="testObj.type + '-' + getQuestionNo(question_group_idx, question_idx)"
                                :class="{
                                    active: question_group_idx == current_question_group_idx && question_idx == current_question_idx,
                                    haveAnswered: getQuestionStatus(question_group_idx, question_idx) == 1 && sideBarClosedValue,
                                    disabled: !canChangeQuestion(question_group_idx, question_idx) && !isTeamLeader && testObj.type == 'trat',
                                }"
                            >
                                <button
                                    class="leftContent"
                                    :class="{
                                        'flexSpaceBetween align-items': !sideBarClosedValue,
                                        disabled: !canChangeQuestion(question_group_idx, question_idx),
                                    }"
                                    :disabled="!canChangeQuestion(question_group_idx, question_idx) ? true : undefined"
                                    @click="
                                        !canChangeQuestion(question_group_idx, question_idx)
                                            ? ''
                                            : moveToDifferentQuestion(question_group_idx, question_idx, 'scrollToQns')
                                    "
                                >
                                    <span>
                                        <i class="fa-solid fa-clipboard-list marginRight8" aria-hidden="true" />
                                        <span class="visible-hidden">Question {{ getQuestionNo(question_group_idx, question_idx) }}</span>
                                        <span aria-hidden="true">
                                            <b>Q{{ getQuestionNo(question_group_idx, question_idx) }}. </b>
                                        </span>
                                    </span>

                                    <div
                                        v-if="
                                            !(isTestCompleted && testObj.type == 'trat' && canClarify && testObj.clarificationStatus == 'released') &&
                                            !sideBarClosedValue
                                        "
                                        class="tableStatusTag whiteSpaceNowrap menuText testSidebar-status"
                                        :class="{
                                            danger:
                                                getQuestionStatus(question_group_idx, question_idx) == -1 ||
                                                getQuestionStatus(question_group_idx, question_idx) == 0,
                                            answered: getQuestionStatus(question_group_idx, question_idx) == 1,
                                        }"
                                    >
                                        <p>
                                            <template v-if="getQuestionStatus(question_group_idx, question_idx) == -1"> Wrong Answer </template>
                                            <template v-if="getQuestionStatus(question_group_idx, question_idx) == 0"> Not Answered </template>
                                            <template v-if="getQuestionStatus(question_group_idx, question_idx) == 1"> Answered </template>
                                        </p>
                                    </div>
                                </button>
                                <!-- <a
                                    class="leftContent"
                                    href="#"
                                    :class="{
                                        'flexSpaceBetween align-items':
                                            !sideBarClosedValue,
                                        disabled: !canChangeQuestion(
                                            question_group_idx,
                                            question_idx
                                        ),
                                    }"
                                    @click="
                                        !canChangeQuestion(
                                            question_group_idx,
                                            question_idx
                                        )
                                            ? ''
                                            : moveToDifferentQuestion(
                                                  question_group_idx,
                                                  question_idx,
                                                  'scrollToQns'
                                              )
                                    "
                                >
                                    <span>
                                        <i
                                            class="fa-solid fa-clipboard-list marginRight8"
                                            aria-hidden="true"
                                        />
                                        <span class="visible-hidden"
                                            >Question
                                            {{
                                                getQuestionNo(
                                                    question_group_idx,
                                                    question_idx
                                                )
                                            }}</span
                                        >
                                        <span aria-hidden="true">
                                            <b
                                                >Q{{
                                                    getQuestionNo(
                                                        question_group_idx,
                                                        question_idx
                                                    )
                                                }}.
                                            </b>
                                        </span>
                                    </span>

                                    <div
                                        v-if="
                                            !(
                                                isTestCompleted &&
                                                testObj.type == 'trat' &&
                                                canClarify &&
                                                testObj.clarificationStatus ==
                                                    'released'
                                            ) && !sideBarClosedValue
                                        "
                                        class="tableStatusTag whiteSpaceNowrap menuText testSidebar-status"
                                        :class="{
                                            danger:
                                                getQuestionStatus(
                                                    question_group_idx,
                                                    question_idx
                                                ) == -1 ||
                                                getQuestionStatus(
                                                    question_group_idx,
                                                    question_idx
                                                ) == 0,
                                            answered:
                                                getQuestionStatus(
                                                    question_group_idx,
                                                    question_idx
                                                ) == 1,
                                        }"
                                    >
                                        <p>
                                            <template
                                                v-if="
                                                    getQuestionStatus(
                                                        question_group_idx,
                                                        question_idx
                                                    ) == -1
                                                "
                                            >
                                                Wrong Answer
                                            </template>
                                            <template
                                                v-if="
                                                    getQuestionStatus(
                                                        question_group_idx,
                                                        question_idx
                                                    ) == 0
                                                "
                                            >
                                                Not Answered
                                            </template>
                                            <template
                                                v-if="
                                                    getQuestionStatus(
                                                        question_group_idx,
                                                        question_idx
                                                    ) == 1
                                                "
                                            >
                                                Answered
                                            </template>
                                        </p>
                                    </div>
                                </a> -->

                                <div v-if="isTestCompleted && testObj.type == 'trat' && canClarify" class="middleContent">
                                    <template v-if="!isClarificationAnswered(question_group_idx, question_idx) && !sideBarClosedValue">
                                        <p class="menuText">Clarification Added</p>
                                    </template>

                                    <template v-else-if="!['paused', 'stopped', 'released'].includes(testObj.clarificationStatus) && !sideBarClosedValue">
                                        <a
                                            class="link menuText"
                                            href="#"
                                            :class="{
                                                disabled: !canChangeQuestion(question_group_idx, question_idx),
                                            }"
                                            aria-label="click here to add clarification"
                                            @click="moveToDifferentQuestion(question_group_idx, question_idx, 'scrollToQns')"
                                        >
                                            Need Clarification
                                        </a>
                                    </template>
                                </div>

                                <div
                                    v-if="isTestCompleted && testObj.type == 'trat' && canClarify && testObj.clarificationStatus == 'released'"
                                    class="rightContent"
                                >
                                    <template v-if="isQuestionClarificationAssignedToMe(question_group_idx, question_idx) && !sideBarClosedValue">
                                        <p class="menuText">Clarification Assigned to us</p>
                                    </template>
                                    <template v-else-if="!sideBarClosedValue">
                                        <i class="fa-solid fa-xmark greyText menuText" aria-label="No Clarification Assigned to us" aria-hidden="true" />
                                    </template>
                                </div>
                            </li>
                            <span
                                v-if="
                                    canDisplayQuestion(question) &&
                                    question_idx == questionGroup.length - 1 &&
                                    question_group_idx != testObj.questions.length - 1
                                "
                                class="divider"
                            />
                        </template>
                    </template>
                </ul>
            </div>
        </div>

        <!-- <div class="hidden-xs hidden-sm">
      <div class="test-sideBar">
        <div class="panel">
          <div class="panel-heading flexSpaceBetween align-items">
            <div v-if="canClarify" tabindex="0" class="marginRight20">
              Clarifications & Assignments
              <span class="visible-hidden" aria-hidden="true"> table </span>
            </div>

            <div v-else-if="canDiscuss" tabindex="0" class="marginRight20">
              Question Discussions
              <span class="visible-hidden" aria-hidden="true"> table </span>
            </div>

            <div v-else tabindex="0" class="marginRight20">
              Questions Statuses
              <span class="visible-hidden" aria-hidden="true"> table </span>
            </div>

            <div class="flexRight">
              <router-link
                :to="{
                  name: 'tests.clarification',
                  params: { id: testObj.uuid },
                }"
                tag="button"
                v-if="canClarify"
                class="btn btn-outline-primary"
                aria-label="View All Clarifications"
                v-tooltip="'View All Clarifications'"
              >
                
                <i class="fas fa-eye" aria-hidden="true"></i>
              </router-link>
              <router-link
                tag="button"
                v-if="canDiscuss"
                :to="{ name: 'tests.discussion', params: { id: testObj.uuid } }"
                class="btn btn-outline-primary"
                aria-label="View Discussions"
                v-tooltip="'View Discussions'"
              >
                
                <i class="fas fa-eye" aria-hidden="true"></i>
              </router-link>
              <button 
                class="btn marginLeft0"
                @click.prevent="sideBarClosed = !sideBarClosed"
              >
                <i v-if="sideBarClosed" class="fas fa-angles-right" aria-hidden="true"></i>
                <i v-else class="fas fa-angles-left" aria-hidden="true"></i>
              </button>
            </div>
          </div>

          <div class="panel-body test-sideBar-panel-body">
            <table class="table test-sideBar">
              <thead>
                <tr>
                  <th style="width:44px;">NO.</th>
                  <th>
                    <template
                      v-if="
                        testObj.type == 'trat' ||
                          (this.testObj.type == 'application' &&
                            this.testObj.others.applicationType == 'team')
                      "
                    >
                      TEAM </template
                    ><template v-else>YOUR</template> STATUS
                  </th>
                  <th v-if="!isTestCompleted">ACTIONS</th>
                  <th
                    v-if="
                      isTestCompleted && testObj.type == 'trat' && canClarify
                    "
                  >
                    CLARIFICATION
                  </th>
                  <th
                    v-if="
                      isTestCompleted && testObj.type == 'trat' && canClarify
                    "
                  >
                    ASSIGNED
                  </th>
                </tr>
              </thead>
              <tbody>
                <template
                  v-for="(questionGroup,
                  question_group_idx) in testObj.questions"
                >
                  <tr
                    v-if="canDisplayQuestion(question)"
                    v-for="(question, question_idx) in questionGroup"
                    :key="
                      testObj.type +
                        '-' +
                        getQuestionNo(question_group_idx, question_idx)
                    " 
                  >
                    <td>
                      <button
                        class="btn gradient btn-default test-status-question-btn"
                        :class="{
                          disabled: !canChangeQuestion(
                            question_group_idx,
                            question_idx
                          ),
                        }"
                        @click="
                          !canChangeQuestion(question_group_idx, question_idx)
                            ? ''
                            : moveToDifferentQuestion(
                                question_group_idx,
                                question_idx
                              )
                        "
                        :aria-label="
                          canChangeQuestion(question_group_idx, question_idx)
                            ? 'Question ' +
                              getQuestionNo(question_group_idx, question_idx) +
                              ' Enabled'
                            : 'Question ' +
                              getQuestionNo(question_group_idx, question_idx) +
                              ' Disabled'
                        "
                        tabindex="0"
                      >
                        {{
                          getQuestionNo(question_group_idx, question_idx)
                        }}.&nbsp;<i
                          class="fas fa-info-circle"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </td>

                    <td tabindex="0">
                      <legend class="visible-hidden">
                        Question
                        {{ getQuestionNo(question_group_idx, question_idx) }}
                        statuses
                      </legend>

                      <div
                        v-if="
                          getQuestionStatus(question_group_idx, question_idx) ==
                            -1
                        "
                      >
                        <i
                          class="fas fa-circle redGradientCircle"
                          aria-hidden="true"
                        ></i
                        >&nbsp;
                        <span class="greyText">Wrong Answer</span>
                      </div>
                      <div
                        v-if="
                          getQuestionStatus(question_group_idx, question_idx) ==
                            0
                        "
                      >
                        <i
                          class="fas fa-circle redGradientCircle"
                          aria-hidden="true"
                        ></i
                        >&nbsp;
                        <span class="greyText">Not Completed</span>
                      </div>
                      <div
                        v-if="
                          getQuestionStatus(question_group_idx, question_idx) ==
                            1
                        "
                      >
                        <i
                          class="fas fa-circle dateCircle"
                          aria-hidden="true"
                        ></i
                        >&nbsp;<span> Completed </span>
                      </div>
                    </td>
                    <td v-if="!isTestCompleted">
												<template v-if="canChangeQuestion(question_group_idx,question_idx)&&!isTestCompleted">
													<span v-if="getQuestionStatus(question_group_idx,question_idx)!=1" @click="moveToDifferentQuestion(question_group_idx,question_idx)" style="cursor:pointer">Complete Now&nbsp;<i class="fas fa-caret-right"></i></span>
												</template>	
											</td>
                    <td
                      v-if="
                        isTestCompleted && testObj.type == 'trat' && canClarify
                      "
                      class="textAlignCenter"
                    >
                      <template
                        v-if="
                          !isClarificationAnswered(
                            question_group_idx,
                            question_idx
                          )
                        "
                      >
                        <i
                          class="fa fa-check greyText"
                          aria-label="Clarification Added"
                          tabindex="0"
                        ></i>
                      </template>

                      <template
                        v-else-if="
                          !['paused', 'stopped', 'released'].includes(
                            testObj.clarificationStatus
                          )
                        "
                      >
                        <a
                          class="link"
                          @click="
                            moveToDifferentQuestion(
                              question_group_idx,
                              question_idx
                            )
                          "
                          tabindex="0"
                          aria-label="click here to add clarification"
                        >
                          Need Clarification
                        </a>
                      </template>
                    </td>

                    <td
                      v-if="
                        isTestCompleted && testObj.type == 'trat' && canClarify
                      "
                      class="textAlignCenter"
                    >
                      <template
                        v-if="testObj.clarificationStatus != 'released'"
                      >
                      </template>
                      <template v-else>
                        <i
                          v-if="
                            isQuestionClarificationAssignedToMe(
                              question_group_idx,
                              question_idx
                            )
                          "
                          class="fa fa-check greyText"
                          aria-label="Clarification Assigned to me"
                          tabindex="0"
                        ></i>
                        <i
                          v-else
                          class="fa-solid fa-xmark greyText"
                          tabindex="0"
                          aria-label="No Clarification Assigned to me"
                          aria-hidden="true"
                        ></i>
                      </template>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div> -->

        <div id="testSidebarModal" class="modal" tabindex="-1" role="dialog" aria-labelledby="testSidebarModal-title">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h4 id="testSidebarModal-title" class="modal-title flexSpaceBetween align-items">
                            <template v-if="canClarify"> Clarifications & Assignments </template>
                            <template v-else-if="canDiscuss"> Question Discussions </template>
                            <template v-else> Questions Status </template>

                            <div class="marginRight10">
                                <router-link
                                    v-if="canClarify"
                                    v-tooltip="'View All Clarifications'"
                                    :to="{
                                        name: 'tests.clarification',
                                        params: { id: testObj.uuid },
                                    }"
                                    tag="button"
                                    class="btn btn-outline-primary mainBtn"
                                    aria-label="View All Clarifications"
                                >
                                    <i class="fas fa-eye" aria-hidden="true" />
                                    <!-- View All Clarifications -->
                                </router-link>
                                <router-link
                                    v-if="canDiscuss"
                                    v-tooltip="'View Discussions'"
                                    tag="button"
                                    :to="{
                                        name: 'tests.discussion',
                                        params: { id: testObj.uuid },
                                    }"
                                    class="btn btn-outline-primary mainBtn"
                                    aria-label="View Discussions"
                                >
                                    <i class="fas fa-eye" aria-hidden="true" />
                                    <!-- View Discussion -->
                                </router-link>
                            </div>
                        </h4>
                    </div>
                    <div class="modal-body test-sideBar">
                        <div class="dropdown menuText marginBottom20 width100">
                            <button
                                id="viewDropdownMenuButton"
                                class="btn width100 flexSpaceBetween alignBaseline capitalize"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {{ sort }}
                                <i class="fa fa-caret-down marginLeft8" aria-hidden="true" />
                            </button>
                            <div class="dropdown-menu dropdown-menu-new" aria-labelledby="dropdownMenuButton">
                                <ul>
                                    <li :class="{ active: sort == 'all' }">
                                        <a
                                            :class="{ disabled: sort == 'all' }"
                                            href="#"
                                            @click="
                                                sort = 'all';
                                                getNumberOfQns();
                                            "
                                        >
                                            All
                                        </a>
                                    </li>
                                    <li :class="{ active: sort == 'answered' }">
                                        <a
                                            :class="{
                                                disabled: sort == 'answered',
                                            }"
                                            href="#"
                                            @click="
                                                sort = 'answered';
                                                getNumberOfQns();
                                            "
                                        >
                                            Answered
                                        </a>
                                    </li>
                                    <li
                                        :class="{
                                            active: sort == 'not answered',
                                        }"
                                    >
                                        <a
                                            :class="{
                                                disabled: sort == 'not answered',
                                            }"
                                            href="#"
                                            @click="
                                                sort = 'not answered';
                                                getNumberOfQns();
                                            "
                                        >
                                            Not Answered
                                        </a>
                                    </li>
                                    <li
                                        v-if="testObj.type == 'trat'"
                                        :class="{
                                            active: sort == 'wrong answer',
                                        }"
                                    >
                                        <a
                                            :class="{
                                                disabled: sort == 'wrong answer',
                                            }"
                                            href="#"
                                            @click="
                                                sort = 'wrong answer';
                                                getNumberOfQns();
                                            "
                                        >
                                            Wrong Answer
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <ul>
                            <template v-if="getNumbers == 0">
                                <template v-if="sort == 'not answered'">
                                    <p>You have <b>Answered</b> all the questions.</p>
                                </template>
                                <template v-if="sort == 'answered'">
                                    <p>You have <b>Not Answered</b> any question.</p>
                                </template>
                                <template v-if="sort == 'wrong answer'">
                                    <p>You have <b>Not Get Wrong Answer</b> for any question.</p>
                                </template>
                            </template>
                            <template v-for="(questionGroup, question_group_idx) in testObj.questions">
                                <template v-for="(question, question_idx) in questionGroup">
                                    <li
                                        v-if="canDisplayQuestion(question)"
                                        :key="testObj.type + '-' + getQuestionNo(question_group_idx, question_idx)"
                                        :class="{
                                            active: question_group_idx == current_question_group_idx && question_idx == current_question_idx,
                                            haveAnswered: getQuestionStatus(question_group_idx, question_idx) == 1 && sideBarClosedValue,
                                            disabled: !canChangeQuestion(question_group_idx, question_idx) && !isTeamLeader && testObj.type == 'trat',
                                        }"
                                    >
                                        <a
                                            class="leftContent"
                                            href="#"
                                            :class="{
                                                'flexSpaceBetween align-items': !sideBarClosedValue,
                                                disabled: !canChangeQuestion(question_group_idx, question_idx),
                                            }"
                                            @click="
                                                !canChangeQuestion(question_group_idx, question_idx)
                                                    ? ''
                                                    : moveToDifferentQuestion(question_group_idx, question_idx, 'scrollToQns')
                                            "
                                        >
                                            <span>
                                                <i class="fa-solid fa-clipboard-list marginRight8" aria-hidden="true" />

                                                <span class="visible-hidden">Question {{ getQuestionNo(question_group_idx, question_idx) }}</span>
                                                <span aria-hidden="true">
                                                    <b>Q{{ getQuestionNo(question_group_idx, question_idx) }}. </b>
                                                </span>
                                            </span>

                                            <div
                                                v-if="
                                                    !(isTestCompleted && testObj.type == 'trat' && canClarify && testObj.clarificationStatus == 'released') &&
                                                    !sideBarClosedValue
                                                "
                                                class="tableStatusTag whiteSpaceNowrap menuText testSidebar-status"
                                                :class="{
                                                    danger:
                                                        getQuestionStatus(question_group_idx, question_idx) == -1 ||
                                                        getQuestionStatus(question_group_idx, question_idx) == 0,
                                                    answered: getQuestionStatus(question_group_idx, question_idx) == 1,
                                                }"
                                            >
                                                <p>
                                                    <template v-if="getQuestionStatus(question_group_idx, question_idx) == -1"> Wrong Answer </template>
                                                    <template v-if="getQuestionStatus(question_group_idx, question_idx) == 0"> Not Answered </template>
                                                    <template v-if="getQuestionStatus(question_group_idx, question_idx) == 1"> Answered </template>
                                                </p>
                                            </div>
                                        </a>

                                        <div v-if="isTestCompleted && testObj.type == 'trat' && canClarify" class="middleContent">
                                            <template v-if="!isClarificationAnswered(question_group_idx, question_idx) && !sideBarClosedValue">
                                                <p class="menuText">Clarification Added</p>
                                            </template>

                                            <template
                                                v-else-if="!['paused', 'stopped', 'released'].includes(testObj.clarificationStatus) && !sideBarClosedValue"
                                            >
                                                <a
                                                    class="link menuText"
                                                    :class="{
                                                        disabled: !canChangeQuestion(question_group_idx, question_idx),
                                                    }"
                                                    href="#"
                                                    aria-label="click here to add clarification"
                                                    @click="moveToDifferentQuestion(question_group_idx, question_idx, 'scrollToQns')"
                                                >
                                                    Need Clarification
                                                </a>
                                            </template>
                                        </div>

                                        <div
                                            v-if="isTestCompleted && testObj.type == 'trat' && canClarify && testObj.clarificationStatus == 'released'"
                                            class="rightContent"
                                        >
                                            <template v-if="isQuestionClarificationAssignedToMe(question_group_idx, question_idx) && !sideBarClosedValue">
                                                <p class="menuText">Clarification Assigned to us</p>
                                            </template>
                                            <template v-else-if="!sideBarClosedValue">
                                                <i
                                                    class="fa-solid fa-xmark greyText menuText"
                                                    aria-label="No Clarification Assigned to us"
                                                    aria-hidden="true"
                                                />
                                            </template>
                                        </div>
                                    </li>
                                    <span
                                        v-if="
                                            canDisplayQuestion(question) &&
                                            question_idx == questionGroup.length - 1 &&
                                            question_group_idx != testObj.questions.length - 1
                                        "
                                        class="divider"
                                    />
                                </template>
                            </template>
                        </ul>

                        <!-- <template
              v-for="(questionGroup, question_group_idx) in testObj.questions"
            >
              <div
                v-for="(question, question_idx) in questionGroup"
                class="mini-panel panel hidden-md hidden-lg"
                :key="
                  'modal-' +
                    testObj.type +
                    '-' +
                    getQuestionNo(question_group_idx, question_idx)
                "
              >
                <div
                  class="panel-heading flexSpaceBetween align-items"
                  tabindex="0"
                >
                  <div>
                    QUESTION
                    {{ getQuestionNo(question_group_idx, question_idx) }}
                  </div>
                  <div>
                    <div
                      v-if="
                        getQuestionStatus(question_group_idx, question_idx) ==
                          -1
                      "
                      class="subHeader"
                    >
                      <i class="fas fa-circle redGradientCircle"></i>&nbsp;
                      <span class="greyText question capitalize">
                        Wrong Answer
                      </span>
                    </div>
                    <div
                      v-if="
                        getQuestionStatus(question_group_idx, question_idx) == 0
                      "
                      class="subHeader"
                    >
                      <i class="fas fa-circle redGradientCircle"></i>&nbsp;
                      <span class="greyText question capitalize">
                        Not Completed
                      </span>
                    </div>
                    <div
                      v-if="
                        getQuestionStatus(question_group_idx, question_idx) == 1
                      "
                      class="subHeader"
                    >
                      <i class="fas fa-circle dateCircle "></i>&nbsp;<span
                        class="question capitalize"
                      >
                        Completed
                      </span>
                    </div>
                  </div>
                </div>

                <div class="panel-body">
                  <div
                    v-if="
                      isTestCompleted && testObj.type == 'trat' && canClarify
                    "
                  >
                    <div class="control-label">CLARIFICATION</div>
                    <template
                      v-if="
                        !isClarificationAnswered(
                          question_group_idx,
                          question_idx
                        )
                      "
                    >
                      <i class="fa fa-check greyText"></i>
                    </template>
                    <template
                      v-else-if="
                        !['paused', 'stopped', 'released'].includes(
                          testObj.clarificationStatus
                        )
                      "
                    >
                      <a
                        class="link"
                        @click="
                          moveToDifferentQuestion(
                            question_group_idx,
                            question_idx
                          )
                        "
                      >
                        Need Clarification
                      </a>
                    </template>
                    <template v-else>
                      -
                    </template>
                  </div>
                  <div
                    v-if="
                      isTestCompleted && testObj.type == 'trat' && canClarify
                    "
                    class="marginTop20"
                  >
                    <div class="control-label">ASSIGNED</div>
                    <template v-if="testObj.clarificationStatus != 'released'">
                      -
                    </template>
                    <template v-else>
                      <i
                        v-if="
                          isQuestionClarificationAssignedToMe(
                            question_group_idx,
                            question_idx
                          )
                        "
                        class="fa fa-check greyText"
                      ></i>
                      <i v-else class="fa-solid fa-xmark greyText"></i>
                    </template>
                  </div>
                </div>
              </div>
            </template>

            <div class="hidden-md hidden-lg">
              <router-link
                :to="{
                  name: 'tests.clarification',
                  params: { id: testObj.uuid },
                }"
                tag="button"
                v-if="canClarify"
                class="col-xs-12 btn btn-primary mainBtn"
                aria-label="View All Clarifications"
              >
                View All Clarifications
              </router-link>
              <router-link
                :to="{ name: 'tests.discussion', params: { id: testObj.uuid } }"
                tag="button"
                v-if="canDiscuss"
                class="col-xs-12 btn btn-primary mainBtn"
                aria-label="View Discussion"
              >
                View Discussion
              </router-link>
            </div>

            <table class="hidden-xs hidden-sm table test-sideBar">
              <thead>
                <tr>
                  <th style="width:44px;">NO.</th>
                  <th>STATUS</th>
                  <th
                    v-if="
                      isTestCompleted && testObj.type == 'trat' && canClarify
                    "
                  >
                    CLARIFICATION
                  </th>
                  <th
                    v-if="
                      isTestCompleted && testObj.type == 'trat' && canClarify
                    "
                  >
                    ASSIGNED
                  </th>
                </tr>
              </thead>
              <tbody>
                <template
                  v-for="(questionGroup,
                  question_group_idx) in testObj.questions"
                >
                  <template v-for="(question, question_idx) in questionGroup">
                    <tr>
                      <td>
                        <button
                          class="btn gradient btn-default test-status-question-btn"
                          :disabled="
                            !canChangeQuestion(question_group_idx, question_idx)
                          "
                          @click="
                            moveToDifferentQuestion(
                              question_group_idx,
                              question_idx
                            )
                          "
                          data-dismiss="modal"
                          :aria-label="
                            canChangeQuestion(question_group_idx, question_idx)
                              ? 'Question ' +
                                getQuestionNo(question_group_idx, question_idx)
                              : 'Question ' +
                                getQuestionNo(
                                  question_group_idx,
                                  question_idx
                                ) +
                                ' Disabled'
                          "
                        >
                          {{
                            getQuestionNo(question_group_idx, question_idx)
                          }}.&nbsp;<i class="fas fa-info-circle"></i>
                        </button>
                      </td>
                      <td>
                        <div
                          v-if="
                            getQuestionStatus(
                              question_group_idx,
                              question_idx
                            ) == -1
                          "
                        >
                          <i class="fas fa-circle redGradientCircle"></i>&nbsp;
                          <span class="greyText">Wrong Answer</span>
                        </div>
                        <div
                          v-if="
                            getQuestionStatus(
                              question_group_idx,
                              question_idx
                            ) == 0
                          "
                        >
                          <i class="fas fa-circle redGradientCircle"></i>&nbsp;
                          <span class="greyText">Not Completed</span>
                        </div>
                        <div
                          v-if="
                            getQuestionStatus(
                              question_group_idx,
                              question_idx
                            ) == 1
                          "
                        >
                          <i class="fas fa-circle dateCircle"></i>&nbsp;<span
                            >Completed</span
                          >
                        </div>
                      </td>
                      <td
                        v-if="
                          isTestCompleted &&
                            testObj.type == 'trat' &&
                            canClarify
                        "
                        class="textAlignCenter"
                      >
                        <template
                          v-if="
                            !isClarificationAnswered(
                              question_group_idx,
                              question_idx
                            )
                          "
                        >
                          <i class="fa fa-check greyText"></i>
                        </template>
                        <template
                          v-else-if="
                            !['paused', 'stopped', 'released'].includes(
                              testObj.clarificationStatus
                            )
                          "
                        >
                          <a
                            class="link"
                            @click="
                              moveToDifferentQuestion(
                                question_group_idx,
                                question_idx
                              )
                            "
                            aria-label="Need Clarification"
                            >Need Clarification</a
                          >
                        </template>
                      </td>
                      <td
                        v-if="
                          isTestCompleted &&
                            testObj.type == 'trat' &&
                            canClarify
                        "
                        class="textAlignCenter"
                      >
                        <template
                          v-if="testObj.clarificationStatus != 'released'"
                        >
                        </template>
                        <template v-else>
                          <i
                            v-if="
                              isQuestionClarificationAssignedToMe(
                                question_group_idx,
                                question_idx
                              )
                            "
                            class="fa fa-check greyText"
                          ></i>
                          <i v-else class="fa-solid fa-xmark greyText"></i>
                        </template>
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { testMixins } from './../../../../../mixins/test';
export default {
    mixins: [testMixins],
    props: ['sideBarClosedValue'],
    data() {
        return {
            sort: 'all',
            getNumbers: 0,
        };
    },

    computed: {
        canClarify() {
            if (this.testObj.type == 'trat' && this.testObj.settings.allowTeamClarifications) {
                return true;
            }
            return false;
        },
        canDiscuss() {
            if (this.testObj.type == 'application' && this.isTestCompleted) {
                return true;
            }
            return false;
        },
    },
    created() {
        this.getNumberOfQns();
    },
    mounted() {
        Events.listen('open-test-sidebar-modal', function () {
            $('#testSidebarModal').modal('show');
        });

        this.getNumberOfQns();
    },
    beforeUnmount() {
        Events.off('open-test-sidebar-modal');
    },
    methods: {
        canDisplayQuestion(question) {
            let status = this.getQuestionStatus(question.group - 1, question.order - 1);
            if ((status == 0 && this.sort == 'answered') || (status != 0 && this.sort == 'not answered') || (status != -1 && this.sort == 'wrong answer')) {
                return false;
            }
            return true;
        },
        isQuestionClarificationAssignedToMe(question_group_idx, question_idx) {
            let isAssigned = _.get(this.testObj.questions, question_group_idx + '.' + question_idx + '.isAssignedToMe', '');
            if (isAssigned) {
                return true;
            }
            return false;
        },
        isClarificationAnswered(question_group_idx, question_idx) {
            let clarification = _.get(this.testObj.questions, question_group_idx + '.' + question_idx + '.studentAnswer.clarifications', '');
            if (!clarification) {
                return true;
            }
            return false;
        },
        getNumberOfQns() {
            var that = this;

            Vue.nextTick(function () {
                that.getNumbers = $('.statusBar li:visible').length;
            });
        },
        handleButtonClick() {
            this.$emit('button-clicked');
        },
    },
};
</script>
<style scoped lang="scss">
.test-status-question-btn[disabled] {
    color: #222;
    opacity: 0.3;
}
.test-sideBar .fas.fa-circle,
.statusBar .fas.fa-circle {
    font-size: 6px;
}

body.test .test-sideBar .panel-body {
    padding: 0;
}
@media (min-width: 992px) {
    .table.test-sideBar {
        border: none;
    }
}
.subHeader {
    display: flex;
    align-items: center;
    line-height: 0.7;
}
.panel-body.test-sideBar-panel-body {
    overflow-x: auto;
}
.test-sideBar thead tr:only-child th {
    font-size: 13px;
}

ul {
    padding: 0;
    margin: 0;

    li {
        list-style: none;

        .fa,
        .fab,
        .fal,
        .far,
        .fas {
            vertical-align: text-bottom;
        }
    }
}
.test-sideBar {
    li {
        padding: 14px 10px;
        // border-radius: 8px;

        a {
            color: #3c3c43;

            &.link {
                color: #1941a6;
            }
        }

        &:hover {
            // background: rgba(245, 158, 11, 0.6);
            // border: 1px solid rgba(245, 158, 11, 0.6);
        }

        &:active {
            // background: #C97420;
            // border: 1px solid #C97420;
        }

        &.active {
            background: #ffefe0;
            // border: 1px solid #C97420;
        }

        &:focus-within {
            outline: 4px solid #1941a6;
        }
    }

    .panel-body {
        white-space: inherit;
    }

    .isClarification {
        display: grid;
        grid-template-columns: auto 0.75fr;
        text-align: center;
        align-items: center;

        .tableStatusTag p {
            font-size: 14px;
        }
    }

    .clarificationReleased {
        display: grid;
        grid-template-columns: auto 0.75fr 0.5fr;
        text-align: center;
        align-items: center;

        a:last-child,
        p {
            font-size: 14px;
        }
    }
}
.statusBar {
    -moz-transition: width 0.5s ease-out;
    -o-transition: width 0.5s ease-out;
    -webkit-transition: width 0.5s ease-out;
    transition: width 0.5s ease-out;
    display: inline-block;
    z-index: 0;
    position: fixed;
    height: calc(100% - 10px);
    z-index: 100;

    ul {
        display: grid;
        // grid-gap: 10px;
    }

    li {
        display: grid;
        align-items: center;
        grid-column-gap: 10px;
        padding: 16px 8px;
        border-left: 4px solid transparent;

        &:not(:first-child) {
            padding-top: 16px;
        }

        a:first-child {
            color: #3c3c43;
        }

        &:hover {
            background: #e7dcf9;
        }

        &:focus-visible {
            background: #e7dcf9;
            outline: 5px solid #1941a6;
            outline-offset: 3px;
        }

        &.haveAnswered {
            background: #2d8659;
            a:first-child {
                color: #fff;
            }
        }

        &.active {
            background: #fff;
            border-left: 5px solid #0071eb;

            &:hover {
                background: none;
            }

            a:first-child {
                color: #3c3c43;
            }
        }

        &.disabled {
            // &.active {
            //   border-left-color: #989b9c;
            //   background: #f6f8fa;
            // }

            a.disabled {
                color: #8b8b8f;
            }

            &.haveAnswered {
                background: #efefef;
            }
        }
    }

    .dropdown-menu-new {
        li {
            display: list-item;
            background: none;
            border: none;
            padding: 0;

            a {
                background: none;
                border-left: 4px solid transparent;
                padding: 16px 8px;

                &:hover {
                    background: #e7dcf9;
                    border-left: 4px solid #ab7cf8;
                }

                &.active {
                    background: #fff;
                    border-left: 5px solid #0071eb;

                    &:hover {
                        background: none;
                    }

                    a:first-child {
                        color: #3c3c43;
                    }
                }
            }

            ul {
                padding: 0 10px 0 20px;

                li {
                    display: flex;
                    grid-column-gap: 5px;

                    a,
                    i {
                        color: #3c3c43;
                    }
                }
            }
        }
    }

    .divider {
        margin-left: 0;
        margin-right: 0;
    }
    &.needClarification {
        width: 380px;

        li {
            grid-template-columns: 6fr 5fr;
        }
    }

    &.isReleased {
        li {
            grid-template-columns: 1.5fr 2fr 2fr;
            grid-template-areas: 'leftContent' 'middleContent' 'rightContent';
        }
    }

    .menuText {
        opacity: 1;
        -moz-transition: opacity 0.6s 0.2s ease-out;
        -o-transition: opacity 0.6s 0.2s ease-out;
        -webkit-transition: opacity 0.6s 0.2s ease-out;
        transition: opacity 0.6s 0.2s ease-out;
    }

    #toggleIcon {
        -moz-transition: transform 0.4s ease-out;
        -o-transition: transform 0.4s ease-out;
        -webkit-transition: transform 0.4s ease-out;
        transition: transform 0.4s ease-out;
    }

    .rotate {
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        -moz-transition: transform 0.4s ease-out;
        -o-transition: transform 0.4s ease-out;
        -webkit-transition: transform 0.4s ease-out;
        transition: transform 0.4s ease-out;
    }

    &.sidebar--Collapse {
        width: auto;
        max-width: 186px;
        -moz-transition: width 0.6s ease-out;
        -o-transition: width 0.6s ease-out;
        -webkit-transition: width 0.6s ease-out;
        transition: width 0.6s ease-out;

        .menuText {
            opacity: 0;
            -moz-transition: opacity 0.3s ease-out;
            -o-transition: opacity 0.3s ease-out;
            -webkit-transition: opacity 0.3s ease-out;
            transition: opacity 0.3s ease-out;
            position: absolute;
        }
    }

    .panel {
        border: none;
        margin: 0;

        .panel-heading,
        .panel-body {
            padding: 16px 8px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        .panel-body {
            background: #f7faff;
            height: calc(100% - 192px);

            &.panel-body-closed {
                // padding: 16px 0;

                p {
                    padding-left: 8px;
                    padding-right: 8px;
                }

                li {
                    grid-template-columns: auto;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    .modal-dialog {
        width: 90%;
        max-height: 50%;
        overflow: auto;
    }
}

@media (min-width: 992px) {
    .statusBar {
        width: 240px;
    }
}
</style>
