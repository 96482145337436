<template>
    <div v-if="component_done_loading" :id="question.uuid + '-question'" class="panel" tabindex="-1">
        <!-- v-shortkey.propagte="{
      answer: ['shift', 'a'],
      up: ['arrowup'],
      down: ['arrowdown'],
      enter: ['enter'],
    }"
    @shortkey="questionAccessibility" -->
        <kr-calculator
            v-if="question.type == `openended`"
            :id="question.uuid + '-question-calculator'"
            ref="calculator"
            :can-answer="canAnswer"
            @answer="appendTempAnswer"
        />
        <div class="panel-heading flexSpaceBetween align-items">
            <div>
                <span>
                    Question
                    {{ getQuestionNo(question_group_idx, question_idx) }}
                </span>

                <button
                    v-if="testObj.type == 'trat' && !['not started', 'scheduled'].includes(testObj.status)"
                    v-tooltip="{
                        content: 'Remind me to seek clarification for this question later',
                    }"
                    :disabled="!isTeamLeader || question.studentAnswer.attempts.length == 0 ? true : undefined"
                    class="btn btn-default"
                    :class="{ bookmarked: question.studentAnswer.isBookmarked }"
                    aria-label="Remind me to seek clarification for this question later"
                    @click="toggleBookmark"
                >
                    <i class="fa fa-bookmark" aria-hidden="true" />
                </button>

                <button v-if="question.type == `openended`" class="btn" aria-label="Open Calculator" @click="$refs.calculator.open()">
                    <i class="fa fa-calculator" aria-hidden="true" />
                </button>
            </div>
            <div v-if="['mcqs'].includes(question.type) && testObj.settings.confidenceBasedTesting && (!isPreview || isTeacherPreview)" class="pntRemainining">
                Points Remaining: {{ pointSpreadingPointsLeft }}
            </div>
            <!-- <div v-if="question.attachments&&question.attachments.length!=0">
                <button class="btn btn-default mainBtn" @click="openAttachmentsModal">
                    <i class="fa fa-paperclip"></i>&nbsp;Question Attachment(s)
                </button>
            </div> -->
        </div>
        <div :id="question.uuid + '-panel-body'" class="panel-body eGallery-panel">
            <template v-if="question.type == 'mcqs' || question.type == 'mcqm'">
                <mcq
                    :test-obj="testObj"
                    :question="question"
                    :value="tempAnswers[question_group_idx][question_idx]"
                    :disabled="!canAnswer ? true : undefined"
                    :is-test-completed="isTestCompleted"
                    @changed="setTempAnswer"
                />
            </template>
            <template v-else-if="['vsa', 'openended'].includes(question.type)">
                <openended
                    :test-obj="testObj"
                    :question="question"
                    :value="tempAnswers[question_group_idx][question_idx]"
                    :disabled="!canAnswer ? true : undefined"
                    :typing="typing.currentQuestionUuid == question.uuid ? typing : false"
                    :is-test-completed="isTestCompleted"
                    @changed="setTempAnswer"
                />
            </template>
            <div v-if="canAttach" class="row marginTop20">
                <template v-if="!isTeacherPreview">
                    <button
                        class="btn btn-primary"
                        :disabled="!canEditComment && !canEditClarification ? true : undefined"
                        aria-label="Add Attachments"
                        @click.prevent="addAttachments()"
                    >
                        Add Attachments
                    </button>
                </template>
                <template v-else>
                    <button
                        v-tooltip="{
                            content: 'This would open on a real test',
                        }"
                        class="btn btn-primary"
                        aria-label="Add Attachments"
                    >
                        Add Attachments
                    </button>
                </template>

                <div v-if="question.studentAnswer._attachments && question.studentAnswer._attachments.length != 0" class="marginTop20 col-xs-12 col-md-6">
                    <div class="flex align-items marginTop10">
                        <div class="col-xs-12">
                            <!-- <span style="font-weight:bold;">Uploaded Files</span> -->
                            <div v-for="(file, idx) in question.studentAnswer._attachments" :key="idx" class="flexSpaceBetween">
                                <div>
                                    <span class="marginRight5">{{ idx + 1 }}.&nbsp;</span>
                                    <span> <i class="fa fa-file-image-o" aria-hidden="true" />&nbsp; </span>
                                    <a :href="file.url" target="_blank" download :aria-label="file.filename">
                                        {{ file.filename }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="canComment && isElaborationRequired" class="marginTop20">
                <label class="control-label" :for="question.uuid + '-elaborate'"> Elaboration<span aria-label="required" class="important">*</span> </label>
                <div class="row">
                    <textarea
                        :id="question.uuid + '-elaborate'"
                        v-model="question.studentAnswer._comments"
                        rows="5"
                        cols="3"
                        class="form-control"
                        placeholder="Type comments here…"
                        :disabled="!canEditComment ? true : undefined"
                    />
                </div>
                <div class="row marginTop10">
                    <button class="btn btn-success" :disabled="!canSubmitComments ? true : undefined" @click.prevent="submitComments()">
                        Save Elaboration
                    </button>
                </div>
            </div>
        </div>
        <div v-if="!isPreview || isTeacherPreview">
            <!-- <template v-if="question.type == 'mcqs' || question.type == 'mcqm'">
        <div class="unreadChoices" v-if="!reachedTheBottom">
          <div>
            <i class="fas fa-long-arrow-alt-down marginRight10 "></i>More Unread
            Choices<i class="fas fa-long-arrow-alt-down marginLeft10 "></i>
          </div>
        </div>
      </template> -->
            <div class="panel-footer no-print">
                <div class="row flex flexRight">
                    <div class="buttonGroup">
                        <div class="hidden-xs">
                            <div>
                                <div
                                    v-if="(testObj.type == 'irat' && testObj.settings.immediateFeedback) || testObj.type == 'trat'"
                                    :id="question.type + '-' + getQuestionNo(question_group_idx, question_idx) + '-feedback-update'"
                                    class="align-items HintAndExplanations"
                                    aria-live="assertive"
                                    tabindex="-1"
                                >
                                    <div v-if="getQuestionStatus(question_group_idx, question_idx) == 1" class="darkGreen" style="display: block">
                                        <i class="fa fa-check-circle marginRight8" aria-hidden="true" />Correct answer!

                                        <template v-if="testObj.type == 'trat' || (testObj.type == 'irat' && testObj.settings.immediateFeedback)">
                                            <template v-if="testObj.settings.enableHintAndExplanation && question.hintOrExplanation">
                                                <template v-if="question.studentAnswer.isCorrect">
                                                    <i class="fa fa-trophy marginRight8" aria-hidden="true" />

                                                    <span class="visible-hidden"> The Explanation is </span>
                                                    <i>
                                                        <kr-math :key="question.uuid + '-hint-correct'" :input="question.hintOrExplanation">
                                                            <span slot-scope="props" class="inline" v-html="props.model.output" />
                                                        </kr-math>
                                                    </i>

                                                    <span class="visible-hidden"> Please proceed to next step </span>
                                                </template>
                                            </template>
                                        </template>
                                    </div>

                                    <div v-if="getQuestionStatus(question_group_idx, question_idx) == -1" class="important" style="display: block">
                                        <i class="fa fa-info-circle marginRight8" aria-hidden="true" />Wrong answer!

                                        <template v-if="testObj.type == 'trat' || (testObj.type == 'irat' && testObj.settings.immediateFeedback)">
                                            <template v-if="testObj.settings.enableHintAndExplanation && question.hintOrExplanation">
                                                <template v-if="!question.studentAnswer.isCorrect">
                                                    <i class="fa fa-commenting fa-flip-horizontal marginRight8" aria-hidden="true" />
                                                    <span class="visible-hidden">The Hint is</span>
                                                    <i>
                                                        <kr-math :key="question.uuid + '-hint-wrong'" :input="question.hintOrExplanation">
                                                            <span slot-scope="props" class="inline" v-html="props.model.output" />
                                                        </kr-math>
                                                    </i>
                                                </template>
                                            </template>
                                        </template>
                                    </div>
                                </div>
                            </div>

                            <!-- <div
                                v-if="
                                    (testObj.type == 'irat' &&
                                        testObj.settings.immediateFeedback) ||
                                    testObj.type == 'trat'
                                "
                                :id="
                                    question.type +
                                    '-' +
                                    getQuestionNo(
                                        question_group_idx,
                                        question_idx
                                    ) +
                                    '-feedback-update'
                                "
                                class="visible-hidden"
                                aria-live="assertive"
                            >
                                <template v-if="!isQuestionAnswerChanged">
                                    <template
                                        v-if="
                                            getQuestionStatus(
                                                question_group_idx,
                                                question_idx
                                            ) == 1
                                        "
                                    >
                                        Correct Answer!
                                        <template
                                            v-if="
                                                testObj.settings
                                                    .enableHintAndExplanation &&
                                                question.hintOrExplanation
                                            "
                                        >
                                            The Explanation is
                                            <kr-math
                                                :key="
                                                    question.uuid +
                                                    '-hint-correct'
                                                "
                                                :input="
                                                    question.hintOrExplanation
                                                "
                                            >
                                                <span
                                                    slot-scope="props"
                                                    class="inline"
                                                    v-html="props.model.output"
                                                />
                                            </kr-math>
                                        </template>

                                        Please proceed to next step
                                    </template>

                                    <template
                                        v-if="
                                            getQuestionStatus(
                                                question_group_idx,
                                                question_idx
                                            ) === -1
                                        "
                                    >
                                        Wrong answer! Please try again

                                        <template
                                            v-if="
                                                testObj.settings
                                                    .enableHintAndExplanation &&
                                                question.hintOrExplanation
                                            "
                                        >
                                            The Hint is
                                            <kr-math
                                                :key="
                                                    question.uuid +
                                                    '-hint-wrong'
                                                "
                                                :input="
                                                    question.hintOrExplanation
                                                "
                                            >
                                                <span
                                                    slot-scope="props"
                                                    class="inline"
                                                    v-html="props.model.output"
                                                />
                                            </kr-math>
                                        </template>
                                    </template>
                                </template>
                            </div> -->

                            <button
                                v-if="canAnswer"
                                class="btn btn-success floatRight"
                                :class="{
                                    isLoading: isLoading,
                                    isSaved: isSaved,
                                    isEnabled: canSubmitAnswer,
                                }"
                                :disabled="!canSubmitAnswer || isLoading || isSaved ? true : undefined"
                                @click="submitAnswer"
                            >
                                <template v-if="!(isSaved || isLoading)"> <i class="fa fa-floppy-o marginRight8" aria-hidden="true" />Save Answer </template>
                                <template v-else-if="isLoading">
                                    Saving Answer
                                    <i class="fa fa-spinner fa-spin marginLeft8" aria-hidden="true" />
                                </template>
                                <template v-else-if="isSaved">
                                    Saved
                                    <i class="fa fa-check marginLeft8" aria-hidden="true" />
                                </template>
                            </button>

                            <button
                                v-if="canChangeQuestion(next_question_group_idx, next_question_idx) && !isLastQuestion"
                                class="btn btn-outline-primary"
                                @click="moveToDifferentQuestion(next_question_group_idx, next_question_idx, 'scrollToQns')"
                            >
                                Next
                                <i class="fas fa-caret-right marginLeft8" aria-hidden="true"></i>
                            </button>
                            <div v-if="isSaved" class="visible-hidden" role="alert" aria-live="assertive" aria-atomic="true">Answer has been saved</div>

                            <button
                                v-if="canClarify"
                                class="btn btn-primary"
                                :aria-label="
                                    canEditClarification
                                        ? !question.studentAnswer._clarifications
                                            ? 'Add Clarification'
                                            : 'Edit Clarification'
                                        : 'View Clarification'
                                "
                                @click="openClarificationModal"
                            >
                                <template v-if="canEditClarification">
                                    <template v-if="!question.studentAnswer._clarifications">
                                        <i class="fas fa-plus marginRight8" aria-hidden="true" />Add Clarification
                                    </template>
                                    <template v-else> <i class="fas fa-edit marginRight8" aria-hidden="true" />Edit Clarification </template>
                                </template>
                                <template v-else> <i class="fas fa-eye marginRight8" aria-hidden="true" />View Clarification </template>
                            </button>

                            <button
                                v-if="canComment && !isElaborationRequired"
                                class="btn btn-success"
                                :aria-label="canEditComment ? (!question.studentAnswer._comments ? 'Add Elaboration' : 'Edit Elaboration') : 'View Elaboration'"
                                @click="openCommentModal"
                            >
                                <template v-if="canEditComment">
                                    <template v-if="!question.studentAnswer._comments">
                                        <i class="fas fa-plus marginRight8" aria-hidden="true" />Add Elaboration
                                    </template>
                                    <template v-else> <i class="fas fa-edit marginRight8" aria-hidden="true" />Edit Elaboration </template>
                                </template>
                                <template v-else> <i class="fas fa-eye marginRight8" aria-hidden="true" />View Elaboration </template>
                            </button>
                            <!--<button class="btn btn-success mainBtn" @click="openCommentModal" :disabled="(!question.studentAnswer._comments) ? true:undefined" v-else>
                               
                            </button>-->
                            <!--<button v-if="canChangeQuestion(previous_question_group_idx,previous_question_idx)" class="btn btn-default mainBtn" @click="moveToDifferentQuestion(previous_question_group_idx,previous_question_idx)">
                                <i class="fas fa-caret-left"></i>&nbsp;Previous Question
                            </button>
                             <button v-if="canChangeQuestion(next_question_group_idx,next_question_idx)"  class="btn btn-primary mainBtn"  @click="moveToDifferentQuestion(next_question_group_idx,next_question_idx)">
                                Next Question&nbsp;<i class="fas fa-caret-right"></i>
                            </button>-->
                            <!-- <button v-if="!isTestCompleted" class="btn btn-success mainBtn" :disabled="(isSubmitTestButtonDisabled) ? true:undefined" @click.prevent="submitTest">
                                <i class="fas fa-check"></i>&nbsp;Submit Test
                            </button> -->
                        </div>
                        <div class="hidden-sm hidden-md hidden-lg">
                            <!-- <button
                                v-if="canAnswer"
                                class="answer-submission btn btn-success"
                                :class="{
                                    isLoading: isLoading,
                                    isSaved: isSaved,
                                    isEnabled: canSubmitAnswer,
                                }"
                                :disabled="(!canSubmitAnswer) ? true:undefined"
                                aria-label="Save Answer"
                                @click="submitAnswer"
                            >
                                <i
                                    class="fa fa-floppy-o marginRight8"
                                    aria-hidden="true"
                                />Save Answer
                            </button> -->

                            <button
                                v-if="canAnswer"
                                class="btn btn-success"
                                :class="{
                                    isLoading: isLoading,
                                    isSaved: isSaved,
                                    isEnabled: canSubmitAnswer,
                                }"
                                :disabled="!canSubmitAnswer || isLoading || isSaved ? true : undefined"
                                @click="submitAnswer"
                            >
                                <template v-if="!(isSaved || isLoading)"> <i class="fa fa-floppy-o marginRight8" aria-hidden="true" />Save Answer </template>
                                <template v-else-if="isLoading">
                                    Saving Answer
                                    <i class="fa fa-spinner fa-spin marginLeft8" aria-hidden="true" />
                                </template>
                                <template v-else-if="isSaved">
                                    Saved
                                    <i class="fa fa-check marginLeft8" aria-hidden="true" />
                                </template>
                            </button>
                            <button
                                v-if="canChangeQuestion(next_question_group_idx, next_question_idx) && !isLastQuestion"
                                class="btn btn-outline-primary"
                                @click="moveToDifferentQuestion(next_question_group_idx, next_question_idx, 'scrollToQns')"
                            >
                                Next
                                <i class="fas fa-caret-right marginLeft8" aria-hidden="true"></i>
                            </button>
                            <div v-if="isSaved" class="visible-hidden" role="alert" aria-live="assertive" aria-atomic="true">Answer has been saved</div>
                            <button v-if="canClarify" class="btn btn-primary" aria-label="Add/Edit/View Clarification" @click="openClarificationModal">
                                <template v-if="canEditClarification">
                                    <template v-if="!question.studentAnswer._clarifications">
                                        <i class="fas fa-plus marginRight8" aria-hidden="true" />Clarification
                                    </template>
                                    <template v-else> <i class="fas fa-edit marginRight8" aria-hidden="true" />Clarification </template>
                                </template>
                                <template v-else> <i class="fas fa-eye marginRight8" aria-hidden="true" />Clarification </template>
                            </button>
                            <button
                                v-if="canComment && !isElaborationRequired"
                                class="btn btn-success"
                                :aria-label="canEditComment ? (!question.studentAnswer._comments ? 'Add Elaboration' : 'Edit Elaboration') : 'View Elaboration'"
                                @click="openCommentModal"
                            >
                                <template v-if="canEditComment">
                                    <template v-if="!question.studentAnswer._comments">
                                        <i class="fas fa-plus marginRight8" aria-hidden="true" />Add Elaboration
                                    </template>
                                    <template v-else> <i class="fas fa-edit marginRight8" aria-hidden="true" />Edit Elaboration </template>
                                </template>
                                <template v-else> <i class="fas fa-eye marginRight8" aria-hidden="true" />View Elaboration </template>
                            </button>
                            <!--<button class="btn btn-success mainBtn" @click="openCommentModal" :disabled="(!question.studentAnswer._comments) ? true:undefined" v-else>
                                
                            </button>-->
                            <!--<button v-if="canChangeQuestion(previous_question_group_idx,previous_question_idx)" class="btn btn-default mainBtn" @click="moveToDifferentQuestion(previous_question_group_idx,previous_question_idx)">
                                <i class="fas fa-caret-left"></i>
                            </button>
                             <button v-if="canChangeQuestion(next_question_group_idx,next_question_idx)"  class="btn btn-primary mainBtn"  @click="moveToDifferentQuestion(next_question_group_idx,next_question_idx)">
                                <i class="fas fa-caret-right"></i>
                            </button> -->
                            <!--<button v-if="!isTestCompleted" class="btn btn-success mainBtn" :disabled="(isSubmitTestButtonDisabled) ? true:undefined" @click.prevent="submitTest">
                                <i class="fas fa-check"></i>
                            </button>-->
                        </div>
                    </div>
                </div>

                <div
                    v-if="
                        !testObj._isPreview &&
                        canAllowChangeAnswers &&
                        isQuestionAnswerChanged &&
                        canSubmitAnswer &&
                        question.studentAnswer.attempts != 0 &&
                        testObj.type != 'trat'
                    "
                    class="alert alert-danger marginTop20"
                    role="alert"
                >
                    <i class="fa fa-info-circle marginTop2" />Changed your answer? Don't forget to save it!
                </div>

                <div class="hidden-sm hidden-md hidden-lg">
                    <div v-if="(testObj.type == 'irat' && testObj.settings.immediateFeedback) || testObj.type == 'trat'" class="marginTop10">
                        <div v-if="getQuestionStatus(question_group_idx, question_idx) == 1" class="darkGreen">
                            <i class="fa fa-check-circle marginRight8" aria-hidden="true" />Correct answer!

                            <template v-if="testObj.type == 'trat' || (testObj.type == 'irat' && testObj.settings.immediateFeedback)">
                                <template v-if="testObj.settings.enableHintAndExplanation && question.hintOrExplanation">
                                    <template v-if="question.studentAnswer.isCorrect">
                                        <i class="fa fa-trophy marginRight8" aria-hidden="true" />
                                        <span class="visible-hidden">The Explanation is</span>
                                        <kr-math :key="question.uuid + '-hint-correct'" :input="question.hintOrExplanation">
                                            <span slot-scope="props" class="inline" v-html="props.model.output" />
                                        </kr-math>
                                    </template>
                                </template>
                            </template>
                        </div>
                        <div v-if="getQuestionStatus(question_group_idx, question_idx) == -1" class="important">
                            <i class="fa fa-info-circle marginRight8" aria-hidden="true" />Wrong answer!

                            <template v-if="testObj.type == 'trat' || (testObj.type == 'irat' && testObj.settings.immediateFeedback)">
                                <template v-if="testObj.settings.enableHintAndExplanation && question.hintOrExplanation">
                                    <template v-if="!question.studentAnswer.isCorrect">
                                        <i class="fa fa-commenting fa-flip-horizontal marginRight8" aria-hidden="true" />
                                        <span class="visible-hidden">The Hint is</span>
                                        <kr-math :key="question.uuid + '-hint-wrong'" :input="question.hintOrExplanation">
                                            <span slot-scope="props" class="inline" v-html="props.model.output" />
                                        </kr-math>
                                    </template>
                                </template>
                            </template>
                        </div>
                    </div>
                </div>
                <!-- <template
          v-if="
            testObj.type == 'trat' ||
              (testObj.type == 'irat' && testObj.settings.immediateFeedback)
          "
        >
          <template
            v-if="
              testObj.settings.enableHintAndExplanation &&
                question.hintOrExplanation
            "
          >
            <template v-if="question.studentAnswer.isCorrect">
              <div class="successColor">
                <i class="fa fa-check-circle"></i>&nbsp;Explanation!
                {{ question.hintOrExplanation }}
              </div>
            </template>
            <template v-else>
              <div class="errorColor">
                <i class="fa fa-info-circle"></i>&nbsp;Hint:
                {{ question.hintOrExplanation }}
              </div>
            </template>
          </template>
        </template> -->
            </div>
        </div>
        <div
            :id="question.uuid + '-clarification-modal'"
            class="modal"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'clarificationModal-' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'clarificationModal-' + question.uuid + '-title'" class="modal-title">Clarify This Question</h2>
                    </div>
                    <div class="modal-body">
                        <p>We would like to clarify …</p>
                        <div class="row">
                            <textarea
                                v-model="question.studentAnswer._clarifications"
                                rows="5"
                                cols="3"
                                class="form-control"
                                placeholder="Type clarification here…"
                                :disabled="!canEditClarification ? true : undefined"
                                aria-label="We would like to clarify"
                            />
                        </div>
                        <div class="row" style="margin-top: 10px">
                            <div class="buttonGroup">
                                <button
                                    class="btn btn-primary"
                                    :disabled="!canSubmitClarifications ? true : undefined"
                                    aria-label="Save Clarification"
                                    @click.prevent="submitClarifications()"
                                >
                                    Save Clarification
                                </button>
                                <button class="btn btn-default" data-dismiss="modal" aria-label="Back">
                                    <i class="fa fa-caret-left marginRight8" aria-hidden="true" />Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div :id="question.uuid + '-comment-modal'" class="modal" tabindex="-1" role="dialog" :aria-labelledby="'commentModal-' + question.uuid + '-title'">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'commentModal-' + question.uuid + '-title'" class="modal-title">Elaborate</h2>
                    </div>
                    <div class="modal-body">
                        <p>We would like to elaborate...</p>
                        <div class="row">
                            <textarea
                                v-model="question.studentAnswer._comments"
                                rows="5"
                                cols="3"
                                class="form-control"
                                placeholder="Type comments here…"
                                :disabled="!canEditComment ? true : undefined"
                                aria-label="We would like to elaborate"
                            />
                        </div>
                        <div class="row" style="margin-top: 10px">
                            <div class="buttonGroup">
                                <button
                                    class="btn btn-success"
                                    :disabled="!canSubmitComments ? true : undefined"
                                    aria-label="Save Elaboration"
                                    @click.prevent="submitComments()"
                                >
                                    Save Elaboration
                                </button>
                                <button class="btn btn-default" data-dismiss="modal" aria-label="Back">
                                    <i class="fa fa-caret-left marginRight8" aria-hidden="true" />Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            :id="question.uuid + '-attachments-modal'"
            class="modal"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'attachmentsModal-' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'attachmentsModal-' + question.uuid + '-title'" class="modal-title">Teacher’s Attachment(s)</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12 col-md-6">
                            <div v-if="question.attachments && question.attachments.length != 0" class="flex align-items">
                                <div class="col-xs-12">
                                    <!-- <span style="font-weight:bold;">Uploaded Files</span> -->
                                    <div v-for="(file, idx) in question.attachments" :key="idx" class="flexSpaceBetween attachedFiles">
                                        <div>
                                            <span>{{ idx + 1 }}.</span>
                                            <span>
                                                <i class="fa fa-file-image-o" aria-hidden="true" />
                                            </span>
                                            <template v-if="isPdf(file)">
                                                <template v-if="testObj.settings.allowPdfDownload">
                                                    <a :href="file.url" target="_blank" download :aria-label="file.filename">{{ file.filename }}</a>
                                                </template>
                                                <template v-else>
                                                    <a :aria-label="file.filename" @click.prevent="openViewPdfModal(file)">{{ file.filename }}</a>
                                                </template>
                                            </template>
                                            <template v-else>
                                                <a :href="file.url" target="_blank" download :aria-label="file.filename">{{ file.filename }}</a>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <template v-else>
                                <div>-</div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            :id="question.uuid + '-enlarge-image-modal'"
            class="modal modal-manual"
            data-backdrop="static"
            tabindex="-1"
            role="dialog"
            :aria-labelledby="'enlargeImageModal-' + question.uuid + '-title'"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true" />
                        </button>

                        <h2 :id="'enlargeImageModal-' + question.uuid + '-title'" class="modal-title">
                            <i class="fa fa-arrows marginRight8" aria-hidden="true" />Enlarged Image
                        </h2>
                    </div>
                    <div class="modal-body padding0">
                        <img :key="srcValue" :src="srcValue" width="100%" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { testMixins } from './../../../../../../../mixins/test';
import KrAuth from '../../../../../../../components/auth/auth';
import * as filestack from 'filestack-js';
export default {
    mixins: [testMixins],
    props: ['testObj', 'question', 'question_group_idx', 'question_idx', 'tempAnswers', 'typing', 'sharedObj'],
    components: {
        mcq: require(`./partials/mcq.vue`).default,
        openended: require(`./partials/openended.vue`).default,
    },
    data() {
        var that = this;
        var auth = new KrAuth();
        var debounceLiveTyping = _.debounce(
            function (tempAnswer) {
                window.Echo.join(`test.${that.testObj.testUuid}.team.${that.testObj.teamUuid}`).whisper('typing', {
                    user: {
                        uuid: auth.user().uuid,
                        displayName: auth.user().displayName,
                    },
                    currentQuestionUuid: that.question.uuid,
                    answer: tempAnswer,
                });
            },
            1000,
            { maxWait: 2000 }
        );
        return {
            auth: auth,
            component_done_loading: false,
            clarifications: null,
            comments: null,
            attachments: [],
            client: filestack.init(process.env.VUE_APP_FILESTACK_API_KEY), //filestack client
            accessibility: {
                currOption: false,
                testSubmissionBtnOption: false,
            },
            reachedTheBottom: false,
            scrollFunction: null,
            debounceLiveTyping: debounceLiveTyping,
            attachment: null,
            isLoading: false,
            isSaved: false,
            srcValue: '',
        };
    },
    computed: {
        isQuestionAnswerChanged() {
            let attempts = _.get(this.question, 'studentAnswer.attempts', []);
            let storedAnswer = -1;
            if (attempts.length != 0) {
                storedAnswer = attempts[attempts.length - 1];
                if (this.testObj.settings.randomizeAnswerOptionOrder) {
                    storedAnswer = _.find(this.question.options, function (o) {
                        return o.originalKey == storedAnswer;
                    });
                    if (storedAnswer) {
                        storedAnswer = storedAnswer.key;
                    }
                }
            }
            if (this.tempAnswers[this.question_group_idx][this.question_idx] == '') {
                return false;
            }

            return !_.isEqual(this.tempAnswers[this.question_group_idx][this.question_idx], storedAnswer);
        },
        /*isSubmitTestButtonDisabled(){
            return this.total_questions!=this.answered_count||(this.testObj.type=='trat'&&!this.isTeamLeader)||(this.testObj.type=='application'&&this.testObj.others.applicationType=='team'&&!this.isTeamLeader);
        },*/
        canAllowChangeAnswers() {
            return _.get(this.testObj, 'settings.allowChangeAnswersBeforeFinalSubmission', true);
        },
        canComment() {
            if (this.testObj.type == 'application' && !this.isTestCompleted) {
                return true;
            }
            return false;
        },
        canEditComment() {
            if (!this.canComment) {
                return false;
            }
            if ((this.testObj.others.applicationType == 'team' && this.isTeamLeader) || this.testObj.others.applicationType == 'individual') {
                return true;
            }
            return false;
        },
        canSubmitComments() {
            let comments = _.get(this.question, 'studentAnswer.comments', '');
            let attachments = _.get(this.question, 'studentAnswer.attachments', []);
            if (!attachments) {
                attachments = [];
            }
            if (this.question.studentAnswer._comments == comments && _.isEqual(attachments, this.question.studentAnswer._attachments)) {
                return false;
            }
            if (!this.canComment || !this.canEditComment) {
                return false;
            }
            if (this.isElaborationRequired && !_.get(this.question, 'studentAnswer._comments', false)) {
                return false;
            }
            return true;
        },
        canClarify() {
            if (this.testObj.type == 'trat' && this.testObj.settings.allowTeamClarifications) {
                return true;
            }
            return false;
        },
        canEditClarification() {
            if (!['paused', 'stopped', 'released'].includes(this.testObj.clarificationStatus) && this.isTeamLeader) {
                return true;
            }
            return false;
        },
        canSubmitClarifications() {
            let clarifications = _.get(this.question, 'studentAnswer.clarifications', '');
            let attachments = _.get(this.question, 'studentAnswer.attachments', []);
            if (!attachments) {
                attachments = [];
            }
            if (this.question.studentAnswer._clarifications == clarifications && _.isEqual(attachments, this.question.studentAnswer._attachments)) {
                return false;
            }
            if (!this.canClarify || !this.canEditClarification) {
                return false;
            }
            return true;
        },
        canAnswer() {
            if (this.isTestCompleted || this.sharedObj.isFormBusy) {
                return false;
            }
            let attempts = _.get(this.question, 'studentAnswer.attempts', []);
            let storedAnswer = -1;
            if (attempts.length != 0) {
                storedAnswer = attempts[attempts.length - 1];
            }
            if (this.testObj.type == 'irat' && !_.get(this.testObj, 'settings.immediateFeedback')) {
                if (!this.canAllowChangeAnswers && storedAnswer != -1) {
                    return false;
                }
            }
            if (this.testObj.type == 'trat' || (this.testObj.type == 'irat' && _.get(this.testObj, 'settings.immediateFeedback'))) {
                let isCorrect = _.get(this.question, 'studentAnswer.isCorrect', false);
                if (isCorrect) {
                    return false;
                }
                if (!this.isTeamLeader && this.testObj.type == 'trat') {
                    return false;
                }
            }
            if (this.testObj.type == 'application') {
                if (!this.canAllowChangeAnswers && storedAnswer != -1) {
                    return false;
                }
                if (this.testObj.others.applicationType == 'team') {
                    if (!this.isTeamLeader) {
                        return false;
                    }
                }
            }
            return true;
        },
        canSubmitAnswer() {
            if (this.isElaborationRequired && this.question.studentAnswer.comments == '') {
                return false;
            }
            if (this.testObj.type == 'irat' && this.testObj.settings.immediateFeedback) {
                let isCorrect = _.get(this.question, 'studentAnswer.isCorrect', false);
                if (isCorrect) {
                    return false;
                } else {
                    if (this.question.type == 'mcqs') {
                        if (this.tempAnswers[this.question_group_idx][this.question_idx] == '') {
                            return false;
                        }
                    }
                    if (!_.isEqual(this.tempAnswers[this.question_group_idx][this.question_idx], storedAnswer)) {
                        if (this.tempAnswers[this.question_group_idx][this.question_idx] != '') {
                            return true;
                        }
                    }
                }
            }

            if (this.isTestCompleted || this.sharedObj.isFormBusy) {
                return false;
            }
            let attempts = _.get(this.question, 'studentAnswer.attempts', []);
            let storedAnswer = -1;
            if (attempts.length != 0) {
                storedAnswer = attempts[attempts.length - 1];
            }

            if (this.testObj.type == 'irat' || this.testObj.type == 'application') {
                if (this.testObj.type == 'application' && this.testObj.others.applicationType == 'team') {
                    if (!this.isTeamLeader) {
                        return false;
                    }
                }
                if (!this.canAllowChangeAnswers && storedAnswer != -1) {
                    return false;
                } else if (this.testObj.settings.confidenceBasedTesting && ['mcqs'].includes(this.question.type)) {
                    //console.log(this.tempAnswers[this.question_group_idx][this.question_idx]);
                    //console.log(storedAnswer);
                    let cloneTempAnswers = _.cloneDeep(this.tempAnswers[this.question_group_idx][this.question_idx]);
                    if (this.testObj.settings.randomizeAnswerOptionOrder) {
                        for (var z = 0; z < this.question.options.length; z++) {
                            cloneTempAnswers[this.question.options[z].originalKey] =
                                this.tempAnswers[this.question_group_idx][this.question_idx][this.question.options[z].key];
                        }
                    }
                    if (this.pointSpreadingPointsLeft == 0 && !_.isEqual(cloneTempAnswers, storedAnswer)) {
                        return true;
                    }
                } else if (this.question.type == 'mcqm') {
                    if (this.tempAnswers[this.question_group_idx][this.question_idx].length != 0) {
                        if (!_.isEqual(this.tempAnswers[this.question_group_idx][this.question_idx], storedAnswer)) {
                            return true;
                        }
                    }
                } else if (['mcqs', 'openended', 'vsa'].includes(this.question.type)) {
                    if (this.tempAnswers[this.question_group_idx][this.question_idx] != '') {
                        //randomize question orders
                        if (this.testObj.type == 'irat') {
                            if (this.testObj.settings.randomizeAnswerOptionOrder) {
                                storedAnswer = _.find(this.question.options, function (o) {
                                    return o.originalKey == storedAnswer;
                                });
                                if (storedAnswer) {
                                    storedAnswer = storedAnswer.key;
                                }
                            }

                            if (!_.isEqual(this.tempAnswers[this.question_group_idx][this.question_idx], storedAnswer)) {
                                return true;
                            }
                        }
                        if (['vsa', 'openended'].includes(this.question.type)) {
                            if (this.tempAnswers[this.question_group_idx][this.question_idx])
                                var length = this.tempAnswers[this.question_group_idx][this.question_idx].split(/[^\s]+/).length - 1;

                            if (this.question.type == 'vsa' && typeof length == 'undefined') {
                                return false;
                            }

                            if (this.question.minimum > length) {
                                return false;
                            }
                            if (this.question.maximum && this.question.maximum < length) {
                                return false;
                            }
                        }
                        if (this.isQuestionAnswerChanged) {
                            return true;
                        }
                    }
                }
            }
            if (this.testObj.type == 'trat') {
                if (!this.isTeamLeader) {
                    return false;
                } else {
                    if (['mcqs', 'mcqm'].includes(this.question.type)) {
                        let isCorrect = _.get(this.question, 'studentAnswer.isCorrect', false);
                        if (isCorrect) {
                            return false;
                        } else {
                            if (this.question.type == 'mcqm') {
                                if (this.tempAnswers[this.question_group_idx][this.question_idx].length == 0) {
                                    return false;
                                }
                            }
                            if (this.question.type == 'mcqs') {
                                if (this.tempAnswers[this.question_group_idx][this.question_idx] == '') {
                                    return false;
                                }
                            }
                            if (!_.isEqual(this.tempAnswers[this.question_group_idx][this.question_idx], storedAnswer)) {
                                if (this.question.type == 'mcqs') {
                                    if (this.tempAnswers[this.question_group_idx][this.question_idx] != '') {
                                        return true;
                                    }
                                }
                                if (this.question.type == 'mcqm') {
                                    if (this.tempAnswers[this.question_group_idx][this.question_idx].length > 0) {
                                        return true;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            return false;
        },
        canAttach() {
            if (this.testObj.type == 'irat') {
                return false;
            }
            return _.get(this.testObj, 'settings.allowStudentsToUploadFiles', false);
        },
        isLastQuestion() {
            if (
                this.question_group_idx == this.testObj.questions.length - 1 &&
                this.question_idx == this.testObj.questions[this.question_group_idx].length - 1
            ) {
                return true;
            }
            return false;
        },
        pointSpreadingPointsLeft() {
            if (!this.testObj.settings.confidenceBasedTesting) {
                return null;
            }
            let total = this.question.options.length;
            //return total;
            var that = this;
            Object.keys(this.tempAnswers[this.question_group_idx][this.question_idx]).forEach(function (key, index) {
                total -= parseInt(that.tempAnswers[that.question_group_idx][that.question_idx][key]);
            });
            return total;
        },
        isElaborationRequired() {
            if (this.testObj.type == 'application' && this.question.isElaborationRequired) {
                return true;
            } else return false;
        },
        isChildInView() {
            return this.$parent.questionInView[this.question_idx] || false;
        },
    },
    created() {
        var that = this;
        this.scrollFunction = function () {
            if (!['mcqm', 'mcqs'].includes(this.question.type)) {
                return;
            }
            let option = this.question.options[this.question.options.length - 1];
            if (this.isScrolledIntoView('#' + this.question.uuid + '-' + option.key + '-div')) {
                this.reachedTheBottom = true;
            }
        }.bind(this);

        this.component_done_loading = true;
        //this.init();

        Vue.nextTick(function () {
            that.scrollFunction();
            $('#' + that.question.uuid + '-enlarge-image-modal').draggable();
        });
    },
    mounted() {
        window.addEventListener('click', (event) => {
            const isImg = event.target.nodeName === 'IMG';
            if (!isImg) {
                return;
            }
            var questionUUID = event.target.closest('.panel').getAttribute('id').slice(0, 36);
            this.openEnlargeImageModal(event.target.src, questionUUID);
        });

        window.addEventListener('scroll', this.scrollFunction);
    },
    beforeUnmount() {
        window.removeEventListener('click', (event) => {
            const isImg = event.target.nodeName === 'IMG';
            if (!isImg) {
                return;
            }

            var questionUUID = event.target.closest('.panel').getAttribute('id').slice(0, 36);
            this.openEnlargeImageModal(event.target.src, questionUUID);
        });

        window.removeEventListener('scroll', this.scrollFunction);
    },
    methods: {
        // questionAccessibility(event) {
        //   if (this.testObj.questions[this.question_group_idx].length > 1) {
        //     return;
        //   }
        //   if (this.testObj.type == "application") {
        //     return;
        //   }
        //   if (($("#test_confirm_modal").data("bs.modal") || {}).isShown) {
        //     return;
        //   }
        //   if (
        //     ($("#" + this.question.uuid + "-comment-modal").data("bs.modal") || {})
        //       .isShown
        //   ) {
        //     return;
        //   }
        //   if (
        //     (
        //       $("#" + this.question.uuid + "-clarification-modal").data(
        //         "bs.modal"
        //       ) || {}
        //     ).isShown
        //   ) {
        //     return;
        //   }
        //   var that = this;
        //   let options = _.clone(that.question.options);
        //   options.push({ key: "answer", enabled: true });
        //   options.push({ key: "test", enabled: true });
        //   switch (event.srcKey) {
        //     case "answer":
        //       if (that.canSubmitAnswer) {
        //         that.submitAnswer();
        //       }
        //       break;
        //     case "enter":
        //       let currIdx = Math.abs(
        //         that.accessibility.currOption % options.length
        //       );
        //       let key = options[currIdx].key;
        //       if (key == "answer") {
        //         if (
        //           (that.question.type == "openended" &&
        //             !$("#" + that.question.uuid + "-" + "openended").is(
        //               ":focus"
        //             )) ||
        //           that.question.type != "openended"
        //         ) {
        //           that.submitAnswer();
        //         }
        //       } else if (key == "test") {
        //         Events.fire("submit-test");
        //       } else {
        //         if (that.question.type != "mcqm") {
        //           that.tempAnswers[that.question_group_idx][
        //             that.question_idx
        //           ] = key;
        //           that.tempAnswers.splice();
        //         }
        //       }
        //       break;
        //     case "up":
        //     case "down":
        //       let bias = 0;
        //       if (that.accessibility.currOption === false) {
        //         bias = 0;
        //         that.accessibility.currOption = 0;
        //       } else if (event.srcKey == "up") {
        //         bias = -1;
        //       } else if (event.srcKey == "down") {
        //         bias = 1;
        //       }
        //       let routine = function(bias) {
        //         let answer_submit_buttons = $("button.answer-submission");
        //         let test_submit_buttons = $("button.test-submission");
        //         that.accessibility.currOption += bias;
        //         let currIdx = Math.abs(
        //           that.accessibility.currOption % options.length
        //         );
        //         let key = options[currIdx].key;
        //         for (var i = 0; i < answer_submit_buttons.length; i++) {
        //           $(answer_submit_buttons[i]).removeClass("selected");
        //         }
        //         for (var i = 0; i < test_submit_buttons.length; i++) {
        //           $(test_submit_buttons[i]).removeClass("selected");
        //         }
        //         if (key != "answer" && key != "test") {
        //           if (!["mcqs", "mcqm"].includes(that.question.type)) {
        //             return;
        //           }
        //           for (var i = 0; i < that.question.options.length; i++) {
        //             that.question.options[i].highlight = i === currIdx;

        //             if (that.question.options[i].highlight) {
        //               if (
        //                 $(
        //                   "#" +
        //                     that.question.uuid +
        //                     "-" +
        //                     that.question.options[i].key +
        //                     "-div"
        //                 )
        //               ) {
        //                 if (
        //                   $(
        //                     "#" +
        //                       that.question.uuid +
        //                       "-" +
        //                       that.question.options[i].key +
        //                       "-div"
        //                   ) != undefined
        //                 ) {
        //                   var offset = $(
        //                     "#" +
        //                       that.question.uuid +
        //                       "-" +
        //                       that.question.options[i].key +
        //                       "-div"
        //                   ).offset().top;
        //                   Vue.nextTick(function() {
        //                     $([document.documentElement, document.body]).animate(
        //                       {
        //                         scrollTop: offset - 200,
        //                       },
        //                       200
        //                     );
        //                   });
        //                 }
        //               }
        //             }
        //             that.question.options.splice();
        //           }
        //         } else {
        //           if (key == "answer") {
        //             if (answer_submit_buttons.length == 0) {
        //               routine(bias);
        //               return;
        //             }
        //             for (var i = 0; i < answer_submit_buttons.length; i++) {
        //               if (!$(answer_submit_buttons[i]).prop("disabled")) {
        //                 $(answer_submit_buttons[i]).addClass("selected");
        //               } else {
        //                 routine(bias);
        //                 return;
        //               }
        //             }
        //           }
        //           if (key == "test") {
        //             if (test_submit_buttons.length == 0) {
        //               routine(bias);
        //               return;
        //             }
        //             for (var i = 0; i < test_submit_buttons.length; i++) {
        //               if (!$(test_submit_buttons[i]).prop("disabled")) {
        //                 $(test_submit_buttons[i]).addClass("selected");
        //               } else {
        //                 routine(bias);
        //                 return;
        //               }
        //             }
        //           }
        //         }
        //       };
        //       routine(bias);
        //       break;
        //   }
        // },
        toggleBookmark() {
            /*if(_.isEmpty(_.get(this.question,'studentAnswer.isBookmarked'))){
                return false;
            }*/
            var that = this;
            that.question.studentAnswer.isBookmarked = !that.question.studentAnswer.isBookmarked;
            /*Vue.set(
                that.question.studentAnswer,
                'isBookmarked',
                !that.question.studentAnswer.isBookmarked
            );*/
            axios
                .post('student/tests/bookmark', {
                    userPlacementTestUuid: this.testObj.uuid,
                    activityQuestionUuid: this.question.uuid,
                })
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'Question is now' + (that.question.studentAnswer.isBookmarked ? ' remembered' : ' forgotten.'),
                    });
                })
                .catch(function (errors) {
                    that.question.studentAnswer.isBookmarked = !that.question.studentAnswer.isBookmarked;
                    /*Vue.set(
                        that.question.studentAnswer,
                        'isBookmarked',
                        !that.question.studentAnswer.isBookmarked
                    );*/
                    that.$notify({
                        group: 'form',
                        type: 'error',
                        title: 'Error',
                        text: 'Unable to show question statistics at this moment, please try again later',
                    });
                });
        },
        /*submitTest(){
            Events.fire('submit-test');
        },*/
        addAttachments() {
            var that = this;
            this.client
                .picker({
                    accept: [
                        '.jpg',
                        '.jpeg',
                        '.gif',
                        '.png',
                        '.csv',
                        '.doc',
                        '.docx',
                        '.dot',
                        '.dotx',
                        '.key',
                        '.numbers',
                        '.odt',
                        '.odp',
                        '.ods',
                        '.pages',
                        '.pdf',
                        '.ppt',
                        '.pot',
                        '.potx',
                        '.pps',
                        '.ppsx',
                        '.pub',
                        '.rtf',
                        '.sldx',
                        '.txt',
                        '.xls',
                        '.xlt',
                        '.xlsx',
                        '.mp4',
                        '.mp3',
                        '.rmd',
                    ],
                    maxFiles: 20,
                    uploadInBackground: false,
                    onUploadStarted: function (file) {
                        //that.is_uploading = true;
                    },
                    onUploadDone: function (res) {
                        //that.is_uploading = false;
                        let currentLength = _.get(that, 'question.studentAnswer._attachments', []).length;
                        for (var i = 0; i < res.filesUploaded.length; i++) {
                            _.set(that.question.studentAnswer._attachments, currentLength++, res.filesUploaded[i]);
                        }
                        that.question.studentAnswer._attachments.splice();
                        that.submitAttachments();
                    },
                })
                .open();
        },
        submitClarifications() {
            var that = this;
            if (this.isTeacherPreview) {
                this.question.studentAnswer.clarifications = this.question.studentAnswer._clarifications;
                return;
            }
            axios
                .post(`/student/tests/${this.testObj.uuid}/clarifications`, {
                    activityQuestionUuid: this.question.uuid,
                    clarifications: this.question.studentAnswer._clarifications,
                })
                .then(function (response) {
                    $('#' + that.question.uuid + '-clarification-modal').modal('hide');
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'You have successfully sent clarifications for this question',
                    });
                    //Events.fire('changed-test',response.data.data);
                });
        },
        submitComments() {
            var that = this;
            if (this.isTeacherPreview) {
                this.question.studentAnswer.comments = this.question.studentAnswer._comments;
                return;
            }
            axios
                .post(`/student/tests/${this.testObj.uuid}/elaborations`, {
                    activityQuestionUuid: this.question.uuid,
                    comments: this.question.studentAnswer._comments,
                })
                .then(function (response) {
                    $('#' + that.question.uuid + '-comment-modal').modal('hide');

                    that.question.studentAnswer.comments = that.question.studentAnswer._comments;

                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'You have successfully sent elaborations for this question',
                    });
                    // Events.fire('changed-test',response.data.data);
                });
        },
        submitAttachments() {
            var that = this;
            var url = this.canClarify
                ? `/student/tests/${this.testObj.uuid}/clarifications`
                : this.canComment
                ? `/student/tests/${this.testObj.uuid}/elaborations`
                : false;
            if (!url) {
                //console.log('cannot submit attachment')
                return false;
            }
            axios
                .post(url, {
                    activityQuestionUuid: this.question.uuid,
                    attachments: this.question.studentAnswer._attachments,
                })
                .then(function (response) {
                    that.$notify({
                        group: 'form',
                        type: 'success',
                        title: 'Success',
                        text: 'You have successfully sent attachment/s for this question',
                    });
                    // Events.fire('changed-test',response.data.data);
                });
        },
        submitAnswer() {
            var that = this;

            this.isLoading = true;
            window.setTimeout(function () {
                that.isLoading = false;
                that.isSaved = true;

                window.setTimeout(function () {
                    that.isSaved = false;

                    that.$emit('submitAnswer', {
                        question_group_idx: that.question_group_idx,
                        question_idx: that.question_idx,
                    });
                }, 500);
            }, 500);
        },
        appendTempAnswer(answer) {
            answer = _.get(this.tempAnswers[this.question_group_idx], this.question_idx, '') + answer;
            this.tempAnswers[this.question_group_idx][this.question_idx] = answer;
            /*Vue.set(
                this.tempAnswers[this.question_group_idx],
                this.question_idx,
                answer
            );*/
        },
        setTempAnswer(tempAnswer) {
            var that = this;
            if (this.question.type == 'openended' && this.isTeamLeader) {
                this.debounceLiveTyping(tempAnswer);
            }
            this.tempAnswers[this.question_group_idx][this.question_idx] = tempAnswer;
            /*Vue.set(
                this.tempAnswers[this.question_group_idx],
                this.question_idx,
                tempAnswer
            );*/
            /*if(this.testObj.type=='irat'){
                this.submitAnswer();
            }*/
        },
        init() {
            this.client = filestack.init(process.env.VUE_APP_FILESTACK_API_KEY);
            //this.question = this.testObj.questions[this.question_group_idx][this.question_idx];
        },
        openAttachmentsModal() {
            $('#' + this.question.uuid + '-attachments-modal').modal('show');
        },
        openClarificationModal() {
            $('#' + this.question.uuid + '-clarification-modal').modal('show');
        },
        openCommentModal() {
            $('#' + this.question.uuid + '-comment-modal').modal('show');
        },
        isScrolledIntoView(elem) {
            var docViewTop = $(window).scrollTop();
            var docViewBottom = docViewTop + $(window).height();

            var elemTop = $(elem).offset().top + 90;
            var elemBottom = elemTop + $(elem).height();

            return elemBottom <= docViewBottom && elemTop >= docViewTop;
        },
        openViewPdfModal(attachment) {
            Events.fire('view-pdf-modal', attachment, {
                canDownload: this.testObj.settings.allowPdfDownload,
            });
        },
        openEnlargeImageModal(src, id) {
            if (id == this.question.uuid) {
                $('#' + this.question.uuid + '-enlarge-image-modal').modal('show');

                this.srcValue = src;
                $('#' + this.question.uuid + '-enlarge-image-modal').resizable({
                    handles: 'ne, se, sw, nw',
                });
            }
        },
    },
};
</script>

<style>
.panel-footer-sticky {
    position: sticky;
    bottom: 36px;
    z-index: 5;
}

@media screen and (max-height: 800px) {
    .panel-footer {
        position: inherit;
    }
}

@media (max-width: 991px) {
    .group .panel-footer {
        bottom: 47px;
    }
}
.inline p {
    display: inline;
}
</style>
<style scoped>
.pntRemainining {
    color: #767676;
    font-weight: bold;
    text-transform: capitalize;
}
.bookmarked i.fa-bookmark {
    color: #facd36;
}

.attachedFiles {
    margin-bottom: 20px;
}

.attachedFiles:last-child {
    margin-bottom: 0;
}

.unreadChoices {
    display: flex;
    justify-content: center;
    padding: 10px;
}

.unreadChoices div {
    text-align: center;
    padding: 6px 12px;
    border-radius: 25px;
    text-transform: uppercase;
    background: #c5d0de;
    color: rgba(255, 255, 255, 0.8);
    font-size: 12px;
}

.HintAndExplanations:focus-visible {
    outline: 4px solid #1941a6;
    outline-offset: 3px;
    box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
}
</style>
