<template>
    <div>
        <div id="support_widget" class="support_widget">
            <button
                v-tooltip="{
                    content: 'Support',
                }"
                class="widget_button main_button"
                aria-label="Support"
                @click.prevent="toggleSupportWidget"
            >
                <svg width="42" height="42" viewBox="0 0 595 420" aria-hidden="true">
                    <g transform="translate(0,420) scale(0.1,-0.1)" fill="#fff" stroke="none">
                        <path
                            d="M2100 3893 c-139 -13 -245 -28 -370 -53 -736 -147 -1313 -555 -1499
                -1060 -49 -134 -56 -178 -55 -360 0 -160 3 -183 27 -265 37 -123 123 -293 200
                -395 105 -138 332 -343 467 -420 13 -8 9 -21 -25 -87 -130 -255 -341 -504
                -553 -654 -293 -207 -280 -265 39 -173 372 107 981 383 1251 566 l58 39 62
                -15 c375 -85 877 -89 1281 -10 498 97 930 314 1218 610 241 250 359 515 359
                809 0 736 -811 1364 -1890 1464 -104 10 -484 13 -570 4z m349 -413 c210 -27
                400 -156 492 -335 51 -98 64 -155 63 -265 -3 -196 -82 -321 -286 -454 -84 -55
                -135 -101 -159 -146 -9 -17 -15 -77 -17 -187 l-4 -162 -167 -3 c-91 -2 -170 0
                -175 4 -4 3 -7 111 -4 240 3 229 4 234 30 289 35 70 58 91 213 197 148 100
                181 136 196 212 22 104 -29 194 -134 241 -42 19 -69 23 -142 23 -106 0 -170
                -23 -228 -82 -56 -57 -70 -101 -75 -234 l-4 -118 -174 0 -174 0 0 84 c0 132
                24 248 72 346 73 151 234 291 378 330 106 29 183 34 299 20z m96 -1860 l0
                -175 -177 -3 -178 -2 0 173 c0 96 3 177 7 181 4 3 84 5 177 4 l171 -3 0 -175z"
                        />
                        <path
                            d="M4656 2313 c-18 -148 -29 -204 -57 -287 -154 -462 -657 -861 -1312
                -1040 -67 -18 -123 -38 -124 -43 -6 -16 132 -137 223 -199 298 -199 731 -304
                1139 -274 121 8 308 35 326 46 4 2 47 -21 95 -52 289 -181 924 -423 924 -352
                0 18 -46 61 -140 130 -131 96 -250 236 -349 411 -18 32 -32 59 -30 61 2 2 33
                22 69 46 93 61 238 211 289 298 163 274 140 561 -64 819 -172 218 -471 385
                -821 459 -162 34 -162 34 -168 -23z"
                        />
                    </g>
                </svg>
            </button>

            <div class="widget_body" tabindex="-1" role="dialog" aria-labelledby="form-widget-header-title">
                <header>
                    <h2 id="form-widget-header-title">Leave us a message</h2>
                    <button class="widget_button sub_button" aria-label="minimize support widget" @click.prevent="toggleSupportWidget">
                        <svg width="15" height="15" viewBox="0 0 15 15" aria-hidden="true">
                            <path stroke="#ffffff" stroke-linecap="round" stroke-width="2" d="M1 8h13" fill="#ffffff" />
                        </svg>
                    </button>
                </header>

                <div class="widget_content">
                    <div v-once id="hubspotForm" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import KrAuth from '../../../../components/auth/auth.js';
export default {
    data() {
        var auth = new KrAuth();
        return {
            auth: auth,
        };
    },
    mounted() {
        const script = document.createElement('script');
        script.src = '//js.hsforms.net/forms/embed/v2.js';
        document.body.appendChild(script);
        script.addEventListener('load', () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    region: 'na1',
                    portalId: '6182127',
                    formId: '93031977-918d-44b9-881b-780cab42304e',
                    // formInstanceId: 1,
                    target: '#hubspotForm',
                });
            }
        });
    },
    methods: {
        toggleSupportWidget() {
            $('#support_widget').toggleClass('open_widget');
            $('body').toggleClass('widget_open');
        },
        openElement() {
            var messages = $('.floating-chat').find('.messages');
            var textInput = $('.floating-chat').find('.text-box');
            $('.floating-chat').find('>i').hide();
            $('.floating-chat').addClass('expand');
            $('.floating-chat').find('.chat').addClass('enter');
            var strLength = textInput.val().length * 2;
            $('.floating-chat').off('click', openElement);
            $('.floating-chat').find('.header button').click(closeElement);
            messages.scrollTop(messages.prop('scrollHeight'));
        },
        closeElement() {
            $('.floating-chat').toggleClass('expand');
            $('.floating-chat').find('.chat').removeClass('enter').hide();
            $('.floating-chat').find('>i').show();
        },
    },
};
</script>

<style lang="scss">
body {
    &.widget_open {
        height: 100vh;
        overflow-y: hidden;
    }
}

// Support manual button
.support_widget {
    position: fixed;
    bottom: 1%;
    right: 2%;
    z-index: 1000;

    .main_button {
        display: block;
        background: #1f73b7;
        border-radius: 100px;
        position: absolute;
        z-index: 1;
        padding: 6px 12px;
        line-height: 1;
        position: fixed;
        bottom: 1%;
        right: 2%;
        width: 66px;
        height: 66px;
    }

    .widget_button {
        &:hover,
        &:focus {
            background: #1991eb;
        }
    }

    .widget_body {
        display: none;
        width: 62px;
        height: 62px;
        border-radius: 100px;
        background-color: #fff;
        box-shadow: rgb(0 0 0 / 20%) 0px 0px 0.428571rem 0px;
        overflow: hidden;
        transform: translateY(0);
        transform-origin: bottom right;
        transition: transform 250ms ease-out;

        header {
            display: flex;
            align-content: stretch;
            -webkit-box-align: center;
            align-items: center;
            min-height: 2rem;
            padding: 0.571429rem 1rem;
            background: rgb(31, 115, 183);
            color: rgb(255, 255, 255);

            h2 {
                font-weight: 700;
                text-align: center;
                letter-spacing: 0.0214286rem;
                line-height: 1.2;
                font-size: 1.07143rem;
                flex-grow: 1;
                margin: 0;
            }
        }

        .widget_content {
            padding: 1.07143rem 0.714286rem;
            margin-left: 0.714286rem;
            margin-right: 0.357143rem;
            overflow: hidden auto;
            height: 100%;
        }

        .sub_button {
            padding: 3px 5px;
        }
    }
}

.open_widget {
    .main_button {
        display: none;
    }

    .widget_body {
        display: block;
        width: 100%;
        height: auto;
        border-radius: 0.571429rem;
        transform: translateY(0);
    }
}

@media (max-width: 900px) {
    .main_button {
        svg {
            width: 36px;
            height: 36px;
        }
    }

    .support_widget.open_widget {
        width: 100%;
        height: 100%;
        bottom: 0;
        right: 0;

        .widget_body {
            height: 100%;
            border-radius: 0;
        }
    }
}
</style>
