<template>
	<div v-if="component_done_loading">
		<kr-panel :with-footer="false" :options="{canToggle:false}">
			<template #title>
				<div>
					Evaluation Analysis
				</div>
			</template>
			<template #content>
				<div class="nav-tabsContainer">
					<div class="links">
			            <ul>
			            	<li v-if="testObj.evaluationSettings.evaluateTeammates||testObj.evaluationSettings.evaluateThemselves" :class="{'active':tab=='evaIndividual'}">
								<a class="nav-link" data-toggle="tab" href="#evaIndividual" aria-label="Individual Evaluation" @click="tab='evaIndividual'">INDIVIDUAL EVALUATION</a>
							</li>
							<li v-if="testObj.evaluationSettings.evaluateTeam" :class="{'active':tab=='evaOnTeam'}">
								<a class="nav-link" data-toggle="tab" href="#evaOnTeam"  aria-label="Team Evaluation" @click="tab='evaOnTeam'">TEAM EVALUATION</a>
							</li>
							<li v-if="testObj.evaluationSettings.evaluateInstructor" :class="{'active':tab=='evaOnInstructor'}">
								<a class="nav-link" data-toggle="tab" href="#evaOnInstructor"  aria-label="Instructor Evaluation" @click="tab='evaOnInstructor'">INSTRUCTOR EVALUATION</a>
							</li>
							<li v-if="testObj.settings.enableMichaelsenPointDistribution" :class="{'active':tab=='michelsenMethod'}">
								<a class="nav-link" data-toggle="tab" href="#michelsenMethod" aria-label="Michaelsen Method" @click="tab='michelsenMethod'">MICHAELSEN METHOD</a>
							</li>
			            </ul>
			        </div>
				</div>

				<div class="flexOny marginTop30">
					<div class="inlineFlex">
						<div v-if="tab!='michelsenMethod'" class="flex align-items">
							<div class="form-dropdown viewFilter marginLeft0 minWidth0">
								<select v-model="questionSelection" class="form-control" :aria-label="questionSelection">
									<option value="" disabled selected>Choose a question</option>
									<template v-for="(question,key) in questions">
										<option :value="key">{{question}}</option>
									</template>
								</select>
							</div>
						</div>
						<div v-if="tab!='evaOnInstructor'" class="flex align-items" :class="{'marginLeft30':tab!='michelsenMethod'}">
							<div class="form-dropdown viewFilter marginLeft0 minWidth0">
								<select v-model="teamSelection" class="form-control" :aria-label="teamSelection">
									<option value="" disabled selected>Choose a team</option>
									<template v-for="(team,key) in teams">
										<option :value="key">{{team}}</option>
									</template>
								</select>
							</div>
						</div>
						<div v-else class="flex align-items marginLeft30">
							<div class="form-dropdown viewFilter marginLeft0 minWidth0">
								<select v-model="instructorSelection" class="form-control" :aria-label="instructorSelection">
									<option value="" disabled selected>Choose an instructor</option>
									<template v-for="(instructor,key) in instructors">
										<option :value="key">{{instructor}}</option>
									</template>
								</select>
							</div>
						</div>
						<button class="btn btn-primary mainBtn" :disabled="(!canSearch) ? true:undefined" aria-label="Search" @click="fetch()">
							Search<i class="fas fa-search marginLeft8" aria-hidden="true"></i>
						</button>
					</div>
				</div>

				<div class="tab-content marginTop20">

					<div v-if="testObj.evaluationSettings.evaluateTeammates||testObj.evaluationSettings.evaluateThemselves" id="evaIndividual" :class="{'active':tab=='evaIndividual'}" class="tab-pane fade in">
						<div v-if="!(questionSelection&&teamSelection)" class="fontItalic">Choose a question and team above and press "Search".</div>
						 
						<div v-if="(questionSelection&&teamSelection)" class="flexSpaceBetween alignBaseline">
						 	<div class="flexOnly alignFlexStart">
						 		<button v-if="individualData.question.question" class="btn gradient btn-default marginRight10" :aria-label="individualData.question.questionNo" @click.prevent="openPreviewQuestionModal(individualData.question)">
									{{individualData.question.questionNo}}<i class="fas fa-info-circle marginLeft8" aria-hidden="true"></i>
								</button>
				                <span class="question displayInlineBlock" v-html="individualData.question.question"></span>
				            </div>
				            <div v-if="individualData.question.isRequired" class="statusTag statusTagDanger fontSize12 padding5">
				                Required
				            </div>
				        </div>

						<template v-if="individualData.question.type=='rating' && (questionSelection&&teamSelection)">
							<div class="table-responsive newEvaluationTable marginTop20">
								<table class="table">
									<thead>
										<tr>
											<th rowspan="3" class="width150px"> Rating Received By </th>
											<th rowspan="3" class="width150px"> Status </th>
											<th :colspan="individualData.individualEvaluation.length * 3"> Rating Received From </th>
											<th rowspan="3"> Total </th>
											<th rowspan="3"> Avg </th>
											<th rowspan="3"> Team Avg </th>
										</tr>
										<tr>
											<template v-for="(individual,idx) in individualData.individualEvaluation">
												<th colspan="3" class="whiteSpaceNowrap"> {{individual.receiver}} </th>
											</template>
										</tr>
										<tr>
											<template v-for="(individual,idx) in individualData.individualEvaluation">
												<th class="whiteSpaceNowrap"> Scale </th>
												<th class="whiteSpaceNowrap"> Label </th>
												<th class="whiteSpaceNowrap"> Description </th>
											</template>
										</tr>
									</thead>

									<tbody>
										<template v-for="(individual,idx) in individualData.individualEvaluation.slice(0,1)">
											<tr>
												<td>
													{{individual.receiver}}
												</td>

												<td>
													<div v-if="individual.status.status=='Timed Out'" class="tableStatusTag tableStatusTag-danger">
														Timed Out
													</div>
													<div v-if="individual.status.status=='Not Started'" class="tableStatusTag tableStatusTag-danger">
														Not Started
													</div>
													<div v-if="individual.status.status=='Ongoing'" class="tableStatusTag tableStatusTag-warning">
														Ongoing
													</div>
													<div v-if="individual.status.status=='Submitted'" class="tableStatusTag tableStatusTag-success">
														Submitted
													</div>
													<div v-if="individual.status.tally" class="marginTop10">{{individual.status.tally}}</div>
												</td>

												<template v-for="(giver,giver_idx) in individual.givers">
													<td :class="{'disabled':((individualData.individualEvaluation[giver_idx].receiver==individual.receiver)&&!testObj.evaluationSettings.evaluateThemselves)||((individualData.individualEvaluation[giver_idx].receiver!=individual.receiver)&&!testObj.evaluationSettings.evaluateTeammates)}">
														{{ lookForWeight(idx, individualData.individualEvaluation[giver_idx].receiver) }}	
													</td>

													<td :class="{'disabled':((individualData.individualEvaluation[giver_idx].receiver==individual.receiver)&&!testObj.evaluationSettings.evaluateThemselves)||((individualData.individualEvaluation[giver_idx].receiver!=individual.receiver)&&!testObj.evaluationSettings.evaluateTeammates)}">
														{{ lookForLabel(idx, individualData.individualEvaluation[giver_idx].receiver) }}	
													</td>

													<td :class="{'disabled':((individualData.individualEvaluation[giver_idx].receiver==individual.receiver)&&!testObj.evaluationSettings.evaluateThemselves)||((individualData.individualEvaluation[giver_idx].receiver!=individual.receiver)&&!testObj.evaluationSettings.evaluateTeammates)}">
														{{ lookForRubric(idx, individualData.individualEvaluation[giver_idx].receiver) }}	
													</td>
												</template>

												<td>
													{{ totalEvaluationScale(idx) }}
												</td>

												<td>
													{{individual.receiverAvg}}
												</td>

												<template v-if="individual.givers.length!=0">
													<td :rowspan="individual.givers.length">
														{{individual.teamMean.toFixed(2)}}
													</td>
												</template>
											</tr>
										</template>

										<template v-for="(individual,idx) in individualData.individualEvaluation.slice(1)">
											<tr class="notFirstRow">
												<td>
													{{individual.receiver}}
												</td>

												<td>
													<div v-if="individual.status.status=='Timed Out'" class="tableStatusTag tableStatusTag-danger">
														Timed Out
													</div>
													<div v-if="individual.status.status=='Not Started'" class="tableStatusTag tableStatusTag-danger">
														Not Started
													</div>
													<div v-if="individual.status.status=='Ongoing'" class="tableStatusTag tableStatusTag-warning">
														Ongoing
													</div>
													<div v-if="individual.status.status=='Submitted'" class="tableStatusTag tableStatusTag-success">
														Submitted
													</div>
													<div v-if="individual.status.tally" class="marginTop10">{{individual.status.tally}}</div>
												</td>

												<template v-for="(giver,giver_idx) in individual.givers">
													<td :class="{'disabled':((individualData.individualEvaluation[giver_idx].receiver==individual.receiver)&&!testObj.evaluationSettings.evaluateThemselves)||((individualData.individualEvaluation[giver_idx].receiver!=individual.receiver)&&!testObj.evaluationSettings.evaluateTeammates)}">
														{{ lookForWeight(idx + 1, individualData.individualEvaluation[giver_idx].receiver) }}	
													</td>

													<td :class="{'disabled':((individualData.individualEvaluation[giver_idx].receiver==individual.receiver)&&!testObj.evaluationSettings.evaluateThemselves)||((individualData.individualEvaluation[giver_idx].receiver!=individual.receiver)&&!testObj.evaluationSettings.evaluateTeammates)}">
														{{ lookForLabel(idx + 1, individualData.individualEvaluation[giver_idx].receiver) }}	
													</td>

													<td :class="{'disabled':((individualData.individualEvaluation[giver_idx].receiver==individual.receiver)&&!testObj.evaluationSettings.evaluateThemselves)||((individualData.individualEvaluation[giver_idx].receiver!=individual.receiver)&&!testObj.evaluationSettings.evaluateTeammates)}">
														{{ lookForRubric(idx + 1, individualData.individualEvaluation[giver_idx].receiver) }}	
													</td>
												</template>

												<td>
													{{ totalEvaluationScale( idx + 1 ) }}
												</td>

												<td>
													{{individual.receiverAvg}}
												</td>
											</tr>
										</template>
									</tbody>
								</table>
							</div> 
							
							<!-- <table class="table marginTop20">
								<thead>
									<tr>
										<th class="width150px">RECEIVER</th>
										<th class="width150px">STATUS</th>
										<th class="width150px">GIVER</th>
										<th>POINTS GIVEN</th>
										<th v-if="!(!testObj.evaluationSettings.evaluateTeammates&&testObj.evaluationSettings.evaluateThemselves)">RECEIVER AVG.</th>
										<th>TEAM MEAN</th>
									</tr>
								</thead>
								<tbody>
									<template v-for="(individual,idx) in individualData.individualEvaluation">
										<tr>
											<tr class="thisRow">
												<td :rowspan="individual.givers.length">
													{{individual.receiver}}
												</td>
												<td :rowspan="individual.givers.length">
													<div v-if="individual.status.status=='Timed Out'" class="statusTag statusTagDanger subBtn">
		                                            	Timed Out
		                                        	</div>
													<div v-if="individual.status.status=='Not Started'" class="statusTag statusTagDarkDanger subBtn">
		                                            	Not Started
		                                        	</div>
													<div v-if="individual.status.status=='Ongoing'" class="statusTag statusTagWarning subBtn">
		                                            	Ongoing
		                                        	</div>
													<div v-if="individual.status.status=='Submitted'" class="statusTag statusTagSuccess subBtn">
			                                            Submitted
			                                        </div>
													<div class="marginTop10">{{individual.status.tally}}</div>
												</td>
												<template v-if="individual.givers.length!=0">
													<td :class="{'disabled':((individual.givers[0].giver==individual.receiver)&&!testObj.evaluationSettings.evaluateThemselves)||((individual.givers[0].giver!=individual.receiver)&&!testObj.evaluationSettings.evaluateTeammates)}">
														{{individual.givers[0].giver}}
													</td>
													<td :class="{'disabled':((individual.givers[0].giver==individual.receiver)&&!testObj.evaluationSettings.evaluateThemselves)||((individual.givers[0].giver!=individual.receiver)&&!testObj.evaluationSettings.evaluateTeammates)}">
														{{individual.givers[0].response}}
													</td>
													<td v-if="!(!testObj.evaluationSettings.evaluateTeammates&&testObj.evaluationSettings.evaluateThemselves)" :rowspan="individual.givers.length">
														{{individual.receiverAvg}}
													</td>
													<td :rowspan="individual.givers.length">
														{{individual.teamMean}}
													</td>
												</template>
											</tr>
											<template v-if="individual.givers.length>1">
												<template v-for="(giver,giver_idx) in individual.givers.slice(1)">
													<tr>
														<td :class="{'disabled':((giver.giver==individual.receiver)&&!testObj.evaluationSettings.evaluateThemselves)||((giver.giver!=individual.receiver)&&!testObj.evaluationSettings.evaluateTeammates)}">
															<span class="question displayInlineBlock" v-html="giver.giver"></span>
														</td>
														<td :class="{'disabled':(giver.giver==individual.receiver)&&!testObj.evaluationSettings.evaluateThemselves}">
															<span class="question displayInlineBlock" v-html="giver.response"></span>
														</td>
													</tr>
												</template>
											</template>	
										</tr>
									</template>
								</tbody>
							</table> -->
						</template>

						<template v-if="individualData.question.type=='openended' && (questionSelection&&teamSelection)">
							<table class="table marginTop20">
								<thead>
									<tr>
										<th class="width150px">RECEIVER</th>
										<th class="width150px">STATUS</th>
										<th class="width150px">GIVER</th>
										<th>EVALUATION GIVEN</th>
									</tr>
								</thead>
								<tbody>
									<template v-for="(individual,idx) in individualData.individualEvaluation">
										<tr>
											<tr class="thisRow">
												<td :rowspan="individual.givers.length">
													{{individual.receiver}}
												</td>
												<td :rowspan="individual.givers.length">
													<div v-if="individual.status.status=='Timed Out'" class="tableStatusTag tableStatusTag-danger">
														Timed Out
													</div>
													<div v-if="individual.status.status=='Not Started'" class="tableStatusTag tableStatusTag-danger">
														Not Started
													</div>
													<div v-if="individual.status.status=='Ongoing'" class="tableStatusTag tableStatusTag-warning">
														Ongoing
													</div>
													<div v-if="individual.status.status=='Submitted'" class="tableStatusTag tableStatusTag-success">
														Submitted
													</div>
													<div v-if="individual.status.tally" class="marginTop10">{{individual.status.tally}}</div>
												</td>
												<template v-if="individual.givers.length!=0">
													<td :class="{'disabled':((individual.givers[0].giver==individual.receiver)&&!testObj.evaluationSettings.evaluateThemselves)||((individual.givers[0].giver!=individual.receiver)&&!testObj.evaluationSettings.evaluateTeammates)}">
														{{individual.givers[0].giver}}
													</td>
													<td class="wordBreakWord whiteSpacePreWrap" :class="{'disabled':((individual.givers[0].giver==individual.receiver)&&!testObj.evaluationSettings.evaluateThemselves)||((individual.givers[0].giver!=individual.receiver)&&!testObj.evaluationSettings.evaluateTeammates)}" v-html="filterProfanity(individual.givers[0].response)">
													</td>
												</template>
											</tr>
											<template v-if="individual.givers.length>1">
												<template v-for="(giver,giver_idx) in individual.givers.slice(1)">
													<tr class="thisRow">
														<td :class="{'disabled':((giver.giver==individual.receiver)&&!testObj.evaluationSettings.evaluateThemselves)||((giver.giver!=individual.receiver)&&!testObj.evaluationSettings.evaluateTeammates)}" v-html="giver.giver">
														</td>
														<td class="wordBreakWord whiteSpacePreWrap" :class="{'disabled':((giver.giver==individual.receiver)&&!testObj.evaluationSettings.evaluateThemselves)||((giver.giver!=individual.receiver)&&!testObj.evaluationSettings.evaluateTeammates)}" v-html="filterProfanity(giver.response)">
														</td>
													</tr>
												</template>
											</template>
										</tr>
									</template>
								</tbody>
							</table>
						</template> 

						<!-- Special Case (Self Only) -->
						<!-- <div class="flexSpaceBetween">
				    		<div class="flexOnly">
				                1. This teammate is always on-time for class?
				            </div>
				            <div class="statusTag statusTagDanger subBtn fontSize12 padding5">
				                Required
				            </div>
				        </div>
				        <template>
							<table class="table marginTop20">
								<thead>
									<tr>
										<th>RECEIVER</th>
										<th class="width150px">STATUS</th>
										<th>GIVER</th>
										<th>POINTS GIVEN</th>
										<th>TEAM MEAN</th>
									</tr>
								</thead>
								<tbody>
									<tr class="thisRow">
										<td>
											Mark
										</td>
										<td>
											<div class="statusTag statusTagSuccess subBtn">
	                                            Submitted
	                                        </div>
											<div class="marginTop10">9/10 Answered</div>
										</td>
										<td>
											Mark
										</td>
										<td>
											10 (Very Strongly Agree)
										</td>
										<td rowspan="5">
											8.3
										</td>
									</tr>

									<tr>
										<td>
											Alex
										</td>
										<td>
											<div class="statusTag statusTagWarning subBtn">
	                                            Ongoing
	                                        </div>
											<div class="marginTop10">7/10 Answered</div>
										</td>
										<td>
											Alex
										</td>
										<td>
											9 (Strongly Agree)
										</td>
									</tr>

									
									<tr>
										<td>
											Jolene
										</td>
										<td>
											<div class="statusTag statusTagDarkDanger subBtn">
	                                            Not Started
	                                        </div>
										</td>
										<td>
											Jolene
										</td>
										<td>

										</td>
									</tr>

									
									<tr>
										<td>
											Kelvin
										</td>
										<td>
											<div class="statusTag statusTagDanger subBtn">
	                                            Timed Out
	                                        </div>
											<div class="marginTop10">7/10 Answered</div>
										</td>
										<td>
											Kelvin
										</td>
										<td>
											6 (Agree)
										</td>
									</tr>
								</tbody>
							</table>
						</template> -->
					</div>

					<div id="evaOnTeam" :class="{'active':tab=='evaOnTeam'}" class="tab-pane fade in">

						<div v-if="!(questionSelection&&teamSelection)" class="fontItalic">Choose a question and team above and press "Search".</div>
						 
						<div v-if="(questionSelection&&teamSelection)" class="flexSpaceBetween alignBaseline">
						 	<div class="flexOnly alignFlexStart">
						 		<button v-if="teamData.question.question" class="btn gradient btn-default marginRight10" :aria-label="teamData.question.questionNo" @click.prevent="openPreviewQuestionModal(teamData.question)">
									{{teamData.question.questionNo}}<i class="fas fa-info-circle marginLeft8" aria-hidden="true"></i>
								</button>
						 		<span class="question displayInlineBlock"  v-html="teamData.question.question"></span>
						 	</div>
				            <div v-if="teamData.question.isRequired" class="statusTag statusTagDanger fontSize12 padding5">
				                Required
				            </div>
				        </div>

				        <template v-if="teamData.question.type=='rating' && (questionSelection&&teamSelection)">

							<div class="table-responsive newEvaluationTable marginTop20">
								<table class="table">
									<thead>
										<tr>
											<th class="width150px">RECEIVER</th>
											<th class="width150px">GIVER</th>
											<th class="width150px"> GIVER STATUS</th>
											<th>Scale</th>
											<th>Label</th>
											<th>Description</th>
											<th>Avg</th>
											<th>Team Avg</th>
										</tr>
									</thead>
									<tbody>

										<tr>
											<tr class="thisRow">
												<td :rowspan="teamData.teamEvaluation.givers.length">
													{{teamData.teamEvaluation.receiver}}
												</td>
												<template v-if="teamData.teamEvaluation.givers.length!=0">
													<td>
														{{teamData.teamEvaluation.givers[0].giver}}
													</td>
													<td>
														<div v-if="teamData.teamEvaluation.givers[0].status.status=='Timed Out'" class="tableStatusTag tableStatusTag-danger">
														Timed Out
														</div>
														<div v-if="teamData.teamEvaluation.givers[0].status.status=='Not Started'" class="tableStatusTag tableStatusTag-danger">
															Not Started
														</div>
														<div v-if="teamData.teamEvaluation.givers[0].status.status=='Ongoing'" class="tableStatusTag tableStatusTag-warning">
															Ongoing
														</div>
														<div v-if="teamData.teamEvaluation.givers[0].status.status=='Submitted'" class="tableStatusTag tableStatusTag-success">
															Submitted
														</div>
														<div class="marginTop10">{{teamData.teamEvaluation.givers[0].status.tally}}</div>
													</td>
													<td>
														{{ lookForTeamWeights(0) }}
													</td>
													<td>
														{{ lookForTeamLabels(0) }}
													</td>
													<td>
														{{ lookForTeamRubrics(0) }}
													</td>
													<td  :rowspan="teamData.teamEvaluation.givers.length">{{teamData.teamEvaluation.teamAvg}}</td>
													<td  :rowspan="teamData.teamEvaluation.givers.length">{{teamData.teamEvaluation.sectionMean}}</td>
												</template>
											</tr>
											<template v-if="teamData.teamEvaluation.givers.length>1">
												<template v-for="(giver,giver_idx) in teamData.teamEvaluation.givers.slice(1)">
													<tr>
														<td>
															{{giver.giver}}
														</td>
														<td>
															<div v-if="giver.status.status=='Timed Out'" class="tableStatusTag tableStatusTag-danger">
															Timed Out
															</div>
															<div v-if="giver.status.status=='Not Started'" class="tableStatusTag tableStatusTag-danger">
																Not Started
															</div>
															<div v-if="giver.status.status=='Ongoing'" class="tableStatusTag tableStatusTag-warning">
																Ongoing
															</div>
															<div v-if="giver.status.status=='Submitted'" class="tableStatusTag tableStatusTag-success">
																Submitted
															</div>
															<div class="marginTop10">{{giver.status.tally}}</div>
														</td>
														<td>
															{{ lookForTeamWeights(giver_idx + 1) }}
														</td>
														<td>
															{{ lookForTeamLabels(giver_idx + 1) }}
														</td>
														<td>
															{{ lookForTeamRubrics(giver_idx + 1) }}
														</td>
													</tr>
												</template>
											</template>
										</tr>
									</tbody>
								</table>
							</div>
							
							<!-- <table class="table marginTop20">
								<thead>
									<tr>
										<th class="width150px">RECEIVER</th>
										<th class="width150px">GIVER</th>
										<th class="width150px"> GIVER STATUS</th>
										<th>POINTS GIVEN</th>
										<th>TEAM AVG.</th>
										<th>SECTION MEAN</th>
									</tr>
								</thead>
								<tbody>

									<tr>
										<tr class="thisRow">
											<td :rowspan="teamData.teamEvaluation.givers.length">
												{{teamData.teamEvaluation.receiver}}
											</td>
											<template v-if="teamData.teamEvaluation.givers.length!=0">
												<td>
													{{teamData.teamEvaluation.givers[0].giver}}
												</td>
												<td>
													<div v-if="teamData.teamEvaluation.givers[0].status.status=='Timed Out'" class="statusTag statusTagDanger subBtn">
	                                            	Timed Out
		                                        	</div>
													<div v-if="teamData.teamEvaluation.givers[0].status.status=='Not Started'" class="statusTag statusTagDarkDanger subBtn">
		                                            	Not Started
		                                        	</div>
													<div v-if="teamData.teamEvaluation.givers[0].status.status=='Ongoing'" class="statusTag statusTagWarning subBtn">
		                                            	Ongoing
		                                        	</div>
													<div v-if="teamData.teamEvaluation.givers[0].status.status=='Submitted'" class="statusTag statusTagSuccess subBtn">
			                                            Submitted
			                                        </div>
													<div class="marginTop10">{{teamData.teamEvaluation.givers[0].status.tally}}</div>
												</td>
												<td>{{teamData.teamEvaluation.givers[0].response}}</td>
												<td  :rowspan="teamData.teamEvaluation.givers.length">{{teamData.teamEvaluation.teamAvg}}</td>
												<td  :rowspan="teamData.teamEvaluation.givers.length">{{teamData.teamEvaluation.sectionMean}}</td>
											</template>
										</tr>
										<template v-if="teamData.teamEvaluation.givers.length>1">
											<template v-for="(giver,giver_idx) in teamData.teamEvaluation.givers.slice(1)">
												<tr>
													<td>
														{{giver.giver}}
													</td>
													<td>
														<div v-if="giver.status.status=='Timed Out'" class="statusTag statusTagDanger subBtn">
		                                            	Timed Out
			                                        	</div>
														<div v-if="giver.status.status=='Not Started'" class="statusTag statusTagDarkDanger subBtn">
			                                            	Not Started
			                                        	</div>
														<div v-if="giver.status.status=='Ongoing'" class="statusTag statusTagWarning subBtn">
			                                            	Ongoing
			                                        	</div>
														<div v-if="giver.status.status=='Submitted'" class="statusTag statusTagSuccess subBtn">
				                                            Submitted
				                                        </div>
														<div class="marginTop10">{{giver.status.tally}}</div>
													</td>
													<td>
														{{giver.response}}
													</td>
												</tr>
											</template>
										</template>
									</tr>
								</tbody>
							</table> -->
						</template>

						<!-- Team Evaluation (Free Response) -->
						
						<template v-if="teamData.question.type=='openended' && (questionSelection&&teamSelection)">
							<table class="table marginTop20">
								<thead>
									<tr>
										<th class="width150px">RECEIVER</th>
										<th class="width150px">GIVER</th>
										<th class="width150px"> GIVER STATUS</th>
										<th>EVALUATION GIVEN</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<tr class="thisRow">
											<td :rowspan="teamData.teamEvaluation.givers.length">
												{{teamData.teamEvaluation.receiver}}
											</td>
											<template v-if="teamData.teamEvaluation.givers.length!=0">
												<td>
													{{teamData.teamEvaluation.givers[0].giver}}
												</td>
												<td>
													<div v-if="teamData.teamEvaluation.givers[0].status.status=='Timed Out'" class="tableStatusTag tableStatusTag-danger">
	                                            	Timed Out
		                                        	</div>
													<div v-if="teamData.teamEvaluation.givers[0].status.status=='Not Started'" class="tableStatusTag tableStatusTag-danger">
		                                            	Not Started
		                                        	</div>
													<div v-if="teamData.teamEvaluation.givers[0].status.status=='Ongoing'" class="tableStatusTag tableStatusTag-warning">
		                                            	Ongoing
		                                        	</div>
													<div v-if="teamData.teamEvaluation.givers[0].status.status=='Submitted'" class="tableStatusTag tableStatusTag-success">
			                                            Submitted
			                                        </div>
													<div class="marginTop10">{{teamData.teamEvaluation.givers[0].status.tally}}</div>
												</td>
												<td class="whiteSpacePreWrap"><div v-html="filterProfanity(teamData.teamEvaluation.givers[0].response)"></div></td>
											</template>
										</tr>
										<template v-if="teamData.teamEvaluation.givers.length>1">
											<template v-for="(giver,giver_idx) in teamData.teamEvaluation.givers.slice(1)">
												<tr class="thisRow">
													<td>
														<span class="whiteSpacePreWrap" v-html="giver.giver"></span>
														<!-- {{giver.giver}} -->
													</td>
													<td>
														<div v-if="giver.status.status=='Timed Out'" class="tableStatusTag tableStatusTag-danger">
		                                            	Timed Out
			                                        	</div>
														<div v-if="giver.status.status=='Not Started'" class="tableStatusTag tableStatusTag-danger">
			                                            	Not Started
			                                        	</div>
														<div v-if="giver.status.status=='Ongoing'" class="tableStatusTag tableStatusTag-warning">
			                                            	Ongoing
			                                        	</div>
														<div v-if="giver.status.status=='Submitted'" class="tableStatusTag tableStatusTag-success">
				                                            Submitted
				                                        </div>
														<div class="marginTop10">{{giver.status.tally}}</div>
													</td>
													<td class="wordBreakWord whiteSpacePreWrap" v-html="filterProfanity(giver.response)">
													</td>
												</tr>
											</template>
										</template>
									</tr>
								</tbody>
							</table>
						</template>

					</div>

					<div id="evaOnInstructor" :class="{'active':tab=='evaOnInstructor'}" class="tab-pane fade in">
						
						<div v-if="!(questionSelection&&instructorSelection)" class="fontItalic">Choose a question and instructor above and press "Search".</div>
						 
						<div v-if="(questionSelection&&instructorSelection)" class="flexSpaceBetween alignBaseline">
						 	<div class="flexOnly alignFlexStart">
						 		<button v-if="instructorData.question.question" class="btn gradient btn-default marginRight10" :aria-label="instructorData.question.questionNo" @click.prevent="openPreviewQuestionModal(instructorData.question)">
									{{instructorData.question.questionNo}}<i class="fas fa-info-circle marginLeft8" aria-hidden="true"></i>
								</button>
						 		<span class="question displayInlineBlock" v-html="instructorData.question.question"></span>
						 	</div>
				            <div v-if="instructorData.question.isRequired" class="statusTag statusTagDanger fontSize12 padding5">
				                Required
				            </div>
				        </div>

				        <template v-if="instructorData.question.type=='rating' && (questionSelection&&instructorSelection)">
							<div class="table-responsive newEvaluationTable marginTop20">
								<table class="table">
									<thead>
										<tr>
											<th class="width150px">RECEIVER</th>
											<th class="width150px">GIVER</th>
											<th class="width150px"> GIVER STATUS</th>
											<th>Scale</th>
											<th>Label</th>
											<th>Description</th>
											<th>AVG</th>
											<th>INSTRUCTORS AVG</th>
										</tr>
									</thead>
									<tbody>

										<tr>
											<tr class="thisRow">
												<td :rowspan="instructorData.instructorEvaluation.givers.length">
													{{instructorData.instructorEvaluation.receiver}}
												</td>
												<template v-if="instructorData.instructorEvaluation.givers.length!=0">
													<td>
														{{instructorData.instructorEvaluation.givers[0].giver}}
													</td>
													<td>
														<div v-if="instructorData.instructorEvaluation.givers[0].status.status=='Timed Out'" class="tableStatusTag tableStatusTag-danger">
														Timed Out
														</div>
														<div v-if="instructorData.instructorEvaluation.givers[0].status.status=='Not Started'" class="tableStatusTag tableStatusTag-danger">
															Not Started
														</div>
														<div v-if="instructorData.instructorEvaluation.givers[0].status.status=='Ongoing'" class="tableStatusTag tableStatusTag-warning">
															Ongoing
														</div>
														<div v-if="instructorData.instructorEvaluation.givers[0].status.status=='Submitted'" class="tableStatusTag tableStatusTag-success">
															Submitted
														</div>
														<div class="marginTop10">{{instructorData.instructorEvaluation.givers[0].status.tally}}</div>
													</td>
													<td>
														{{ lookForInstructorWeights(0) }}
													</td>
													<td>
														{{ lookForInstructorLabels(0) }}
													</td>
													<td>
														{{ lookForInstructorRubrics(0) }}
													</td>
													<td  :rowspan="instructorData.instructorEvaluation.givers.length">{{instructorData.instructorEvaluation.instructorAvg}}</td>
													<td  :rowspan="instructorData.instructorEvaluation.givers.length">{{instructorData.instructorEvaluation.instructorsMean}}</td>
												</template>
											</tr>
											<template v-if="instructorData.instructorEvaluation.givers.length>1">
												<template v-for="(giver,giver_idx) in instructorData.instructorEvaluation.givers.slice(1)">
													<tr>
														<td>
															{{giver.giver}}
														</td>
														<td>
															<div v-if="giver.status.status=='Timed Out'" class="tableStatusTag tableStatusTag-danger">
															Timed Out
															</div>
															<div v-if="giver.status.status=='Not Started'" class="tableStatusTag tableStatusTag-danger">
																Not Started
															</div>
															<div v-if="giver.status.status=='Ongoing'" class="tableStatusTag tableStatusTag-warning">
																Ongoing
															</div>
															<div v-if="giver.status.status=='Submitted'" class="tableStatusTag tableStatusTag-success">
																Submitted
															</div>
															<div class="marginTop10">{{giver.status.tally}}</div>
														</td>
														<td>
															{{ lookForInstructorWeights(giver_idx + 1) }}
														</td>
														<td>
															{{ lookForInstructorLabels(giver_idx + 1) }}
														</td>
														<td>
															{{ lookForInstructorRubrics(giver_idx + 1) }}
														</td>
													</tr>
												</template>
											</template>
										</tr>
									</tbody>
								</table>
							</div>

							<!-- <table class="table marginTop20">
								<thead>
									<tr>
										<th class="width150px">RECEIVER</th>
										<th class="width150px">GIVER</th>
										<th class="width150px"> GIVER STATUS</th>
										<th>POINTS GIVEN</th>
										<th>INSTRUCTOR AVG.</th>
										<th>INSTRUCTORS MEAN</th>
									</tr>
								</thead>
								<tbody>

									<tr>
										<tr class="thisRow">
											<td :rowspan="instructorData.instructorEvaluation.givers.length">
												{{instructorData.instructorEvaluation.receiver}}
											</td>
											<template v-if="instructorData.instructorEvaluation.givers.length!=0">
												<td>
													{{instructorData.instructorEvaluation.givers[0].giver}}
												</td>
												<td>
													<div v-if="instructorData.instructorEvaluation.givers[0].status.status=='Timed Out'" class="statusTag statusTagDanger subBtn">
	                                            	Timed Out
		                                        	</div>
													<div v-if="instructorData.instructorEvaluation.givers[0].status.status=='Not Started'" class="statusTag statusTagDarkDanger subBtn">
		                                            	Not Started
		                                        	</div>
													<div v-if="instructorData.instructorEvaluation.givers[0].status.status=='Ongoing'" class="statusTag statusTagWarning subBtn">
		                                            	Ongoing
		                                        	</div>
													<div v-if="instructorData.instructorEvaluation.givers[0].status.status=='Submitted'" class="statusTag statusTagSuccess subBtn">
			                                            Submitted
			                                        </div>
													<div class="marginTop10">{{instructorData.instructorEvaluation.givers[0].status.tally}}</div>
												</td>
												<td>{{instructorData.instructorEvaluation.givers[0].response}}</td>
												<td  :rowspan="instructorData.instructorEvaluation.givers.length">{{instructorData.instructorEvaluation.instructorAvg}}</td>
												<td  :rowspan="instructorData.instructorEvaluation.givers.length">{{instructorData.instructorEvaluation.instructorsMean}}</td>
											</template>
										</tr>
										<template v-if="instructorData.instructorEvaluation.givers.length>1">
											<template v-for="(giver,giver_idx) in instructorData.instructorEvaluation.givers.slice(1)">
												<tr>
													<td>
														{{giver.giver}}
													</td>
													<td>
														<div v-if="giver.status.status=='Timed Out'" class="statusTag statusTagDanger subBtn">
		                                            	Timed Out
			                                        	</div>
														<div v-if="giver.status.status=='Not Started'" class="statusTag statusTagDarkDanger subBtn">
			                                            	Not Started
			                                        	</div>
														<div v-if="giver.status.status=='Ongoing'" class="statusTag statusTagWarning subBtn">
			                                            	Ongoing
			                                        	</div>
														<div v-if="giver.status.status=='Submitted'" class="statusTag statusTagSuccess subBtn">
				                                            Submitted
				                                        </div>
														<div class="marginTop10">{{giver.status.tally}}</div>
													</td>
													<td>
														{{giver.response}}
													</td>
												</tr>
											</template>
										</template>
									</tr>
								</tbody>
							</table> -->
						</template>

						<!-- Team Evaluation (Free Response) -->
						
						<template v-if="instructorData.question.type=='openended' && (questionSelection&&instructorSelection)">
							<table class="table marginTop20">
								<thead>
									<tr>
										<th class="width150px">RECEIVER</th>
										<th class="width150px">GIVER</th>
										<th class="width150px"> GIVER STATUS</th>
										<th>EVALUATION GIVEN</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<tr class="thisRow">
											<td :rowspan="instructorData.instructorEvaluation.givers.length">
												{{instructorData.instructorEvaluation.receiver}}
											</td>
											<template v-if="instructorData.instructorEvaluation.givers.length!=0">
												<td>
													{{instructorData.instructorEvaluation.givers[0].giver}}
												</td>
												<td>
													<div v-if="instructorData.instructorEvaluation.givers[0].status.status=='Timed Out'" class="tableStatusTag tableStatusTag-danger">
	                                            	Timed Out
		                                        	</div>
													<div v-if="instructorData.instructorEvaluation.givers[0].status.status=='Not Started'" class="tableStatusTag tableStatusTag-danger">
		                                            	Not Started
		                                        	</div>
													<div v-if="instructorData.instructorEvaluation.givers[0].status.status=='Ongoing'" class="tableStatusTag tableStatusTag-warning">
		                                            	Ongoing
		                                        	</div>
													<div v-if="instructorData.instructorEvaluation.givers[0].status.status=='Submitted'" class="tableStatusTag tableStatusTag-success">
			                                            Submitted
			                                        </div>
													<div class="marginTop10">{{instructorData.instructorEvaluation.givers[0].status.tally}}</div>
												</td>
												<td><div v-html="filterProfanity(instructorData.instructorEvaluation.givers[0].response)"></div></td>
											</template>
										</tr>
										<template v-if="instructorData.instructorEvaluation.givers.length>1">
											<template v-for="(giver,giver_idx) in instructorData.instructorEvaluation.givers.slice(1)">
												<tr class="thisRow">
													<td>
														<span class="whiteSpacePreWrap" v-html="giver.giver"></span>
														<!-- {{giver.giver}} -->
													</td>
													<td>
														<div v-if="giver.status.status=='Timed Out'" class="tableStatusTag tableStatusTag-danger">
		                                            	Timed Out
			                                        	</div>
														<div v-if="giver.status.status=='Not Started'" class="tableStatusTag tableStatusTag-danger">
			                                            	Not Started
			                                        	</div>
														<div v-if="giver.status.status=='Ongoing'" class="tableStatusTag tableStatusTag-warning">
			                                            	Ongoing
			                                        	</div>
														<div v-if="giver.status.status=='Submitted'" class="tableStatusTag tableStatusTag-success">
				                                            Submitted
				                                        </div>
														<div class="marginTop10">{{giver.status.tally}}</div>
													</td>
													<td class="wordBreakWord whiteSpacePreWrap" v-html="filterProfanity(giver.response)">
													</td>
												</tr>
											</template>
										</template>
									</tr>
								</tbody>
							</table>
						</template>

					</div>

					<div id="michelsenMethod" :class="{'active':tab=='michelsenMethod'}" class="tab-pane fade in">
						<div v-if="michelsenData.michelsenPoints.length!=0" class="table-responsive newEvaluationTable">
							<table class="table">
								<thead>
									<tr>
										<th rowspan="2" class="width150px"> Total Points Received By </th>
										<th rowspan="2" class="width150px"> Status </th>
										<th :colspan="michelsenData.michelsenPoints.length"> Total Points Received From </th>
										<th rowspan="2"> Total </th>
										<th rowspan="2"> Avg </th>
										<th rowspan="2"> Team Avg </th>
										<th rowspan="2"> PAF <span
                                            class="badge badge-pill badge-info"
											style="display:inline"
                                        >
                                            BETA
                                        </span></th>
										<th rowspan="2"> SAPA <span
                                            class="badge badge-pill badge-info"
											style="display:inline"
                                        >
                                            BETA
                                        </span></th>
									</tr>
									<tr>
										<template v-for="(individual,idx) in michelsenData.michelsenPoints">
											<th class="whiteSpaceNowrap"> {{individual.receiver}} </th>
										</template>
									</tr>
								</thead>

								<tbody>
									<template v-for="(individual,idx) in michelsenData.michelsenPoints.slice(0,1)">
										<tr>
											<td>
												{{individual.receiver}}
											</td>

											<td>
												<div v-if="individual.status.status=='Timed Out'" class="tableStatusTag tableStatusTag-danger">
													Timed Out
												</div>
												<div v-if="individual.status.status=='Not Started'" class="tableStatusTag tableStatusTag-danger">
													Not Started
												</div>
												<div v-if="individual.status.status=='Ongoing'" class="tableStatusTag tableStatusTag-warning">
													Ongoing
												</div>
												<div v-if="individual.status.status=='Submitted'" class="tableStatusTag tableStatusTag-success">
													Submitted
												</div>
												<div v-if="individual.status.tally" class="marginTop10">{{individual.status.tally}}</div>
											</td>

											<template v-for="(giver,giver_idx) in individual.givers">
												<td :class="{'disabled':michelsenData.michelsenPoints[giver_idx].receiver == individual.receiver}">
													{{lookForPoint(idx, michelsenData.michelsenPoints[giver_idx].receiver)}}
												</td>
											</template>

											<td>
												{{ totalPoint(idx) }}
											</td>

											<td>
												{{individual.receiverAvg.toFixed(2)}}
											</td>

											<template v-if="individual.givers.length!=0">
												<td :rowspan="individual.givers.length">
													{{individual.teamMean.toFixed(2)}}
												</td>
											</template>
											<td> {{individual.paf}} </td>
											<td :class="{'disabled':!testObj.settings.allowToGiveThemselvesPoints}"> {{individual.sapa}} </td>
										</tr>
									</template>

									<template v-for="(individual,idx) in michelsenData.michelsenPoints.slice(1)">
										<tr class="notFirstRow">
											<td>
												{{individual.receiver}}
											</td>

											<td>
												<div v-if="individual.status.status=='Timed Out'" class="tableStatusTag tableStatusTag-danger">
													Timed Out
												</div>
												<div v-if="individual.status.status=='Not Started'" class="tableStatusTag tableStatusTag-danger">
													Not Started
												</div>
												<div v-if="individual.status.status=='Ongoing'" class="tableStatusTag tableStatusTag-warning">
													Ongoing
												</div>
												<div v-if="individual.status.status=='Submitted'" class="tableStatusTag tableStatusTag-success">
													Submitted
												</div>
												<div v-if="individual.status.tally" class="marginTop10">{{individual.status.tally}}</div>
											</td>

											<template v-for="(giver,giver_idx) in individual.givers">
												<td :class="{'disabled':michelsenData.michelsenPoints[giver_idx].receiver == individual.receiver}">
													{{ lookForPoint(idx + 1, michelsenData.michelsenPoints[giver_idx].receiver) }}
												</td>
											</template>

											<td>
												{{ totalPoint( idx + 1 ) }}
											</td>

											<td>
												{{individual.receiverAvg.toFixed(2)}}
											</td>
											<td> {{individual.paf}} </td>
											<td :class="{'disabled':!testObj.settings.allowToGiveThemselvesPoints}"> {{individual.sapa}} </td>
										</tr>
									</template>
								</tbody>
							</table>
						</div> 

						<!-- <table v-if="michelsenData.michelsenPoints.length!=0" class="table marginTop20">
							<thead>
								<tr>
									<th class="width150px">RECEIVER</th>
									<th class="width150px">STATUS</th>
									<th class="width150px">GIVER</th>
									<th>POINTS GIVEN</th>
									<th>RECEIVER AVG.</th>
									<th>TEAM MEAN</th>
								</tr>
							</thead>
							<tbody>
								<template v-for="(individual,idx) in michelsenData.michelsenPoints">
									<tr>
										<tr class="thisRow">
											<td :rowspan="individual.givers.length">
												{{individual.receiver}}
											</td>
											<td :rowspan="individual.givers.length">
												<div v-if="individual.status.status=='Timed Out'" class="statusTag statusTagDanger subBtn">
	                                            	Timed Out
	                                        	</div>
												<div v-if="individual.status.status=='Not Started'" class="statusTag statusTagDarkDanger subBtn">
	                                            	Not Started
	                                        	</div>
												<div v-if="individual.status.status=='Ongoing'" class="statusTag statusTagWarning subBtn">
	                                            	Ongoing
	                                        	</div>
												<div v-if="individual.status.status=='Submitted'" class="statusTag statusTagSuccess subBtn">
		                                            Submitted
		                                        </div>
												<div v-if="individual.status.tally" class="marginTop10">{{individual.status.tally}}</div>
											</td>
											<template v-if="individual.givers.length!=0">
												<td  :class="{'disabled':individual.givers[0].giver==individual.receiver}">
													{{individual.givers[0].giver}}
												</td>
												<td  :class="{'disabled':individual.givers[0].giver==individual.receiver}">
													{{individual.givers[0].point}}
												</td>
												<td :rowspan="individual.givers.length">
													{{individual.receiverAvg}}
												</td>
												<td :rowspan="individual.givers.length">
													{{individual.teamMean}}
												</td>
											</template>
										</tr>
										<template v-if="individual.givers.length>1">
											<template v-for="(giver,giver_idx) in individual.givers.slice(1)">
												<tr>
													<td :class="{'disabled':giver.giver==individual.receiver}">
														{{giver.giver}}
													</td>
													<td :class="{'disabled':giver.giver==individual.receiver}">
														{{giver.point}}
													</td>
												</tr>
											</template>
										</template>	
									</tr>
								</template>
							</tbody>
						</table> -->
					</div>

				</div>
			</template>
		</kr-panel>
		
		<div
id="evaluationAnalysisQuestionInfoModal" class="modal" style="z-index:50001!important;" 
      tabindex="-1"
      role="dialog"
      aria-labelledby="evaluationAnalysisQuestionInfoModal-title">
		  	<div class="modal-dialog">
			    <!-- Modal content-->
			    <div class="modal-content">
					<div class="modal-header">
						<button
						type="button"
						class="close"
						data-dismiss="modal"
						aria-label="Close Modal"
						>
							<i class="fa-solid fa-xmark" aria-hidden="true"></i>
						</button>

						<h2 id="evaluationAnalysisQuestionInfoModal-title" class="modal-title">
							<i class="fa fa-arrows marginRight8" aria-hidden="true"></i>View Question
						</h2>
					</div>
			      	<div class="modal-body">
				    	<question-display :question="previewQuestion"></question-display>
				    	<div class="col-xs-12 buttonGroup marginTop30">
							<button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Close">Close</button>
						</div>
			      	</div>
			      <div class="modal-footer">
			      	<!-- <div style="font-weight:bold;font-size:12px;text-align:left;">QUESTION LAST UPDATED ON</div>
			      	<div style="font-size:14px;text-align:left;margin-top:10px;">{{convertToReadableDate(previewQuestion.lastModified,'LLLL').date}}</div>
			      	<div style="margin-top:20px;" v-if="!previewQuestion.isTrashed">
				        <button @click.prevent="editQuestion(previewQuestion)" class="btn btn-secondary btn-action hidden-xs hidden-sm modalEditBtn" style="width:70%;line-height:1;padding:10px;margin:0;" data-dismiss="modal"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit Question</button>
				        <button class="btn btn-danger hidden-xs hidden-sm" style="width:28%;height:36px;" @click.prevent="remove(previewQuestion.uuid)"><i class="fa fa-trash-o"></i> Delete</button>
				         <button @click.prevent="editQuestion(previewQuestion)" class="btn btn-secondary btn-action hidden-md hidden-lg modalEditBtn" style="width:100%;line-height:1;padding:10px;margin-bottom:10px;"><i class="fa fa-pencil-square-o" aria-hidden="true" data-dismiss="modal"></i> Edit Question</button>
				        <button class="btn deleteBtn hidden-md hidden-lg" style="width:100%;height:36px;" @click.prevent="remove(previewQuestion.uuid)"><i class="fa fa-trash-o"></i> Delete</button>
				    </div>
				    <div style="margin-top:20px;" v-else>
				        <button class="btn btn-outline-info btn-action" style="width:100%;line-height:1;padding:10px;;margin-bottom:10px;" @click.prevent="reinstate(previewQuestion.uuid)"><i class="fa fa-refresh" aria-hidden="true"></i> Reinstate</button>
				    </div> -->
			      </div>
			    </div>
	  		</div>             		
		</div>
	</div>
</template>
<script>
export default {
  props: ["testObj"],
  data() {
    return {
      component_done_loading: true,
      tab: "",
      evaluationDetailedView: false,
      individualData: {
        question: {},
        individualEvaluation: { givers: [], status: {} },
      },
      teamData: { question: {}, teamEvaluation: { givers: [] } },
      instructorData: { question: {}, instructorEvaluation: { givers: [] } },
      michelsenData: { question: {}, michelsenPoints: [] },

      teams: [],
      instructors: [],
      questions: [],
      teamSelection: "",
      questionSelection: "",
      instructorSelection: "",
      previewQuestion: {},
    };
  },
  computed: {
    canSearch() {
      if (this.tab == "evaIndividual") {
        if (this.questionSelection != "" && this.teamSelection != "") {
          return true;
        }
      } else if (this.tab == "evaOnTeam") {
        if (this.questionSelection != "" && this.teamSelection != "") {
          return true;
        }
      } else if (this.tab == "evaOnInstructor") {
        if (this.questionSelection != "" && this.instructorSelection != "") {
          return true;
        }
      } else if (this.tab == "michelsenMethod") {
        if (this.teamSelection != "") {
          return true;
        }
      }
      return false;
    },
  },
  watch: {
    tab: {
      handler: function () {
        let target = "peer";
        if (this.tab == "evaOnTeam") {
          target = "team";
        } else if (this.tab == "evaOnInstructor") {
          target = "instructor";
        }
        var that = this;
        axios
          .get(`/tests/${this.testObj.uuid}/questions?target=${target}`)
          .then(function (response) {
            that.questions = response.data;
          });
        this.teamSelection = "";
        this.questionSelection = "";
        this.instructorSelection = "";
      },
      deep: true,
    },
	'testObj.status':{
		handler:function(){
			if(['completed','ended'].includes(this.testObj.status)){
				this.fetch();//refetch report
			}
		},
		deep:true
	}
  },
  created() {
    this.init();
  },
  mounted() {
    let tab = "";
    if (
      this.testObj.evaluationSettings.evaluateThemselves ||
      this.testObj.evaluationSettings.evaluateTeammates
    ) {
      tab = "evaIndividual";
    } else if (this.testObj.evaluationSettings.evaluateTeam) {
      tab = "evaOnTeam";
    } else if (this.testObj.evaluationSettings.evaluateInstructor) {
      tab = "evaOnInstructor";
    } else if (this.testObj.settings.enableMichaelsenPointDistribution) {
      tab = "michelsenMethod";
    }
    this.tab = _.get(this, "$route.query.tab", tab);
  },
  methods: {
    openPreviewQuestionModal(question) {
      this.previewQuestion = question;
      this.previewQuestion.questionIsHTML = true;
      $("#evaluationAnalysisQuestionInfoModal").modal("show");
    },
    init() {
      var that = this;
      axios
        .all([
          axios.get(`/tests/${this.testObj.uuid}/teams`),
          axios.get(`/tests/${this.testObj.uuid}/instructors`),
        ])
        .then(
          axios.spread(function (teams, instructors) {
            that.teams = teams.data;
            that.instructors = instructors.data;
          })
        );
      //axios.all([])
    },
    fetch() {
      if (this.tab == "evaIndividual") {
        this.fetchIndividualEvaluation();
      } else if (this.tab == "evaOnTeam") {
        this.fetchTeamEvaluation();
      } else if (this.tab == "evaOnInstructor") {
        this.fetchInstructorEvaluation();
      } else if (this.tab == "michelsenMethod") {
        this.fetchMichelsenEvaluation();
      }
    },
    fetchMichelsenEvaluation() {
      var that = this;
      axios
        .post(
          `/tests/${this.testObj.uuid}/dashboard/evaluation/michelsen-method`,
          { teamUuid: this.teamSelection }
        )
        .then(function (response) {
          that.michelsenData = response.data.data;
        });
    },
    fetchIndividualEvaluation() {
      var that = this;
      axios
        .post(`/tests/${this.testObj.uuid}/dashboard/evaluation/individual`, {
          questionUuid: this.questionSelection,
          teamUuid: this.teamSelection,
        })
        .then(function (response) {
          that.individualData = response.data.data;
        });
    },
    fetchTeamEvaluation() {
      var that = this;
      axios
        .post(`/tests/${this.testObj.uuid}/dashboard/evaluation/team`, {
          questionUuid: this.questionSelection,
          teamUuid: this.teamSelection,
        })
        .then(function (response) {
          that.teamData = response.data.data;
        });
    },
    fetchInstructorEvaluation() {
      var that = this;
      axios
        .post(`/tests/${this.testObj.uuid}/dashboard/evaluation/instructor`, {
          questionUuid: this.questionSelection,
          userUuid: this.instructorSelection,
        })
        .then(function (response) {
          that.instructorData = response.data.data;
        });
    },
    lookForPoint(idx, receiver) {
      var data = this.michelsenData.michelsenPoints[idx].givers;
      var index;

      index = data.findIndex((x) => x.giver === receiver);
      return data[index].point;
    },
    totalPoint(idx) {
      var data = this.michelsenData.michelsenPoints[idx].givers;
      var sum = 0;

      data.forEach(function (value) {
        if (value.point == "-" || value.point == "") {
          sum += parseFloat(0);
        } else {
          sum += parseFloat(value.point);
        }
      });

      return sum;
    },
    lookForWeight(idx, receiver) {
      var data = this.individualData.individualEvaluation[idx].givers;
      var index;
      var str;

      index = data.findIndex((x) => x.giver === receiver);
      str = data[index].response;

      if (str != null) {
        if (str != "-") {
          if ($.isNumeric(str) == false) {
            str = str.slice(0, str.indexOf("("));
          }
        }
      } else {
        str = "-";
      }

      return str;
    },
    lookForTeamWeights(idx) {
      var data = this.teamData.teamEvaluation.givers;
      var str;

      str = data[idx].response;

      if (str != null) {
        if (str != "-") {
          if ($.isNumeric(str) == false) {
            str = str.slice(0, str.indexOf("("));
          }
        }
      } else {
        str = "-";
      }

      return str;
    },
    lookForInstructorWeights(idx) {
      var data = this.instructorData.instructorEvaluation.givers;
      var str;

      str = data[idx].response;

      if (str != null) {
        if (str != "-") {
          if ($.isNumeric(str) == false) {
            str = str.slice(0, str.indexOf("("));
          }
        }
      } else {
        str = "-";
      }

      return str;
    },
    lookForLabel(idx, receiver) {
      var data = this.individualData.individualEvaluation[idx].givers;
      var index;
      var str;

      index = data.findIndex((x) => x.giver === receiver);
      str = data[index].response;

      if (str != null) {
        if (str != "-") {
          if ($.isNumeric(str) == false) {
            str = str.match(/\(([^)]+)\)/)[1];
          }
        }
      } else {
        str = "-";
      }

      return str;
    },
    lookForTeamLabels(idx) {
      var data = this.teamData.teamEvaluation.givers;
      var str;

      str = data[idx].response;

      if (str != null) {
        if (str != "-") {
          if ($.isNumeric(str) == false) {
            str = str.match(/\(([^)]+)\)/)[1];
          }
        }
      } else {
        str = "-";
      }

      return str;
    },
    lookForInstructorLabels(idx) {
      var data = this.instructorData.instructorEvaluation.givers;
      var str;

      str = data[idx].response;

      if (str != null) {
        if (str != "-") {
          if ($.isNumeric(str) == false) {
            str = str.match(/\(([^)]+)\)/)[1];
          }
        }
      } else {
        str = "-";
      }

      return str;
    },
    lookForRubric(idx, receiver) {
      var data = this.individualData.individualEvaluation[idx].givers;
      var index;
      var str;

      var descriptionIndex;

      index = data.findIndex((x) => x.giver === receiver);
      str = data[index].response;

      if (str != null) {
        if (str != "-") {
          if ($.isNumeric(str) == false) {
            str = str.slice(0, str.indexOf("("));
          }
        }
      }

      if (str != null) {
        if (str != "-") {
          descriptionIndex =
            this.individualData.question.ratingSettings.findIndex(
              (x) => x.weight === parseFloat(str)
            );

          if (
            this.individualData.question.ratingSettings[descriptionIndex]
              .rubric == null
          ) {
            str = "-";
          } else {
            str =
              this.individualData.question.ratingSettings[descriptionIndex]
                .rubric;
          }
        }
      } else {
        str = "-";
      }

      return str;
    },
    lookForTeamRubrics(idx, receiver) {
      var data = this.teamData.teamEvaluation.givers;
      var str;

      var descriptionIndex;

      str = data[idx].response;

      if (str != null) {
        if (str != "-") {
          if ($.isNumeric(str) == false) {
            str = str.slice(0, str.indexOf("("));
          }
        }
      }

      if (str != null) {
        if (str != "-") {
          descriptionIndex = this.teamData.question.ratingSettings.findIndex(
            (x) => x.weight === parseFloat(str)
          );

          if (
            this.teamData.question.ratingSettings[descriptionIndex].rubric ==
            null
          ) {
            str = "-";
          } else {
            str =
              this.teamData.question.ratingSettings[descriptionIndex].rubric;
          }
        }
      } else {
        str = "-";
      }

      return str;
    },
    lookForInstructorRubrics(idx, receiver) {
      var data = this.instructorData.instructorEvaluation.givers;
      var str;

      var descriptionIndex;

      str = data[idx].response;

      if (str != null) {
        if (str != "-") {
          if ($.isNumeric(str) == false) {
            str = str.slice(0, str.indexOf("("));
          }
        }
      }

      if (str != null) {
        if (str != "-") {
          descriptionIndex =
            this.instructorData.question.ratingSettings.findIndex(
              (x) => x.weight === parseFloat(str)
            );

          if (
            this.instructorData.question.ratingSettings[descriptionIndex]
              .rubric == null
          ) {
            str = "-";
          } else {
            str =
              this.instructorData.question.ratingSettings[descriptionIndex]
                .rubric;
          }
        }
      } else {
        str = "-";
      }

      return str;
    },
    totalEvaluationScale(idx) {
      var data = this.individualData.individualEvaluation[idx].givers;
      var sum = 0;
      var str;

      data.forEach(function (value) {
        if (
          value.response == "-" ||
          value.response == "" ||
          value.response == null
        ) {
          sum += parseFloat(0);
        } else {
          str = value.response;

          if ($.isNumeric(str) == false) {
            sum += parseFloat(str.slice(0, str.indexOf("(")));
          } else {
            sum += parseFloat(str);
          }
        }
      });

      return sum;
    },
  },
  components: {
    "question-display":
      require(`./../../../../../../questions/partials/question-display.vue`)
        .default,
  },
};
</script>

<style lang="scss">

// #evaIndividual table td:last-child,
// #evaOnTeam table td:last-child, 
// #evaOnInstructor table td:last-child,
// #michelsenMethod table td:last-child {
// 	border-right: solid 1px #d8d8d8;
// }

// #evaIndividual table .thisRow td:last-child,
// #evaOnTeam table .thisRow td:last-child,
// #evaOnInstructor table .thisRow td:last-child,
// #michelsenMethod table .thisRow td:last-child {
// 	border-right: none;
// }

#evaIndividual,
#evaOnTeam,
#evaOnInstructor,
#michelsenMethod {
	table {
		td:last-child {
			border-right: solid 1px #d8d8d8;	
		}

		.thisRow {
			td:last-child {
				border-right: none;
			}	
		}
	}
}

.width150px {
	width:150px;
	min-width:150px;
	max-width:150px;
}

td.disabled {
	background-color:#c5d0de;
}

.newEvaluationTable {
	border: solid 1px #d8d8d8;
    border-radius: 3px;
	box-shadow: 0 0 2px 0 rgb(202 202 202 / 50%);

	table {
		border:none;
		border-collapse: collapse;

		thead {
			tr {
				border:none;
				box-shadow:none;

				&:first-child {
					th:last-child {
						border-right:none;
					}
				}
			}
			
			th {
				border: solid 1px #d8d8d8;
				box-shadow:none;
				border-left:none;
				font-size: 12px;
				padding-top:6px;
				padding-bottom:6px;
			}

			th[rowspan] {
				background-image: linear-gradient(to bottom, #ffffff, #eff2f6);
			}

			tr {
				&:nth-child(2) {
					text-transform:none;
					background: #fff;
				}

				&:first-child {
					text-transform: uppercase;
				}
			}
		}

		th, td {
			white-space:normal;

			&.whiteSpaceNowrap {
				white-space: nowrap;
			}
		}

		td:last-child {
			border-right:none;
		}

		.notFirstRow {
			td:last-child {
				border-right: solid 1px #d8d8d8;
			}
		}
	}
}

</style>
