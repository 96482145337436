<template>
    <div class="row">
        <!-- <div :class="{'hoverDisplayQns':canHover}">
			<span class="onHover" v-show="canHover">
				<button class="btn editBtn" @click.prevent="emit('edit')"><i class="fa fa-pencil-square-o"></i> Edit</button>
				<button class="btn duplicateBtn"  @click.prevent="emit('duplicate')"><i class="fa fa-files-o"></i> Duplicate</button>
				<button class="btn deleteBtn"><i class="fa fa-trash-o"></i> Delete</button>
			</span>
			<div class="hoverDisplayQnsInner">
				<div class="question-box">
	        		<div class="question-info" style="display:flex;flex-wrap:wrap;justify-content:space-between;">
	        			<span class="question-no" v-if="question_no_label!=null">Question {{question_no_label+1}}</span>
	        			<div class="notHover qnsBuilderLabel" :class="{'notHover':!canHover}">
	        				<span class="displayTopic" style="text-transform: uppercase;" >{{question.topic}}</span>
	        				<span class="displayDifficultyLvl">LEVEL {{question.difficultyLevel}}</span>
	        			</div>
	        		</div>	

			    	<div class="question-desc" style="margin-top:10px;">
		        		<template v-if="question.questionIsHTML">
			            	<span v-html="question.question" style="white-space: pre-wrap;"></span>
			        	</template>
				        <template v-else>
				            <span>{{question.question}}</span>
				        </template>
		        	</div>
					<div class="options" v-if="question.type=='mcqs'||question.type=='mcqm'||question.type=='binary'">
		    			<template v-for="(option,idx) in question.options">
		    				 <div class="option displayingOptions">
				                <div class="description">
		                            <div v-if="option.isCorrect==true" >
		                                <div style="display:inline-flex;align-items:flex-start;">
		                                    <div>
			                                	<i v-if="question.type=='mcqs'" class="fa fa-check-circle"></i>
			                                	<i v-if="question.type=='mcqm'" class="fa fa-check-square"></i>
			                                </div>
			                                <div style="display:inline-flex;align-items:flex-start;">
			                                    <div style="margin-right:5px;font-weight:bold;">{{option.key}}.</div>
			                                    <template v-if="option.contentIsHTML">
			                                            <div v-html="option.content" style="white-space: pre-wrap;"></div>
			                                    </template>
			                                    <template v-else>
			                                        <div>{{option.content}}</div>
			                                    </template>
			                                </div>
		                                </div>
		                            </div>
		                            <div v-else>
		                                <div class="displayingQnsOption" style="display:inline-flex;align-items:flex-start;">
		                                	<div>
			                                	<input v-if="question.type=='mcqs'" type="radio" style="margin:0;margin-right:10px;" disabled>
			                                	<input v-if="question.type=='mcqm'" type="checkbox" style="margin:0;margin-right:10px;" disabled>
			                                </div>
			                                <div style="display:inline-flex;">
			                                    <div style="margin-right:5px;font-weight:bold;">{{option.key}}.</div>
			                                    <template v-if="option.contentIsHTML">
			                                            <div v-html="option.content" style="white-space: pre-wrap;"></div>
			                                    </template>
			                                    <template v-else>
			                                        <div>{{option.content}}</div>
			                                    </template>
			                                </div>
		                                </div>
		                            </div>      
					            </div>	
			            	</div>
		    			</template>
		        	</div>

		        	<div v-if="question.type=='openended'" style="margin-top:20px;">
		        		<textarea style="width:100%;height:70px;resize: none;border-radius: 3px;background-color: #ffffff;border: solid 1px #cacaca;" disabled></textarea>
		        		<span class="minOfCharacters">MIN 100 CHARACTERS</span>
		        	</div>

		        	<div v-if="question.type=='rating'" style="margin-top:20px;">
		        		<div class="hidden-xs hidden-sm" style="width:100%;overflow:hidden;overflow-x:scroll">
			        		<table style="width:100%;">
						      <tr >
						      	<template v-for="(label,idx) in question.labels">
							        <td class="ratingQnsWidthTd" style="padding:0;border:0;">
							          <table style="width:100%;min-width:200px;text-align:center;">
							            <tr>
							              <td style="padding:0;border:0;">
							              	{{label.weight}}  
							              </td>
							            </tr>
							            <tr>
							              <td style="margin-top:5px;padding:0;border:0;">
							                {{label.label}}
							              </td>
							            </tr>
							            <tr v-if="label.rubric!=''">
							              <td style="border: solid 1px #cacaca;margin-top:10px;padding:5px 0;">
							                {{label.rubric}}
							              </td>
							            </tr>
							            <tr>
							              <td style="background-color: #f8f8f8;margin-top:10px;padding:10px 0;border:0;">
							                <input type="radio" disabled>
							              </td>
							            </tr>
							          </table>
							        </td>
							    </template>

						      </tr>
						    </table>
						</div>

						<div class="hidden-md hidden-lg">
							<template v-for="(label,idx) in question.labels">
			        			<div class="ratingMobileOptionsDefault">
			        				<span class="mobileRatingTopOption">
				        				<span class="ratingRadioButton">
					        				<input type="radio" disabled>
					        			</span>
					        			<span>{{label.weight}}&nbsp <b>{{label.label}}</b></span>
					        		</span>

					        		<span class="mobileRatingBtmOption" v-if="label.rubric!=''">
				        				<span>
					        				{{label.rubric}}
					        			</span>
					        			<span class="textButton">
											SHOW MORE <i class="fa fa-caret-down"></i>
										</span>
					        		</span>
			        			</div>
		        			</template>
		        		</div>
		        		</div>

		        	</div>
		        </div>
			</div>		
	    </div> -->
        <div>
            <div tabindex="0">
                <caption class="visible-hidden">
                    Question:
                </caption>
                <kr-math :input="question.question" :safe="!question.questionIsHTML" class="wordBreakWord" />
            </div>

            <!-- point evaluation -->
            <!-- <div class="gridBase">
        		<table class="table marginTop20">
					<thead>
						<tr>
							<th>
								TEAMMATE
							</th>
							<th>
								POINTS ( Remaining: <span class="errorColor">5</span> )
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<div class="flexOnly align-items">
									<div class="profile_image marginRight5">
					                    <template>
					                        <div class="actual-image">
					                        </div>
					                    </template>
					                </div>
									<div>Sebastian Prescot (Me)</div>
								</div>
							</td>
							<td>
								<div class="numberInput">
									<input type="number" class="form-control">
						        	<span class="quantity-nav">
						        		<div class="quantity-button quantity-up" >
						        			<i class="fas fa-plus" aria-hidden="true"></i>
						        		</div>
						        		<div class="quantity-button quantity-down">
						        			<i class="fas fa-minus" aria-hidden="true"></i>
						        		</div>
						        	</span>
								</div>	
							</td>
						</tr>
						<tr>
							<td>
								<div class="flexOnly align-items">
									<div class="profile_image marginRight5">
					                    <template>
					                        <div class="actual-image">
					                        </div>
					                    </template>
					                </div>
									<div>Sebastian Prescot (Me)</div>
								</div>
							</td>
							<td>
								<div class="numberInput">
									<input type="number" class="form-control">
						        	<span class="quantity-nav">
						        		<div class="quantity-button quantity-up" >
						        			<i class="fas fa-plus" aria-hidden="true"></i>
						        		</div>
						        		<div class="quantity-button quantity-down">
						        			<i class="fas fa-minus" aria-hidden="true"></i>
						        		</div>
						        	</span>
								</div>	
							</td>
						</tr>
						<tr>
							<td>
								<div class="flexOnly align-items">
									<div class="profile_image marginRight5">
					                    <template>
					                        <div class="actual-image">
					                        </div>
					                    </template>
					                </div>
									<div>Sebastian Prescot (Me)</div>
								</div>
							</td>
							<td>
								<div class="numberInput">
									<input type="number" class="form-control">
						        	<span class="quantity-nav">
						        		<div class="quantity-button quantity-up" >
						        			<i class="fas fa-plus" aria-hidden="true"></i>
						        		</div>
						        		<div class="quantity-button quantity-down">
						        			<i class="fas fa-minus" aria-hidden="true"></i>
						        		</div>
						        	</span>
								</div>	
							</td>
						</tr>
						<tr>
							<td>
								<div class="flexOnly align-items">
									<div class="profile_image marginRight5">
					                    <template>
					                        <div class="actual-image">
					                        </div>
					                    </template>
					                </div>
									<div>Sebastian Prescot (Me)</div>
								</div>
							</td>
							<td>
								<div class="numberInput">
									<input type="number" class="form-control">
						        	<span class="quantity-nav">
						        		<div class="quantity-button quantity-up" >
						        			<i class="fas fa-plus" aria-hidden="true"></i>
						        		</div>
						        		<div class="quantity-button quantity-down">
						        			<i class="fas fa-minus" aria-hidden="true"></i>
						        		</div>
						        	</span>
								</div>	
							</td>
						</tr>
					</tbody>
				</table>
        	</div> -->

            <!-- evaluation -->
            <!-- <div class="gridBase">
        		<div id="exTab1" class="container marginTop20">	
        			<div style="overflow:scroll;">
						<ul class="nav nav-pills flexOnly">
			            	<li class="active">
								<a  href="#1a" data-toggle="tab">
									<div class="flexOnly align-items">
										<div class="profile_image marginRight5">
						                    <template>
						                        <div class="actual-image">
						                        </div>
						                    </template>
						                </div>
										<div>Sebastian Prescot</div>
									</div>
								</a>
							</li>
							<li>
								<a href="#2a" data-toggle="tab">
									<div class="flexOnly align-items">
										<div class="profile_image marginRight5">
						                    <template>
						                        <div class="actual-image">
						                        </div>
						                    </template>
						                </div>
										<div>Sebastian Prescot</div>
									</div>
								</a>
							</li>
							<li>
								<a href="#3a" data-toggle="tab">
									<div class="flexOnly align-items">
										<div class="profile_image marginRight5">
						                    <template>
						                        <div class="actual-image">
						                        </div>
						                    </template>
						                </div>
										<div>Sebastian Prescot</div>
									</div>
								</a>
							</li>
							<li>
								<a href="#4a" data-toggle="tab">
									<div class="flexOnly align-items">
										<div class="profile_image marginRight5">
						                    <template>
						                        <div class="actual-image">
						                        </div>
						                    </template>
						                </div>
										<div class="warningColor">Sebastian Prescot (Me)</div>
									</div>
								</a>
							</li>
			            </ul>
					</div>
					<div class="tab-content clearfix">
						<div class="tab-pane active" id="1a">
							<textarea placeholder="Input your answers here" row="5" cols="50"></textarea>
							<div class="fontSize12 marginTop10">
								Min no. of words: <span class="darkGreen">120</span>/100
							</div>
							<div class="col-xs-12 alignRight">
								<div class="inlineFlex align-items">
									<div>
										1 / 5 Completed
									</div>
									<button class="btn btn-default mainBtn">
										<i class="fas fa-caret-left"></i>&nbsp;Previous
									</button>
									<button class="btn btn-default mainBtn">
										Next&nbsp;<i class="fas fa-caret-right"></i>
									</button>
								</div>		
							</div>
						</div>
						<div class="tab-pane" id="2a">
							<textarea placeholder="Input your answers here" row="5" cols="50"></textarea>
							<div class="fontSize12 marginTop10">
								Min no. of words: <span class="darkGreen">120</span>/100
							</div>
						</div>
						<div class="tab-pane" id="3a">
							<textarea placeholder="Input your answers here" row="5" cols="50"></textarea>
							<div class="fontSize12 marginTop10">
								Min no. of words: <span class="darkGreen">120</span>/100
							</div>
						</div>
						<div class="tab-pane" id="4a">
							<textarea placeholder="Input your answers here" row="5" cols="50"></textarea>
							<div class="fontSize12 marginTop10">
								Min no. of words: <span class="darkGreen">120</span>/100
							</div>
						</div>
					</div>
				</div>
			</div> -->
            <!-- grib base -->
            <div v-if="question.type == 'rating'" class="gridBase overFlowAuto">
                <table class="transparent table marginTop20">
                    <thead>
                        <tr>
                            <template v-for="(label, label_idx) in question.ratingSettings">
                                <th class="width150px">
                                    {{ label.weight }}
                                </th>
                            </template>
                        </tr>
                        <tr v-if="hasLabel">
                            <template v-for="(label, label_idx) in question.ratingSettings">
                                <th class="textWrap">
                                    {{ label.label }}
                                </th>
                            </template>
                        </tr>
                        <tr v-if="hasRubric">
                            <template v-for="(label, label_idx) in question.ratingSettings">
                                <th class="whiteSpacePreLine">
                                    <div>{{ label.rubric }}</div>
                                </th>
                            </template>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <template v-for="(label, label_idx) in question.ratingSettings">
                                <td>
                                    <div class="col-xs-12 gradient btn-default mainBtn radioField align-items justifyCenter flexOnly">
                                        <input type="radio" class="margin0" disabled />
                                        <!-- <label></label> -->
                                    </div>
                                </td>
                            </template>
                        </tr>
                    </tbody>
                </table>
            </div>
            <template v-if="question.type == 'mcqs' || question.type == 'mcqm' || question.type == 'binary'">
                <div tabindex="0" class="questionBankOptionsField">
                    <caption class="visible-hidden">
                        There are
                        {{
                            question.options.length
                        }}
                        answers choice
                    </caption>

                    <div
                        v-for="(option, idx) in question.options"
                        :key="option + '_' + idx"
                        :class="{
                            correctOption: option.isCorrect && showCorrectAnswer,
                            paddingLeft35px: !option.isCorrect && showCorrectAnswer,
                        }"
                        class="checkboxField flex marginTop10"
                    >
                        <caption v-if="option.isCorrect && showCorrectAnswer" class="visible-hidden">
                            option
                            {{
                                option.key
                            }}
                            is correct
                        </caption>

                        <div v-if="option.isCorrect && showCorrectAnswer" class="marginRight10">
                            <i v-if="question.type == 'mcqs'" class="fa fa-check-circle" aria-hidden="true" />
                            <i v-if="question.type == 'mcqm'" class="fa fa-check-square" aria-hidden="true" />
                        </div>
                        <label :for="question.uuid + '-' + option.key">
                            <span class="withOptionKey" :aria-label="'option ' + option.key"> {{ option.key }}. </span>
                            <span class="withAnswerKey">
                                <kr-math :input="option.content" :safe="!option.contentIsHTML" class="question" />
                            </span>
                        </label>
                    </div>
                </div>
            </template>

            <template v-if="question.type == 'vsa'">
                <div class="marginTop20">
                    <h3 class="fontBlack fs-18px marginBottom5">
                        <i class="fa fa-check-circle successColor marginRight8" aria-hidden="true" /><b>Correct Answers</b>
                    </h3>

                    <p>
                        <template v-if="question.vsaSettings.correctAnswers == null"> There is no correct answer </template>
                        <template v-for="(correctAnswer, idx) in question.vsaSettings.correctAnswers" v-else>
                            {{ correctAnswer }}
                            <template v-if="idx != question.vsaSettings.correctAnswers.length - 1"> , </template>
                        </template>
                    </p>
                </div>

                <div class="marginTop20">
                    <h3 class="fontBlack fs-18px marginBottom5">
                        <i class="fa fa-times-circle dangerColor marginRight8" aria-hidden="true" /><b>Wrong Answers</b>
                    </h3>
                    <p>
                        <template v-if="question.vsaSettings.wrongAnswers == null"> There is no wrong answer </template>
                        <template v-for="(wrongAnswer, idx) in question.vsaSettings.wrongAnswers" v-else>
                            {{ wrongAnswer }}
                            <template v-if="idx != question.vsaSettings.wrongAnswers.length - 1"> , </template>
                        </template>
                    </p>
                </div>
            </template>
            <!--<div class="col-xs-12 buttonGroup">
				<button class="btn btn-primary mainBtn">Edit</button>
				<button class="btn btn-default mainBtn" data-dismiss="modal">Close</button>
			</div>
			-->
        </div>
    </div>
</template>
<script>
export default {
    props: ['question', 'question_no_label', 'options'],
    data() {
        return {
            canHover: false,
            showCorrectAnswer: true,
        };
    },
    computed: {
        hasLabel() {
            if (this.question.type == 'rating') {
                var ratingSettings = this.question.ratingSettings ? this.question.ratingSettings : [];
                for (var i = 0; i < ratingSettings.length; i++) {
                    if (ratingSettings[i].label != '') {
                        return true;
                    }
                }
            }
            return false;
        },
        hasRubric() {
            if (this.question.type == 'rating') {
                var ratingSettings = this.question.ratingSettings ? this.question.ratingSettings : [];
                for (var i = 0; i < ratingSettings.length; i++) {
                    if (ratingSettings[i].rubric != '') {
                        return true;
                    }
                }
            }
            return false;
        },
    },
    created() {
        this.canHover = _.get(this.options, 'canHover', false);
        this.showCorrectAnswer = _.get(this.options, 'showCorrectAnswer', true);
    },
    methods: {
        emit(event, data = []) {
            this.$emit(event, data);
        },
    },
};
</script>
<style scoped lang="scss">
.qnsOptions {
    margin-top: 20px;
}

.qnsOptions.correctOption {
    margin-top: 15px;
}

.qnsOptions:first-child,
.qnsOptions.correctOption:first-child {
    margin-top: 0;
}

.optionKey {
    font-weight: bold;
}

.correctOption {
    padding: 10px;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #f3fffd;
    border: 1px solid #0f9b81;
}

.correctOption i {
    color: #0f9b81;
}

.gridBase table {
    table-layout: fixed;
    background: none;
}

.transparent.table,
table.transparent th,
table.transparent td {
    border: 0;
    box-shadow: none;
    text-align: center;
}

.transparent.table thead tr {
    background-image: none;
    background: none;
    box-shadow: none;
}

table.transparent tbody tr td {
    padding: 20px;
    text-align: left;
    font-weight: bold;
    vertical-align: middle;
}

table.transparent tbody tr:first-child td {
    padding: 20px 0 10px;
    text-align: left;
    font-weight: bold;
    vertical-align: middle;
}

table.transparent tbody tr:last-child td {
    padding-bottom: 0;
}

table.transparent td:last-child div {
    border-left: 0;
    border-right: 1px solid #d8d8d8;
    margin-left: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.transparent.table > thead > tr > th {
    vertical-align: middle;
}

.transparent.table > thead > tr:first-child > th {
    padding: 5px 20px 10px;
}

.transparent.table > thead > tr:last-child > th {
    padding: 5px 20px 0;
}

.transparent.table td:first-child div.gradient.btn-default {
    border-left: 1px solid #d8d8d8;
    border-right: 0;
    margin-left: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.transparent.table td:not(:first-child) div.gradient.btn-default {
    border-left: 0;
    border-right: 0;
    margin-left: 0;
    border-radius: 0;
    box-shadow: none;
}

.transparent.table td:last-child div.gradient.btn-default {
    border-left: 0;
    border-right: 1px solid #d8d8d8;
    margin-left: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.transparent.table td:nth-child(2):last-child div.gradient.btn-default {
    border: 1px solid #d8d8d8;
}

.transparent.table thead tr:nth-child(3) th {
    border: 1px solid #d8d8d8;
    border-right: 0;
    padding: 20px;
    text-transform: none;
}

.transparent.table thead tr:nth-child(3) th:last-child {
    border-right: 1px solid #d8d8d8;
}

.transparent.table thead tr:nth-child(3) {
    margin-bottom: 10px;
}

.gridBase .profile_image.marginRight5 {
    margin-right: 5px;
}

#exTab1 .nav-pills > li > a {
    width: 250px;
    border-radius: 0;
    padding: 20px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom: 1px solid #fff;
    border: solid 1px #d8d8d8;
    background-image: linear-gradient(to bottom, #ffffff, #f5f7f9);
    color: #222;
}

#exTab1 .nav-pills > li.active > a {
    border: 1px solid #1991eb;
    background-image: none;
    background: #fff;
    border-bottom: none;
    border-top: 3px solid #1991eb;
}

#exTab1 .tab-content {
    padding: 20px;
    border: 1px solid #1991eb;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background: #fff;
}

.numberInput {
    position: relative;
    width: 185px;
}

.quantity-nav {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 10px;
    color: #222;
    z-index: 4;
    height: 100%;
    max-height: 100%;
}

.quantity-nav .disabled {
    color: #dfe3e9;
    cursor: not-allowed;
}

.quantity-button {
    min-height: 18px;
    height: 50%;
    display: flex;
    align-items: center;
    width: 22px;
    justify-content: center;
    background-color: white !important;
    /* border: 1px solid #dfe3e9 !important; */
    border: 1px solid #767676 !important;
}

.quantity-button:last-child {
    /* border: 1px solid #dfe3e9 !important; */
    border-top: 1px solid #767676 !important;
}

.width150px {
    width: 150px;
}

.questionBankOptionsField {
    .checkboxField label {
        align-items: baseline;
    }

    .fa-check-circle,
    .fa-check-square {
        font-size: 18px;
    }
}
</style>
