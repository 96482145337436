<template>
    <div class="row flex">
        <!-- <div class="col-xs-12 marginBottom20">
			<test-progression :testObj="test"></test-progression>
			<team-leadership v-if="test.type=='trat'" :testObj="test"></team-leadership>
		</div> -->
        <div class="col-xs-12">
            <div class="timer_Div darkGreen flex align-items justifyCenter" :class="{ timerPaused: testObj.status == 'paused' }">
                <div>
                    <div class="timeText">HOURS</div>
                    <div class="time flex">
                        <div class="number-div">
                            {{ time.hours.charAt(0) }}
                        </div>
                        <div class="number-div">
                            {{ time.hours.charAt(1) }}
                        </div>
                    </div>
                </div>
                <div class="colon">:</div>
                <div>
                    <div class="timeText">MINUTES</div>
                    <div class="time flex">
                        <div class="number-div">
                            {{ time.minutes.charAt(0) }}
                        </div>
                        <div class="number-div">
                            {{ time.minutes.charAt(1) }}
                        </div>
                    </div>
                </div>
                <div class="colon">:</div>
                <div>
                    <div class="timeText">SECONDS</div>
                    <div class="time flex">
                        <div class="number-div">
                            {{ time.seconds.charAt(0) }}
                        </div>
                        <div class="number-div">
                            {{ time.seconds.charAt(1) }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-offset-2 col-md-8 test-progression testProgression">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Team</th>
                                <th>Progress</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(team, idx) in testObj.leaderboard">
                                <tr v-if="team.item.members.length != 0">
                                    <td>
                                        <button class="btn gradient btn-default" :aria-label="team.item.name" @click="openTeamInfoModal(team.item)">
                                            <i class="fa fa-users marginRight8" aria-hidden="true"></i>{{ team.item.name }}
                                        </button>
                                    </td>
                                    <td>
                                        <div class="flexSpaceBetween marginBottom10">
                                            <div>{{ team.progress.percent.toFixed(0) }}%</div>
                                            <div>
                                                <template v-if="team.item.status == 'Not Started'">
                                                    <div class="important fontBold">Not Started</div>
                                                </template>
                                                <template v-else-if="team.item.status == 'Submitted'">
                                                    <div class="textSuccess fontBold">Submitted</div>
                                                </template>
                                                <template v-else>
                                                    {{ team.progress.countAnsweredQuestions }}
                                                    /
                                                    {{ team.progress.countActivityQuestions }}
                                                    Questions Completed
                                                </template>
                                            </div>
                                        </div>
                                        <div class="bar">
                                            <div class="barProgress" :style="'width:' + team.progress.percent.toFixed(0) + '%'"></div>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div
            id="discussionTeamInfoModal"
            class="modal"
            style="z-index: 50001 !important"
            tabindex="-1"
            role="dialog"
            aria-labelledby="discussionTeamInfoModal-title"
        >
            <div class="modal-dialog">
                <!-- Modal content-->
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close Modal">
                            <i class="fa-solid fa-xmark" aria-hidden="true"></i>
                        </button>

                        <h2 id="discussionTeamInfoModal-title" class="modal-title">View {{ previewTeam.name }}</h2>
                    </div>
                    <div class="modal-body">
                        <div class="col-xs-12">
                            <ul v-for="(student, idx) in previewTeam.members">
                                <li>{{ student.displayName }}</li>
                            </ul>
                        </div>
                        <div class="col-xs-12 marginTop20 buttonGroup">
                            <button class="btn btn-default mainBtn" data-dismiss="modal" aria-label="Close">Close</button>
                        </div>
                    </div>
                    <div class="modal-footer"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['testObj'],
    data() {
        return {
            component_done_loading: false,
            timer: null,
            time: { hours: '00', minutes: '00', seconds: '00' },
            previewTeam: {},
        };
    },
    watch: {
        'testObj.status': {
            handler: function () {
                if (this.testObj.status == 'ongoing' && this.testObj.settings.hasTimeLimit) {
                    this.initTimer();
                } else {
                    this.stopTimer();
                }
            },
        },
        'testObj.startTime': {
            handler: function () {
                if (this.testObj.status == 'ongoing' && this.testObj.settings.hasTimeLimit) {
                    this.initTimer();
                } else {
                    this.stopTimer();
                }
            },
        },
        'testObj.endTime': {
            handler: function () {
                if (this.testObj.status == 'ongoing' && this.testObj.settings.hasTimeLimit) {
                    this.initTimer();
                } else {
                    this.stopTimer();
                }
            },
        },
    },
    created() {},
    mounted() {
        if (this.testObj.status == 'paused') {
            this.processPausedTimerOnInit();
        } else if (this.testObj.status == 'ongoing') {
            this.initTimer();
        } else if (this.testObj.status == 'ended') {
            this.time.days = '00';
            this.time.hours = '00';
            this.time.minutes = '00';
            this.time.seconds = '00';
        }
        this.component_done_loading = true;
    },
    beforeUnmount() {
        this.stopTimer();
    },
    methods: {
        openTeamInfoModal(team) {
            var that = this;
            this.previewTeam = team;
            $('#discussionTeamInfoModal').modal('show');

            $('#discussionTeamInfoModal').on('hidden.bs.modal', function () {
                that.previewTeam = {};
            });
        },
        stopTimer() {
            if (this.timer) {
                clearInterval(this.timer);
            }
            if (this.testObj.status == 'ended') {
                this.time.days = '00';
                this.time.hours = '00';
                this.time.minutes = '00';
                this.time.seconds = '00';
            }
        },
        /*processPausedTimerOnInit(){
			if(this.testObj.status=='paused'){
				var startTime = this.convertToReadableDate(_.get(this.testObj,'startTime','')).dateObj;
				var endTime = this.convertToReadableDate(_.get(this.testObj,'endTime','')).dateObj;
				var totalDistance =  moment.duration(endTime.diff(startTime));
				var timePaused =  this.convertToReadableDate(_.get(this.testObj,'timePaused','')).dateObj;
				var distance = moment.duration(endTime.diff(timePaused));
				if(distance>0){
					this.time.hours = ('0'+distance.hours()).slice(-2);
					this.time.minutes = ('0'+distance.minutes()).slice(-2);
					this.time.seconds = ('0'+distance.seconds()).slice(-2);
				}else{
					this.time.hours = '00';
					this.time.minutes = '00';
					this.time.seconds = '00';
				}
				return;
			}
		},
		initTimer(){
			var that = this;
			//sync or async uses same time.
			var startTime = this.convertToReadableDate(_.get(this.testObj,'myProgression.startTime','')).dateObj;
			var endTime = this.convertToReadableDate(_.get(this.testObj,'myProgression.endTime','')).dateObj;
			var totalDistance =  moment.duration(endTime.diff(startTime));
			this.stopTimer();
			this.timer = setInterval(function() {
				var now = moment();
				var distance = moment.duration(endTime.diff(now));
				if(distance>0){
					that.time.hours = ('0'+distance.hours()).slice(-2);
					that.time.minutes = ('0'+distance.minutes()).slice(-2);
					that.time.seconds = ('0'+distance.seconds()).slice(-2);
				}else{
					that.time.hours = '00';
					that.time.minutes = '00';
					that.time.seconds = '00';
				}
				//totalDistance.subtract(1,'seconds');
			},1000);
		},*/
        processPausedTimerOnInit() {
            if (this.testObj.status == 'paused') {
                var startTime = this.convertToReadableDate(_.get(this.testObj, 'startTime', '')).dateObj;
                var endTime = this.convertToReadableDate(_.get(this.testObj, 'endTime', '')).dateObj;
                var totalDistance = moment.duration(endTime.diff(startTime));
                var timePaused = this.convertToReadableDate(_.get(this.testObj, 'timePaused', '')).dateObj;
                var distance = moment.duration(endTime.diff(timePaused));
                if (distance > 0) {
                    this.time.hours = ('0' + distance.hours()).slice(-2);
                    this.time.minutes = ('0' + distance.minutes()).slice(-2);
                    this.time.seconds = ('0' + distance.seconds()).slice(-2);
                } else {
                    this.time.hours = '00';
                    this.time.minutes = '00';
                    this.time.seconds = '00';
                }
                return;
            }
        },
        initTimer() {
            var that = this;
            this.isShown = true;
            if (!(_.get(this.testObj, 'startTime') && _.get(this.testObj, 'endTime'))) {
                return;
            }
            //sync or async uses same time.
            var startTime = this.convertToReadableDate(_.get(this.testObj, 'startTime', '')).dateObj;
            var endTime = this.convertToReadableDate(_.get(this.testObj, 'endTime', '')).dateObj;
            var totalDistance = moment.duration(endTime.diff(startTime));
            this.stopTimer();
            this.timer = setInterval(function () {
                var now = moment();
                var distance = moment.duration(endTime.diff(now));
                if (distance > 0) {
                    that.time.hours = ('0' + distance.hours()).slice(-2);
                    that.time.minutes = ('0' + distance.minutes()).slice(-2);
                    that.time.seconds = ('0' + distance.seconds()).slice(-2);
                } else {
                    that.time.hours = '00';
                    that.time.minutes = '00';
                    that.time.seconds = '00';
                }
            }, 1000);
        },
    },
};
</script>
<style scoped>
@media (min-width: 992px) {
    .discussion .timer_Div {
        font-size: 100px;
    }

    .discussion .time .number-div {
        padding: 10px 24px 5px;
    }

    .discussion .time .number-div:last-child {
        margin-left: 10px;
    }

    .discussion .timeText {
        /* font-size: 14px; */
        font-size: 0.778rem;
        margin-bottom: 10px;
    }
}
.discussion .test-progression th:first-child,
.discussion .test-progression td:first-child {
    width: 10%;
}

.discussion .test-progression tbody {
    display: block;
    height: 480px;
    overflow: auto;
}
.discussion .test-progression thead,
.discussion .test-progression tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.time .number-div {
    background: #fff;
    padding: 10px 12px 5px;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(128, 128, 128, 0.5);
}

.time .number-div:last-child {
    margin-left: 5px;
}

.colon {
    color: #767676;
}

@media (min-width: 992px) {
    .timer_Div {
        font-size: 70px;
        font-weight: bold;
        line-height: 1;
        margin-bottom: 40px;
    }

    .colon {
        padding: 0 10px;
    }
}

@media (max-width: 991px) {
    .timer_Div {
        font-size: 24px;
        font-weight: bold;
        line-height: 1;
        margin-bottom: 20px;
    }

    .colon {
        padding: 0 5px;
    }
}

.timeText {
    color: #222;
    /* font-size: 12px; */
    font-size: 0.667rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 5px;
}
.timerPaused {
    opacity: 0.2;
}
</style>
