<template>
    <div>
        <div tabindex="0">
            <caption class="visible-hidden">
                Question Stem
            </caption>

            <kr-math :input="question.question" :safe="!question.questionIsHTML" class="question"></kr-math>
        </div>
        <template v-if="question.attachments && question.attachments.length != 0">
            <div class="marginTop20 fontBold"><i class="fa fa-paperclip marginRight8" aria-hidden="true"></i>Question Attachment(s)</div>

            <div class="textLink">
                <div v-for="(file, idx) in question.attachments" :key="idx + '-' + file.filename" class="flex">
                    <i class="fa fa-file-image-o marginRight8" aria-hidden="true"></i>
                    <template
                        v-if="
                            (testObj.type == 'application' && (!isTestCompleted || (isTestCompleted && testObj.allowStudentsToViewAttachments))) ||
                            testObj.type != 'application'
                        "
                    >
                        <template v-if="isPdf(file)">
                            <template v-if="testObj.settings.allowPdfDownload">
                                <a class="textLink" :href="file.url" target="_blank" download :aria-label="file.filename">
                                    {{ file.filename }}
                                </a>
                            </template>
                            <template v-else>
                                <a class="textLink" :aria-label="file.filename" @click.prevent="openViewPdfModal(file)">{{ file.filename }}</a>
                            </template>
                        </template>
                        <template v-else>
                            <a class="textLink" :href="file.url" target="_blank" download :aria-label="file.filename">{{ file.filename }}</a>
                        </template>
                    </template>
                    <template v-else>
                        <a v-tooltip="{ content: 'Viewing disabled' }" class="textLink disabled" :aria-label="file.filename">{{ file.filename }} </a>
                    </template>
                </div>
            </div>
        </template>

        <div v-if="!testObj._isPreview || testObj._isTeacherPreview" class="marginTop30">
            <template v-if="question.type == 'vsa'">
                <input
                    :id="question.uuid + '-openended'"
                    v-model="model"
                    class="form-control vsaTextField"
                    type="text"
                    placeholder="Input your answer here"
                    :disabled="disabled ? true : undefined"
                    @keyup="$emit('changed', model)"
                    @keydown="keyhandler"
                />
                <span v-if="question.vsaSettings.label != ''" class="marginRight10">
                    {{ question.vsaSettings.label }}
                </span>
            </template>

            <kr-editor
                v-else-if="question.canAllowStudentRichTextResponses"
                :key="question.uuid"
                v-model="model"
                :options="{
                    toolbar1: 'bold italic underline strikethrough | bullist numlist outdent indent | table | image | link',
                    toolbar2: '',
                }"
                :disabled="disabled ? true : undefined"
                @input="$emit('changed', model)"
            ></kr-editor>

            <textarea
                v-else
                :id="question.uuid + '-openended'"
                ref="textarea"
                v-model="model"
                placeholder="Input your answers here"
                row="5"
                cols="50"
                :disabled="disabled ? true : undefined"
                @keyup="$emit('changed', model)"
                @keydown="keyhandler"
                @input="textareaResize"
            ></textarea>

            <!-- <div v-if="question.maximum || question.minimum!=0" :style="'color:'+charColor+';font-size:14px;padding-top:5px'">
	            <span v-if="question.minimum!=0" style="float:left;color:#989b9c;font-weight:bold;">Min {{question.minimum}} Word/s</span>
	        	<span v-if="question.maximum" style="float:right;">{{noOfWords}} / {{question.maximum}} Words</span>
        	</div> -->

            <template v-if="typing">
                <div class="greyText marginTop10">{{ typing.user.displayName }} is typing...</div>
            </template>

            <div v-if="question.type != 'vsa'" class="fontSize16 marginTop10">
                <span :style="'color:' + charColor + ';padding-top:5px'">
                    <template v-if="noOfWords < question.minimum && question.minimum != 0">
                        Please enter {{ question.minimum - noOfWords }} more
                        {{ question.minimum - noOfWords == 1 ? 'word' : 'words' }}
                    </template>
                    <template v-else-if="noOfWords <= question.maximum">
                        {{ noOfWords }}
                        {{ noOfWords < 2 ? 'word' : 'words' }} (max {{ question.maximum }})
                    </template>
                    <template v-else-if="noOfWords > question.maximum">
                        {{ noOfWords }}
                        {{ noOfWords < 2 ? 'word' : 'words' }} (max {{ question.maximum }})
                    </template>
                </span>
                <span :key="'mcqs-' + question.uuid + '-lastAttempt'" class="floatRight" v-html="displayLastAttemptDate()"></span>
            </div>

            <div v-else-if="question.type == 'vsa'" class="fontSize16 marginTop10">
                <span :key="'mcqs-' + question.uuid + '-lastAttempt'" v-html="displayLastAttemptDate()"></span>
            </div>

            <!--<div class="fontSize12 marginTop10"  v-if="question.maximum || question.minimum!=0" :style="'color:'+charColor+';font-size:14px;padding-top:5px'">
				{{noOfWords}}<template v-if="question.maximum">/{{question.maximum}}</template> word/s (Min. {{question.minimum}}<template v-if="question.maximum"> , Max. {{question.maximum}}</template>) 
			</div>-->
        </div>
    </div>
</template>

<script>
export default {
    props: ['testObj', 'question', 'value', 'disabled', 'typing', 'isTestCompleted'],

    data() {
        return {
            model: null,
            charColor: '#0f9b81',
            noOfWords: 0,
        };
    },
    watch: {
        value: {
            handler: function () {
                this.model = this.value;
            },
            deep: true,
        },
        question: {
            handler: function () {
                this.model = this.value;
            },
            deep: true,
        },
        model: {
            handler(value, oldValue) {
                this.computeNoOfWords();
                if (this.noOfWords < this.question.minimum && this.question.minimum != 0) {
                    this.charColor = '#dc3c42';
                } else if (this.noOfWords <= this.question.maximum) {
                    this.charColor = 'grey';
                } else if (this.noOfWords > this.question.maximum) {
                    this.charColor = '#dc3c42';
                }
            },
        },
    },
    created() {
        this.model = this.value;
    },
    mounted() {
        if (this.$refs.textarea) {
            this.$refs.textarea.style.minHeight = this.$refs.textarea.scrollHeight + 'px';
        }
    },
    methods: {
        displayLastAttemptDate() {
            if (!this.question.studentAnswer.attempts2) {
                return '';
            }
            let attempt2Length = this.question.studentAnswer.attempts2.length;
            let attempt = this.question.studentAnswer.attempts2[attempt2Length - 1];
            if (attempt2Length == 0) {
                return '';
            }
            return `<span>saved this answer at ${this.convertToReadableDate(attempt.timestamp, 'YYYY-MM-DD h:mm A').date} (${
                this.convertToReadableDate(attempt.timestamp, 'YYYY-MM-DD h:mm A').current_timezone
            })</span>`;
        },
        computeNoOfWords() {
            var length = this.model ? this.model.split(/[^\s]+/).length - 1 : 0;
            this.noOfWords = length;
        },
        init() {
            this.model = this.value;
            this.computeNoOfWords();
        },
        keyhandler(event) {
            if (this.question.maximum) {
                if (this.noOfWords == this.question.maximum) {
                    //console.log(event);
                    if (event.keyCode == 46 || event.keyCode == 8) {
                    } else if (event.keyCode == 13 || event.keyCode == 9 || event.keyCode == 32) {
                        event.preventDefault();
                    }
                }
            }
        },
        textareaResize() {
            if (!this.$refs.textarea) {
                return;
            }
            this.$refs.textarea.style.minHeight = this.$refs.textarea.scrollHeight + 'px';
        },
        openViewPdfModal(attachment) {
            var that = this;
            Events.fire('view-pdf-modal', attachment, {
                canDownload: that.testObj.settings.allowPdfDownload,
            });
        },
    },
};
</script>

<style scoped>
textarea {
    border: solid 1px #d8d8d8;
}

.vsaTextField {
    width: 200px;
    display: inline-block;
}
</style>
