import { defineStore } from 'pinia';
var app_id = `intedashboard.v3.${process.env.VUE_APP_ENV}.client`;
export const useAuthUserStore = defineStore(app_id, {
    state: () => ({
        client: {
            profile: {},
            auth: {},
            options: {}, //login options will also now be saved
            socket: {
                isConnected: false,
                isAuthenticated: false,
            },
            impersonate: {
                profile: {},
                auth: {},
                impersonate: false,
            },
            search_history: {},
            courses: [],
            tabs: [], //list of uuids
        },
        session: {
            uuid: '',
        },
    }),

    actions: {
        logout() {
            this.client.auth = {};
            this.client.profile = {};
            this.client.options = {};
            this.client.tabs = {};
            localStorage.removeItem(app_id);
            Events.fire('logout-successful');
        },
        login(data) {
            this.client.auth = data.auth;
            this.client.profile = data.profile;
            this.client.options = data.options;
            Events.fire('login-successful', data.options);
        },
        impersonate_login(data) {
            this.client.impersonate.auth = data.auth;
            this.client.impersonate.profile = data.profile;
            this.client.impersonate.impersonate = true;
            Events.fire('impersonate-login-successful', data.options);
        },
        impersonate_logout() {
            this.client.impersonate.auth = {};
            this.client.impersonate.profile = {};
            this.client.impersonate.impersonate = false;
            Events.fire('impersonate-logout-successful');
        },
        updateProfile(data) {
            if (this.client.impersonate.impersonate) {
                this.client.impersonate.profile = data.profile;
            } else {
                this.client.profile = data.profile;
            }
            Events.fire('profile-update-successful', data.options);
        },
        updateSearchHistory(data) {
            if (!data.id) {
                //no way to retrieve as we don't do key increments
                return;
            }
            let id = data.id;
            delete data.id;
            this.client.search_history[id] = _.clone(data);
        },
        /*data.courseUuid data.sectionUuid data.moduleUuid*/
        addHiddenModule(data) {
            if (!data.courseUuid || !data.sectionUuid || !data.moduleUuid) {
                //no way to retrieve as we don't do key increments
                return;
            } else {
                let idx = _.findIndex(this.client.courses, function (o) {
                    return o.uuid == data.courseUuid;
                });
                if (idx != -1) {
                    let idx2 = _.findIndex(this.client.courses[idx].sections, function (o) {
                        return o.uuid == data.sectionUuid;
                    });
                    if (idx2 == -1) {
                        this.client.courses[idx].sections.push({
                            uuid: data.sectionUuid,
                            hidden_modules: [data.moduleUuid],
                        });
                    } else {
                        let section = this.client.courses[idx].sections[idx2];
                        let idx3 = section.hidden_modules.indexOf(data.moduleUuid);
                        if (idx3 == -1) {
                            this.client.courses[idx].sections[idx2].hidden_modules.push(data.moduleUuid);
                        }
                    }
                } else {
                    this.client.courses.push({
                        uuid: data.courseUuid,
                        sections: [
                            {
                                uuid: data.sectionUuid,
                                hidden_modules: [data.moduleUuid],
                            },
                        ],
                    });
                }
            }
        },
        /*data.courseUuid data.sectionUuid data.moduleUuid*/
        removeHiddenModule(data) {
            if (!data.courseUuid || !data.sectionUuid || !data.moduleUuid) {
                //no way to retrieve as we don't do key increments
                return;
            } else {
                let idx = _.findIndex(this.client.courses, function (o) {
                    return o.uuid == data.courseUuid;
                });
                if (idx != -1) {
                    let idx2 = _.findIndex(this.client.courses[idx].sections, function (o) {
                        return o.uuid == data.sectionUuid;
                    });
                    if (idx2 != -1) {
                        let section = this.client.courses[idx].sections[idx2];
                        let idx3 = section.hidden_modules.indexOf(data.moduleUuid);
                        if (idx3 != -1) {
                            section.hidden_modules.splice(idx3, 1);
                        }
                    }
                }
            }
        },
        /*For modal change password display*/
        updatePasswordModalShown(data) {
            this.client.auth.password_modal_shown = data;
        },
        removeTab() {
            let uuid = this.session.uuid;
            let idx = this.client.tabs.indexOf(uuid);
            if (idx != -1) {
                this.client.tabs.splice(idx, 1);
            }
        },
        setSessionUuid(uuid) {
            if (!this.session.uuid) {
                this.session.uuid = uuid;
                sessionStorage.setItem(app_id, JSON.stringify(this.session));
                if (!this.client.tabs.includes(uuid)) {
                    this.client.tabs.push(uuid);
                }
            }
        },
        /*data.courseUuid data.sectionUuid*/
        setCurrentSection(data) {
            if (!data.courseUuid || !data.sectionUuid) {
                //no way to retrieve as we don't do key increments
                return;
            } else {
                let idx = _.findIndex(this.client.courses, function (o) {
                    return o.uuid == data.courseUuid;
                });
                if (idx != -1) {
                    this.client.courses[idx].selectedSectionUuid = data.sectionUuid;
                } else {
                    this.client.courses.push({
                        uuid: data.courseUuid,
                        selectedSectionUuid: data.sectionUuid,
                        sections: [],
                    });
                }
            }
        },
    },
    getters: {
        isImpersonation(state) {
            return state.client.impersonate.impersonate;
        },
        profile(state) {
            if (state.client.impersonate.impersonate) {
                return state.client.impersonate.profile;
            } else {
                return state.client.profile;
            }
        },
        originalProfile(state) {
            return state.client.profile;
        },
        isLoggedIn(state) {
            if (state.client.auth.access_token != undefined) return true;
            return false;
        },
        auth(state) {
            console.log(state);
            if (state.client.impersonate.impersonate) {
                return state.client.impersonate.auth;
            } else {
                return state.client.auth;
            }
        },
        options(state) {
            return state.client.options;
        },
        search_history(state) {
            return state.client.search_history;
        },
        tabs(state) {
            return state.client.tabs;
        },
        sessionUuid(state) {
            return state.session.uuid;
        },
        courses(state) {
            return state.client.courses;
        },
    },
    persist: {
        storage: localStorage, // data in sessionStorage is cleared when the page session ends.
    },
});
