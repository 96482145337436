<template>
    <div v-if="component_done_loading">
        <kr-panel :with-footer="false" :options="{ canToggle: false }">
            <template #title>
                <div class="col-xs-6">
                    <template v-if="testObj.status == 'prework'"> Material Status </template>
                </div>
                <div class="buttonGroup">
                    <!--<div class="hidden-xs hidden-sm">
                        <button class="btn btn-primary mainBtn">
                            <i class="fas fa-bell"></i>&nbsp;Send Reminder to Download
                        </button>
                    </div>
                    <div class="hidden-md hidden-lg">
                        <button class="btn btn-primary mainBtn">
                            <i class="fas fa-bell"></i>
                        </button>
                    </div>-->
                </div>
            </template>
            <template #content>
                <div class="dashboardStatus">
                    <template v-if="testObj.type == 'prework'">
                        <div class="flex marginBottom20">
                            <div class="col-xs-6 pieChartDiv">
                                <kr-pie-chart :chartdata="data" :options="options" :height="118"></kr-pie-chart>
                            </div>
                            <div class="col-xs-6 names">
                                <div>Opened</div>
                                <div class="namesScroll">
                                    <template v-for="(student, idx) in stats.opened">
                                        <div>{{ student.displayName }}</div>
                                    </template>
                                    <!--<div>Students 1</div>
									<div>Students 2</div>
									<div>Students 3</div>
									<div>Students 4</div>
									<div>Students 5</div>
									<div>Students 6</div>
									<div>Students 7</div>
									<div>Students 8</div>
									<div>Students 9</div>
									<div>Students 10</div>
									<div>Students 11</div>
									<div>Students 12</div>
									<div>Students 13</div>
									<div>Students 14</div>
									<div>Students 15</div>
									<div>Students 16</div>-->
                                </div>
                            </div>
                        </div>
                        <div class="dashboardStatusBar">
                            <div class="flex align-items details columns">
                                <div>
                                    <i class="fas fa-circle redGradientCircle" aria-hidden="true"></i>
                                </div>
                                <div>
                                    <div class="col-xs-12">Not Opened</div>
                                    <div class="col-xs-12">
                                        <span>{{ stats.neither.length }}</span>
                                        / {{ testObj.students.length }} Students
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items details columns">
                                <div>
                                    <i class="fas fa-circle readOnlyCircle" aria-hidden="true"></i>
                                </div>
                                <div>
                                    <div class="col-xs-12">Opened</div>
                                    <div class="col-xs-12">
                                        <span>{{ stats.opened.length }}</span> / {{ testObj.students.length }} Students
                                    </div>
                                </div>
                            </div>
                            <div class="flex align-items details columns">
                                <div>
                                    <i class="fas fa-circle activeCircle" aria-hidden="true"></i>
                                </div>
                                <div>
                                    <div class="col-xs-12">Downloaded</div>
                                    <div class="col-xs-12">
                                        <span>{{ stats.downloaded.length }}</span>
                                        / {{ testObj.students.length }} Students
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <!--<div>
						<div class="col-xs-12 col-md-6">
						</div>
						
					</div>-->
                </div>
            </template>
        </kr-panel>
    </div>
</template>
<script>
export default {
    props: ['testObj'],
    data() {
        return {
            component_done_loading: false,
            data: {
                datasets: [
                    {
                        data: [10, 20, 30],
                        backgroundColor: ['#f1272f', '#f7981c', '#188e9b'],
                    },
                ],

                // These labels appear in the legend and in the tooltips when hovering different arcs
                labels: ['Not Opened', 'Opened', 'Downloaded'],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                    position: 'top',
                    fullWidth: false,
                    labels: {
                        display: false,
                        usePointStyle: true,
                        fontSize: 15,
                        fontStyle: 'bold',
                    },
                },
            },
            stats: { opened: [], downloaded: [], neither: [] },
        };
    },
    watch: {
        testObj: {
            handler: function () {
                this.init();
            },
            deep: true,
        },
    },
    created() {
        this.init();
    },
    mounted() {
        this.component_done_loading = true;
    },
    methods: {
        init() {
            let students = this.testObj.students;
            let materials = this.testObj.materials;
            this.stats = { opened: [], downloaded: [], neither: [] };
            for (var i = 0; i < students.length; i++) {
                let student = students[i];
                let flag = 0;
                for (var j = 0; j < materials.length; j++) {
                    if (materials[j].opened.includes(student.displayName)) {
                        flag = 2;
                        //break;
                    }
                    if (materials[j].downloaded.includes(student.displayName)) {
                        flag += 1;
                    }
                }
                if (flag == 2) {
                    this.stats.opened.push(student);
                } else if (flag == 3) {
                    //this.stats.downloaded.push(student);
                    this.stats.opened.push(student);
                } else if (flag == 1) {
                    this.stats.downloaded.push(student);
                } else {
                    this.stats.neither.push(student);
                }
            }
            this.data.datasets[0].data = [this.stats.neither.length, this.stats.opened.length, this.stats.downloaded.length];
            let newData = _.clone(this.data);
            this.data = newData;
        },
    },
};
</script>

<style>
.pieChartDiv {
    height: 118px;
}

.dashboardStatus .names div,
.dashboardStatus .names div div {
    font-weight: bold;
    font-size: 14px;
    margin-top: 5px;
}

.dashboardStatus .names div:first-child {
    font-weight: bold;
    font-size: 12px;
    margin-top: 0;
}

.dashboardStatus .names div div:first-child {
    font-weight: bold;
    font-size: 14px;
    margin-top: 0;
}

.dashboardStatus .details div:first-child {
    font-size: 12px;
    margin-right: 10px;
}

.dashboardStatus .details div:last-child {
    font-weight: bold;
    color: #4a4a4a;
}

.dashboardStatus .details span {
    font-size: 24px;
}

.dashboardStatusBar {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
}

.dashboardStatusBar .column {
    flex: 1;
    margin-right: 20px;
}

.dashboardStatusBar .column:last-child {
    flex: 1;
    margin-right: 0;
}

.dashboardStatus .details {
    padding-left: 10px;
    padding-right: 10px;
    border-right: 1px solid #d8d8d8;
}

.dashboardStatus .details:first-child {
    padding-left: 0;
    padding-right: 10px;
}

.dashboardStatus .details:last-child {
    padding-left: 10px;
    padding-right: 0;
    border-right: 0;
}

.dashboardStatus .namesScroll {
    height: 110px;
    overflow: hidden;
    overflow-y: scroll;
}
</style>
